<template>
  <VlanForm
    :formConfig="formConfig"
    :forbiddenVlanIds="forbiddenVlanIds"
    :forbiddenVlanNames="forbiddenVlanNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </VlanForm>
</template>

<script>
/**
 * компонент настроенной формы VlanForm через generateFormConfig для создания нового vlan.
 * ! Компонент локальный, используется только в CreateVlanModal.vue
 * @component
 */

import {
  VlanForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateVlanForm',
  components: {
    VlanForm
  },
  props: {
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
