// selected checkbox of a row or not
function getSelectedRowValue(currentVlanId, selectedVlanIdsArray) {
  let value = false;

  selectedVlanIdsArray.forEach(vlanId => {
    if (vlanId === currentVlanId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
