/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import { AXIOS_INSTANCE, GET_VLAN_BY_VLAN_ID_URL } from '../config';
import {
  vlanDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').Vlan_FRONTEND} Vlan_FRONTEND
 * @typedef {import('../types.js').Vlan_BACKEND} Vlan_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/511410274/FT23.10.14+VLAN+VLAN+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {Vlan_BACKEND} vlan - искомый объект vlan.
 */

/**
 * Функция-фетчер для получения vlan по его vlan_id.
 *
 * @param {number} vlanId - vlan_id искомого vlan.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */ 

const getVlanByVlanIdFetcher = async (vlanId) => {
  const url = `${GET_VLAN_BY_VLAN_ID_URL}${vlanId}`;

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {Vlan_FRONTEND} vlan - искомый объект vlan.
 */

/**
 * получает vlan по его vlan_id.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {number} vlanId - vlan_id искомого vlan.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getVlanByVlanId = async (vlanId, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await getVlanByVlanIdFetcher(vlanId);
    const {
      vlan
    } = response;

    const data = vlanDataTypesConverterForFrontend(vlan);

    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
};

export default getVlanByVlanId;
