<template>
  <div class="vlan-group-page">
    <VlanGroupTable
      :items="vlanGroups"
      :totalItems="totalVlanGroupsCount"
      :perPage="limit"
      :selectedVlanGroupIds="selectedVlanGroupIds"
      :activeRowVlanGroupId="''"
      :sortState="sortParams"
      :isDataLoading="false"
      :isDisabled="isLoading"
      :isOneColMode="false"
      :isSelectionRowsModeEnabled="true"
      @on-cell-click="handleCellClick"
      @on-selected-rows-change="handleSelectRow"
      @on-sort-change="handleSortChange"
      @on-page-change="handlePageChange"
      @on-per-page-change="handlePerPageChange"
    >
      <template v-slot:table-menu>
        <div class="control-panel">
          <div class="control-panel__container left">
            <WButton info
              :disabled="isLoading"
              @click="openCreateVlanGroupModal"
            >
              {{ $t('general.add') }}
            </WButton>
            <WButton info
              :disabled="isLoading || !selectedVlanGroupIds.length"
              @click="openDeleteVlanGroupModal"
            >
              {{ $t('general.delete') }}
            </WButton>
          </div>
        </div>
      </template>
    </VlanGroupTable>

    <CreateVlanGroupModal
      :forbiddenVlanGroupNames="forbiddenVlanGroupNames"
      :isOpen="isCreateVlanGroupModalOpen"
      @submit="createVlanGroup"
      @close="closeCreateVlanGroupModal"
    />
    <ChangeVlanGroupModal
      :vlanGroupForChanging="selectedVlanGroupForChanging"
      :forbiddenVlanGroupNames="forbiddenVlanGroupNames"
      :isOpen="isChangeVlanGroupModalOpen"
      @submit="changeVlanGroup"
      @close="closeChangeVlanGroupModal"
    />
    <DeleteVlanGroupModal
      :vlanGroupIdsToDelete="selectedVlanGroupIds"
      :isOpen="isDeleteVlanGroupModalOpen"
      @close="closeDeleteVlanGroupModal"
      @confirm="deleteVlanGroups"
    />
  </div>
</template>

<script>
/**
 * компонент страницы vlan group
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%B2%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B5-VLAN-%D0%93%D1%80%D1%83%D0%BF%D0%BF%D0%B0
 * @component
 */
/* eslint-disable camelcase */

import {
  VlanGroupTable,
  CreateVlanGroupModal,
  ChangeVlanGroupModal,
  DeleteVlanGroupModal
} from './components';

import vlanGroupApi from './api';

export default {
  name: 'VlanGroupPage',
  components: {
    VlanGroupTable,
    CreateVlanGroupModal,
    ChangeVlanGroupModal,
    DeleteVlanGroupModal
  },
  props: {
  },
  data() {
    return {
      vlanGroups: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedVlanGroupIds: [],
      selectedVlanGroupForChanging: {},
      totalVlanGroupsCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateVlanGroupModalOpen: false,
      isChangeVlanGroupModalOpen: false,
      isDeleteVlanGroupModalOpen: false
    };
  },
  computed: {
    forbiddenVlanGroupNames() {
      const vlanGroupNames = this.vlanGroups.map((vlanGroup) => vlanGroup.name);
      return vlanGroupNames;
    }
  },
  methods: {
    handleCellClick(rowData) {
      const {
        nativeData: { id }
      } = rowData;

      const [selectedVlanGroupForChanging] = this.vlanGroups.filter((vlanGroup) => vlanGroup.id === id);

      this.selectedVlanGroupForChanging = selectedVlanGroupForChanging;

      this.openChangeVlanGroupModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedVlanGroupIds = selectedRowData.map((rowData) => rowData.nativeData.id);

      this.selectedVlanGroupIds = selectedVlanGroupIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };
      this.getVlanGroups();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalVlanGroupsCount) {
        this.offset = 0;
      }
      this.getVlanGroups();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalVlanGroupsCount) {
        this.offset = 0;
      }
      this.getVlanGroups();
    },
    getVlanGroups() {
      vlanGroupApi.getVlanGroups({
        limit: this.limit,
        offset: this.offset,
        // // если надо будет реализовать фильтрацию, то использовать filterByName, filterByVlanId
        // filterByName: undefined,
        // filterByVlanId: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            vlan_groups
          } = data;

          this.vlanGroups = vlan_groups;
          this.totalVlanGroupsCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedVlanGroupIds = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    createVlanGroup(formValues) {
      vlanGroupApi.createVlanGroup({
        list_vlan_id: formValues['general.list_vlan_id'],
        name: formValues['general.name']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeCreateVlanGroupModal();
          this.getVlanGroups();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('vlanGroup.responseMessage.createVlanGroup.success'),
        errorMessageDefault: this.$t('vlanGroup.responseMessage.createVlanGroup.error.default'),
        errorMessageInvalidRange: this.$t('vlanGroup.responseMessage.createVlanGroup.error.invalidRange')
      });
    },
    changeVlanGroup(formValues) {
      const { id } = this.selectedVlanGroupForChanging;

      vlanGroupApi.updateVlanGroup({
        id,
        list_vlan_id: formValues['general.list_vlan_id'],
        name: formValues['general.name']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeVlanGroupModal();
          this.getVlanGroups();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('vlanGroup.responseMessage.updateVlanGroup.success'),
        errorMessageDefault: this.$t('vlanGroup.responseMessage.createVlanGroup.error.default'),
        errorMessageInvalidRange: this.$t('vlanGroup.responseMessage.createVlanGroup.error.invalidRange')
      });
    },
    deleteVlanGroups() {
      const idsToDelete = this.selectedVlanGroupIds;

      vlanGroupApi.deleteVlanGroups({
        ids: idsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteVlanGroupModal();
          this.getVlanGroups();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('vlanGroup.responseMessage.deleteVlanGroups.success'),
        errorMessage: this.$t('vlanGroup.responseMessage.deleteVlanGroups.error')
      });
    },
    openCreateVlanGroupModal() {
      this.isCreateVlanGroupModalOpen = true;
    },
    closeCreateVlanGroupModal() {
      this.isCreateVlanGroupModalOpen = false;
    },
    openChangeVlanGroupModal() {
      this.isChangeVlanGroupModalOpen = true;
    },
    closeChangeVlanGroupModal() {
      this.isChangeVlanGroupModalOpen = false;
      this.selectedVlanGroupForChanging = {};
    },
    openDeleteVlanGroupModal() {
      this.isDeleteVlanGroupModalOpen = true;
    },
    closeDeleteVlanGroupModal() {
      this.isDeleteVlanGroupModalOpen = false;
    }
  },
  mounted() {
    this.getVlanGroups();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
