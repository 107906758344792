<template>
  <div class="wlan-policy-tab">
    <!-- контент вкладок и/или секций -->
    <div class="wlan-policy-tab-content">
      <template v-for="(content, index) in tabContent">
        <!-- ! вкладка accessPolicies -->
        <template v-if="content.name === 'accessPolicies.general'">
          <AccessPoliciesGeneralSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <!-- ! секция advanced.eogreSettings -->
        <template v-else-if="content.name === 'advanced.eogreSettings'">
          <!--
            todo пока что поле eogre_profile скрыто. В будущем появится и ручка, и поле будет доступно в ui
            https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398188/FT23.10.1+front?focusedCommentId=625279040
          -->
        </template>
        <!-- ! вкладка advanced.nasIdSettings -->
        <template v-else-if="content.name === 'advanced.nasIdSettings'">
          <NasIdSettingsSection
            :key="index"
            :config="content"
            :formState="formState"
            @update:inputValue="handleFormSectionInputValue"
          />
        </template>
        <!-- ! остальные инпуты без выделения в отдельные компоненты секций -->
        <template v-else>
          <div class="wlan-policy-tab-input-group" :key="index">
            <h3 v-if="content.label"
              class="wlan-policy-tab-input-group-heading"
            >
              {{ content.label }}
            </h3>
            <template v-for="input in content.inputs">
              <template v-if="input.inputType === 'checkbox'">
                <WCheckbox
                  :key="input.id"
                  :label="input.label"
                  :name="input.name"
                  :id="input.name"
                  :checked="formState[input.name]"
                  @change="handleCheckbox(input.name, $event)"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="change"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                />
              </template>
              <template v-else-if="input.inputType === 'radio'">
                <WRadio
                  :key="input.id"
                  :label="input.label"
                  :name="input.name"
                  :id="input.id"
                  :value="input.radioValue"
                  @input="handleRadio(input, $event)"
                  :checked="formState[input.name] === input.radioValue"
                />
              </template>
              <template v-else-if="input.inputType === 'select'">
                <WSelect
                  :key="input.id"
                  :options="input.selectOptions"
                  :label="input.label"
                  :name="input.name"
                  :id="input.name"
                  :value="formState[input.name]"
                  @input="handleSelect(input.name, $event)"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="input"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                />
              </template>
              <!-- обычные инпуты -->
              <template v-else>
                <WInput
                  data-vv-delay="500"
                  :key="input.id"
                  :type="input.inputType"
                  :label="input.label"
                  :value="formState[input.name]"
                  @input="handleInput(input, $event)"
                  :id="input.name"
                  :name="input.name"
                  :placeholder="input.placeholder"
                  :disabled="input.isDisabled"
                  v-validate="input.validateRules"
                  data-vv-validate-on="input"
                  :data-vv-as="input.label"
                  :errorText="errors.first(input.name)"
                  autocomplete="one-time-code"
                />
              </template>
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент управления содержимым табов формы WlanPolicyForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

import {
  AccessPoliciesGeneralSection
} from './tabs/accessPolicies';

import {
  NasIdSettingsSection
} from './tabs/advanced';

export default {
  name: 'WlanPolicyFormTabContent',
  inject: ['$validator'],
  components: {
    AccessPoliciesGeneralSection,
    NasIdSettingsSection
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    currentTabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      this.$emit('update:inputValue', { inputName: name, value });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleUpdateBranchProfileVlans({ value }) {
      this.$emit('update:inputValue', {
        inputName: 'vlan.branchProfileVlans',
        value
      });
    },
    handleFormSectionInputValue({ inputName, value }) {
      this.$emit('update:inputValue', { inputName, value });
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.wlan-policy-tab {
  height: 100%;
  max-height: 500px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.wlan-policy-tab::-webkit-scrollbar {
  width: 5px;
}

.wlan-policy-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.wlan-policy-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.wlan-policy-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.wlan-policy-tab-description {
  flex: 0 0 40%;
  display: flex;
}

.wlan-policy-tab-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}
</style>
<style lang="css">
.wlan-policy-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

.wlan-policy-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wlan-policy-tab-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wlan-policy-form-close-icon {
  color: var(--brand-danger);
  cursor: pointer;
}
</style>
