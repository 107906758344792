<template>
  <i v-if="status" class="fa fa-arrow-circle-up fa-lg connected"></i>
  <i v-else class="fa fa-arrow-circle-down fa-lg disconnected"></i>
</template>

<script>
/**
 * компонент - ячейка admin_status.
 * компонент локальный: используется только в SVITable.vue
 * @component
 */

export default {
  name: 'StatusCell',
  components: {},
  props: {
    status: {
      type: Boolean,
      required: true
    }
  },
  methods: {}
};
</script>

<style lang="css" scoped>
.connected {
  color: var(--brand-success);
}

.disconnected {
  color: var(--brand-danger);
}
</style>
