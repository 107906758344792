<template>
  <WlanPolicyForm
    :formConfig="formConfig"
    :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </WlanPolicyForm>
</template>

<script>
/**
 * компонент настроенной формы WlanPolicyForm через generateFormConfig для изменения существующего wlan policy.
 * ! Компонент локальный, используется только в ChangeWlanPolicyModal.vue
 * @component
 */

import {
  WlanPolicyForm,
  generateFormConfig
} from '../../features';

/* eslint-disable camelcase */

export default {
  name: 'ChangeWlanPolicyForm',
  components: {
    WlanPolicyForm
  },
  props: {
    wlanPolicyForChanging: {
      type: Object,
      required: true
    },
    forbiddenWlanPolicyNames: {
      type: Array,
      default: () => []
    },
    aclOptions: {
      type: Array,
      default: () => []
    },
    vlanOptions: {
      type: Array,
      default: () => []
    },
    vlanGroupOptions: {
      type: Array,
      default: () => []
    },
    policyMapOptions: {
      type: Array,
      default: () => []
    },
    eogreProfileOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const aclSelectOptions = this.aclOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const vlanSelectOptions = this.vlanOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const vlanGroupSelectOptions = this.vlanGroupOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const policyMapSelectOptions = this.policyMapOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const eogreProfileSelectOptions = this.eogreProfileOptions.map(({ name, value }) => ({
      display: name, data: value
    }));

    const {
      id,
      created_at,
      updated_at,
      name,
      description,
      profile_enabled,
      central_switch_enabled,
      acl,
      id_vlan,
      id_vlan_group,
      id_policy_map_client_in,
      id_policy_map_client_out,
      id_policy_map_ssid_in,
      id_policy_map_ssid_out,
      session_timeout,
      idle_timeout,
      idle_threshold,
      exclusion_timeout,
      aaa_override_enabled,
      proxy_arp_enabled,
      id_eogre_profile,
      central_auth_enabled,
      nas_id_1,
      nas_id_1_custom,
      nas_id_2,
      nas_id_2_custom,
      nas_id_3,
      nas_id_3_custom
    } = this.wlanPolicyForChanging;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          name: {
            isDisabled: true,
            defaultValue: name
          },
          description: {
            defaultValue: description
          },
          profile_enabled: {
            defaultValue: profile_enabled
          },
          central_switch_enabled: {
            defaultValue: central_switch_enabled
          },
          central_auth_enabled: {
            defaultValue: central_auth_enabled
          }
        },
        accessPolicies: {
          acl: {
            defaultValue: acl,
            selectOptions: aclSelectOptions
          },
          id_vlan: {
            defaultValue: id_vlan,
            selectOptions: vlanSelectOptions
          },
          id_vlan_group: {
            defaultValue: id_vlan_group,
            selectOptions: vlanGroupSelectOptions
          }
        },
        qos: {
          id_policy_map_client_in: {
            defaultValue: id_policy_map_client_in,
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_client_out: {
            defaultValue: id_policy_map_client_out,
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_ssid_in: {
            defaultValue: id_policy_map_ssid_in,
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_ssid_out: {
            defaultValue: id_policy_map_ssid_out,
            selectOptions: policyMapSelectOptions
          }
        },
        advanced: {
          session_timeout: {
            defaultValue: session_timeout
          },
          idle_timeout: {
            defaultValue: idle_timeout
          },
          idle_threshold: {
            defaultValue: idle_threshold
          },
          exclusion_timeout: {
            defaultValue: exclusion_timeout
          },
          aaa_override_enabled: {
            defaultValue: aaa_override_enabled
          },
          proxy_arp_enabled: {
            defaultValue: proxy_arp_enabled
          },
          id_eogre_profile: {
            defaultValue: id_eogre_profile,
            selectOptions: eogreProfileSelectOptions
          },
          nas_id_1: {
            defaultValue: nas_id_1
          },
          nas_id_1_custom: {
            defaultValue: nas_id_1_custom
          },
          nas_id_2: {
            defaultValue: nas_id_2
          },
          nas_id_2_custom: {
            defaultValue: nas_id_2_custom
          },
          nas_id_3: {
            defaultValue: nas_id_3
          },
          nas_id_3_custom: {
            defaultValue: nas_id_3_custom
          },
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
