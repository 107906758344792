import { render, staticRenderFns } from "./ProductBuildInfo.vue?vue&type=template&id=65ed61e5&scoped=true&"
import script from "./ProductBuildInfo.vue?vue&type=script&lang=js&"
export * from "./ProductBuildInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProductBuildInfo.vue?vue&type=style&index=0&id=65ed61e5&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ed61e5",
  null
  
)

export default component.exports