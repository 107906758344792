<template>
  <div>
    <router-view></router-view>
    <api-loading-status-indicator/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import operationsService from '@/services/operationsService';
import { apiStatusNotificationMixin, themeWatcherMixin } from '@/mixins';
import { ApiLoadingStatusIndicator } from './components/UI';

const { mapActions: localeMapActions } = createNamespacedHelpers('locale');
const { mapActions: themeMapActions } = createNamespacedHelpers('theme');

export default {
  name: 'app',
  components: {
    ApiLoadingStatusIndicator
  },
  mixins: [apiStatusNotificationMixin, themeWatcherMixin],
  data() {
    return {
      currentBuildHash: '{{{{{%POST_BUILD_ENTERS_HASH_HERE%}}}}}' // заменняется хешем текущего проекта при сборке через npm build (скриптом post-build.js)
    };
  },
  methods: {
    ...localeMapActions(['initLocale']),
    ...themeMapActions(['initTheme'])
  },
  created() {
    if (this.$route.path != '/pages/login') {
      operationsService.getPedingOperations();
    }
    this.$store.commit('setCurrentBuildHash', this.currentBuildHash);
  },
  mounted() {
    this.initLocale({});
    this.initTheme();

    // todo refactor
    // прячем прелоадер, которй прописан в /static/index.html
    // отрефакторить так, чтобы по всему проекту не нужно было удалять эти классы
    // данный код встречается в других местах. Удалить и там
    document.body.classList.add('loaded-preloader');
    document.body.classList.add('loaded_hiding-preloader');
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

@import url('./styles/themes/default/light.css');
@import url('./styles/themes/default/dark.css');
@import url('./styles/themes/beeline/light.css');
@import url('./styles/themes/beeline/dark.css');
@import url('./styles/themes/mts/light.css');
@import url('./styles/themes/mts/dark.css');

* {
  outline: none !important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a,
button {
  cursor: pointer;
  position: relative;
}

.main {
  overflow: hidden;
  overflow-x: auto;
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none;
}

.progress {
  /*min-width: 250px;*/
  border-radius: 7px;
  line-height: 1.2rem;
}

.progress-bar {
  height: 1.2rem;
}

.progress-bar__notzero {
  min-width: 26px;
}

.loader {
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.8);

  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #1bb3a0 transparent #1bb3a0 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &--mini:after {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
  }

  &-backdrop {
    position: absolute;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotateZ(-360deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
}

.app-header.navbar {
  // min-width: 730px;
}

.breadcrumb {
  display: none;
}

.progress-bar.active {
  animation: progress-bar-stripes 1.5s linear infinite;
}

.disabled-card {
  z-index: 1000;
  background-color: lightgrey;
  opacity: 0.6;
  pointer-events: none;
}

/*.badge{*/
/*font-size: 70%;*/
/*}*/

.badge-client,
.badge-wlan,
.badge-cpe {
  position: relative;
  word-break: break-all;
  /*white-space: nowrap;*/
}

.badge-clear {
  padding: 0.25em 0.2em;
  font-weight: 600;
  /*background-color: rgba(0,0,0,.01);*/
  color: rgba(0, 0, 0, 0.6);
  font-size: 100%;
}

.badge-status {
  &:after {
    content: '';
    position: absolute;
    left: -15px;
    top: 5px;
    display: inline-block !important;
    width: 10px;
    height: 10px;
    margin-right: 0.25rem;
    border: 1px solid #fff;
    border-radius: 50em;
  }
}

.badge-client.badge-status:after {
  left: -15px;
  top: 4px;
}

.badge-status__connected:after {
  background-color: #4dbd74 !important;
}

.badge-status__disconnected:after {
  background-color: #bcc5d0 !important;
}

.badge-status__pending:after {
  background-color: #bcc5d0 !important;
}

.badge-status__pending {
  background-color: #bcc5d0 !important;
}

.badge-status__updating {
  background-color: #63c2de !important;
}
.badge-status__updating:after {
  background-color: #63c2de !important;
}

.badge-status__client-disconnected {
  background-color: #bcc5d0 !important;
}

.badge-dark {
  background-color: #4f5a66;
}

.badge-light {
  opacity: 0.8;
}

.badge-sidebar {
  position: relative;
  top: 4px;
  right: -2px;

  font-size: 75%;
  padding: 0.2em 0.4em;

  &__vertical {
    top: -8px;
  }
  &.badge-default {
    background-color: #a9b2bd;
  }
}

.cpe-logo {
  vertical-align: baseline;
  height: 15px;
  margin-right: 0.3rem;
  position: relative;
  top: 2px;
}

.cpe-icon {
  vertical-align: baseline;
  height: 16px;
  margin-right: 0.3rem;
  position: relative;
}

.cpe-badge {
  height: 11px;
  vertical-align: text-bottom;
}

// Vee-Validate style
.help {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;

  &.is-danger {
    color: #ff3860;
  }
  &.is-warning {
    color: orange;
  }
}

.input {
  &.is-danger {
    border-color: #ff3860;
  }
  &.is-warning {
    border-color: orange;
  }
}

.error_tooltip {
  position: absolute;
  right: 8px;
  top: 0px;

  cursor: pointer;
  z-index: 1000;
}

.tooltip {
  z-index: 1000000 !important;
}

.edit-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: 0.25rem;

  & .btn-sm {
    padding: 0.18rem 0.35rem;
    font-size: 0.675rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.edit-header__info {
  display: flex;
  justify-content: space-between;
}

.edit-header__name {
  height: fit-content;
  position: relative;
  word-break: break-all;

  margin-right: 1rem;
}

.edit-header__tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.edit-header__buttons {
  display: flex;
  justify-content: flex-end;

  min-width: 150px;
}

//  Avatar style
.navbar .dropdown-toggle .img-avatar {
  height: 40px;
  width: 40px;
  margin: 0 10px;
  border-radius: 100%;
  object-fit: cover;
}

// Buttons
.actions-buttons {
  padding: 1.1rem;
  display: flex;
  justify-content: space-between;
}

.actions-buttons__multiple {
  padding: 1.1rem 0;
}

.actions-buttons__operator {
  justify-content: flex-end;
}

//Filters fields
.filter-block {
  display: flex;
  justify-content: space-between;

  padding-right: 15px;
  padding-left: 15px;
}
.filters {
  display: flex;
  flex-direction: column;

  & i.fa-circle-o-notch {
    color: #20a8d8;
  }
}

.filters__radius {
  flex-direction: row;
}

.filters__search {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.65rem;

  & input {
    padding-right: 25px !important;
  }
}

.filters__multiple {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.5em;
  /*justify-content: space-between;*/
}

.filters__multiple .btn:hover,
.filters__multiple .btn.active:hover {
  opacity: 0.8;
}

.filters__field {
  height: 27px !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  padding-right: 25px !important;
}

.filters__field-icon {
  height: 27px;
}

.filters__input {
  width: 100%;
  min-width: 130px;

  &--clients {
    max-width: 100%;
  }
}

.filters__wlan {
  width: 100%;
  min-width: 200px;
}

.filters__sort {
  width: 100%;
  min-width: 200px;
}

//Table
.table-row {
  transition: background 0.15s;
  cursor: pointer;
}

.table-row.selected {
  background: #d9e3ec;
}

.table-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.table-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.table th,
.table td {
  vertical-align: middle;
}

.td-name {
  word-break: break-all;
}

.description-row {
  max-height: 65px;
  max-width: 250px;
  overflow-y: auto;
}

.row-icon {
  height: 30px;
}
.responsive-row {
  word-break: break-all;
  word-wrap: break-word;
}

// MultiSelect additional styling
.multiselect__tag {
  max-width: 100%;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.multiselect__tags {
  border-radius: 0;
}

.multiselect__tag span {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.multiselect__content {
  z-index: 3;
}

.multiselect-disabled .multiselect__tags {
  background-color: #e3e8ec !important;
}

.multiselect-disabled .multiselect__input {
  background-color: #e3e8ec !important;
}

.multiselect-disabled-field {
  cursor: not-allowed;
}

.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__tag-icon:after {
  content: '✕';
  font-size: 12px;
}

.multiselect__tag-icon {
  line-height: 23px;
}

// Switch style for disabled
.switch-disabled {
  cursor: not-allowed !important;
}

.switch.switch-text.switch-disabled .switch-label {
  background-color: #e3e8ec !important;
}

.switch-disabled .switch-label:before {
  color: #ccc !important;
  cursor: not-allowed;
}

// Select style for disabled
.select-disabled {
  background-color: #e3e8ec !important;
}

// Custom header
header.navbar .nav-user {
  margin-right: 30px !important;
}

header.navbar .nav-info {
  margin-left: 30px !important;
}

header.navbar .nav-info a {
  padding: 0px 15px;
}

@media (max-width: 730px) {
  header.navbar .nav-info {
    display: none;
  }
  .app-header .navbar-brand {
    margin-left: 74px !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 321px) {
  header.navbar .nav-info {
    display: none;
  }
  header.navbar .navbar-brand {
    display: none !important;
  }
}

@media (max-width: 991px) {
  /*Nav*/
  .app-header .navbar-brand {
    width: auto;
  }
  .app-header .navbar-nav {
    position: relative !important;
  }
  .nav-info {
    white-space: nowrap;
  }
  .app-header .navbar-toggler {
    position: relative;
  }

  /* Table */
  .table-responsive-custom {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
  }
}

// Vue-slider
.vue-slider-wrap {
  z-index: 1;
}

// Custom notification
.mini-toastr {
  margin-top: 45px !important;
}

.mini-toastr-notification__message {
  display: block !important;
}

// HC

.highcharts-credits {
  display: none;
}

.highcharts-tick {
  display: none;
}

// Date Picker
.filter-date {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.filter-date__small {
  font-size: 72%;
}

.date-picker-button {
  margin-bottom: 0px !important;
}

.range_inputs {
  margin-top: 1rem;
}

.disableDP {
  background-color: #ccc !important;
  border-color: #ccc !important;
  padding: 0.25rem 0.5rem;
  cursor: default !important;
}

.btn-xsm {
  padding: 0.2rem 0.3rem;
  font-size: 0.675rem;
}

//Modal

.modal-btn-right {
  display: flex;
  justify-content: flex-end !important;
}

//Icon
.icon-sort {
  cursor: pointer;
}

//Custom select
.hourselect,
.minuteselect {
  padding-left: 10px;
}

.bg-default {
  color: #fff !important;
  background-color: #bcc5d0 !important;
}

//Tags
.tag {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1.1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  opacity: 0.9;
  border-radius: 10rem;
  padding-right: 0.7em;
  padding-left: 0.6em;

  cursor: pointer;
  transition: background 0.3s;
}

.modal-tags .tag {
  font-size: 80%;
}

.edit-header .tag {
  font-size: 70%;
  margin: 1px;
}
.tag--item {
  background-color: #bcc5d0;
}

.cpes-list,
.wlans-list {
  & .tag--item:hover {
    background-color: #20a8d8;
  }
  & .tag--item:hover .fa-tag {
    display: none;
  }
  & .tag--item:hover .fa-search {
    display: inline-block;
  }
  & .tag .fa-search {
    display: none;
  }
}

.cpes-edit,
.wlans-edit,
.modal-tags {
  & .tag--item:hover {
    background-color: #f86c6b;
  }
  & .tag--item:hover {
    background-color: #f86c6b;
  }
  & .tag--item:hover .fa-tag {
    display: none;
  }
  & .tag--item:hover .fa-remove {
    display: inline-block;
  }
  & .tag .fa-remove {
    display: none;
  }

  // Disable mode
  & .tag--disable.tag--item:hover .fa-tag {
    display: inline-block;
  }
  & .tag--disable.tag--item:hover {
    background-color: #39b2d5;
  }
  & .tag--disable.tag--item:hover .fa-remove {
    display: none;
  }
  & .tag--disable.tag--item {
    cursor: default;
  }
}

.tag--default {
  opacity: 0.7;
  cursor: default;
  background-color: #bcc5d0;
}
.tag--add {
  color: #4dbd74;
  background-color: #ffffff;
  border: 1px solid #4dbd74;
}
.tag i {
  width: 13px;
  margin-right: 3px;
}

.tag--add:hover {
  color: #ffffff;
  background-color: #4dbd74;
}

.tag--add .fa-plus {
  display: none;
}

.tag--add:hover .fa-plus {
  display: inline-block;
}
.tag--add:hover .fa-tag {
  display: none;
}

.clear-input {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  z-index: 10;

  & i.fa-remove {
    cursor: pointer;
  }

  &:hover i.fa-remove {
    transform: scale(1.2);
  }
}

.row-tags {
  display: flex;
  flex-wrap: wrap;
}

.row-tags .tag {
  margin-bottom: 0.25rem;
  font-size: 60%;
}

.hr-settings {
  border-color: #ffffff;
}

.setting-title {
  margin-top: 1rem;
  display: block;
}

label.switch ~ span {
  font-weight: 500;
}

button {
  border-radius: 0;
}

.summary p {
  margin-bottom: 0.5rem;
}

.radio-data {
  padding-right: 0.2rem;
  white-space: pre-line;
  font-weight: 600;
}

.uuid-block {
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  font-size: 90%;
  width: 280px;
  cursor: pointer;
}
.uuid {
  display: block;
}

.uuid-block:hover .uuid {
  color: #f86c6b;
}

.uuid-block i {
  position: relative;
  margin-right: 0.5rem;
  transition: transform 0.2s;
  top: 2px;
}
.uuid-block:hover i {
  transform: scale(1.4);
}

.multiselect--active {
  z-index: 1000;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.border-danger {
  border-color: #f86c6b !important;
}
.border-success {
  border-color: #4dbd74 !important;
}
.border-secondary {
  border-color: #bcc5d0 !important;
}

.tooltip-text {
  cursor: pointer;
  border-bottom: 1px dashed;
}
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: #444;
    color: #f9f9f9;
    border-radius: 6px;
    padding: 5px 10px 4px;
    font-size: 13px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #444;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

// custom checkbox
.custom-control {
  cursor: pointer;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control__disabled {
  cursor: not-allowed;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #20a8d8;
}

.custom-control-description {
  margin-left: 0.55rem;
}

.custom-control-indicator {
  position: absolute;
  top: 0.2rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url(/static/img/checked.svg);
}

select {
  -moz-appearance: none;
}

.form-control {
  color: #7f8a9b !important;
}

.action-delete {
  cursor: pointer;
}

// style fot items list (mac, ip , etc
.items_list {
  list-style: none;
  flex-direction: column;

  max-height: 100px;
  overflow-y: scroll;

  & li {
    line-height: 13px;
  }
}

.item-for-delete {
  align-items: baseline;

  font-size: 80%;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    color: #f86c6b !important;
  }

  & i {
    margin-left: 10px;
    transition: all 0.2s;
    opacity: 0.6;
    transition: all 0.2s;
  }

  &:hover i {
    opacity: 1;
    /*transform: scale(1.4);*/
  }

  &--disable:hover {
    cursor: default;
    color: inherit !important;
  }
}

.form-group {
  position: relative;
}

.settings-block {
  border-radius: 10px;
  padding: 15px;
  background: rgba(236, 236, 236, 0.45);
}
.icon-button-hover:hover {
  cursor: pointer;
  color: #9e9e9e;
  transition: color 0.2s ease-in-out;
}
.icon-button-hover {
  transition: color 0.2s ease-in-out;
}
.no-scroll-bar {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll-bar ::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
}

.overflowY-hidden {
  overflow-y: hidden !important;
}
.overflowY-auto {
  overflow-y: auto !important;
}
.overflowX-hidden {
  overflow-x: hidden !important;
}
.overflowX-auto {
  overflow-x: auto !important;
}

/*стили для cкругления углов и доавления теней ( модернизации внешнего вида ) (задача WNE-2454) */

/*перменные*/
$border-radius-for-new-style-blocks: 25px;
$border-radius-for-new-style-btns: 10px;
$border-radius-for-new-style-btns-in-filters: 7px;
$border-radius-for-new-style-btns-in-edit-panel: 15px;

/*создаем  наборы свойств для дальнейшего наследования*/
/*кнопки*/
%new-modern-style-btn {
  border-radius: $border-radius-for-new-style-btns !important;
  transition: 0.2s ease-in-out;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
}

%new-modern-style-button-clear-shadow {
  box-shadow: none !important;
}

/*кнопки, расположенные справа от полей ввода данных, чтобы были не вполотную*/
%new-modern-style-button-after-input-fields-or-select-fields-in-same-string {
  margin-left: 0.25em;
}

/*блоки*/
%new-modern-style-block-shadow {
  box-shadow: 0 30px 30px -20px rgb(0 0 0 / 20%);
}

%new-modern-style-block-border-radius {
  border-radius: $border-radius-for-new-style-blocks;
}

%new-modern-style-block-in-block-shadow {
  box-shadow: 0 10px 10px -10px rgb(0 0 0 / 20%);
}

%new-modern-style-block-clear-shdow-and-border-radius {
  box-shadow: none;
  border-radius: 0px;
}

%new-modern-style-button-clear-shdow-and-border-radius {
  box-shadow: none !important;
  border-radius: 0px !important;
}

/*бейджики*/
%new-modern-style-badge {
  border-radius: 3px !important;
  /*box-shadow: 2px 2px 3px rgba(0, 0, 0, .15);*/
}
/*выбарное (бейджики) внутри мультиселектора*/
%new-modern-style-badge-in-multiselect {
  border-radius: 6px !important;
}

/*применяем стили*/

/*сайдбар*/
.sidebar {
  /*бейджики */
  /*добавляем */
  .badge {
    @extend %new-modern-style-badge;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  }
}

/*основнрй блок*/
main.main {
  /*кнопки*/
  /*добавляем */
  .btn {
    @extend %new-modern-style-btn;
  }

  .btn:hover {
    @extend %new-modern-style-btn;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .btn:active {
    @extend %new-modern-style-btn;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
  }

  .btn-group .btn {
    @extend %new-modern-style-btn;
    border-radius: $border-radius-for-new-style-btns-in-filters !important;
    margin-right: 0.25em;

    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    /*box-shadow: inherit !important;*/
  }

  .btn-group .btn:active {
    @extend %new-modern-style-btn;
    border-radius: $border-radius-for-new-style-btns-in-filters !important;
    box-shadow: -1px -1px -1px rgba(0, 0, 0, 0.15);
    /*box-shadow: inherit !important;*/
  }

  /*<!--  .btn-group .btn:first-child {-->*/
  /*<!--  @extend %new-modern-style-btn;-->*/
  /*<!--  border-radius: $border-radius-for-new-style-btns 0px 0px $border-radius-for-new-style-btns !important;-->*/

  /*<!--}-->*/

  /*<!--  .btn-group .btn:last-child {-->*/
  /*<!--  @extend %new-modern-style-btn;-->*/
  /*<!--  border-radius: 0px $border-radius-for-new-style-btns $border-radius-for-new-style-btns 0px !important;-->*/

  /*<!--}-->*/

  .new-modern-style-btn-in-filters {
    @extend %new-modern-style-button-clear-shdow-and-border-radius;
    border-radius: $border-radius-for-new-style-btns !important;
  }

  .new-modern-style-btn-close-panel {
    @extend %new-modern-style-button-clear-shdow-and-border-radius;
    border-radius: $border-radius-for-new-style-btns !important;
  }
  .new-modern-style-btn-in-edit-panel {
    @extend %new-modern-style-button-clear-shdow-and-border-radius;
    border-radius: $border-radius-for-new-style-btns-in-edit-panel !important;
  }

  .new-modern-style-btn-load-more-btn,
  .new-modern-style-btn-load-more-btn:hover,
  .new-modern-style-btn-load-more-btn:active {
    @extend %new-modern-style-button-clear-shdow-and-border-radius;
    border-radius: $border-radius-for-new-style-btns !important;
  }
  .new-modern-style-btn-other-btn,
  .new-modern-style-btn-other-btn:hover,
  .new-modern-style-btn-other-btn:active {
    @extend %new-modern-style-button-clear-shdow-and-border-radius;
    border-radius: $border-radius-for-new-style-btns !important;
  }

  /*card*/
  /*добавляем */
  .card {
    @extend %new-modern-style-block-shadow;
    @extend %new-modern-style-block-border-radius;
  }

  .card .btn {
    @extend %new-modern-style-button-clear-shadow;
  }

  .card-header {
    @extend %new-modern-style-block-border-radius;
    border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
  }
  /*для раскрывающихся и закрываюихся блоков (например на главном дашборде)*/
  .folding-card-header-when-not-open {
    border-radius: $border-radius-for-new-style-blocks !important;
  }

  .card-footer {
    @extend %new-modern-style-block-border-radius;
    border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
  }

  .card .card {
    /*card внутри card*/
    @extend %new-modern-style-block-in-block-shadow;
    @extend %new-modern-style-block-border-radius;
  }
  /*убираем потом где добавилось но не нужно*/
  .card .table-column-switcher .btn {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }

  .card .interface-block-header .btn {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }

  .card .setting-block-header .btn {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }

  .portal-dashboard-calendar .card {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }
  .events-list .card {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }

  /* другие места */
  .new-modern-style-data-iterator-card {
    @extend %new-modern-style-block-shadow;
    @extend %new-modern-style-block-border-radius;
  }
  .new-modern-style-data-loader-spiner {
    @extend %new-modern-style-block-border-radius;
  }

  /*tab-content*/
  /*добавляем*/
  .tab-content {
    @extend %new-modern-style-block-shadow;
    @extend %new-modern-style-block-border-radius;
  }

  .tab-content .btn {
    @extend %new-modern-style-button-clear-shadow;
  }

  .nav.nav-tabs.row {
    border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
  }
  /*убираем*/
  .clear-background-and-clear-padding-for-clients-rf {
    @extend %new-modern-style-block-clear-shdow-and-border-radius;
  }

  /*сами табы*/
  ul.nav.nav-tabs {
    li.nav-item {
      a {
        @extend %new-modern-style-block-border-radius;
        border-radius: ($border-radius-for-new-style-blocks * 0.5) ($border-radius-for-new-style-blocks * 0.5) 0px 0px;
      }
    }
  }

  /*бейджики */
  /*добавляем */
  .badge {
    @extend %new-modern-style-badge;
  }
  .client-state__advanced .badge {
    margin-right: 0.25em;
  }

  /*модалка */
  /*добавляем */
  .modal-content {
    @extend %new-modern-style-block-border-radius;
  }

  .modal-content .btn {
    @extend %new-modern-style-button-clear-shadow;
  }

  .modal-content .modal-header {
    border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
  }

  .modal-content .modal-footer {
    border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
  }

  /*категории в базе знаний*/
  /* первый раздел, есть всегда, поэтому применяем к нему всегда*/
  .folding-category-new-style-glossary {
    .category {
      @extend %new-modern-style-block-border-radius;
      border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
    }
  }
  /*остальные разделы, могут быть или не быть в зависимости от сборки. поэтому примекняем закруглёные
  стили к последнему, причем если только он закрыт*/
  .folding-category-new-style-other-categories-wrapper div:last-child.closed-folding-category {
    .category {
      @extend %new-modern-style-block-border-radius;
      border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
    }
  }

  /* блок для скачивания файлов */
  .dropdown-extract {
    .dropdown-menu.dropdown-extract__menu {
      @extend %new-modern-style-block-border-radius;
      @extend %new-modern-style-block-shadow;
      padding: 20px;
    }
  }
  /*блок в разделе с лицензиями  */
  .licenses-information-wrapper-block {
    div.social-box,
    i.bg-info {
      border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
    }
  }
  /*табы и таблица в разделе Правила*/
  .tab-content.left-tab-selected-in-rules-page {
    @extend %new-modern-style-block-border-radius;
    border-radius: 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks
      $border-radius-for-new-style-blocks !important;
  }
  ul.nav.nav-tabs.tabs-in-rules-page {
    &.row {
      @extend %new-modern-style-block-clear-shdow-and-border-radius;
      border: 0px !important;
    }
  }
  /*кнопки, расположенные справа от полей ввода данных, чтобы были не вполотную*/
  .input-group {
    /* случай с input слева от кнопки */
    input ~ span.input-group-btn {
      button {
        @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
        /*border-radius: 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px !important;*/
      }
    }
    input ~ div.input-group-btn {
      button {
        @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
        /*border-radius: 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px !important;*/
      }
    }
    /* случай с select слева от кнопки */
    select ~ span.input-group-btn {
      button {
        @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
        /*border-radius: 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px !important;*/
      }
    }
    select ~ div.input-group-btn {
      button {
        @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
        /*border-radius: 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px !important;*/
      }
    }
    .prefix-optional-in-portal-profiles ~ button {
      @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
    }
  }
  #portal_url_input ~ button {
    @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
  }
  .input-field-near-button-in-wifi-target ~ button,
  .input-field-near-button-in-wifi-target ~ span button {
    @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
  }
  input ~ button.button-after-input-field-or-select-field-in-same-string {
    @extend %new-modern-style-button-after-input-fields-or-select-fields-in-same-string;
  }
  /* закончились селекторы для кнопок, расположенных справа вплотную от полей ввода данных*/
}
/*хэдер*/
.app-header {
  /*кнопка выхода из режима логина как подчиненый*/
  .logout-as-subordinate-button {
    border-radius: $border-radius-for-new-style-btns !important;
  }
  /*список-окошко с текущими операциями (после нажатии я на колокольцик в хэдере)*/
  .operations {
    @extend %new-modern-style-block-border-radius;
    @extend %new-modern-style-block-shadow;

    .dropdown-header {
      @extend %new-modern-style-block-border-radius;
      border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
    }
  }
  /*окошко интеревал обновления (после нажатиия на крутящиеся стрелочки в хэдере)*/
  .refresh {
    @extend %new-modern-style-block-border-radius;
    @extend %new-modern-style-block-shadow;
    .dropdown-header {
      @extend %new-modern-style-block-border-radius;
      border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
    }
    .refresh-block {
      border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
    }
  }
  /*окошко с простмотром информации о разделе*/
  .view-tooltip__message {
    @extend %new-modern-style-block-border-radius;
    @extend %new-modern-style-block-shadow;
  }

  /*свитчер локализации*/
  .locale-switcher:hover {
    .locale-switcher-wrapper {
      @extend %new-modern-style-block-border-radius;
      @extend %new-modern-style-block-shadow;

      .locale-list-item {
        @extend %new-modern-style-block-border-radius;
      }
    }
  }
  // менюшка с настройками при клике на аву аккаунта в верхнем правом углу
  // где настройки и выход
  .dropdown-menu-in-header {
    @extend %new-modern-style-block-border-radius;
    @extend %new-modern-style-block-shadow;

    .dropdown-header {
      @extend %new-modern-style-block-border-radius;
      border-radius: $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks 0px 0px;
    }
    .dropdown-menu-in-header-logout {
      @extend %new-modern-style-block-border-radius;
      border-radius: 0px 0px $border-radius-for-new-style-blocks $border-radius-for-new-style-blocks;
    }
  }
}

/*вне разделов*/
/*дэйтпикер*/
.mx-datepicker-main.mx-datepicker-popup {
  @extend %new-modern-style-block-border-radius;
  @extend %new-modern-style-block-shadow;
  button {
    @extend %new-modern-style-btn;
    @extend %new-modern-style-button-clear-shadow;
  }
  .datepicker-custom__container button {
    margin-left: 0.45em;
  }
  .mx-datepicker-sidebar {
    padding-left: 10px;
    padding-top: 12px;
  }
  .mx-datepicker-btn-confirm {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
/*компонент Alert*/
div.alert.alert-success,
div.alert.alert-info,
div.alert.alert-warning,
div.alert.alert-danger {
  border-radius: $border-radius-for-new-style-blocks * 0.5;
}

// выбраное внутри мультиселекторов (фактически теги)
.multiselect {
  .multiselect__tags {
    .multiselect__tags-wrap {
      .multiselect__tag {
        @extend %new-modern-style-badge-in-multiselect;
      }
    }
  }
}

label.btn>input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
</style>
