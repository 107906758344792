import { v4 as uuidv4 } from 'uuid';
import RouteGroups from '@/access-control/RouteGroups';

// https://wimark.kaiten.ru/space/461929/boards/card/43834115 - было принято решение полностью убрать EasyInstallWizard
// Если не пригодится в будущем, то следует полностью убрать (23.01.2025)
function showEasyInstallWizard({ thisInstance, $can }) {
  if (!thisInstance.isOneExperimentalModeEnabled) {
    return false;
  }
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Dashboard' })
    && thisInstance.isAdminRole
    && thisInstance.isContentPortalEnabled;
}

function showMainSectionNMSAnalytic({ thisInstance, $can }) {
  return (
    thisInstance.isContentAnalyticsEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.ANALYTICS)
  ) && (
    $can('read', { route: 'Marketing' })
    || $can('read', { route: 'maps' })
    || $can('read', { route: 'Radar' })
  );
}

function showMainSectionNMSMonitor({ thisInstance, $can }) {
  return thisInstance.$canSomeInGroup('read', RouteGroups.MONITORING)
    && (
      ($can('read', { route: 'ClientDistance' }) && thisInstance.isOneExperimentalModeEnabled)
      || ($can('read', { route: 'DITExport' }) && thisInstance.isBeelineBranding) || $can('read', { route: 'PeriodicTasks' })
    );
}

function showMainSectionNMSStatistic({ thisInstance, $can }) {
  return thisInstance.$canSomeInGroup('read', RouteGroups.STATISTICS)
    && (
      $can('read', { route: 'Statsystem' })
      || $can('read', { route: 'Statwlans' })
      || $can('read', { route: 'Statreports' })
      || $can('read', { route: 'SNMPStat' })
    );
}

function showMainSectionNMSGeneral({ thisInstance, $can }) {
  return $can('read', { route: 'Support' })
    || (
      $can('read', { route: 'Dashboard' })
      && !thisInstance.isPortalOnlyModeEnabled
      && thisInstance.isContentPortalEnabled
      && thisInstance.isAdminRole
    )
    || (
      thisInstance.isCiscoModeEnabled
      && $can('read', { route: 'Controllers' })
    );
}

function showMainSectionsNMS({ thisInstance, $can }) {
  if (!thisInstance.isOneExperimentalModeEnabled) {
    return false;
  }

  return showMainSectionNMSGeneral({ thisInstance, $can })
    || showMainSectionNMSStatistic({ thisInstance, $can })
    || showMainSectionNMSMonitor({ thisInstance, $can })
    || showMainSectionNMSAnalytic({ thisInstance, $can });
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getNms = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'NMS',
    title: 'NMS',
    icon: 'fa fa-bookmark fa-fw fa-lg',
    to: false,
    selected: false,
    role: 'root',
    isShow: showMainSectionsNMS({ thisInstance, $can }),
    children: [
      // Statistics
      {
        id: uuidv4(),
        name: 'statistics',
        title: $t('sidebarNew.statistics'),
        icon: 'fa fa-bar-chart fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionNMSStatistic({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'locations',
            title: $t('sidebarNew.locations'),
            icon: '',
            to: '/statistics/statsystem',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Statsystem' }) && thisInstance.areLocationsVisible,
            children: []
          },
          {
            id: uuidv4(),
            name: 'wlans',
            title: $t('sidebarNew.wlans'),
            icon: '',
            to: '/statistics/statwlans',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Statwlans' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'reports',
            title: $t('sidebarNew.reports'),
            icon: '',
            to: '/statistics/statreports',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Statreports' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'SNMPStat',
            title: $t('sidebarNew.SNMPStat'),
            icon: '',
            to: '/statistics/snmpstat',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'SNMPStat' }) && thisInstance.isOneExperimentalModeEnabled,
            children: []
          }
        ]
      },
      // Monitor
      {
        id: uuidv4(),
        name: 'monitor',
        title: $t('sidebarNew.monitor'),
        icon: 'fa fa-lightbulb-o fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionNMSMonitor({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'clientDistance',
            title: $t('sidebarNew.clientDistance'),
            icon: '',
            to: '/monitor/client-distance',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'ClientDistance' }) && thisInstance.isOneExperimentalModeEnabled,
            children: []
          },
          {
            id: uuidv4(),
            name: 'DITExport',
            title: $t('sidebarNew.DITExport'),
            icon: '',
            to: '/monitor/dit-statistic',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'DITExport' }) && thisInstance.isBeelineBranding,
            children: []
          },
          {
            id: uuidv4(),
            name: 'PeriodicTasks',
            title: $t('sidebarNew.PeriodicTasks'),
            icon: '',
            to: '/monitor/periodic-tasks',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PeriodicTasks' }),
            children: []
          }
        ]
      },
      // Analytics
      {
        id: uuidv4(),
        name: 'analytics',
        title: $t('sidebarNew.analytics'),
        icon: 'fa fa-table fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionNMSAnalytic({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'visitors',
            title: $t('sidebarNew.visitors'),
            icon: '',
            to: '/marketing',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Marketing' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'heatmap',
            title: $t('sidebarNew.heatmap'),
            icon: '',
            to: '/maps',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'maps' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'radar',
            title: $t('sidebarNew.radar'),
            icon: '',
            to: '/target',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Radar' }),
            children: []
          }
        ]
      },
      {
        id: uuidv4(),
        name: 'support',
        title: $t('sidebarNew.support'),
        icon: '',
        to: '/support',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Support' }),
        children: []
      },
      {
        id: uuidv4(),
        name: 'easyInstallWizard',
        title: $t('easyInstallWizard.easyInstallButtonInSidebar'),
        icon: '',
        to: '/easy-install-wizard',
        selected: false,
        role: 'link',
        isShow: showEasyInstallWizard({ thisInstance, $can }),
        children: []
      },
      {
        id: uuidv4(),
        name: 'controllers',
        title: $t('sidebarNew.controllers'),
        icon: '',
        to: '/controllers',
        selected: false,
        role: 'link',
        isShow: thisInstance.isCiscoModeEnabled && $can('read', { route: 'Controllers' }),
        children: []
      }
    ]
  }
);

export default getNms;
