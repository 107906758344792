<template>
  <VlanGroupForm
    :formConfig="formConfig"
    :forbiddenVlanGroupNames="forbiddenVlanGroupNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </VlanGroupForm>
</template>

<script>
/**
 * компонент настроенной формы VlanGroupForm через generateFormConfig для создания нового vlan group.
 * ! Компонент локальный, используется только в CreateVlanGroupModal.vue
 * @component
 */

import {
  VlanGroupForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateVlanGroupForm',
  components: {
    VlanGroupForm
  },
  props: {
    forbiddenVlanGroupNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
./features
