<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <!--<small class="text-muted">Basic parameters</small>-->
          <form action="">
            <div class="form-group mt-1">
              <!--<span class="h6 setting-title">Template Settings</span>-->
              <label for="name">{{ $t('general.name') }}</label>
              <input
                type="text"
                name="name"
                id="name"
                :placeholder="$t('template.templateNamePlaceHolder')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-model="newTemplateData.name"
              />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>

            <div class="form-group mt-1">
              <label for="description">{{ $t('aps.description') }}</label>
              <input
                type="text"
                id="description"
                :placeholder="$t('template.templateDescriptionPlaceHolder')"
                name="description"
                v-validate="'max:200'"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('description') }"
                v-model="newTemplateData.description"
              />
              <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
            </div>

            <div class="form-group" v-if="areLocationsVisible">
              <label for="location">{{ $t('general.baseLocation') }}</label>
              <select @change="changeLocationHandler" v-model="newTemplateData.base_location" name="location" class="form-control" id="location">
                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>

            <label for="model" class="mt-2">{{ $t('template.cpeModel') }}</label>
            <multiselect
              id="model"
              v-if="cpeModelsList"
              v-model="newTemplateData.model"
              :multiple="false"
              :options="cpeModelsList || []"
              label="description"
              track-by="id"
              class="mb-1"
              :close-on-select="true"
              :placeholder="$t('template.wizardSelectModelPlaceholder')"
              :selectLabel="$t('general.multiSelectPressToSelect')"
              :deselectLabel="$t('general.multiSelectDeselectLabel')"
              :selectedLabel="$t('general.multiSelectSelected')"
            ></multiselect>

            <label for="name">UUID</label>
            <div class="input-group">
              <input
                type="text"
                name="uuid"
                class="form-control input-tag"
                :placeholder="$t('template.templateAddUUIDPlaceHolder')"
                @keyup.13="addUUIID"
                data-vv-validate-on="input"
                v-validate="'uuid'"
                v-model="newUuid"
                :class="{ input: true, 'is-danger': errors.has('uuid') }"
              />
              <div class="input-group-btn">
                <WButton success
                  @click="addUUIID"
                  :disabled="this.newUuid == '' || errors.has('uuid')"
                >
                  {{ $t('general.add') }}
                </WButton>
              </div>
              <div class="input-group-btn fileUpload ml-1" v-tooltip.top-center="{ content: getCsvTip }">
                <WButton :danger="fileTypeError || invalidUuid">
                  <i class="fa fa-file-o"></i>
                </WButton>
                <input id="csv" @change="previewUuids" name="csv" type="file" class="upload btn btn-info mb-0" />
              </div>
            </div>
            <span v-if="fileTypeError" class="help is-danger">{{ $t('template.fieldMustBeCsvFile') }}</span>
            <span v-if="invalidUuid" class="help is-danger">{{ $t('template.wrongDataInFile') }}</span>
            <div class="mt-1 mb-1">
              <span
                class="uuid"
                @click.prevent="removeUuid"
                :data-index="index"
                v-for="(uuid, index) in newTemplateData.cpes"
              >
                {{ uuid }}
                <i class="fa fa-remove text-danger"></i>
              </span>
            </div>

            <div class="form-group mt-3">
              <label for="ipaddr">{{$t('general.ipField')}}</label>
              <input
                type="text"
                id="ipaddr"
                v-validate="'ipv4WithSpecialOctetsRangesForTemplate'"
                placeholder="X.X.X.X"
                name="ipaddr"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('ipaddr') }"
                v-model="newTemplateData.subnet.ipaddr"
              />
              <small>{{ $t('general.ipFieldHint') }}</small>
              <span v-show="errors.has('ipaddr')" class="help is-danger">{{ errors.first('ipaddr') }}</span>
            </div>

            <div class="form-group">
              <label for="ipaddr">{{ $t('template.networkMask') }}</label>
              <input
                type="text"
                id="netmask"
                v-validate="'subnetMask'"
                placeholder="X.X.X.X"
                name="netmask"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('netmask') }"
                v-model="newTemplateData.subnet.netmask"
              />
              <small>{{ $t('template.networkMaskHint') }}</small>
              <span v-show="errors.has('netmask')" class="help is-danger">{{ errors.first('netmask') }}</span>
            </div>

            <div class="form-group">
              <label for="mac_prefix">{{ $t('template.macPrefix') }}</label>
              <input
                type="text"
                id="mac_prefix"
                placeholder="XX:XX:XX:XX:XX:XX"
                v-validate="'macPrefix'"
                name="mac_prefix"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('mac_prefix') }"
                v-model="newTemplateData.mac_prefix"
              />
              <small>{{ $t('template.macPrefixHint') }}</small>
              <span v-show="errors.has('mac_prefix')" class="help is-danger">{{ errors.first('mac_prefix') }}</span>
            </div>

            <div class="form-group mb-2 mt-2">
              <Switch-component
                v-model="newTemplateData.is_auto"
                :label="$t('template.autoConfiguration')"
                @change="checkAuto"
                id="is-auto"
                key="is-auto"
              />
            </div>

            <div class="form-group mb-2 mt-2">
              <Switch-component
                v-model="newTemplateData.is_always"
                :disabled="!newTemplateData.is_auto"
                :label="$t('template.alwaysApply')"
                id="is-always"
                key="is-always"
              />
            </div>

            <div class="mt-2">
              <WButton outline
                customClass="cpe-register-next-button float-right"
                @click="setNewTemplateData"
                :disabled="errors.any()"
              >
                {{ $t('general.next') }}
              </WButton>
              <WButton secondary outline
                customClass="cpe-register-back-button float-left"
                @click="$parent.prevStep"
              >
                {{ $t('general.back') }}
              </WButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import Papa from 'papaparse';
import commonService from '../../services/commonService';
import helpers from '../../helpers';
import { getCsvTip } from '../../helpers/tooltip';
import SwitchComponent from '../../components/Universal/Switch-component.vue';

export default {
  components: { Multiselect, SwitchComponent },
  name: 'TemplatesStep1',
  data() {
    return {
      newTemplateData: {
        name: '',
        model: '',
        description: '',
        cpes: [],
        base_location: '',
        is_auto: true,
        is_always: false,
        subnet: {}
      },
      newUuid: '',
      invalidUuid: false,
      fileTypeError: false
    };
  },
  computed: {
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    cpeModelsList() {
      return this.$store.state.cpeModelsList;
    }
  },
  methods: {
    getCsvTip,
    setNewTemplateData() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.newUuid) {
              this.newTemplateData.cpes.push(this.newUuid);
            }
            this.$store.commit('setNewTemplateData', this.newTemplateData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    changeLocationHandler() {
      this.$nextTick(()=> {
        this.$store.commit('setSelectedOnTemplateWizStep1Location', this.newTemplateData.base_location);
      });
    },
    previewUuids(event) {
      const that = this;
      function showCsv() {
        const input = event.target;
        if (input.files && input.files[0] && input.files[0].type === 'text/csv') {
          Papa.parse(input.files[0], {
            complete(results) {
              const { data } = results;
              const uuids = data.reduce((acc, val) => acc.concat(val), []);
              const correctUuids = uuids.filter((id) => id.length === 36);

              const newUuidArray = new Set([...that.newTemplateData.cpes, ...correctUuids]);
              that.newTemplateData.cpes = Array.from(newUuidArray);

              that.invalidUuid = false;
              that.fileTypeError = false;
              input.value = '';
            }
          });
        } else {
          that.invalidUuid = false;
          that.fileTypeError = true;
        }
      }
      showCsv();
    },
    addUUIID() {
      this.$validator
        .validateAll({
          uuid: this.newUuid
        })
        .then((result) => {
          if (result) {
            if (this.newTemplateData.cpes.indexOf(this.newUuid) == -1) {
              this.newTemplateData.cpes.push(this.newUuid);
              this.newUuid = '';
            } else {
              this.newUuid = '';
            }
          }
        });
    },
    removeUuid(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTemplateData.cpes.splice(index, 1);
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    checkAuto() {
      if (!this.newTemplateData) {
        return;
      }

      if (!this.newTemplateData.is_auto) {
        this.newTemplateData.is_always = false;
      }
    }
  },
  created() {
    if (this.$store.state.newTemplateData) {
      this.newTemplateData = this.$store.state.newTemplateData;
    }
    this.newTemplateData.base_location = this.$store.state.userData.base_location;
  }
};
</script>
<style lang="scss">
.fileUpload:hover .btn-primary.btn-danger {
  background-color: #f63c3a;
  border-color: #f53231;
}
.fileUpload:hover .btn-primary {
  background-color: #1985ac;
  border-color: #187fa3;
}
</style>
