<template>
  <div class="wlan-policy-tab-input-group nas-id">
    <h3 v-if="config.label"
      class="wlan-policy-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <template v-for="input in config.inputs"
      class="wlan-policy-form-tab-input-group"
    >
      <template v-if="input.name === 'advanced.nas_id_1_custom'">
        <WInput v-if="formState['advanced.nas_id_1'] === 'custom_string'"
          data-vv-scope="nas_id_1_custom"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.name === 'advanced.nas_id_2_custom'">
        <WInput v-if="formState['advanced.nas_id_2'] === 'custom_string'"
          data-vv-scope="nas_id_2_custom"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.name === 'advanced.nas_id_3_custom'">
        <WInput v-if="formState['advanced.nas_id_3'] === 'custom_string'"
          data-vv-scope="nas_id_3_custom"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.inputType === 'select'">
        <WSelect
          withInitialOption
          :options="input.selectOptions"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else>
        <WInput
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </template>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба advanced - NAS Id.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'NasIdSettingsSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentNas_id_1() {
      return this.formState['advanced.nas_id_1'];
    },
    currentNas_id_2() {
      return this.formState['advanced.nas_id_2'];
    },
    currentNas_id_3() {
      return this.formState['advanced.nas_id_3'];
    }
  },
  watch: {
    currentNas_id_1(newVal) {
      // при nas_id_1 !== 'custom_string' - сброс значений и ошибок валидации в дефолтное значение
      if (newVal !== 'custom_string') {
        this.handleResetNas_id_1_custom();
      }
    },
    currentNas_id_2(newVal) {
      // при nas_id_2 !== 'custom_string' - сброс значений и ошибок валидации в дефолтное значение
      if (newVal !== 'custom_string') {
        this.handleResetNas_id_2_custom();
      }
    },
    currentNas_id_3(newVal) {
      // при nas_id_3 !== 'custom_string' - сброс значений и ошибок валидации в дефолтное значение
      if (newVal !== 'custom_string') {
        this.handleResetNas_id_3_custom();
      }
    }
  },
  methods: {
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetNas_id_1_custom() {
      this.$emit('update:inputValue', {
        inputName: 'advanced.nas_id_1_custom',
        value: ''
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'nas_id_1_custom',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetNas_id_2_custom() {
      this.$emit('update:inputValue', {
        inputName: 'advanced.nas_id_2_custom',
        value: ''
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'nas_id_2_custom',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetNas_id_3_custom() {
      this.$emit('update:inputValue', {
        inputName: 'advanced.nas_id_3_custom',
        value: ''
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'nas_id_3_custom',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
