const LOCALES_FILE = {
  en: {
    'accessControl.accessControl': 'Access settings',
    'accessControl.firewall': 'Firewall',
    'accessControl.wanBlockAccess': 'SSH / Web access block from WAN',
    'account.addEmail': 'add e-mail',
    'account.attention': 'Attention!',
    'account.attentionMessage': 'All user sessions of this client will be disabled. Confirm the password change?',
    'account.change': 'Change',
    'account.changePassword': 'Change password',
    'account.changePasswordButton': 'Change password',
    'account.confirm': 'Confirm',
    'account.confirmNewPassword': 'Confirm new password',
    'account.confirmNewPasswordPlaceholder': 'Re-enter the new password',
    'account.enterEmailPlaceholder': 'Enter an e-mail',
    'account.enterNewPasswordPlaceholder': 'Enter the new password',
    'account.enterOldPasswordPlaceholder': 'Enter old password',
    'account.newPassword': 'New password',
    'account.newPhoto': 'New photo',
    'account.photo': 'Photo',
    'account.oldPassword': 'Old password',
    'account.save': 'Save',
    'apjoin.pageName': 'AP Join',
    'apjoin.addAPJoinProfile': 'Add AP Join profile',
    'apjoin.changeAPJoinProfile': 'Edit AP Join profile',
    'apjoin.tabLabel.general': 'General',
    'apjoin.tabLabel.management': 'Management',
    'apjoin.tabLabel.statistics': 'Statistics',
    'apjoin.tabLabel.syslog': 'Syslog',
    'apjoin.tabLabel.timers': 'Timers',
    'apjoin.tabLabel.qualityOfService': 'Quality of service',
    'apjoin.inputLabel.profileName': 'Profile name',
    'apjoin.inputLabel.profileDescription': 'Profile description',
    'apjoin.inputLabel.ledState': 'Led state',
    'apjoin.inputLabel.mssSizeOfWanInterface': 'MSS size of WAN interface',
    'apjoin.inputLabel.mssState': 'Adjust MSS Enable',
    'apjoin.inputLabel.lldpCdpSupport': 'LDP\\CDP support',
    'apjoin.inputLabel.ipAdressOfNTPServer': 'IP adress of NTP Server',
    'apjoin.inputLabel.timezone': 'Timezone',
    'apjoin.inputLabel.timezone.selectOption.notConfigured': 'not configured',
    'apjoin.inputLabel.timezone.selectOption.wlc': 'WLC',
    'apjoin.inputLabel.timezone.selectOption.delta': 'delta from WLC',
    'apjoin.inputLabel.offsetHH': 'Offset (HH)',
    'apjoin.inputLabel.offsetMM': 'Offset (MM)',
    'apjoin.inputLabel.primaryController': 'Primary controller',
    'apjoin.inputLabel.secondaryController': 'Secondary controller',
    'apjoin.inputLabel.tertiaryController': 'Tertiary controller',
    'apjoin.inputLabel.sshSupport': 'SSH support',
    'apjoin.inputLabel.sshPort': 'SSH port',
    'apjoin.inputLabel.username': 'Username',
    'apjoin.inputLabel.password': 'Password',
    'apjoin.inputError.uniqueProfileName': 'Profile name is not unique',
    'apjoin.inputError.profileNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'apjoin.inputError.profileDescriptionMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces',
    'apjoin.inputError.managementUsernameMask': 'Wrong username format',
    'apjoin.inputError.managementPasswordMask': 'Wrong password format',
    'apjoin.inputError.sshPortMask': 'The SSH port field must be 22 or between 1024 and 49151',
    'apjoin.inputLabel.secretPassword': 'Secret password',
    'apjoin.tabText.sshLabel': 'Settings for connecting to an access point',
    'apjoin.tabText.warningCaption': 'If you disable SSH support and save this setting, the existing user will be deleted',
    'apjoin.tabText.passwordRulesDescription.1': 'The password must have english letters and number only',
    'apjoin.tabText.passwordRulesDescription.2': 'The password length is from 8 to 120 characters',
    'apjoin.tabText.passwordRulesDescription.3': 'At least one character in the password must be uppercase',
    'apjoin.tabText.passwordRulesDescription.4': 'At least one character in the password must be in lowercase',
    'apjoin.tabText.passwordRulesDescription.5': 'At least one character in the password must be a digit',
    'apjoin.tabText.retransmitTimersLabel': 'Retransmit timers',
    'apjoin.inputLabel.sendApStatistic': 'Send AP statistic',
    'apjoin.inputLabel.apStatiscticReportPeriod': 'AP statisctic report period (sec)',
    'apjoin.inputLabel.facilityValue': 'Facility Value',
    'apjoin.inputLabel.facilityValue.selectOption.none': 'none',
    'apjoin.inputLabel.facilityValue.selectOption.hostapd': 'hostapd',
    'apjoin.inputLabel.facilityValue.selectOption.kern': 'kern',
    'apjoin.inputLabel.facilityValue.selectOption.logread': 'logread',
    'apjoin.inputLabel.facilityValue.selectOption.cron': 'cron',
    'apjoin.inputLabel.facilityValue.selectOption.authpriv': 'authpriv',
    'apjoin.inputLabel.facilityValue.selectOption.ntp': 'ntp',
    'apjoin.inputLabel.facilityValue.selectOption.cpeagent': 'cpeagent',
    'apjoin.inputLabel.facilityValue.selectOption.daemon': 'daemon',
    'apjoin.inputLabel.facilityValue.selectOption.dhcpoptinj': 'dhcpoptinj',
    'apjoin.inputLabel.ipAdressOfRemoteServerForSyslogMessages': 'IP address of the remote server for syslog messages',
    'apjoin.inputLabel.logLevel': 'Log level',
    'apjoin.inputLabel.logLevel.selectOption.notice': 'Notice',
    'apjoin.inputLabel.logLevel.selectOption.informational': 'Informational',
    'apjoin.inputLabel.facilityValue.selectOption.debug': 'Debug',
    'apjoin.inputLabel.heartbeatTimeout': 'Heartbeat timeout (sec)',
    'apjoin.inputLabel.configurationRetransmissions': 'Number of configuration retransmissions',
    'apjoin.inputLabel.acceptanceInterval': 'Timeout for confirmation of configuration acceptance (sec)',
    'apjoin.inputLabel.trustDscpUpstream': 'Trust DSCP Upstream',
    'apjoin.tableHeading.id': 'Profile ID',
    'apjoin.tableHeading.name': 'AP Join profile name',
    'apjoin.tableHeading.description': 'Profile description',
    'apjoin.responseMessage.createAPsProfile.success': 'AP Join profile has been successfully added',
    'apjoin.responseMessage.updateAPsProfile.success': 'AP Join profile has been successfully updated',
    'apjoin.responseMessage.updateAPsProfile.error': 'AP Join profile hasn’t been successfully updated',
    'apjoin.responseMessage.deleteAPsProfile.success': 'AP Join profile has been successfully deleted',
    'apjoin.responseMessage.deleteAPsProfile.error': 'AP Join profile hasn’t been successfully deleted',
    'wlan.addWlanProfile': 'Add WLAN profile',
    'wlan.editWlanProfile': 'Edit WLAN profile',
    'wlan.cloneWlanProfile': 'Clone WLAN profile {name}',
    'wlan.tabLabel.general': 'General',
    'wlan.tabLabel.security': 'Security',
    'wlan.tabLabel.advanced': 'Advanced',
    'wlan.tabLabel.layer2': 'Layer 2',
    'wlan.tabLabel.layer3': 'Layer 3',
    'wlan.tabLabel.aaa': 'AAA',
    'wlan.tabSectionLabel.securityType': 'Security type',
    'wlan.tabSectionLabel.encryptionType': 'Encryption',
    'wlan.tabSectionLabel.80211r': '802.11r configuration',
    'wlan.tabSectionLabel.80211w': '802.11w configuration',
    'wlan.tabSectionLabel.authenticationKeyManagement': 'Authentication Key Management',
    'wlanPolicy.tabSectionLabel.nasIdSettings': 'NAS ID settings',
    'wlan.tabSectionLabel.maxClientsConnections': 'MAX Client Connections',
    'wlan.tabSectionLabel.dtimPeriod': 'DTIM period (in beacon interval)',
    'wlan.tabSectionLabel.80211k': '802.11k',
    'wlan.tabSectionLabel.80211ax': '802.11ax',
    'wlan.tabSectionLabel.80211v': '802.11v',
    'wlan.pageName': 'WLAN',
    'wlan.inputLabel.name': 'Profile name',
    'wlan.inputLabel.wlan_id': 'WLAN ID',
    'wlan.inputLabel.ssid': 'SSID',
    'wlan.inputLabel.broadcast_ssid_enabled': 'Broadcast SSID',
    'wlan.inputLabel.status_24ghz_enabled': 'Status 2.4 GHz',
    'wlan.inputLabel.status_5ghz_enabled': 'Status 5 GHz',
    'wlan.inputLabel.status_6ghz_enabled': 'Status 6 GHz',
    'wlan.inputLabel.status_ssid_enabled': 'WLAN Status',
    'wlan.inputLabel.security_type.open': 'Open',
    'wlan.inputLabel.security_type.wpa2': 'WPA2',
    'wlan.inputLabel.security_type.wpa2+wpa3': 'WPA2+WPA3',
    'wlan.inputLabel.security_type.wpa3': 'WPA3',
    'wlan.inputLabel.encryption_type.ccmp': 'CCMP',
    'wlan.inputLabel.encryption_type.ccmp256': 'CCMP-256',
    'wlan.inputLabel.encryption_type.gcmp256': 'GCMP-256',
    'wlan.inputLabel.isMabEnabled': 'MAB',
    'wlan.inputLabel.mab_radius_profile': 'Radius profile',
    'wlan.inputLabel.mab_radius_profile.selectOption.notConfigured': 'No',
    'wlan.inputLabel.fast_roaming_enabled': 'Fast Roaming 802.11r',
    'wlan.inputLabel.ft_over_ds_enabled': 'FT over DS',
    'wlan.inputLabel.param_80211r_reassociation_timeout': 'Reassociation timeout (sec)',
    'wlan.inputLabel.support_pmf': 'Support PMF',
    'wlan.inputLabel.support_pmf.selectOption.disabled': 'disabled',
    'wlan.inputLabel.support_pmf.selectOption.optional': 'optional',
    'wlan.inputLabel.support_pmf.selectOption.mandatory': 'required',
    'wlan.inputLabel.param_80211w_query_attempts': 'Number of SA query attempts',
    'wlan.inputLabel.param_80211w_retry_timeout': 'Interval (msec) between two SA Query requests',
    'wlan.inputLabel.psk_enabled': 'PSK',
    'wlan.inputLabel.psk_256_enabled': 'PSK-SHA256',
    'wlan.inputLabel.param_8021x_enabled': '802.1x',
    'wlan.inputLabel.param_8021x_256_enabled': '802.1x-SHA256',
    'wlan.inputLabel.sae_enabled': 'SAE',
    'wlan.inputLabel.ft_sae_enabled': 'FT + SAE',
    'wlan.inputLabel.ft_8021x_enabled': 'FT + 802.1x',
    'wlan.inputLabel.ft_psk_enabled': 'FT + PSK',
    'wlan.inputLabel.suite_b_192_enabled': 'SUITE-B-192',
    'wlan.inputLabel.owe_enabled': 'OWE',
    'wlan.inputLabel.psk_format': 'PSK format',
    'wlan.inputLabel.psk_type': 'PSK type',
    'wlan.inputLabel.psk_type.selectOption.open': 'Open',
    'wlan.inputLabel.psk_type.selectOption.encrypted': 'Encrypted',
    'wlan.inputLabel.psk_key': 'PSK',
    'wlan.inputLabel.owe_wlan_id': 'WLAN ID OWE transition mode',
    'wlan.inputLabel.sae_anti_clogging_threshold': 'Anti Clogging Threshold',
    'wlan.inputLabel.sae_max_retries': 'SAE Max Retries',
    'wlan.inputLabel.sae_password_element': 'SAE Password Element',
    'wlan.inputLabel.sae_password_element.selectOption.hnp': 'Hunting and pecking only',
    'wlan.inputLabel.sae_password_element.selectOption.h2e': 'Hash to element only',
    'wlan.inputLabel.sae_password_element.selectOption.both-h2e-hnp': 'Both',
    'wlan.inputLabel.isWebAuthProfileEnabled': 'WEB authentication',
    'wlan.inputLabel.web_auth_profile': 'WEB auth redirect',
    'wlan.inputLabel.radius_8021x_profile': '802.1x authentication Radius profile',
    'wlan.inputLabel.radius_accounting_profile': 'Accounting Radius profile',
    'wlan.inputLabel.radius_accounting_profile.selectOption.notConfigured': 'No',
    'wlan.inputLabel.max_connection_radio_wlan': 'Per Radio AP Per WLAN',
    'wlan.inputLabel.dtim_interval_5ghz': 'Band 5 GHz (1-255)',
    'wlan.inputLabel.dtim_interval_24ghz': 'Band 2.4 GHz (1-255)',
    'wlanPolicy.inputLabel.nas.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.nas.selectOption.sys-name': 'sys-name',
    'wlanPolicy.inputLabel.nas.selectOption.sys-ip': 'sys-ip',
    'wlanPolicy.inputLabel.nas.selectOption.sys-mac': 'sys-mac',
    'wlanPolicy.inputLabel.nas.selectOption.ssid': 'SSID',
    'wlanPolicy.inputLabel.nas.selectOption.ap-ip': 'ap-ip',
    'wlanPolicy.inputLabel.nas.selectOption.ap-name': 'ap-name',
    'wlanPolicy.inputLabel.nas.selectOption.ap-mac-eth': 'ap-mac-eth',
    'wlanPolicy.inputLabel.nas.selectOption.ap-policy-tag': 'ap-policy-tag',
    'wlanPolicy.inputLabel.nas.selectOption.ap-site-tag': 'ap-site-tag',
    'wlanPolicy.inputLabel.nas.selectOption.ap-location': 'ap-location',
    'wlanPolicy.inputLabel.nas.selectOption.customString': 'custom string',
    'wlanPolicy.inputLabel.nas_id_1': ' NAS ID option 1',
    'wlanPolicy.inputLabel.nas_id_1_custom': 'Custom string NAS ID 1',
    'wlanPolicy.inputLabel.nas_id_2': 'NAS ID option 2',
    'wlanPolicy.inputLabel.nas_id_2_custom': 'Custom string NAS ID 2',
    'wlanPolicy.inputLabel.nas_id_3': 'NAS ID option 3',
    'wlanPolicy.inputLabel.nas_id_3_custom': 'Custom string NAS ID 3',
    'wlan.inputLabel.neighbor_report_enabled': 'Neighbor report',
    'wlan.inputLabel.param_80211ax_enabled': '802.11ax',
    'wlan.inputLabel.ofdma_downlink_enabled': 'OFDMA Downlink',
    'wlan.inputLabel.ofdma_uplink_enabled': 'OFDMA Uplink',
    'wlan.inputLabel.mumimo_downlink_enabled': 'MU-MIMO Downlink',
    'wlan.inputLabel.mumimo_uplink_enabled': 'MU-MIMO Uplink',
    'wlan.inputLabel.bss_twt_enabled': 'BSS Target Wake Up Time',
    'wlan.inputLabel.bss_fast_transition_enabled': 'BSS Transition',
    'wlan.inputLabel.advertise_ap_name_enabled': 'Advertise AP Name',
    'wlan.inputLabel.p2p_block_action': 'P2P Blocking Action',
    'wlan.inputLabel.p2p_block_action.selectOption.disabled': 'Disabled',
    'wlan.inputLabel.p2p_block_action.selectOption.drop': 'Drop',
    'wlan.inputLabel.deny_laa_rcm_enabled': 'Deny LAA (RCM) clients',
    'wlan.inputLabel.wmm_policy': 'WMM policy',
    'wlan.inputLabel.wmm_policy.selectOption.disabled': 'Disabled',
    'wlan.inputLabel.p2p_block_action.selectOption.allowed': 'Allowed',
    'wlan.inputLabel.p2p_block_action.selectOption.require': 'Required',
    'wlan.inputLabel.okc_enabled': 'OKC',
    'wlan.inputError.uniqueProfileName': 'Profile name is not unique',
    'wlan.inputError.uniqueWlanId': 'WLAN ID is not unique',
    'wlan.inputError.status_6ghz_enabled': 'Enable WPA2+WPA3 or WPA3 and 802.11ax',
    'wlan.inputError.profileNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'wlan.inputError.ssidMask': 'The field must contain only english letters, numbers and symbols: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~ must not contain leading or trailing spaces',
    'wlanPolicy.inputError.nasMask': 'The field must contain only english letters, numbers and symbols: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~',
    'wlan.inputError.psk_key.open.ascii': 'Input PSK. PSK length should be in range 8 - 63 characters',
    'wlan.inputError.psk_key.open.hex': 'Input PSK. PSK length should be exactly 64 characters in HEX format',
    'wlan.inputError.psk_key.encrypted': 'Input PSK',
    'wlan.inputError.authKeyManagementCheckbox.required': 'At least one checkbox must be selected',
    'wlan.inputError.authKeyManagementCheckbox.combination': 'Invalid checkbox combination',
    'wlan.inputInfo.mab_radius_profile': 'Click {here} to navigate AAA page to create new Radius',
    'wlan.inputInfo.owe_wlan_id.wpa3': 'Enter WLAN ID with security type OPEN',
    'wlan.inputInfo.owe_wlan_id.open': 'Enter WLAN ID with OWE support',
    'wlan.inputInfo.web_auth_profile': 'Click {here} to navigate WEB auth page to create new rule',
    'wlan.inputInfo.radius_8021x_profile': 'Click {here} to navigate AAA page to create new Radius',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.1': 'Enable authentication key management. Supported combinations:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.2': 'CCMP encryption and 802.1x/802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.3': 'CCMP encryption and PSK/PSK-SHA256/FT+PSK-SHA256',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.4': 'CCMP-256/GCMP-256 encryption and SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.1': 'Enable authentication key management.',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.2': 'Supported combinations for WPA2:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.3': 'CCMP encryption and 802.1x/802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.4': 'CCMP encryption and PSK/PSK-SHA256/FT+PSK-SHA256',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.5': 'CCMP-256/GCMP-256 encryption and SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.6': 'Supported combinations for WPA3:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.7': 'CCMP encryption and 802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.8': 'CCMP encryption and SAE/FT+SAE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.9': 'CCMP-256/GCMP-256 encryption and SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.1': 'Enable authentication key management. Supported combinations:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.2': 'CCMP encryption and 802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.3': 'CCMP encryption and SAE/FT+SAE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.4': 'CCMP encryption and OWE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.5': 'CCMP-256/GCMP-256 encryption and SUITE-B-192',
    'wlan.tabText.warningCaption.80211r.wpa2.1': 'For 802.11r enable FT+PSK or FT+802.1x',
    'wlan.tabText.warningCaption.80211r.wpa2wpa3.1': 'For 802.11r enable FT+PSK or\\and FT+SAE or FT+802.1x',
    'wlan.tabText.warningCaption.80211r.wpa3.1': 'For 802.11r enable FT+SAE',
    'wlan.tableHeading.id': 'Profile ID',
    'wlan.tableHeading.status_ssid_enabled': 'WLAN Status',
    'wlan.tableHeading.wlanId': 'WLAN ID',
    'wlan.tableHeading.name': 'WLAN Profile name',
    'wlan.tableHeading.ssid': 'SSID',
    'wlan.tableHeading.24_5Ghz': '2.4 GHz\\5GHz Security',
    'wlan.tableHeading.6Ghz': '6 GHz Security',
    'wlan.tableCell.security_type.open': 'Open',
    'wlan.tableCell.security_type.wpa2': 'WPA2',
    'wlan.tableCell.security_type.wpa3': 'WPA3',
    'wlan.tableCell.security_type.wpa2wpa3': 'WPA2+WPA3',
    'wlan.tableCell.mab_radius_profile': 'MAC Filtering',
    'wlan.tableCell.web_auth_profile': 'WEB Authentication',
    'wlan.tableCell.support_pmf': '802.11w enabled',
    'wlan.tableCell.fast_roaming_enabled': '802.11r enabled',
    'wlan.responseMessage.deleteWlans.success': 'WLAN profile has been successfully deleted',
    'wlan.responseMessage.deleteWlans.error': 'WLAN profile hasn’t been successfully deleted',
    'wlan.responseMessage.createWlan.success': 'WLAN profile has been successfully added',
    'wlan.responseMessage.createWlan.error': 'WLAN profile hasn’t been successfully added',
    'wlan.responseMessage.updateWlan.success': 'WLAN profile has been successfully updated',
    'wlan.responseMessage.updateWlan.error': 'WLAN profile hasn’t been successfully updated',
    'wlan.responseMessage.enableWlans.success': 'Selected WLAN profiles has been successfully enabled',
    'wlan.responseMessage.enableWlans.error': 'Selected WLAN profiles hasn’t successfully enabled',
    'wlan.responseMessage.disableWlans.success': 'Selected WLAN profiles has been successfully disabled',
    'wlan.responseMessage.disableWlans.error': 'Selected WLAN profiles hasn’t successfully disabled',
    'branchProfile.pageName': 'Branch profile',
    'branchProfile.addBranchProfile': 'Add Branch profile',
    'branchProfile.changeBranchProfile': 'Add Branch profile',
    'branchProfile.tabLabel.general': 'General',
    'branchProfile.tabLabel.authentication': 'Authentication',
    'branchProfile.tabLabel.vlan': 'VLAN configuration',
    'branchProfile.tabLabel.policy': 'Policy configuration',
    'branchProfile.inputLabel.profileName': 'Profile name',
    'branchProfile.inputLabel.profileDescription': 'Profile description',
    'branchProfile.inputLabel.nativeVlan': 'Native VLAN',
    'branchProfile.inputLabel.fallbackRadioShutdown': 'Fallback Radio Shutdown',
    'branchProfile.inputLabel.arpCaching': 'ARP caching',
    'branchProfile.inputLabel.efficientSoftwareUpgrade': 'Efficient software upgrade',
    'branchProfile.inputLabel.ipOverlap': 'IP overlap',
    'branchProfile.inputLabel.pmkPropagation': 'PMK propagation',
    'branchProfile.inputLabel.radiusGroup': 'Radius group',
    'branchProfile.inputLabel.webAuthentication': 'WEB authentication',
    'branchProfile.inputLabel.vlanName': 'VLAN Name',
    'branchProfile.inputLabel.vlanId': 'VLAN ID',
    'branchProfile.inputLabel.aclType': 'ACL',
    'branchProfile.inputLabel.aclType.unidirectional': 'Unidirectional',
    'branchProfile.inputLabel.aclType.bidirectional': 'Bidirectional',
    'branchProfile.inputLabel.aclValue': 'ACL',
    'branchProfile.inputLabel.aclIngressVlaue': 'Ingress ACL',
    'branchProfile.inputLabel.aclEgressValue': 'Egress ACL',
    'branchProfile.inputError.uniqueProfileName': 'Profile name is not unique',
    'branchProfile.inputError.profileNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'branchProfile.inputError.profileDescriptionMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces',
    'branchProfile.inputError.uniqueVlanName': 'VLAN name is not unique',
    'branchProfile.inputError.vlanNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'branchProfile.inputError.uniqueVlanId': 'VLAN ID is not unique',
    'branchProfile.inputLabel.radiusGroup.selectOption.notConfigured': 'Not configured',
    'branchProfile.inputLabel.webAuthentication.selectOption.notConfigured': 'Not configured',
    'branchProfile.inputLabel.aclValue.selectOption.notConfigured': 'Not configured',
    'branchProfile.inputLabel.aclIngressVlaue.selectOption.notConfigured': 'Not configured',
    'branchProfile.inputLabel.aclEgressValue.selectOption.notConfigured': 'Not configured',
    'branchProfile.tableHeading.vlanId': 'VLAN ID',
    'branchProfile.tableHeading.vlanName': 'VLAN Name',
    'branchProfile.tableHeading.aclIngress': 'ACL Ingress',
    'branchProfile.tableHeading.aclEgress': 'ACL Egress',
    'branchProfile.tableHeading.name': 'Branch Profile name',
    'branchProfile.tableHeading.description': 'Profile description',
    'branchProfile.responseMessage.createBranchProfile.success': 'Branch profile has been successfully added',
    'branchProfile.responseMessage.createBranchProfile.error': 'Branch profile hasn’t been successfully added',
    'branchProfile.responseMessage.updateBranchProfile.success': 'Branch profile has been successfully updated',
    'branchProfile.responseMessage.updateBranchProfile.error': 'Branch profile hasn’t been successfully updated',
    'branchProfile.responseMessage.deleteBranchProfiles.success': 'Branch profile has been successfully deleted',
    'branchProfile.responseMessage.deleteBranchProfiles.error': 'Branch profile hasn’t been successfully deleted',
    'branchProfile.confirmDeletingText': 'Confirm deleting VLAN',
    'vlanConfiguration.pageSectionName': 'VLAN',
    'vlan.pageName': 'VLAN',
    'vlan.tableHeading.adminStatus': 'Status',
    'vlan.tableHeading.vlanId': 'VLAN ID',
    'vlan.tableHeading.name': 'Name',
    'vlan.responseMessage.deleteVlans.success': 'VLAN has been successfully deleted',
    'vlan.responseMessage.deleteVlans.error': 'VLAN hasn’t been successfully deleted',
    'vlan.responseMessage.createVlan.success': 'VLAN has been successfully added',
    'vlan.responseMessage.createVlan.error': 'VLAN hasn’t been successfully added',
    'vlan.responseMessage.updateVlan.success': 'VLAN has been successfully updated',
    'vlan.responseMessage.updateVlan.error': 'VLAN hasn’t been successfully updated',
    'vlan.createVlan': 'Add VLAN',
    'vlan.changeVlan': 'Edit VLAN',
    'vlan.tabLabel.general': 'General',
    'vlan.inputLabel.creationMode.single': 'Create one VLAN',
    'vlan.inputLabel.creationMode.multiple': 'Create several VLANs',
    'vlan.inputLabel.vlan_id': 'VLAN ID',
    'vlan.inputLabel.name': 'Name',
    'vlan.inputLabel.admin_status': 'Status',
    'vlan.inputLabel.initial_vlan_id': 'Initial VLAN ID',
    'vlan.inputLabel.last_vlan_id': 'Last VLAN ID',
    'vlan.inputError.uniqueVlanId': 'VLAN ID is not unique',
    'vlan.inputError.vlanNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'vlan.inputError.uniqueVlanName': 'Name is not unique',
    // todo
    'svi.pageName': 'SVI',
    'svi.tableHeading.adminStatus': 'Admin status',
    'svi.tableHeading.operStatus': 'Operational status',
    'svi.tableHeading.name': 'Name',
    'svi.tableHeading.ip': 'IPv4 address',
    'svi.tableHeading.description': 'Description',
    'svi.tableHeading.subnetMask': 'Subnet mask',
    'svi.inputError.isVlanId': 'VLAN ID does not exist',
    'svi.inputError.vlanValueRange': 'The field must contain unique numbers and should be in range 1 - 4094 values',
    'svi.inputError.uniqueSVIName': 'SVI name is not unique.',
    'svi.inputError.descriptionMask':
      'The field must contain only letters, numbers, and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { |, and must not contain leading or trailing spaces',
    'svi.inputError.mtu': 'The field must contain unique numbers and should be in range 68 - 1500 values',
    'svi.inputError.ipAddressMask': 'Must match the subnet mask format, X.X.X.X',
    'svi.inputError.subnetMask': 'Must match the subnet mask format, X.X.X.X',
    'svi.tabLabel.general': 'General',
    'svi.inputLabel.idVlan': 'VLAN ID',
    'svi.inputLabel.description': 'Description',
    'svi.inputLabel.adminStatus': 'Admin status',
    'svi.inputLabel.ip': 'IPv4 address',
    'svi.inputLabel.subnetMask': 'Subnet mask',
    'svi.inputLabel.aclIngress': 'Ingress access-list',
    'svi.inputLabel.aclEgress': 'Egress access-list',
    'svi.inputLabel.mtu': 'MTU',
    'svi.inputLabel.mDNSGateway': 'mDNS Gateway',
    'svi.inputLabel.aclIngress.deafultValue': 'No',
    'svi.inputLabel.aclEgress.deafultValue': 'No',
    'svi.inputLabel.acl.selectOption.notConfigured': 'Not configured',
    'svi.createSVI': 'Add SVI',
    'svi.changeSVI': 'Edit SVI',
    'svi.responseMessage.deleteSVIs.success': 'SVI has been successfully deleted',
    'svi.responseMessage.deleteSVIs.error': 'SVI hasn’t been successfully deleted',
    'svi.responseMessage.createSVI.success': 'SVI has been successfully added',
    'svi.responseMessage.createSVI.error': 'SVI hasn’t been successfully added',
    'svi.responseMessage.updateSVI.success': 'SVI has been successfully updated',
    'svi.responseMessage.updateSVI.error': 'SVI hasn’t been successfully updated',
    'vlanGroup.pageName': 'VLAN Group',
    'vlanGroup.createVlanGroup': 'Add VLAN group',
    'vlanGroup.changeVlanGroup': 'Edit VLAN group',
    'vlanGroup.tableHeading.name': 'Name',
    'vlanGroup.tableHeading.list_vlan_id': 'VLAN list',
    'vlanGroup.tabLabel.general': 'General',
    'vlanGroup.inputLabel.name': 'Name',
    'vlan.inputLabel.list_vlan_id': 'VLAN list',
    'vlan.inputPlaceholder.list_vlan_id': 'Example: 4,5,7-9',
    'vlanGroup.inputError.vlanGroupNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'vlanGroup.inputError.uniqueVlanGroupName': 'Vlan Group name is not unique.',
    'vlanGroup.inputError.vlanGroupListVlanIdMask': 'The field must contain only numbers (1-4094) and symbols: ", -" and must not contain leading or trailing spaces. Example: 4,5,7-9',
    'vlanGroup.responseMessage.createVlanGroup.success': 'Vlan Group has been successfully added',
    'vlanGroup.responseMessage.createVlanGroup.error.default': 'Vlan Group hasn’t been successfully added',
    'vlanGroup.responseMessage.createVlanGroup.error.invalidRange': 'VLAN group cannot be created. Create VLAN first:',
    'vlanGroup.responseMessage.updateVlanGroup.success': 'VLAN group has been successfully updated',
    'vlanGroup.responseMessage.updateVlanGroup.error': 'VLAN group hasn’t been successfully updated',
    'vlanGroup.responseMessage.deleteVlanGroups.success': 'VLAN group has been successfully deleted',
    'vlanGroup.responseMessage.deleteVlanGroups.error': 'VLAN group hasn’t been successfully deleted',
    'wlanPolicy.pageName': 'WLAN Policy',
    'wlanPolicy.addWlanPolicyProfile': 'Add WLAN Policy profile',
    'wlanPolicy.cloneWlanPolicyProfile': 'Clone WLAN Policy profile {name}',
    'wlanPolicy.changeWlanPolicyProfile': 'Edit WLAN Policy profile',
    'wlanPolicy.tabLabel.general': 'General',
    'wlanPolicy.tabLabel.accessPolicies': 'Access policies',
    'wlanPolicy.tabLabel.qos': 'QoS',
    'wlanPolicy.tabLabel.advanced': 'Advanced',
    'wlanPolicy.tabSectionLabel.qoSProfileClient': 'QoS Profile Client',
    'wlanPolicy.tabSectionLabel.qoSProfileSsid': 'QoS Profile SSID',
    'wlanPolicy.tabSectionLabel.wlanTimeoutSettings': 'WLAN Timeout settings',
    'wlanPolicy.tabSectionLabel.aaaSettings': 'AAA settings',
    'wlanPolicy.tabSectionLabel.proxySettings': 'Proxy settings',
    'wlanPolicy.tabSectionLabel.eogreSettings': 'EoGRE settings',
    'wlanPolicy.inputLabel.name': 'Profile name',
    'wlanPolicy.inputLabel.description': 'Profile description',
    'wlanPolicy.inputLabel.profile_enabled': 'Profile status',
    'wlanPolicy.inputLabel.central_switch_enabled': 'Central switching',
    'wlanPolicy.inputLabel.central_auth_enabled': 'Central authentication',
    'wlanPolicy.inputLabel.acl': 'ACL',
    'wlanPolicy.inputLabel.vlan': 'VLAN',
    'wlanPolicy.inputLabel.vlan_group': 'VLAN group',
    'wlanPolicy.inputLabel.vlan.warningCaption': 'For locally switched WLAN, VLAN configuration must be in the Branch profile',
    'wlanPolicy.inputLabel.policy_map_client_in': 'Ingress',
    'wlanPolicy.inputLabel.policy_map_client_out': 'Egress',
    'wlanPolicy.inputLabel.policy_map_ssid_in': 'Ingress',
    'wlanPolicy.inputLabel.policy_map_ssid_out': 'Egress',
    'wlanPolicy.inputLabel.policy_map_client_in.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.policy_map_client_out.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.policy_map_ssid_in.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.policy_map_ssid_out.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.session_timeout': 'Session timeout (sec)',
    'wlanPolicy.inputLabel.idle_timeout': 'Idle timeout (sec)',
    'wlanPolicy.inputLabel.idle_threshold': 'Idle threshold (byte)',
    'wlanPolicy.inputLabel.exclusion_timeout': 'Exclusion timeout (sec)',
    'wlanPolicy.inputLabel.aaa_override_enabled': 'AAA Override',
    'wlanPolicy.inputLabel.proxy_arp_enabled': 'Proxy arp',
    'wlanPolicy.inputLabel.eogre_profile': 'EoGRE profile',
    'wlanPolicy.inputLabel.eogre_profile.selectOption.notConfigured': 'No',
    'wlanPolicy.tableHeading.id': 'Profile ID',
    'wlanPolicy.tableHeading.name': 'Profile name',
    'wlanPolicy.tableHeading.description': 'Profile description',
    'wlanPolicy.tableHeading.profile_enabled': 'Profile status',
    'wlanPolicy.inputError.uniqueWlanPolicyName': 'Wlan policy name is not unique',
    'wlanPolicy.inputError.wlanPolicyNameMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces and must not consist of multiple words',
    'wlanPolicy.inputError.descriptionMask': 'The field must contain only letters, numbers and symbols: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | must not contain leading or trailing spaces',
    'wlanPolicy.inputError.vlanConfiguration': 'VLAN or VLAN group must be selected',
    'wlanPolicy.inputLabel.acl.selectOption.notConfigured': 'No',
    'wlanPolicy.inputLabel.vlan.selectOption.notConfigured': 'Select VLAN',
    'wlanPolicy.inputLabel.vlan_group.selectOption.notConfigured': 'Select VLAN group',
    'wlanPolicy.responseMessage.deleteWlanPolicies.success': 'WLAN Policy profile has been successfully deleted',
    'wlanPolicy.responseMessage.deleteWlanPolicies.error': 'WLAN Policy profile hasn’t been successfully deleted',
    'wlanPolicy.responseMessage.createWlanPolicyProfile.success': 'WLAN Policy profile has been successfully added',
    'wlanPolicy.responseMessage.createWlanPolicyProfile.error': 'WLAN Policy profile hasn’t been successfully added',
    'wlanPolicy.responseMessage.updateWlanPolicy.success': 'WLAN Policy profile has been successfully updated',
    'wlanPolicy.responseMessage.updateWlanPolicy.error': 'WLAN Policy profile hasn’t been successfully updated',
    'administration.createNtpServer': 'Add NTP server',
    'administration.enableAuth': 'Enable authentication',
    'administration.hostName': 'Host Name',
    'administration.ipForDns': 'IP for DNS resolution',
    'administration.ntpDetails': 'NTP server details',
    'administration.prefer': 'Prefer',
    'administration.refreshNtp': 'Refresh NTP Table',
    'administration.sourceAddress': 'Source address',
    'administration.status': 'Status',
    'administration.time.address': 'Address',
    'administration.time.confirmDeletingModal': 'Confirm deletion',
    'administration.time.confirmDeletingModalHeader': 'Delete a NTP server',
    'administration.time.notificationCreatedSuccessfully': 'NTP server successfully added',
    'administration.time.notificationDeletedSuccessfully': 'NTP server successfully deleted',
    'administration.time.notificationUpdatedSuccessfully': 'NTP server successfully updated',
    'administration.time.ntpListAllСonfiguredServers': 'NTP servers already configured on the controller',
    'administration.time.ntpListAllAlreadySelected': 'All configured NTP servers are already selected',
    'administration.time.ntpListIsEmpty': 'There are no configured NTP servers. To configure, use "Administration" / "Time" page.',
    'administration.time.port': 'Port',
    'administration.time.priority': 'Priority',
    'administration.time.source': 'Source',
    'administration.time.status': 'Status',
    'administration.time.title': 'Title',
    'administration.updateNtpServer': 'Edit NTP server',
    'administration.vrfName': 'VRF Name',
    'adsprofile.activeAd': 'Active',
    'adsprofile.add': 'Add an advert or poll',
    'adsprofile.addanswer': 'Add an answer',
    'adsprofile.additionalSettings': 'Advanced settings',
    'adsprofile.addVariant': 'Add a poll answer',
    'adsprofile.ads': 'Advert or poll',
    'adsprofile.adsStats': 'Statistics of views for all adverts',
    'adsprofile.adsStatsAllAuthorizationTypes': 'Statistics of views for all authorisation types',
    'adsprofile.adsStatsAllAuthorizationTypesHeader': 'Statistics of views and clicks for all authorisation types',
    'adsprofile.adsStatsAllAuthorizationTypesShort': 'Views',
    'adsprofile.adsStatsAllFollowsAuthorizationTypesShort': 'Clicks',
    'adsprofile.adsStatsbyId': 'Statistics of views',
    'adsprofile.adsStatsClose': 'to "Advert" page',
    'adsprofile.adsStatsFor': 'for',
    'adsprofile.adsStatsSepareteAuthorizationTypesHeader': 'Statistics of views by authorisation type',
    'adsprofile.adsStatsSepareteFacebook': 'Facebook',
    'adsprofile.adsStatsSepareteFree': 'Free',
    'adsprofile.adsStatsSepareteInstagram': 'Instagram',
    'adsprofile.adsStatsSepareteSponsor': 'Sponsored',
    'adsprofile.adsStatsSepareteStaff': 'Service',
    'adsprofile.adsStatsSepareteVk': 'VK',
    'adsprofile.adsStatsSepareteVoucher': 'Voucher',
    'adsprofile.adtype': 'Type of advert or poll',
    'adsprofile.changePollsColorThemeAlert': 'Press the "Save" button for applying the changes',
    'adsprofile.changeRedirectButtonColorAlert': 'Press the "Save" button for applying the changes',
    'adsprofile.dayOfWeek': 'Days of the week',
    'adsprofile.dayOfWeekCaption': 'Days of the week for displaying',
    'adsprofile.dayOfWeekFriday': 'Friday',
    'adsprofile.dayOfWeekMonday': 'Monday',
    'adsprofile.dayOfWeekSaturday': 'Saturday',
    'adsprofile.dayOfWeekSunday': 'Sunday',
    'adsprofile.dayOfWeekThursday': 'Thursday',
    'adsprofile.dayOfWeekTuesday': 'Tuesday',
    'adsprofile.dayOfWeekWednesday': 'Wednesday',
    'adsprofile.doneAd': 'The displaying is over',
    'adsprofile.duration': 'Advert or poll duration (in sec.)',
    'adsprofile.edit': 'Edit advert or poll',
    'adsprofile.enableBirthDate': "Ask user's birthday",
    'adsprofile.enableSex': "Ask user's gender",
    'adsprofile.enterRedirectButtonColor': 'Enter the color of the button',
    'adsprofile.enterRedirectButtonText': 'Enter the text and color of the redirect button',
    'adsprofile.enterRedirectButtonTextPlaceholder': 'More details',
    'adsprofile.fileLimitation': 'The following image formats are supported: .jpg, .png. Maximum file size is {KB} KB.',
    'adsprofile.flash': 'flash',
    'adsprofile.hasDesktop': 'Upload the desktop/laptop version of advert or poll',
    'adsprofile.hasPollPictures': 'Add images to poll answers',
    'adsprofile.hasTheme': 'Set a custom color theme for the poll',
    'adsprofile.iframe': 'iframe',
    'adsprofile.image': 'Image',
    'adsprofile.massAdsEditSchedule': 'Edit schedule',
    'adsprofile.massAdsEditScheduleAndTargeting': 'Edit schedule and targeting',
    'adsprofile.massAdsEditSelectAll': 'select all',
    'adsprofile.massAdsEditSelected': 'selected',
    'adsprofile.massAdsEditTargeting': 'Edit targeting',
    'adsprofile.massAdsUpdatedSuccess': 'Data successfully updated',
    'adsprofile.new': 'New advert or poll',
    'adsprofile.noColor': 'No color',
    'adsprofile.notificationAdPollCreatedSuccessfully': 'Advert or poll successfully сreated',
    'adsprofile.notificationAdPollDeletedSuccessfully': 'Advert or poll successfully deleted',
    'adsprofile.notificationAdPollUpdatedSuccessfully': 'Advert or poll successfully updated',
    'adsprofile.numberOfVisits': 'Number of visits',
    'adsprofile.numberOfVisitsCaption': 'You can configure a display for new customers (equal 0), visited 10 times (equal 10), visited more than 5 times (greater than 5) etc.',
    'adsprofile.numberOfVisitsHeader': 'Configure by number of visits',
    'adsprofile.numberOfVisitsSign': 'Condition',
    'adsprofile.numberOfVisitsSignequal': 'Equal',
    'adsprofile.numberOfVisitsSigngreater': 'Greater than',
    'adsprofile.numberOfVisitsSignless': 'Less than',
    'adsprofile.os': 'Operating systems',
    'adsprofile.osCaption': 'List of operating systems for displaying advert or poll. iOS or Android for example',
    'adsprofile.osFromList': 'Select operating system from the list',
    'adsprofile.osInput': 'or enter',
    'adsprofile.osInstruction': 'OS targeting. If operating systems are not specified, then the advert or poll is shown on devices for all operating systems',
    'adsprofile.osNoSpecified': 'Operating systems are no specified',
    'adsprofile.osSpecified': 'Specified operating systems',
    'adsprofile.plannedAd': 'Planned',
    'adsprofile.platform': 'Platform',
    'adsprofile.platformCaption': 'Platform for advert display',
    'adsprofile.platformDesktop': 'Desktop',
    'adsprofile.platformMobile': 'Mobile',
    'adsprofile.platformTablet': 'Tablet',
    'adsprofile.poll': 'Poll',
    'adsprofile.poll_quality': 'Poll about quality',
    'adsprofile.poll_user_data': 'Poll for user data',
    'adsprofile.pollImagesCaption': 'Poll answer must contain both image and text.',
    'adsprofile.pollQualityVariants': 'Number of answers (number of stars)',
    'adsprofile.pollResults': 'Poll results',
    'adsprofile.PollSelfVariant': 'Your answer',
    'adsprofile.pollSelfVariantPlaceholder': 'Write your own option',
    'adsprofile.pollUserData.date_of_birth': 'Birthday',
    'adsprofile.pollUserData.dateOfBirth': 'Enter your date of birth',
    'adsprofile.pollUserData.genders.female': 'Female',
    'adsprofile.pollUserData.genders.male': 'Male',
    'adsprofile.pollUserData.getInfo': "Get information about user's",
    'adsprofile.pollUserData.selectGender': 'Enter your gender',
    'adsprofile.pollUserData.sex': 'Gender',
    'adsprofile.pollvariants': 'Poll answers',
    'adsprofile.poolQualityUpdateVariants': 'Apply',
    'adsprofile.poolQualityVariantsValidate': 'Number of answers must be greater than 1 and less than 5',
    'adsprofile.preview': 'Preview',
    'adsprofile.previewDesktop': 'Desktop template',
    'adsprofile.previewMobile': 'Mobile template',
    'adsprofile.priority': 'Priority',
    'adsprofile.priorityCaption': 'From 0 (lowest) to 100 (highest)',
    'adsprofile.question': 'Question',
    'adsprofile.redirectURL': 'Redirect URL',
    'adsprofile.redirectURLCaption': 'Leave blank if redirect is not needed',
    'adsprofile.resolutionCaption': 'The file above will be used for both mobile and desktop/laptop devices by default.',
    'adsprofile.sameAs': 'Same settings as',
    'adsprofile.schedule': 'Schedule',
    'adsprofile.scheduleCaption': 'Advert activity period',
    'adsprofile.scheduleDate': 'Starting from date:',
    'adsprofile.scheduleEmpty': 'No active adverts for this date',
    'adsprofile.self_variant': 'Open question (custom answer is possible)',
    'adsprofile.setSchedule': 'Please provide schedule',
    'adsprofile.skip': 'Skip advert',
    'adsprofile.skipafter': 'Skip advert or poll after (in sec.)',
    'adsprofile.statistics': 'Statistics',
    'adsprofile.statisticsDuration': 'Views duration',
    'adsprofile.statisticsMeanDuration': 'Average view duration',
    'adsprofile.statisticsPoll': 'Poll results',
    'adsprofile.statisticsShows': 'Advert display statistics',
    'adsprofile.statisticsSkipped': 'Statistics of views with skip',
    'adsprofile.statisticsTotal': 'Total views',
    'adsprofile.switchPollWarning': 'This action will delete saved poll answers. Please confirm the deletion.',
    'adsprofile.targeting': 'Targeting settings',
    'adsprofile.textOnly': 'Text-only',
    'adsprofile.timeOfDay': 'Time of a day',
    'adsprofile.timeOfDayAfternoon': 'Afternoon',
    'adsprofile.timeOfDayCaption': 'Time of a day to display',
    'adsprofile.timeOfDayEvening': 'Evening',
    'adsprofile.timeOfDayMorning': 'Morning',
    'adsprofile.timeOfDayNight': 'Night',
    'adsprofile.url': 'URL',
    'adsprofile.vendor': 'Vendors',
    'adsprofile.vendorCaption': 'List of vendors for displaying of advert or poll. Apple or Intel for example.',
    'adsprofile.vendorinstruction': 'Vendors targeting. If vendors are not specified then the advert is shown on devices of all vendors.',
    'adsprofile.vendorNoSpecified': 'Vendors are not specified',
    'adsprofile.vendorSpecified': 'Specified vendors',
    'adsprofile.video': 'Video',
    'adsprofile.videoLoadingError': 'Video upload problem',
    'adsprofile.videoLoadingErrorReload': 'reload',
    'adsprofile.views': 'Number of views',
    'adsprofile.viewsCaption': '0 for infinite views',
    'adsprofile.warnUsage': 'Please note that if you delete an Ad in use by a Portal, {0}.',
    'adsprofile.warnUsageAccent': 'such Portal will not work properly',
    'adsprofile.withImages': 'Images with text',
    'alert.licenseEnds.link': 'Click here to configure',
    'alert.licenseEnds.text': 'Your licence is about to expire!',
    'alert.lowOfLicenses.link': 'To licenses',
    'alert.lowOfLicenses.text': 'There is only one AP (or less) license left. If you want to use more APs, you should add licenses',
    'alert.lowOfLicensesAnalytics.link': 'To licenses',
    'alert.lowOfLicensesAnalytics.text': 'There is only one analytics (or less) license left. If you want to use more analytics, you should add licenses.',
    'alert.lowOfLicensesPortals.link': 'To licenses',
    'alert.lowOfLicensesPortals.text': 'There is only one portal (or less) license left. If you want to use more portals, you should add licenses.',
    'aps.join': 'join',
    'aps.accessControlSettings': 'Edit Access Control Settings',
    'aps.active': 'Active',
    'aps.addCommonTemplates': 'Apply the template',
    'aps.addCommonWlans': 'Add common WLANs',
    'aps.addConfig': 'Add configuration',
    'aps.addCpe': 'Add AP',
    'aps.Advanced': 'Advanced settings',
    'aps.advancedSettings': 'Advanced settings',
    'aps.allWlans': 'All WLANs',
    'aps.assignCommonWlans': 'Assign common WLANs',
    'aps.assignCommonWlansHint': 'Select common WLANs for selected APs',
    'aps.assignCommonWlansWarn': 'Be careful! Selected APs have different radios',
    'aps.assignTemplate': 'Apply the template',
    'aps.assignTemplateHint': 'Select the template for selected APs',
    'aps.auto': 'Auto',
    'aps.back': 'Back',
    'aps.backgroundScaning': 'Background scanning',
    'aps.bandMode': 'Bandmode',
    'aps.bandwidth': 'Bandwidth',
    'aps.blacklist': 'Blacklist',
    'aps.brokerHost': "Broker's host",
    'aps.buttonsApply': 'Apply',
    'aps.buttonsBack': 'Back',
    'aps.buttonsUpgrade': 'Upgrade',
    'aps.buttonsYesReboot': 'Yes, reboot',
    'aps.buttonsYesReset': 'Yes, reset',
    'aps.cancel': 'Cancel',
    'aps.channel': 'Channel',
    'aps.channels': 'Channels',
    'aps.clickToWatchEventsCaptionInTooltip': 'Click to watch events',
    'aps.clients': 'Clients',
    'aps.clientTimeout': 'Client timeout (in sec)',
    'aps.close': 'Close',
    'aps.commandOutput': 'Output',
    'aps.commonWlans': 'Common WLANs',
    'aps.Config': 'Configuration',
    'aps.config': 'Configuration',
    'aps.configModalLabel': 'Enter the script',
    'aps.configModalTitle': 'Send configuration script',
    'aps.configNotSendCaption': 'Send the configuration every time',
    'aps.configStatus': 'Configuration status',
    'aps.confirm': 'Confirm',
    'aps.connected': 'Connected',
    'aps.connectionInfo': 'Connection information',
    'aps.country': 'Country code',
    'aps.cpe': 'APs',
    'aps.cpeClientsListFromCpeChannellCaption': 'Channel',
    'aps.cpeClientsListFromCpeClientsAssociated': 'Associated',
    'aps.cpeClientsListFromCpeClientsAuthenticated': 'Authenticated',
    'aps.cpeClientsListFromCpeClientsAuthorized': 'Authorised',
    'aps.cpeClientsListFromCpeClientsAuthorizedAuthenticatedAssociated': 'Authorised, Authenticated, Associated',
    'aps.cpeClientsListFromCpeClientsBeaconInterval': 'Beacon interval',
    'aps.cpeClientsListFromCpeClientsBitrate': 'Bitrate',
    'aps.cpeClientsListFromCpeClientsCombinedSignal': 'Signal',
    'aps.cpeClientsListFromCpeClientsConnectedCaption': 'connected',
    'aps.cpeClientsListFromCpeClientsConnectedTime': 'Connection time',
    'aps.cpeClientsListFromCpeClientsDTIMPeriod': 'DTIM period',
    'aps.cpeClientsListFromCpeClientsInactiveTime': 'Inactive time',
    'aps.cpeClientsListFromCpeClientsInfoCaption': 'Allows you to get information about current clients directly from the AP',
    'aps.cpeClientsListFromCpeClientsMac': 'MAC',
    'aps.cpeClientsListFromCpeClientsMFP': 'MFP',
    'aps.cpeClientsListFromCpeClientsPreamble': 'Preamble',
    'aps.cpeClientsListFromCpeClientsRX': 'RX',
    'aps.cpeClientsListFromCpeClientsRxBitrate': 'Rx bitrate',
    'aps.cpeClientsListFromCpeClientsRxBytes': 'Rx bytes',
    'aps.cpeClientsListFromCpeClientsRxDropMisc': 'Rx drop misc',
    'aps.cpeClientsListFromCpeClientsRxDuration': 'Rx duration',
    'aps.cpeClientsListFromCpeClientsRxPackets': 'Rx packets',
    'aps.cpeClientsListFromCpeClientsShortSlotTime': 'Short slot time',
    'aps.cpeClientsListFromCpeClientsSignal': 'Signal',
    'aps.cpeClientsListFromCpeClientsSignalAvg': 'Signal avg',
    'aps.cpeClientsListFromCpeClientsTableHeader': 'Clients',
    'aps.cpeClientsListFromCpeClientsTDLSPeer': 'TDLS peer',
    'aps.cpeClientsListFromCpeClientsTX': 'TX',
    'aps.cpeClientsListFromCpeClientsTxBitrate': 'TX bitrate',
    'aps.cpeClientsListFromCpeClientsTxBytes': 'TX bytes',
    'aps.cpeClientsListFromCpeClientsTxFailed': 'TX failed',
    'aps.cpeClientsListFromCpeClientsTxPackets': 'TX packets',
    'aps.cpeClientsListFromCpeClientsTxRetries': 'TX retries',
    'aps.cpeClientsListFromCpeClientsWMMWME': 'WMM/WME',
    'aps.cpeClientsListFromCpeCommandLink': 'Show current clients (data from AP)',
    'aps.cpeClientsListFromCpeCommandModalHeader': 'AP Clients',
    'aps.cpeClientsListFromCpeNoClients': 'No clients to display',
    'aps.cpeClientsListFromCpeTooltipHeader': 'WLAN information',
    'aps.cpeConfigSetButtonText': 'Upload configuration from a file',
    'aps.cpeConfigSetButtonTooltipText': 'Upload configuration for APs from a previously saved file',
    'aps.cpeConfigSetCopyFromClipboardButtonCaption': 'Paste from clipboard',
    'aps.cpeConfigSetCpeListCaption': 'The configuration will be applied to the following APs',
    'aps.cpeConfigSetCpeListNameCaptionAfterApplying': 'Name (after applying)',
    'aps.cpeConfigSetCpeListNameCaptionBeforeApplying': 'Name (before applying)',
    'aps.cpeConfigSetErrorsInUploadedData': 'There are some errors in the configuration data. Check if they are correct.',
    'aps.cpeConfigSetFromUploadedFileButtonCaption': 'Upload from file',
    'aps.cpeConfigSetFromUploadedFileIsertToFieldCaption': 'or paste the configuration into the field',
    'aps.cpeConfigSetTitleHeader': 'Apply configuration for APs',
    'aps.cpeConfigShowCopyButtonCaption': 'Copy to clipboard',
    'aps.cpeConfigShowCopyToClipboardError': 'Failed to copy to clipboard',
    'aps.cpeConfigShowCopyToClipboardSuccess': 'Copied to clipboard',
    'aps.cpeConfigShowDownloadButtonCaption': 'Save to a file',
    'aps.cpeConfigShowSaveToFileError': 'Failed to save file',
    'aps.cpeConfigShowTitleHeader': 'AP configuration',
    'aps.cpeConfigShowTooltipForButtonInCpe': 'Download AP configuration as a file',
    'aps.cpeEditCaptionOf': 'of',
    'aps.cpeEditCaptionUnlimited': 'unlimited',
    'aps.cpeEditPanelDescriptionPlaceHolder': 'Enter AP description',
    'aps.cpeEditPanelNamePlaceHolder': 'Enter AP name',
    'aps.cpeEditRadioSettings': 'Radio Settings',
    'aps.cpeEvents': 'Show the AP events',
    'aps.cpeFwCustomAddress': 'server address',
    'aps.cpeFwCustomAddressAndSchema': 'FW server address and protocol',
    'aps.cpeId': 'AP ID',
    'aps.serialNumber': 'Serial Number',
    'aps.cpeInfo': 'AP info',
    'aps.cpesList': 'APs',
    'aps.cpeStatusTooltipActive': 'Active',
    'aps.cpeStatusTooltipNotActive': 'Not active',
    'aps.cpeStatusTooltipUpdating': 'Updating',
    'aps.createTemplateFromCpe': 'Create the template from APs configuration',
    'aps.currentBlacklistItems': 'Current blacklist items',
    'aps.currentState': 'Current state',
    'aps.currentWhitelistItems': 'Current whitelist items',
    'aps.dbm': 'dBm',
    'aps.deleteAllTags': 'Delete all tags',
    'aps.deleteAllWlans': 'Delete all WLANs',
    'aps.deleteCommonTags': 'Delete common tags',
    'aps.deleteCommonWlans': 'Delete common WLANs',
    'aps.deleteCpe': 'Delete AP',
    'aps.deleteCpeConfirm': 'Confirm deletion',
    'aps.deleteCpes': 'Delete APs',
    'aps.deleteCpesConfirm': 'Confirm deletion',
    'aps.deleteTagsConfirm': 'Confirm deletion of tags on selected APs',
    'aps.deleteTemplate': 'Delete the template',
    'aps.deleteWlansConfirm': 'Confirm deletion of WLANs on selected APs',
    'aps.description': 'Description',
    'aps.DHCPCaptureFilter': 'DHCP packet capture',
    'aps.DHCPCaptureFilterDisabled': 'DHCP capture off',
    'aps.DHCPCaptureFilterDisabledShort': 'off',
    'aps.DHCPCaptureFilterEnabled': 'DHCP capture on',
    'aps.DHCPCaptureFilterEnabledShort': 'on',
    'aps.dhcpPacketCapture': 'DHCP packet capture',
    'aps.differentHostAddrMessage': 'Attention! Selected points have different broker addresses.',
    'aps.disabled': 'Disabled',
    'aps.discardErrorsButton': 'Reset AP errors',
    'aps.discardErrorsNotificationErrorsDiscardedSuccessfully': 'Errors successfully reset',
    'aps.disconnected': 'Disconnected',
    'aps.downloadSpeed': 'Download speed limit',
    'aps.downloadSpeedDescription': 'Download speed limit is equally applied to all clients',
    'aps.downloadSpeedMax': 'Total limit',
    'aps.downloadSpeedMin': 'Limit per client',
    'aps.downloadSpeedType': 'Unit',
    'aps.dualBand': 'Dual radio',
    'aps.editCommonParameters': 'Edit common APs parameters',
    'aps.editingMode': 'Edit mode',
    'aps.editLogAndStatSettings': 'Edit logs, statistics, Wi-Fi target, SNMP and DHCP packet capture settings',
    'aps.editSelectedCpes': 'Edit selected APs',
    'aps.editSettingsCpes': 'Edit radio settings',
    'aps.editTags': 'Add tags',
    'aps.empty': 'empty',
    'aps.emptyWatcher': 'Reboot AP when MAC address list is empty',
    'aps.emptyWatcherInfoTooltip': 'Mandatory for TP-Link MR3020 APs due to driver features',
    'aps.error': 'Error',
    'aps.errorCaptionInCpeEditPanel': 'Error',
    'aps.errorCopyToClipBoardCaption': 'Copy to clipboard',
    'aps.errorMessageNoAvailableCapabilities': 'No available possibilities',
    'aps.errorMessageNoAvailableCapabilitiesNote': 'Band / Mode / Channel combination is not correct. Please turn off, delete from platfrom, reboot and recreate AP from the scratch.',
    'aps.errorMessageNoCPEsModelData': 'No model data for selected APs',
    'aps.errorMessageOnlyForAPsWithEqualModel': 'This setting is only available for APs of equal model',
    'aps.errorMessageOnlyForConnectedCPEs': 'This setting is only available for connected APs',
    'aps.errorMessageOnlyForCPEsWithEqualHostAddr': 'Available only for APs with the same broker address',
    'aps.errorMessageOnlyForDisconnectedCPEs': 'This setting is only available for disconnected APs',
    'aps.errorMessageOnlyForIpsecConnectedCPEs': 'This setting is only available for IPsec connected APs',
    'aps.errorMessageOnlyIfLeastOneCPEHaveError': 'Reset of errors is only available if at least one of the selected APs has errors',
    'aps.eth0IsOff': 'Interface is off',
    'aps.eth0StateCaption': 'Ethernet interface state',
    'aps.eth0StateSinceRebootCaption': 'since reboot',
    'aps.filterByCompanyDisableSortingByLocationMsg': 'Turn off sorting by company name for sorting by location',
    'aps.filterByUpdatingStatus': 'Updatable',
    'aps.firmwareModeCheck': 'check',
    'aps.firmwareModeOff': 'off',
    'aps.firmwareModeOn': 'on',
    'aps.forFollowingCPEs': 'The change will be applied for the following APs',
    'aps.FWAutoUpgrade': 'Firmware auto upgrade',
    'aps.fwFileUploadedCaption': 'uploaded',
    'aps.fwFileUploadSuccessfullyMsgCaption': 'Firmware file uploaded successfully',
    'aps.fwSelectFirmwareCaption': 'Select firmware file',
    'aps.fwUpgrade': 'Firmware upgrade',
    'aps.FWversion': 'Firmware version',
    'aps.general': 'General',
    'aps.getLogsFromCpe': 'Get log from AP',
    'aps.ghz': 'GHz',
    'aps.goBackToClients': 'Back to clients',
    'aps.goBackToCpeStatistic': 'Back to AP statistics',
    'aps.Guestcontrol': 'Guest control',
    'aps.header': 'Access Points',
    'aps.hideDescription': 'Hide description',
    'aps.hostAddr': 'Broker address',
    'aps.Input': 'Input',
    'aps.inputForChangeDefaultPlaceholder': 'Enter to modify the default value',
    'aps.inputForChangeDefaultServerPlaceholder': 'Enter to modify the default server',
    'aps.inputForChangeDefaultServerPortPlaceholder': 'Enter to modify the default server port',
    'aps.interfaceDisabled': 'Interface is disabled',
    'aps.interfaces': 'Interfaces',
    'aps.interfaceType': 'WAN type',
    'aps.ipaddr': 'Local network address',
    'aps.IPsec': 'IPsec',
    'aps.IPsecModalLabel': 'Please select the action',
    'aps.IPsecTurnOff': 'Turn off',
    'aps.IPsecTurnOn': 'Turn on',
    'aps.IPsecTurnOnUnencrypted': 'Turn on without encryption',
    'aps.isCpeModelEqualRTBR24WFN2Ev61ErrorMsgForLan2': "Can't set VLAN 0 when LAN 2 is selected for Rotek RT-BR24-WFN2E v6.1",
    'aps.isCpeModelEqualRTBR24WFN2Ev61InfoMsgForLan2': "Can't set VLAN 0 when LAN 2 is selected for Rotek RT-BR24-WFN2E v6.2",
    'aps.isSystemWlanError': 'The access point may not work correctly if system VLAN is set.',
    'aps.lastError': 'Problematic',
    'aps.lbs': 'Wi-Fi target',
    'aps.lbsConfig': 'Wi-Fi target configuration',
    'aps.LBSFilterMode': 'Wi-Fi target filter mode',
    'aps.legacyRates': 'Legacy rates (802.11b support)',
    'aps.limitperWlan': 'Limit per WLAN',
    'aps.localAddr': 'Local tunnel address',
    'aps.location': 'Location',
    'aps.locationChangeHint': 'Changing the location will reset all WLANs selected for the interfaces',
    'aps.Log': 'Log',
    'aps.log': 'Log',
    'aps.logConfig': 'Log configuration',
    'aps.logFromCpe': 'AP log',
    'aps.logLevel': 'Log level',
    'aps.logLevelMode': 'ON - debug mode, OFF - normal mode',
    'aps.macaddr': 'MAC',
    'aps.massRegistrationMode': 'Mass registration mode',
    'aps.maxCacheQueue': 'How many MAC addresses to store offline',
    'aps.maxCacheQueueCaption': 'when AP is not connected to the controller',
    'aps.maxCacheQueueShort': 'MAC addresses to store offline',
    'aps.maxClients': 'Max number of clients',
    'aps.maxinactivityAboutModalText': 'Max inactivity timeout from hostapd config',
    'aps.maxinactivityAboutModalTitle': 'About inactivity timeout',
    'aps.maxinactivityPlaceholder': 'Enter period in sec. (value 0 is for default 180 sec.)',
    'aps.maxinactivityTitle': 'Inactivity timeout',
    'aps.maxinactivityTooltip': 'Max inactivity timeout from hostapd config. Click to view more information.',
    'aps.maxQuiet': 'Empty collections times before reboot',
    'aps.maxQuietCaption': 'after how many empty attempts reboot',
    'aps.maxQuietShort': 'empty collections times',
    'aps.modalAddTagPlaceHolder': 'Add tag',
    'aps.modalAddUUIDPlaceHolder': 'Add UUID',
    'aps.modalBeAttention': 'Pay attention!',
    'aps.modalBeAttentionFirst': 'During force APs upgrade, the automatic upgrade mode will be disabled.',
    'aps.modalBeAttentionSecond': 'If uploaded firmware is the same version as chosen AP has - upgrade will not be performed.',
    'aps.modalBrokerAddress': 'Broker address',
    'aps.modalBrokerAddressPlaceHolder': 'Enter for changing default broker address',
    'aps.modalConfigFromTitle': 'Current config from',
    'aps.modalCurrentFirmwareVersion': 'Current firmware version',
    'aps.modalDisabled': 'Disabled',
    'aps.modalEnabled': 'Enabled',
    'aps.modalEnterTemplateNamePlaceHolder': 'Enter template name',
    'aps.modalFirewallTitle': 'Firewall',
    'aps.modalFirmwareFileInfo': 'Firmware file info',
    'aps.modalFirmwareFileInfoSize': 'Size',
    'aps.modalLocationTitle': 'Location',
    'aps.modalLogsTitle': 'Logs',
    'aps.modalModelTitle': 'Model',
    'aps.modalNo': 'No',
    'aps.modalNoUpdatesAvailable': 'No updates available',
    'aps.modalNoWLANs': 'No WLANs',
    'aps.modalSelectTemplatePlaceHolder': 'Select the template',
    'aps.modalStatTitle': 'Statistics',
    'aps.modalTemplateDescriptionPlaceHolder': 'Enter description',
    'aps.modalUploadFWfile': 'Upload FW file',
    'aps.modalUUIDOptionalTitle': 'UUID (optional)',
    'aps.modalWiFiRadarTitle': 'Wi-Fi target',
    'aps.modalWLANsTitle': 'WLANs',
    'aps.modalYes': 'Yes',
    'aps.mode': 'Mode',
    'aps.model': 'Model',
    'aps.multiEdit': 'Multiple edit',
    'aps.multiEditMode': 'Multiple edit mode',
    'aps.name': 'Name',
    'aps.nasIp': 'NAS IP address',
    'aps.natAccess': 'Access from NAT',
    'aps.natNetworkInfoMsg': "Don't use 10.9.0.1/16 for IPsec APs",
    'aps.natNetworkIpaddr': 'IP for NAT network',
    'aps.natNetworkIpaddrPlaceholder': 'Enter IP for NAT network',
    'aps.natNetworkNetmask': 'Mask for the NAT network',
    'aps.natNetworkNetmaskPlaceholder': 'Enter the mask for the NAT network',
    'aps.noDataToDisplay': 'No data to display',
    'aps.none': 'none',
    'aps.notActive': 'Not active',
    'aps.noTags': 'No tags',
    'aps.notConnected': 'Not сonnected',
    'aps.notificationCPECreatedSuccessfully': 'AP successfully created',
    'aps.notificationCPEOperationSuccessfull': 'Successfully',
    'aps.notificationCPEUpdatedSuccessfully': 'AP successfully updated',
    'aps.notificationLocationSuccessfullyChanged': 'Location successfully changed',
    'aps.notificationTemplateCreatedSuccessfully': 'Template successfully created',
    'aps.ntpServerAddressPlaceholder': 'server address (IP or domain)',
    'aps.ntpSettingsEnable': 'Enable NTP',
    'aps.ntpSettingsHeader': 'NTP',
    'aps.ntpSettingsServersAdd': 'Add',
    'aps.ntpSettingsServersAlreadyAdded': 'Server already added',
    'aps.ntpSettingsServersClear': 'Clear',
    'aps.ntpSettingsServersDelete': 'Delete',
    'aps.ntpSettingsServersHeader': 'NTP servers',
    'aps.ntpSettingsServersListCounter': 'NTP servers selected',
    'aps.offline': 'offline',
    'aps.ok': 'online',
    'aps.oneBand': 'One radio',
    'aps.Output': 'Output',
    'aps.OutputWarning': 'Choose at least one option',
    'aps.OutputWarningForNonRootLocationUsers': 'Enable NAT',
    'aps.pending': 'pending',
    'aps.Ports': 'Ports',
    'aps.positionBlockCoords': 'Coordinates',
    'aps.positionBlockCoordsLat': 'lat.',
    'aps.positionBlockCoordsLatFull': 'Latitude',
    'aps.positionBlockCoordsLng': 'lng.',
    'aps.positionBlockCoordsLngFull': 'Longitude',
    'aps.positionBlockCopyFromLocationButtonCaption': 'Copy from',
    'aps.positionBlockCopyFromLocationButtonInfo': 'Allows you to copy coordinates (latitude and longitude) from the location where the access point is located',
    'aps.positionBlockCopyFromLocationCaption': 'Copy from location',
    'aps.positionBlockCopyFromSelectedLocationButtonCaption': 'Copy from selected location',
    'aps.positionBlockCopyFromSelectedLocationButtonInfo': 'Allows to copy coordinates (latitude and longitude) from the selected location',
    'aps.positionBlockEnterAddressToSearch': 'Enter the address to find coordinates',
    'aps.positionBlockForMultiEditButtonCaption': 'Set the position',
    'aps.positionBlockHeader': 'Position',
    'aps.positionBlockSearchAddressCaption': 'Сoordinates search by address',
    'aps.positionBlockSelectLocation': 'Select a location',
    'aps.radar': 'Wi-Fi target',
    'aps.rateControl': 'Rate control',
    'aps.readOnlyMode': 'Read only mode',
    'aps.rebootCpe': 'Reboot AP',
    'aps.rebootCpeAgent': 'Reboot AP agent',
    'aps.rebootCpeAgentModalText': 'Please confirm AP agent reboot',
    'aps.rebootCpeConfirm': 'Confirm AP reboot',
    'aps.rebootCpeRedundant': 'Reboot via redundant channel',
    'aps.rebootCpeRedundantModalText': 'Please confirm the reboot via redundant channel',
    'aps.rebooting': 'rebooting',
    'aps.recommendationCaptionInCpeEditPanel': 'Recommendation',
    'aps.Redirect': 'Redirect',
    'aps.register': 'Register',
    'aps.registerCPE': 'Register AP',
    'aps.registerCpeExample': 'Example',
    'aps.registerCpeFileTip': 'Use .csv file',
    'aps.registerCpeRegisterFollowCPEs': 'Register follow APs',
    'aps.registerCPEs': 'Register APs',
    'aps.registerDescription': '',
    'aps.registerFollowCPEs': 'Register follow APs?',
    'aps.reportPeriod': 'Report period (in sec)',
    'aps.resetCpe': 'Reset AP',
    'aps.resetCpeConfirm': 'Confirm AP reset',
    'aps.resetting': 'reset',
    'aps.restartCpeAgent': 'Restart AP agent',
    'aps.rrmInfoTooltip': 'RRM - radio resource control algorithm',
    'aps.save': 'Save',
    'aps.search': 'Search',
    'aps.selectChannelsLessThanForCorrectWork': 'For correct work select channels less than',
    'aps.selectChannelsMoreThanForCorrectWork': 'For correct work select channels more than',
    'aps.selectedAndWLANtunnelsAreNotMatch': 'The selected tunnel and the selected WLAN tunnel do not match',
    'aps.selectHostAddress': 'select',
    'aps.sendConfigCmd': 'Send config command',
    'aps.setTimeUsingNTP': 'Set time using NTP',
    'aps.setTimeUsingNTPEnterServerAddress': 'Enter server address (IP or domain)',
    'aps.setTimeUsingNTPSuccessMsg': 'Time set successfully',
    'aps.showCurrentClients': 'Show current clients',
    'aps.showCurrentStats': 'Show current statistics',
    'aps.showCurrentStatsBytes': 'Bytes',
    'aps.showCurrentStatsGbytes': 'Gbytes',
    'aps.showCurrentStatsInterfacesHeader': 'AP interfaces',
    'aps.showCurrentStatsInterfacesInterfaceNameTableCaption': 'AP interface',
    'aps.showCurrentStatsInterfacesInterfaceTableHeaderCaption': 'Interface',
    'aps.showCurrentStatsInterfacesTxRxChartRx': 'RX',
    'aps.showCurrentStatsInterfacesTxRxChartTitle': 'TX and RX for',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipB': 'Bytes',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipGB': 'Gbytes',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipKB': 'Kbytes',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipMB': 'Mbytes',
    'aps.showCurrentStatsInterfacesTxRxChartTx': 'TX traffic',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleB': 'Bytes',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleGB': 'Gbytes',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleKB': 'Kbytes',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleMB': 'Mbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartRx': 'RX traffic delta',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTitle': 'TX and RX traffic delta for',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltip': '#Н/Д',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipB': 'Bytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipGB': 'Gbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipKB': 'Kbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipMB': 'Mbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTx': 'TX traffic delta',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB': 'Bytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleGB': 'Gbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleKB': 'Kbytes',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleMB': 'Mbytes',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartRx': 'RX speed',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTitle': 'TX and RX speed for',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB': 'Bytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipGB': 'Gbytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipKB': 'Kb/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipMB': 'Mbytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTx': 'TX speed',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB': 'Bytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleGB': 'Gbytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleKB': 'Kbytes/sec',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleMB': 'Mbytes/sec',
    'aps.showCurrentStatsKbytes': 'Kbytes',
    'aps.showCurrentStatsMbytes': 'Mbytes',
    'aps.showCurrentStatsModalTitle': 'Current statistics for AP',
    'aps.showCurrentStatsNoDataForCPE': 'There is no data for this AP',
    'aps.showCurrentStatsRamChartChartTitle': 'Memory',
    'aps.showCurrentStatsRamChartFreeRam': 'Free RAM',
    'aps.showCurrentStatsRamChartTooltip': 'Mbytes',
    'aps.showCurrentStatsRamChartTotalRam': 'Total memory',
    'aps.showCurrentStatsRamChartYAxisTitle': 'Mbytes',
    'aps.showCurrentStatsUnits': 'Units',
    'aps.showCurrentStatsUptimeCaption': 'Uptime',
    'aps.showDescription': 'show description',
    'aps.singleRegistrationMode': 'Single registration mode',
    'aps.snmp': 'SNMP',
    'aps.snmpCommunity': 'Community',
    'aps.snmpinterfaces': 'Interfaces',
    'aps.snmpinterfacesListItems': 'Interfaces list items',
    'aps.snmpListenInterface': 'Listen interface',
    'aps.snmpLocation': 'Location',
    'aps.sortByConnectionnOrDisconnectionTime': 'Sort by conn./disconn. time',
    'aps.sortByConnectionnOrDisconnectionTimeButton': 'Conn./disconn. time',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeCaption': 'Date range',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterButton': 'Enable',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption': 'Filtering by date range',
    'aps.sortByConnectionnOrDisconnectionTimeNoSort': 'No sort',
    'aps.sortByFirstConnection': 'By first connection',
    'aps.sortByLastConnection': 'By last connection',
    'aps.sortByLastDisconnection': 'By last disconnection',
    'aps.statAndRadarCaptionInCpeInfoBlock': 'Statistics, Wi-Fi target, Log info, SNMP and DHCP packet capture',
    'aps.statAndRadarCaptionInCpeInfoBlockWithoutWifiTarget': 'Statistics, Log info, SNMP and DHCP packet capture',
    'aps.statAndRadarCaptionInModalBlock': 'Statistics, Wi-Fi target, Log info, SNMP and DHCP packet capture',
    'aps.statAndRadarCaptionInModalBlockWithoutWifiTarget': 'Statistics, Log info, SNMP and DHCP packet capture',
    'aps.statConfig': 'Statistics configuration',
    'aps.statConfigCapture': 'AP and client statistics period',
    'aps.Stats': 'Statistics',
    'aps.syncTime': 'Synchronize time',
    'aps.syncTimeButton': 'Synchronize',
    'aps.syncTimeForCPES': 'Time synchronization will be done for the following APs',
    'aps.syncTimeSuccessMsg': 'Time synchronization will be done for the following APs',
    'aps.systemVlans': 'system VLANs',
    'aps.tableItemsOf': 'items of',
    'aps.tableLoad': 'Load',
    'aps.tableOutOf': 'APs ouf of',
    'aps.tableTab_clients': 'Clients',
    'aps.tableTab_firstConnection': 'First conn.',
    'aps.tableTab_five': '5 Ghz',
    'aps.tableTab_interface': 'Interfaces',
    'aps.tableTab_lastConnection': 'Last conn.',
    'aps.tableTab_lastDisconnection': 'Last disconn.',
    'aps.tableTab_location': 'Location, company',
    'aps.tableTab_model': 'Model',
    'aps.tableTab_name': 'Name',
    'aps.tableTab_radar': 'Wi-Fi target',
    'aps.tableTab_status': 'Status',
    'aps.tableTab_tags': 'Tags',
    'aps.tableTab_two': '2.4 Ghz',
    'aps.tableTab_vlan': '#Н/Д',
    'aps.tableTab_wired': 'Wired',
    'aps.tableTab_wlans': 'WLANs',
    'aps.tableView': 'view',
    'aps.templates': 'Templates',
    'aps.Tunnel': 'Tunnel',
    'aps.tunnelAndWLANtunnelsAreNotMatchForCPETable': 'Tunnel and WLAN tunnel do not match in one of the wired interface configuration',
    'aps.tunnels.captionInCpeModal': 'Tunnels',
    'aps.tunnels.noTunnels': 'There are no tunnels',
    'aps.tunnelTypeForWLANInfo': 'Tunnel',
    'aps.txPower': 'TX Power',
    'aps.txPowerAdjust': 'TX Power',
    'aps.type': 'Type',
    'aps.unlim': 'unlimited',
    'aps.update': 'Update',
    'aps.updating': 'updating',
    'aps.upgrading': 'upgrading',
    'aps.wanBlockAccess': 'SSH / Web access block from WAN',
    'aps.warnStatistics': 'Please pay attention that deletion of AP will result in {0}.',
    'aps.warnStatisticsLoss': 'loss of statistics',
    'aps.whitelist': 'whitelist',
    'aps.width': 'Width',
    'aps.wifiLock': 'Lock Wi-Fi configuration change',
    'aps.wifiRadar': 'Wi-Fi target',
    'aps.wired': 'Wired',
    'aps.WiredConfig': 'Wired configuration',
    'aps.wiredConfigAccounting': 'Accounting',
    'aps.wiredConfigAccountingEnable': 'Enable accounting',
    'aps.wiredConfigFakeWlan': 'Fake WLAN',
    'aps.wiredNoTunel': 'No tunnel',
    'aps.wiredPorts': 'Ports',
    'aps.wiredSet0forNative': 'set 0 for native',
    'aps.wiredTunnel': 'Tunnel',
    'aps.wiredVLANNAtive': 'native',
    'aps.wlans': 'WLANs',
    'aps.wlansCaption': 'WLANs',
    'aps.wlansSelectorHint': 'Only WLANs from the location selected for the AP are available',
    'aps.wlansSelectorPlaceHolder': 'Select a WLAN',
    'aps.wlansSelectorTip': 'Press enter to select',
    'aps.WLANsstatus': 'WLANs status',
    'aps.WPA3AddToNoWPA3CPEError': "You can't add a WPA3 network to a WPA3 incapable AP",
    'aps.WPA3AddToNoWPA3CPEMultiEditError': "Some of the selected APs are WPA3 incapable. You can't add a WPA3 network to a WPA3 incapable AP.",
    'aps.WPA3Capable': 'WPA3 capable',
    'aps.zeroVLANIfNativeVlanOnSinglePortInfoMsg': 'Do not set VLAN 0 on the interface from which the device connects to the network. This will result in the loss of the device.',
    'clientDistance.chartDistance': 'Distance (meters)',
    'clientDistance.chartHeader': 'Client distance',
    'clientDistance.noTags': 'no tags',
    'clientDistance.tableItemsOf': 'items of',
    'clientDistance.tableView': 'view',
    'clients.auth': 'Authentication',
    'clients.authDevice': 'device',
    'clients.authInfo': 'Authentication information',
    'clients.authNo': 'No',
    'clients.authorization': 'Authorisation',
    'clients.authorized': 'Authorised',
    'clients.authOs': 'OS',
    'clients.authOsVersion': 'OS version',
    'clients.authTable': 'Authentication',
    'clients.authType': 'type',
    'clients.authUseragent': 'useragent',
    'clients.authUsername': 'username',
    'clients.camera': 'Camera',
    'clients.channel': 'Channel',
    'clients.chipManufacturer': 'Chip manufacturer',
    'clients.clientMac': 'Client MAC',
    'clients.clients': 'Clients',
    'clients.clientsOutOf': 'Clients out of',
    'clients.connected': 'Connected',
    'clients.cpe': 'AP',
    'clients.CPE': 'AP',
    'clients.disconnected': 'Disconnected',
    'clients.editPanelCamera': 'Camera',
    'clients.editPanelCameraRtspStream': 'RTSP stream',
    'clients.editPanelCameraRtspStreamLinkPlaceHolder': 'Enter the link for RTSP stream',
    'clients.editPanelDescriptionPlaceHolder': 'Enter description',
    'clients.editPanelOther': 'Wireless',
    'clients.editPanelWired': 'Wired',
    'clients.eventsAndRssiButtonCaption': 'Events and RSSI',
    'clients.eventsAndRssiTooltipCaption': 'Allows you to view events and RSSI for the selected client on the chart',
    'clients.eventsButtonCaption': 'Events',
    'clients.eventsTableCaption': 'Events',
    'clients.disconnectAndDeauthorizeButtonCaption': 'Disconnect & deauthorize',
    'clients.disconnectAndDeauthorizeSuccessMsg': 'Client successfully disconnected & deauthorized',
    'clients.eventsTooltipCaption': 'Allows you to go to the "Events" section and view events for the selected client',
    'clients.frequency': 'Frequency',
    'clients.goBackToClientsStatistic': 'Back to "Clients statistic"',
    'clients.healthFair': 'Fair',
    'clients.healthGood': 'Good',
    'clients.healthInactive': 'Inactive',
    'clients.healthOffline': 'Offline',
    'clients.healthPoor': 'Poor',
    'clients.healthScoresInfoTooltip': 'Click to see more information about the health status',
    'clients.healthStatusButtonsInfo': 'only for "Connected" clients filter',
    'clients.healthStatusModalInfoCaption': 'Health status can have the following definitions',
    'clients.healthStatusModalInfoTitle': 'About health status',
    'clients.itemsOf': 'items of',
    'clients.lastConnected': 'Last connected',
    'clients.load': 'Load',
    'clients.noConnectedClientsToDisplay': 'No connected clients to display',
    'clients.noConnectedClientsToDisplayWhenCPESelected': 'No connected clients to display for selected AP',
    'clients.notificationClientUpdated': 'Client successfully updated',
    'clients.other': 'Wireless',
    'clients.scores': 'points',
    'clients.showAllClients': 'Show all clients',
    'clients.showAllClientsWhenCPESelected': 'Show all clients for selected AP',
    'clients.tableInterface': 'Interface',
    'clients.tableNo': 'No',
    'clients.tableNoData': 'no data',
    'clients.tableNotFound': 'not found',
    'clients.tableTab_channel': 'Channel',
    'clients.tableTab_cpe': 'AP (frequency / channel / RSSI)',
    'clients.tableTab_description': 'Description',
    'clients.tableTab_frequency': 'Frequency',
    'clients.tableTab_healthScore': 'Health status',
    'clients.tableTab_mac': 'MAC',
    'clients.tableTab_rssi': 'RSSI',
    'clients.tableTab_state': 'Last state',
    'clients.tableTab_type': 'Type',
    'clients.tableTab_wlan': 'WLAN',
    'clients.view': 'view',
    'clients.wired': 'Wired',
    'clients.wlan': 'WLAN',
    'clients.WLAN': 'WLAN',
    'clientsRF.authDevice': 'device',
    'clientsRF.authInfo': 'Authentication information',
    'clientsRF.authNo': 'No',
    'clientsRF.authOs': 'OS',
    'clientsRF.authOsVersion': 'OS version',
    'clientsRF.authTable': 'Authentication',
    'clientsRF.authType': 'type',
    'clientsRF.authUseragent': 'useragent',
    'clientsRF.authUsername': 'username',
    'clientsRF.clientRF': 'Client RF',
    'clientsRF.clientRFChartTitle': 'Client RF',
    'clientsRF.clientRFP': 'Client RFP',
    'clientsRF.clientRFSidebar': 'Client RF',
    'clientsRF.clientRFTableTitle': 'Client RF',
    'clientsRF.expectedThroughput': 'Expected bandwidth',
    'clientsRF.noData': 'no data',
    'clientsRF.noise': 'Noise',
    'clientsRF.numberOfEntries': 'Number of entries',
    'clientsRF.paginationClientsOutOf': 'Clients out of',
    'clientsRF.paginationLoad': 'Load',
    'clientsRF.rating': 'Rating',
    'clientsRF.rx': 'Receive',
    'clientsRF.signal': 'Signal',
    'clientsRF.signalP10': 'Signal P10',
    'clientsRF.signalP90': 'Signal P90',
    'clientsRF.snrAvg': 'SNR average',
    'clientsRF.table': 'Table',
    'clientsRF.tableItemsOf': 'items of',
    'clientsRF.tableView': 'view',
    'clientsRF.tx': 'Transmit',
    'configExportImport.chooseAFile': 'Select a file',
    'configExportImport.configExportHeader': 'Export configuration',
    'configExportImport.configImportHeader': 'Import configuration',
    'configExportImport.configSuccessfullyImported': 'Configuration successfully imported',
    'configExportImport.downloadButtonCaption': 'Download a file',
    'configExportImport.exportButtonCaption': 'Export configuration',
    'configExportImport.fileImportCpation': 'File for import',
    'configExportImport.fileParsingError': 'Selected file parsing error',
    'configExportImport.forLocation': 'Import for location',
    'configExportImport.importButtonCaption': 'Import configuration',
    'configExportImport.saveToFileError': 'Failed to save file',
    'configExportImport.selectedFile': 'Selected file',
    'configExportImport.viewFileCaption': 'View file',
    'configExportImport.withChildsFalse': 'without child locations',
    'configExportImport.withChildsTrue': 'with child locations',
    'controller.accessSettings': 'Access settings',
    'controller.active': 'Activity',
    'controller.addController': 'Add controller',
    'controller.confirmDelete': 'Confirm deletion',
    'controller.controller': 'Controller',
    'controller.controllerHeader': 'Controller',
    'controller.controllersList': 'Controllers list',
    'controller.deleteControllerHeader': 'Delete controller',
    'controller.FWversion': 'Firmware version',
    'controller.newController': 'New controller',
    'controller.notificationControlleOperationSuccessfull': 'Successfully',
    'controller.notificationControllerCreatedSuccessfully': 'Controller successfully created',
    'controller.notificationControllerDeletedSuccessfully': 'Controller successfully deleted',
    'controller.notificationControllerUpdatedSuccessfully': 'Controller successfully updated',
    'controller.password': 'Password',
    'controller.serialNumber': 'Serial number',
    'controller.SSHkey': 'SSH key',
    'controller.status': 'Status',
    'controller.username': 'Username',
    'controller.vendor': 'Vendor',
    'controllerDateTimeSettings.changeDateAndTime': 'Change date and time',
    'controllerDateTimeSettings.date': 'Date',
    'controllerDateTimeSettings.dateAndTime': 'Date and time',
    'controllerDateTimeSettings.refresh': 'Refresh',
    'controllerDateTimeSettings.source': 'Source',
    'controllerDateTimeSettings.syncDateAndTime': 'Synchronize time and time zone with your computer',
    'controllerDateTimeSettings.syncDateAndTimeConfirm': 'Are you sure you want to synchronize your time and time zone with your computer?',
    'controllerDateTimeSettings.dateTimeDisplayAsOnControllerHint': 'Displays the time and time zone set on the controller',
    'controllerDateTimeSettings.useDateAndTimeFromBrowser': 'Synchronize time with your computer',
    'controllerDateTimeSettings.timezoneAcronym': 'Timezone',
    'controllerDateTimeSettings.timezoneSelect': 'Select timezone',
    'controllerDateTimeSettings.offsetMinutes': 'Minutes',
    'controllerDateTimeSettings.offsetMinutesHint': '0, 30 or 45',
    'controllerDateTimeSettings.offsetHours': 'Hours',
    'controllerDateTimeSettings.offset': 'Offset from UTC',
    'controllerDateTimeSettings.time': 'Time',
    'controllerDateTimeSettings.timezone': 'Timezone',
    'countries.00': ' World',
    'countries.AD': ' Andorra',
    'countries.AE': ' United Arab Emirates',
    'countries.AF': ' Afghanistan',
    'countries.AG': ' Antigua and Barbuda',
    'countries.AI': ' Anguilla',
    'countries.AL': ' Albania',
    'countries.AM': ' Armenia',
    'countries.AN': ' Netherlands Antilles',
    'countries.AO': ' Angola',
    'countries.AQ': ' Antarctica',
    'countries.AR': ' Argentina',
    'countries.AS': ' American Samoa',
    'countries.AT': ' Austria',
    'countries.AU': ' Australia',
    'countries.AW': ' Aruba',
    'countries.AX': ' Aland Islands',
    'countries.AZ': ' Azerbaijan',
    'countries.BA': ' Bosnia and Herzegovina',
    'countries.BB': ' Barbados',
    'countries.BD': ' Bangladesh',
    'countries.BE': ' Belgium',
    'countries.BF': ' Burkina Faso',
    'countries.BG': ' Bulgaria',
    'countries.BH': ' Bahrain',
    'countries.BI': ' Burundi',
    'countries.BJ': ' Benin',
    'countries.BL': ' Saint Barthelemy',
    'countries.BM': ' Bermuda',
    'countries.BN': ' Brunei Darussalam',
    'countries.BO': ' Bolivia',
    'countries.BR': ' Brazil',
    'countries.BS': ' Bahamas',
    'countries.BT': ' Bhutan',
    'countries.BV': ' Bouvet Island',
    'countries.BW': ' Botswana',
    'countries.BY': ' Belarus',
    'countries.BZ': ' Belize',
    'countries.CA': ' Canada',
    'countries.CC': ' Cocos (Keeling) Islands',
    'countries.CD': ' Congo',
    'countries.CF': ' Central African Republic',
    'countries.CG': ' Congo',
    'countries.CH': ' Switzerland',
    'countries.CI': ' Cote d\\Ivoire',
    'countries.CK': ' Cook Islands',
    'countries.CL': ' Chile',
    'countries.CM': ' Cameroon',
    'countries.CN': ' China',
    'countries.CO': ' Colombia',
    'countries.CR': ' Costa Rica',
    'countries.CU': ' Cuba',
    'countries.CV': ' Cape Verde',
    'countries.CX': ' Christmas Island',
    'countries.CY': ' Cyprus',
    'countries.CZ': ' Czech Republic',
    'countries.DE': ' Germany',
    'countries.DJ': ' Djibouti',
    'countries.DK': ' Denmark',
    'countries.DM': ' Dominica',
    'countries.DO': ' Dominican Republic',
    'countries.DZ': ' Algeria',
    'countries.EC': ' Ecuador',
    'countries.EE': ' Estonia',
    'countries.EG': ' Egypt',
    'countries.EH': ' Western Sahara',
    'countries.ER': ' Eritrea',
    'countries.ES': ' Spain',
    'countries.ET': ' Ethiopia',
    'countries.FI': ' Finland',
    'countries.FJ': ' Fiji',
    'countries.FK': ' Falkland Island',
    'countries.FM': ' Micronesia',
    'countries.FO': ' Faroe Islands',
    'countries.FR': ' France',
    'countries.GA': ' Gabon',
    'countries.GB': ' United Kingdom',
    'countries.GD': ' Grenada',
    'countries.GE': ' Georgia',
    'countries.GF': ' French Guiana',
    'countries.GG': ' Guernsey',
    'countries.GH': ' Ghana',
    'countries.GI': ' Gibraltar',
    'countries.GL': ' Greenland',
    'countries.GM': ' Gambia',
    'countries.GN': ' Guinea',
    'countries.GP': ' Guadeloupe',
    'countries.GQ': ' Equatorial Guinea',
    'countries.GR': ' Greece',
    'countries.GS': ' South Georgia',
    'countries.GT': ' Guatemala',
    'countries.GU': ' Guam',
    'countries.GW': ' Guinea-Bissau',
    'countries.GY': ' Guyana',
    'countries.HK': ' Hong Kong',
    'countries.HM': ' Heard and McDonald Islands',
    'countries.HN': ' Honduras',
    'countries.HR': ' Croatia',
    'countries.HT': ' Haiti',
    'countries.HU': ' Hungary',
    'countries.ID': ' Indonesia',
    'countries.IE': ' Ireland',
    'countries.IL': ' Israel',
    'countries.IM': ' Isle of Man',
    'countries.IN': ' India',
    'countries.IO': ' Chagos Islands',
    'countries.IQ': ' Iraq',
    'countries.IR': ' Iran',
    'countries.IS': ' Iceland',
    'countries.IT': ' Italy',
    'countries.JE': ' Jersey',
    'countries.JM': ' Jamaica',
    'countries.JO': ' Jordan',
    'countries.JP': ' Japan',
    'countries.KE': ' Kenya',
    'countries.KG': ' Kyrgyzstan',
    'countries.KH': ' Cambodia',
    'countries.KI': ' Kiribati',
    'countries.KM': ' Comoros',
    'countries.KN': ' Saint Kitts and Nevis',
    'countries.KP': ' North Korea',
    'countries.KR': ' South Korea',
    'countries.KW': ' Kuwait',
    'countries.KY': ' Cayman Islands',
    'countries.KZ': ' Kazakhstan',
    'countries.LA': ' Laos',
    'countries.LB': ' Lebanon',
    'countries.LC': ' Saint Lucia',
    'countries.LI': ' Liechtenstein',
    'countries.LK': ' Sri Lanka',
    'countries.LR': ' Liberia',
    'countries.LS': ' Lesotho',
    'countries.LT': ' Lithuania',
    'countries.LU': ' Luxembourg',
    'countries.LV': ' Latvia',
    'countries.LY': ' Libyan Arab Jamahiriya',
    'countries.MA': ' Morocco',
    'countries.MC': ' Monaco',
    'countries.MD': ' Moldova',
    'countries.ME': ' Montenegro',
    'countries.MF': ' Saint Martin (French part)',
    'countries.MG': ' Madagascar',
    'countries.MH': ' Marshall Islands',
    'countries.MK': ' Macedonia',
    'countries.ML': ' Mali',
    'countries.MM': ' Myanmar',
    'countries.MN': ' Mongolia',
    'countries.MO': ' Macao',
    'countries.MP': ' Northern Mariana Islands',
    'countries.MQ': ' Martinique',
    'countries.MR': ' Mauritania',
    'countries.MS': ' Montserrat',
    'countries.MT': ' Malta',
    'countries.MU': ' Mauritius',
    'countries.MV': ' Maldives',
    'countries.MW': ' Malawi',
    'countries.MX': ' Mexico',
    'countries.MY': ' Malaysia',
    'countries.MZ': ' Mozambique',
    'countries.NA': ' Namibia',
    'countries.NC': ' New Caledonia',
    'countries.NE': ' Niger',
    'countries.NF': ' Norfolk Island',
    'countries.NG': ' Nigeria',
    'countries.NI': ' Nicaragua',
    'countries.NL': ' Netherlands',
    'countries.NO': ' Norway',
    'countries.NP': ' Nepal',
    'countries.NR': ' Nauru',
    'countries.NU': ' Niue',
    'countries.NZ': ' New Zealand',
    'countries.OM': ' Oman',
    'countries.PA': ' Panama',
    'countries.PE': ' Peru',
    'countries.PF': ' French Polynesia',
    'countries.PG': ' Papua New Guinea',
    'countries.PH': ' Philippines',
    'countries.PK': ' Pakistan',
    'countries.PL': ' Poland',
    'countries.PM': ' Saint Pierre and Miquelon',
    'countries.PN': ' Pitcairn',
    'countries.PR': ' Puerto Rico',
    'countries.PS': ' Palestinian Territory',
    'countries.PT': ' Portugal',
    'countries.PW': ' Palau',
    'countries.PY': ' Paraguay',
    'countries.QA': ' Qatar',
    'countries.RE': ' Reunion',
    'countries.RO': ' Romania',
    'countries.RS': ' Serbia',
    'countries.RU': ' Russian Federation',
    'countries.RW': ' Rwanda',
    'countries.SA': ' Saudi Arabia',
    'countries.SB': ' Solomon Islands',
    'countries.SC': ' Seychelles',
    'countries.SD': ' Sudan',
    'countries.SE': ' Sweden',
    'countries.SG': ' Singapore',
    'countries.SH': ' St. Helena and Dependencies',
    'countries.SI': ' Slovenia',
    'countries.SJ': ' Svalbard and Jan Mayen',
    'countries.SK': ' Slovakia',
    'countries.SL': ' Sierra Leone',
    'countries.SM': ' San Marino',
    'countries.SN': ' Senegal',
    'countries.SO': ' Somalia',
    'countries.SR': ' Suriname',
    'countries.ST': ' Sao Tome and Principe',
    'countries.SV': ' El Salvador',
    'countries.SY': ' Syrian Arab Republic',
    'countries.SZ': ' Swaziland',
    'countries.TC': ' Turks and Caicos Islands',
    'countries.TD': ' Chad',
    'countries.TF': ' French Southern Territories',
    'countries.TG': ' Togo',
    'countries.TH': ' Thailand',
    'countries.TJ': ' Tajikistan',
    'countries.TK': ' Tokelau',
    'countries.TL': ' Timor-Leste',
    'countries.TM': ' Turkmenistan',
    'countries.TN': ' Tunisia',
    'countries.TO': ' Tonga',
    'countries.TR': ' Turkey',
    'countries.TT': ' Trinidad and Tobago',
    'countries.TV': ' Tuvalu',
    'countries.TW': ' Taiwan',
    'countries.TZ': ' Tanzania',
    'countries.UA': ' Ukraine',
    'countries.UG': ' Uganda',
    'countries.UM': ' U.S. Minor Outlying Islands',
    'countries.US': ' United States',
    'countries.UY': ' Uruguay',
    'countries.UZ': ' Uzbekistan',
    'countries.VA': ' Vatican City State',
    'countries.VC': ' St. Vincent and Grenadine',
    'countries.VE': ' Venezuela',
    'countries.VG': ' Virgin Islands British',
    'countries.VI': ' Virgin Islands U.S.',
    'countries.VN': ' Viet Nam',
    'countries.VU': ' Vanuatu',
    'countries.WF': ' Wallis and Futuna',
    'countries.WS': ' Samoa',
    'countries.YE': ' Yemen',
    'countries.YT': ' Mayotte',
    'countries.ZA': ' South Africa',
    'countries.ZM': ' Zambia',
    'countries.ZW': ' Zimbabwe',
    'dashboard.ads': 'Advert',
    'dashboard.adsCounter': 'Views',
    'dashboard.adsStatistic': 'Advert view statistics',
    'dashboard.adsStatisticCaption': 'for this month, the best view days are shown',
    'dashboard.adsStatisticTotal': 'Total advert views',
    'dashboard.adsTotal': 'Total adverts',
    'dashboard.adsType': 'Type',
    'dashboard.available': 'Available',
    'dashboard.cache': 'Cache',
    'dashboard.configCPEs': 'APs state',
    'dashboard.connected': 'Connected',
    'dashboard.connectedCPEs': 'Connected APs',
    'dashboard.connectionsStat': 'Connections statistics',
    'dashboard.connectionsStatCaption': 'last 7 days',
    'dashboard.CPE': 'AP',
    'dashboard.cpeChartSeriesName': 'APs',
    'dashboard.cpeStatusChartSeriesName': 'APs',
    'dashboard.cpu': 'CPU',
    'dashboard.cpuCores': 'Cores',
    'dashboard.cpuCount': 'Number of CPU',
    'dashboard.cpuLoad': 'CPU load',
    'dashboard.empty': 'Empty',
    'dashboard.ERROR': 'Error',
    'dashboard.Events': 'Events',
    'dashboard.free': 'Free',
    'dashboard.gb': 'Gbytes',
    'dashboard.goBackToClients': 'Back to clients',
    'dashboard.goBackToDashboard': 'Back to dashboard',
    'dashboard.goToCpeSettings': 'AP settings',
    'dashboard.goToCpeStatistic': 'Statistics',
    'dashboard.INFO': 'Information',
    'dashboard.kb': 'Kbytes',
    'dashboard.last10events': 'Events summary for 24h',
    'dashboard.load': 'Load',
    'dashboard.load_core': 'Load per core',
    'dashboard.load1': 'Last minute',
    'dashboard.load15': 'Last 15 minutes',
    'dashboard.load5': 'Last 5 minutes',
    'dashboard.locationAddress': 'Address',
    'dashboard.locationCreated': 'Created',
    'dashboard.locationManager': 'Manager',
    'dashboard.locationOwner': 'Owner',
    'dashboard.locations': 'Locations',
    'dashboard.locationsCaption': '3 random locations',
    'dashboard.locationsMap': 'Map of locations',
    'dashboard.locationsMapCaption': 'Only locations with coordinates are displayed',
    'dashboard.locationsTotal': 'Total locations',
    'dashboard.locationsWhereManager': 'Locations where you are the manager ',
    'dashboard.locationsWhereManagerCaption': 'Only locations where you are the manager are displayed',
    'dashboard.mb': 'Mbytes',
    'dashboard.memory': 'Memory',
    'dashboard.memoryFree': 'Memory free',
    'dashboard.memoryTotal': 'Memory total',
    'dashboard.memoryUsed': 'Memory used',
    'dashboard.model': 'Model',
    'dashboard.modelCPU': 'Model CPU',
    'dashboard.mostactiveclients': 'Most active clients',
    'dashboard.mostactiveCPEs': 'Most active APs',
    'dashboard.mostloadCPEs': 'Most load APs',
    'dashboard.notConnected': 'Not connected',
    'dashboard.offline': 'Offline',
    'dashboard.online': 'Online',
    'dashboard.os': 'OS',
    'dashboard.radar': 'Wi-Fi target',
    'dashboard.received': 'Received',
    'dashboard.rule': 'Rule',
    'dashboard.serverInfo': 'Server information',
    'dashboard.service': 'Services',
    'dashboard.toAds': 'View advert',
    'dashboard.toAdsStatistic': 'View advert views statistics',
    'dashboard.toClients': 'View clients',
    'dashboard.toConnectionsStat': 'View connections statistics',
    'dashboard.toCPEs': 'View APs',
    'dashboard.toEvents': 'View events',
    'dashboard.toLocations': 'View locations',
    'dashboard.toMarketing': 'View analytics',
    'dashboard.total': 'Total',
    'dashboard.trafficIn': 'RX',
    'dashboard.trafficOut': 'TX traffic',
    'dashboard.transmitted': 'Transmitted',
    'dashboard.updating': 'In process',
    'dashboard.uptime': 'Uptime day',
    'dashboard.used': 'Used',
    'dashboard.WARNING': 'Warning',
    'DBState.Bytes': 'Bytes',
    'DBState.BytesShort': 'B',
    'DBState.Gbytes': 'Gbytes',
    'DBState.GbytesShort': 'Gb',
    'DBState.Kbytes': 'Kbytes',
    'DBState.KbytesShort': 'Kb',
    'DBState.Mbytes': 'Mbytes',
    'DBState.MbytesShort': 'Mb',
    'DBState.sortingSortByCount': 'By objects count',
    'DBState.sortingSortByNs': 'By collection name',
    'DBState.sortingSortBySize': 'By data size',
    'DBState.sortingSortByStorageSize': 'By data size on disk',
    'DBState.tableColumnCount': 'Number of objects',
    'DBState.tableColumnNs': 'Collection name',
    'DBState.tableColumnSize': 'Data size',
    'DBState.tableColumnStorageSize': 'Data size on disk',
    'DBState.tableTitle': 'DB state',
    'DBState.units': 'Units',
    'DITStat.cancelSelectAllCpesButtonCaption': 'Clear all selected APs',
    'DITStat.createDitButton': 'Create DIT export',
    'DITStat.deleteDitModalHeader': 'Delete a DIT export',
    'DITStat.deleteDitModalText': 'Delete a DIT export',
    'DITStat.DITStatNotificationCreated': 'DIT statistics export successfully created',
    'DITStat.DITStatNotificationDeleted': 'DIT statistics export successfully deleted',
    'DITStat.DITStatNotificationUpdated': 'DIT statistics exportt successfully updated',
    'DITStat.filtersForCpeListFiltringForCpeListIsEnabled': 'enabled',
    'DITStat.filtersForCpeListResetFiltersButton': 'reset filters',
    'DITStat.filtersForCpeListSwitcherCaption': 'Filtering a list of APs',
    'DITStat.filtersForCpeListSwitcherInfoTooltip': 'Filtering makes it easier to find the desired AP in the AP selection list. Allows you to filter access points by their location.',
    'DITStat.filtersForCpeListSwitcherLocationCaption': 'AP location',
    'DITStat.modalCpeListNoOptionsMsg': 'No APs',
    'DITStat.modalCpeListNoOptionsMsgWhenFilterEnabled': 'No APs matching filter conditions',
    'DITStat.modalCpeListNoResultMsg': 'No APs with the same name was found',
    'DITStat.modalCpeListNoResultMsgWhenFilterEnabled': 'No APs with the name matching the filter conditions was found',
    'DITStat.modalCpeListPlaceholder': 'Select APs or enter a name to search',
    'DITStat.modalCPEsCaption': 'APs',
    'DITStat.modalEnebledCaption': 'Enabled',
    'DITStat.modalFormatCaption': 'Export format',
    'DITStat.modalFTPServerCaption': 'FTP Server',
    'DITStat.modalFTPServerPlaceholder': 'Provide the FTP server address in the ip:port format (for example 127.0.0.1:21)',
    'DITStat.modalHeaderForEdit': 'Edit DIT export',
    'DITStat.modalHeaderForNew': 'Create DIT export',
    'DITStat.modalIntervalCaption': 'Export interval',
    'DITStat.modalIntervalMinutes': 'in minutes',
    'DITStat.modalIntervalPlaceholder': 'Provide the interval in minutes for exporting statistics',
    'DITStat.modalLoginCaption': 'Username for FTP server',
    'DITStat.modalLoginPlaceholder': 'Provide the FTP server username',
    'DITStat.modalPasswordCaption': 'FTP Server password',
    'DITStat.modalPasswordPlaceholder': 'Enter the FTP server password',
    'DITStat.modalPrivateKeyCaption': 'Private key for SFTP server',
    'DITStat.modalPrivateKeyChangeButton': 'Change the private key',
    'DITStat.modalPrivateKeyPlaceholder': 'Provide the private key for SFTP server',
    'DITStat.modalSelectedCPEsCaption': 'Selected',
    'DITStat.modalSelectedCpesLengthError': 'Select at least one AP',
    'DITStat.modalSFTPAuthTypeCaption': 'Select the type of authentication for the SFTP server',
    'DITStat.selectAllCpesButtonCaption': 'Add all filtered APs',
    'DITStat.SFTPAuthTypePassword': 'Password',
    'DITStat.SFTPAuthTypePrivateKey': 'Private Key',
    'DITStat.sftpSwitcherCaption': 'Enable SFTP',
    'DITStat.sftpSwitcherInfo': 'Allows you to use SFTP server for export',
    'DITStat.tableColumnCPE': 'APs',
    'DITStat.tableColumnEnabled': 'Status',
    'DITStat.tableColumnFormat': 'Format',
    'DITStat.tableColumnFtpLogin': 'Username',
    'DITStat.tableColumnFtpPassword': 'Password',
    'DITStat.tableColumnFtpServer': 'FTP Server',
    'DITStat.tableColumnId': 'ID',
    'DITStat.tableColumnInterval': 'Export interval',
    'DITStat.tableColumnPrivateKey': 'Private key',
    'DITStat.tableColumnPrivateKeyInfo': 'The private key can only be used for SFTP servers',
    'DITStat.tableTitle': 'DIT statistics exports',
    'downloadFilesNames.analyticsVisitors': 'Analytics - Visitors',
    'downloadFilesNames.clients': 'Clients',
    'downloadFilesNames.cpes': 'APs',
    'downloadFilesNames.licenses': 'Licenses',
    'downloadFilesNames.monitoringCPEConnectivity': 'Monitoring - AP connectivity',
    'downloadFilesNames.monitoringDBState': 'Monitoring - DB state',
    'downloadFilesNames.monitoringEvents': 'Monitoring - Events',
    'downloadFilesNames.portalAccessServers': 'Portal - Access servers',
    'downloadFilesNames.portalAccounts': 'Portal - Accounts',
    'downloadFilesNames.portalAdsAndPollsStatisticSection': 'Adverts views statistics',
    'downloadFilesNames.portalAdsAndPollsStatisticSectionAll': 'all types',
    'downloadFilesNames.portalAdsAndPollsStatisticSectionBy': 'by authorisation type',
    'downloadFilesNames.portalBirthdays': 'Portal - Birthdays for',
    'downloadFilesNames.portalHotelVouchers': 'Hotel vouchers',
    'downloadFilesNames.portalInAdsAndPollsStatisticSection': 'Adverts views statistics',
    'downloadFilesNames.portalInAdsAndPollsStatisticSectionAll': 'all types',
    'downloadFilesNames.portalInAdsAndPollsStatisticSectionBy': 'by authorisation type',
    'downloadFilesNames.portalStatistic': 'Portal - Statistics',
    'downloadFilesNames.portalStatisticLineChartAuthenticationType': 'Authentication type',
    'downloadFilesNames.portalStatisticLineChartAuthorizationType': 'Authorisation type',
    'downloadFilesNames.portalStatisticLineChartDeviceType': 'Device type',
    'downloadFilesNames.portalStatisticLineChartLocale': 'Locale',
    'downloadFilesNames.portalStatisticLineChartNewAndRepeatUsers': 'New and returned users',
    'downloadFilesNames.portalStatisticLineChartOperatingSystems': 'Operating systems',
    'downloadFilesNames.portalStatisticLineChartVendors': 'Vendors',
    'downloadFilesNames.portalStatisticPieChartAuthenticationType': 'Authentication type',
    'downloadFilesNames.portalStatisticPieChartAuthorizationType': 'Authorisation types',
    'downloadFilesNames.portalStatisticPieChartBrowsers': 'Browsers',
    'downloadFilesNames.portalStatisticPieChartDeviceType': 'Device type',
    'downloadFilesNames.portalStatisticPieChartLocale': 'Locale',
    'downloadFilesNames.portalStatisticPieChartOperatingSystems': 'Operating systems',
    'downloadFilesNames.portalStatisticPieChartUsers': 'Users',
    'downloadFilesNames.portalStatisticPieChartVendors': 'Vendors',
    'downloadFilesNames.portalVouchers': 'Vouchers',
    'downloadFilesNames.statClients': 'Statistics - Clients',
    'downloadFilesNames.statClientsSessions': 'Statistics - Clients - Sessions',
    'downloadFilesNames.statCPEs': 'Statistics - APs',
    'downloadFilesNames.statSNMP': 'Statistics - SNMP',
    'downloadFilesNames.statSystem': 'Statistics - Locations',
    'downloadFilesNames.statWLANs': 'Statistics - WLANs',
    'downloadFilesNames.users': 'Users',
    'downloadFilesNames.visitors': 'Visitors',
    'drPicker.always': 'Always',
    'drPicker.apply': 'Select',
    'drPicker.april_full': 'April',
    'drPicker.april_short': 'Apr',
    'drPicker.august_full': 'August',
    'drPicker.august_short': 'Aug',
    'drPicker.cancel': 'Cancel',
    'drPicker.chooseInterval': 'Select time period',
    'drPicker.custom': 'Custom',
    'drPicker.days': 'days',
    'drPicker.december_full': 'December',
    'drPicker.december_short': 'Dec',
    'drPicker.february_full': 'February',
    'drPicker.february_short': 'Feb',
    'drPicker.friday_short': 'Fr',
    'drPicker.from': 'From',
    'drPicker.hours': 'hours',
    'drPicker.january_full': 'January',
    'drPicker.january_short': 'Jan',
    'drPicker.july_full': 'July',
    'drPicker.july_short': 'Jul',
    'drPicker.june_full': 'June',
    'drPicker.june_short': 'Jun',
    'drPicker.last': 'Last',
    'drPicker.last24': 'Last 24 hours',
    'drPicker.last30': 'Last 30 days',
    'drPicker.last48': 'Last 48 hours',
    'drPicker.last7': 'Last 7 days',
    'drPicker.march_full': 'March',
    'drPicker.march_short': 'Mar',
    'drPicker.maxDateRangeLimit': 'Maximum interval for selection',
    'drPicker.may_full': 'May',
    'drPicker.may_short': 'May',
    'drPicker.minutes': 'min',
    'drPicker.monday_short': 'Mo',
    'drPicker.month': 'This month',
    'drPicker.months': 'months',
    'drPicker.next': 'Next',
    'drPicker.november_full': 'November',
    'drPicker.november_short': 'Nov',
    'drPicker.october_full': 'October',
    'drPicker.october_short': 'Oct',
    'drPicker.prev_month': 'Previous month',
    'drPicker.prev_week': 'Previous week',
    'drPicker.saturday_short': 'Sa',
    'drPicker.seconds': 'seconds',
    'drPicker.september_full': 'September',
    'drPicker.september_short': 'Sept',
    'drPicker.sunday_short': 'Su',
    'drPicker.thursday_short': 'Th',
    'drPicker.to': 'To',
    'drPicker.today': 'Today',
    'drPicker.tuesday_short': 'Tu',
    'drPicker.wednesday_short': 'We',
    'drPicker.week': 'This week',
    'drPicker.weeks': 'weeks',
    'drPicker.years': 'years',
    'drPicker.yesterday': 'Yesterday',
    'easyInstallWizard.badgeSidebarInProgress': 'In process',
    'easyInstallWizard.easyInstallButtonInSidebar': 'Simple installation',
    'easyInstallWizard.headerCaption': 'Wizard for WLAN and portal creation',
    'easyInstallWizard.step1Caption': 'Create WLAN',
    'easyInstallWizard.step1Header': 'Step 1: Create a WLAN',
    'easyInstallWizard.step1NextButton': 'Create',
    'easyInstallWizard.step1SuiteRequiredsError': 'The "Encryption" field is required',
    'easyInstallWizard.step1WhereIsLookingForThisSettings': '"WLANS"',
    'easyInstallWizard.step2Caption': 'Create page',
    'easyInstallWizard.step2FieldPageName': 'Page name',
    'easyInstallWizard.step2Header': 'Step 2: Create a page',
    'easyInstallWizard.step2NextButton': 'Create',
    'easyInstallWizard.step2WhereIsLookingForThisSettings': '"Portal"  -> "Configuration" -> "Pages"',
    'easyInstallWizard.step3Caption': 'Create redirect rule',
    'easyInstallWizard.step3Header': 'Step 3: Create a redirect rule',
    'easyInstallWizard.step3NextButton': 'Create',
    'easyInstallWizard.step3PrevStepPageURL': 'URL of the portal page created on the previous step',
    'easyInstallWizard.step3WhereIsLookingForThisSettings': '"Guest Control" ',
    'easyInstallWizard.step4Caption': 'Adding previously created WLAN to the necessary APs',
    'easyInstallWizard.step4CPESPlaceholder': 'Select one or several Aps',
    'easyInstallWizard.step4Header': 'Step 4: Adding a WLAN to the necessary APs',
    'easyInstallWizard.step4Hint': 'Select APs for previously created WLAN addition',
    'easyInstallWizard.step4NextButton': 'Add',
    'easyInstallWizard.step4WhereIsLookingForThisSettings': '"APs"',
    'easyInstallWizard.step4WhereIsLookingForThisSettingsstep5Caption': 'Create profile',
    'easyInstallWizard.step4WhereIsLookingForThisSettingsstep5Header': 'Step 5: Create profile',
    'easyInstallWizard.step4WhereIsLookingForThisSettingsstep5WhereIsLookingForThisSettings': 'Portal -> Configuration -> Profiles',
    'easyInstallWizard.step5NextButton': 'Create',
    'easyInstallWizard.whereIsLookingForThisSettings': 'Where you can find these settings in the future',
    'events.chartEventsAlert': 'Only events loaded in table are showed on the chart',
    'events.clientRFAndEventsAuthorizationChartSeries': 'Authorisation',
    'events.clientRFAndEventsButtonCaption': 'Events and RSSI',
    'events.clientRFAndEventsChartEventsCaption': 'Events',
    'events.clientRFAndEventsClientCaption': 'Client',
    'events.clientRFAndEventsConnectedChartSeries': 'Connected',
    'events.clientRFAndEventsDhcp_AckChartSeries': 'DHCP ACK',
    'events.clientRFAndEventsDisconnectedChartSeries': 'Disconnected',
    'events.clientRFAndEventsEventHeader': 'Event',
    'events.clientRFAndEventsModalCaption': 'Events and RSSI',
    'events.clientRFAndEventsNumberOfEventsCaption': 'Number of events',
    'events.clientRFAndEventsRFHeader': 'RF data',
    'events.copyToClipboard': 'Copy to clipboard',
    'events.cpeName': 'AP name',
    'events.description': 'description',
    'events.errorEvents': 'Error',
    'events.eventData': 'Event data',
    'events.events': 'Events',
    'events.eventsChart': 'Events chart',
    'events.eventsTable': 'Events',
    'events.filterACK': 'DHCP ACK',
    'events.filterAUTHORIZATION': 'Authorisation',
    'events.filterCLIENT': 'Client',
    'events.filterCLIENT AUTH': 'Authentication',
    'events.filterCONNECTED': 'Connected',
    'events.filterCPE': 'AP',
    'events.filterDISCONNECTED': 'Disconnected',
    'events.filterERROR': 'Error',
    'events.filterFIRMWARE': 'Firmware',
    'events.filterINFO': 'Information',
    'events.filterLOG': 'Log',
    'events.filterRULE': 'Rule',
    'events.filterSERVICE': 'Service',
    'events.filterWARNING': 'Warning',
    'events.goBackToClients': 'Back to clients',
    'events.goBackToCPE': 'Go back to AP',
    'events.in24Hours': '24 hours',
    'events.itemsOf': 'items of',
    'events.level': 'Level',
    'events.noData': 'No data. Try another time period and/or other filters',
    'events.noDataForSelectedDateRangeForSelectedCPE': 'There is no data for the selected AP. Try to choose a different time period',
    'events.paginationEventsOutOf': 'Events out of',
    'events.paginationLoad': 'Load',
    'events.placeHolderSearch': 'Search',
    'events.sendConfigButton': 'Send configuration script to problem APs',
    'events.sendConfigDuccessMessage': 'Configuration script successfully sent',
    'events.sendConfigModalHintMessage': 'The script will be sent to APs with errors of the following type',
    'events.showPayload': 'Show full payload',
    'events.status': 'Status',
    'events.subject': 'Subject',
    'events.subjectId': 'Subject ID',
    'events.summary': 'Summary',
    'events.time': 'Time',
    'events.timestamp': 'Time',
    'events.type': 'Type',
    'events.view': 'view',
    'externalAP.addExternalAP': 'Add external AP',
    'externalAP.byMac': 'By MAC',
    'externalAP.byName': 'By name',
    'externalAP.bySN': 'By serial number',
    'externalAP.confirmDelete': 'Confirm deletion',
    'externalAP.deleteExternalAPHeader': 'Delete an external AP',
    'externalAP.description': 'Description',
    'externalAP.externalAPHeader': 'External AP',
    'externalAP.externalAPHeaderAdd': 'Add external AP',
    'externalAP.externalApsList': 'External APs',
    'externalAP.externalAPsOutOf': 'external AP of',
    'externalAP.fw_version': 'Firmware version',
    'externalAP.ip_addr': 'IP',
    'externalAP.load': 'Load',
    'externalAP.mac': 'MAC',
    'externalAP.name': 'Name',
    'externalAP.notificationExternalApCreatedSuccessfully': 'External AP successfully created',
    'externalAP.notificationExternalApDeletedSuccessfully': 'External AP successfully deleted',
    'externalAP.notificationExternalApOperationSuccessfull': 'Successfully',
    'externalAP.notificationExternalApUpdatedSuccessfully': 'External AP successfully updated',
    'externalAP.password': 'Password',
    'externalAP.port': 'Port',
    'externalAP.serial': 'Serial number',
    'externalAP.setTrapServer': 'Automatic trap-server configuration',
    'externalAP.snmp': 'SNMP',
    'externalAP.snmpCommunity': 'Community',
    'externalAP.snmpPort': 'Port',
    'externalAP.snmpVersion': 'Version',
    'externalAP.ssh': 'SSH',
    'externalAP.sshKey': 'SSH key',
    'externalAP.sshKeyORPasswordMustNotEmptyError': 'The "Password" or "SSH key" field is required',
    'externalAP.sshKeyORPasswordOnlyOneError': 'Only one field must be filled in: "Password" or "SSH key"',
    'externalAP.status': 'Status',
    'externalAP.username': 'Username',
    'externalAP.vendor': 'Vendor',
    'firewall.Action': 'Action',
    'firewall.addFirewall': 'Add a firewall',
    'firewall.addNewRule': 'Add new firewall rule',
    'firewall.AddRule': 'Add rule',
    'firewall.ANY': 'Any',
    'firewall.confirmButton': 'Confirm',
    'firewall.createFirewall': 'Firewall creation wizard',
    'firewall.deleteRuleButton': 'Delete rule',
    'firewall.Direction': 'Direction',
    'firewall.direction': 'Direction',
    'firewall.editRule': 'Edit rule',
    'firewall.enterIPPlaceholder': 'Enter IP address',
    'firewall.enterMACPlaceholder': 'Enter MAC address',
    'firewall.enterPortPlaceholder': 'Enter port',
    'firewall.firewall': 'Firewall',
    'firewall.firewallNamePlaceHolder': 'Enter firewall name',
    'firewall.Firewalls': 'Firewalls',
    'firewall.firewallSettings': 'Firewall settings',
    'firewall.fromIP': 'From IP',
    'firewall.fromIPaddress': 'From IP address',
    'firewall.fromMAC': 'From MAC',
    'firewall.fromMACaddress': 'From MAC address',
    'firewall.fromPort': 'From port',
    'firewall.fromPortModal': 'From port',
    'firewall.IN': 'In',
    'firewall.infoMessage': 'Our firewall is based on IP-tables. Rules are created in its notation. The documentation for the rules can be found here.',
    'firewall.infoTooltip': 'Click to view info about firewall',
    'firewall.Internetlayer': 'Internet layer',
    'firewall.Linklayer': 'Link layer',
    'firewall.modalDeleteFirewallText': 'Confirm deletion',
    'firewall.modalDeleteFirewallTitle': 'Delete firewall',
    'firewall.notificationFirewallCreatedSuccessfully': 'Firewall successfully created',
    'firewall.notificationFirewallDeletedSuccessfully': 'Firewall successfully deleted',
    'firewall.notificationFirewallOperationSuccessfull': 'Successfully',
    'firewall.notificationFirewallUpdatedSuccessfully': 'Firewall successfully updated',
    'firewall.OUT': 'Out',
    'firewall.Policy': 'Policy',
    'firewall.policy': 'Policy',
    'firewall.ruleIPProtocol': 'IP protocol',
    'firewall.ruleProtocol': 'Protocol',
    'firewall.Rules': 'Rules',
    'firewall.rules': 'Rules',
    'firewall.RuleSettings': 'Rule settings',
    'firewall.SaveRule': 'Save rule',
    'firewall.toIP': 'To IP',
    'firewall.toIPaddress': 'To IP address',
    'firewall.toMAC': 'To MAC',
    'firewall.toMACaddress': 'To MAC address',
    'firewall.toPort': 'To port',
    'firewall.toPortModal': 'To port',
    'firewall.Transportlayer': 'Transport layer',
    'firewall.wizard1Caption': 'Fill required firewall parameters',
    'firewall.wizard2Caption': 'Fill firewall rules',
    'firewall.wizard3Caption': 'Confirm new firewall creation',
    'firewall.wizardNoRules': 'No rules',
    'general.multiEdit': 'Multiple edit',
    'general.multiEditMode': 'Multiple edit mode',
    'general.ipField': 'IP address',
    'general.ipFieldHint': 'IP address in X.X.X.X format',
    'general.account': 'Account',
    'general.active': 'Active',
    'general.add': 'Add',
    'general.here': 'here',
    'general.update': 'Update',
    'general.clone': 'Clone',
    'general.address': 'Address',
    'general.addTags': 'Add tags',
    'general.advancedParameters': 'Advanced settings',
    'general.all': 'All',
    'general.allCompanys': 'All companies',
    'general.allLocations': 'All locations',
    'general.allModels': 'All models',
    'general.allRoles': 'All roles',
    'general.allStatuses': 'All statuses',
    'general.allTags': 'All tags',
    'general.allWlans': 'All WLANs',
    'general.Always': 'Always',
    'general.another': 'Another',
    'general.apply': 'Apply',
    'general.applyFilters': 'Apply filters',
    'general.Auto': 'Auto',
    'general.auto': 'Auto',
    'general.back': 'Back',
    'general.baseLocation': 'Base location',
    'general.basicParameters': 'Basic parameters',
    'general.byDefault': 'by default',
    'general.capslock': 'Caps Lock is ON',
    'general.cancel': 'Cancel',
    'general.change': 'Change',
    'general.changeLocations': 'Change locations',
    'general.chooseFile': 'Select a file',
    'general.clear': 'Clear',
    'general.clickAndDrag': '#Н/Д',
    'general.clientRF': 'Client RF',
    'general.clients': 'Clients',
    'general.close': 'Close',
    'general.closeModal': 'Close the window',
    'general.closeModalConfirm': 'Please confirm the window closing.\nUnsaved data will be lost.',
    'general.company': 'Company',
    'general.confirm': 'Confirm',
    'general.confirmDeleting': 'Confirm deletion',
    'general.confirmDeletingText': 'Please confirm deleting',
    'general.confirmDeletingTitle': 'Deletion confirmation',
    'general.connectedDisconnectedShort': 'Conn./Disconn.',
    'general.connectedShort': 'conn.',
    'general.copy': 'Copy',
    'general.copyToClipBoardCaption': 'Copy to clipboard',
    'general.cpe': 'APs',
    'general.cpeOne': 'AP',
    'general.cpes': 'APs',
    'general.cpeSeveral': 'APs',
    'general.cpuLoad': 'CPU load',
    'general.create': 'Create',
    'general.csvFileUUIds': 'CSV file with UUIDs',
    'general.date': 'Date',
    'general.dateRangeLimitExceededError': 'The selected range more then',
    'general.day': 'day',
    'general.dayShort': 'day',
    'general.dBm': 'dBm',
    'general.delete': 'Delete',
    'general.delete_sm': 'delete',
    'general.description': 'Description',
    'general.noDescription': 'No description',
    'general.differenceBetweenPeriods': 'Difference',
    'general.differenceGrow': 'growth',
    'general.differenceLoss': 'loss',
    'general.disabled': 'disabled',
    'general.disconnectedShort': 'disconn.',
    'general.download': 'Download',
    'general.downloadAllPng': 'Download consolidated report',
    'general.downloadVouchers': 'Download vouchers',
    'general.downloadCsv': 'Download csv',
    'general.downloadFiles': 'Download table with data',
    'general.downloadPdf': 'Download pdf',
    'general.downloadPng': 'Save image',
    'general.downloadXls': 'Download xls',
    'general.downloadXlsx': 'Download xlsx',
    'general.duration12h': '12 hours',
    'general.durationDay': '24 hours',
    'general.durationDays': '{days} days',
    'general.durationMonth': 'Month',
    'general.durationWeek': 'Week',
    'general.durationYear': 'Year',
    'general.edit': 'edit',
    'general.Edit': 'edit',
    'general.editingMode': 'Edit mode',
    'general.email': 'E-mail',
    'general.empty': 'Empty',
    'general.enable': 'Enable',
    'general.disable': 'Disable',
    'general.enabled': 'enabled',
    'general.enableShort': 'Enabled',
    'general.expand': 'Expand',
    'general.feltersDateRange': 'Date range',
    'general.filter': 'Filter',
    'general.filterPageLimitSmall': 'Numb. of entr.',
    'general.filters': 'Filters',
    'general.filtersCity': 'Current city',
    'general.filtersGender': 'Gender',
    'general.filtersHomeTown': 'City of birth',
    'general.filtersNasId': 'NAS ID',
    'general.filtersPageLimit': 'Number of entries',
    'general.filtersPageOffset': 'Show starting from',
    'general.filtersSocialNetwork': 'Social network',
    'general.filtersSort': 'Sort',
    'general.filtersSubscription': 'Subscription status',
    'general.filtersSubscriptionAtTime': 'Subscription date',
    'general.filtersSubscriptionStatus': 'Subscription status',
    'general.filtersTownPlaceholder': 'City name',
    'general.filtersVisitsAll': 'Visits',
    'general.filtersYearOfBirth': 'Year of birth',
    'general.firstConnected': 'First connected',
    'general.for': 'for',
    'general.forCurrentPeriod': 'For the current period',
    'general.format': 'Format',
    'general.forPreviousPeriod': 'For the previous period',
    'general.found': 'Found',
    'general.fr': 'Fr',
    'general.freeRAM': 'Free RAM',
    'general.frequency': 'Frequency',
    'general.from': 'from',
    'general.fullscreen.expand': 'Enter Fullscreen',
    'general.fullscreen.collapse': 'Exit Fullscreen',
    'general.generatePassword': 'Generate password',
    'general.hideDescription': 'Hide description',
    'general.hideid': 'hide id',
    'general.historyRequests': 'History requests',
    'general.hour': 'hour',
    'general.hours': 'Hours',
    'general.hourShort': 'hour',
    'general.info': 'Information',
    'general.infoShort': 'Information',
    'general.insec': 'in sec.',
    'general.interface': 'Interface',
    'general.itemsPerPage': 'items per page',
    'general.kbit': 'Kbit',
    'general.language.en': 'English',
    'general.language.ru': 'Russian',
    'general.lastDisconnected': 'Last disconnected',
    'general.location': 'Location',
    'general.locations': 'Locations',
    'general.locationsAll': 'All',
    'general.locationSelect': 'Select location',
    'general.mustBeLessThan': 'must be less than',
    'generan.mustBeGreaterThan': 'must be greater than',
    'general.mac': 'MAC',
    'general.maxOneElement': 'Maximum of options selected. First remove some of selected options to select another.',
    'general.mbit': 'Mbit',
    'general.mbitS': 'Mbit/s',
    'general.memoryLoad': 'Memory load',
    'general.mhz': 'MHz',
    'general.min': 'min',
    'general.minimize': 'Minimize',
    'general.minShort': 'min',
    'general.mo': 'Mo',
    'general.modalChangeLocationsCaption': 'Change locations for the following',
    'general.Mode': 'Mode',
    'general.model': 'Model',
    'general.month': 'Month',
    'general.multiSelectDeselectLabel': 'Press "Enter" to remove selection',
    'general.multiSelectPressToSelect': 'Press "Enter" to select',
    'general.multiSelectSelected': 'Selected',
    'general.name': 'Name',
    'general.next': 'Next',
    'general.no': 'No',
    'general.noData': 'no data',
    'general.noDataToDisplay': 'No data to display',
    'general.noDataToDisplayWhenCPESelected': 'No data for selected AP to display',
    'general.noDataToDisplayWhenCPESelectedAndTimeRange': 'There is no data for this AP to display for chosen period of time with selected filter settings',
    'general.noModel': 'No model',
    'general.noOptionsForSearch': 'Start typing for search',
    'general.noResultForSearch': 'No search results',
    'general.maxElementsSelected': 'Maximum of {max} options selected. First remove a selected option to select another.',
    'general.notActive': 'Not active',
    'general.noTag': 'No tag',
    'general.noTags': 'no tags',
    'general.notFound': 'not found',
    'general.object': 'Object',
    'general.of': 'of',
    'general.Off': 'Off',
    'general.off': 'Off',
    'general.ofTotal': 'of total',
    'general.offset': 'Offset',
    'general.On': 'On',
    'general.on': 'On',
    'general.orderAsc': 'Ascending',
    'general.orderDesc': 'Descending',
    'general.password': 'Password',
    'general.pagination.firstPage': 'First',
    'general.pagination.lastPage': 'Last',
    'general.pagination.nextPage': 'Next',
    'general.pagination.prevPage': 'Prev',
    'general.pagination.rowsPerPage': 'Rows per page',
    'general.pagination.of': 'of',
    'general.pagination.page': 'page',
    'general.pagination.all': 'All',
    'general.pending': 'Pending',
    'general.port': 'port',
    'general.previouslySelected': 'Previously selected ',
    'general.readOnlyMode': 'Read only mode',
    'general.removeAll': 'remove all',
    'general.resetAllFilters': 'Reset all filters',
    'general.role.admin': 'admin',
    'general.role.advertising': 'advertising agent',
    'general.role.hotel_employee': 'hotel representative',
    'general.role.marketer': 'marketer',
    'general.role.operator': 'operator',
    'general.rub': 'rub.',
    'general.rules': 'Rules',
    'general.sa': 'Sa',
    'general.save': 'Save',
    'general.search': 'Search',
    'general.searchPlaceHolder': 'Search',
    'general.sec': 'sec',
    'general.secShort': 'sec',
    'general.msShort': 'ms',
    'general.select': 'select',
    'general.selectAll': 'select all',
    'general.selected': 'Selected',
    'general.selectedForTables': 'selected',
    'general.searchBarPlaceholder': 'Select AP\\Clients',
    'general.selectLocation': 'Select a location',
    'general.select.initialOptionValue': 'Select value',
    'general.serialNumber': 'S/N',
    'general.server': 'server',
    'general.sessionAverageTime': 'Session average time',
    'general.sessionCount': 'Session count',
    'general.sessionFirstClients': 'Session new clients',
    'general.sessionReturnClients': 'Session returned clients',
    'general.sessions': 'Sessions (conn./disconn.)',
    'general.Settings': 'Settings',
    'general.showDescription': 'show description',
    'general.showid': 'show id',
    'general.skip': 'Skip',
    'general.start': 'Start',
    'general.startPage': 'Start page',
    'general.state.loading': 'Loading...',
    'general.status': 'Status',
    'general.step1': 'Step 1',
    'general.step10': 'Step 10',
    'general.step11': 'Step 11',
    'general.step2': 'Step 2',
    'general.step3': 'Step 3',
    'general.step4': 'Step 4',
    'general.step5': 'Step 5',
    'general.step6': 'Step 6',
    'general.step7': 'Step 7',
    'general.step8': 'Step 8',
    'general.step9': 'Step 9',
    'general.stop': 'Stop',
    'general.su': 'Su',
    'general.submit': 'Submit',
    'general.summary': 'Summary',
    'general.summaryConfirmation': 'Summary and confirmation',
    'general.system': 'System',
    'general.softwareVersion': 'Version',
    'general.tags': 'Tags',
    'general.th': 'Th',
    'general.till': 'till',
    'general.time': 'time',
    'general.Timeout': 'Timeout',
    'general.totalClients': 'Total clients',
    'general.trafficDownloaded': 'RX',
    'general.trafficTxRx': 'Traffic (rx/tx)',
    'general.trafficTx': 'Traffic tx',
    'general.trafficRx': 'Traffic rx',
    'general.trafficUploaded': 'TX traffic',
    'general.tu': 'Tu',
    'general.type': 'Type',
    'general.tz': 'Time zone (UTC)',
    'general.uniqueClients': 'Unique Clients',
    'general.unset': 'Unset',
    'general.updating': 'Updating',
    'general.view': 'View',
    'general.warning': 'Warning',
    'general.we': 'We',
    'general.withChild': 'with child',
    'general.wlan': 'WLAN',
    'general.wlanOne': 'WLAN',
    'general.wlans': 'WLANs',
    'general.wlanSeveral': 'WLANs',
    'general.width': 'Width',
    'general.yes': 'Yes',
    'general.yesDelete': 'Yes, delete',
    'general.noSelected': 'No selected',
    'GuestControl.addRule': 'Add redirection rule',
    'GuestControl.allCpes': 'All APs',
    'GuestControl.allWlans': 'All WLANs',
    'GuestControl.create': 'Wizard for redirection rule creation',
    'GuestControl.deleteRedirectRuleModalText': 'Confirm deletion',
    'GuestControl.deleteRedirectRuleModalTitle': 'Delete redirection rule',
    'GuestControl.domain': 'Domain',
    'GuestControl.domainCaption': 'Redirection domain. Address of "Wimark portal" accessible for client should be provided. If this is a public access domain, only IP address should be specified in "Walled garden". Otherwise a domain must also be specified in "Walled garden". Domain name should not include protocol.',
    'GuestControl.domainName': 'Domain name',
    'GuestControl.enterName': 'Enter the redirection rule name',
    'GuestControl.expertHide': 'Hide expert mode',
    'GuestControl.expertShow': 'Show expert mode',
    'GuestControl.howTo': 'how to fill?',
    'GuestControl.ipAddress': 'IP address',
    'GuestControl.macList': 'MAC whitelist',
    'GuestControl.noMasquerade': 'No NAT/PAT',
    'GuestControl.PreAuthACLConfig': 'Pre-Auth ACL configuration',
    'GuestControl.PreAuthACLConfigHint': 'Pre-Auth ACL is called “redirect”. In addition to the ip addresses and domains specified by the user below, the access-list “redirect” allows the bootp and arp protocols. After successful authentication, the access-list called “internet” is applied to the user. The “internet” access-list does not contain any restrictions.',
    'GuestControl.notificationRedirectCreatedSuccessfully': 'Redirection successfully created',
    'GuestControl.notificationRedirectDeletedSuccessfully': 'Redirection successfully deleted',
    'GuestControl.notificationRedirectSuccessfull': 'Successfully',
    'GuestControl.notificationRedirectUpdatedSuccessfully': 'Redirection successfully updated',
    'GuestControl.redirectIP': 'Captive portal IP',
    'GuestControl.redirectType': 'Redirect type',
    'GuestControl.redirectType.static': 'Static URL',
    'GuestControl.redirectType.dynamic': 'Dynamic URL',
    'GuestControl.redirectURL': 'Redirection URL',
    'GuestControl.redirectUrl': 'Redirection URL',
    'GuestControl.redirectURLCaption': 'Address for client redirection after successful authorisation',
    'GuestControl.redirectExample': 'Example:',
    'GuestControl.redirectExampleText': '{redirectExpl} is required!',
    'GuestControl.redirectFormUsage': 'Fill in this form using key={paramName} template. A relevant parameter will be used instead of {paramName}.',
    'GuestControl.redirectSwitchParam': '{switchParam}: Platform address where the client was switched.',
    'GuestControl.redirectRedirectParam': '{redirectParam}: Address where the client was redirected after successful authorization.',
    'GuestControl.ruleSettings': 'Redirect rule settings',
    'GuestControl.switchURL': 'Switch URL',
    'GuestControl.switchURLCaption': 'Platform address (IP or domain) for clients redirection. Must contain only hostname (e.g. "www.example.com"). Protocol, port or URL-path should not to be specified.',
    'GuestControl.tableHeading': 'Redirect rules for captive portal',
    'GuestControl.targetingLocation': 'Location for targeting',
    'GuestControl.urlList': 'URL whitelist',
    'GuestControl.walledGarden': 'Walled garden',
    'GuestControl.walledGardenIPTooltip': 'All IPs of Redirect Domain name REQUIRED to be in Walled garden',
    'GuestControl.wizard1Caption': 'Fill basic parameters of redirect rule',
    'GuestControl.wizard2Caption': 'Confirm new кedirect кule creation',
    'GuestControl.wlanCaption': 'If rule will be applied to a "Wimark Appliance" (an ethernet interface), an ID must be explicitly provided',
    'header.account': 'Account',
    'header.clearListOperations': 'Clear operations list',
    'header.create': 'create',
    'header.current': 'Current',
    'header.delete': 'delete',
    'header.errors': 'errors',
    'header.hideLocations': 'Hide locations',
    'header.inputIntervalPlaceholder': 'Input interval in sec',
    'header.last': 'Last',
    'header.logout': 'Logout',
    'header.max': 'max',
    'header.noCurrOperations': 'No current operations',
    'header.off': 'off',
    'header.operations': 'operations',
    'header.pending': 'pending',
    'header.reboot': 'reboot',
    'header.reset': 'reset',
    'header.sec': 'sec',
    'header.setRefreshInterval': 'Set refresh interval',
    'header.settings': 'Settings',
    'header.showLocations': 'Show locations',
    'header.success': 'successfully',
    'header.tooltipDelete': 'delete',
    'header.tooltipDeleteAll': 'delete all',
    'header.tooltipShowAll': 'show all',
    'header.tooltipShowErrorsOnly': 'show errors only',
    'header.total': 'total',
    'header.type': 'Type',
    'header.typeCREATE': 'Create',
    'header.typeDELETE': 'Delete',
    'header.typeUPDATE': 'Update',
    'header.update': 'update',
    'header.updateInterval': 'Refresh',
    'header.wifiWizard': 'Wi-Fi wizard',
    'hotspot.addHS20': 'Add HS 2.0 profile',
    'hotspot.additionalStep': 'Additional step required for access',
    'hotspot.addRealm': 'Add Realm',
    'hotspot.authSubType': 'Authentication subtype',
    'hotspot.authType': 'Authentication type',
    'hotspot.cellular': 'Cellular',
    'hotspot.connectionCapabilityList': 'Connection capability list',
    'hotspot.dgaf': 'Downstream Group-Addressed Forwarding (DGAF)',
    'hotspot.domain': 'Domain',
    'hotspot.domainList': 'Domain list',
    'hotspot.domains': 'Domains',
    'hotspot.eapMethod': 'EAP method',
    'hotspot.emergencyServices': 'Emergency services availability',
    'hotspot.enterRealmNamePlaceholder': 'Enter Realm name',
    'hotspot.group': 'Group',
    'hotspot.hessid': 'HESSID',
    'hotspot.hotspotWizard': 'Wizard for Hotspot 2.0 creation',
    'hotspot.hs20profiles': 'Hotspot 2.0 profiles',
    'hotspot.internet': 'Internet',
    'hotspot.ipAvailability': 'IP Address Type Availability',
    'hotspot.language': 'Language',
    'hotspot.naiRealmList': 'NAI Realm List',
    'hotspot.network': 'Network',
    'hotspot.networkType': 'Network type',
    'hotspot.noItems': 'no items',
    'hotspot.notificationHotspotCreatedSuccessfully': 'Hotspot successfully created',
    'hotspot.notificationHotspotDeletedSuccessfully': 'Hotspot successfully deleted',
    'hotspot.notificationHotspotUpdatedSuccessfully': 'Hotspot successfully updated',
    'hotspot.notificationRADIUSOperationSuccessfull': 'Successfully',
    'hotspot.operatingClassIndication': 'Operating Class Indication',
    'hotspot.operatorFriendlyNameList': 'Operator Friendly Name List',
    'hotspot.organizationID': 'Organization ID',
    'hotspot.port': 'Port',
    'hotspot.protocol': 'Protocol',
    'hotspot.realm': 'NAI Realm',
    'hotspot.realmNoAuth': 'no authentications',
    'hotspot.realmNoData': 'No data to display',
    'hotspot.roaming': 'Roaming Consortium',
    'hotspot.roamingConsortiumList': 'Roaming Consortium List',
    'hotspot.selectClass': 'Select class',
    'hotspot.status': 'Status',
    'hotspot.threeGPP': '3GPP cellular network list',
    'hotspot.type': 'Type',
    'hotspot.unauthenticatedEmergency': 'Accessability of emergency services for unauthenticated users',
    'hotspot.venue': 'Venue',
    'hotspot.WANMetrics': 'WAN metrics',
    'hotspot.wizard1Title': 'Fill basic and network "Hotspot" parameters',
    'hotspot.wizard2Title': 'Fill advanced "Hotspot" parameters',
    'hotspot.wizard3Title': 'Fill advanced "Hotspot" parameters',
    'hotspot.wizard4Title': 'Confirm new "Hotspot" creation',
    'hotspot.wizardGeneral': 'General settings',
    'hotspot.wizardNamePlaceholder': 'Enter Hotspot 2.0 name',
    'imgAndVideoRecommendations.adsAndPollsImgDesktop': 'For tablets and laptops. Recommended size is 1920*1080',
    'imgAndVideoRecommendations.adsAndPollsImgMobile': 'For smartphones. Recommended size is 1080*1920',
    'imgAndVideoRecommendations.adsAndPollsVideo': 'Recommended size is 1080*1920',
    'imgAndVideoRecommendations.pagesBackground': '',
    'imgAndVideoRecommendations.pagesLogoFooter': 'Recommended width is 400px',
    'imgAndVideoRecommendations.pagesLogoTop': 'Recommended width is 400px',
    'licenses.avgValueCaption': 'Average value',
    'licenses.avgValuesCaption': 'Average values',
    'licenses.forTheSelectedPeriodCaption': 'for the selected period',
    'licenses.licenses': 'Licenses',
    'licenses.licensesAllConnected': 'Connected APs, Wi-Fi target and heatmaps',
    'licenses.licensesStats': 'Licenses statistics',
    'licenses.licensesStatsClose': 'To licenses',
    'licenses.notificationLicenseSuccessfullyUpdated': 'License successfully updated',
    'licenses.notificationNewLicenseFileSuccessfullyAdded': 'New license file successfully added',
    'licenses.notificationNewLicenseSuccessfullyAdded': 'New license successfully added',
    'licenses.notificationWrongLicenseCode': 'Wrong license code',
    'licenses.notificationWrongLicenseFile': 'Wrong license file',
    'licenses.types.cpes': 'APs',
    'licenses.types.heatmap': 'Heatmap',
    'licenses.types.radar': 'Wi-Fi target',
    'location.active_menu_item_color': 'Color of active menu item',
    'location.address': 'Address',
    'location.addressNotFound': 'Address is not found',
    'location.background_color': 'Background color',
    'location.byUser': 'by user',
    'location.company': 'Company',
    'location.companyCaptionForTable': 'Company',
    'location.companyNotFound': 'Company is not found',
    'location.coords': 'Coordinates',
    'location.coordsLat': 'lat.',
    'location.coordsLatFull': 'Latitude',
    'location.coordsLng': 'lng.',
    'location.coordsLngFull': 'Longitude',
    'location.created': 'Created',
    'location.custom_colors': 'Custom colors',
    'location.custom_logo': 'Custom logo for location',
    'location.custom_styles_enable': 'Enable custom colors for location',
    'location.delete': 'Delete location',
    'location.deleteLocationDescriptionMsg': 'All objects of the deleted location will be moved to the top-level location. For example, during deletion of the location "/wimark/office", all APs of the location "/office" will be moved to the location "/wimark".',
    'location.deleteText': 'During deletion of this location you will delete all the descendant locations and all the users of these locations. Please confirm the deletion.',
    'location.descendant': "You may create descendant locations, dividing them with a '/' character",
    'location.description': 'Description',
    'location.edit': 'Edit location',
    'location.enterAddressToSearch': 'Enter the address to find coordinates on the map',
    'location.enterCompanyNameToSearch': 'Enter a company name for search',
    'location.footer': 'Footer',
    'location.formal_name': 'Formal name',
    'location.header': 'Header',
    'location.hover_menu_item_color': 'Hover menu item color ',
    'location.img_upload_error': 'The file should be image and the size must be less 300kB',
    'location.inn': 'INN',
    'location.innCaptionForTable': 'INN',
    'location.innValidatorErrorMsg': 'The INN field may contain only digits',
    'location.interfaceLogo': 'Logo',
    'location.kpp': 'KPP',
    'location.kppValidatorErrorMsg': 'The KPP field may contain only digits',
    'location.limits.cpe': 'APs',
    'location.limits.header': 'Limits',
    'location.limits.heatmap': 'Heatmap',
    'location.limits.portal': 'Portal',
    'location.limits.radar': 'Wi-Fi target',
    'location.list': 'Location list',
    'location.locationDescription': 'Location description',
    'location.locId': 'Location ID',
    'location.main_block': 'Main block',
    'location.manager': 'Location manager',
    'location.managerCaption': 'If not specified, you will be assigned as location manager',
    'location.managerShort': 'Manager',
    'location.mapCaption': 'Map',
    'location.menu_item_text_color': 'Menu item text color',
    'location.name': 'Name',
    'location.new': 'Add location',
    'location.notificationLocationCreatedSuccessfully': 'Location successfully created',
    'location.notificationLocationDeletedSuccessfully': 'Location successfully deleted',
    'location.notificationLocationUpdatedSuccessfully': 'Location successfully updated',
    'location.ogrn': 'OGRN',
    'location.ogrnValidatorErrorMsg': 'The OGRN field may contain only digits',
    'location.owner': 'Owner',
    'location.path': 'Location name (path)',
    'location.phone': 'Phone',
    'location.phoneCaptionForTable': 'Phone',
    'location.phoneRequiredErrorMsg': 'It is necessary to fill in the "Phone" field, because it is used for billing.',
    'location.sameAsSelecteted': 'Copy settings from',
    'location.searchСompanCaption': 'Company search',
    'location.searchAddressCaption': 'Address search',
    'location.sidebar': 'Sidebar',
    'location.someFieldsHaveErrors': 'some fields have errors',
    'location.text_color': 'Text color',
    'location.withChildLocations': 'with child locations',
    'login.customPasswordValidation': 'The password field may contain only the following special characters: _ - # $ & ^ * @ !',
    'login.goToLoginButton': 'Go to login page',
    'login.autologout.extendModal.extendSession': 'Extend session',
    'login.autologout.extendModal.closeSession': 'Close session',
    'login.autologout.extendModal.heading': 'User session is expiring',
    'login.autologout.extendModal.text': '{timeLeft} sec left. Would you like to extend the session?',
    'login.autologout.afterStatusText': 'Logged out successfully',
    'login.login': 'Login',
    'login.loginHeader': 'Login',
    'login.newPasswordConfirmationField': 'New password confirmation',
    'login.newPasswordField': 'New password',
    'login.password': 'Password',
    'login.passwordSuccessfullySet': 'Password successfully set',
    'login.resetPassword': 'Forgot password?',
    'login.resetPasswordHeader': 'Reset password',
    'login.resetPasswordHeaderCaption': 'Enter username',
    'login.resetPasswordNewPassword': 'Enter the new password',
    'login.resetPasswordNewPasswordCaption': 'Enter the new password',
    'login.resetPasswordNewPasswordConfirm': 'Enter the new password again',
    'login.resetPasswordNewPasswordConfirmationError': 'The passwords are different.',
    'login.resetPasswordNewPasswordHeader': 'New password',
    'login.resetPasswordNewPasswordSubmitButton': 'Submit',
    'login.resetPasswordNewPasswordSuccessMessage': 'New password has been successfully set.',
    'login.resetPasswordNewPasswordTokenExpiredMessage': 'Token expired. Request the password reset again.',
    'login.resetPasswordPageBackButton': 'Back',
    'login.resetPasswordPageSendButton': 'Send password reset link',
    'login.resetPasswordPageSuccessMessage': 'Password reset link was sent to your e-mail',
    'login.signHeader': 'Sign in to your account',
    'login.support': 'support',
    'login.username': 'Username',
    'login.errors.WRONG_LOGIN_OR_PASSWORD': 'Wrong username or password',
    'login.errors.USER_NOT_FOUND': 'User with this username not found',
    'logs.container': 'Container',
    'logs.filter': 'Filter',
    'logs.filter.noSelected': 'Not selected',
    'logs.datetime': 'Date and time',
    'logs.filter.analytics': 'Analytics',
    'logs.filter.controller': 'Controller',
    'logs.filter.web': 'Web-client',
    'logs.filter.cpu-utilization': 'CPU utilization',
    'logs.filter.ram-utilization': 'RAM Utilization',
    'logs.filter.disk-utilization': 'Disk Utilization',
    'logs.filter.net-utilization': 'Network Utilization',
    'logs.filter.user-login': 'User Login',
    'logs.filter.ap-status': 'AP Status',
    'logs.filter.client-status': 'Client Status',
    'logs.filter.ap-channel': 'AP Channel',
    'logs.filter.ap-power': 'AP Power',
    'logs.filter.client-psk-mismatch': 'Client-psk-mismatch',
    'logs.filter.portal': 'Portal',
    'logs.debugging': 'Debugging',
    'logs.informational': 'Informational',
    'logs.ipConfig': 'IP-address configuration',
    'logs.ipType': 'IP-address type',
    'logs.ipv4ipv6address': 'IPV4 / IPV6 server address',
    'logs.label': 'Label',
    'logs.level': 'Log level',
    'logs.levelSettings': 'Log level settings',
    'logs.manageServers': 'Manage syslog servers',
    'logs.messageBuffer': 'Configuration of message buffer',
    'logs.numberOfLogs': 'Rows',
    'logs.server': 'Server',
    'logs.serverType': 'Server type',
    'logs.syslog': 'Syslog',
    'logs.value': 'Value',
    'logs.vrfName': 'VRF name',
    'maps.addMapButton': 'Add map',
    'maps.allLocations': 'All locations',
    'maps.availableCPEHint': 'APs which are in one location with map and which are still not added to the map.',
    'maps.availableCPEs': 'Available APs',
    'maps.bubbleRX': 'RX',
    'maps.bubbleTX': 'TX',
    'maps.bubleConnectedClients': 'connected visitors',
    'maps.bublePasserbyClients': 'passerby',
    'maps.byCPEs': 'by APs',
    'maps.changedCPEsModalText': 'There are unsaved AP changes. Are you sure you want to leave the planing mode?',
    'maps.changedCPEsModalTitle': 'There are unsaved changes',
    'maps.clientCoordsButton': 'Visitors',
    'maps.clientCoordsHint': "Visitor's last coordinates",
    'maps.Clients': 'Visitors',
    'maps.close': 'Close',
    'maps.closeAndShowOtherMaps': 'close and show other maps',
    'maps.closeCurrentMap': 'close current map',
    'maps.connected': 'connected',
    'maps.connectedCounterTitle': 'Connected',
    'maps.connectedHint': 'Visitors connected to Wi-Fi',
    'maps.countersTitle': 'Counters',
    'maps.cpe': 'AP',
    'maps.cpeControlTitle': 'List of APs',
    'maps.cpeInfoAboutUsers': 'Clients information',
    'maps.cpeNamePlaceHolder': 'AP name',
    'maps.cpePopUpID': 'ID',
    'maps.cpePopUpIP': 'IP',
    'maps.cpePopUpMAC': 'MAC',
    'maps.cpePopUpModel': 'Model',
    'maps.cpePopUpWifiRadar': 'Wi-Fi target',
    'maps.cpeShowMore': 'More information',
    'maps.cpeStatisticCPULoad': 'CPU',
    'maps.cpeStatisticMemory': 'Mem.',
    'maps.cpeStatisticUsers': 'Clients',
    'maps.cpesTooltipForTable': 'APs',
    'maps.cpeUsers': 'сonnected clients',
    'maps.Created': 'Date of creation',
    'maps.deleteMapModalText': 'Are you sure you want to delete the map?',
    'maps.deleteMapModalTitle': 'Delete the map',
    'maps.dynamicsTitle': 'Dynamics',
    'maps.editMapModalOpenButton': 'Edit the map',
    'maps.editMapModalTitle': 'Edit the map',
    'maps.editScaleMode': 'edit scale mode',
    'maps.enterMapNamePlaceholder': 'Enter map name',
    'maps.enterZoneNamePlaceholder': 'Enter zone name',
    'maps.error': 'Error',
    'maps.filteringNightClientsCaption': 'Visit. filter.',
    'maps.filteringNightClientsModalCaption': 'Filtering visitors',
    'maps.filteringNightClientsModalText': 'Visitor filtering mode allows you to filter out visitors who were seen outside of business hours of the facility. To be able to turn it on, you need to set three time settings in the map editing window (start, end and time zone)',
    'maps.found': 'Found visitors',
    'maps.foundOne': 'Found',
    'maps.heatmap': 'Heatmap',
    'maps.heatmapLegendHeader': 'Legend (visitors)',
    'maps.highlightCPEsInfoCaption': 'Highlight AP information',
    'maps.highlightCPEsInfoHint': 'Highlights AP information on the map for better readability. Allows you to improve visibility on black and white maps.',
    'maps.lastConnected': 'Last connected',
    'maps.lastSeen': 'Last seen',
    'maps.lastUpdated': 'Last updated',
    'maps.layersControl': 'Layers control',
    'maps.locationPlaceholder': 'Location',
    'maps.mapActions': 'Map actions',
    'maps.mapsList': 'Maps list',
    'maps.mapsWizardFillMapParameters': 'Fill map parameters',
    'maps.mapsWizardHeight': 'height',
    'maps.mapsWizardPx': 'px',
    'maps.mapsWizardStep1Title': 'Choose map image file',
    'maps.mapsWizardStep2Title': 'Fill required fields',
    'maps.mapsWizardStep3Title': 'Map settings',
    'maps.mapsWizardTitle': 'Wizard for map creation',
    'maps.mapsWizardWidth': 'width',
    'maps.newZoneNameError': 'Zone name must be greater than 0 and less than 22 characters',
    'maps.noAvailableCPEs': 'No available APs',
    'maps.noCpeConnectedUsersToDisplay': 'There are no connected clients',
    'maps.noCpeData': 'No AP data',
    'maps.noCpeDataFull': 'No AP data. Perhaps an AP from a location inaccessible to you.',
    'maps.noData': 'no data',
    'maps.notFound': 'not found',
    'maps.notificationMapCPEpositionupdated': 'AP position updated',
    'maps.notificationMapCreatedSuccessfully': 'Map successfully created',
    'maps.notificationMapDeletedSuccessfully': 'Map successfully deleted',
    'maps.notificationMapScaleUpdated': 'Map scale successfully updated',
    'maps.notificationMapZonesUpdated': 'Zones successfully updated',
    'maps.onlyOnePointInPath': 'This is only one point in the path.',
    'maps.passerby': 'passerby',
    'maps.passerbyCounterTitle': 'Passerby',
    'maps.passerbyHint': 'Visitors passing near the AP',
    'maps.planingMode': 'planing mode',
    'maps.playButton': 'Play',
    'maps.refresh': 'refresh',
    'maps.saveAndCreateButton': 'Save and create',
    'maps.saveAndHandleClients': 'Save and handle visitors',
    'maps.scaleModeDangerText': 'All previous visitors data will not be available',
    'maps.scaleModeDistance': 'Distance',
    'maps.scaleModeOf': 'of',
    'maps.scaleModePlaceholder': 'Enter distance',
    'maps.scaleModeSave': 'Save',
    'maps.scaleModeSetTwoPoints': 'First set two points on the map',
    'maps.scaleModeTypeDistance': 'Now enter the distance between them',
    'maps.serchByClientMAC': 'Visitor search by MAC',
    'maps.setMapUnitsPerPixel': 'Set map units per pixel',
    'maps.setMapUnitsPerPixelNew': 'Set the scale',
    'maps.showClients': 'Show visitors',
    'maps.showCPEs': 'Show APs',
    'maps.showMode': 'show mode',
    'maps.showPath': 'show path',
    'maps.showZones': 'Show zones',
    'maps.sortTypeCreated': 'By created time',
    'maps.sortTypeName': 'By name',
    'maps.sortTypeUpdated': 'By updated time',
    'maps.statisticsTitle': 'Statistics',
    'maps.stopButton': 'Stop',
    'maps.tableOutOf': 'maps of',
    'maps.timeline': 'timeline',
    'maps.timelineConnectedTitle': 'Connected timeline',
    'maps.timelineConnectedTooltipCaption': 'Connected',
    'maps.timelinePasserbyTitle': 'Passersby timeline',
    'maps.timelinePasserbyTooltipCaption': 'Passerby',
    'maps.timelineTitle': 'Visitors’ timeline',
    'maps.timelineTrafficTitle': 'Traffic timeline',
    'maps.timelineTrafficTooltipCaption': 'Traffic',
    'maps.traffic': 'traffic',
    'maps.trafficCounterTitle': 'Traffic',
    'maps.trafficHint': 'Traffic data',
    'maps.user': 'visitor',
    'maps.users': 'visitors',
    'maps.vendor': 'Vendor',
    'maps.workTimeCaption': 'Opening times settings',
    'maps.workTimeHint': 'Allows you to set the open hours of the object for better filtering of visitors. For application you need to fill in all three fields.',
    'maps.workTimeStart': 'Start time',
    'maps.workTimeStartHint': 'Start time in HH:MM format (for example 10:00)',
    'maps.workTimeStartPlaceholder': 'HH:MM format',
    'maps.workTimeStop': 'End time',
    'maps.workTimeStopHint': 'Expiration time in HH:MM format (for example 21:00)',
    'maps.workTimeStopPlaceholder': 'HH:MM format',
    'maps.workTimeTimezone': 'Timezone',
    'maps.workTimeTimezoneMultiselectPlaceholder': 'Choose timezone',
    'maps.zoneClientsCounterCaptionInZoneTooltip': 'Visitors',
    'maps.zoneCreating': 'New zone',
    'maps.zoneEditMode': 'edit zones',
    'maps.zoneIntersectionError': 'Zones should not intersect',
    'maps.zoneName': 'Zone name',
    'maps.zoneNameCaption': 'Zone name',
    'maps.zoneNameCaptionInZoneTooltip': 'Zone',
    'maps.zonesAddNewName': 'Name for new zone',
    'maps.zonesForTable': 'Zones',
    'maps.zonesTooltipForTable': 'Zones',
    'marketing.all': 'All APs',
    'marketing.allCpes': 'All APs',
    'marketing.allCpesSelected': 'All APs selected',
    'marketing.allRadars': 'All Wi-Fi target',
    'marketing.allVisitors': 'All',
    'marketing.avgDwellTime': 'Avg. dwell time',
    'marketing.avgLoadingDataTimeMsg': 'The average waiting time for data to load with the selected time interval is',
    'marketing.avgVisitFreq': 'Avg. visit frequency',
    'marketing.byDefault': 'by default',
    'marketing.columnNameForDownloads': 'Name',
    'marketing.columnPercentsForDownloads': 'Percents',
    'marketing.columnValueForDownloads': 'Value',
    'marketing.count': 'Number',
    'marketing.counters': 'Counters',
    'marketing.cpes': 'APs',
    'marketing.createExportButtonCaption': 'Create segment',
    'marketing.createExportButtonInfo': 'Allows you to go to the "Wi-Fi Target Segment" section and start creating a new segment using the selected APs, location and date range',
    'marketing.cVisits': 'Visits',
    'marketing.cVisitsVisitors': 'Visitors',
    'marketing.date': 'Date',
    'marketing.dateForDownloads': 'Date',
    'marketing.datePickerCaption': 'Date range',
    'marketing.deleteBenchmark': 'delete benchmark',
    'marketing.downloadData': 'download data',
    'marketing.exitWithoutPurchase': 'Exit without purchase',
    'marketing.fittingRoom': 'Fitting rooms',
    'marketing.footTraffic': 'Footfall',
    'marketing.h': 'h.',
    'marketing.heatmap': 'Heatmap',
    'marketing.hideDescription': 'Hide description',
    'marketing.housewares': 'Houseware',
    'marketing.infoAboutWifiTargetBegin': 'There are',
    'marketing.infoAboutWifiTargetEnd': 'with selected filtering conditions with enabled Wi-Fi Target',
    'marketing.location': 'Location',
    'marketing.mall': 'Mall',
    'marketing.menDept': 'Menswear department',
    'marketing.min': 'min',
    'marketing.new': 'New',
    'marketing.percent': 'Percent',
    'marketing.radar': 'Wi-Fi target',
    'marketing.repeat': 'Returned',
    'marketing.repeatVisitors': 'Returned visits',
    'marketing.report': 'Report',
    'marketing.rVbyF': 'Return visitors by visit frequency',
    'marketing.sales': 'Sales',
    'marketing.sec': 'sec',
    'marketing.selectCompanyPlaceholder': 'Select company',
    'marketing.selectLocationPlaceholder': 'Select a location',
    'marketing.showDescription': 'show description',
    'marketing.submit': 'Refresh',
    'marketing.timeframe': 'Period',
    'marketing.trafficByZone': 'Traffic by zone',
    'marketing.uniqueVisitors': 'Unique visitors',
    'marketing.vendor': 'Vendor',
    'marketing.vendors': 'Vendors',
    'marketing.venue': 'Venue',
    'marketing.visitors': 'Visitors',
    'marketing.visits': 'Visits',
    'marketing.visits1': '1 visit',
    'marketing.visits10': '>= 10 visits',
    'marketing.visits2': '2 visits',
    'marketing.visits34': '3-4 visits',
    'marketing.visits59': '5-9 visits',
    'marketing.withoutS': 'Exit without purchase',
    'marketing.withS': 'Stopped near a cash desk',
    'marketing.womenDept': 'Womenswear department',
    'marketing.workTimeBlockCaption': 'Filtering by worktime',
    'marketing.workTimeFilterEnabled': 'enabled',
    'marketing.workTimeHint': 'Allows you to set the opening hours for filtering visitors',
    'marketing.workTimeStart': 'Open at',
    'marketing.workTimeStartHint': 'Start time in HH:MM format (for example 10:00)',
    'marketing.workTimeStartPlaceholder': 'HH:MM format',
    'marketing.workTimeStop': 'Close at',
    'marketing.workTimeStopHint': 'Expiration time in HH:MM format (for example 21:00)',
    'marketing.workTimeStopPlaceholder': 'HH:MM format',
    'marketing.workTimeTimezone': 'Timezone',
    'marketing.workTimeTimezoneMultiselectPlaceholder': 'Choose timezone',
    'marketing.workTimeTimezoneNoSelectError': 'The "Timezone" field is mandatory',
    'marketing.zone': 'Zone',
    'misc.$eq': 'Equal',
    'misc.$gt': 'Greater than',
    'misc.$lt': 'Less than',
    'misc.blacklist': 'Blacklist',
    'misc.current': 'Selected',
    'misc.items': 'Items',
    'misc.no_data': 'No data',
    'misc.no_errors': 'No errors',
    'misc.no_name': 'No name',
    'misc.none': 'None',
    'misc.whitelist': 'Whitelist',
    'monitor.addRule': 'Add rule',
    'monitor.noRules': 'There are no configured rules',
    'monitor.analyzerInfoMessage': 'WiFi analyzer will work only if background scanning is enebled in the interface settings.',
    'monitor.clientsEvents': 'Clients events',
    'monitor.cpesEvents': 'APs events',
    'monitor.cpesState': 'APs state',
    'monitor.cpeState': 'AP state',
    'monitor.cpuLoad': 'CPU load',
    'monitor.createRule': 'Create rule',
    'monitor.data': 'Data',
    'monitor.enterRuleName': 'Enter rule name',
    'monitor.error': 'ERROR',
    'monitor.freeRAM': 'Free RAM',
    'monitor.info': 'INFO',
    'monitor.lastScanDate': 'Last scan in ',
    'monitor.notificationRuleCopyingScriptWasSuccessful': 'Script successfully copied!',
    'monitor.notificationRuleCopyingScriptWasUnsuccessful': 'Error during script copying!',
    'monitor.notificationRuleCreatedSuccessfully': 'Rule successfully created',
    'monitor.range': 'Range',
    'monitor.ruleConfirmDeleting': 'Confirm deletion',
    'monitor.ruleDelete': 'Delete a rule',
    'monitor.ruleDescription': 'Point out the range of the rule (red line).  Once exceeding the border  one will get a notification.',
    'monitor.ruleEvents': 'Rules',
    'monitor.ruleParameters': 'Fill required rule parameters',
    'monitor.ruleRange': 'Rule range',
    'monitor.rules': 'Rules',
    'monitor.modal.applyRules.header': 'Rules for',
    'monitor.modal.applyRules.label': 'Edit the AP\'s applied ruleset',
    'monitor.ruleScript': 'Post Script',
    'monitor.ruleType': 'Rule type',
    'monitor.ruleWizard': 'Wizard for rule creation',
    'monitor.script': 'Script',
    'monitor.sessionConnected': 'Connected',
    'monitor.sessionDisconnected': 'Disconnected',
    'monitor.sessions': 'Uptime',
    'monitor.systemEvents': 'System events',
    'monitor.warning': 'Warnings',
    'monitor.wifiAnalyzer': 'Wi-Fi analyzer',
    'NewVersionChecker.modalHeader': 'Software version updated',
    'NewVersionChecker.modalReloadButton': 'Refresh',
    'NewVersionChecker.modalText': 'An update is available. Please click "Reload" below for correct further work. You can also accept these updates by refreshing your browser page',
    'pageprofile.add': 'Add a page',
    'pageprofile.authorizationButton': 'Authorisation window',
    'pageprofile.back': 'Background',
    'pageprofile.background': 'Background image',
    'pageprofile.backgroundInPreview': 'Background',
    'pageprofile.changeColorAlert': 'The theme in the preview window will be changed after saving the settings!',
    'pageprofile.contrast': 'Contrast',
    'pageprofile.dateRangeforShowing': 'Date range for showing',
    'pageprofile.dayOfWeek': 'Day of week',
    'pageprofile.deleteSchedule': 'Delete schedule',
    'pageprofile.edit': 'Edit a page',
    'pageprofile.enterAgreement': 'Please paste an agreement text in the field below (in plain text format)',
    'pageprofile.facebook': 'Facebook',
    'pageprofile.free': 'Free',
    'pageprofile.hasAgreement': 'Has User Agreement',
    'pageprofile.hasSupport': 'Has Support Contacts',
    'pageprofile.hotel_voucher': 'Hotel voucher',
    'pageprofile.hoursforShowing': 'Show hours',
    'pageprofile.imagesBlock': 'Images',
    'pageprofile.imageUploadedNotification': 'Images changes will be saved only after clicking the Save button at the bottom of that window.',
    'pageprofile.instagram': 'Instagram',
    'pageprofile.interface': 'Interface',
    'pageprofile.isExternalUrl': 'External resource',
    'pageprofile.light': 'Light',
    'pageprofile.logo': 'Logo',
    'pageprofile.logo_footer': 'Footer logo',
    'pageprofile.main_color': 'Main color',
    'pageprofile.new': 'New page',
    'pageprofile.noSchedule': 'No schedule',
    'pageprofile.openPage': 'Open page',
    'pageprofile.pageClonedSuccessfully': 'Page successfully copied',
    'pageprofile.pageCreatedSuccessfully': 'Page successfully created',
    'pageprofile.pageDeletedSuccessfully': 'Page successfully deleted',
    'pageprofile.pages': 'Pages',
    'pageprofile.pageScheduleStatusTextForTooltipAciveAndDayMatch': 'Showing',
    'pageprofile.pageScheduleStatusTextForTooltipAciveAndDayNotMatch': 'On the selected day, the showing is not performed due to the settings of the day of the week for showing',
    'pageprofile.pageUpdatedSuccessfully': 'Page successfully updated',
    'pageprofile.portalurl': 'Portal URL',
    'pageprofile.portalurlCaption': 'Use with your domain on the guest control settings page',
    'pageprofile.portalurlID': 'Portal URL ID',
    'pageprofile.preview': 'Portal preview',
    'pageprofile.saveurl': 'Save this URL to use in redirect rule',
    'pageprofile.schedule': 'Schedule',
    'pageprofile.scheduleAShow': 'Schedule a show',
    'pageprofile.scheduleClonedSuccessfully': 'Schedule successfully copied',
    'pageprofile.scheduleCreatedSuccessfully': 'Schedule successfully created',
    'pageprofile.scheduleDeletedSuccessfully': 'Schedule successfully deleted',
    'pageprofile.scheduleEmpty': 'No scheduled pages for this date',
    'pageprofile.scheduleInfoMessage': 'Сlick the "Save" button to save the schedule settings',
    'pageprofile.scheduleUpdatedSuccessfully': 'Schedule successfully updated',
    'pageprofile.showHours': 'Show hours',
    'pageprofile.showHoursAllDay': 'all day',
    'pageprofile.showHoursNotAllDay': 'not all day',
    'pageprofile.sponsor': 'Sponsored',
    'pageprofile.staff': 'Staff',
    'pageprofile.subscription': 'Internet with subscription',
    'pageprofile.support': 'Contacts will be displayed if user encounters an error on Portal',
    'pageprofile.theme': 'Color theme',
    'pageprofile.themeType': 'Theme type',
    'pageprofile.themeType-default': 'by default',
    'pageprofile.themeType-default-beeline': 'default-beeline',
    'pageprofile.themeType-minimalistic': 'minimalistic',
    'pageprofile.themeType-minimalistic-beeline': 'minimalistic-beeline',
    'pageprofile.themeTypeMinimalisticOrNot': 'Theme view',
    'pageprofile.timeOfDay': 'Time of a day',
    'pageprofile.timeRange': 'Schedule',
    'pageprofile.title': 'Page title',
    'pageprofile.ToS': 'Terms of service',
    'pageprofile.urlToExternalTOS': 'URL of external resource with your ToS',
    'pageprofile.urlToFileTOS': 'Path to your ToS file',
    'pageprofile.vk': 'VK',
    'pageprofile.voucher': 'Paid Internet with vouchers',
    'pageprofile.warnUsage': 'Please note that if you delete a Page in use by a Portal, {0}.',
    'pageprofile.warnUsageAccent': 'such Portal will not work properly',
    'paymentSystems.add': 'Add payment system',
    'paymentSystems.directions': 'Guide',
    'paymentSystems.edit': 'Edit payment system',
    'paymentSystems.hashKey': 'Hash Key',
    'paymentSystems.head': 'Guide header',
    'paymentSystems.identity': 'Internal ID',
    'paymentSystems.identityCaption': 'Currently the following ID are available: Humo, MegafonLife, Terminal',
    'paymentSystems.image': 'Image',
    'paymentSystems.merchant': 'Merchant',
    'paymentSystems.merchantCaption': 'ID should be unique',
    'paymentSystems.merchantInfo': 'This is a unique number transmitted to the Payment system provider. Required when using the API for replenishing the virtual balance of paid Wi-Fi',
    'paymentSystems.name': 'Name',
    'paymentSystems.new': 'New payment system',
    'paymentSystems.notificationPaymentSystemCreatedSuccessfully': 'Payment system successfully created',
    'paymentSystems.notificationPaymentSystemDeletedSuccessfully': 'Payment system successfully deleted',
    'paymentSystems.notificationPaymentSystemUpdatedSuccessfully': 'Payment system successfully updated',
    'paymentSystems.preview': 'Preview',
    'paymentSystems.profile': 'Profile',
    'paymentSystems.templateHash': 'Template Hash',
    'paymentSystems.templateURL': 'Template URL',
    'paymentSystems.title': 'Payment systems',
    'paymentSystems.tranUnique': 'Check transaction ID for uniqueness',
    'paymentSystems.type.ext': 'External gateway',
    'paymentSystems.type.ext_url': 'External gateway with URL generation',
    'paymentSystems.type.guide': 'Guide',
    'paymentSystems.type.type': 'Type',
    'paymentSystems.warnUsage': 'Please note that if you delete a Payment System in use by a Portal, {0}.',
    'paymentSystems.warnUsageAccent': 'such Portal will not work properly',
    'paywallForAuthFreemium.contactToUsCaption': 'If you want to enable this section - write to us',
    'PeriodicTasks.actionType.change_password': 'Change WLAN Password',
    'PeriodicTasks.actionType.reboot_point': 'Reboot AP',
    'PeriodicTasks.allZerosInSpecEveryDurationObjectError': 'At least one field (days, hours, minutes, seconds) must be greater than 0',
    'PeriodicTasks.cancelSelectAllCpesButtonCaption': 'Clear all selected APs',
    'PeriodicTasks.cancelSelectAllWlansButtonCaption': 'Clear all selected WLANS',
    'PeriodicTasks.createPeriodicTaskButton': 'Create a periodic task',
    'PeriodicTasks.days': 'Days',
    'PeriodicTasks.daysShort': 'd',
    'PeriodicTasks.deletePeriodicTaskModalHeader': 'Deleting a periodic task',
    'PeriodicTasks.deletePeriodicTaskModalText': 'Deleting a periodic task',
    'PeriodicTasks.filtersForCpeListFiltringForCpeListIsEnabled': 'enabled',
    'PeriodicTasks.filtersForCpeListResetFiltersButton': 'reset filter',
    'PeriodicTasks.filtersForCpeListSwitcherCaption': 'Filtering a list of APs',
    'PeriodicTasks.filtersForCpeListSwitcherInfoTooltip': 'Filtering makes it easy to find the desired AP in the AP selection list. Allows you to filter access points by their location',
    'PeriodicTasks.filtersForCpeListSwitcherLocationCaption': 'AP location',
    'PeriodicTasks.filtersForWlanListFiltringForWlanListIsEnabled': 'enabled',
    'PeriodicTasks.filtersForWlanListSwitcherCaption': 'Filtering a list of WLANs',
    'PeriodicTasks.filtersForWlanListSwitcherInfoTooltip': 'Filtering makes it easy to find the desired WLAN in the WLAN selection list. Allows you to filter WLANs by their location',
    'PeriodicTasks.filtersForWlanListSwitcherLocationCaption': 'WLAN location',
    'PeriodicTasks.hours': 'Hours',
    'PeriodicTasks.hoursShort': 'h',
    'PeriodicTasks.minutes': 'Minutes',
    'PeriodicTasks.minutesShort': 'min',
    'PeriodicTasks.modalActionTypeCaption': 'Action type',
    'PeriodicTasks.modalActionTypePlaceholder': 'Action type',
    'PeriodicTasks.modalCpeListNoOptionsMsg': 'No APs',
    'PeriodicTasks.modalCpeListNoOptionsMsgWhenFilterEnabled': 'No APs matching filter conditions',
    'PeriodicTasks.modalCpeListNoResultMsg': 'No APs with the same name was found',
    'PeriodicTasks.modalCpeListNoResultMsgWhenFilterEnabled': 'No APs with the name matching the filter conditions was found',
    'PeriodicTasks.modalCpeListPlaceholder': 'Select APs or enter a name to search',
    'PeriodicTasks.modalCPEsCaption': 'APs',
    'PeriodicTasks.modalHeaderForEdit': 'Edit periodic task',
    'PeriodicTasks.modalHeaderForNew': 'New periodic task',
    'PeriodicTasks.modalNameCaption': 'Name',
    'PeriodicTasks.modalNamePlaceholder': 'Name',
    'PeriodicTasks.modalNotifyTypeCaption': 'Notification type',
    'PeriodicTasks.modalNotifyTypePlaceholder': 'Notification type',
    'PeriodicTasks.modalSelectedCPEsCaption': 'Selected',
    'PeriodicTasks.modalSelectedCpesLengthError': 'Select at least one AP',
    'PeriodicTasks.modalSelectedWlansCaption': 'Selected',
    'PeriodicTasks.modalSelectedWlansLengthError': 'Select at least one WLAN',
    'PeriodicTasks.modalSpecCaption': 'Periodicity',
    'PeriodicTasks.modalSpecPlaceholder': 'Periodicity',
    'PeriodicTasks.modalSpecRawCaption': 'Periodicity in custom format (cron-expression)',
    'PeriodicTasks.modalSpecRawInfoMsg': 'You can read more about the format here',
    'PeriodicTasks.modalSpecRawPlaceholder': 'Periodicity in custom format (cron-expression)',
    'PeriodicTasks.modalWlanListNoOptionsMsg': 'No WLANs',
    'PeriodicTasks.modalWlanListNoOptionsMsgWhenFilterEnabled': 'No WLANs matching filter conditions',
    'PeriodicTasks.modalWlanListNoResultMsg': 'No WLANs with the same name were found',
    'PeriodicTasks.modalWlanListNoResultMsgWhenFilterEnabled': 'No WLANs with the name matching the filter conditions were found',
    'PeriodicTasks.modalWlanListPlaceholder': 'Select a WLAN or enter name to search',
    'PeriodicTasks.modalWlansCaption': 'WLANs',
    'PeriodicTasks.notifySettingsEmailSubjectCaption': 'Subject',
    'PeriodicTasks.notifySettingsEmailSubjectPlaceholder': 'Subject for e-mail',
    'PeriodicTasks.notifySettingsEmailTextCaption': 'Text',
    'PeriodicTasks.notifySettingsEmailTextPlaceholder': 'E-mail text',
    'PeriodicTasks.notifySettingsEmailToCaption': 'To',
    'PeriodicTasks.notifySettingsEmailToPlaceholder': 'Email address',
    'PeriodicTasks.notifySettingsForTableCaption': 'Action parameters',
    'PeriodicTasks.notifySettingsForTableForEmailToAddress': 'Mail to address',
    'PeriodicTasks.notifySettingsForTableForMQTTToTopic': 'Message into topic',
    'PeriodicTasks.notifySettingsForTableForTelegramToChatId': 'Message to chat with id',
    'PeriodicTasks.notifySettingsForTableHide': 'Hide',
    'PeriodicTasks.notifySettingsForTableShowMore': 'show more',
    'PeriodicTasks.notifySettingsMQTTTextCaption': 'Text',
    'PeriodicTasks.notifySettingsMQTTTextPlaceholder': 'Text',
    'PeriodicTasks.notifySettingsMQTTTopicCaption': 'Topic',
    'PeriodicTasks.notifySettingsMQTTTopicInfo': 'MQTT Topic',
    'PeriodicTasks.notifySettingsMQTTTopicPlaceholder': 'Topic',
    'PeriodicTasks.notifySettingsTelegramChatIdCaption': 'Chat Id',
    'PeriodicTasks.notifySettingsTelegramChatIdInfo': 'Unique identifier of the telegram chat',
    'PeriodicTasks.notifySettingsTelegramChatIdPlaceholder': 'Chat Id',
    'PeriodicTasks.notifySettingsTelegramTextCaption': 'Text',
    'PeriodicTasks.notifySettingsTelegramTextPlaceholder': 'Notification text',
    'PeriodicTasks.notifySettingsTelegramTokenCaption': 'Token',
    'PeriodicTasks.notifySettingsTelegramTokenInfo': 'Token obtained from @BotFather',
    'PeriodicTasks.notifySettingsTelegramTokenPlaceholder': 'Token',
    'PeriodicTasks.notifyType.email': 'E-mail',
    'PeriodicTasks.notifyType.mqtt': 'MQTT',
    'PeriodicTasks.notifyType.telegram': 'Telegram',
    'PeriodicTasks.onlyForWpaPersonalWLANSMsg': 'Password change works only for WPA Personal WLANs (Only such WLANs are shown in the list for selecting WLANs)',
    'PeriodicTasks.onlyForWpaWpa2PersonalWLANSMsg': 'Password change works only for WPA / WPA2 Personal WLANs (Only such WLANs are shown in the list for selecting WLANs)',
    'PeriodicTasks.period': 'Period',
    'PeriodicTasks.PeriodicTasksNotificationCreated': 'Periodic task successfully created',
    'PeriodicTasks.PeriodicTasksNotificationDeleted': 'Periodic task successfully deleted',
    'PeriodicTasks.PeriodicTasksNotificationUpdated': 'Periodic task successfully updated',
    'PeriodicTasks.seconds': 'Seconds',
    'PeriodicTasks.secondsShort': 'sec',
    'PeriodicTasks.selectAllCpesButtonCaption': 'Add all filtered APs',
    'PeriodicTasks.selectAllWlansButtonCaption': 'Add all filtered WLANS',
    'PeriodicTasks.sortActionType': 'By action type',
    'PeriodicTasks.sortCpesCount': 'By AP count',
    'PeriodicTasks.sortName': 'By name',
    'PeriodicTasks.sortNotifyType': 'By notiofication type',
    'PeriodicTasks.sortObjectsCount': 'By objects count',
    'PeriodicTasks.specFieldCaption@daily': 'Daily',
    'PeriodicTasks.specFieldCaption@every': 'Period',
    'PeriodicTasks.specFieldCaption@hourly': 'Hourly',
    'PeriodicTasks.specFieldCaption@monthly': 'Monthly',
    'PeriodicTasks.specFieldCaption@weekly': 'Weekly',
    'PeriodicTasks.specFieldCaption@yearly': 'Annually',
    'PeriodicTasks.specFieldCaptionraw': 'Сustom format',
    'PeriodicTasks.specFieldInfo@daily': 'Run daily, midnight',
    'PeriodicTasks.specFieldInfo@every': 'Runs at a set periodicity',
    'PeriodicTasks.specFieldInfo@hourly': 'Run hourly, beginning of hour',
    'PeriodicTasks.specFieldInfo@monthly': 'Run monthly, midnight, first day of month',
    'PeriodicTasks.specFieldInfo@weekly': 'Run weekly, midnight between Sat/Sun ',
    'PeriodicTasks.specFieldInfo@yearly': 'Run annualy, midnight, Jan. 1st',
    'PeriodicTasks.specFieldInforaw': 'string in custom format (cron-expression)',
    'PeriodicTasks.tableColumnActionType': 'Action type',
    'PeriodicTasks.tableColumnCPEs': 'APs',
    'PeriodicTasks.tableColumnName': 'Name',
    'PeriodicTasks.tableColumnNotifyParameters': 'Notification parameters',
    'PeriodicTasks.tableColumnNotifyType': 'Notification type',
    'PeriodicTasks.tableColumnObjects': 'APs/WLANs',
    'PeriodicTasks.tableColumnSpec': 'Periodicity',
    'PeriodicTasks.tableColumnWlans': 'WLANs',
    'PeriodicTasks.tableTitle': 'Periodic tasks',
    'PeriodicTasks.tableTooltipCPEs': 'APs',
    'PeriodicTasks.tableTooltipWlans': 'WLANs',
    'portal.accessList': 'Access list',
    'portal.add': 'Add a profile',
    'portal.ads_to_watch': 'Number of adverts or polls to watch',
    'portal.adsFollow': 'Enable internet access after clicking on advert (poll)',
    'portal.adsRotation': 'Show adverts (polls) in random order',
    'portal.allPrefix': 'Any prefix',
    'portal.auth_timeout': 'Authorisation timeout',
    'portal.auth.attachedURL': 'Attached URL',
    'portal.auth.groupURL': 'Group URL',
    'portal.auth.message': 'Post message',
    'portal.auth.redirectURL': 'Redirect URL',
    'portal.authentication': 'Authentication',
    'portal.authGroup': 'Authenticate and subscribe',
    'portal.authOnly': 'Authentication',
    'portal.authorization': 'Authorisation',
    'portal.authPost': 'Authenticate and post',
    'portal.blackList': 'Black list',
    'portal.block_after': 'Internet usage time before blocking (in sec.)',
    'portal.block_afterCaption': 'Must be fullfilled if field below is fullfilled',
    'portal.block_expire': 'Time for block expiration (in sec.)',
    'portal.block_expireCaption': 'Must be fullfilled if field above is fullfilled',
    'portal.code': 'Country code',
    'portal.download_limit': 'Download limit (in kb.)',
    'portal.duration3Days': '3 days',
    'portal.edit': 'Edit profile',
    'portal.errorMessage': 'Validation error message',
    'portal.errorMessageCaption': 'Error message to be displayed on validation error',
    'portal.gatewaySettings.add': 'Add',
    'portal.gatewaySettings.blockHeader': 'Send gateway',
    'portal.gatewaySettings.cancel': 'Cancel',
    'portal.gatewaySettings.clear': 'Clear',
    'portal.gatewaySettings.content_type': 'Content type',
    'portal.gatewaySettings.delete': 'Delete',
    'portal.gatewaySettings.edit': 'Edit',
    'portal.gatewaySettings.header': 'Header name',
    'portal.gatewaySettings.headers': 'Headers',
    'portal.gatewaySettings.hide': 'Hide',
    'portal.gatewaySettings.method': 'Method',
    'portal.gatewaySettings.methodNoSelected': 'Not selected',
    'portal.gatewaySettings.noHeaders': 'No headers',
    'portal.gatewaySettings.noParams': 'No parameters',
    'portal.gatewaySettings.param': 'Parameter name',
    'portal.gatewaySettings.params': 'Parameters',
    'portal.gatewaySettings.payload_textarea': 'Payload',
    'portal.gatewaySettings.save': 'Apply',
    'portal.gatewaySettings.url': 'URL',
    'portal.gatewaySettings.value': 'Value',
    'portal.max_sessions': 'Max Sessions',
    'portal.mustContainCode': 'Must contain: {CODE}',
    'portal.nasId': 'NAS ID',
    'portal.new': 'New profile',
    'portal.notification': 'Session end time notification',
    'portal.notificationText': 'Notification text',
    'portal.notificationTime': 'Time until the end of the session (sec.)',
    'portal.noUsersInList': 'No users in the list',
    'portal.otp': 'Length of one-time password',
    'portal.otpCodeText': 'SMS text for OTP',
    'portal.paginationAdsOutOf': 'adverts out of',
    'portal.paginationLoad': 'Load',
    'portal.paginationOf': 'items of',
    'portal.paginationPagesOutOf': 'out of',
    'portal.paginationPaymentSystemsOutOf': 'out of',
    'portal.paginationProfilesOutOf': 'out of',
    'portal.paginationTariffsOutOf': 'out of',
    'portal.paginationUserAccountsOutOf': 'Accounts out of',
    'portal.paginationVouchersOutOf': 'Vouchers out of',
    'portal.paymentSystems': 'Payment systems',
    'portal.pollquestionValidationError': 'The following symbols are not allowed: $ (dollar), . (dot)',
    'portal.portalprofiles': 'Profiles',
    'portal.prefix': 'Prefix',
    'portal.redirect': 'Redirect to Landing Page',
    'portal.regex': 'Validation regular expression',
    'portal.regexCaption': 'Regular expression for user ID validation on the portal',
    'portal.remember': 'Remember time (in sec)',
    'portal.rememberCaption': 'Time period for which a client authentication is remembered and not requested repeatedly',
    'portal.rememberQuick': 'Quick options:',
    'portal.skipFinalPage': 'Skip final page',
    'portal.smsCodeValidationError': 'Text must contain {CODE}',
    'portal.socialURLValidationError': 'URL must contain https://vk.com, https://facebook.com or https://instagram.com',
    'portal.thereAreUsersInList': 'There are users in a list',
    'portal.timeout': 'One Session Timeout (in sec.)',
    'portal.transferable': 'Transferable',
    'portal.transferableInfo': 'Possibility to split a session into several within one day',
    'portal.tzCaption': 'Examples: 3 (Moscow), -4 (New-York), 2 (Barcelona)',
    'portal.voucherBuyHeader': 'Voucher purchase header',
    'portal.voucherBuyInfo': 'Voucher purchase information',
    'portal.voucherCodeText': 'SMS text for voucher',
    'portal.whiteList': 'Whitelist',
    'portalAccessServers.bson': 'bson',
    'portalAccessServers.coaPort': 'CoA port',
    'portalAccessServers.coaSecret': 'CoA Secret',
    'portalAccessServers.coaVsa': 'CoA Attributes',
    'portalAccessServers.coaVsaForTable': 'CoA Attrib.',
    'portalAccessServers.createAccessServerButtonCaption': 'Add access server',
    'portalAccessServers.description': 'Description',
    'portalAccessServers.disableForTable': 'disable',
    'portalAccessServers.enable': 'Enabled',
    'portalAccessServers.enableForTable': 'enabled',
    'portalAccessServers.externalIP': 'External IP',
    'portalAccessServers.location': 'Location',
    'portalAccessServers.login': 'Login',
    'portalAccessServers.macAuth': 'MAC Auth',
    'portalAccessServers.modalCoaPortFieldPlaceholder': 'CoA port (3799 by default)',
    'portalAccessServers.modalCoaSecretFieldPlaceholder': 'CoA Secret',
    'portalAccessServers.modalCoaVsaFieldPlaceholder': 'CoA Attributes',
    'portalAccessServers.modalCreateAccessServerHeader': 'Add access server',
    'portalAccessServers.modalDescriptionFieldPlaceholder': 'Description',
    'portalAccessServers.modalExternalIPFieldPlaceholder': 'External IP',
    'portalAccessServers.modalLocationFieldPlaceholder': 'Location',
    'portalAccessServers.modalLoginFieldPlaceholder': 'Login',
    'portalAccessServers.modalNameFieldPlacheolder': 'Access server name',
    'portalAccessServers.modalNasIdFieldPlaceholder': 'NAS ID',
    'portalAccessServers.modalNasIpFieldPlaceholder': 'NAS IP address',
    'portalAccessServers.modalPasswordFieldPlaceholder': 'Password',
    'portalAccessServers.modalPostAuthVsaFieldPlaceholder': 'Post-authorisation attributes',
    'portalAccessServers.modalPreAuthVsaFieldPlaceholder': 'Pre-authentication attributes',
    'portalAccessServers.modalUpdateAccessServerHeader': 'Edit an access server',
    'portalAccessServers.name': 'Name',
    'portalAccessServers.nasId': 'NAS ID',
    'portalAccessServers.nasIP': 'NAS IP',
    'portalAccessServers.noForTable': 'No',
    'portalAccessServers.notificationPortalAccessServerCreatedSuccessfully': 'Access server successfully created',
    'portalAccessServers.notificationPortalAccessServerDeletedSuccessfully': 'Access server successfully deleted',
    'portalAccessServers.notificationPortalAccessServerUpdatedSuccessfully': 'Access server successfully updated',
    'portalAccessServers.password': 'Password',
    'portalAccessServers.postAuthVsa': 'Post-authorisation attributes',
    'portalAccessServers.postAuthVsaForTable': 'Post Auth Attrib.',
    'portalAccessServers.preAuthVsa': 'Pre Auth Attributes',
    'portalAccessServers.preAuthVsaForTable': 'Pre Auth Attrib.',
    'portalAccessServers.tableCaption': 'Access Servers',
    'portalAccessServers.type': 'Type',
    'portalAccessServers.validatorErrors': 'There are validation errors. Check fields',
    'portalBirthdays.birthdays': 'Birthdays',
    'portalBirthdays.selectedDate': 'Selected date',
    'portalBirthdays.users': 'Users',
    'portalDashboard.adsPeriod': 'for all time',
    'portalDashboard.adsViewsPeriod': 'for this month',
    'portalDashboard.Advert': 'Advert',
    'portalDashboard.Advertdays': 'top views days',
    'portalDashboard.AdvertPeriod': 'views for all time',
    'portalDashboard.AdvertStatistic': 'Adverts views',
    'portalDashboard.AdvertStatisticByDates': 'by dates',
    'portalDashboard.AdvertStatisticPeriod': 'for this month',
    'portalDashboard.AdvertStatisticTotal': 'Total views',
    'portalDashboard.AdvertTop3': 'top 3',
    'portalDashboard.AdvertTop5': 'top',
    'portalDashboard.AdvertTotal': 'Total',
    'portalDashboard.AdvertViews': 'views',
    'portalDashboard.authentications': 'Authentications',
    'portalDashboard.authenticationsEmail': 'E-mail',
    'portalDashboard.authenticationsPeriod': 'last 7 days',
    'portalDashboard.authenticationsSms': 'SMS',
    'portalDashboard.authenticationsTotal': 'Total',
    'portalDashboard.authorizations': 'Authorisations',
    'portalDashboard.authorizationsPeriod': 'last 7 days',
    'portalDashboard.birthdays': 'Birthdays',
    'portalDashboard.connections': 'Connections',
    'portalDashboard.connectionsCaption': 'Number of connections',
    'portalDashboard.connectionsNew': 'New users',
    'portalDashboard.connectionsPeriod': 'last 7 days',
    'portalDashboard.connectionsRepeat': 'Return users',
    'portalDashboard.connectionsTotal': 'Total',
    'portalDashboard.connectionsUnique': 'Unique clients',
    'portalDashboard.locations': 'Locations',
    'portalDashboard.locationsAll': 'All locations',
    'portalDashboard.locationsMap': 'Map of locations',
    'portalDashboard.locationsTotal': 'Total',
    'portalDashboard.locationsWhenManager': 'total',
    'portalDashboard.locationsWhereCurrentUserisManager': 'Locations where you are the manager ',
    'portalDashboard.noLocations': 'no locations',
    'portalDashboard.os': 'Operating systems',
    'portalDashboard.osPeriod': 'last 7 days',
    'portalDashboard.recomendation': 'Recommendation',
    'portalDashboard.recomendationVKFB': 'Recommendation',
    'portalDashboard.showMore': 'show more',
    'portalDashboard.smsCost': 'Cost of SMS',
    'portalDashboard.support': 'Support',
    'portalDashboard.supportText': 'Have problem? Write us, we can help.',
    'portalLocationsStat.adStat': 'Adverts & polls statistics',
    'portalLocationsStat.allVisits': 'All visits',
    'portalLocationsStat.clicks': 'Clicks',
    'portalLocationsStat.clientsStat': 'Clients statistics',
    'portalLocationsStat.location': 'Location',
    'portalLocationsStat.locationsStat': 'Locations statistics',
    'portalLocationsStat.repeatVisit': 'Returned visits',
    'portalLocationsStat.total': 'Total',
    'portalLocationsStat.uniqueVisit': 'Unique visits',
    'portalLocationsStat.views': 'Views',
    'portalPreview.default': 'Default',
    'portalPreview.fillData': 'Please fill in the following fields',
    'portalPreview.minimalistic': 'Minimalistic',
    'portalPreview.params': 'Preview options',
    'portalPreview.portalLink': 'Link to the portal page',
    'portalPreview.preview': 'Preview',
    'portalProfiles.access': 'Access',
    'portalProfiles.accessInfo': 'To whom the profile is available or unavailable',
    'portalProfiles.advertismentSettings': 'Advert or poll settings',
    'portalProfiles.authenInfo': 'Authentication (login) profile method on the Authorization Portal. Up to 5 authentications available.',
    'portalProfiles.authenTypecallback': 'Free call',
    'portalProfiles.authenTypecallfront': 'Outgoing call',
    'portalProfiles.authenTypeemail': 'E-mail',
    'portalProfiles.authenTypeesia': 'Esia',
    'portalProfiles.authenTypesms': 'SMS',
    'portalProfiles.authenTypeuserpass': 'Personal code',
    'portalProfiles.authInfo': 'Authorization method on the authorization portal. Up to 5 authorizations available.',
    'portalProfiles.authnSkipCaption': 'Skip authentication',
    'portalProfiles.authzDefaultCaption': 'Default authorisation',
    'portalProfiles.callbackInfo': '',
    'portalProfiles.callfrontInfo': "Authentication using an outgoing user's call",
    'portalProfiles.checkAuthNsWhenAuthenSkipIsFalseErrorText': 'If the "Skip authentication" mode is disabled, at least one authentication must be enabled',
    'portalProfiles.chooseCustomInterval': 'Select a period of time',
    'portalProfiles.condition': 'Condition',
    'portalProfiles.conditionInfo': 'Profile works for specified parameters.\nIt is pronibited to set the same settings (WLANs list, AP, NAS ID) for different portal profiles - the result could be unpredictable!',
    'portalProfiles.countryCode': 'Country code',
    'portalProfiles.customTimeInterval': 'Custom',
    'portalProfiles.cutDownButton': 'Minimize',
    'portalProfiles.emailInfo': 'Authentication using e-mail. OTP code will be sent to the mail specified by the client.',
    'portalProfiles.esiaInfo': 'Authentication using ESIA',
    'portalProfiles.facebookInfo': 'Free internet access after authorisation via Facebook',
    'portalProfiles.freeInfo': 'Free internet access',
    'portalProfiles.generalSettingsForAllAuthNApplyButton': 'Apply',
    'portalProfiles.generalSettingsForAllAuthNCaption': 'General authentications settings',
    'portalProfiles.generalSettingsForAllAuthNInfoMsg': 'Allows you to apply some settings to all enabled authentications at once. After applying the changes, you need to save the Profile by clicking the "Save" button',
    'portalProfiles.generalSettingsForAllAuthZApplyButton': 'Apply',
    'portalProfiles.generalSettingsForAllAuthZCaption': 'General authorization settings (session settings)',
    'portalProfiles.generalSettingsForAllAuthZInfoMsg': 'Allows you to apply some settings to all enabled authorizations at once. After applying the changes, you need to save the Profile by clicking the "Save" button',
    'portalProfiles.hotel_voucherInfo': 'Access to the internet after entering the voucher code',
    'portalProfiles.instagramInfo': 'Free internet access after authorisation via Instagram',
    'portalProfiles.lineCabinetAddressCaption': 'Line Cabinet for subscription management URL',
    'portalProfiles.lineCabinetCaption': 'Line cabinet for subscription management',
    'portalProfiles.lineCabinetEnableSwitcher': 'Enable line cabinet for subscription management',
    'portalProfiles.nasForTable': 'NAS',
    'portalProfiles.noAuthentication': 'no Authentications',
    'portalProfiles.noAuthorization': 'no authorisations',
    'portalProfiles.noCPEs': 'no APs',
    'portalProfiles.noDataToDisplay': 'no data',
    'portalProfiles.noDescription': 'no description',
    'portalProfiles.noNAS': 'no NAS',
    'portalProfiles.notificationProfileCreatedSuccessfully': 'Profile successfully created',
    'portalProfiles.notificationProfileDeletedSuccessfully': 'Profile successfully deleted',
    'portalProfiles.notificationProfileUpdatedSuccessfully': 'Profile successfully updated',
    'portalProfiles.noWLANs': 'No WLANs',
    'portalProfiles.otpCodeText': 'OTP text',
    'portalProfiles.otpInfo': 'OTP message text. Must contain {CODE} ',
    'portalProfiles.phonePrefixOptional': 'Prefix (optional)',
    'portalProfiles.profileEdit': 'Edit profile',
    'portalProfiles.sameAsForAuthN': 'Same settings as',
    'portalProfiles.sameAsForAuthZ': 'Same settings as',
    'portalProfiles.sessionSettings': 'Session settings',
    'portalProfiles.settingsForAuthZandAuthN': 'Settings',
    'portalProfiles.showMacSettingsView': 'Show instruction during private MAC addresses connection',
    'portalProfiles.skipInfo': 'Free internet access without authorisation',
    'portalProfiles.smsInfo': 'Authentication using SMS. OTP code will be sent to the phone specified by the user. ',
    'portalProfiles.socialAuthRequiredFieldsNotForgetToFillNotificationMsg': "don't forget to fill in the required fields",
    'portalProfiles.sponsorInfo': 'Free internet access from sponsor',
    'portalProfiles.staffInfo': 'Free internet access for staff',
    'portalProfiles.subscriptionInfo': 'Access to the internet after subscribtion',
    'portalProfiles.tosInfo': 'Show user "Terms of service" and ask for their consent to them',
    'portalProfiles.userpassInfo': 'Authentication using a previously obtained code. Available only to users from the access list.',
    'portalProfiles.validatorErrors': 'There are validation errors. Check all fields including fields in hidden categories.',
    'portalProfiles.vkInfo': 'Free internet access after authorisation via VK',
    'portalProfiles.voucherInfo': 'Access to the internet after entering the voucher code',
    'portalProfiles.vouchersSettings': 'Voucher settings',
    'portalSessions.allCpes': 'All APs',
    'portalSessions.allWlans': 'All WLANs',
    'portalSessions.authenStatechecked': 'Authentication passed',
    'portalSessions.authenStateneed': 'No authentication',
    'portalSessions.authenStatesent': 'Code entry or call pending',
    'portalSessions.authenStateTableCaption': 'Authentication state',
    'portalSessions.authenStateunset': 'All',
    'portalSessions.authenTypecallback': 'Free call',
    'portalSessions.authenTypecallfront': 'Outgoing call',
    'portalSessions.authenTypeemail': 'E-mail',
    'portalSessions.authenTypeesia': 'Esia',
    'portalSessions.authenTypesms': 'SMS',
    'portalSessions.authenTypeTableCaption': 'Authentication type',
    'portalSessions.authenTypeunset': 'All',
    'portalSessions.authenTypeuserpass': '#Н/Д',
    'portalSessions.authZStatechecked': 'Authorisation passed',
    'portalSessions.authZStatenone': 'No authorisation',
    'portalSessions.authZStateTableCaption': 'Authorisation state',
    'portalSessions.authZStateunset': 'All',
    'portalSessions.authZTimeBeginTableCaption': 'Authorisation start time',
    'portalSessions.authZTimeEndTableCaption': 'Authorisation end time',
    'portalSessions.authZTypefacebook': 'Facebook',
    'portalSessions.authZTypefb': 'Facebook',
    'portalSessions.authZTypefree': 'Free',
    'portalSessions.authZTypehotel_voucher': 'Hotel voucher',
    'portalSessions.authZTypeinstagram': 'Instagram',
    'portalSessions.authZTypenone': '',
    'portalSessions.authZTypenotSelect': 'All',
    'portalSessions.authZTypeskip': 'Skip',
    'portalSessions.authZTypesponsor': 'Sponsored',
    'portalSessions.authZTypestaff': 'Staff',
    'portalSessions.authZTypesubscription': 'Subscription',
    'portalSessions.authZTypeTableCaption': 'Authorisation type',
    'portalSessions.authZTypetype': 'Type',
    'portalSessions.authZTypeunset': 'Unset',
    'portalSessions.authZTypevk': 'VK',
    'portalSessions.authZTypevoucher': 'Voucher',
    'portalSessions.browser': 'Browser',
    'portalSessions.costForTable': 'Cost of SMS',
    'portalSessions.costForTableInfo': 'The cost is provided for sessions with the SMS authentication type',
    'portalSessions.cpe': 'AP',
    'portalSessions.cpeTableCaption': 'AP',
    'portalSessions.create': 'Date',
    'portalSessions.create_at': 'Date',
    'portalSessions.creationSessionForTable': 'Session creation time',
    'portalSessions.dateTimeFiltersCaption': 'Date and time filters',
    'portalSessions.deleteSessionButtonCaption': 'Delete the session',
    'portalSessions.deleteSessionModalConfirm': 'Are you confirming the deletion of the session',
    'portalSessions.deleteSessionModalHeader': 'Deleting a session',
    'portalSessions.expirationSessionForTable': 'Session expiration time',
    'portalSessions.getSessions': "Show {num} user's sessions",
    'portalSessions.identityTableCaption': 'ID',
    'portalSessions.ip': 'IP',
    'portalSessions.isDeletedForDownloadsForTableCaption': 'Deleted',
    'portalSessions.isDeletedForDownloadsNo': 'No',
    'portalSessions.isDeletedForDownloadsYes': 'Yes',
    'portalSessions.mac': 'MAC',
    'portalSessions.nas_id': 'NAS ID',
    'portalSessions.oneOrMoreDateTimeFiltersAreEnabled': 'one or more filters are enabled',
    'portalSessions.os': 'OS',
    'portalSessions.osVersion': 'OS version',
    'portalSessions.profile': 'Profile',
    'portalSessions.sessionCreationDate': 'Session creation time',
    'portalSessions.sessionCreationExpirationDate': 'Session creation time / Session expiration time',
    'portalSessions.sessionIsDelitedfalse': 'Not deleted',
    'portalSessions.sessionIsDelitedTitleCaption': 'Session is deleted',
    'portalSessions.sessionIsDelitedtrue': 'Deleted',
    'portalSessions.sessionIsDelitedunset': 'All',
    'portalSessions.sessions': 'User sessions',
    'portalSessions.socialNetworksFirstNameTableCaption': 'First Name',
    'portalSessions.socialNetworksIdTableBirthday': 'Birthday',
    'portalSessions.socialNetworksIdTableCaption': 'ID',
    'portalSessions.socialNetworksIdTableCity': 'City',
    'portalSessions.socialNetworksIdTableEmail': 'E-mail',
    'portalSessions.socialNetworksNameTableCaption': 'Name',
    'portalSessions.socialNetworksSecondNameTableCaption': 'Last name',
    'portalSessions.stateTableCaption': 'Session state',
    'portalSessions.stateTypeadvertise': 'Advert or poll',
    'portalSessions.stateTypeauthenticate': 'Authentication',
    'portalSessions.stateTypeauthorize': 'Authorisation',
    'portalSessions.stateTypepass': 'Pass to the internet',
    'portalSessions.stateTypeunset': 'All',
    'portalSessions.tabeleViewTypeCaption': 'Displayed data type',
    'portalSessions.tableViewTypeauthenticationView': 'Authentication',
    'portalSessions.tableViewTypeauthorizationView': 'Authorisation',
    'portalSessions.tableViewTypesocialNetworksView': 'Social networks',
    'portalSessions.tableViewTypestandardView': 'Standard',
    'portalSessions.tableViewTypesubscriptionsView': 'Subscriptions',
    'portalSessions.title': 'User sessions',
    'portalSessions.ua.bot': 'Bot',
    'portalSessions.ua.desktop': 'Desktop',
    'portalSessions.ua.mobile': 'Mobile',
    'portalSessions.ua.tablet': 'Tablet',
    'portalSessions.undoDeleteSessionButtonCaption': 'Cancel the session deletion',
    'portalSessions.undoDeleteSessionModalConfirm': 'Confirm the cancellation of session deletion',
    'portalSessions.undoDeleteSessionModalHeader': 'Cancel the session deletion',
    'portalSessions.undoDeleteSessionModalUndoButton': 'Cancel the deletion',
    'portalSessions.wlan': 'WLAN',
    'portalSessions.wlanTableCaption': 'WLAN',
    'portalStats.accounts': 'Accounts',
    'portalStats.allAccounts': 'All accounts',
    'portalStats.allLocations': 'All locations',
    'portalStats.allProfiles': 'All profiles',
    'portalStats.allTariffs': 'All tariffs',
    'portalStats.auth.facebook': 'Facebook',
    'portalStats.auth.fb': 'Facebook',
    'portalStats.auth.free': 'Free',
    'portalStats.auth.hotel_voucher': 'Hotel voucher',
    'portalStats.auth.instagram': 'Instagram',
    'portalStats.auth.skip': 'Skip',
    'portalStats.auth.sponsor': 'Sponsored',
    'portalStats.auth.staff': 'Staff',
    'portalStats.auth.type': 'Type',
    'portalStats.auth.unset': 'Unset',
    'portalStats.auth.vk': 'VK',
    'portalStats.auth.voucher': 'Voucher',
    'portalStats.authen.callfront': 'Outgoing call',
    'portalStats.authen.email': 'E-mail',
    'portalStats.authen.sms': 'SMS',
    'portalStats.authen.type': 'Type',
    'portalStats.authen.userpass': 'Personal сode',
    'portalStats.authenFull': 'Authentication type',
    'portalStats.authenN': 'Authentication',
    'portalStats.authenZ': 'Authorisation',
    'portalStats.authFull': 'Authorisation types',
    'portalStats.average': 'Average value',
    'portalStats.browser': 'Browser',
    'portalStats.browserFull': 'Browsers',
    'portalStats.comparePeriodsPeriod': 'Selected period',
    'portalStats.comparePeriodsPeriodForCompare': 'Period for compare ',
    'portalStats.compareWithPreviousPeriodButton': 'Compare',
    'portalStats.count': 'Number',
    'portalStats.dataToYesterdayInclusive': 'data not include today (data for yesterday inclusive)',
    'portalStats.desktop': 'Desktop',
    'portalStats.historyData': 'Historical data',
    'portalStats.locale.en': 'English',
    'portalStats.locale.locale': 'Locale',
    'portalStats.locale.ru': 'Russian',
    'portalStats.locale.tg': 'Tajik',
    'portalStats.mobile': 'Mobile',
    'portalStats.newAndRepeatUsers': 'New and returned users',
    'portalStats.newUsers': 'New users',
    'portalStats.nonRealTimeData': 'Non-real time data',
    'portalStats.notNewUsers': 'Returned users',
    'portalStats.OS': 'OS',
    'portalStats.os': 'OS',
    'portalStats.other': 'Other',
    'portalStats.percent': 'Percent',
    'portalStats.profiles': 'Profiles',
    'portalStats.profileSelection': 'Profiles',
    'portalStats.range': 'Range',
    'portalStats.realTimeData': 'Real time data',
    'portalStats.selectAccount': 'Select an account',
    'portalStats.selectAccountPlaceholder': 'Select an account',
    'portalStats.selectAds': 'Select adverts',
    'portalStats.selectCpe': 'Select an AP',
    'portalStats.selectLocation': 'Select a location',
    'portalStats.selectLocationWithChild': 'With child',
    'portalStats.selectPage': 'Select a page',
    'portalStats.selectProfile': 'Select a profile',
    'portalStats.selectRange': 'Select a period',
    'portalStats.selectRules': 'Select rules',
    'portalStats.selectTariff': 'Select a tariff',
    'portalStats.selectTheme': 'Select a theme',
    'portalStats.selectWlan': 'Select a WLAN',
    'portalStats.showSelectPreviousPeriodBlockButton': 'Compare with another period',
    'portalStats.smsCostInfoMsg': 'This metric is independent of the Location or Profile selection filters. When you select anything in these filters, they are ignored for this metric',
    'portalStats.stats': 'Statistics',
    'portalStats.tariffs': 'Tariffs',
    'portalStats.totalConnections': 'Total connections',
    'portalStats.type': 'Type',
    'portalStats.typeFull': 'Device type',
    'portalStats.uniqClients': 'Unique clients',
    'portalStats.users.new': 'New users',
    'portalStats.users.repeat': 'Returned users',
    'portalStats.users.type': 'Users',
    'portalStats.usersForTable.new': 'New',
    'portalStats.usersForTable.repeat': 'Returned',
    'portalStats.usersForTable.type': 'Type',
    'portalStats.usersFull': 'Users',
    'portalStats.vendors': 'Vendors',
    'portalStats.vendorsForTable': 'Vendors',
    'portalStats.vendorsFull': 'Vendors',
    'portalStats.vouchers': 'Vouchers',
    'portalStats.vouchersAmount': 'Total vouchers amount',
    'radar.addNew': 'Add new segment',
    'radar.addresses': 'Addresses',
    'radar.addressesCountCaption': 'Total',
    'radar.addressesOrBusinessId': 'Addresses / Business ID',
    'radar.addressesOrCTN': 'Addresses / CTN',
    'radar.allStatuses': 'All statuses',
    'radar.allVisitors': 'All',
    'radar.audience': 'Audience',
    'radar.audienceForBeelineForTableCaption': 'Audience',
    'radar.auto': 'Periodicity',
    'radar.autoPeriod': 'Auto export periodicity (in hours)',
    'radar.autoPlaceholder': 'Enter autoperiod',
    'radar.beepro': 'Beepro',
    'radar.businessIdErrorShow': 'A user with Business id must be selected (and Business id must not be 0)',
    'radar.businessIdsCaption': 'Business ID',
    'radar.cabinet': 'To BeePro',
    'radar.cancelSelectAllCpesButtonCaption': 'Clear all selected APs',
    'radar.continuously': 'Continuously',
    'radar.cpeListNoOptionsMsg': 'No APs',
    'radar.cpeListNoOptionsMsgWhenFilterEnabled': 'No APs matching filter conditions',
    'radar.cpeListNoResultMsg': 'No APs with the same name was found',
    'radar.cpeListNoResultMsgWhenFilterEnabled': 'No APs with the name matching the filter conditions was found',
    'radar.cpeListPlaceholder': 'Select APs or enter a name to search',
    'radar.cpes': 'APs',
    'radar.cpeSelectedCounterCaption': 'Selected APs',
    'radar.createCopy': 'create copy',
    'radar.created': 'Created',
    'radar.createdForBeelineForTableCaption': 'Date of creation',
    'radar.createdForCTNForBeelineForTableCaption': 'Created for client with CTN',
    'radar.ctnCaption': 'CTN',
    'radar.ctnErrorShow': 'A user with CTN must be selected',
    'radar.dataRangeFilterTypeButtonCreated': 'Created',
    'radar.dataRangeFilterTypeButtonUpdated': 'Updated',
    'radar.deleteModalText': 'Confirm deletion',
    'radar.deleteModalTitle': 'Delete a segment',
    'radar.description': 'Description',
    'radar.descriptionPlaceholder': 'Enter description',
    'radar.email': 'E-mail',
    'radar.exportNameForBeelineForModalCaption': 'Segment name',
    'radar.exportNameForBeelineForTableCaption': 'Segment name',
    'radar.filter': 'Filter by visitors',
    'radar.filtersForCpeListSwitcherCaption': 'Filtering a list of APs',
    'radar.filtersForCpeListSwitcherInfoTooltip': 'Filtering makes it easy to find the desired AP in the AP selection list. Allows you to filter access points by their location and by the state of enabling or disabling the Wi-Fi Target on them',
    'radar.filtersForCpeListSwitcherLocationCaption': 'AP location',
    'radar.finished': 'Finished',
    'radar.format': 'Format',
    'radar.hash': 'Hash data',
    'radar.hashInfo': 'This is a data mask obtained by encryption. Serves to unambiguously identify an object or entity',
    'radar.infinity': 'No limits',
    'radar.items': 'Current items',
    'radar.match': 'match',
    'radar.matched': 'found',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedError': 'Exceeded the limit on the number of APs with enabled Wi-Fi Target for a specified time period',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMore': 'More about restrictions',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader': 'About restrictions',
    'radar.mytarget': 'myTarget',
    'radar.name': 'Name',
    'radar.namePlaceholder': 'Enter the segment name',
    'radar.newVisitors': 'Only new',
    'radar.notificationRadarExportCreatedSuccessfully': 'Segment successfully created',
    'radar.notificationRadarExportDeletedSuccessfully': 'Segment successfully deleted',
    'radar.notificationRadarExportUpdatedSuccessfully': 'Segment successfully updated',
    'radar.period': 'Period',
    'radar.processed': 'Processed',
    'radar.radarEnableStatusForCpeFilterAny': 'Any',
    'radar.radarEnableStatusForCpeFilterCaption': 'Wi-Fi target status on APs',
    'radar.radarEnableStatusForCpeFilterDisabled': 'Wi-Fi target is disabled',
    'radar.radarEnableStatusForCpeFilterEnabled': 'Wi-Fi target is enabled',
    'radar.radarFiltringForCpeListIsEnabled': 'enabled',
    'radar.radarFiltringForCpeListResetFiltersButton': 'reset filter',
    'radar.refreshButtonCaption': 'Refresh',
    'radar.returnVisitors': 'Only returned',
    'radar.running': 'Running',
    'radar.selectAllCpesButtonCaption': 'Add all filtered APs',
    'radar.selectedBusinessIdsCaption': 'Selected Business ID',
    'radar.selectedCPEsCaption': 'Selected',
    'radar.selectedCTNCaption': 'Selected CTN',
    'radar.selectPlaceholder': 'Select APs',
    'radar.settingsBeepro': 'Beepro settings',
    'radar.settingsEmail': 'E-mail settings',
    'radar.settingsExport': 'Export settings',
    'radar.settingsForBeelineForModalCaption': 'Settings',
    'radar.settingsGeneral': 'General settings',
    'radar.share': 'Share',
    'radar.sortTypeCreated': 'By created time',
    'radar.sortTypeName': 'By name',
    'radar.sortTypeUpdated': 'By updated time',
    'radar.status': 'Status',
    'radar.statusForBeelineForTableCaption': 'Segment processing status',
    'radar.subject': 'Subject',
    'radar.subjectPlaceholder': 'Enter the subject',
    'radar.tableHeaderForBeelineForTableCaption': 'Segment list',
    'radar.title': 'Wi-Fi target segments',
    'radar.type': 'Type',
    'radar.updated': 'Last updated',
    'radar.user': 'User',
    'radar.usersManualLinkCaption': "User's manual",
    'radar.usersWithBusinessIdsPlaceholder': 'Select a user or enter a name to search',
    'radar.valid': 'exported',
    'radar.wifiTargetDisabledOnAllSelectedCPES': 'Attention! Wi-Fi target is disabled on all selected APs',
    'radar.wifiTargetEnabled': 'Wi-Fi target is enabled',
    'radar.wifiTargetEnabledShort': 'Wi-Fi target enab.',
    'radar.wrongEmailMsg': 'Wrong e-mail',
    'radar.yandex': 'Yandex',
    'radar.yandexCaption': 'Email format of ‘@yandex.ru’ must be used with ‘Yandex’ export type',
    'radius.acctPort': 'Accounting port',
    'radius.acctPortPlaceholder': 'Enter an accoun port',
    'radius.addRadius': 'Add RADIUS',
    'radius.addressField': 'IP address',
    'radius.addressInTableTitle': 'Address',
    'radius.advancedSettings': 'Fill required advanced RADIUS parameters',
    'radius.authPort': 'Auhtorization port',
    'radius.authPortPlaceholder': 'Enter an authorisation port',
    'radius.basicParameters': 'Fill required basic RADIUS parameters',
    'radius.clientOnAP': 'Client on AP',
    'radius.clientOnWLC': 'Client on WLC',
    'radius.clientOnWLCHint': 'Only for networks with web authentication (Captive portal)',
    'radius.coaServerKey': 'CoA Server Key',
    'radius.coaServerKeyConfirmation': 'CoA server key confirmation',
    'radius.coaSettings': 'CoA (RFC 5176)',
    'radius.confirmDeletingModal': 'Confirm deletion',
    'radius.confirmRadius': 'Confirm new RADIUS creation',
    'radius.createRadiusServer': 'Add RADIUS server',
    'radius.daeBlockHintInfoMsg': 'Dynamic authorization extension (RFC 5176)',
    'radius.daeClient': 'DAE client',
    'radius.daeClientHintInfoMsg': 'Dynamic Authorization Extension client. This client can send “Disconnect-Request” or “CoA-Request” packets to forcibly disconnect a client or change connection parameters. Wildcard IP (like 0.0.0.0) are not working - only direct is allowed',
    'radius.daeClientPlaceholder': 'DAE client (IP address)',
    'radius.daePort': 'DAE port',
    'radius.daePortHintInfoMsg': 'Port the Dynamic Authorization Extension server listens on',
    'radius.daePortPlaceholder': 'DAE port (3799 by default)',
    'radius.daeSecret': 'DAE secret',
    'radius.daeSecretHintInfoMsg': 'Shared DAE secret',
    'radius.daeSecretPlaceholder': 'Shared DAE secret',
    'radius.daeSettings': 'Dynamic authorization extension (RFC 5176)',
    'radius.deleteRADIUSModalTitle': 'Delete the RADIUS',
    'radius.editRadiusServer': 'Edit RADIUS Server',
    'radius.ipPlaceholder': 'X.X.X.X',
    'radius.key': 'Key',
    'radius.keyConfirmation': 'Key confirmation',
    'radius.local': 'Local',
    'radius.namePlaceholder': 'Enter a name',
    'radius.notificationRADIUSCreatedSuccessfully': 'RADIUS successfully created',
    'radius.notificationRADIUSDeletedSuccessfully': 'RADIUS successfully deleted',
    'radius.notificationRADIUSOperationSuccessfull': 'Successfully',
    'radius.notificationRADIUSUpdatedSuccessfully': 'RADIUS successfully updated',
    'radius.password': 'Password',
    'radius.portal': 'Portal',
    'radius.radius': 'RADIUS',
    'radius.radiusForSidebar': 'RADIUS servers',
    'radius.radiusList': 'RADIUS list',
    'radius.radiusWizard': 'Wizard for RADIUS creation',
    'radius.radiusWizardButtonCaption': 'RADIUS Wizard',
    'radius.secret': 'Secret',
    'radius.secretPlaceholder': 'Enter RADIUS Key',
    'radius.wizardAdvancedParameters': 'Advanced settings',
    'reactiveLogs.addButton': 'Add',
    'reactiveLogs.confirmDeletingModal': 'Confirm deletion',
    'reactiveLogs.confirmDeletingModalHeader': 'Delete',
    'reactiveLogs.customIntervalsUnits.hours': 'h.',
    'reactiveLogs.customIntervalsUnits.minutes': 'min',
    'reactiveLogs.customIntervalsUnits.seconds': 'sec',
    'reactiveLogs.datePickerStartGreaterThenEndError': 'The end date and time cannot be earlier than the start date',
    'reactiveLogs.debugDHCP': 'Debug DHCP',
    'reactiveLogs.debuggedCaption': 'Debugged ',
    'reactiveLogs.debugHostap': 'Debug hostAP',
    'reactiveLogs.debugNTP': 'Debug NTP',
    'reactiveLogs.debugStateCaption': 'Debug state',
    'reactiveLogs.debugStateStopped': 'stopped',
    'reactiveLogs.debugStateStrated': 'started',
    'reactiveLogs.deleteButton': 'Delete',
    'reactiveLogs.endTime': 'Expiration time',
    'reactiveLogs.fileDownloadingError': 'File download error',
    'reactiveLogs.generateButton': 'Generate',
    'reactiveLogs.generateLogsFor': 'Generate logs for the last',
    'reactiveLogs.generateLogsFor10Minutes': '10 minutes',
    'reactiveLogs.generateLogsFor1Hour': '1 hour',
    'reactiveLogs.generateLogsFor20Minutes': '30 minutes',
    'reactiveLogs.generateLogsForCustomDatetime': 'Set custom datetime',
    'reactiveLogs.interval': 'Range',
    'reactiveLogs.logsSettingsModal': 'Log settings',
    'reactiveLogs.logState': 'State',
    'reactiveLogs.logStateError': 'Error',
    'reactiveLogs.logStateInProgress': 'In progress',
    'reactiveLogs.logStateSuccessful': 'Successfully',
    'reactiveLogs.modalMACIP': 'MAC/IP Address',
    'reactiveLogs.modalMACIPInputHint': 'Enter the MAC/IP address: each in a new row (no more than 3). If the identifiers refer to one object, they must be entered in one line separated by commas (no more than 3)',
    'reactiveLogs.modalMACIPEditInputHint': 'Enter the MAC/IP address, they must be entered in one line separated by commas (no more than 3)',
    'reactiveLogs.notificationCreatedSuccessfully': 'Filter successfully created',
    'reactiveLogs.notificationDeletedSuccessfully': 'Filter successfully deleted',
    'reactiveLogs.notificationStartedSuccessfully': 'Log collection successfully started',
    'reactiveLogs.notificationStoppedSuccessfully': 'Log collection successfully stopped',
    'reactiveLogs.notificationUpdatedSuccessfully': 'Filter successfully updated',
    'reactiveLogs.nowClickGenerateToGetGatheredDataHint': 'Now click Generate to get gathered data',
    'reactiveLogs.replicateTheIssueNowHint': 'Replicate the issue now',
    'reactiveLogs.seeDetails': 'See details',
    'reactiveLogs.startButton': 'Start',
    'reactiveLogs.startdataCapturingFirstHint': 'Please Start data capturing first and replicate the issue',
    'reactiveLogs.startDateTime': 'Date and time when the log data collection started',
    'reactiveLogs.startTime': 'Start time',
    'reactiveLogs.stopButton': 'Stop',
    'reactiveLogs.stopDateTime': 'Date and time when the log data collection ended',
    'reactiveLogs.tableAction': 'Action',
    'reactiveLogs.tableMACIP': 'MAC/IP Address',
    'reactiveLogs.tableTraceFile': 'Trace file',
    'reactiveLogs.errors.maxLinesEnteredForReactiveLogs': 'More than 3 lines entered',
    'recommendations.addAdds.button': 'Configure adevrt',
    'recommendations.addAdds.text': 'Add adverts to the portal to increase the sales.',
    'recommendations.addAdds.title': 'Add portal adverts',
    'recommendations.addBanners.button': 'Enable banners',
    'recommendations.addBanners.text': 'Add advert banners to draw attention of portal visitors.',
    'recommendations.addBanners.title': 'Add banners',
    'recommendations.addPolls.button': 'Enable polls',
    'recommendations.addPolls.text': 'Polls allow you to collect opinions of portal visitors or entertain them.',
    'recommendations.addPolls.title': 'Add polls',
    'recommendations.addPollsQuality.button': 'Enable quality reviews',
    'recommendations.addPollsQuality.text': 'Quality reviews allow you to collect portal visitors opinions about anything.',
    'recommendations.addPollsQuality.title': 'Add quality reviews',
    'recommendations.addPollsUserDataBirthday.button': 'Configure polls',
    'recommendations.addPollsUserDataBirthday.text': 'User data polls allow you to collect information about birthdays of Portal visitors.',
    'recommendations.addPollsUserDataBirthday.title': 'Setup User Data polls',
    'recommendations.addRadarExport.button': 'Configure segments export',
    'recommendations.addRadarExport.text': 'Setup periodic export of Wi-Fi target segments to receive up-to-date reports on gathered data.',
    'recommendations.addRadarExport.title': 'Wi-Fi target reports',
    'recommendations.addVideo.button': 'Setup video',
    'recommendations.addVideo.text': 'Use video for promotion of products and services.',
    'recommendations.addVideo.title': 'Show Video',
    'recommendations.allGood.text': 'All recommendations are implemented.',
    'recommendations.allGood.title': 'Well done!',
    'recommendations.distressedCPEs.button': 'Show APs',
    'recommendations.distressedCPEs.text': 'APs with errors detected. Configuration must be checked, AP Agent must be fixed and/or restarted. Please contact the administrator.',
    'recommendations.distressedCPEs.title': 'AP errors',
    'recommendations.easyInstallWizard.button': 'Go',
    'recommendations.easyInstallWizard.text': 'Follow the wizard steps to easily set up and configure a WLAN with a portal',
    'recommendations.easyInstallWizard.title': 'Simple installation',
    'recommendations.heading': 'Recommendations',
    'recommendations.highCPULoad.text': 'CPU load exceeds 90%. Please contact the server administrator.',
    'recommendations.highCPULoad.title': 'High CPU load',
    'recommendations.knowledgeBase.button': 'Go',
    'recommendations.knowledgeBase.text': 'A section describing the main sections of the platform will help you get to know the platform better',
    'recommendations.knowledgeBase.title': 'Knowledge base',
    'recommendations.moveRecommendationsBottom': 'move down',
    'recommendations.moveRecommendationsTop': 'move up',
    'recommendations.showAdsStats.button': 'See statistics',
    'recommendations.showAdsStats.text': 'Find out how effective your adverts are.',
    'recommendations.showAdsStats.title': 'Adverts statistics',
    'registerMyCPEPlease.cpeAlreadyRegisteredInfoMessage': 'A device with such ID is already registered',
    'registerMyCPEPlease.cpeId': 'Device ID',
    'registerMyCPEPlease.cpeRegisterButton': 'Register',
    'registerMyCPEPlease.cpeRegistrationHeader': 'Device registration',
    'registerMyCPEPlease.deviceMoreInfo': 'Device — access point or server',
    'registerMyCPEPlease.goToAllCpeListButton': 'Go to the list of devices',
    'reports.add': 'Add report',
    'reports.addCharts': 'Add charts',
    'reports.addChartsCaption': "Available only for 'aps_common' subject and 'xlsx' format",
    'reports.collect_period': 'Period',
    'reports.confirmDeleting': 'Confirm deletion',
    'reports.dayMonth': 'Day (month)',
    'reports.dayWeek': 'Day of week',
    'reports.deleteReport': 'Delete a report',
    'reports.editReport': 'Edit a report',
    'reports.format': 'Report type',
    'reports.formats.csv': 'csv',
    'reports.formats.json': 'json',
    'reports.formats.xlsx': 'xlsx',
    'reports.hour': 'hour',
    'reports.newReport': 'New report',
    'reports.notificationReportCreatedSuccessfully': 'Report successfully created',
    'reports.notificationReportDeletedSuccessfully': 'Report successfully deleted',
    'reports.notificationReportUpdatedSuccessfully': 'Report successfully updated',
    'reports.period.day': 'Every day',
    'reports.period.month': 'Every month',
    'reports.period.now': 'Now',
    'reports.period.week': 'Every week',
    'reports.placedescr': 'Enter a report description',
    'reports.placeHolderAddEmail': 'Enter an e-mail',
    'reports.placename': 'Enter a report name',
    'reports.subject': 'Subject',
    'reports.subjects.clients': 'Clients',
    'reports.subjects.cpes': 'APs',
    'reports.subjects.cpes_common': 'APs summary statistics',
    'reports.subjects.events': 'Events',
    'reports.tableTooltipStart': 'Start',
    'reports.tableTooltipStop': 'Stop',
    'reports.type': 'Report type',
    'reports.types.current': 'current',
    'reports.types.summary': 'summary',
    'reports.typestypeForClientsSubject.clients': 'Clients',
    'reports.typestypeForClientsSubject.clients_auth': 'Authorised clients',
    'reports.typestypeForClientsSubject.current': 'current',
    'reports.typestypeForClientsSubject.summary': 'summary',
    'reports.week.0': 'Sunday',
    'reports.week.1': 'Monday',
    'reports.week.2': 'Tuesday',
    'reports.week.3': 'Wednesday',
    'reports.week.4': 'Thursday',
    'reports.week.5': 'Friday',
    'reports.week.6': 'Saturday',
    'RRM.alertAction': 'Configure radio interfaces',
    'RRM.alertText': 'Background scanning on radio interfaces must be enabled.',
    'RRM.algo': 'Algorithm',
    'RRM.algorithmBlind': 'Blind',
    'RRM.algorithmGreed': 'Greed',
    'RRM.allGroups': 'All groups',
    'RRM.deleteRRMHeader': 'Delete the RRM group',
    'RRM.forGroup': 'For a group',
    'RRM.group': 'Group',
    'RRM.groups': 'Groups',
    'RRM.modalCPEs': 'APs',
    'RRM.modalGroupNamePlaceHolder': 'Enter a group name',
    'RRM.modalGroupTimeoutPlaceHolder': 'Enter timeout',
    'RRM.modalNOCPEs': 'No APs',
    'RRM.modalTitle': 'Add new group',
    'RRM.Movetoselectedgroup': 'Move to selected group',
    'RRM.multipleEdit': 'Multiple edit',
    'RRM.multipleEditSelectGroup': 'Select a group',
    'RRM.noGroup': 'No group',
    'RRM.notificationRRMGroupCreated': 'RRM group successfully created',
    'RRM.notificationRRMGroupDeleted': 'RRM group successfully deleted',
    'RRM.notificationRRMGroupForceCall': 'RRM has been called successfully for this group',
    'RRM.notificationRRMGroupUpdated': 'RRM group successfully updated',
    'RRM.power': 'Manage power',
    'RRM.powerForTable': 'Manage power',
    'RRM.RemovefromRRMgroups': 'Remove from RRM groups',
    'RRM.rrmForceCallButtonInModal': 'Force RRM call',
    'RRM.rrmForceCallButtonInModalDisabledHint': 'Group setting must be saved before forcing RRM call',
    'RRM.showAll': 'show all',
    'RRM.tableItemsOf': 'items of',
    'RRM.tableLoad': 'Load',
    'RRM.tableOutOf': 'APs ouf of',
    'RRM.tableRRMCPEs': 'APs',
    'RRM.tableRRMCPEsTooltip': 'Show APs',
    'RRM.tableRRMSeconds': 'sec',
    'RRM.tableSelected': 'selected',
    'RRM.tableView': 'view',
    'RRM.timeout': 'Timeout (in sec.)',
    'rules.addRule': 'Add',
    'rules.minTrafficThresholdCaption': 'Minimum traffic threshold',
    'rules.minTrafficThresholdCaptionBytes': 'in bytes',
    'rules.minTrafficThresholdCaptionBytesForTableColumn': 'bytes',
    'rules.minTrafficThresholdInfoCaption': 'If less traffic came during the period, then the script is executed',
    'rules.minTrafficThresholdPlaceholder': 'Traffic threshold (in bytes)',
    'rules.namePlaceHolderInWizard': 'Enter a rule name',
    'rules.noRules': 'none',
    'rules.notificationTypeCaption': 'Action type',
    'rules.notificationTypes.email': 'E-mail',
    'rules.notificationTypes.mqtt': 'MQTT',
    'rules.notificationTypes.telegram': 'Telegram',
    'rules.notifySettingsEmailSubjectCaption': 'Subject',
    'rules.notifySettingsEmailSubjectPlaceholder': 'Subject for e-mail',
    'rules.notifySettingsEmailTextCaption': 'Text',
    'rules.notifySettingsEmailTextPlaceholder': 'E-mail text',
    'rules.notifySettingsEmailToCaption': 'To',
    'rules.notifySettingsEmailToPlaceholder': 'Email address',
    'rules.notifySettingsForTableCaption': 'Action parameters',
    'rules.notifySettingsForTableForEmailToAddress': 'Mail to address',
    'rules.notifySettingsForTableForMQTTToTopic': 'Message into topic',
    'rules.notifySettingsForTableForTelegramToChatId': 'Message to chat with id',
    'rules.notifySettingsForTableHide': 'Hide',
    'rules.notifySettingsForTableShowMore': 'Show more',
    'rules.notifySettingsMQTTTextCaption': 'Text',
    'rules.notifySettingsMQTTTextPlaceholder': 'Text',
    'rules.notifySettingsMQTTTopicCaption': 'Topic',
    'rules.notifySettingsMQTTTopicInfo': 'MQTT Topic',
    'rules.notifySettingsMQTTTopicPlaceholder': 'Topic',
    'rules.notifySettingsTelegramChatIdCaption': 'Chat Id',
    'rules.notifySettingsTelegramChatIdInfo': 'Unique identifier of the telegram chat',
    'rules.notifySettingsTelegramChatIdPlaceholder': 'Chat Id',
    'rules.notifySettingsTelegramTextCaption': 'Text',
    'rules.notifySettingsTelegramTextPlaceholder': 'Notification text',
    'rules.notifySettingsTelegramTokenCaption': 'Token',
    'rules.notifySettingsTelegramTokenInfo': 'Token obtained from @BotFather',
    'rules.notifySettingsTelegramTokenPlaceholder': 'Token',
    'rules.ruleTypes.client_con': 'Client: Connection',
    'rules.ruleTypes.client_dis': 'Client: Disconnection',
    'rules.ruleTypes.client_far': 'Client: Distance from AP',
    'rules.ruleTypes.config_error': 'Access point: Configuration error',
    'rules.ruleTypes.connected': 'Access point: Connection',
    'rules.ruleTypes.cpu_load': 'Access point: CPU load',
    'rules.ruleTypes.customer_activity': 'Access point: Activity',
    'rules.ruleTypes.disconnected': 'Access point: Disconnection',
    'rules.ruleTypes.free_ram': 'Access point: Free RAM',
    'rules.ruleTypes.iface_error': 'Access point: Interface error',
    'rules.script': 'Script executing ',
    'rules.scriptInfoCaptionInWizard': 'Insert one line sh script. Possible env variables TEXT - event payload, CPE - AP uuid',
    'rules.scriptPlaceHolderInWizard': 'Enter the script',
    'rules.triggerScript': 'Trigger script',
    'sessions.noSessions': 'No sessions',
    'sidebar.accounts': 'Accounts',
    'sidebar.administration': 'Administration',
    'sidebar.ads': 'Adverts & polls',
    'sidebar.analiticsAndData': 'Analytics & Data',
    'sidebar.analytics': 'Analytics',
    'sidebar.badgeTooltip24h': 'Total (last 24h)',
    'sidebar.badgeTooltipConnected': 'Connected',
    'sidebar.badgeTooltipTotal': 'Total',
    'sidebar.birthdays': 'Birthdays',
    'sidebar.buyers': 'Buyers',
    'sidebar.clientDistance': 'Client distance',
    'sidebar.clientRF': 'Client RF',
    'sidebar.clients': 'Clients',
    'sidebar.ConfigExportImport': 'Configuration Export/Import',
    'sidebar.controllers': 'Controllers',
    'sidebar.cpes': 'APs',
    'sidebar.cpu': 'CPU',
    'sidebar.cpuLoad': 'CPU load',
    'sidebar.dashboard': 'Dashboard',
    'sidebar.DBState': 'DB state',
    'sidebar.DITExport': 'DIT Export',
    'sidebar.DITStat': 'DIT Statistic',
    'sidebar.events': 'Events',
    'sidebar.export': 'Export',
    'sidebar.externalAPs': 'External APs',
    'sidebar.firewall': 'Firewall',
    'sidebar.GuestControl': 'Guest control',
    'sidebar.heatmap': 'Heatmap',
    'sidebar.hotelVouchers': 'Hotel vouchers',
    'sidebar.hotspots': 'Hotspot 2.0',
    'sidebar.licenses': 'Licenses',
    'sidebar.locations': 'Locations',
    'sidebar.logs': 'Logs',
    'sidebar.maps': 'Maps',
    'sidebar.monitor': 'Monitoring',
    'sidebar.network': 'Network',
    'sidebar.nms': 'NMS',
    'sidebar.pages': 'Pages',
    'sidebar.paidInternet': 'Paid internet',
    'sidebar.paymentSystems': 'Payment systems',
    'sidebar.PeriodicTasks': 'Periodic tasks',
    'sidebar.portal': 'Portal',
    'sidebar.PortalAccessServers': 'Access Servers',
    'sidebar.portalConfiguration': 'Configuration',
    'sidebar.portalDashboard': 'Dashboard',
    'sidebar.preview': 'Preview',
    'sidebar.profiles': 'Profiles',
    'sidebar.radar': 'Wi-Fi target',
    'sidebar.radius': 'RADIUS servers',
    'sidebar.reactiveLogs': 'Reactive logs',
    'sidebar.reports': 'Reports',
    'sidebar.rrm': 'RRM',
    'sidebar.rules': 'Rules',
    'sidebar.scanner': 'Scanner',
    'sidebar.sessions': 'Find session',
    'sidebar.settings': 'Settings',
    'sidebar.SNMP': 'SNMP',
    'sidebar.SNMPMonitoringConfig': 'SNMP monitoring configuration',
    'sidebar.SNMPStat': 'SNMP Statistic',
    'sidebar.statistics': 'Statistics',
    'sidebar.support': 'Knowledge base',
    'sidebar.system': 'System',
    'sidebar.systemInfo': 'System information',
    'sidebar.tariffs': 'Tariffs',
    'sidebar.template': 'Templates',
    'sidebar.Time': 'Time',
    'sidebar.troubleshooter': 'AP connectivity',
    'sidebar.visitors': 'Visitors',
    'sidebar.vouchers': 'Vouchers',
    'sidebar.wifiAnalyzer': 'Wi-Fi analyzer',
    'sidebar.wlans': 'WLANs',
    'sidebarNew.aaa': 'AAA',
    'sidebarNew.accounts': 'Accounts',
    'sidebarNew.acl': 'ACL',
    'sidebarNew.administration': 'Administration',
    'sidebarNew.ads': 'Adverts & polls',
    'sidebarNew.adsStatistic': 'Adverts & polls (statistics)',
    'sidebarNew.analiticsAndData': 'Analytics & Data',
    'sidebarNew.analytics': 'Analytics',
    'sidebarNew.aps': 'Access points',
    'sidebarNew.apStatistics': 'AP Statistics',
    'sidebarNew.badgeTooltip24h': 'Total (last 24h)',
    'sidebarNew.badgeTooltipConnected': 'Connected',
    'sidebarNew.badgeTooltipTotal': 'Total',
    'sidebarNew.birthdays': 'Birthdays',
    'sidebarNew.buyers': 'Buyers',
    'sidebarNew.clientDistance': 'Client distance',
    'sidebarNew.clientRF': 'Client RF',
    'sidebarNew.clients': 'Clients',
    'sidebarNew.clientsStatistic': 'Clients statistics',
    'sidebarNew.ConfigExportImport': 'Configuration Export/Import',
    'sidebarNew.configuration': 'Configuration',
    'sidebarNew.controllers': 'Controllers',
    'sidebarNew.collapseMenu': 'Collapse menu',
    'sidebarNew.cpes': 'APs',
    'sidebarNew.cpu': 'CPU',
    'sidebarNew.cpuLoad': 'CPU load',
    'sidebarNew.dashboard': 'Dashboard',
    'sidebarNew.DBState': 'DB state',
    'sidebarNew.DITExport': 'DIT Export',
    'sidebarNew.DITStat': 'DIT Statistic',
    'sidebarNew.events': 'Events',
    'sidebarNew.expandMenu': 'Expand menu',
    'sidebarNew.export': 'Export',
    'sidebarNew.externalAPs': 'External APs',
    'sidebarNew.firewall': 'Firewall',
    'sidebarNew.general': 'General',
    'sidebarNew.GuestControl': 'Guest control',
    'sidebarNew.heatmap': 'Heatmap',
    'sidebarNew.hotelVouchers': 'Hotel vouchers',
    'sidebarNew.hotspotOpenRoaming': 'Hotspot/OpenRoaming',
    'sidebarNew.hotspots': 'Hotspot 2.0',
    'sidebarNew.licenses': 'Licenses',
    'sidebarNew.licensing': 'Licenses',
    'sidebarNew.locations': 'Locations',
    'sidebarNew.locationsStatistics': 'Locations (statistics)',
    'sidebarNew.logs': 'Logs',
    'sidebarNew.maps': 'Maps',
    'sidebarNew.monitor': 'Monitoring',
    'sidebarNew.monitoring': 'Monitoring',
    'sidebarNew.network': 'Network',
    'sidebarNew.newMenuStyle': 'switch to old menu',
    'sidebarNew.nms': 'NMS',
    'sidebarNew.oldMenuStyle': 'switch to new menu',
    'sidebarNew.pages': 'Pages',
    'sidebarNew.paidInternet': 'Paid internet',
    'sidebarNew.paymentSystems': 'Payment systems',
    'sidebarNew.PeriodicTasks': 'Periodic tasks',
    'sidebarNew.portal': 'Portal',
    'sidebarNew.PortalAccessServers': 'Access Servers',
    'sidebarNew.portalConfiguration': 'Configuration',
    'sidebarNew.portalDashboard': 'Dashboard',
    'sidebarNew.portalSection': 'Portal',
    'sidebarNew.preview': 'Preview',
    'sidebarNew.profiles': 'Profiles',
    'sidebarNew.radar': 'Wi-Fi target',
    'sidebarNew.radioConfiguration': 'Radio configuration',
    'sidebarNew.radioStatistics': 'Radio statistics',
    'sidebarNew.radius': 'RADIUS servers',
    'sidebarNew.reactiveLogs': 'Reactive logs',
    'sidebarNew.reports': 'Reports',
    'sidebarNew.rrm': 'RRM',
    'sidebarNew.rules': 'Rules',
    'sidebarNew.scanner': 'Scanner',
    'sidebarNew.security': 'Security',
    'sidebarNew.sessions': 'Find session',
    'sidebarNew.settings': 'Settings',
    'sidebarNew.SNMP': 'SNMP',
    'sidebarNew.SNMPMonitoringConfig': 'SNMP monitoring configuration',
    'sidebarNew.SNMPStat': 'SNMP Statistic',
    'sidebarNew.statistics': 'Statistics',
    'sidebarNew.support': 'Knowledge base',
    'sidebarNew.system': 'System',
    'sidebarNew.systemInfo': 'System information',
    'sidebarNew.tagsAndProfiles': 'Tags & profiles',
    'sidebarNew.layer2': 'Layer 2',
    'sidebarNew.tariffs': 'Tariffs',
    'sidebarNew.template': 'Templates',
    'sidebarNew.Time': 'Time',
    'sidebarNew.troubleshooter': 'AP connectivity',
    'sidebarNew.troubleshooting': 'Troubleshooting',
    'sidebarNew.visitors': 'Visitors',
    'sidebarNew.vouchers': 'Vouchers',
    'sidebarNew.webAuth': 'Web auth',
    'sidebarNew.wifiAnalyzer': 'Wi-Fi analyzer',
    'sidebarNew.wireless': 'Wireless',
    'sidebarNew.wirelessSetup': 'Wireless setup',
    'sidebarNew.wlans': 'WLANs',
    'sidebarNew.wlanWizard': 'WLANs wizard',
    'SNMP.noOptions': 'No options',
    'SNMP.applyButton': 'Apply',
    'SNMP.rollbackButton': 'Cancel',
    'SNMP.selectAll': 'Select All',
    'SNMP.deselectAll': 'Deselect All',
    'SNMP.notSelected': 'Not selected',
    'SNMP.add': 'Add',
    'SNMP.delete': 'Delete',
    'SNMP.enableAll': 'Enable All',
    'SNMP.disableAll': 'Disable All',
    'SNMP.available': 'Available',
    'SNMP.enabled': 'Enabled',
    'SNMP.apply': 'Apply',
    'SNMP.onOff': 'on/off',
    'SNMP.enabledDisbled': 'enabled/disabled',
    'SNMP.SNMPModeSwitcher': 'SNMP',
    'SNMP.SNMPGeneralHeader': 'General',
    'SNMP.SNMPViewsHeader': 'SNMP Views',
    'SNMP.SNMPCommunityStringsHeader': 'Community Strings',
    'SNMP.SNMPUserGroupsHeader': 'User Groups',
    'SNMP.SNMPUsersHeader': 'Users',
    'SNMP.SNMPHostsHeader': 'Hosts',
    'SNMP.SNMPGeneral.systemLocation': 'System Location',
    'SNMP.SNMPGeneral.systemContact': 'System Contact',
    'SNMP.SNMPGeneral.snmpTraps': 'SNMP Traps',
    'SNMP.SNMPGeneral.snmpTrapsNoEnabled': 'No SNMP Traps Enabled',
    'SNMP.SNMPGeneral.notificationUpdatedSuccessfully': 'Settings Updated Successfully',
    'SNMP.SNMPViews.included': 'Included',
    'SNMP.SNMPViews.excluded': 'Excluded',
    'SNMP.SNMPCommunityStrings.communityName': 'Community Name',
    'SNMP.SNMPCommunityStrings.accessMode': 'Access Mode',
    'SNMP.SNMPCommunityStrings.editCommunityStringModalHeader': 'Edit',
    'SNMP.SNMPCommunityStrings.addCommunityStringModalHeader': 'Create',
    'SNMP.SNMPCommunityStrings.notificationUpdatedSuccessfully': 'Community String Updated Successfully',
    'SNMP.SNMPCommunityStrings.notificationCreatedSuccessfully': 'Community String Created Successfully',
    'SNMP.SNMPCommunityStrings.notificationDeletedSuccessfully': 'Community String Deleted Successfully',
    'SNMP.SNMPCommunityStrings.communityStringAlreadyExists': 'Community string with same name already exists',
    'SNMP.SNMPCommunityStrings.communityStringAlreadyMaybeExists': 'Community string with same name maybe already exists',
    'SNMP.SNMPCommunityStrings.modes.read': 'read',
    'SNMP.SNMPCommunityStrings.modes.read/write': 'read and write',
    'SNMP.SNMPUserGroups.groupName': 'Group Name',
    'SNMP.SNMPUserGroups.securityLevel': 'Security Level',
    'SNMP.SNMPUserGroups.readView': 'Read View',
    'SNMP.SNMPUserGroups.writeView': 'Write View',
    'SNMP.SNMPUserGroups.editUserGroupsModalHeader': 'Edit',
    'SNMP.SNMPUserGroups.addUserGroupsModalHeader': 'Create',
    'SNMP.SNMPUserGroups.notificationUpdatedSuccessfully': 'User Group Updated Successfully',
    'SNMP.SNMPUserGroups.notificationCreatedSuccessfully': 'User Group Created Successfully',
    'SNMP.SNMPUserGroups.notificationDeletedSuccessfully': 'User Group Deleted Successfully',
    'SNMP.SNMPUserGroups.securityLevels.noAuthNoPriv': 'noAuthNoPriv',
    'SNMP.SNMPUserGroups.securityLevels.authNoPriv': 'authNoPriv',
    'SNMP.SNMPUserGroups.securityLevels.authPriv': 'authPriv',
    'SNMP.SNMPUsers.userName': 'User name',
    'SNMP.SNMPUsers.groupName': 'Group Name',
    'SNMP.SNMPUsers.securityMode': 'Security Mode',
    'SNMP.SNMPUsers.authenticationProtocol': 'Authentication Protocol',
    'SNMP.SNMPUsers.authenticationPassword': 'Authentication Password',
    'SNMP.SNMPUsers.authenticationPasswordConfirmation': 'Authentication Password Confirmation',
    'SNMP.SNMPUsers.privacyProtocol': 'Privacy Protocol',
    'SNMP.SNMPUsers.privacyPassword': 'Privacy Password',
    'SNMP.SNMPUsers.privacyPasswordConfirmation': 'Privacy Password Confirmation',
    'SNMP.SNMPUsers.editUsersModalHeader': 'Edit',
    'SNMP.SNMPUsers.addUsersModalHeader': 'Create',
    'SNMP.SNMPUsers.notificationUpdatedSuccessfully': 'User Updated Successfully',
    'SNMP.SNMPUsers.notificationCreatedSuccessfully': 'User Created Successfully',
    'SNMP.SNMPUsers.notificationDeletedSuccessfully': 'User Deleted Successfully',
    'SNMP.SNMPUsers.securityLevels.noAuthNoPriv': 'noAuthNoPriv',
    'SNMP.SNMPUsers.securityLevels.authNoPriv': 'authNoPriv',
    'SNMP.SNMPUsers.securityLevels.authPriv': 'authPriv',
    'SNMP.SNMPHosts.ipAddress': 'IP Address',
    'SNMP.SNMPHosts.version': 'Version',
    'SNMP.SNMPHosts.community': 'Community String',
    'SNMP.SNMPHosts.port': 'Port',
    'SNMP.SNMPHosts.type': 'Type',
    'SNMP.SNMPHosts.editHostsHeader': 'Edit',
    'SNMP.SNMPHosts.addHostsModalHeader': 'Create',
    'SNMP.SNMPHosts.notificationUpdatedSuccessfully': 'Host Updated Successfully',
    'SNMP.SNMPHosts.notificationCreatedSuccessfully': 'Host Created Successfully',
    'SNMP.SNMPHosts.notificationDeletedSuccessfully': 'Host Deleted Successfully',
    'SNMP.SNMPHosts.types.read': 'read',
    'SNMP.SNMPHosts.types.write': 'write',
    'SNMP.SNMPHosts.types.traps': 'traps',
    'SNMP.SNMPHosts.versions.1': 'V1',
    'SNMP.SNMPHosts.versions.2c': 'V2',
    'SNMP.SNMPHosts.versions.3': 'V3',
    'SNMP.SNMPWirelessTraps.enableAll': 'Enable All',
    'SNMP.SNMPWirelessTraps.disableAll': 'DIsable All',
    'SNMP.SNMPWirelessTraps.notificationUpdatedSuccessfully': 'Wireless Traps Updated Successfully',
    'SNMP.SNMPWirelessTrapsBlocks.mesh_trap': 'Mesh',
    'SNMP.SNMPWirelessTrapsBlocks.wireless_client_trap': 'Wireless Client',
    'SNMP.SNMPWirelessTrapsBlocks.rf_trap': 'RF',
    'SNMP.SNMPWirelessTrapsBlocks.roque_trap': 'Rogue',
    'SNMP.SNMPWirelessTrapsBlocks.wireless_mobility_trap': 'Wireless Mobility',
    'SNMP.SNMPWirelessTrapsBlocks.ap_trap': 'Access Point',
    'SNMP.SNMPWirelessTrapsBlocks.rrm_trap': 'RRM',
    'SNMP.SNMPWirelessTrapsBlocks.general_controller': 'General Controller',
    'SNMP.SNMPWirelessTrapsHeader': 'Wireless Traps',
    'SNMP.SNMPWirelessTrapsOptions.abate_snr': 'Abate SNR',
    'SNMP.SNMPWirelessTrapsOptions.auth_failure': 'Authentiication Failure',
    'SNMP.SNMPWirelessTrapsOptions.child_moved': 'Child Moved',
    'SNMP.SNMPWirelessTrapsOptions.child_moved:': 'Child Moved',
    'SNMP.SNMPWirelessTrapsOptions.excessive_children': 'Excessive Children',
    'SNMP.SNMPWirelessTrapsOptions.excessive_hop_count': 'Excessive Hopcount',
    'SNMP.SNMPWirelessTrapsOptions.onset_snr': 'Onset SNR',
    'SNMP.SNMPWirelessTrapsOptions.parent_change': 'Parent Change',
    'SNMP.SNMPWirelessTrapsOptions.tx_power': 'Tx Power',
    'SNMP.SNMPWirelessTrapsOptions.channels': 'Channels',
    'SNMP.SNMPWirelessTrapsOptions.coverage': 'Coverage',
    'SNMP.SNMPWirelessTrapsOptions.interference': 'Interference',
    'SNMP.SNMPWirelessTrapsOptions.load': 'Load',
    'SNMP.SNMPWirelessTrapsOptions.noise': 'Noise',
    'SNMP.SNMPWirelessTrapsOptions.anchor_client_enabled': 'Anchor Client Enabled',
    'SNMP.SNMPWirelessTrapsOptions.group': 'Group',
    'SNMP.SNMPWirelessTrapsOptions.general_controller': ' General Controller',
    'SNMP.SNMPWirelessTrapsOptions.association_fail': 'Association Fail',
    'SNMP.SNMPWirelessTrapsOptions.associate': 'Associate',
    'SNMP.SNMPWirelessTrapsOptions.authentication_fail': 'Authentication Fail',
    'SNMP.SNMPWirelessTrapsOptions.de_authenticate': 'Deauthenticate',
    'SNMP.SNMPWirelessTrapsOptions.disassociate': 'Disassociate',
    'SNMP.SNMPWirelessTrapsOptions.excluded': 'Excluded',
    'SNMP.SNMPWirelessTrapsOptions.authenticate': 'Authenticate',
    'SNMP.SNMPWirelessTrapsOptions.max_threshold_warning': 'Maximum Threshold Warning',
    'SNMP.SNMPWirelessTrapsOptions.rogue_client': 'Rogue Client',
    'SNMP.SNMPWirelessTrapsOptions.rogue_ap': 'Rogue AP',
    'SNMP.SNMPWirelessTrapsOptions.ap_stats': 'AP Statistics',
    'SNMP.SNMPWirelessTrapsOptions.authorization': 'Authorisation',
    'SNMP.SNMPWirelessTrapsOptions.broken_antenna': 'Broken antenna',
    'SNMP.SNMPWirelessTrapsOptions.dual_band_radio_band_change': 'Dual-band Radio Band Change',
    'SNMP.SNMPWirelessTrapsOptions.dual_band_radio_role_change': 'Dual-band Radio Role Change',
    'SNMP.SNMPWirelessTrapsOptions.mfp': 'MFP',
    'SNMP.SNMPWirelessTrapsOptions.mode': 'Mode',
    'SNMP.SNMPWirelessTrapsOptions.no_radio_cards': 'No Radio Cards',
    'SNMP.SNMPWirelessTrapsOptions.register': 'Register',
    'SNMP.SNMPWirelessTrapsOptions.crash': 'Crash',
    'SNMP.SNMPWirelessTrapsOptions.interface_up': 'Interface Up',
    'SNMP.SNMPWirelessTrapsOptions.ip_address_fall_back': 'IP Address Fallback',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCheckAgent': 'Monitor the agent',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCommunity': 'Community',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCommunityPlaceHolder': 'Enter community',
    'SNMPMonitoringConfig.SNMPMonitoringConfigEnabled': 'Enable SNMP',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationCreated': 'SNMP monitoring configuration successfully created',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationDeleted': 'SNMP monitoring configuration successfully deleted',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationUpdated': 'SNMP monitoring configuration successfully updated',
    'SNMPMonitoringConfig.SNMPMonitoringConfigSaveState': 'Save request state in the database',
    'SNMPMonitoringConfig.SNMPMonitoringConfigTitle': 'SNMP monitoring configuration',
    'SNMPMonitoringConfig.SNMPMonitoringCPEMustBeAccessibleByIPFromServer': 'The AP must be accessible by IP from the server',
    'SNMPMonitoringConfig.SNMPMonitoringNeedToEnableSNMPInCPESettings': 'For correct work of SNMP monitoring, you must enable the SNMP agent in the AP settings',
    'SNMPMonitoringConfig.tableColumnTitleIP': 'IP',
    'SNMPMonitoringConfig.tableColumnTitleServer': 'Server',
    'SNMPMonitoringConfig.tableColumnTitleSNMPStatus': 'SNMP Status',
    'SNMPStat.address': 'Address',
    'SNMPStat.associated_users': 'Associated users',
    'SNMPStat.associated_users_small': 'Assoc. users',
    'SNMPStat.availability': 'Availability',
    'SNMPStat.availabilityAvailably': 'Available',
    'SNMPStat.availabilityNOTAvailably': 'Not available',
    'SNMPStat.client': 'Client',
    'SNMPStat.filtersAvailablyAll': 'All',
    'SNMPStat.filtersAvailablyAvailably': 'Available',
    'SNMPStat.filtersAvailablyNotAvailably': 'Not available',
    'SNMPStat.id': 'ID',
    'SNMPStat.name': 'Name',
    'SNMPStat.nas_ip': 'NAS IP',
    'SNMPStat.SNMPStatTitle': 'SNMP Statistic',
    'SNMPStat.sortAssociatedUsers': 'By associated users',
    'SNMPStat.sortAvailability': 'By availability',
    'SNMPStat.sortClient': 'By client name',
    'SNMPStat.sortLocation': 'By location name',
    'SNMPStat.sortName': 'By name',
    'SNMPStat.sortNASIP': 'By NAS IP',
    'SNMPStat.sortNumberOfWLANs': 'By number of WLANs',
    'SNMPStat.sortTimestamp': 'By timestamp',
    'SNMPStat.sortTrafficRX': 'By RX traffic',
    'SNMPStat.sortTrafficRXSpeed': 'By Traffic RX speed',
    'SNMPStat.sortTrafficTX': 'By TX traffic',
    'SNMPStat.sortTrafficTXSpeed': 'By Traffic TX speed',
    'SNMPStat.sortUptime': 'By uptime',
    'SNMPStat.time_stamp': 'Timestamp',
    'SNMPStat.traffic_rx_speed': 'Traffic RX speed',
    'SNMPStat.traffic_rx_volume': 'RX',
    'SNMPStat.traffic_tx_speed': 'Traffic TX speed',
    'SNMPStat.traffic_tx_volume': 'Traffic TX',
    'SNMPStat.uptime': 'Uptime',
    'SNMPStat.wlans': 'WLANs',
    'statistics.allCpes': 'All APs',
    'statistics.allUsers': 'All users',
    'statistics.authorizationDataForDownload': 'Authorisation data',
    'statistics.authorized': 'Only authorised',
    'statistics.byconnectedClients': 'By connected clients',
    'statistics.byCPULoad': 'By CPU load',
    'statistics.bydisconnectedClients': 'By disconnected clients',
    'statistics.byDuration': 'By duration',
    'statistics.byLocation': 'By location',
    'statistics.byMemoryLoad': 'By memory load',
    'statistics.bypasserbyClients': 'By Passerby clients',
    'statistics.byRxTraffic': 'By RX traffic',
    'statistics.bytotalClients': 'By total clients',
    'statistics.byTraffic': 'By traffic',
    'statistics.byTxTraffic': 'By TX traffic',
    'statistics.chooseCPEs': 'Select APs',
    'statistics.chooseDate': 'Select a date',
    'statistics.chooseDateRange': 'Select a period',
    'statistics.clients': 'Clients',
    'statistics.companyForDownload': 'Company',
    'statistics.cpeForDownload': 'AP',
    'statistics.cpuLoadChartAxis': 'CPU load',
    'statistics.cpuLoadChartSeries': 'CPU',
    'statistics.cpuLoadChartTitle': 'CPU load',
    'statistics.createAndDownload': 'Create and download',
    'statistics.export': 'Data export',
    'statistics.exportStat': 'Export Wi-Fi target data',
    'statistics.field': 'Field',
    'statistics.filteringByCPE': 'Filtering by AP',
    'statistics.filtersCPE': 'AP name',
    'statistics.filtersRadio': 'Interface name (radio or VLAN)',
    'statistics.filtersUsers': 'Users',
    'statistics.filtersWLAN': 'WLAN name',
    'statistics.from': 'From',
    'statistics.goToClientsSettings': 'To the clients settings',
    'statistics.goToCPESettings': 'To the AP settings',
    'statistics.goToWLANSettings': 'To the WLAN settings',
    'statistics.ipsForDownload': 'IPs',
    'statistics.limit': 'Number',
    'statistics.locationForDownload': 'Location',
    'statistics.macForDownload': 'MAC',
    'statistics.memoryLoadChartAxis': 'Memory load',
    'statistics.memoryLoadChartSeries': 'Memory',
    'statistics.memoryLoadChartTitle': 'Memory load',
    'statistics.noCompanyNameTooltipForTable': 'No company name',
    'statistics.offset': 'Starting from',
    'statistics.order': 'Order',
    'statistics.pagination': 'Pagination',
    'statistics.paginationCPERaitngsOutOf': 'out of',
    'statistics.paginationLoad': 'Load',
    'statistics.pan': 'Click and drag to zoom in. Hold down shift key to pan',
    'statistics.passerbyClients': 'Passerby clients',
    'statistics.period': 'Period',
    'statistics.rawButton': 'Sessions report',
    'statistics.rawConfirmation': 'Please confirm client sessions report generation with the following parameters:',
    'statistics.rawModal': 'Generate report',
    'statistics.rawRequest': 'Generate report',
    'statistics.received': 'Received',
    'statistics.receivedShort': 'RX',
    'statistics.sessionClientsChartAxisOffline': 'offline',
    'statistics.sessionClientsChartAxisOnline': 'online',
    'statistics.sessionClientsChartSeriesConnected': 'Connected',
    'statistics.sessionClientsChartSeriesDisconnected': 'Disconnected',
    'statistics.sessionClientsChartTitle': 'Client sessions',
    'statistics.sessionClientsChartTooltipCPE': 'AP',
    'statistics.sessionClientsChartTooltipFrequency': 'Frequency',
    'statistics.sessionClientsChartTooltipMode': 'Mode',
    'statistics.sessionClientsChartTooltipWLAN': 'WLAN',
    'statistics.sessionDurationForDownload': 'Session duration (sec.)',
    'statistics.showClient': 'show client information',
    'statistics.ssidForDownload': 'SSID',
    'statistics.startForDownload': 'Start',
    'statistics.statCpesTooltipAvgTime': 'avg time',
    'statistics.statCpesTooltipCount': 'number',
    'statistics.statCpesTooltipFirst': 'first',
    'statistics.statCpesTooltipReturn': 'returned',
    'statistics.statCpesTooltipRx': 'RX',
    'statistics.statCpesTooltipSession': 'session',
    'statistics.statCpesTooltipTx': 'TX',
    'statistics.stopForDownload': 'Stop',
    'statistics.to': 'To',
    'statistics.totalClientsChartAxis': 'Clients',
    'statistics.totalClientsChartSeries': 'Clients',
    'statistics.totalClientsChartTitle': 'Total clients',
    'statistics.totalDurationForDownload': 'Total duration (sec.)',
    'statistics.totalTxRxPerUserChartMbytes': 'Mbytes',
    'statistics.totalTxRxPerUserChartRX': 'RX',
    'statistics.totalTxRxPerUserChartTitle': 'Total TX/RX per user',
    'statistics.totalTxRxPerUserChartTX': 'TX',
    'statistics.totalTxRxPerUserChartTXandRX': 'TX+RX',
    'statistics.trafficDownloadedForDownload': 'Traffic RX',
    'statistics.trafficUploadedForDownload': 'Traffic TX',
    'statistics.transmitted': 'Transmitted',
    'statistics.transmittedShort': 'TX',
    'statistics.txRxChartMbytes': 'Mbytes',
    'statistics.txRxChartRX': 'RX',
    'statistics.txRxChartTitle': 'Total TX/RX',
    'statistics.txRxChartTX': 'TX',
    'statistics.txRxChartTXandRX': 'TX+RX',
    'statistics.txRxRateChartMbytess': 'Mbytes/sec',
    'statistics.txRxRateChartRXRate': 'RX rate',
    'statistics.txRxRateChartTitle': 'TX/RX rate',
    'statistics.txRxRateChartTXandRXRate': 'TX+RX rate',
    'statistics.txRxRateChartTXRate': 'TX rate',
    'statistics.txRxRatePerUserChartMbytess': 'Mbytes/sec',
    'statistics.txRxRatePerUserChartRXRate': 'RX rate',
    'statistics.txRxRatePerUserChartTitle': 'TX/RX rate per user',
    'statistics.txRxRatePerUserChartTXandRXRate': 'TX+RX rate',
    'statistics.txRxRatePerUserChartTXRate': 'TX rate',
    'statistics.userData': 'User Data',
    'statistics.username': 'Last Authorization data',
    'statistics.vlanForDownload': 'VLAN',
    'statistics.wlan': 'WLAN',
    'statistics.wlanForDownload': 'WLAN',
    'statistics.wlanForReportModal': 'WLAN',
    'support.analytics.analytics1': 'assessment of the passability of a certain point, location;',
    'support.analytics.analytics2': 'heat maps;',
    'support.analytics.analytics3': 'collecting a list of MAC addresses for uploading to Yandex / myTarget for online targeting.',
    'support.analytics.analyticsHeader': 'Analytics',
    'support.analytics.analyticsMain1': 'The Wi-Fi analytics block contains the results of the analysis of data obtained on the basis of "Wi-Fi Target" - a Wi-Fi radio interface operating in the passive broadcast monitor mode, which collects all connection requests from devices to Wi-Fi networks.',
    'support.analytics.analyticsMain2': 'Based on this data, we get:',
    'support.analytics.heatmapHeader': 'Heatmap',
    'support.analytics.heatmapMain': 'The heatmap page compares the location data of access points and clients captured by Wi-Fi Target with real maps. Maps allow you to visualize the hottest zones in terms of traffic over the selected period, as well as assess the dynamics.',
    'support.analytics.radarExportHeader': 'Wi-Fi target',
    'support.analytics.radarExportMain': 'Wi-Fi target is uploading customer data by e-mail to MyTarget, Yandex audience for use when displaying adverts.',
    'support.analytics.visitorsHeader': 'Visitors',
    'support.analytics.visitorsMain1': 'Reports regarding any collected customer data can be created here. Using filters, a report can be created both for all APs and for specific ones. Based on this report, you can identify returned visits, duration of visits, customer devices, etc.',
    'support.analytics.visitorsMain2': 'The page contains an estimate of the attendance of the locations in which the Wi-Fi Target are installed. A continuous visit is a user visit for 10 minutes. New users are considered relative to the selected period. One user can have multiple visits. It is important to take into account that the data in the analytics is already devoid of noise and random MAC addresses, which makes it possible to more accurately estimate the attendance and not take into account the same users several times.',
    'support.glossary.AP': 'AP — access point',
    'support.glossary.BSSID': 'BSSID — Basic Service Set IDentifier (MAC address of the network adapter)',
    'support.glossary.CPU': 'CPU — Central Processing Unit',
    'support.glossary.DHCP': 'DHCP — Dynamic Host Configuration Protocol (the protocol responsible for dynamical issue of IP-addresses to network devices)',
    'support.glossary.DNS': 'DNS — Domain Name System',
    'support.glossary.header': 'Glossary',
    'support.glossary.HTTP': 'HTTP - HyperText Transfer Protocol',
    'support.glossary.HTTPS': 'HTTPS - HyperText Transfer Protocol Secure (an HTTP protocol extension that supports encryption)',
    'support.glossary.IP': 'IP — Internet Protocol address (unique network address of a node in a computer network)',
    'support.glossary.MAC': 'MAC — Media Access Control (unique identifier assigned to an each piece of a network equipment)',
    'support.glossary.NAS': 'NAS — Network Attached Storage (file-level storage server)',
    'support.glossary.NAT': 'NAT — Network Address Translation',
    'support.glossary.NMS': 'NMS — Network Management System',
    'support.glossary.OS': 'OS — Operating System',
    'support.glossary.RADIUS': 'RADIUS — Remote Authentication Dial-In User Service',
    'support.glossary.RRM': 'RRM — Radio Resource Management (control of radio transmission characteristics in the system)',
    'support.glossary.RSSI': 'RSSI — Received Signal Strength Indicator (total power of the signal received by the receiver)',
    'support.glossary.SOFTWARE': 'SOFT — software',
    'support.glossary.SSID': 'SSID — Service Set Identifier (the symbolic name of the wireless Wi-Fi access point)',
    'support.glossary.URL': 'URL — Uniform Resource Locator',
    'support.glossary.UUID': 'UUID — Universally Unique Identifier',
    'support.glossary.VLAN': 'VLAN — Virtual Local Area Network (local computer network, which is a group of hosts with a common set of requirements)',
    'support.glossary.WLAN': 'WLAN — Wireless Local Area Network (local area network built on the basis of wireless technologies)',
    'support.glossary.WPA': 'WPA — Wi-Fi Protected Access (wireless certification program)',
    'support.header': 'Description of the Platform',
    'support.nms.ClientRFHeader': 'Client RF',
    'support.nms.ClientRFMain': "Allows you to view the status of the client's network connection on a graph. You can view parameters such as receive rate, baud rate, signal, noise, SNR. You can also view customer ratings by signal quality (you can view SNR average, etc.) ",
    'support.nms.clientsHeader': 'Clients',
    'support.nms.ClientsHeader': 'Clients',
    'support.nms.clientsMain': 'After the client is connected to the WLAN, all information about their device will be here — MAC address, IP, distance from the AP, the name of the network to which they are connected (just press the "show client information" button in front of the device), the time of connecting to a specific router.',
    'support.nms.ClientsMain': 'After the client is connected to the WLAN, all information about their device will be here — MAC address, IP, distance from the AP, the name of the network to which they are connected (just press the "show client information" button in front of the device), the time of connecting to a specific router.',
    'support.nms.cpesConnHeader': 'APs connectivity',
    'support.nms.cpesConnMain': 'In APs connectivity, you can track the operating time of each point. Filters help you sort by location, date and connection status, which makes monitoring a section very convenient.',
    'support.nms.cpesHeader': 'APs',
    'support.nms.cpesMain1': 'Here you can find more details foe each AP.',
    'support.nms.cpesMain2': 'For each characteristic (traffic, number of clients, processor and memory load) there is data for a certain time.',
    'support.nms.dashboard1': "the servers' characteristics (CPU, CPU load, memory);",
    'support.nms.dashboard2': 'events summary 24 hours (level, type, object, status, time of access points);',
    'support.nms.dashboard3': 'the status and characteristics of the overloaded and most active access points;',
    'support.nms.dashboard4': 'the number of new and returned connections, visits;',
    'support.nms.dashboard5': 'about the devices that were signed in.',
    'support.nms.dashboardHeader': 'Dashboard',
    'support.nms.dashboardMain': 'The dashboard provides information about:',
    'support.nms.eventsHeader': 'Events',
    'support.nms.eventsMain1': 'The graph shows the events in the last 24 hours, associated with the time and the status of the connected clients.',
    'support.nms.eventsMain2': 'Also, by clicking on the client, you can view the detailed information about the connection session, AP, WLAN.',
    'support.nms.FirewallHeader': 'Firewall',
    'support.nms.FirewallMain': 'The section allows you to view and edit existing firewall rules and create new ones. Our firewall is based on Iptables. Rules are created in its notation. The documentation for the rules can be found here: ',
    'support.nms.GuestControlHeader': 'Guest Control',
    'support.nms.GuestControlMain': 'NAS Management - Network Access Server - module responsible for redirecting unauthorised users to the Captive Portal.',
    'support.nms.HotspotHeader': 'Hotspot 2.0',
    'support.nms.HotspotMain': "Manage Hotspot 2.0 profiles, with which you can connect to closed networks, such as WPA2-Enterprise, using a profile preinstalled on the device, or using the device's SIM card (EAP-SIM, EAP-AKA, EAP-AKA ' )",
    'support.nms.LicensesHeader': 'Licenses',
    'support.nms.LicensesMain': 'This section displays information about the current number of access points, the maximum number of access points. It is possible to view statistics on access points, Wi-Fi target and heat maps on a graph for a selected period',
    'support.nms.locationsHeader': 'Locations',
    'support.nms.monitoring': 'Monitoring',
    'support.nms.nms1': 'the system information about statistics on locations, access points and clients;',
    'support.nms.nms2': 'events summary 24 hours, rules, connections of access points (model, name, operating time, location), clients, distance of users;',
    'support.nms.nms3': 'the system level management of radio resources (RRM);',
    'support.nms.nms4': 'information about WiFi networks (name (SSID), security, number of access points, clients, virtual local area networks (VLAN));',
    'support.nms.nms5': 'a networking protocol that provides centralized authentication and authorization (RADIUS);',
    'support.nms.nms6': 'control and filtering of network traffic passing through the network in accordance with the specified rules (Firewall);',
    'support.nms.nms7': 'Hotspot 2.0 technology, which allows you to automatically authenticate and gain access to the Wi-Fi network by sending a request to an access point;',
    'support.nms.nms8': 'guest control (allows you to set redirect rules for portals).',
    'support.nms.nmsHeader': 'NMS',
    'support.nms.nmsMain': 'The network management platform includes:',
    'support.nms.RadiusHeader': 'RADIUS',
    'support.nms.RadiusMain': 'RADIUS - adding external AAA servers (authentication, authorization and accounting) for organizing a WPA2-Enterprise or SORM network in a WLAN',
    'support.nms.reportsHeader': 'Reports',
    'support.nms.reportsMain': 'Here you can configure reports for event, client, access point data, and point aggregate reports (aps_common). And also to download the report by periods. Available formats - JSON, CSV, XLS. For XLS, for aps_common, you can add graphs of a certain value depending on time (load, unload, clients, processor load).',
    'support.nms.RRMHeader': 'RRM',
    'support.nms.RRMMain': 'RRM — Radio Resource Management — setting up groups to run a patented resource management algorithm',
    'support.nms.rulesHeader': 'Rules',
    'support.nms.rulesMain1': 'Each AP can be assigned specific "rules" for connecting users.',
    'support.nms.rulesMain2': 'Conditions are written in a script and connected in the "Rules" tab.',
    'support.nms.rulesMain3': 'Then you can select the required rule for each AP.',
    'support.nms.SNMPMonitoringConfigHeader': 'SNMP monitoring configuration',
    'support.nms.SNMPMonitoringConfigMain': 'Allows you to enable SNMP monitoring for devices, enable monitoring of the agent and its status ',
    'support.nms.SNMPStatHeader': 'SNMP Statistic',
    'support.nms.SNMPStatMain': 'Allows you to view SNMP statistics obtained from devices configured in the SNMP monitoring config section',
    'support.nms.statistics': 'Statistics',
    'support.nms.systemCpesMain1': 'In this block, you can manage access points - reboot, update software, reset settings and install new ones, create settings templates, as well as all system information about it.',
    'support.nms.systemCpesMain2': 'In the additional settings of the WiFi network, you can generate a report on statistics.',
    'support.nms.systemHeader': 'System',
    'support.nms.systemMain1': 'It collects data from each location. The location combines all APs.',
    'support.nms.systemMain2': 'You can see here how much traffic each location consumes, the number of clients, CPU and memory usage.',
    'support.nms.TemplatesHeader': 'Templates',
    'support.nms.TemplatesMain': 'The section allows you to create templates with settings for their further use on access points ',
    'support.nms.wifiAnalyzerHeader': 'Wi-Fi analyzer',
    'support.nms.wifiAnalyzerMain1': 'This interface scans the access point and gives results of the technical characteristics of its WiFi networks (signal, channel, frequency, offset, width).',
    'support.nms.wifiAnalyzerMain2': 'Each WiFi network has a schedule. For convenience, you can select all available WLANs or several specific ones.',
    'support.nms.wlansHeader': 'WLANs',
    'support.nms.WlansHeader': 'WLANs',
    'support.nms.wlansMain': 'This tab displays information about the WLAN which is distributed by specific AP — traffic, the number of clients and the location to which it belongs.',
    'support.nms.WlansMain': 'This tab displays information about the WLAN which is distributed by specific AP — traffic, the number of clients and the location to which it belongs.',
    'support.portal.adsAndPolls1': 'period of advert activity;',
    'support.portal.adsAndPolls2': 'priority (the higher the priority, the more often this Advert will be shown);',
    'support.portal.adsAndPolls3': 'display limit (infinite or limited, after the limit expiration this advert will no longer be dispayed to users);',
    'support.portal.adsAndPolls4': 'total showing time and the ability to skip adverts;',
    'support.portal.adsAndPolls5': 'display settings by days of a week, time of a day;',
    'support.portal.adsAndPolls6': 'showing to certain (all) device models or OS.',
    'support.portal.adsAndPollsHeader': 'Adverts & polls',
    'support.portal.adsAndPollsMain1': 'All advert settings are located here.',
    'support.portal.adsAndPollsMain2': 'You can choose one of the types of advert for the portal: image, video, poll on a topic, poll on user data (for example, specify the date of birth or gender).',
    'support.portal.adsAndPollsMain3': 'In the ad settings, you can specify:',
    'support.portal.adsHeader': 'Adverts & polls',
    'support.portal.adsMain': 'Here you can see the statistics of views of advertising and polls, as well as the state of advertising at the moment (showing, planned, etc.).',
    'support.portal.authHeader': 'Connections, authentications, authorisations',
    'support.portal.authMain': 'Here are statistics of connections, authentication and authorization of new, unique users, browsers, operating systems, types and manufacturers of devices that customers use is located in these sections. All diagrams can be downloaded as images.',
    'support.portal.birthdaysHeader': 'Birthdays',
    'support.portal.birthdaysMain': "Here you can find analytics for users' birthdays, their social networks and contacts.",
    'support.portal.configuration': 'Configuration',
    'support.portal.dashboardHeader': 'Dashboard',
    'support.portal.dashboardMain': 'It is a short summary of the basic data for the "Portal" section.',
    'support.portal.data.accountsHeader': 'Accounts',
    'support.portal.data.accountsMain': 'Here are the guests of the Authorization Portals. The guest account is replenished: each guest can have several devices, authorization through different social networks, user sessions. This gives information about birthday and location. All data can be downloaded and saved in PDF, CSV and XLS formats.',
    'support.portal.data.adsAndPollsMain': 'Here you can see statistics of views by authorization types (free, sponsored, service) in the form of graphs for a specified period of time.',
    'support.portal.data.analyticsAndData': 'Analytics & Data',
    'support.portal.data.birthdaysMain': 'Here you can find data about the birthdays of your guests, collected from social networks during login and through surveys. All data can be downloaded in PDF, CSV and XLS.',
    'support.portal.data.portalLocationsStatHeader': 'Locations',
    'support.portal.data.portalLocationsStatMain': 'Summary statistics for each of the locations. There is data on clicks, views, visits (first, returned, unique, all) for each location. It is possible to filter by a profile, time interval and locations.',
    'support.portal.data.portalSessionsHeader': 'Find session',
    'support.portal.data.portalSessionsMain': 'Here you can find data about the sessions of your portal guests. You can find out information about the IP address, OS version, browser and session profile. There is the ability to filter by various parameters',
    'support.portal.data.statisticsHeader': 'Statistics',
    'support.portal.data.statisticsMain': 'It contains data on new, repeated users and unique, repeated user connections classified by the type of operating system, device type, languages, manufacturers and, of course, by identification and authorization methods. All data can be downloaded for the period and saved in PDF, CSV and XLS formats.',
    'support.portal.hotelVouchersHeader': 'Hotel vouchers',
    'support.portal.hotelVouchersMain1': 'Vouchers are needed to access the internet without authentication using a special code  (for example, via SMS).',
    'support.portal.hotelVouchersMain2': 'All information about available vouchers is displayed on this page - profile, validity time, access code, status (activated or not)',
    'support.portal.hotelVouchersMain3': 'All data can be edited in the window for each voucher.',
    'support.portal.locationHeader': 'Location',
    'support.portal.locationMain': 'On the map you can see all locations and descriptions for them: owners, addresses, dates of creation. You can edit each location in a special window.',
    'support.portal.pagesHeader': 'Pages',
    'support.portal.pagesMain': 'In this interface, you can customize the visual of the portal page - the position of the logos, background, theme, title.',
    'support.portal.paidInternet.paidInternetHeader': 'Paid internet',
    'support.portal.paidInternet.paidInternetMain': 'This block contains the settings of the paid internet.',
    'support.portal.paidInternet.paymentHeader': 'Payment systems',
    'support.portal.paidInternet.paymentMain': 'Here you set up the methods for replenishing the balance: setting the type (external gateway - going to another site for payment, guide - performing a series of actions for payment), image, specifying the merchant - a unique number to provide it to the Payment system supplier.',
    'support.portal.paidInternet.tariffsHeader': 'Tariffs',
    'support.portal.paidInternet.tariffsMain': 'Here is the update of tariffs and all the settings necessary for it: cost, speed, session length.',
    'support.portal.paidInternet.vouchersHeader': 'Vouchers',
    'support.portal.paidInternet.vouchersMain1': "Adding vouchers with all related settings - vouchers' validity time and tariff.",
    'support.portal.paidInternet.vouchersMain2': 'The list shows the status of the voucher, the code for using it, the validity period and the tariff.',
    'support.portal.portalHeader': 'Portal',
    'support.portal.portalMain': 'This block contains settings and statistics on authorization portals — special web pages on which users can go through the stages of identification (by SMS or e-mail) with authorization and displaying adverts.   ',
    'support.portal.previewHeader': 'Preview',
    'support.portal.previewMain': 'To view the demo version of the portal, you must select a profile, page and account. Then you can follow the link to the portal and check its work.',
    'support.portal.profiles1': 'to link a profile to a location;',
    'support.portal.profiles2': 'to set the time zone;',
    'support.portal.profiles3': 'to set working conditions (WLANs, APs);',
    'support.portal.profiles4': 'to add MAC addresses of users to special access lists — black list (there is no access to the profile), white list (access without authentication and authorisation, mainly for devices which cannot recieve SMS), access list (instead of authentication, use a known code or voucher);',
    'support.portal.profiles5': 'specify the method of authentication (via SMS or e-mail) and authorisation (via social networks, vouchers, etc.)',
    'support.portal.profilesHeader': 'Profiles',
    'support.portal.profilesMain1': 'Here you can see all the profiles, by clicking on each you can set certain settings:',
    'support.portal.profilesMain2': 'For each authorisation method, you can configure a session - time, amount, actions that must be performed to access the internet.',
    'support.portal.recommendationHeader': 'Recommendations',
    'support.portal.recommendationMain': 'Here are some quick tips on how to add ads and polls to use the platform more efficiently.',
    'system.addCode': 'Insert license code',
    'system.addLicense': 'Add new license',
    'system.analyticsCurrent': 'Current analytics',
    'system.analyticsHint': 'Analytics is the number of enabled Wi-Fi Targets on access points',
    'system.analyticsLimit': 'Limit Analytics',
    'system.byFile': 'By file',
    'system.byText': 'By code',
    'system.current': 'Current APs',
    'system.import': 'Import',
    'system.licenceActivationCaption': 'License activation',
    'system.limit': 'Limit APs',
    'system.portalCurrent': 'Current portals',
    'system.portalLimit': 'Limit Portals',
    'system.selectedFile': 'Selected file',
    'system.selectedFileName': 'Selected file name',
    'system.selectFile': 'Select a file',
    'system.uploadFileButton': 'Submit',
    'tags.addTags': 'Add tags',
    'tags.availlableTags': 'Available tags',
    'tags.currentTags': 'Current tags',
    'tags.deleteAllTags': 'Delete All Tags',
    'tariffs.add': 'Add a tariff',
    'tariffs.amount': 'Price',
    'tariffs.edit': 'Edit a tariff',
    'tariffs.locale.en': 'English',
    'tariffs.locale.locale': 'Locale',
    'tariffs.locale.ru': 'Russian',
    'tariffs.locale.tg': 'Tajik',
    'tariffs.name': 'Name',
    'tariffs.new': 'New tariff',
    'tariffs.noProfileSelectedError': 'You must select a profile',
    'tariffs.notificationTariffCreatedSuccessfully': 'Tariff successfully created',
    'tariffs.notificationTariffDeletedSuccessfully': 'Tariff successfully deleted',
    'tariffs.notificationTariffUpdatedSuccessfully': 'Tariff successfully updated',
    'tariffs.profileCaptionInTable': 'Profile',
    'tariffs.profileField': 'Profile',
    'tariffs.profileSelectorTip': 'Press Enter to select',
    'tariffs.recurringCaption': 'Subscription periodicity',
    'tariffs.recurringPeriodCaption': 'Recurring period',
    'tariffs.recurringPeriodDay': 'Day',
    'tariffs.recurringPeriodMonth': 'Month',
    'tariffs.recurringPeriodWeek': 'Week',
    'tariffs.recurringSwitcherCaption': 'Enable Subscription Recurring',
    'tariffs.selectProfile': 'Select a profile',
    'tariffs.session': 'Session length',
    'tariffs.speed': 'Speed',
    'tariffs.subscriptionDescriptionAdvice': 'Paste your description text in the field below',
    'tariffs.subscriptionDescriptionCaption': 'Description',
    'tariffs.tariffTypeCaption': 'Tariff type',
    'tariffs.tariffTypeSubscription': 'Subscription',
    'tariffs.tariffTypeVoucher': 'Voucher',
    'tariffs.title': 'Tariffs',
    'template.addTemplate': 'Add a template',
    'template.advancedParameters': '#Н/Д',
    'template.advancedSettings': 'Advanced settings',
    'template.allUUIDs': 'All UUIDs',
    'template.alwaysApply': 'Always apply',
    'template.autoConfig': 'Auto configuration',
    'template.autoConfiguration': 'Enable',
    'template.basicParameters': 'Fill required basic templates parameters',
    'template.configFor': 'Configuration',
    'template.configGeneral': 'General Config Settings',
    'template.configRadio': 'Interface settings',
    'template.confirmButton': 'Confirm',
    'template.cpeModel': 'AP model',
    'template.CPEsTemplates': 'APs templates',
    'template.fieldMustBeCsvFile': 'The field must be a csv file',
    'template.locationChangeHint': 'If location is changed, the selection of all WLANs selected for interfaces will be reset',
    'template.macPrefix': 'MAC prefix',
    'template.macPrefixHint': 'MAC prefix in XX:XX:XX:XX:XX:XX format',
    'template.manualConfig': 'Manual configuration',
    'template.networkMask': 'Subnet mask',
    'template.networkMaskHint': 'Subnet mask in X.X.X.X format',
    'template.notificationTemplateDeletedSuccessfully': 'Template successfully deleted',
    'template.notificationTemplateUpdatedSuccessfully': 'Template successfully updated',
    'template.requiredConfigParameters': 'Fill required config parameters',
    'template.statLogLBSSettings': 'Stat & Wi-Fi Target & Log Settings',
    'template.tableCPEsUUID': 'APs',
    'template.tableOff': 'off',
    'template.tableOn': 'on',
    'template.template': 'Template',
    'template.templateAddUUIDPlaceHolder': 'Add UUID',
    'template.templateDescriptionPlaceHolder': 'Enter description',
    'template.templateNamePlaceHolder': 'Enter template name',
    'template.templateSettings': 'Template settings',
    'template.templateWizard': 'Wizard for template creation',
    'template.wizardrConfigParametersTitle': 'Configuration settings',
    'template.wizardSelectModelPlaceholder': 'Select model',
    'template.wizardWLANs': 'WLANs',
    'template.wizardWLANsPlaceHolder': 'Select a WLAN',
    'template.wlansSelectorHint': 'Only WLANs from the location selected for the template are available',
    'template.wrongDataInFile': 'Wrong data in file',
    'userAccount.actions': 'Actions',
    'userAccount.add': 'Add account',
    'userAccount.addition': 'Top-up',
    'userAccount.addToBlacklist': 'Add to blacklist',
    'userAccount.amount': 'Amount',
    'userAccount.authCode': 'Authentication code',
    'userAccount.authCodeInfo': 'The code is generated by clicking "Create access code" in the "Authentication" column. This is what the client needs to enter to access the Internet.',
    'userAccount.balance': 'Balance',
    'userAccount.balanceInfo': 'Virtual account balance in case of paid access',
    'userAccount.birthday': 'Birthday',
    'userAccount.city': 'Current city',
    'userAccount.createAt': 'Date of creation',
    'userAccount.createdOn': 'Date',
    'userAccount.currency': 'Currency',
    'userAccount.dataFromSocials': 'User data from social networks',
    'userAccount.deleteAccessListCode': 'Delete access code',
    'userAccount.deleteFromBlacklist': 'Delete from blacklist',
    'userAccount.edit': 'Edit account',
    'userAccount.email': 'E-mail',
    'userAccount.fb': 'Facebook',
    'userAccount.firstName': 'First Name',
    'userAccount.gender.<nil>': '',
    'userAccount.gender.мужик': 'Male',
    'userAccount.gender.female': 'Female',
    'userAccount.gender.male': 'Male',
    'userAccount.gender.type': 'Gender',
    'userAccount.gender.unset': 'Unset',
    'userAccount.generateAccessListCode': 'Generate access code',
    'userAccount.getTransactions': "Show {num} user's transactions",
    'userAccount.goToLink': 'Go to {link}',
    'userAccount.history30d': 'Transactions history for the last 30 days',
    'userAccount.homeTown': 'City of birth',
    'userAccount.identityInfo': 'Phone, e-mail, MAC address - depending on the selected authentication type',
    'userAccount.instagram': 'Instagram',
    'userAccount.isUserAddedToBlacklist': 'Is user added to the blacklist?',
    'userAccount.isUserAddedToBlacklistNo': 'No',
    'userAccount.isUserAddedToBlacklistYes': 'Yes',
    'userAccount.macs': 'MAC',
    'userAccount.name': 'Name',
    'userAccount.nameFromSocial': 'Name from social network',
    'userAccount.new': 'New account',
    'userAccount.next': 'next',
    'userAccount.notificationUserAccountCreatedSuccessfully': 'Account successfully created',
    'userAccount.notificationUserAccountDeletedSuccessfully': 'Account successfully deleted',
    'userAccount.notificationUserAccountSuccess': 'Successfully',
    'userAccount.notificationUserAccountUpdatedSuccessfully': 'Account successfully updated',
    'userAccount.noTransactions': 'No top-ups or purchases for the last 30 days',
    'userAccount.notSubscribed': 'Not subscribed',
    'userAccount.of': 'of',
    'userAccount.prev': 'prev',
    'userAccount.profile': 'Profile',
    'userAccount.purchase': 'Purchase',
    'userAccount.rowsPerPage': 'Rows per page',
    'userAccount.socialLink': 'Social network link',
    'userAccount.socialLinks': 'Social networks',
    'userAccount.subscribed': 'Subscribed',
    'userAccount.subscribedFromDate': 'from',
    'userAccount.subscriptionName': 'Subscription name',
    'userAccount.subscriptionTableTitle': 'Subscription',
    'userAccount.surname': 'Last name',
    'userAccount.title': 'User accounts',
    'userAccount.transactions': 'Transactions history',
    'userAccount.transactionsInfo': "List of all user's payment transactions (debit and top-up)",
    'userAccount.type': 'Type',
    'userAccount.universities': 'Education',
    'userAccount.unset': 'unset',
    'userAccount.unsetForFilter': 'Unset',
    'userAccount.username': 'Username',
    'userAccount.visits': 'Connections',
    'userAccount.vk': 'VK',
    'users.addUser': 'Add user',
    'users.addUserWithDemoData': 'Add user with demo data',
    'users.bee_pro_bis_id': 'Business ID',
    'users.bee_pro_ctn': 'CTN',
    'users.blockedUsers': 'Blocked',
    'users.blockUserCaption': 'Block user',
    'users.company': 'Company',
    'users.copyFromLocation': 'copy from location',
    'users.createdBy': 'Created by',
    'users.createdByForUserEditPanel': 'Created by',
    'users.creationDate': 'Creation date',
    'users.deleteUserHeader': 'Delete user',
    'users.deleteUserCaption': 'Delete user',
    'users.description': 'Position',
    'users.editUserCaption': 'Edit user',
    'users.enterEmailPlaceHolder': 'Enter an e-mail',
    'users.enterForPasswordChangePlaceHolder': 'Enter for a change',
    'users.enterPasswordPlaceHolder': 'Enter password',
    'users.enterUsernamePlaceHolder': 'Enter username',
    'users.firstLoginForUserEditPanel': 'First login',
    'users.isBlocked': 'Blocked',
    'users.lastLogin': 'Last login',
    'users.lastLoginForUserEditPanel': 'Last login',
    'users.loginAsSubordinateForButton': 'Login',
    'users.logoutAsSubordinateForButton': 'Continue as',
    'users.mac_mask': 'Mask MAC addresses for this user',
    'users.newUsernameInfoMsg': 'This name will be used as a login when entering your personal account',
    'users.newUserPasswordValidationMessage': 'The password field may contain only the following special characters: _ - # $ & ^ * @ ! %',
    'users.noBlockedUsers': 'Not blocked',
    'users.noLogin': 'No login yet',
    'users.notificationUserBlockedSuccessfully': 'User successfully blocked',
    'users.notificationUserCreatedSuccessfully': 'User successfully created',
    'users.notificationUserDeletedSuccessfully': 'User successfully deleted',
    'users.notificationUserUnblockedSuccessfully': 'User successfully unblocked',
    'users.notificationUserUpdatedSuccessfully': 'User successfully updated',
    'users.password': 'Password',
    'users.phone': 'Phone',
    'users.Role': 'Role',
    'users.setActiveUntillForUser': "Set user's expiration date",
    'users.unblockUserCaption': 'Unblock user',
    'users.updUsernameInfoMsg': 'This name is used as a login when entering personal account',
    'users.userHasCTNButHasNOtBisIDTooltip': 'User has CTN but no BusinessID (or it is 0)',
    'users.username': 'Username',
    'users.users': 'Users',
    'users.usersList': "User's list",
    'users.wrongEmailMsg': 'Wrong e-mail',
    'usersDemoWizard.email': 'E-mail',
    'usersDemoWizard.headerCaption': 'Wizard for creation of user with demo data',
    'usersDemoWizard.step1Caption': 'Create a location',
    'usersDemoWizard.step1ErrorLocationWithThisNameAlredyExists': 'Location with this name already exists',
    'usersDemoWizard.step1Header': 'Step 1: Create a location',
    'usersDemoWizard.step2Caption': 'Create administrator for created location',
    'usersDemoWizard.step2ErrorUserWithThisNameAlredyExists': 'User with the same name already exists',
    'usersDemoWizard.step2Header': 'Step 2: Create anadministrator for location',
    'usersDemoWizard.step3Caption': 'Create WLAN',
    'usersDemoWizard.step3Header': 'Step 3: Create a WLAN',
    'usersDemoWizard.step3SuiteRequiredsError': 'The "Encryption" field is required',
    'usersDemoWizard.step4Caption': 'Create a profile',
    'usersDemoWizard.step4FieldProfileName': 'Profile name',
    'usersDemoWizard.step4Header': 'Step 4: Create a profile',
    'usersDemoWizard.step5Caption': 'Create a page',
    'usersDemoWizard.step5FieldPageName': 'Page name',
    'usersDemoWizard.step5Header': 'Step 5: Create a page',
    'vouchers.account': 'Account',
    'vouchers.add': 'Add a voucher',
    'vouchers.allAccounts': 'All accounts',
    'vouchers.backToVouchers': 'Back to vouchers',
    'vouchers.code': 'Code',
    'vouchers.codeLength': 'Code length',
    'vouchers.createHotelVouchers': 'Create hotel vouchers',
    'vouchers.createVouchers': 'Create vouchers',
    'vouchers.download': 'Download for print',
    'vouchers.duration': 'Duration',
    'vouchers.edit': 'Edit voucher',
    'vouchers.editHotel': 'Edit hotel voucher',
    'vouchers.expire_at': 'Expiration time',
    'vouchers.new': 'New voucher',
    'vouchers.newHotelVouchers': 'New hotel vouchers',
    'vouchers.newVouchers': 'New vouchers',
    'vouchers.noAccountSelectedInModalErr': 'Account field is required',
    'vouchers.notificationHotelVoucherCreatedSuccessfully': 'Hotel voucher successfully created',
    'vouchers.notificationHotelVoucherDeletedSuccessfully': 'Hotel voucher successfully deleted',
    'vouchers.notificationHotelVoucherUpdatedSuccessfully': 'Hotel voucher successfully updated',
    'vouchers.notificationVoucherCreatedSuccessfully': 'Voucher successfully created',
    'vouchers.notificationVoucherDeletedSuccessfully': 'Voucher successfully deleted',
    'vouchers.notificationVoucherUpdatedSuccessfully': 'Voucher successfully updated',
    'vouchers.number_of_vouchers': 'Number of vauchers',
    'vouchers.profile': 'Profile',
    'vouchers.selectAccount': 'Select an account',
    'vouchers.session': 'Internet access time after activation',
    'vouchers.tariff': 'Tariff',
    'vouchers.title': 'Vouchers',
    'vouchers.titleHotel': 'Hotel vouchers',
    'vouchers.used': 'Used',
    'vouchers.validFor': 'Valid for {day} day(s)',
    'vouchers.validForShort': 'Valid for',
    'wifiAnalyzer.channel': 'Channel',
    'wifiAnalyzer.quality': 'Quality',
    'wifiAnalyzer.signal': 'Signal',
    'wifiAnalyzer.wlanChars': 'Characteristics of WLANs',
    'wlans.nasIp': 'NAS IP address',
    'wlans.nasIpPlaceholder': 'NAS IP address in X.X.X.X format',
    'wlans.CentralAuth': 'Central Auth',
    'wlans.AAATabCaption': 'AAA',
    'wlans.AAAOverride': 'AAA Override',
    'wlans.DynamicVLAN': 'Dynamic VLAN',
    'wlans.DynamicVLANPlaceholder': 'Enter VLAN values',
    'wlans.accessControl': 'Guest control',
    'wlans.addRADIUS': 'Add RADIUS',
    'wlans.addRedirectRule': 'Add Redirect',
    'wlans.addTag': 'Add tag',
    'wlans.addWLAN': 'Add WLAN',
    'wlans.addWLANWizard': 'Wizard for WLAN creation',
    'wlans.advancedSettings': 'Advanced WLAN parameters',
    'wlans.bandSteering': 'Enable band steering',
    'wlans.bandSteeringForBadge': 'Band steering',
    'wlans.bandSteeringForLastStepWlanWizard': 'Band steering',
    'wlans.basicParameters': 'Basic WLAN parameters',
    'wlans.blackList': 'Blacklist',
    'wlans.bridge': 'For Bridge interfaces VLAN is ignored',
    'wlans.bss_transitioForBadge': 'BSS Transition',
    'wlans.bss_transition': 'BSS Transition',
    'wlans.bss_transitionForBadge': 'BSS Transition',
    'wlans.centralized': 'Centralized',
    'wlans.centralSwitching': 'Central switching',
    'wlans.confirmDeleting': 'Confirm deletion',
    'wlans.confirmWlans': 'Confirm new WLAN creation',
    'wlans.currentBlacklistItems': 'Current blacklist items',
    'wlans.currentWhitelistItems': 'Current whitelist items',
    'wlans.dataTrafficManagement': 'Data traffic management',
    'wlans.deleteMulti': 'Delete the WLANs',
    'wlans.deleteTagsModalText': 'Confirm deleting all tags for selected WLANs',
    'wlans.deleteTagsModalTitile': 'Delete common tags',
    'wlans.deleteWLAN': 'Delete a WLAN',
    'wlans.deleteWLANs': 'Delete WLANs',
    'wlans.descriptionPlaceholder': 'Enter WLAN description',
    'wlans.dhcpOption82': 'DHCP Option 82 (without Relay)',
    'wlans.dhcpOption82CircuitId': 'Option 82 Circuit ID',
    'wlans.dhcpOption82CircuitIdExample': 'WLAN:wlan0:3690:SSID_Name:BC:E6:7C:4D:EE:00:XV3-8-4DDA3C:BC:E6:7C:4D:DA:3C:',
    'wlans.dhcpOption82CircuitIdExampleCaption': 'Example',
    'wlans.dhcpOption82CircuitIdFormat': 'WLAN:IFNAME:VLAN:SSID:BSSID:HOSTNAME:AP-MAC:SITE-ID',
    'wlans.dhcpOption82CircuitIdFormatCaption': 'Input format',
    'wlans.dhcpOption82Enable': 'Enable DHCP Option 82 (without Relay)',
    'wlans.dhcpOption82ForBadge': 'option 82',
    'wlans.dhcpOption82InfoHint': 'format “AP_MAC:SSID“',
    'wlans.disabledForBadge': 'off',
    'wlans.download': 'Download',
    'wlans.editRADIUS': 'Edit RADIUS',
    'wlans.enabledForBadge': 'on',
    'wlans.enterKbitPlaceholder': 'Enter kbit',
    'wlans.enterNASIdentifierPlaceholder': 'Enter NAS identifier',
    'wlans.enterNASPortIdPlaceholder': 'Enter NAS port ID',
    'wlans.enterRSNAPlaceholder': 'Enter RSNA',
    'wlans.enterVLANPlaceHolder': 'Enter VLAN',
    'wlans.enterWlanPasswordPlaceholder': 'Enter WLAN password',
    'wlans.fastTransition': 'Fast roaming (802.11r)',
    'wlans.localPMKGeneration': 'Local PMK generation',
    'wlans.localPMKGenerationOnlyForWP2PSK': 'For WPA2 Personal only',
    'wlans.experimentalFTImprovements': 'Experimental FT improvements',
    'wlans.RRBTunneling': 'RRB Tunneling',
    'wlans.RRBSeqcheck': 'RRB Seq check',
    'wlans.directRRBExchange': 'Direct RRB exchange',
    'wlans.directRRBExchangeHint': 'Allows you to speed up the transition of clients between specified APs (provided that the original roaming AP is in this list)',
    'wlans.selectApsForDirectRRBExchange': 'Select APs',
    'wlans.selectApsForDirectRRBExchangeHint': 'You can select up to 20',
    'wlans.filter': 'Filter',
    'wlans.filterBlacklistMode': 'Blacklist mode',
    'wlans.filterBlacklistModeCaption': 'Everyone except the chosen clients can connect to the WLAN',
    'wlans.filterMode': 'Filter mode',
    'wlans.filterNoneModeCaption': 'All clients can connect to the WLAN',
    'wlans.filterWhitelistMode': 'Whitelist mode',
    'wlans.filterWhitelistModeCaption': 'Only the chosen clients can connect to the WLAN',
    'wlans.firewall': 'Firewall',
    'wlans.goBackToClients': 'Back to clients',
    'wlans.goBackToWlanStatistic': 'Back to WLANs statistic',
    'wlans.hidden': 'Hidden',
    'wlans.hotspotProfileNone': 'None',
    'wlans.hotspotProfileTitle': 'Hotspot 2.0 profile',
    'wlans.ieee80211k': '802.11k',
    'wlans.ieee80211kForBadge': '802.11k',
    'wlans.ieee80211v': '802.11v',
    'wlans.ieee80211vForBadge': '802.11v',
    'wlans.interface': 'Interface',
    'wlans.itemsOf': 'items of',
    'wlans.L2isolation': 'L2 isolation',
    'wlans.L2TabCaption': 'Layer 2',
    'wlans.L3TabCaption': 'Layer 3',
    'wlans.load': 'Load',
    'wlans.loadBalancing': 'Load balancing',
    'wlans.loadBalancingInfoMsg': 'Works correctly only with 802.11v',
    'wlans.localSwitching': 'Local switching',
    'wlans.MACRADIUSAuthentication': 'MAC RADIUS Authentication',
    'wlans.mirroring': 'Mirroring',
    'wlans.mirroringHint': 'Duplicated sending for multiple RADIUS servers',
    'wlans.mobility': 'Mobility settings',
    'wlans.nas_generateCaption': 'Generate NAS ID',
    'wlans.nas_generateInfo': 'Enables better work quality for 802.11r roaming',
    'wlans.NASidentifier': 'NAS identifier',
    'wlans.NASportid': 'NAS port id',
    'wlans.natDisabledForTable': 'disabled',
    'wlans.natEnabledForTable': 'enabled',
    'wlans.natNetworkInfoMsg': "Don't use 10.9.0.1/16 for IPSec APs",
    'wlans.natNetworkIpaddr': 'IP for NAT network',
    'wlans.natNetworkIpaddrPlaceholder': 'Enter IP for NAT network',
    'wlans.natNetworkNetmask': 'Mask for the NAT network',
    'wlans.natNetworkNetmaskPlaceholder': 'Enter the mask for the NAT network',
    'wlans.networkEncapsulation': 'Network encapsulation',
    'wlans.noCurrentCPEs': 'No current APs',
    'wlans.noInterfaces': 'No interfaces',
    'wlans.none': 'None',
    'wlans.notAvailableForCisco': 'Not available for Cisco',
    'wlans.notForOpen': 'Not for an open WLAN',
    'wlans.notificationWLANCreatedSuccessfully': 'WLAN successfully created',
    'wlans.notificationWLANDeletedSuccessfully': 'WLAN successfully deleted',
    'wlans.notificationWLANOperationSuccessfull': 'Successfully',
    'wlans.notificationWLANUpdatedSuccessfully': 'WLAN successfully updated',
    'wlans.onlyForWPAEnterprise': 'Only for WPA Enterprise',
    'wlans.optionalParameters': 'Fill optional WLAN parameters, you can skip and do it later',
    'wlans.optionalStep': 'You can skip and do it later',
    'wlans.overTheDS': 'Over the DS',
    'wlans.peerAddress': 'EoGRE server',
    'wlans.peerAddressPlaceholder': 'Enter the EoGRE server IP',
    'wlans.PMKcaching': 'PMK caching',
    'wlans.ppsk': 'iPSK',
    'wlans.proxyARP': 'Proxy ARP',
    'wlans.proxyARPHint': 'The ARP Proxy option allows an access point or controller to respond to ARP requests on behalf of clients. This reduces the number of broadcast messages in the network, saving resources and enhancing Wi-Fi performance. Enable this feature to decrease the load on wireless clients and increase network throughput',
    'wlans.proto': 'Type',
    'wlans.qrForWLANConnet': 'QR code for connection to a WLAN',
    'wlans.qrSaveQRCode': 'Save QR',
    'wlans.radiusAccounting': 'RADIUS accounting server',
    'wlans.radiusAccountingForWLANModalAAA': 'RADIUS accounting server',
    'wlans.radiusAccountingInterval': 'Radius Accounting interval',
    'wlans.radiusAdditionalSettings': 'RADIUS advanced settings',
    'wlans.redirect': 'Redirect',
    'wlans.rrm_beacon_report': 'Beacon report',
    'wlans.rrm_beacon_reportForBadge': 'Beacon report',
    'wlans.rrm_neighbor_report': 'Neighborhood report',
    'wlans.rrm_neighbor_reportForBadge': 'Neighborhood report',
    'wlans.rsnaCapture': 'If left blank, it will be filled depending on Security (OPEN / WPA / RSNA)',
    'wlans.rsnaSettings': 'RSNA Settings',
    'wlans.rsnaSettingsV2': 'Change Calling Station ID',
    'wlans.rssiThreshold': 'RSSI threshold',
    'wlans.rssiThresholdHint': 'Allowed client signal level when connecting (from -90 to -60 dbm)',
    'wlans.rssiThresholdHintForInfo': 'Allowed client signal level when connecting',
    'wlans.rssiThresholdHintRange': 'from -90 to -60 dbm, 0 to disable',
    'wlans.rssiThresholdPlaceholder': 'from -90 to -60 dbm, 0 to disable',
    'wlans.security': 'Security',
    'wlans.securityOpen': 'Open',
    'wlans.securityParameters': 'Choose your new WLAN security profile',
    'wlans.securitySettingsCaption': 'Security settings',
    'wlans.selected': 'selected',
    'wlans.selectInterface': 'Select interface',
    'wlans.selectRADIUSPlaceholder': 'Select a RADIUS',
    'wlans.selectSingleInterface': 'Select Interface',
    'wlans.selectSingleTunnel': 'Select a tunnel',
    'wlans.selectSingleWlan': 'Select a WLAN',
    'wlans.signalBlockCaption': 'Settings for the RSSI threshold, when reduced, the user is disconnected from the WLAN network with the specified code',
    'wlans.signalDropReason': 'Drop reason code',
    'wlans.signalDropReasonForBadge': 'Drop reason code',
    'wlans.signalDropReasonForInfo': 'Drop reason code sent to the client',
    'wlans.signalDropReasonHint': '3 by default',
    'wlans.signalDropReasonMoreInfo': 'More info about codes',
    'wlans.signalDropReasonMoreInfoForModal': 'You can read more about codes here',
    'wlans.signalDropReasonPlaceholder': 'Drop reason code, 3 by default',
    'wlans.signalPollTime': 'Poll time',
    'wlans.signalPollTimeForBadge': 'Poll time',
    'wlans.signalPollTimeForBadgeSec': 'sec',
    'wlans.signalPollTimeForInfo': 'Poll time, 5 sec. by default',
    'wlans.signalPollTimeHint': '5 sec. by default',
    'wlans.signalPollTimePlaceholder': 'Poll time, 5 sec. by default',
    'wlans.signalStay': 'Minimum RSSI threshold',
    'wlans.signalStayForBadge': 'Minimum RSSI threshold',
    'wlans.signalStayForInfo': 'For connected clients, when detecting a decrease in the signal power less than the specified one, within a specified number of times, they are disconnected',
    'wlans.signalStayHint': 'from -100 to -40 dbm, 0 to disable',
    'wlans.signalStayPlaceholder': 'from -100 to -40 dbm, 0 to disable',
    'wlans.signalStrikes': 'Number of checks before disconnect',
    'wlans.signalStrikesForBadge': 'Number of checks',
    'wlans.signalStrikesForInfo': 'Number of signal power level checks before disconnecting the client',
    'wlans.signalStrikesForLastWizardStep': 'Number of checks',
    'wlans.signalStrikesHint': '3 by default',
    'wlans.signalStrikesPlaceholder': 'Number of checks, 3 by default',
    'wlans.speedControl': 'Speed control',
    'wlans.speedDownload': 'Download speed',
    'wlans.speedDownloadKbs': 'Kb\\s',
    'wlans.speedDownloadPlaceholder': 'Enter a value between 8 and 10000000',
    'wlans.speedUpload': 'Upload speed',
    'wlans.speedUploadKbs': 'Kb\\s',
    'wlans.speedUploadPlaceholder': 'Enter a value between 8 and 10000000',
    'wlans.SSHWebAccess': 'SSH / Web access',
    'wlans.ssidPlaceholder': 'Enter SSID',
    'wlans.status': 'Status',
    'wlans.statusActive': 'active',
    'wlans.statusEmpty': 'empty',
    'wlans.statusNotActive': 'not active',
    'wlans.statusPending': 'pending',
    'wlans.suites': 'Encryption',
    'wlans.suitesPlaceholder': 'Encryption',
    'wlans.tabAdvanced': 'Advanced',
    'wlans.tabGeneral': 'General',
    'wlans.tableNone': 'none',
    'wlans.tableTab_clients': 'Clients',
    'wlans.tableTab_cpes': 'APs',
    'wlans.tableTab_dataTrafficManagement': 'Data traffic management ',
    'wlans.tableTab_encapsulation': 'Encapsulation',
    'wlans.tableTab_filter': 'Filter',
    'wlans.tableTab_hasPortal': 'Portal',
    'wlans.tableTab_location': 'Location',
    'wlans.tableTab_name': 'Name',
    'wlans.tableTab_security': 'Security',
    'wlans.tableTab_ssid': 'SSID',
    'wlans.tableTab_status': 'Status',
    'wlans.tableTab_tags': 'Tags',
    'wlans.tableTab_visibility': 'Visibility',
    'wlans.tableTab_vlan': 'VLAN',
    'wlans.tableTab_wlans': 'WLANs',
    'wlans.tabSecurity': 'Security',
    'wlans.tunnel': 'Tunnel',
    'wlans.tunneling': 'Tunneling',
    'wlans.tunnelingShort': 'Tunneling',
    'wlans.tunnelTypeForTable': 'Tunnel',
    'wlans.UAPSD': 'UAPSD',
    'wlans.unlim': 'unlim',
    'wlans.upload': 'Upload',
    'wlans.view': 'view',
    'wlans.visibility': 'Visibility',
    'wlans.visibilityHidden': 'Hidden',
    'wlans.visibilityVisible': 'Visible',
    'wlans.visible': 'Visible',
    'wlans.vlanNative': 'Native',
    'wlans.warnService': 'Please note that an active WLAN is selected. Its deletion will result in {0}.',
    'wlans.warnServiceLoss': 'loss of a service',
    'wlans.warnStatistics': 'Please note that deletion of WLAN will result in {0}.',
    'wlans.warnStatisticsLoss': 'loss of statistics',
    'wlans.whiteList': 'Whitelist',
    'wlans.wizardAdditionalSettings': 'Advanced settings',
    'wlans.wizardBlackWhiteListItem': 'items',
    'wlans.wizardGuestControl': 'Guest control',
    'wlans.wizardGuestControlStepCaption': 'Guest control settings',
    'wlans.wizardMobility': 'Mobility settings',
    'wlans.wizardMobilityStepCaption': 'Mobility settings',
    'wlans.wizardNetworkEncapsulationStepCaption': 'Network encapsulation settings',
    'wlans.wizardRADIUSAccounting': 'RADIUS accounting server',
    'wlans.wizardRADIUSAccountingStepCaption': 'RADIUS accounting settings',
    'wlans.wizardRADIUSAdditionalSettings': 'RADIUS additional settings',
    'wlans.wizardRADIUSAdditionalSettingsStepCaption': 'RADIUS additional settings',
    'wlans.wizardSecuritySettings': 'Security settings',
    'wlans.wizardSkipButton': 'Skip next steps',
    'wlans.wizardSkipButtonInfo': 'Skip all subsequent steps and move to the last step. The settings specified in the current step will be applied.',
    'wlans.wizardSpeedControlSettings': 'Speed control',
    'wlans.wizardSpeedControlSettingsStepCaption': 'Speed control settings',
    'wlans.wizardVisibility': 'Visibility',
    'wlans.wizardWMM': 'WMM',
    'wlans.wizardWMMStepCaption': 'WMM settings',
    'wlans.wlanCreate': 'Create WLAN',
    'wlans.wlanEdit': 'Edit WLAN',
    'wlans.wlans': 'WLANs',
    'wlans.wlansList': 'WLANs list',
    'wlans.WLANsOufOf': 'WLANs ouf of',
    'wlans.wlanWizard': 'Wizard for WLAN creation',
    'wlans.wlanWizardV2': 'Create WLAN',
    'wlans.WMM': 'WMM',
    'wlans.wnm_sleep_mode': 'Sleep mode ability',
    'wlans.wnm_sleep_modeForBadge': 'Sleep mode ability',
    'wlans.wnm_sleep_modeForWizardLastStep': 'Sleep mode',
    'wlans.wnm_sleep_modeInfo': 'Enables sleep mode ability for power saving',
    'wlans.wpa3IsNotSupportedOnAllCPE': 'WPA3 is not supported on all APs',
    'wmm.Background': 'Background (BK)',
    'wmm.BestEffort': 'BestEffort  (BE) ',
    'wmm.configPlaceHolder': 'Enter to change the default value',
    'wmm.custom': 'enabled',
    'wmm.default': 'by default',
    'wmm.infoTooltip': 'click to view details about WMM configuration',
    'wmm.modalInfomoreInfo': 'More information',
    'wmm.modalInfoTitle': 'About WMM configuration',
    'wmm.Video': 'Video (VI)',
    'wmm.Voice': 'Voice (VO) ',
    'wmm.error.AcCwFiled': 'You must enter one of the following values: 1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767',
    'wmm.hint.cli_cw_min': 'From 0 to 15. The value must not be greater than in the Client cwMax field. Required if Client cwMax is filled',
    'wmm.hint.cli_cw_max': 'From 0 to 15. The value must be no less than in the Client cwMin field. Required if Client cwMin is filled',
    'wmm.hint.cli_aifs': 'The value must be greater than or equal to 0',
    'wmm.hint.cli_txop': 'The value must be greater than or equal to 0',
    'wmm.hint.ac_cw_min': '1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767. The value must be no more than in the AC cwMax field. Required if AC cwMin is filled',
    'wmm.hint.ac_cw_max': '1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767. The value must be no less than the AC cwMin field. Required if AC cwMax is filled',
    'wmm.hint.ac_aifs': 'The value must be greater than or equal to 0',
    'wmm.hint.ac_burst': 'The value must be greater than or equal to 0, with one decimal place',
    'vlan.errors.dynamicVLANCommonValidationError': 'Invalid input format. Acceptable format is numbers from {min} to {max}. It is possible to enter multiple values ​​separated by commas without repetitions (for example 2,6,3,10). Or a range of values ​​using a dash (for example 2-8)',
    'vlan.errors.dynamicVLANNumberNotInRangeValidationError': 'Valid format is numbers from {min} to {max}',
    'vlan.errors.dynamicVLANNumberRepeatedValidationError': 'Repeated numbers are not allowed',
    'vlan.errors.dynamicVLANFirstNumberInRangeGreaterThenLastValidationError': 'The first number in the range must be less than the last',
    'validator.message.wrongIpv4Format': 'Wrong IPv4 format',
    'validation.wlanNasIp': 'Wrong IPv4 format',
    'validation.ipv4WithSpecialOctetsRanges': 'Wrong IPv4 format',
    'validation.subnetMask': 'Wrong subnet mask. Enter the subnet mask in the format X.X.X.X',
    'validation.macPrefix': 'Wrong MAC prefix. Enter the MAC prefix in the format XX:XX:XX:XX:XX:XX',
    'validation.ValidCharacters': 'May contain only the following character: letters in upper and lower case, digits, special character: _ - # $ & ^ * @ !',
    'validation.usernameValidCharacters': 'May contain only the following character: letters in upper and lower case, digits, special character: _ - .',
    'validation.uuid': 'The UUID does not match requirements',
    'validation.location': 'The location name should start with a letter or a number and may contain letters of the Latin alphabet and the following special characters: _ -',
    'validation.wrongmacaddr': 'Wrong MAC address',
    'validation.ipv4ordomain': 'Wrong IP address or Domain Name',
    'validation.hexcolor': 'Wrong HEX color',
    'validation.ipv4mask': 'Wrong IP address',
    'validation.wrongurl': 'Wrong URL address',
    'validation.wrongl2port': 'Wrong Port address',
    'validation.ssidsize': 'The SSID name may not be greater than 32 bytes',
    'validation.hexhs': 'Wrong OI. Each OI is between 3 and 15 octets and is configured as a hexstring',
    'validation.customUserPassword': 'The password field may contain only the following special character: _ - # $ & ^ * @ !',
    'validation.customCheckLength': 'The password length must be between 8 and 64 characters.',
    'validation.customCheckLengthMin': 'Password length must be at least 8 characters',
    'validation.customCheckLengthMinWLC': 'Password length must be at least 6 characters',
    'validation.customCheckLengthMax': 'Password length must be no more than 64 characters',
    'validation.upperCaseLetter': 'Must have at least one uppercase letter',
    'validation.lowerCaseLetter': 'Must have at least one lowercase letter',
    'validation.digitContained': 'Must have at least one digit',
    'validation.wrongMaskNAT': 'Wrong mask for the NAT network',
    'validation.ctn': 'CTN value must be a phone number (10 digits in the format 9XXXXXXXXX)',
    'validation.signalRSSI': 'The Minimum RSSI Threshold value must be in the range of -100 to -40 dBm (or 0 to disable)',
    'validation.signalStrikes': 'The value of the Number of checks must be greater than 0',
    'validation.signalPoll': 'The value of the Poll Time must be greater than 0',
    'validation.signalReason': 'The Drop Reason code must be greater or equal 0',
    'validation.signalThreshold': 'The RSSI threshold value must be in the range of -90 to -60 dBm (or 0 to disable)',
    'validation.timehhmm': 'Not valid time format. Enter the time in HH:MM format',
    'validation.wrongFTP': 'Wrong FTP Server. Enter the FTP server address in the ip: port format (for example 127.0.0.1:21)',
    'validation.multiInterface': 'The Interface field is required',
    'validation.wrongIPorDomain': 'Wrong IP address or domain',
    'validation.wrongIPorMAC': 'Wrong IP address or MAC',
    'validation.ipOrMacList': 'Wrong IP address or MAC. Or more than 1 line',
    'validation.ipOrMacFilter': 'More than 3 filters entered per line'
  },
  ru: {
    'accessControl.accessControl': 'Настройки доступа',
    'accessControl.firewall': 'Брандмауэр',
    'accessControl.wanBlockAccess': 'Запрет доступа через WAN',
    'account.addEmail': 'добавить электронную почту',
    'account.attention': 'Внимание!',
    'account.attentionMessage': 'Все пользовательские сеансы этого клиента будут отключены. Подтвердите смену пароля?',
    'account.change': 'Изменить',
    'account.changePassword': 'Сменить пароль',
    'account.changePasswordButton': 'Сменить пароль',
    'account.confirm': 'Подтвердить',
    'account.confirmNewPassword': 'Подтвердите новый пароль',
    'account.confirmNewPasswordPlaceholder': 'Повторно введите новый пароль',
    'account.enterEmailPlaceholder': 'Введите адрес электронной почты',
    'account.enterNewPasswordPlaceholder': 'Введите новый пароль',
    'account.enterOldPasswordPlaceholder': 'Введите старый пароль',
    'account.newPassword': 'Новый пароль',
    'account.newPhoto': 'Новое фото',
    'account.photo': 'Фото',
    'account.oldPassword': 'Старый пароль',
    'account.save': 'Сохранить',
    'apjoin.pageName': 'Профиль ТД',
    'apjoin.addAPJoinProfile': 'Добавить профиль ТД',
    'apjoin.changeAPJoinProfile': 'Редактировать профиль ТД',
    'apjoin.tabLabel.general': 'Основные',
    'apjoin.tabLabel.management': 'Управление',
    'apjoin.tabLabel.statistics': 'Статистика',
    'apjoin.tabLabel.syslog': 'Логирование',
    'apjoin.tabLabel.timers': 'Таймеры',
    'apjoin.tabLabel.qualityOfService': 'Качество обслуживания',
    'apjoin.inputLabel.profileName': 'Название профиля',
    'apjoin.inputLabel.profileDescription': 'Описание профиля',
    'apjoin.inputLabel.ledState': 'Индикаторы LED',
    'apjoin.inputLabel.mssSizeOfWanInterface': 'Размер MSS на WAN интерфейсе',
    'apjoin.inputLabel.mssState': 'Настройка MSS',
    'apjoin.inputLabel.lldpCdpSupport': 'Поддержка LLDP\\CDP',
    'apjoin.inputLabel.ipAdressOfNTPServer': 'IP адрес NTP сервера',
    'apjoin.inputLabel.timezone': 'Временная зона',
    'apjoin.inputLabel.timezone.selectOption.notConfigured': 'не настроено',
    'apjoin.inputLabel.timezone.selectOption.wlc': 'контроллер',
    'apjoin.inputLabel.timezone.selectOption.delta': 'дельта от контроллера',
    'apjoin.inputLabel.offsetHH': 'Смещение (ЧЧ)',
    'apjoin.inputLabel.offsetMM': 'Смещение (ММ)',
    'apjoin.inputLabel.primaryController': 'Основной контроллер',
    'apjoin.inputLabel.secondaryController': 'Резервный контроллер',
    'apjoin.inputLabel.tertiaryController': 'Третичный контроллер',
    'apjoin.inputLabel.sshSupport': 'Поддержка SSH',
    'apjoin.inputLabel.sshPort': 'Порт SSH',
    'apjoin.inputLabel.username': 'Имя пользователя',
    'apjoin.inputLabel.password': 'Пароль',
    'apjoin.inputError.uniqueProfileName': 'Имя профиля не уникально',
    'apjoin.inputError.profileNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'apjoin.inputError.profileDescriptionMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце',
    'apjoin.inputError.managementUsernameMask': 'Неверный формат имени пользователя',
    'apjoin.inputError.managementPasswordMask': 'Неверный формат пароля',
    'apjoin.inputError.sshPortMask': 'Поле Порт SSH должно быть 22 или между 1024 и 49151',
    'apjoin.inputLabel.secretPassword': 'Пароль для перехода в привилегированный режим',
    'apjoin.tabText.sshLabel': 'Настройки для подключения к точке доступа',
    'apjoin.tabText.warningCaption': 'При отключении поддержки SSH и сохранения данной настройки, существующий пользователь будет удалён',
    'apjoin.tabText.passwordRulesDescription.1': 'Пароль может содержать только английские буквы и цифры',
    'apjoin.tabText.passwordRulesDescription.2': 'Длина пароля от 8 до 120 символов',
    'apjoin.tabText.passwordRulesDescription.3': 'По крайней мере один символ в пароле должен быть в верхнем регистре',
    'apjoin.tabText.passwordRulesDescription.4': 'По крайней мере один символ в пароле должен быть в нижнем регистре',
    'apjoin.tabText.passwordRulesDescription.5': 'По крайней мере один символ в пароле должен быть цифрой',
    'apjoin.tabText.retransmitTimersLabel': 'Таймеры повторной передачи',
    'apjoin.inputLabel.sendApStatistic': 'Отправка статистики по точке доступа',
    'apjoin.inputLabel.apStatiscticReportPeriod': 'Период отправки статистики по точке доступа (сек)',
    'apjoin.inputLabel.facilityValue': 'Значение Facility',
    'apjoin.inputLabel.facilityValue.selectOption.none': 'нет',
    'apjoin.inputLabel.facilityValue.selectOption.hostapd': 'hostapd',
    'apjoin.inputLabel.facilityValue.selectOption.kern': 'kern',
    'apjoin.inputLabel.facilityValue.selectOption.logread': 'logread',
    'apjoin.inputLabel.facilityValue.selectOption.cron': 'cron',
    'apjoin.inputLabel.facilityValue.selectOption.authpriv': 'authpriv',
    'apjoin.inputLabel.facilityValue.selectOption.ntp': 'ntp',
    'apjoin.inputLabel.facilityValue.selectOption.cpeagent': 'cpeagent',
    'apjoin.inputLabel.facilityValue.selectOption.daemon': 'daemon',
    'apjoin.inputLabel.facilityValue.selectOption.dhcpoptinj': 'dhcpoptinj',
    'apjoin.inputLabel.ipAdressOfRemoteServerForSyslogMessages': 'IP адрес удаленного сервера для приёма системных сообщений',
    'apjoin.inputLabel.logLevel': 'Уровень сообщений',
    'apjoin.inputLabel.logLevel.selectOption.notice': 'Notice',
    'apjoin.inputLabel.logLevel.selectOption.informational': 'Informational',
    'apjoin.inputLabel.facilityValue.selectOption.debug': 'Debug',
    'apjoin.inputLabel.heartbeatTimeout': 'Heartbeat интервал (сек)',
    'apjoin.inputLabel.configurationRetransmissions': 'Количество повторных передач конфигурации',
    'apjoin.inputLabel.acceptanceInterval': 'Время ожидания подтверждения применения конфигурации (сек)',
    'apjoin.inputLabel.trustDscpUpstream': 'Доверять метке DSCP',
    'apjoin.tableHeading.id': 'Идентификатор профиля',
    'apjoin.tableHeading.name': 'Название профиля ТД',
    'apjoin.tableHeading.description': 'Описание профиля',
    'apjoin.responseMessage.createAPsProfile.success': 'Профиль ТД был успешно добавлен',
    'apjoin.responseMessage.updateAPsProfile.success': 'Профиль ТД был успешно обновлён',
    'apjoin.responseMessage.updateAPsProfile.error': 'Профиль ТД не был обновлён',
    'apjoin.responseMessage.deleteAPsProfile.success': 'Профиль ТД был успешно удалён',
    'apjoin.responseMessage.deleteAPsProfile.error': 'Профиль ТД не был удалён',
    'wlan.addWlanProfile': 'Добавить профиль БЛВС',
    'wlan.editWlanProfile': 'Редактировать профиль БЛВС',
    'wlan.cloneWlanProfile': 'Клонировать профиль БЛВС {name}',
    'wlan.tabLabel.general': 'Основные',
    'wlan.tabLabel.security': 'Безопасность',
    'wlan.tabLabel.advanced': 'Дополнительно',
    'wlan.tabLabel.layer2': 'Уровень 2',
    'wlan.tabLabel.layer3': 'Уровень 3',
    'wlan.tabLabel.aaa': 'AAA',
    'wlan.tabSectionLabel.securityType': 'Тип безопасности',
    'wlan.tabSectionLabel.encryptionType': 'Шифрование',
    'wlan.tabSectionLabel.80211r': 'Настройка 802.11r',
    'wlan.tabSectionLabel.80211w': 'Настройка 802.11w',
    'wlan.tabSectionLabel.authenticationKeyManagement': 'Управление ключами аутентификации',
    'wlanPolicy.tabSectionLabel.nasIdSettings': 'Настройка NAS ID',
    'wlan.tabSectionLabel.maxClientsConnections': 'Максимальное количество клиентских подключений',
    'wlan.tabSectionLabel.dtimPeriod': 'Интервал DTIM (измеряется в beacon)',
    'wlan.tabSectionLabel.80211k': '802.11k',
    'wlan.tabSectionLabel.80211ax': '802.11ax',
    'wlan.tabSectionLabel.80211v': '802.11v',
    'wlan.pageName': 'БЛВС',
    'wlan.inputLabel.name': 'Название профиля',
    'wlan.inputLabel.wlan_id': 'Идентификатор БЛВС',
    'wlan.inputLabel.ssid': 'Название БЛВС',
    'wlan.inputLabel.broadcast_ssid_enabled': 'Видимый SSID',
    'wlan.inputLabel.status_24ghz_enabled': 'Статус 2.4 ГГц',
    'wlan.inputLabel.status_5ghz_enabled': 'Статус 5 ГГц',
    'wlan.inputLabel.status_6ghz_enabled': 'Статус 6 ГГц',
    'wlan.inputLabel.status_ssid_enabled': 'Статус БЛВС',
    'wlan.inputLabel.security_type.open': 'Open',
    'wlan.inputLabel.security_type.wpa2': 'WPA2',
    'wlan.inputLabel.security_type.wpa2+wpa3': 'WPA2+WPA3',
    'wlan.inputLabel.security_type.wpa3': 'WPA3',
    'wlan.inputLabel.encryption_type.ccmp': 'CCMP',
    'wlan.inputLabel.encryption_type.ccmp256': 'CCMP-256',
    'wlan.inputLabel.encryption_type.gcmp256': 'GCMP-256',
    'wlan.inputLabel.isMabEnabled': 'MAB',
    'wlan.inputLabel.mab_radius_profile': 'Radius профиль',
    'wlan.inputLabel.mab_radius_profile.selectOption.notConfigured': 'Нет',
    'wlan.inputLabel.fast_roaming_enabled': 'Быстрый роуминг 802.11r',
    'wlan.inputLabel.ft_over_ds_enabled': 'Режим FT over DS',
    'wlan.inputLabel.param_80211r_reassociation_timeout': 'Время ожидания reassociation requests (сек)',
    'wlan.inputLabel.support_pmf': 'Поддержка PMF',
    'wlan.inputLabel.support_pmf.selectOption.disabled': 'выключено',
    'wlan.inputLabel.support_pmf.selectOption.optional': 'опционально',
    'wlan.inputLabel.support_pmf.selectOption.mandatory': 'требуется',
    'wlan.inputLabel.param_80211w_query_attempts': 'Количество попыток SA query',
    'wlan.inputLabel.param_80211w_retry_timeout': 'Интервал (мсек) между двумя SA Query запросами',
    'wlan.inputLabel.psk_enabled': 'PSK',
    'wlan.inputLabel.psk_256_enabled': 'PSK-SHA256',
    'wlan.inputLabel.param_8021x_enabled': '802.1x',
    'wlan.inputLabel.param_8021x_256_enabled': '802.1x-SHA256',
    'wlan.inputLabel.sae_enabled': 'SAE',
    'wlan.inputLabel.ft_sae_enabled': 'FT + SAE',
    'wlan.inputLabel.ft_8021x_enabled': 'FT + 802.1x',
    'wlan.inputLabel.ft_psk_enabled': 'FT + PSK',
    'wlan.inputLabel.suite_b_192_enabled': 'SUITE-B-192',
    'wlan.inputLabel.owe_enabled': 'OWE',
    'wlan.inputLabel.psk_format': 'Формат PSK',
    'wlan.inputLabel.psk_type': 'Тип PSK',
    'wlan.inputLabel.psk_type.selectOption.open': 'Открытый',
    'wlan.inputLabel.psk_type.selectOption.encrypted': 'Зашифрованный',
    'wlan.inputLabel.psk_key': 'Ключ PSK',
    'wlan.inputLabel.owe_wlan_id': 'Идентификатор БЛВС для переходного режима OWE',
    'wlan.inputLabel.sae_anti_clogging_threshold': 'Количество SAE сессий',
    'wlan.inputLabel.sae_max_retries': 'Максимальное количество повторных передач SAE',
    'wlan.inputLabel.sae_password_element': 'Метод поддержки получения элемента пароля SAE',
    'wlan.inputLabel.sae_password_element.selectOption.hnp': 'Hunting and pecking only',
    'wlan.inputLabel.sae_password_element.selectOption.h2e': 'Hash to element only',
    'wlan.inputLabel.sae_password_element.selectOption.both-h2e-hnp': 'Both',
    'wlan.inputLabel.isWebAuthProfileEnabled': 'WEB аутентификация',
    'wlan.inputLabel.web_auth_profile': 'Правило WEB аутентификации',
    'wlan.inputLabel.radius_8021x_profile': 'Radius профиль 802.1x аутентификации',
    'wlan.inputLabel.radius_accounting_profile': 'Radius профиль аккаунтинга',
    'wlan.inputLabel.radius_accounting_profile.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.nas_id_1': 'Параметр NAS ID 1',
    'wlanPolicy.inputLabel.nas_id_1_custom': 'Cобственная строка NAS ID 1',
    'wlanPolicy.inputLabel.nas_id_2': 'Параметр NAS ID 2',
    'wlanPolicy.inputLabel.nas_id_2_custom': 'Cобственная строка NAS ID 2',
    'wlanPolicy.inputLabel.nas_id_3': 'Параметр NAS ID 3',
    'wlanPolicy.inputLabel.nas_id_3_custom': 'Cобственная строка NAS ID 3',
    'wlan.inputLabel.max_connection_radio_wlan': 'На Радио на SSID',
    'wlan.inputLabel.dtim_interval_5ghz': 'Диапазон 5 ГГц (1-255)',
    'wlan.inputLabel.dtim_interval_24ghz': 'Диапазон 2.4 ГГц (1-255)',
    'wlanPolicy.inputLabel.nas.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.nas.selectOption.sys-name': 'название контроллера',
    'wlanPolicy.inputLabel.nas.selectOption.sys-ip': 'ip адрес контроллера',
    'wlanPolicy.inputLabel.nas.selectOption.sys-mac': 'mac адрес контроллера',
    'wlanPolicy.inputLabel.nas.selectOption.ssid': 'название БЛВС',
    'wlanPolicy.inputLabel.nas.selectOption.ap-ip': 'ip адрес ТД',
    'wlanPolicy.inputLabel.nas.selectOption.ap-name': 'название ТД',
    'wlanPolicy.inputLabel.nas.selectOption.ap-mac-eth': 'mac адрес ТД',
    'wlanPolicy.inputLabel.nas.selectOption.ap-policy-tag': 'тег политик ТД',
    'wlanPolicy.inputLabel.nas.selectOption.ap-site-tag': 'тег здания ТД',
    'wlanPolicy.inputLabel.nas.selectOption.ap-location': 'локация ТД',
    'wlanPolicy.inputLabel.nas.selectOption.customString': 'собственная строка',
    'wlan.inputLabel.neighbor_report_enabled': 'Отчёт о соседях',
    'wlan.inputLabel.param_80211ax_enabled': '802.11ax',
    'wlan.inputLabel.ofdma_downlink_enabled': 'OFDMA для нисходящего канала',
    'wlan.inputLabel.ofdma_uplink_enabled': 'OFDMA для восходящего канала',
    'wlan.inputLabel.mumimo_downlink_enabled': 'MU-MIMO для нисходящего канала',
    'wlan.inputLabel.mumimo_uplink_enabled': 'MU-MIMO для восходящего канала',
    'wlan.inputLabel.bss_twt_enabled': 'Целевое время пробуждения',
    'wlan.inputLabel.bss_fast_transition_enabled': 'BSS Transition',
    'wlan.inputLabel.advertise_ap_name_enabled': 'Распространение названия ТД',
    'wlan.inputLabel.p2p_block_action': 'Блокировка соединений точка-точка',
    'wlan.inputLabel.p2p_block_action.selectOption.disabled': 'Отключено',
    'wlan.inputLabel.p2p_block_action.selectOption.drop': 'Отбрасывать',
    'wlan.inputLabel.deny_laa_rcm_enabled': 'Блокировка подключений с Randomized MAC',
    'wlan.inputLabel.wmm_policy': 'WMM политика',
    'wlan.inputLabel.wmm_policy.selectOption.disabled': 'Отключено',
    'wlan.inputLabel.p2p_block_action.selectOption.allowed': 'Разрешено',
    'wlan.inputLabel.p2p_block_action.selectOption.require': 'Требуется',
    'wlan.inputLabel.okc_enabled': 'OKC',
    'wlan.inputError.uniqueProfileName': 'Имя профиля не уникально',
    'wlan.inputError.uniqueWlanId': 'Идентификатор БЛВС не уникален',
    'wlan.inputError.status_6ghz_enabled': 'Выберите Тип безопасности WPA2+WPA3 или WPA3 и включите 802.11ax',
    'wlan.inputError.profileNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'wlan.inputError.ssidMask': 'Поле должно содержать только английские буквы, цифры и специальные знаки: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~ не должно содержать пробелов в начале и в конце',
    'wlanPolicy.inputError.nasMask': 'Поле должно содержать только английские буквы, цифры и специальные знаки: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~',
    'wlan.inputError.psk_key.open.ascii': 'Введите ключ PSK. Ключ PSK должен содержать от 8 до 63 знаков',
    'wlan.inputError.psk_key.open.hex': 'Введите ключ PSK. Ключ PSK должен содержать 64 знака в формате HEX',
    'wlan.inputError.psk_key.encrypted': 'Введите ключ PSK',
    'wlan.inputError.authKeyManagementCheckbox.required': 'Должен быть выбран хотя бы один переключатель',
    'wlan.inputError.authKeyManagementCheckbox.combination': 'Некорректная комбинация переключателей',

    'wlan.inputInfo.mab_radius_profile': 'Нажмите {here} для перехода на страницу AAA для создания Radius',
    'wlan.inputInfo.owe_wlan_id.wpa3': 'Введите идентификатор БЛВС с типом безопасности Открытая',
    'wlan.inputInfo.owe_wlan_id.open': 'Введите идентификатор БЛВС с поддержкой OWE',
    'wlan.inputInfo.web_auth_profile': 'Нажмите {here} для перехода на страницу WEB auth для создания правила',
    'wlan.inputInfo.radius_8021x_profile': 'Нажмите {here} для перехода на страницу AAA для создания Radius',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.1': 'Включите метод управления ключами аутентификации. Поддерживаемые комбинации:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.2': 'CCMP шифрование и 802.1x/802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.3': 'CCMP шифрование и PSK/PSK-SHA256/FT+PSK-SHA256',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2.4': 'CCMP-256/GCMP-256 шифрование и SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.1': 'Включите метод управления ключами аутентификации.',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.2': 'Поддерживаемые комбинации для WPA2:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.3': 'CCMP шифрование и 802.1x/802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.4': 'CCMP шифрование и PSK/PSK-SHA256/FT+PSK-SHA256',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.5': 'CCMP-256/GCMP-256 шифрование и SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.6': 'Поддерживаемые комбинации для WPA3:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.7': 'AES шифрование и 802.1X-SHA256/FT+802.1x/',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.8': 'AES шифрование и SAE/FT+SAE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa2wpa3.9': 'CCMP-256/GCMP-256 и SUITE-B-192',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.1': 'Включите метод управления ключами аутентификации. Поддерживаемые комбинации:',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.2': 'CCMP шифрование и 802.1X-SHA256/FT+802.1x',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.3': 'CCMP шифрование и SAE/FT+SAE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.4': 'CCMP шифрование и OWE',
    'wlan.tabText.warningCaption.authentication-key-management.wpa3.5': 'GCMP-256 шифрование и SUITE-B-192',
    'wlan.tabText.warningCaption.80211r.wpa2.1': 'Для работы 802.11r включите FT+PSK или FT+802.1x',
    'wlan.tabText.warningCaption.80211r.wpa2wpa3.1': 'Для работы 802.11r включите FT+PSK или\\и FT+SAE или FT+802.1x',
    'wlan.tabText.warningCaption.80211r.wpa3.1': 'Для работы 802.11r включите FT+SAE',
    'wlan.tableHeading.id': 'Идентификатор профиля',
    'wlan.tableHeading.status_ssid_enabled': 'Статус БЛВС',
    'wlan.tableHeading.wlanId': 'Идентификатор БЛВС',
    'wlan.tableHeading.name': 'Название профиля БЛВС',
    'wlan.tableHeading.ssid': 'SSID',
    'wlan.tableHeading.24_5Ghz': '2.4ГГц\\5ГГц Безопасность',
    'wlan.tableHeading.6Ghz': '6ГГц Безопасность',
    'wlan.tableCell.security_type.open': 'Открытая',
    'wlan.tableCell.security_type.wpa2': 'WPA2',
    'wlan.tableCell.security_type.wpa3': 'WPA3',
    'wlan.tableCell.security_type.wpa2wpa3': 'WPA2+WPA3',
    'wlan.tableCell.mab_radius_profile': 'MAC Фильтрация',
    'wlan.tableCell.web_auth_profile': 'WEB аутентификация',
    'wlan.tableCell.support_pmf': 'Поддержка 802.11w включена',
    'wlan.tableCell.fast_roaming_enabled': 'Поддержка 802.11r включена',
    'wlan.responseMessage.deleteWlans.success': 'Профиль БЛВС был успешно удалён',
    'wlan.responseMessage.deleteWlans.error': 'Профиль БЛВС не был удалён',
    'wlan.responseMessage.createWlan.success': 'Профиль БЛВС был успешно добавлен',
    'wlan.responseMessage.createWlan.error': 'Профиль БЛВС не был добавлен',
    'wlan.responseMessage.updateWlan.success': 'Профиль БЛВС был успешно обновлён',
    'wlan.responseMessage.updateWlan.error': 'Профиль БЛВС не был обновлён',
    'wlan.responseMessage.enableWlans.success': 'Выбранные профили БЛВС были включены',
    'wlan.responseMessage.enableWlans.error': 'Выбранные профили БЛВС не были включены',
    'wlan.responseMessage.disableWlans.success': 'Выбранные профили БЛВС были выключены',
    'wlan.responseMessage.disableWlans.error': 'Выбранные профили БЛВС не были выключены',
    'branchProfile.pageName': 'Профиль филиала',
    'branchProfile.addBranchProfile': 'Добавить Профиль филиала',
    'branchProfile.changeBranchProfile': 'Редактировать Профиль филиала',
    'branchProfile.tabLabel.general': 'Основные',
    'branchProfile.tabLabel.authentication': 'Локальная аутентификация',
    'branchProfile.tabLabel.vlan': 'Настройка VLAN',
    'branchProfile.tabLabel.policy': 'Настройка политик',
    'branchProfile.inputLabel.profileName': 'Название профиля',
    'branchProfile.inputLabel.profileDescription': 'Описание профиля',
    'branchProfile.inputLabel.nativeVlan': 'Native VLAN',
    'branchProfile.inputLabel.fallbackRadioShutdown': 'Отключение радио-интерфейсов при недоступности проводного интерфейса',
    'branchProfile.inputLabel.arpCaching': 'Кэширование ARP',
    'branchProfile.inputLabel.efficientSoftwareUpgrade': 'Эффективное обновление ПО',
    'branchProfile.inputLabel.ipOverlap': 'Пересечение IP адресов',
    'branchProfile.inputLabel.pmkPropagation': 'Централизованное распространение PMK',
    'branchProfile.inputLabel.radiusGroup': 'Radius группа',
    'branchProfile.inputLabel.webAuthentication': 'WEB аутентификация',
    'branchProfile.inputLabel.vlanName': 'Название VLAN',
    'branchProfile.inputLabel.vlanId': 'Идентификатор VLAN',
    'branchProfile.inputLabel.aclType': 'Список доступа',
    'branchProfile.inputLabel.aclType.unidirectional': 'Однонаправленный',
    'branchProfile.inputLabel.aclType.bidirectional': 'Двунаправленный',
    'branchProfile.inputLabel.aclValue': 'Название списка доступа',
    'branchProfile.inputLabel.aclIngressVlaue': 'Название списка доступа для входящего трафика',
    'branchProfile.inputLabel.aclEgressValue': 'Назание списка доступа для исходящего трафика',
    'branchProfile.tableHeading.vlanId': 'Идентификатор VLAN',
    'branchProfile.tableHeading.vlanName': 'Название VLAN',
    'branchProfile.tableHeading.aclIngress': 'Имя списка доступа для входящего трафика',
    'branchProfile.tableHeading.aclEgress': 'Имя списка доступа для исходящего трафика',
    'branchProfile.inputError.uniqueProfileName': 'Имя профиля не уникально',
    'branchProfile.inputError.profileNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'branchProfile.inputError.profileDescriptionMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце',
    'branchProfile.inputError.uniqueVlanName': 'Название VLAN не уникально',
    'branchProfile.inputError.vlanNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'branchProfile.inputError.uniqueVlanId': 'Идентификатор VLAN не уникален',
    'branchProfile.inputLabel.radiusGroup.selectOption.notConfigured': 'Нет',
    'branchProfile.inputLabel.webAuthentication.selectOption.notConfigured': 'Нет',
    'branchProfile.inputLabel.aclValue.selectOption.notConfigured': 'Не настроено',
    'branchProfile.inputLabel.aclIngressVlaue.selectOption.notConfigured': 'Не настроено',
    'branchProfile.inputLabel.aclEgressValue.selectOption.notConfigured': 'Не настроено',
    'branchProfile.tableHeading.name': 'Название Профиля филиала',
    'branchProfile.tableHeading.description': 'Описание профиля',
    'branchProfile.responseMessage.createBranchProfile.success': 'Профиль филиала был успешно добавлен',
    'branchProfile.responseMessage.createBranchProfile.error': 'Профиль филиала не был добавлен',
    'branchProfile.responseMessage.updateBranchProfile.success': 'Профиль филиала был успешно обновлён',
    'branchProfile.responseMessage.updateBranchProfile.error': 'Профиль филиала не был обновлён',
    'branchProfile.responseMessage.deleteBranchProfiles.success': 'Профиль филиала был успешно удалён',
    'branchProfile.responseMessage.deleteBranchProfiles.error': 'Профиль филиала не был удалён',
    'branchProfile.confirmDeletingText': 'Подтверждаете удаление VLAN',
    'vlanConfiguration.pageSectionName': 'VLAN',
    'vlan.pageName': 'VLAN',
    'vlan.tableHeading.adminStatus': 'Статус',
    'vlan.tableHeading.vlanId': 'VLAN ID',
    'vlan.tableHeading.name': 'Название',
    'vlan.responseMessage.deleteVlans.success': 'VLAN был успешно удалён',
    'vlan.responseMessage.deleteVlans.error': 'VLAN не был удалён',
    'vlan.responseMessage.createVlan.success': 'VLAN был успешно добавлен',
    'vlan.responseMessage.createVlan.error': 'VLAN не был добавлен',
    'vlan.responseMessage.updateVlan.success': 'Информация о VLAN была успешно обновлена',
    'vlan.responseMessage.updateVlan.error': 'Информацию о VLAN не удалось обновить',
    'vlan.createVlan': 'Добавить VLAN',
    'vlan.changeVlan': 'Редактировать VLAN',
    'vlan.tabLabel.general': 'Основные',
    'vlan.inputLabel.creationMode.single': 'Создать один VLAN',
    'vlan.inputLabel.creationMode.multiple': 'Создать несколько VLANs',
    'vlan.inputLabel.vlan_id': 'Идентификатор VLAN',
    'vlan.inputLabel.name': 'Название',
    'vlan.inputLabel.admin_status': 'Статус',
    'vlan.inputLabel.initial_vlan_id': 'Начальный Идентификатор VLAN',
    'vlan.inputLabel.last_vlan_id': 'Конечный Идентификатор VLAN',
    'vlan.inputError.uniqueVlanId': 'Идентификатор VLAN не уникален',
    'vlan.inputError.vlanNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'vlan.inputError.uniqueVlanName': 'Название не уникально',
    // todo
    'svi.pageName': 'SVI',
    'svi.tableHeading.adminStatus': 'Административный статус',
    'svi.tableHeading.operStatus': 'Операционный статус',
    'svi.tableHeading.name': 'Название',
    'svi.tableHeading.ip': 'IPv4 адрес',
    'svi.tableHeading.description': 'Описание',
    'svi.tableHeading.subnetMask': 'Маска подсети',
    'svi.inputError.isVlanId': 'Идентификатор VLAN не существует',
    'svi.inputError.vlanValueRange': 'Поле должно содержать уникальные числовые положительные значения от 1 до 4094',
    'svi.inputError.uniqueSVIName': 'Имя SVI не уникально.',
    'svi.inputError.descriptionMask':
      'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { |, не должно содержать пробелов в начале и в конце',
    'svi.inputError.mtu': 'Поле должно содержать уникальные числовые положительные значения от 68 до 1500',
    'svi.inputError.ipAddressMask': 'Должно соответствовать формату маски подсети, X.X.X.X',
    'svi.inputError.subnetMask': 'Должно соответствовать формату маски подсети, X.X.X.X',
    'svi.tabLabel.general': 'Основные',
    'svi.inputLabel.idVlan': 'Идентификатор VLAN',
    'svi.inputLabel.description': 'Описание',
    'svi.inputLabel.adminStatus': 'Административный статус',
    'svi.inputLabel.ip': 'IPv4 адрес',
    'svi.inputLabel.subnetMask': 'Маска подсети',
    'svi.inputLabel.aclIngress': 'Входящий список доступа',
    'svi.inputLabel.aclEgress': 'Исходящий список доступа',
    'svi.inputLabel.mtu': 'MTU',
    'svi.inputLabel.mDNSGateway': 'mDNS Шлюз',
    'svi.inputLabel.aclIngress.deafultValue': 'Нет',
    'svi.inputLabel.aclEgress.deafultValue': 'Нет',
    'svi.inputLabel.acl.selectOption.notConfigured': 'Нет',
    'svi.createSVI': 'Добавить SVI',
    'svi.changeSVI': 'Редактировать SVI',
    'svi.responseMessage.deleteSVIs.success': 'SVI был успешно удалён',
    'svi.responseMessage.deleteSVIs.error': 'SVI не был удалён',
    'svi.responseMessage.createSVI.success': 'SVI был успешно добавлен',
    'svi.responseMessage.createSVI.error': 'SVI не был добавлен',
    'svi.responseMessage.updateSVI.success': 'Информация о SVI была успешно обновлена',
    'svi.responseMessage.updateSVI.error': 'Информацию о SVI не удалось обновить',
    'vlanGroup.pageName': 'VLAN группа',
    'vlanGroup.createVlanGroup': 'Добавить VLAN группу',
    'vlanGroup.changeVlanGroup': 'Редактировать VLAN группу',
    'vlanGroup.tableHeading.name': 'Название',
    'vlanGroup.tableHeading.list_vlan_id': 'Перечень VLAN',
    'vlanGroup.tabLabel.general': 'Основные',
    'vlanGroup.inputLabel.name': 'Название',
    'vlan.inputLabel.list_vlan_id': 'Перечень VLAN',
    'vlan.inputPlaceholder.list_vlan_id': 'Пример: 4,5,7-9',
    'vlanGroup.inputError.vlanGroupNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце',
    'vlanGroup.inputError.uniqueVlanGroupName': 'Название не уникально.',
    'vlanGroup.inputError.vlanGroupListVlanIdMask': 'Поле должно содержать только цифры (1-4094) и символы: ", -" и не должно содержать пробелов в начале и в конце. Пример: 4,5,7-9',
    'vlanGroup.responseMessage.createVlanGroup.success': 'Информация о VLAN группе была успешно добавлена',
    'vlanGroup.responseMessage.createVlanGroup.error.default': 'Информация о VLAN группе не была добавлена',
    'vlanGroup.responseMessage.createVlanGroup.error.invalidRange': 'Нельзя создать VLAN группу. Необходимо создать VLAN:',
    'vlanGroup.responseMessage.updateVlanGroup.success': 'Информация о VLAN группе была успешно обновлена',
    'vlanGroup.responseMessage.updateVlanGroup.error': 'Информацию о VLAN группе  не удалось обновить',
    'vlanGroup.responseMessage.deleteVlanGroups.success': 'VLAN группа была успешно удалёна',
    'vlanGroup.responseMessage.deleteVlanGroups.error': 'VLAN группа не была удалёна',
    'wlanPolicy.pageName': 'Политики БЛВС',
    'wlanPolicy.addWlanPolicyProfile': 'Добавить профиль Политики БЛВС',
    'wlanPolicy.cloneWlanPolicyProfile': 'Клонировать профиль Политики БЛВС {name}',
    'wlanPolicy.changeWlanPolicyProfile': 'Редактировать профиль Политики БЛВС',
    'wlanPolicy.tabLabel.general': 'Основные',
    'wlanPolicy.tabLabel.accessPolicies': 'Политики доступа',
    'wlanPolicy.tabLabel.qos': 'Качество обслуживания',
    'wlanPolicy.tabLabel.advanced': 'Дополнительно',
    'wlanPolicy.tabSectionLabel.qoSProfileClient': 'Профиль качества обслуживания для клиента',
    'wlanPolicy.tabSectionLabel.qoSProfileSsid': 'Профиль обслуживания для БЛВС',
    'wlanPolicy.tabSectionLabel.wlanTimeoutSettings': 'Настройки Тайм-аутов БЛВС',
    'wlanPolicy.tabSectionLabel.aaaSettings': 'Настройки AAA',
    'wlanPolicy.tabSectionLabel.proxySettings': 'Настройка Proxy',
    'wlanPolicy.tabSectionLabel.eogreSettings': 'Настройка EoGRE',
    'wlanPolicy.inputLabel.name': 'Название профиля',
    'wlanPolicy.inputLabel.description': 'Описание профиля',
    'wlanPolicy.inputLabel.profile_enabled': 'Статус профиля',
    'wlanPolicy.inputLabel.central_switch_enabled': 'Централизованная коммутация',
    'wlanPolicy.inputLabel.central_auth_enabled': 'Централизованная аутентификация',
    'wlanPolicy.inputLabel.acl': 'Список доступа',
    'wlanPolicy.inputLabel.vlan': 'VLAN',
    'wlanPolicy.inputLabel.vlan_group': 'VLAN группа',
    'wlanPolicy.inputLabel.vlan.warningCaption': 'В режиме локальной коммутации настройка VLAN группы должна осуществляться в Профиле филиала',
    'wlanPolicy.inputLabel.policy_map_client_in': 'Входящий',
    'wlanPolicy.inputLabel.policy_map_client_out': 'Исходящий',
    'wlanPolicy.inputLabel.policy_map_ssid_in': 'Входящий',
    'wlanPolicy.inputLabel.policy_map_ssid_out': 'Исходящий',
    'wlanPolicy.inputLabel.policy_map_client_in.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.policy_map_client_out.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.policy_map_ssid_in.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.policy_map_ssid_out.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.session_timeout': 'Тайм-аут сессии (сек)',
    'wlanPolicy.inputLabel.idle_timeout': 'Тайм-аут неактивности (сек)',
    'wlanPolicy.inputLabel.idle_threshold': 'Порог для тайм-аута (байт)',
    'wlanPolicy.inputLabel.exclusion_timeout': 'Время блокировки (сек)',
    'wlanPolicy.inputLabel.aaa_override_enabled': 'AAA Override',
    'wlanPolicy.inputLabel.proxy_arp_enabled': 'Proxy arp',
    'wlanPolicy.inputLabel.eogre_profile': 'EoGRE профиль',
    'wlanPolicy.inputLabel.eogre_profile.selectOption.notConfigured': 'Нет',
    'wlanPolicy.tableHeading.id': 'Идентификатор профиля',
    'wlanPolicy.tableHeading.name': 'Название профиля',
    'wlanPolicy.tableHeading.description': 'Описание профиля',
    'wlanPolicy.tableHeading.profile_enabled': 'Статус профиля',
    'wlanPolicy.inputError.uniqueWlanPolicyName': 'Имя профиля не уникально',
    'wlanPolicy.inputError.wlanPolicyNameMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце и не должно состоять из нескольких слов',
    'wlanPolicy.inputError.descriptionMask': 'Поле должно содержать только буквы, цифры и символы: ! " # $ % & \'() * + , - . / : ; < = > ? @[ ] ^ _ ` { | не должно содержать пробелов в начале и в конце',
    'wlanPolicy.inputError.vlanConfiguration': 'VLAN или VLAN группа должна быть выбрана',
    'wlanPolicy.inputLabel.acl.selectOption.notConfigured': 'Нет',
    'wlanPolicy.inputLabel.vlan.selectOption.notConfigured': 'Выберите VLAN',
    'wlanPolicy.inputLabel.vlan_group.selectOption.notConfigured': 'Выберите VLAN группу',
    'wlanPolicy.responseMessage.deleteWlanPolicies.success': 'Профиль Политики БЛВС был успешно удалён',
    'wlanPolicy.responseMessage.deleteWlanPolicies.error': 'Профиль Политики БЛВС не был удалён',
    'wlanPolicy.responseMessage.createWlanPolicyProfile.success': 'Профиль Политики БЛВС был успешно добавлен',
    'wlanPolicy.responseMessage.createWlanPolicyProfile.error': 'Профиль Политики БЛВС не был добавлен',
    'wlanPolicy.responseMessage.updateWlanPolicy.success': 'Профиль Политики БЛВС был успешно обновлён',
    'wlanPolicy.responseMessage.updateWlanPolicy.error': 'Профиль Политики БЛВС не был обновлён',
    'administration.createNtpServer': 'Добавить NTP сервер',
    'administration.enableAuth': 'Включить аутентификацию',
    'administration.hostName': 'Имя хоста',
    'administration.ipForDns': 'IP для разрешения DNS',
    'administration.ntpDetails': 'Детали NTP сервера',
    'administration.prefer': 'Предпочитаемый',
    'administration.refreshNtp': 'Обновить NTP таблицу',
    'administration.sourceAddress': 'Исходный адрес',
    'administration.status': 'Статус',
    'administration.time.address': 'Адрес',
    'administration.time.confirmDeletingModal': 'Подтвердите удаление',
    'administration.time.confirmDeletingModalHeader': 'Удаление NTP сервера',
    'administration.time.notificationCreatedSuccessfully': 'NTP сервер успешно добавлен',
    'administration.time.notificationDeletedSuccessfully': 'NTP сервер успешно удален',
    'administration.time.notificationUpdatedSuccessfully': 'NTP сервер успешно обновлен',
    'administration.time.ntpListAllСonfiguredServers': 'NTP серверы уже настроенные на контроллере',
    'administration.time.ntpListAllAlreadySelected': 'Все настроенные NTP серверы уже выбраны',
    'administration.time.ntpListIsEmpty': 'Нет настроенных NTP серверов. Для настройки перейдите в раздел "Администрирование" / "Время".',
    'administration.time.port': 'Порт',
    'administration.time.priority': 'Приоритет',
    'administration.time.source': 'Источник',
    'administration.time.status': 'Статус',
    'administration.time.title': 'Имя',
    'administration.updateNtpServer': 'Редактировать NTP сервер',
    'administration.vrfName': 'Имя VRF',
    'adsprofile.activeAd': 'Идёт показ',
    'adsprofile.add': 'Добавить рекламу или опрос',
    'adsprofile.addanswer': 'Введите вариант ответа',
    'adsprofile.additionalSettings': 'Дополнительные настройки',
    'adsprofile.addVariant': 'Добавить вариант ответа',
    'adsprofile.ads': 'Реклама или опрос',
    'adsprofile.adsStats': 'Статистика просмотров по всей рекламе',
    'adsprofile.adsStatsAllAuthorizationTypes': 'Статистика просмотров по всем типам авторизации',
    'adsprofile.adsStatsAllAuthorizationTypesHeader': 'Статистика просмотров и переходов по всем типам авторизации',
    'adsprofile.adsStatsAllAuthorizationTypesShort': 'Просмотры',
    'adsprofile.adsStatsAllFollowsAuthorizationTypesShort': 'Переходы',
    'adsprofile.adsStatsbyId': 'Статистика просмотров',
    'adsprofile.adsStatsClose': 'к разделу "Реклама"',
    'adsprofile.adsStatsFor': 'для',
    'adsprofile.adsStatsSepareteAuthorizationTypesHeader': 'Статистика просмотров по типам авторизации',
    'adsprofile.adsStatsSepareteFacebook': 'Facebook',
    'adsprofile.adsStatsSepareteFree': 'Бесплатный',
    'adsprofile.adsStatsSepareteInstagram': 'Instagram',
    'adsprofile.adsStatsSepareteSponsor': 'Спонсорский',
    'adsprofile.adsStatsSepareteStaff': 'Служебный',
    'adsprofile.adsStatsSepareteVk': 'Вконтакте',
    'adsprofile.adsStatsSepareteVoucher': 'Ваучер',
    'adsprofile.adtype': 'Тип рекламы или опроса',
    'adsprofile.changePollsColorThemeAlert': 'Чтобы изменения вступили в силу нужно нажать кнопку "Сохранить"',
    'adsprofile.changeRedirectButtonColorAlert': 'Чтобы изменения вступили в силу нужно нажать кнопку "Сохранить"',
    'adsprofile.dayOfWeek': 'Дни недели',
    'adsprofile.dayOfWeekCaption': 'Дни недели для показа',
    'adsprofile.dayOfWeekFriday': 'Пятница',
    'adsprofile.dayOfWeekMonday': 'Понедельник',
    'adsprofile.dayOfWeekSaturday': 'Суббота',
    'adsprofile.dayOfWeekSunday': 'Воскресенье',
    'adsprofile.dayOfWeekThursday': 'Четверг',
    'adsprofile.dayOfWeekTuesday': 'Вторник',
    'adsprofile.dayOfWeekWednesday': 'Среда',
    'adsprofile.doneAd': 'Показ окончен',
    'adsprofile.duration': 'Время проигрывания рекламы или опроса (в секундах)',
    'adsprofile.edit': 'Редактирование рекламы или опроса',
    'adsprofile.enableBirthDate': 'Спрашивать дату рождения пользователя',
    'adsprofile.enableSex': 'Спрашивать пол пользователя',
    'adsprofile.enterRedirectButtonColor': 'Введите цвет кнопки',
    'adsprofile.enterRedirectButtonText': 'Введите текст и цвет кнопки редиректа',
    'adsprofile.enterRedirectButtonTextPlaceholder': 'Подробнее',
    'adsprofile.fileLimitation': 'Поддерживаются следующие форматы изображений: .jpg, .png. Максимальный размер файла: {KB} килобайт.',
    'adsprofile.flash': 'флеш',
    'adsprofile.hasDesktop': 'Загрузить версию рекламы или опроса для широких экранов',
    'adsprofile.hasPollPictures': 'Добавить изображения к вариантам ответа',
    'adsprofile.hasTheme': 'Установить особую цветовую тему для опроса',
    'adsprofile.iframe': 'iframe',
    'adsprofile.image': 'Изображение',
    'adsprofile.massAdsEditSchedule': 'Редактировать расписание',
    'adsprofile.massAdsEditScheduleAndTargeting': 'Редактировать расписание и таргетинг',
    'adsprofile.massAdsEditSelectAll': 'выбрать всё',
    'adsprofile.massAdsEditSelected': 'выбрано',
    'adsprofile.massAdsEditTargeting': 'Редактировать таргетинг',
    'adsprofile.massAdsUpdatedSuccess': 'Данные успешно обновлены',
    'adsprofile.new': 'Новая реклама или опрос',
    'adsprofile.noColor': 'Нет цвета',
    'adsprofile.notificationAdPollCreatedSuccessfully': 'Реклама или опрос успешно созданы',
    'adsprofile.notificationAdPollDeletedSuccessfully': 'Реклама или опрос успешно удалены',
    'adsprofile.notificationAdPollUpdatedSuccessfully': 'Реклама или опрос успешно обновлены',
    'adsprofile.numberOfVisits': 'Количество посещений',
    'adsprofile.numberOfVisitsCaption': 'Можно настроить показ новым клиентам (равно 0), заходящим 10 раз (равно 10), заходившим больше 5 раз (больше 5) и т.д.',
    'adsprofile.numberOfVisitsHeader': 'Настройка показов по количеству посещений',
    'adsprofile.numberOfVisitsSign': 'Условие',
    'adsprofile.numberOfVisitsSignequal': 'Равно',
    'adsprofile.numberOfVisitsSigngreater': 'Больше',
    'adsprofile.numberOfVisitsSignless': 'Меньше',
    'adsprofile.os': 'Операционные системы',
    'adsprofile.osCaption': 'Список операционных систем, для которых будет показываться реклама или опрос. Например iOS или Android',
    'adsprofile.osFromList': 'Выберите операционную систему из списка',
    'adsprofile.osInput': 'или введите',
    'adsprofile.osInstruction': 'Таргетирование по ОС. Если не указана ни одна операционная система, то реклама или опрос показывается на устройствах со всеми операционными системами',
    'adsprofile.osNoSpecified': 'Операционные системы не выбраны',
    'adsprofile.osSpecified': 'Выбранные операционные системы',
    'adsprofile.plannedAd': 'Запланировано',
    'adsprofile.platform': 'Площадка',
    'adsprofile.platformCaption': 'Площадка для показа',
    'adsprofile.platformDesktop': 'ПК',
    'adsprofile.platformMobile': 'Мобильные',
    'adsprofile.platformTablet': 'Планшет',
    'adsprofile.poll': 'Опрос',
    'adsprofile.poll_quality': 'Опрос оценки качества',
    'adsprofile.poll_user_data': 'Запрос данных пользователя',
    'adsprofile.pollImagesCaption': 'Вариант ответа должен содержать и текст, и изображение.',
    'adsprofile.pollQualityVariants': 'Количество вариантов ответа (количество звездочек)',
    'adsprofile.pollResults': 'Результаты опроса',
    'adsprofile.PollSelfVariant': 'Свой ответ',
    'adsprofile.pollSelfVariantPlaceholder': 'Напишите свой вариант',
    'adsprofile.pollUserData.date_of_birth': 'День рождения',
    'adsprofile.pollUserData.dateOfBirth': 'Введите дату рождения',
    'adsprofile.pollUserData.genders.female': 'Женский',
    'adsprofile.pollUserData.genders.male': 'Мужской',
    'adsprofile.pollUserData.getInfo': 'Какие данные о пользователе собирать',
    'adsprofile.pollUserData.selectGender': 'Выберите пол',
    'adsprofile.pollUserData.sex': 'Пол',
    'adsprofile.pollvariants': 'Варианты ответа',
    'adsprofile.poolQualityUpdateVariants': 'Применить',
    'adsprofile.poolQualityVariantsValidate': 'Количество вариантов ответа должно быть больше 1 и меньше 5',
    'adsprofile.preview': 'Предпросмотр',
    'adsprofile.previewDesktop': 'Десктоп шаблон',
    'adsprofile.previewMobile': 'Мобильный шаблон',
    'adsprofile.priority': 'Приоритет',
    'adsprofile.priorityCaption': 'От 0 (наименьший) до 100 (наибольший)',
    'adsprofile.question': 'Вопрос',
    'adsprofile.redirectURL': 'URL редиректа при клике',
    'adsprofile.redirectURLCaption': 'Оставьте пустым, если редирект не нужен',
    'adsprofile.resolutionCaption': 'По умолчанию файл выше будет использован как для мобильных устройств, так и для ноутбуков / стационарных компьютеров.',
    'adsprofile.sameAs': 'Такие же настройки',
    'adsprofile.schedule': 'Расписание',
    'adsprofile.scheduleCaption': 'Период активности рекламы',
    'adsprofile.scheduleDate': 'По состоянию начиная с:',
    'adsprofile.scheduleEmpty': 'Активная реклама на указанную дату отсутствует',
    'adsprofile.self_variant': 'Открытый вопрос (возможен свой вариант ответа)',
    'adsprofile.setSchedule': 'Пожалуйста, укажите расписание',
    'adsprofile.skip': 'Пропустить рекламу',
    'adsprofile.skipafter': 'Время пропуска рекламы (опроса) (в секундах)',
    'adsprofile.statistics': 'Статистика',
    'adsprofile.statisticsDuration': 'Длительность просмотров',
    'adsprofile.statisticsMeanDuration': 'Средняя длительность просмотра',
    'adsprofile.statisticsPoll': 'Результаты опроса',
    'adsprofile.statisticsShows': 'Статистика показов',
    'adsprofile.statisticsSkipped': 'Статистика просмотров с пропуском',
    'adsprofile.statisticsTotal': 'Всего просмотров',
    'adsprofile.switchPollWarning': 'Это действие приведет к удалению сохранённых вариантов ответа. Пожалуйста, подтвердите удаление.',
    'adsprofile.targeting': 'Настройки таргетинга',
    'adsprofile.textOnly': 'Только текст',
    'adsprofile.timeOfDay': 'Время суток',
    'adsprofile.timeOfDayAfternoon': 'День',
    'adsprofile.timeOfDayCaption': 'Время суток для показа',
    'adsprofile.timeOfDayEvening': 'Вечер',
    'adsprofile.timeOfDayMorning': 'Утро',
    'adsprofile.timeOfDayNight': 'Ночь',
    'adsprofile.url': 'URL адрес',
    'adsprofile.vendor': 'Производители',
    'adsprofile.vendorCaption': 'Список производителей, для которых будет показываться реклама или опрос. Например Apple или Intel.',
    'adsprofile.vendorinstruction': 'Таргетирование по производителям. Если не указано ни одного производителя, то реклама или опрос показывается на устройствах всех производителей.',
    'adsprofile.vendorNoSpecified': 'Производители не выбраны',
    'adsprofile.vendorSpecified': 'Выбранные производители',
    'adsprofile.video': 'Видео',
    'adsprofile.videoLoadingError': 'Проблема с загрузкой видео',
    'adsprofile.videoLoadingErrorReload': 'обновить',
    'adsprofile.views': 'Количество показов',
    'adsprofile.viewsCaption': '0 для бесконечного количества просмотров',
    'adsprofile.warnUsage': 'Пожалуйста, обратите внимание, что удаление рекламы, которая используется порталом, приведёт к {0}.',
    'adsprofile.warnUsageAccent': 'некорректной работе портала',
    'adsprofile.withImages': 'Картинки и текст',
    'alert.licenseEnds.link': 'Перейти к настройкам',
    'alert.licenseEnds.text': 'Ваша лицензия заканчивается!',
    'alert.lowOfLicenses.link': 'Перейти к лицензиям',
    'alert.lowOfLicenses.text': 'Осталось лицензия всего на одну ТД (или меньше). Если вы хотите использовать больше ТД, следует добавить лицензии',
    'alert.lowOfLicensesAnalytics.link': 'Перейти к лицензиям',
    'alert.lowOfLicensesAnalytics.text': 'Осталось лицензия всего на одну аналитику (или меньше). Если вы хотите использовать больше аналитик, следует добавить лицензии.',
    'alert.lowOfLicensesPortals.link': 'Перейти к лицензиям',
    'alert.lowOfLicensesPortals.text': 'Осталось лицензия всего на один портал (или меньше). Если вы хотите использовать больше порталов, следует добавить лицензии.',
    'aps.join': 'подключение',
    'aps.accessControlSettings': 'Контроль доступа',
    'aps.active': 'Активные',
    'aps.addCommonTemplates': 'Применить шаблон',
    'aps.addCommonWlans': 'Добавить общие сети',
    'aps.addConfig': 'Добавить конфигурацию',
    'aps.addCpe': 'Добавить точку',
    'aps.Advanced': 'Дополнительные настройки',
    'aps.advancedSettings': 'Дополнительные настройки',
    'aps.allWlans': 'Все Wi-Fi сети',
    'aps.assignCommonWlans': 'Присвоить общие сети',
    'aps.assignCommonWlansHint': 'Выберите общие сети WLAN для выбранных ТД',
    'aps.assignCommonWlansWarn': 'Внимание! Выбранные точки имеют разные частоты интерфейсов',
    'aps.assignTemplate': 'Применить шаблон',
    'aps.assignTemplateHint': 'Выбрать шаблон для выбранных ТД',
    'aps.auto': 'Авто',
    'aps.back': 'Назад',
    'aps.backgroundScaning': 'Сканирование радиоэфира',
    'aps.bandMode': 'Режим',
    'aps.bandwidth': 'Ширина',
    'aps.blacklist': 'Черный список',
    'aps.brokerHost': 'Хост брокера',
    'aps.buttonsApply': 'Применить',
    'aps.buttonsBack': 'Назад',
    'aps.buttonsUpgrade': 'Обновить',
    'aps.buttonsYesReboot': 'Да, перезагрузить',
    'aps.buttonsYesReset': 'Да, сбросить',
    'aps.cancel': 'Отмена',
    'aps.channel': 'Канал',
    'aps.channels': 'Каналы',
    'aps.clickToWatchEventsCaptionInTooltip': 'Нажмите, чтобы посмотреть события',
    'aps.clients': 'Клиенты',
    'aps.clientTimeout': 'Период кэша (в сек)',
    'aps.close': 'Закрыть',
    'aps.commandOutput': 'Результат',
    'aps.commonWlans': 'Общие сети',
    'aps.Config': 'Конфигурация',
    'aps.config': 'Конфигурация',
    'aps.configModalLabel': 'Введите скрипт',
    'aps.configModalTitle': 'Отправить скрипт настройки',
    'aps.configNotSendCaption': 'Пересылать конфигурацию каждый раз',
    'aps.configStatus': 'Статус конфигурации',
    'aps.confirm': 'Ок',
    'aps.connected': 'Подключено',
    'aps.connectionInfo': 'Информация о подключении',
    'aps.country': 'Код страны',
    'aps.cpe': 'Точки доступа',
    'aps.cpeClientsListFromCpeChannellCaption': 'Канал',
    'aps.cpeClientsListFromCpeClientsAssociated': 'Ассоциирован',
    'aps.cpeClientsListFromCpeClientsAuthenticated': 'Аутентифицирован',
    'aps.cpeClientsListFromCpeClientsAuthorized': 'Авторизован',
    'aps.cpeClientsListFromCpeClientsAuthorizedAuthenticatedAssociated': 'Авторизован, Аутентифицирован, Ассоциирован',
    'aps.cpeClientsListFromCpeClientsBeaconInterval': 'Beacon интервал',
    'aps.cpeClientsListFromCpeClientsBitrate': 'Скорость передачи данных',
    'aps.cpeClientsListFromCpeClientsCombinedSignal': 'Сигнал',
    'aps.cpeClientsListFromCpeClientsConnectedCaption': 'подключено',
    'aps.cpeClientsListFromCpeClientsConnectedTime': 'Время подключения',
    'aps.cpeClientsListFromCpeClientsDTIMPeriod': 'DTIM период',
    'aps.cpeClientsListFromCpeClientsInactiveTime': 'Время неактивности',
    'aps.cpeClientsListFromCpeClientsInfoCaption': 'Позволяет получить информацию о текущих клиентах непосредственно с самой ТД',
    'aps.cpeClientsListFromCpeClientsMac': 'MAC',
    'aps.cpeClientsListFromCpeClientsMFP': 'MFP',
    'aps.cpeClientsListFromCpeClientsPreamble': 'Preamble',
    'aps.cpeClientsListFromCpeClientsRX': 'вх.',
    'aps.cpeClientsListFromCpeClientsRxBitrate': 'вх. скорость',
    'aps.cpeClientsListFromCpeClientsRxBytes': 'вх. байтов',
    'aps.cpeClientsListFromCpeClientsRxDropMisc': 'вх. drop misc',
    'aps.cpeClientsListFromCpeClientsRxDuration': 'вх. duration',
    'aps.cpeClientsListFromCpeClientsRxPackets': 'вх. пакетов',
    'aps.cpeClientsListFromCpeClientsShortSlotTime': 'Short slot time',
    'aps.cpeClientsListFromCpeClientsSignal': 'Сигнал',
    'aps.cpeClientsListFromCpeClientsSignalAvg': 'Сигнал (в среднем)',
    'aps.cpeClientsListFromCpeClientsTableHeader': 'Клиенты',
    'aps.cpeClientsListFromCpeClientsTDLSPeer': 'TDLS peer',
    'aps.cpeClientsListFromCpeClientsTX': 'исх.',
    'aps.cpeClientsListFromCpeClientsTxBitrate': 'исх. скорость',
    'aps.cpeClientsListFromCpeClientsTxBytes': 'исх. байтов',
    'aps.cpeClientsListFromCpeClientsTxFailed': 'исх. failed',
    'aps.cpeClientsListFromCpeClientsTxPackets': 'исх. пакетов',
    'aps.cpeClientsListFromCpeClientsTxRetries': 'исх. retries',
    'aps.cpeClientsListFromCpeClientsWMMWME': 'WMM/WME',
    'aps.cpeClientsListFromCpeCommandLink': 'Показать текущих клиентов (данные с ТД)',
    'aps.cpeClientsListFromCpeCommandModalHeader': 'Клиенты ТД',
    'aps.cpeClientsListFromCpeNoClients': 'Нет клиентов для отображения',
    'aps.cpeClientsListFromCpeTooltipHeader': 'Информация о WLAN',
    'aps.cpeConfigSetButtonText': 'Загрузить конфигурацию из файла',
    'aps.cpeConfigSetButtonTooltipText': 'Загрузить конфигурацию для точек доступа из ранее сохраненного файла',
    'aps.cpeConfigSetCopyFromClipboardButtonCaption': 'Вставить из буфера обмена',
    'aps.cpeConfigSetCpeListCaption': 'Конфигурация будет применена для следующих ТД',
    'aps.cpeConfigSetCpeListNameCaptionAfterApplying': 'Имя (после применения)',
    'aps.cpeConfigSetCpeListNameCaptionBeforeApplying': 'Имя (до применения)',
    'aps.cpeConfigSetErrorsInUploadedData': 'В данных для конфигурации имеются ошибки. Проверьте их правильность.',
    'aps.cpeConfigSetFromUploadedFileButtonCaption': 'Загрузить из файла',
    'aps.cpeConfigSetFromUploadedFileIsertToFieldCaption': 'или вставьте конфигурацию в поле',
    'aps.cpeConfigSetTitleHeader': 'Применить конфигурацию ТД',
    'aps.cpeConfigShowCopyButtonCaption': 'Копировать в буфер обмена',
    'aps.cpeConfigShowCopyToClipboardError': 'Не удалось скопировать в буфер обмена',
    'aps.cpeConfigShowCopyToClipboardSuccess': 'Скопировано в буфер обмена',
    'aps.cpeConfigShowDownloadButtonCaption': 'Сохранить в файл',
    'aps.cpeConfigShowSaveToFileError': 'Не удалось сохранить в файл',
    'aps.cpeConfigShowTitleHeader': 'Конфигурация ТД',
    'aps.cpeConfigShowTooltipForButtonInCpe': 'Скачать конфигурацию ТД в виде файла',
    'aps.cpeEditCaptionOf': 'из',
    'aps.cpeEditCaptionUnlimited': 'не ограничено',
    'aps.cpeEditPanelDescriptionPlaceHolder': 'Введите описание ТД',
    'aps.cpeEditPanelNamePlaceHolder': 'Введите имя ТД',
    'aps.cpeEditRadioSettings': 'Настройки радио интерфейса',
    'aps.cpeEvents': 'Посмотреть события этой ТД',
    'aps.cpeFwCustomAddress': 'адрес сервера',
    'aps.cpeFwCustomAddressAndSchema': 'Адрес и протокол сервера с обновлениями',
    'aps.cpeId': 'ID точки доступа',
    'aps.serialNumber': 'Серийный номер',
    'aps.cpeInfo': 'Информация о ТД',
    'aps.cpesList': 'Точки доступа',
    'aps.cpeStatusTooltipActive': 'Активно',
    'aps.cpeStatusTooltipNotActive': 'Не активно',
    'aps.cpeStatusTooltipUpdating': 'Обновление',
    'aps.createTemplateFromCpe': 'Создать шаблон из настроек ТД',
    'aps.currentBlacklistItems': 'Элементов черного списка',
    'aps.currentState': 'Текущее состояние',
    'aps.currentWhitelistItems': 'Элементов белого списка',
    'aps.dbm': 'дБм',
    'aps.deleteAllTags': 'Удалить все теги',
    'aps.deleteAllWlans': 'Удалить все сети',
    'aps.deleteCommonTags': 'Удалить общие теги',
    'aps.deleteCommonWlans': 'Удалить общие сети',
    'aps.deleteCpe': 'Удалить точку доступа',
    'aps.deleteCpeConfirm': 'Подтвердить удаление',
    'aps.deleteCpes': 'Удалить точки доступа',
    'aps.deleteCpesConfirm': 'Подтвердить удаление ',
    'aps.deleteTagsConfirm': 'Подтвердить удаление тегов с выбранных точек',
    'aps.deleteTemplate': 'Удалить шаблон',
    'aps.deleteWlansConfirm': 'Подтвердить удаление сетей на выбранных точках',
    'aps.description': 'Описание',
    'aps.DHCPCaptureFilter': 'Захват DHCP пакетов',
    'aps.DHCPCaptureFilterDisabled': 'DHCP захват выкл.',
    'aps.DHCPCaptureFilterDisabledShort': 'выкл.',
    'aps.DHCPCaptureFilterEnabled': 'DHCP захват вкл.',
    'aps.DHCPCaptureFilterEnabledShort': 'вкл.',
    'aps.dhcpPacketCapture': 'Захват DHCP пакетов',
    'aps.differentHostAddrMessage': 'Внимание! У выбраных точек разные адреса брокеров.',
    'aps.disabled': 'Выключено',
    'aps.discardErrorsButton': 'Сбросить ошибки для ТД',
    'aps.discardErrorsNotificationErrorsDiscardedSuccessfully': 'Ошибки успешно сброшены',
    'aps.disconnected': 'Отключено',
    'aps.downloadSpeed': 'Ограничение скорости выгрузки',
    'aps.downloadSpeedDescription': 'Ограничение скорости выгрузки, равномерное для всех клиентов',
    'aps.downloadSpeedMax': 'Общий размер ограничения',
    'aps.downloadSpeedMin': 'Ограничение на клиента',
    'aps.downloadSpeedType': 'Единица измерения',
    'aps.dualBand': 'Мультичастотные',
    'aps.editCommonParameters': 'Редактировать общие параметры точек',
    'aps.editingMode': 'Режим редактирования',
    'aps.editLogAndStatSettings': 'Редактирование логов, статистики, Wi-fi Таргет, SNMP и захвата DHCP пакетов',
    'aps.editSelectedCpes': 'Редактировать выбранные точки',
    'aps.editSettingsCpes': 'Редактировать настройки интерфейсов',
    'aps.editTags': 'Добавить теги',
    'aps.empty': 'пусто',
    'aps.emptyWatcher': 'Перезагружать устройство при пустом списке MAC адресов',
    'aps.emptyWatcherInfoTooltip': 'Обязательно для точек TP-Link mr3020 из-за особенностей драйвера',
    'aps.error': 'Ошибка',
    'aps.errorCaptionInCpeEditPanel': 'Ошибка',
    'aps.errorCopyToClipBoardCaption': 'Копировать в буфер обмена',
    'aps.errorMessageNoAvailableCapabilities': 'Нет доступных возможностей',
    'aps.errorMessageNoAvailableCapabilitiesNote': 'Комбинация Band / Mode / Channel не корректна. Отключите, удалите, перезагрузите и заново создайте устройство.',
    'aps.errorMessageNoCPEsModelData': 'Нет данных о моделе выбранных ТД',
    'aps.errorMessageOnlyForAPsWithEqualModel': 'Эта настройка доступна только для ТД одинаковой модели',
    'aps.errorMessageOnlyForConnectedCPEs': 'Эта настройка доступна только для подключенных ТД',
    'aps.errorMessageOnlyForCPEsWithEqualHostAddr': 'Доступно только для ТД с одинаковым адресом брокера',
    'aps.errorMessageOnlyForDisconnectedCPEs': 'Эта настройка доступна только для отключенных ТД',
    'aps.errorMessageOnlyForIpsecConnectedCPEs': 'Эта настройка доступна только для ТД подключенных через IPsec',
    'aps.errorMessageOnlyIfLeastOneCPEHaveError': 'Сброс ошибок доступен только если хотя бы одна из выбранных ТД имеет ошибки',
    'aps.eth0IsOff': 'Интерфейс выключен',
    'aps.eth0StateCaption': 'Состояние Ethernet интерфейса',
    'aps.eth0StateSinceRebootCaption': 'с момента перезагрузки',
    'aps.filterByCompanyDisableSortingByLocationMsg': 'Для сортировки по локациям необходимо выключить сортировку по имени компании',
    'aps.filterByUpdatingStatus': 'Обновляемые',
    'aps.firmwareModeCheck': 'проверять самостоятельно',
    'aps.firmwareModeOff': 'выкл.',
    'aps.firmwareModeOn': 'вкл.',
    'aps.forFollowingCPEs': 'Изменение будет применено для следующих ТД',
    'aps.FWAutoUpgrade': 'Обновление ПО',
    'aps.fwFileUploadedCaption': 'загружен',
    'aps.fwFileUploadSuccessfullyMsgCaption': 'Файл с ПО успешно загружен',
    'aps.fwSelectFirmwareCaption': 'Выберите файл с ПО',
    'aps.fwUpgrade': 'Обновление ПО',
    'aps.FWversion': 'Версия ПО',
    'aps.general': 'Основные',
    'aps.getLogsFromCpe': 'Получить лог с ТД',
    'aps.ghz': 'ГГц',
    'aps.goBackToClients': 'Назад к клиентам',
    'aps.goBackToCpeStatistic': 'Назад к статистике ТД',
    'aps.Guestcontrol': 'Гостевой контроль',
    'aps.header': 'Точки доступа',
    'aps.hideDescription': 'Скрыть описание',
    'aps.hostAddr': 'Адрес брокера',
    'aps.Input': 'Вход',
    'aps.inputForChangeDefaultPlaceholder': 'Введите для изменения значения по умолчанию',
    'aps.inputForChangeDefaultServerPlaceholder': 'Введите для изменения сервера по умолчанию',
    'aps.inputForChangeDefaultServerPortPlaceholder': 'Введите для изменения порта по умолчанию',
    'aps.interfaceDisabled': 'Интерфейс выключен',
    'aps.interfaces': 'Интерфейсы',
    'aps.interfaceType': 'Тип интерфейса',
    'aps.ipaddr': 'Адрес локальной сети',
    'aps.IPsec': 'IPsec',
    'aps.IPsecModalLabel': 'Выберите действие',
    'aps.IPsecTurnOff': 'Выключить',
    'aps.IPsecTurnOn': 'Включить',
    'aps.IPsecTurnOnUnencrypted': 'Включить без шифрования',
    'aps.isCpeModelEqualRTBR24WFN2Ev61ErrorMsgForLan2': 'Нельзя установить VLAN 0 при выбранном LAN 2 для точек Rotek RT-BR24-WFN2E v6.1',
    'aps.isCpeModelEqualRTBR24WFN2Ev61InfoMsgForLan2': 'Нельзя установить VLAN 0 при выбранном LAN 2 для точек Rotek RT-BR24-WFN2E v6.2',
    'aps.isSystemWlanError': 'При выставлении системного VLAN точка доступа может работать некорректно',
    'aps.lastError': 'Проблемные',
    'aps.lbs': 'Wi-Fi таргет',
    'aps.lbsConfig': 'Конфигурация Wi-Fi таргет',
    'aps.LBSFilterMode': 'Режим ограничения Wi-Fi таргет',
    'aps.legacyRates': 'Поддержка 802.11b',
    'aps.limitperWlan': 'Лимит на каждую Wi-Fi сеть',
    'aps.localAddr': 'Адрес локального туннеля',
    'aps.location': 'Локация',
    'aps.locationChangeHint': 'При изменении локации сброситься выбор всех сетей Wi-Fi, выбранных для интерфейсов',
    'aps.Log': 'Лог',
    'aps.log': 'Лог',
    'aps.logConfig': 'Конфигурация логов',
    'aps.logFromCpe': 'Лог с ТД',
    'aps.logLevel': 'Уровень логирования',
    'aps.logLevelMode': 'ON - дебаг режим, OFF - обычный режим',
    'aps.macaddr': 'MAC',
    'aps.massRegistrationMode': 'Массовое добавление ТД',
    'aps.maxCacheQueue': 'Сколько MAC адресов хранить в оффлайне',
    'aps.maxCacheQueueCaption': 'когда точка доступа не подключена к контроллеру',
    'aps.maxCacheQueueShort': 'длина кэша очереди',
    'aps.maxClients': 'Ограничение кол-ва клиентов',
    'aps.maxinactivityAboutModalText': 'Таймаут неактивности это max inactivity timeout из конфига hostapd',
    'aps.maxinactivityAboutModalTitle': 'О таймауте неактивности',
    'aps.maxinactivityPlaceholder': 'Введите период в сек. (0 для знач. по умолчанию в 180 сек.)',
    'aps.maxinactivityTitle': 'Таймаут неактивности',
    'aps.maxinactivityTooltip': 'Таймаут неактивности это max inactivity timeout из конфига hostapd. Кликните, чтобы узнать больше.',
    'aps.maxQuiet': 'Число пустого сбора перед перезагрузкой',
    'aps.maxQuietCaption': 'через сколько пустых попыток перезагружать',
    'aps.maxQuietShort': 'длина очереди',
    'aps.modalAddTagPlaceHolder': 'Добавить тег',
    'aps.modalAddUUIDPlaceHolder': 'Добавить UUID',
    'aps.modalBeAttention': 'Будьте внимательны!',
    'aps.modalBeAttentionFirst': 'При принудительном обновлении точек доступа режим автоматического обновления будет отключен.',
    'aps.modalBeAttentionSecond': 'Если загруженное ПО такой же версии, как на выбранной точке доступа, то обновления не произойдет.',
    'aps.modalBrokerAddress': 'Адрес брокера',
    'aps.modalBrokerAddressPlaceHolder': 'Введите чтобы изменить адрес брокера по умолчанию',
    'aps.modalConfigFromTitle': 'Текущая конфигурация из',
    'aps.modalCurrentFirmwareVersion': 'Текущая версия ПО',
    'aps.modalDisabled': 'Выключено',
    'aps.modalEnabled': 'Включено',
    'aps.modalEnterTemplateNamePlaceHolder': 'Введите имя шаблона',
    'aps.modalFirewallTitle': 'Брандмауэр',
    'aps.modalFirmwareFileInfo': 'Информация о файле ПО',
    'aps.modalFirmwareFileInfoSize': 'Размер',
    'aps.modalLocationTitle': 'Локация',
    'aps.modalLogsTitle': 'Логи',
    'aps.modalModelTitle': 'Модель',
    'aps.modalNo': 'Нет',
    'aps.modalNoUpdatesAvailable': 'Нет доступных обновлений',
    'aps.modalNoWLANs': 'Нет сетей Wi-Fi',
    'aps.modalSelectTemplatePlaceHolder': 'Выберите шаблон',
    'aps.modalStatTitle': 'Статистика',
    'aps.modalTemplateDescriptionPlaceHolder': 'Введите описание',
    'aps.modalUploadFWfile': 'Загрузить файл с ПО',
    'aps.modalUUIDOptionalTitle': 'UUID (опционально)',
    'aps.modalWiFiRadarTitle': 'Wi-Fi таргет',
    'aps.modalWLANsTitle': 'Сети Wi-Fi',
    'aps.modalYes': 'Да',
    'aps.mode': 'Режим',
    'aps.model': 'Модель',
    'aps.multiEdit': 'Мультиредактирование',
    'aps.multiEditMode': 'Режим мультиредактирования',
    'aps.name': 'Имя',
    'aps.nasIp': 'NAS IP адрес',
    'aps.natAccess': 'Доступ к точке из NAT',
    'aps.natNetworkInfoMsg': 'Не используйте 10.9.0.1/16 для ТД с IPsec',
    'aps.natNetworkIpaddr': 'IP для NAT сети',
    'aps.natNetworkIpaddrPlaceholder': 'Введите IP для NAT сети',
    'aps.natNetworkNetmask': 'Маска для NAT сети',
    'aps.natNetworkNetmaskPlaceholder': 'Введите маску для NAT сети',
    'aps.noDataToDisplay': 'Нет данных для отображения',
    'aps.none': 'нет',
    'aps.notActive': 'Не активные',
    'aps.noTags': 'Нет тэгов',
    'aps.notConnected': 'Не подключено',
    'aps.notificationCPECreatedSuccessfully': 'Точка доступа успешно создана',
    'aps.notificationCPEOperationSuccessfull': 'Успешно',
    'aps.notificationCPEUpdatedSuccessfully': 'Точка доступа успешно обновлена',
    'aps.notificationLocationSuccessfullyChanged': 'Локация успешно изменена',
    'aps.notificationTemplateCreatedSuccessfully': 'Шаблон успешно создан',
    'aps.ntpServerAddressPlaceholder': 'адрес сервера (IP или домен)',
    'aps.ntpSettingsEnable': 'Включить NTP',
    'aps.ntpSettingsHeader': 'NTP',
    'aps.ntpSettingsServersAdd': 'Добавить',
    'aps.ntpSettingsServersAlreadyAdded': 'Сервер уже добавлен',
    'aps.ntpSettingsServersClear': 'Очистить',
    'aps.ntpSettingsServersDelete': 'Удалить',
    'aps.ntpSettingsServersHeader': 'NTP cерверы',
    'aps.ntpSettingsServersListCounter': 'Выбрано NTP серверов',
    'aps.offline': 'офлайн',
    'aps.ok': 'онлайн',
    'aps.oneBand': 'Одночастотные',
    'aps.Output': 'Выход',
    'aps.OutputWarning': 'Выберите хотя бы одну опцию',
    'aps.OutputWarningForNonRootLocationUsers': 'Включите NAT',
    'aps.pending': 'ожидание',
    'aps.Ports': 'Порты',
    'aps.positionBlockCoords': 'Координаты',
    'aps.positionBlockCoordsLat': 'шир.',
    'aps.positionBlockCoordsLatFull': 'Широта',
    'aps.positionBlockCoordsLng': 'долг.',
    'aps.positionBlockCoordsLngFull': 'Долгота',
    'aps.positionBlockCopyFromLocationButtonCaption': 'Скопировать из',
    'aps.positionBlockCopyFromLocationButtonInfo': 'Позволяет скопировать координаты (широту и долготу) из локации, в которой находится точка доступа',
    'aps.positionBlockCopyFromLocationCaption': 'Копирование из локации',
    'aps.positionBlockCopyFromSelectedLocationButtonCaption': 'Скопировать из выбранной локации',
    'aps.positionBlockCopyFromSelectedLocationButtonInfo': 'Позволяет скопировать координаты (широту и долготу) из выбранной локации',
    'aps.positionBlockEnterAddressToSearch': 'Введите адрес для поиска координат',
    'aps.positionBlockForMultiEditButtonCaption': 'Установить местоположение',
    'aps.positionBlockHeader': 'Расположение',
    'aps.positionBlockSearchAddressCaption': 'Поиск координат по адресу',
    'aps.positionBlockSelectLocation': 'Выберите локацию',
    'aps.radar': 'Wi-Fi таргет',
    'aps.rateControl': 'Контроль скорости',
    'aps.readOnlyMode': 'Режим чтения',
    'aps.rebootCpe': 'Перезагрузка ТД',
    'aps.rebootCpeAgent': 'Перезагрузка агента ТД',
    'aps.rebootCpeAgentModalText': 'Пожалуйста, подтвердите перезагрузку агента ТД',
    'aps.rebootCpeConfirm': 'Подтвердить перезагрузку ТД',
    'aps.rebootCpeRedundant': 'Перезагрузка через резервный канал',
    'aps.rebootCpeRedundantModalText': 'Пожалуйста, подтвердите перезагрузку через резервный канал',
    'aps.rebooting': 'перезагрузка',
    'aps.recommendationCaptionInCpeEditPanel': 'Рекомендация',
    'aps.Redirect': 'Редирект',
    'aps.register': 'Добавить',
    'aps.registerCPE': 'Добавление точки доступа',
    'aps.registerCpeExample': 'Пример',
    'aps.registerCpeFileTip': 'Используйте .csv файл',
    'aps.registerCpeRegisterFollowCPEs': 'Добавить следующие ТД',
    'aps.registerCPEs': 'Добавление точек доступа',
    'aps.registerDescription': '',
    'aps.registerFollowCPEs': 'Добавить следующие ТД?',
    'aps.reportPeriod': 'Период отчета (в сек)',
    'aps.resetCpe': 'Сброс ТД',
    'aps.resetCpeConfirm': 'Подтвердить сброс ТД',
    'aps.resetting': 'сброс',
    'aps.restartCpeAgent': 'Перезапустить агент ТД',
    'aps.rrmInfoTooltip': 'RRM - алгоритм управления радиоресурсами',
    'aps.save': 'Сохранить',
    'aps.search': 'Поиск',
    'aps.selectChannelsLessThanForCorrectWork': 'Для корректной работы выбирайте каналы до',
    'aps.selectChannelsMoreThanForCorrectWork': 'Для корректной работы выбирайте каналы после',
    'aps.selectedAndWLANtunnelsAreNotMatch': 'Выбранный туннель и туннель выбранной WLAN не совпадают',
    'aps.selectHostAddress': 'выбрать',
    'aps.sendConfigCmd': 'Отправить команду настройки',
    'aps.setTimeUsingNTP': 'Установить время с помощью NTP',
    'aps.setTimeUsingNTPEnterServerAddress': 'Введите адрес сервера (IP или домен)',
    'aps.setTimeUsingNTPSuccessMsg': 'Время успешно установлено',
    'aps.showCurrentClients': 'Показать текущих клиентов',
    'aps.showCurrentStats': 'Показать текущую статистику',
    'aps.showCurrentStatsBytes': 'Байты',
    'aps.showCurrentStatsGbytes': 'Гбайт',
    'aps.showCurrentStatsInterfacesHeader': 'Интерфейсы ТД',
    'aps.showCurrentStatsInterfacesInterfaceNameTableCaption': 'Интерфейс ТД',
    'aps.showCurrentStatsInterfacesInterfaceTableHeaderCaption': 'Интерфейс',
    'aps.showCurrentStatsInterfacesTxRxChartRx': 'Входящий трафик',
    'aps.showCurrentStatsInterfacesTxRxChartTitle': 'Входящий и исходящий трафик для',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipB': 'Байт',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipGB': 'Гбайт',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipKB': 'Кбайт',
    'aps.showCurrentStatsInterfacesTxRxChartTooltipMB': 'Мбайт',
    'aps.showCurrentStatsInterfacesTxRxChartTx': 'Исходящий трафик',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleB': 'Байт',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleGB': 'Гбайт',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleKB': 'Кбайт',
    'aps.showCurrentStatsInterfacesTxRxChartYAxisTitleMB': 'Мбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartRx': 'Входящая дельта трафика',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTitle': 'Входящая и исходящая дельта трафика для',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltip': '#Н/Д',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipB': 'Байт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipGB': 'Гбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipKB': 'Кбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipMB': 'Мбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartTx': 'Исходящая дельта трафика',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB': 'Байт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleGB': 'Гбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleKB': 'Кбайт',
    'aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleMB': 'Мбайт',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartRx': 'Входящая скорость',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTitle': 'Входящая и исходящая скорость для',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB': 'Байт/с',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipGB': 'Гбайт/с',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipKB': 'Кб/с',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipMB': 'Мбайт/c',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartTx': 'Исходящая скорость',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB': 'Байт/c',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleGB': 'Гбайт/с',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleKB': 'Кбайт/с',
    'aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleMB': 'Мбайт/с',
    'aps.showCurrentStatsKbytes': 'Кбайт',
    'aps.showCurrentStatsMbytes': 'Мбайт',
    'aps.showCurrentStatsModalTitle': 'Текущая статистика для ТД',
    'aps.showCurrentStatsNoDataForCPE': 'Нет данных для данной ТД',
    'aps.showCurrentStatsRamChartChartTitle': 'Память',
    'aps.showCurrentStatsRamChartFreeRam': 'Свободная память',
    'aps.showCurrentStatsRamChartTooltip': 'Мбайт',
    'aps.showCurrentStatsRamChartTotalRam': 'Всего памяти',
    'aps.showCurrentStatsRamChartYAxisTitle': 'Мбайт',
    'aps.showCurrentStatsUnits': 'Единицы измерения',
    'aps.showCurrentStatsUptimeCaption': 'Время работы',
    'aps.showDescription': 'показать описание',
    'aps.singleRegistrationMode': 'Одиночный режим',
    'aps.snmp': 'SNMP',
    'aps.snmpCommunity': 'Community',
    'aps.snmpinterfaces': 'Интерфейсы',
    'aps.snmpinterfacesListItems': 'В списке интерфейсов',
    'aps.snmpListenInterface': 'Интерфейс прослушивания',
    'aps.snmpLocation': 'Локация',
    'aps.sortByConnectionnOrDisconnectionTime': 'Сортировка по времени подкл./откл.',
    'aps.sortByConnectionnOrDisconnectionTimeButton': 'По времени подкл./откл.',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeCaption': 'Временной промежуток',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterButton': 'Включить',
    'aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption': 'Фильтр по промежутку времени',
    'aps.sortByConnectionnOrDisconnectionTimeNoSort': 'Нет',
    'aps.sortByFirstConnection': 'По первому подключению',
    'aps.sortByLastConnection': 'По последнему подключению',
    'aps.sortByLastDisconnection': 'По последнему отключению',
    'aps.statAndRadarCaptionInCpeInfoBlock': 'Статистика, Wi-Fi Таргет, Логи, SNMP и захват DHCP пакетов',
    'aps.statAndRadarCaptionInCpeInfoBlockWithoutWifiTarget': 'Статистика, Логи, SNMP и захват DHCP пакетов',
    'aps.statAndRadarCaptionInModalBlock': 'Статистика, Wi-Fi Таргет, Логи, SNMP, NTP и захват DHCP пакетов',
    'aps.statAndRadarCaptionInModalBlockWithoutWifiTarget': 'Статистика, Логи, SNMP и захват DHCP пакетов',
    'aps.statConfig': 'Конфигурация статистики',
    'aps.statConfigCapture': 'период статистики ТД и клиентов',
    'aps.Stats': 'Статистика',
    'aps.syncTime': 'Синхронизировать время',
    'aps.syncTimeButton': 'Синхронизировать',
    'aps.syncTimeForCPES': 'Синхронизация времени будет проведена для следующих ТД',
    'aps.syncTimeSuccessMsg': 'Время успешно синхронизировано',
    'aps.systemVlans': 'системные VLANы',
    'aps.tableItemsOf': 'из',
    'aps.tableLoad': 'Загрузить',
    'aps.tableOutOf': 'ТД из',
    'aps.tableTab_clients': 'Клиенты',
    'aps.tableTab_firstConnection': 'Перв. подкл.',
    'aps.tableTab_five': '5 ГГц',
    'aps.tableTab_interface': 'Интерфейсы',
    'aps.tableTab_lastConnection': 'Посл. подкл.',
    'aps.tableTab_lastDisconnection': 'Посл. откл.',
    'aps.tableTab_location': 'Локация, компания',
    'aps.tableTab_model': 'Модель',
    'aps.tableTab_name': 'Имя',
    'aps.tableTab_radar': 'Wi-Fi таргет',
    'aps.tableTab_status': 'Статус',
    'aps.tableTab_tags': 'Теги',
    'aps.tableTab_two': '2.4 ГГц',
    'aps.tableTab_vlan': '#Н/Д',
    'aps.tableTab_wired': 'Проводной',
    'aps.tableTab_wlans': 'Сети Wi-Fi',
    'aps.tableView': '',
    'aps.templates': 'Шаблоны',
    'aps.Tunnel': 'Туннель',
    'aps.tunnelAndWLANtunnelsAreNotMatchForCPETable': 'Туннель и туннель WLAN не совпадают в одном из конфигов проводного интерфейса',
    'aps.tunnels.captionInCpeModal': 'Туннели',
    'aps.tunnels.noTunnels': 'Нет туннелей',
    'aps.tunnelTypeForWLANInfo': 'Туннель',
    'aps.txPower': 'Мощность',
    'aps.txPowerAdjust': 'Мощность сигнала',
    'aps.type': 'Тип',
    'aps.unlim': 'нет',
    'aps.update': 'Обновить',
    'aps.updating': 'обновление',
    'aps.upgrading': 'обновление ПО',
    'aps.wanBlockAccess': 'Запрет доступа через WAN',
    'aps.warnStatistics': 'Пожалуйста, обратите внимание, что удаление точки доступа приведёт к {0}.',
    'aps.warnStatisticsLoss': 'потере статистики',
    'aps.whitelist': 'белый список',
    'aps.width': 'Ширина',
    'aps.wifiLock': 'Заблокировать изменение настройки Wi-Fi',
    'aps.wifiRadar': 'Wi-Fi таргет',
    'aps.wired': 'Проводные',
    'aps.WiredConfig': 'Проводная конфигурация',
    'aps.wiredConfigAccounting': 'Подсчет статистики',
    'aps.wiredConfigAccountingEnable': 'Включить подсчет статистики',
    'aps.wiredConfigFakeWlan': 'Привязать статистику к WLAN',
    'aps.wiredNoTunel': 'Нет',
    'aps.wiredPorts': 'Порты',
    'aps.wiredSet0forNative': 'установите 0 для работы без тега',
    'aps.wiredTunnel': 'Тунель',
    'aps.wiredVLANNAtive': 'без тега',
    'aps.wlans': 'Сети Wi-Fi',
    'aps.wlansCaption': 'Сети Wi-Fi',
    'aps.wlansSelectorHint': 'Доступны только сети Wi-Fi из локации, которая выбрана для ТД',
    'aps.wlansSelectorPlaceHolder': 'Выбрать cети Wi-Fi',
    'aps.wlansSelectorTip': 'Нажмите enter для выбора',
    'aps.WLANsstatus': 'Состояние Wi-Fi сетей',
    'aps.WPA3AddToNoWPA3CPEError': 'Нельзя добавить WPA3 Wi-FI сеть на неподдерживающую WPA3 ТД',
    'aps.WPA3AddToNoWPA3CPEMultiEditError': 'Некоторые из выбранных ТД не поддерживают WPA3. Нельзя добавить WPA3  Wi-FI сеть на неподдерживающую WPA3 ТД.',
    'aps.WPA3Capable': 'WPA3-совместимо',
    'aps.zeroVLANIfNativeVlanOnSinglePortInfoMsg': 'Не устанавливайте VLAN 0 на интерфейс, с которого устройство подключается к сети. Это приведет к потере устройства.',
    'clientDistance.chartDistance': 'Дистанция (в метрах)',
    'clientDistance.chartHeader': 'Удаленность пользователя',
    'clientDistance.noTags': 'нет тегов',
    'clientDistance.tableItemsOf': 'из',
    'clientDistance.tableView': '',
    'clients.auth': 'Аутентификация',
    'clients.authDevice': 'устройство',
    'clients.authInfo': 'Информация об аутентификации',
    'clients.authNo': 'Нет',
    'clients.authorization': 'Авторизация',
    'clients.authorized': 'Авторизованные',
    'clients.authOs': 'ОС',
    'clients.authOsVersion': 'версия ОС',
    'clients.authTable': 'Аутентификация',
    'clients.authType': 'тип',
    'clients.authUseragent': 'useragent',
    'clients.authUsername': 'имя',
    'clients.camera': 'Камера',
    'clients.channel': 'Канал',
    'clients.chipManufacturer': 'Производитель чипа',
    'clients.clientMac': 'MAC клиента',
    'clients.clients': 'Клиенты',
    'clients.clientsOutOf': 'Клиентов из',
    'clients.connected': 'Подключенные',
    'clients.cpe': 'ТД',
    'clients.CPE': 'ТД',
    'clients.disconnected': 'Отключенные',
    'clients.editPanelCamera': 'Камера',
    'clients.editPanelCameraRtspStream': 'RTSP поток',
    'clients.editPanelCameraRtspStreamLinkPlaceHolder': 'Введите ссылку для RTSP Потока',
    'clients.editPanelDescriptionPlaceHolder': 'Введите описание',
    'clients.editPanelOther': 'Беспроводной',
    'clients.editPanelWired': 'Проводной',
    'clients.eventsAndRssiButtonCaption': 'События и RSSI',
    'clients.eventsAndRssiTooltipCaption': 'Позволяет посмотреть события и RSSI по выбранному клиенту на графике',
    'clients.eventsButtonCaption': 'События',
    'clients.disconnectAndDeauthorizeButtonCaption': 'Отключить и деавторизовать',
    'clients.disconnectAndDeauthorizeSuccessMsg': 'Клиент успешно отключен и деавторизован',
    'clients.eventsTableCaption': 'События',
    'clients.eventsTooltipCaption': 'Позволяет перейти в раздел "События" и просмотреть события по выбранному клиенту',
    'clients.frequency': 'Частота',
    'clients.goBackToClientsStatistic': 'Назад в "Статистика клиентов"',
    'clients.healthFair': 'Неплохо',
    'clients.healthGood': 'Хорошо',
    'clients.healthInactive': 'Неактивно',
    'clients.healthOffline': 'Оффлайн',
    'clients.healthPoor': 'Плохо',
    'clients.healthScoresInfoTooltip': 'Нажмите, чтобы посмотреть больше информации о статусе работоспособности',
    'clients.healthStatusButtonsInfo': 'только для фильтра "Подключенные"',
    'clients.healthStatusModalInfoCaption': 'Статус работоспособности может принимать следующие значения',
    'clients.healthStatusModalInfoTitle': 'О статусе работоспособности',
    'clients.itemsOf': 'из',
    'clients.lastConnected': 'Последнее подключение',
    'clients.load': 'Загрузить',
    'clients.noConnectedClientsToDisplay': 'Нет подключенных клиентов для отображения',
    'clients.noConnectedClientsToDisplayWhenCPESelected': 'Нет подключенных клиентов для отображения для данной ТД',
    'clients.notificationClientUpdated': 'Клиент успешно обновлен',
    'clients.other': 'Беспроводные',
    'clients.scores': 'балл.',
    'clients.showAllClients': 'Показать всех клиентов',
    'clients.showAllClientsWhenCPESelected': 'Показать всех клиентов для данной ТД',
    'clients.tableInterface': 'Интерфейс',
    'clients.tableNo': 'Нет',
    'clients.tableNoData': 'нет данных',
    'clients.tableNotFound': 'не найдено',
    'clients.tableTab_channel': 'Канал',
    'clients.tableTab_cpe': 'ТД (частота / канал / RSSI)',
    'clients.tableTab_description': 'Описание',
    'clients.tableTab_frequency': 'Частота',
    'clients.tableTab_healthScore': 'Статус работоспособности',
    'clients.tableTab_mac': 'MAC',
    'clients.tableTab_rssi': 'RSSI',
    'clients.tableTab_state': 'Последнее Состояние',
    'clients.tableTab_type': 'Тип',
    'clients.tableTab_wlan': 'Сеть Wi-Fi',
    'clients.view': '',
    'clients.wired': 'Проводные',
    'clients.wlan': 'Сеть Wi-Fi',
    'clients.WLAN': 'Сеть Wi-Fi',
    'clientsRF.authDevice': 'устройство',
    'clientsRF.authInfo': 'Информация об аутентификации',
    'clientsRF.authNo': 'Нет',
    'clientsRF.authOs': 'ОС',
    'clientsRF.authOsVersion': 'версия ОС',
    'clientsRF.authTable': 'Аутентификация',
    'clientsRF.authType': 'тип',
    'clientsRF.authUseragent': 'useragent',
    'clientsRF.authUsername': 'имя',
    'clientsRF.clientRF': 'Клиентский RF',
    'clientsRF.clientRFChartTitle': 'Состояние Wi-Fi клиента',
    'clientsRF.clientRFP': 'Клиентский RFP',
    'clientsRF.clientRFSidebar': 'Состояние Wi-Fi клиента',
    'clientsRF.clientRFTableTitle': 'Состояние Wi-Fi клиента',
    'clientsRF.expectedThroughput': 'Ожидаемая пропускная способность',
    'clientsRF.noData': 'нет данных',
    'clientsRF.noise': 'Шум',
    'clientsRF.numberOfEntries': 'Количество записей',
    'clientsRF.paginationClientsOutOf': 'Клиентов из',
    'clientsRF.paginationLoad': 'Загрузить',
    'clientsRF.rating': 'Рейтинг',
    'clientsRF.rx': 'Прием',
    'clientsRF.signal': 'Сигнал',
    'clientsRF.signalP10': 'Сигнал P10',
    'clientsRF.signalP90': 'Сигнал P90',
    'clientsRF.snrAvg': 'SNR среднее',
    'clientsRF.table': 'Таблица',
    'clientsRF.tableItemsOf': 'из',
    'clientsRF.tableView': '',
    'clientsRF.tx': 'Передача',
    'configExportImport.chooseAFile': 'Выберите файл',
    'configExportImport.configExportHeader': 'Экспорт конфигурации',
    'configExportImport.configImportHeader': 'Импорт конфигурации',
    'configExportImport.configSuccessfullyImported': 'Конфигурация успешно импортирована',
    'configExportImport.downloadButtonCaption': 'Скачать файл',
    'configExportImport.exportButtonCaption': 'Экспортировать',
    'configExportImport.fileImportCpation': 'Файл для импорта',
    'configExportImport.fileParsingError': 'Ошибка при разборе выбранного файла',
    'configExportImport.forLocation': 'Импорт для локации',
    'configExportImport.importButtonCaption': 'Импорт конфигурации',
    'configExportImport.saveToFileError': 'Не удалось сохранить в файл',
    'configExportImport.selectedFile': 'Выбраный файл',
    'configExportImport.viewFileCaption': 'Просмотреть файл',
    'configExportImport.withChildsFalse': 'без дочерних локаций',
    'configExportImport.withChildsTrue': 'с дочерними локациями',
    'controller.accessSettings': 'Настройки доступа',
    'controller.active': 'Активность',
    'controller.addController': 'Добавить контроллер',
    'controller.confirmDelete': 'Подтвердить удаление',
    'controller.controller': 'Контроллер',
    'controller.controllerHeader': 'Контроллер',
    'controller.controllersList': 'Список контроллеров',
    'controller.deleteControllerHeader': 'Удалить контроллер',
    'controller.FWversion': 'Версия ПО',
    'controller.newController': 'Новый контроллер',
    'controller.notificationControlleOperationSuccessfull': 'Успешно',
    'controller.notificationControllerCreatedSuccessfully': 'Контроллер успешно создан',
    'controller.notificationControllerDeletedSuccessfully': 'Контроллер успешно удален',
    'controller.notificationControllerUpdatedSuccessfully': 'Контроллер успешно обновлен',
    'controller.password': 'Пароль',
    'controller.serialNumber': 'Серийный номер',
    'controller.SSHkey': 'SSH ключ',
    'controller.status': 'Статус',
    'controller.username': 'Имя пользователя',
    'controller.vendor': 'Производитель',
    'controllerDateTimeSettings.changeDateAndTime': 'Изменить дату и время',
    'controllerDateTimeSettings.date': 'Дата',
    'controllerDateTimeSettings.dateAndTime': 'Дата и время',
    'controllerDateTimeSettings.refresh': 'Обновить',
    'controllerDateTimeSettings.source': 'Источник',
    'controllerDateTimeSettings.syncDateAndTime': 'Синхронизировать время и часовой пояс',
    'controllerDateTimeSettings.syncDateAndTimeConfirm': 'Вы уверены, что хотите синхронизировать время и часовой пояс с компьютером?',
    'controllerDateTimeSettings.dateTimeDisplayAsOnControllerHint': 'Отображается время и таймзона, установленные на контроллере',
    'controllerDateTimeSettings.useDateAndTimeFromBrowser': 'Синхронизировать время с вашим компьютером',
    'controllerDateTimeSettings.timezoneAcronym': 'Таймзона',
    'controllerDateTimeSettings.timezoneSelect': 'Выбрать таймзону',
    'controllerDateTimeSettings.offsetMinutes': 'Минуты',
    'controllerDateTimeSettings.offsetMinutesHint': '0, 30 или 45',
    'controllerDateTimeSettings.offsetHours': 'Часы',
    'controllerDateTimeSettings.offset': 'Смещение от UTC',
    'controllerDateTimeSettings.time': 'Время',
    'controllerDateTimeSettings.timezone': 'Часовой пояс',
    'countries.00': ' Мир',
    'countries.AD': ' Андорра',
    'countries.AE': ' Объединенные Арабские Эмираты',
    'countries.AF': ' Афганистан',
    'countries.AG': ' Антигуа и Барбуда',
    'countries.AI': ' Ангилья',
    'countries.AL': ' Албания',
    'countries.AM': ' Армения',
    'countries.AN': ' Нидерландские Антильские острова',
    'countries.AO': ' Ангола',
    'countries.AQ': ' Антарктида',
    'countries.AR': ' Аргентина',
    'countries.AS': ' Американское Самоа',
    'countries.AT': ' Австрия',
    'countries.AU': ' Австралия',
    'countries.AW': ' Аруба',
    'countries.AX': ' Аландские острова',
    'countries.AZ': ' Азербайджан',
    'countries.BA': ' Босния и Герцеговина',
    'countries.BB': ' Барбадос',
    'countries.BD': ' Бангладеш',
    'countries.BE': ' Бельгия',
    'countries.BF': ' Буркина-Фасо',
    'countries.BG': ' Болгария',
    'countries.BH': ' Бахрейн',
    'countries.BI': ' Бурунди',
    'countries.BJ': ' Бенин',
    'countries.BL': ' Сен-Бартелеми',
    'countries.BM': ' Бермуды',
    'countries.BN': ' Бруней',
    'countries.BO': ' Боливия',
    'countries.BR': ' Бразилия',
    'countries.BS': ' Багамы',
    'countries.BT': ' Бутан',
    'countries.BV': ' Остров Буве',
    'countries.BW': ' Ботсвана',
    'countries.BY': ' Беларусь',
    'countries.BZ': ' Белиз',
    'countries.CA': ' Канада',
    'countries.CC': ' Кокосовые острова (Килинг)',
    'countries.CD': ' Конго',
    'countries.CF': ' Центрально-Африканская Республика',
    'countries.CG': ' Конго',
    'countries.CH': ' Швейцария',
    'countries.CI': ' Кот-д\\Ивуар',
    'countries.CK': ' Острова Кука',
    'countries.CL': ' Чили',
    'countries.CM': ' Камерун',
    'countries.CN': ' Китай',
    'countries.CO': ' Колумбия',
    'countries.CR': ' Коста-Рика',
    'countries.CU': ' Куба',
    'countries.CV': ' Кабо-Верде',
    'countries.CX': ' Остров Рождества',
    'countries.CY': ' Кипр',
    'countries.CZ': ' Чешская Республика',
    'countries.DE': ' Германия',
    'countries.DJ': ' Джибути',
    'countries.DK': ' Дания',
    'countries.DM': ' Доминика',
    'countries.DO': ' Доминиканская Республика',
    'countries.DZ': ' Алжир',
    'countries.EC': ' Эквадор',
    'countries.EE': ' Эстония',
    'countries.EG': ' Египет',
    'countries.EH': ' Западная Сахара',
    'countries.ER': ' Эритрея',
    'countries.ES': ' Испания',
    'countries.ET': ' Эфиопия',
    'countries.FI': ' Финляндия',
    'countries.FJ': ' Фиджи',
    'countries.FK': ' Фолклендские острова',
    'countries.FM': ' Микронезия',
    'countries.FO': ' Фарерские острова',
    'countries.FR': ' Франция',
    'countries.GA': ' Габон',
    'countries.GB': ' Великобритания',
    'countries.GD': ' Гренада',
    'countries.GE': ' Грузия',
    'countries.GF': ' Французская Гвиана',
    'countries.GG': ' Гернси',
    'countries.GH': ' Гана',
    'countries.GI': ' Гибралтар',
    'countries.GL': ' Гренландия',
    'countries.GM': ' Гамбия',
    'countries.GN': ' Гвинея',
    'countries.GP': ' Гваделупа',
    'countries.GQ': ' Экваториальная Гвинея',
    'countries.GR': ' Греция',
    'countries.GS': ' Южная Георгия',
    'countries.GT': ' Гватемала',
    'countries.GU': ' Гуам',
    'countries.GW': ' Гвинея-Бисау',
    'countries.GY': ' Гайана',
    'countries.HK': ' Гонконг',
    'countries.HM': ' Острова Херд и Макдональд',
    'countries.HN': ' Гондурас',
    'countries.HR': ' Хорватия',
    'countries.HT': ' Гаити',
    'countries.HU': ' Венгрия',
    'countries.ID': ' Индонезия',
    'countries.IE': ' Ирландия',
    'countries.IL': ' Израиль',
    'countries.IM': ' Остров Мэн',
    'countries.IN': ' Индия',
    'countries.IO': ' Острова Чагос',
    'countries.IQ': ' Ирак',
    'countries.IR': ' Иран',
    'countries.IS': ' Исландия',
    'countries.IT': ' Италия',
    'countries.JE': ' Джерси',
    'countries.JM': ' Ямайка',
    'countries.JO': ' Иордания',
    'countries.JP': ' Япония',
    'countries.KE': ' Кения',
    'countries.KG': ' Кыргызстан',
    'countries.KH': ' Камбоджа',
    'countries.KI': ' Кирибати',
    'countries.KM': ' Коморские острова',
    'countries.KN': ' Сент-Китс и Невис',
    'countries.KP': ' Северная Корея',
    'countries.KR': ' Южная Корея',
    'countries.KW': ' Кувейт',
    'countries.KY': ' Каймановы острова',
    'countries.KZ': ' Казахстан',
    'countries.LA': ' Лаос',
    'countries.LB': ' Ливан',
    'countries.LC': ' Сент-Люсия',
    'countries.LI': ' Лихтенштейн',
    'countries.LK': ' Шри-Ланка',
    'countries.LR': ' Либерия',
    'countries.LS': ' Лесото',
    'countries.LT': ' Литва',
    'countries.LU': ' Люксембург',
    'countries.LV': ' Латвия',
    'countries.LY': ' Ливийская джамахирия',
    'countries.MA': ' Марокко',
    'countries.MC': ' Монако',
    'countries.MD': ' Молдова',
    'countries.ME': ' Черногория',
    'countries.MF': ' Сен-Мартен (владение Франции)',
    'countries.MG': ' Мадагаскар',
    'countries.MH': ' Маршалловы острова',
    'countries.MK': ' Македония',
    'countries.ML': ' Мали',
    'countries.MM': ' Мьянма',
    'countries.MN': ' Монголия',
    'countries.MO': ' Макао',
    'countries.MP': ' Северные Марианские острова',
    'countries.MQ': ' Мартиника',
    'countries.MR': ' Мавритания',
    'countries.MS': ' Монтсеррат',
    'countries.MT': ' Мальта',
    'countries.MU': ' Маврикий',
    'countries.MV': ' Мальдивы',
    'countries.MW': ' Малави',
    'countries.MX': ' Мексика',
    'countries.MY': ' Малайзия',
    'countries.MZ': ' Мозамбик',
    'countries.NA': ' Намибия',
    'countries.NC': ' Новая Каледония',
    'countries.NE': ' Нигер',
    'countries.NF': ' Остров Норфолк',
    'countries.NG': ' Нигерия',
    'countries.NI': ' Никарагуа',
    'countries.NL': ' Нидерланды',
    'countries.NO': ' Норвегия',
    'countries.NP': ' Непал',
    'countries.NR': ' Науру',
    'countries.NU': ' Ниуэ',
    'countries.NZ': ' Новая Зеландия',
    'countries.OM': ' Оман',
    'countries.PA': ' Панама',
    'countries.PE': ' Перу',
    'countries.PF': ' Французская Полинезия',
    'countries.PG': ' Папуа - Новая Гвинея',
    'countries.PH': ' Филиппины',
    'countries.PK': ' Пакистан',
    'countries.PL': ' Польша',
    'countries.PM': ' Сен-Пьер и Микелон',
    'countries.PN': ' Острова Питкэрн',
    'countries.PR': ' Пуэрто-Рико',
    'countries.PS': ' Палестина',
    'countries.PT': ' Португалия',
    'countries.PW': ' Палау',
    'countries.PY': ' Парагвай',
    'countries.QA': ' Катар',
    'countries.RE': ' Реюньон',
    'countries.RO': ' Румыния',
    'countries.RS': ' Сербия',
    'countries.RU': ' Россия',
    'countries.RW': ' Руанда',
    'countries.SA': ' Саудовская Аравия',
    'countries.SB': ' Соломоновы острова',
    'countries.SC': ' Сейшельские острова',
    'countries.SD': ' Судан',
    'countries.SE': ' Швеция',
    'countries.SG': ' Сингапур',
    'countries.SH': ' Остров Святой Елены',
    'countries.SI': ' Словения',
    'countries.SJ': ' Шпицберген и Ян-Майен',
    'countries.SK': ' Словакия',
    'countries.SL': ' Сьерра-Леоне',
    'countries.SM': ' Сан-Марино',
    'countries.SN': ' Сенегал',
    'countries.SO': ' Сомали',
    'countries.SR': ' Суринам',
    'countries.ST': ' Сан-Томе и Принсипи',
    'countries.SV': ' Сальвадор',
    'countries.SY': ' Сирия',
    'countries.SZ': ' Свазиленд',
    'countries.TC': ' Острова Теркс и Кайкос',
    'countries.TD': ' Чад',
    'countries.TF': ' Французские Южные территории',
    'countries.TG': ' Того',
    'countries.TH': ' Таиланд',
    'countries.TJ': ' Таджикистан',
    'countries.TK': ' Токелау',
    'countries.TL': ' Восточный Тимор',
    'countries.TM': ' Туркменистан',
    'countries.TN': ' Тунис',
    'countries.TO': ' Тонга',
    'countries.TR': ' Турция',
    'countries.TT': ' Тринидад и Тобаго',
    'countries.TV': ' Тувалу',
    'countries.TW': ' Тайвань',
    'countries.TZ': ' Танзания',
    'countries.UA': ' Украина',
    'countries.UG': ' Уганда',
    'countries.UM': ' Малые отдаленные острова США',
    'countries.US': ' Соединенные Штаты Америки',
    'countries.UY': ' Уругвай',
    'countries.UZ': ' Узбекистан',
    'countries.VA': ' Ватикан',
    'countries.VC': ' Сент-Винсент и Гренадины',
    'countries.VE': ' Венесуэла',
    'countries.VG': ' Британские Виргинские острова',
    'countries.VI': ' Американские Виргинские острова',
    'countries.VN': ' Вьетнам',
    'countries.VU': ' Вануату',
    'countries.WF': ' Уоллис и Футуна',
    'countries.WS': ' Самоа',
    'countries.YE': ' Йемен',
    'countries.YT': ' Майотта',
    'countries.ZA': ' ЮАР',
    'countries.ZM': ' Замбия',
    'countries.ZW': ' Зимбабве',
    'dashboard.ads': 'Реклама',
    'dashboard.adsCounter': 'Просмотры',
    'dashboard.adsStatistic': 'Статистика просмотров рекламы',
    'dashboard.adsStatisticCaption': 'за этот месяц, показаны лучшие по показам дни',
    'dashboard.adsStatisticTotal': 'Всего просмотров',
    'dashboard.adsTotal': 'Всего реклам',
    'dashboard.adsType': 'Тип',
    'dashboard.available': 'Доступно',
    'dashboard.cache': 'Кэш',
    'dashboard.configCPEs': 'Статус ТД',
    'dashboard.connected': 'Подключенные',
    'dashboard.connectedCPEs': 'Подключенные ТД',
    'dashboard.connectionsStat': 'Статистика подключений',
    'dashboard.connectionsStatCaption': 'последние 7 дней',
    'dashboard.CPE': 'ТД',
    'dashboard.cpeChartSeriesName': 'Точки доступа',
    'dashboard.cpeStatusChartSeriesName': 'Точки доступа',
    'dashboard.cpu': 'Процессор',
    'dashboard.cpuCores': 'Количество ядер',
    'dashboard.cpuCount': 'Количество процессоров',
    'dashboard.cpuLoad': 'Загрузка процессора',
    'dashboard.empty': 'Пустая',
    'dashboard.ERROR': 'Ошибка',
    'dashboard.Events': 'События',
    'dashboard.free': 'Свободно',
    'dashboard.gb': 'Гбайт',
    'dashboard.goBackToClients': 'Назад к клиентам',
    'dashboard.goBackToDashboard': 'Назад на главную',
    'dashboard.goToCpeSettings': 'Настройки ТД',
    'dashboard.goToCpeStatistic': 'Статистика',
    'dashboard.INFO': 'Информация',
    'dashboard.kb': 'Кбайт',
    'dashboard.last10events': 'События за 24ч',
    'dashboard.load': 'Нагрузка',
    'dashboard.load_core': 'Нагрузка на ядро',
    'dashboard.load1': 'За 1 минуту',
    'dashboard.load15': 'За 15 минут',
    'dashboard.load5': 'За 5 минут',
    'dashboard.locationAddress': 'Адрес',
    'dashboard.locationCreated': 'Создано',
    'dashboard.locationManager': 'Ответственный',
    'dashboard.locationOwner': 'Владелец',
    'dashboard.locations': 'Локации',
    'dashboard.locationsCaption': '3 случайных локации',
    'dashboard.locationsMap': 'Карта локаций',
    'dashboard.locationsMapCaption': 'Показаны только локации с координатами',
    'dashboard.locationsTotal': 'Всего локаций',
    'dashboard.locationsWhereManager': 'Локации, где вы ответственный',
    'dashboard.locationsWhereManagerCaption': 'Показаны только локации, где вы ответственное лицо',
    'dashboard.mb': 'Мбайт',
    'dashboard.memory': 'Память',
    'dashboard.memoryFree': 'Свободно памяти',
    'dashboard.memoryTotal': 'Общая память',
    'dashboard.memoryUsed': 'Использовано памяти',
    'dashboard.model': 'Модель',
    'dashboard.modelCPU': 'Модель процессора',
    'dashboard.mostactiveclients': 'Самые активные клиенты',
    'dashboard.mostactiveCPEs': 'Самые активные ТД',
    'dashboard.mostloadCPEs': 'Самые нагруженные ТД',
    'dashboard.notConnected': 'Отключенные',
    'dashboard.offline': 'Офлайн',
    'dashboard.online': 'Онлайн',
    'dashboard.os': 'ОС',
    'dashboard.radar': 'Wi-Fi таргет',
    'dashboard.received': 'Скачано',
    'dashboard.rule': 'Правило',
    'dashboard.serverInfo': 'Сервер',
    'dashboard.service': 'Сервисы',
    'dashboard.toAds': 'Просмотреть рекламу',
    'dashboard.toAdsStatistic': 'Просмотреть статистику',
    'dashboard.toClients': 'Просмотреть клиентов',
    'dashboard.toConnectionsStat': 'Просмотреть статистику по подключениям',
    'dashboard.toCPEs': 'Просмотреть ТД',
    'dashboard.toEvents': 'Просмотреть события',
    'dashboard.toLocations': 'Просмотреть локации',
    'dashboard.toMarketing': 'Просмотреть аналитику',
    'dashboard.total': 'Общая',
    'dashboard.trafficIn': 'Входящий трафик',
    'dashboard.trafficOut': 'Исходящий трафик',
    'dashboard.transmitted': 'Отправлено',
    'dashboard.updating': 'В процессе',
    'dashboard.uptime': 'Дней работы',
    'dashboard.used': 'Использовано',
    'dashboard.WARNING': 'Предупреждение',
    'DBState.Bytes': 'Байты',
    'DBState.BytesShort': 'Б',
    'DBState.Gbytes': 'Гбайт',
    'DBState.GbytesShort': 'Гб',
    'DBState.Kbytes': 'Кбайт',
    'DBState.KbytesShort': 'Кб',
    'DBState.Mbytes': 'Мбайт',
    'DBState.MbytesShort': 'Мб',
    'DBState.sortingSortByCount': 'По количеству объектов',
    'DBState.sortingSortByNs': 'По имени коллекции',
    'DBState.sortingSortBySize': 'По размеру данных',
    'DBState.sortingSortByStorageSize': 'По размеру данных на диске',
    'DBState.tableColumnCount': 'Количество объектов',
    'DBState.tableColumnNs': 'Имя коллекции',
    'DBState.tableColumnSize': 'Размер данных',
    'DBState.tableColumnStorageSize': 'Размер данных на диске',
    'DBState.tableTitle': 'Состояние БД',
    'DBState.units': 'Единицы измерения',
    'DITStat.cancelSelectAllCpesButtonCaption': 'Очистить все выбранные ТД',
    'DITStat.createDitButton': 'Создать выгрузку ДИТ',
    'DITStat.deleteDitModalHeader': 'Удаление выгрузки ДИТ',
    'DITStat.deleteDitModalText': 'Удаление выгрузки ДИТ ',
    'DITStat.DITStatNotificationCreated': 'Выгрузка статистики ДИТ успешно создана',
    'DITStat.DITStatNotificationDeleted': 'Выгрузка статистики ДИТ успешно удалена',
    'DITStat.DITStatNotificationUpdated': 'Выгрузка статистики ДИТ успешно обновлена',
    'DITStat.filtersForCpeListFiltringForCpeListIsEnabled': 'вкл.',
    'DITStat.filtersForCpeListResetFiltersButton': 'сбросить фильтры',
    'DITStat.filtersForCpeListSwitcherCaption': 'Фильтрация списка ТД',
    'DITStat.filtersForCpeListSwitcherInfoTooltip': 'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации.',
    'DITStat.filtersForCpeListSwitcherLocationCaption': 'Локация точки доступа',
    'DITStat.modalCpeListNoOptionsMsg': 'Нет точек доступа',
    'DITStat.modalCpeListNoOptionsMsgWhenFilterEnabled': 'Нет ТД, соответствующих условиям фильтров',
    'DITStat.modalCpeListNoResultMsg': 'Не найдено ТД с таким именем',
    'DITStat.modalCpeListNoResultMsgWhenFilterEnabled': 'Не найдено ТД с именем, соответствующим условиям фильтров',
    'DITStat.modalCpeListPlaceholder': 'Выберите ТД или введите имя для поиска',
    'DITStat.modalCPEsCaption': 'Точки доступа',
    'DITStat.modalEnebledCaption': 'Включено',
    'DITStat.modalFormatCaption': 'Формат выгрузки',
    'DITStat.modalFTPServerCaption': 'Сервер FTP',
    'DITStat.modalFTPServerPlaceholder': 'Укажите адрес FTP сервера в формате ip:port (например 127.0.0.1:21)',
    'DITStat.modalHeaderForEdit': 'Редактирование выгрузки ДИТ',
    'DITStat.modalHeaderForNew': 'Создание выгрузки ДИТ',
    'DITStat.modalIntervalCaption': 'Интервал выгрузки',
    'DITStat.modalIntervalMinutes': 'в минутах',
    'DITStat.modalIntervalPlaceholder': 'Укажите интервал в минутах для выгрузки статистики',
    'DITStat.modalLoginCaption': 'Имя пользователя FTP сервера',
    'DITStat.modalLoginPlaceholder': 'Укажите имя пользователя FTP сервера',
    'DITStat.modalPasswordCaption': 'Пароль FTP сервера',
    'DITStat.modalPasswordPlaceholder': 'Укажите пароль для FTP сервера',
    'DITStat.modalPrivateKeyCaption': 'Приватный ключ для SFTP сервера',
    'DITStat.modalPrivateKeyChangeButton': 'Изменить приватный ключ',
    'DITStat.modalPrivateKeyPlaceholder': 'Укажите приватный ключ для SFTP сервера',
    'DITStat.modalSelectedCPEsCaption': 'Выбрано',
    'DITStat.modalSelectedCpesLengthError': 'Выберите хотя бы одну точку доступа',
    'DITStat.modalSFTPAuthTypeCaption': 'Выберите тип аутентификации для SFTP сервера',
    'DITStat.selectAllCpesButtonCaption': 'Добавить все отфильтрованные ТД',
    'DITStat.SFTPAuthTypePassword': 'Пароль',
    'DITStat.SFTPAuthTypePrivateKey': 'Приватный ключ',
    'DITStat.sftpSwitcherCaption': 'Включить SFTP',
    'DITStat.sftpSwitcherInfo': 'Позволяет использовать SFTP сервер для выгрузки',
    'DITStat.tableColumnCPE': 'Точки доступа',
    'DITStat.tableColumnEnabled': 'Статус',
    'DITStat.tableColumnFormat': 'Формат',
    'DITStat.tableColumnFtpLogin': 'Имя пользователя',
    'DITStat.tableColumnFtpPassword': 'Пароль',
    'DITStat.tableColumnFtpServer': 'Сервер FTP',
    'DITStat.tableColumnId': 'ID',
    'DITStat.tableColumnInterval': 'Интервал выгрузки',
    'DITStat.tableColumnPrivateKey': 'Ключ',
    'DITStat.tableColumnPrivateKeyInfo': 'Ключ может использоваться только для SFTP серверов',
    'DITStat.tableTitle': 'Выгрузки статистики ДИТ',
    'downloadFilesNames.analyticsVisitors': 'Аналитика - Посетители',
    'downloadFilesNames.clients': 'Клиенты',
    'downloadFilesNames.cpes': 'Точки доступа',
    'downloadFilesNames.licenses': 'Лицензии',
    'downloadFilesNames.monitoringCPEConnectivity': 'Монитор - Подключение ТД',
    'downloadFilesNames.monitoringDBState': 'Монитор - Состояние БД',
    'downloadFilesNames.monitoringEvents': 'Монитор - События',
    'downloadFilesNames.portalAccessServers': 'Портал - Серверы доступа',
    'downloadFilesNames.portalAccounts': 'Портал - Пользователи',
    'downloadFilesNames.portalAdsAndPollsStatisticSection': 'Статистика просмотров рекламы',
    'downloadFilesNames.portalAdsAndPollsStatisticSectionAll': 'по всем типам',
    'downloadFilesNames.portalAdsAndPollsStatisticSectionBy': 'по типам авторизации',
    'downloadFilesNames.portalBirthdays': 'Портал - Дни рождения для',
    'downloadFilesNames.portalHotelVouchers': 'Отельные ваучеры',
    'downloadFilesNames.portalInAdsAndPollsStatisticSection': 'Статистика просмотров рекламы',
    'downloadFilesNames.portalInAdsAndPollsStatisticSectionAll': 'по всем типам',
    'downloadFilesNames.portalInAdsAndPollsStatisticSectionBy': 'по типам авторизации',
    'downloadFilesNames.portalStatistic': 'Портал - Статистика',
    'downloadFilesNames.portalStatisticLineChartAuthenticationType': 'Тип аутентификации',
    'downloadFilesNames.portalStatisticLineChartAuthorizationType': 'Тип авторизации',
    'downloadFilesNames.portalStatisticLineChartDeviceType': 'Типы устройств',
    'downloadFilesNames.portalStatisticLineChartLocale': 'Языки',
    'downloadFilesNames.portalStatisticLineChartNewAndRepeatUsers': 'Новые и повторные пользователи',
    'downloadFilesNames.portalStatisticLineChartOperatingSystems': 'Операционные системы',
    'downloadFilesNames.portalStatisticLineChartVendors': 'Производители устройств',
    'downloadFilesNames.portalStatisticPieChartAuthenticationType': 'Тип аутентификации',
    'downloadFilesNames.portalStatisticPieChartAuthorizationType': 'Типы авторизации',
    'downloadFilesNames.portalStatisticPieChartBrowsers': 'Браузеры',
    'downloadFilesNames.portalStatisticPieChartDeviceType': 'Типы устройств',
    'downloadFilesNames.portalStatisticPieChartLocale': 'Языки',
    'downloadFilesNames.portalStatisticPieChartOperatingSystems': 'Операционные системы',
    'downloadFilesNames.portalStatisticPieChartUsers': 'Пользователи',
    'downloadFilesNames.portalStatisticPieChartVendors': 'Производители устройств',
    'downloadFilesNames.portalVouchers': 'Ваучеры',
    'downloadFilesNames.statClients': 'Статистика - Клиенты',
    'downloadFilesNames.statClientsSessions': 'Статистика - Клиенты - Сессии',
    'downloadFilesNames.statCPEs': 'Статистика - Точки',
    'downloadFilesNames.statSNMP': 'Статистика - SNMP',
    'downloadFilesNames.statSystem': 'Статистика - Локации',
    'downloadFilesNames.statWLANs': 'Статистика - Сети Wi-Fi',
    'downloadFilesNames.users': 'Пользователи',
    'downloadFilesNames.visitors': 'Посетители',
    'drPicker.always': 'Постоянно',
    'drPicker.apply': 'Выбрать',
    'drPicker.april_full': 'Апреля',
    'drPicker.april_short': 'Апр',
    'drPicker.august_full': 'Августа',
    'drPicker.august_short': 'Авг',
    'drPicker.cancel': 'Отмена',
    'drPicker.chooseInterval': 'Выберите интервал',
    'drPicker.custom': 'Выбрать',
    'drPicker.days': 'дней',
    'drPicker.december_full': 'Декабря',
    'drPicker.december_short': 'Дек',
    'drPicker.february_full': 'Февраля',
    'drPicker.february_short': 'Фев',
    'drPicker.friday_short': 'Пт',
    'drPicker.from': 'От',
    'drPicker.hours': 'часов',
    'drPicker.january_full': 'Января',
    'drPicker.january_short': 'Янв',
    'drPicker.july_full': 'Июля',
    'drPicker.july_short': 'Июл',
    'drPicker.june_full': 'Июня',
    'drPicker.june_short': 'Июн',
    'drPicker.last': 'Последние',
    'drPicker.last24': 'За 24 часа',
    'drPicker.last30': 'За 30 дней',
    'drPicker.last48': 'За 48 часов',
    'drPicker.last7': 'За 7 дней',
    'drPicker.march_full': 'Марта',
    'drPicker.march_short': 'Мар',
    'drPicker.maxDateRangeLimit': 'Максимальный интервал для выбора',
    'drPicker.may_full': 'Мая',
    'drPicker.may_short': 'Май',
    'drPicker.minutes': 'мин',
    'drPicker.monday_short': 'Пн',
    'drPicker.month': 'За этот месяц',
    'drPicker.months': 'месяцев',
    'drPicker.next': 'Следующие',
    'drPicker.november_full': 'Ноября',
    'drPicker.november_short': 'Ноя',
    'drPicker.october_full': 'Октября',
    'drPicker.october_short': 'Окт',
    'drPicker.prev_month': 'За предыдущий месяц',
    'drPicker.prev_week': 'За предыдущую неделю',
    'drPicker.saturday_short': 'Сб',
    'drPicker.seconds': 'секунд',
    'drPicker.september_full': 'Сентября',
    'drPicker.september_short': 'Сен',
    'drPicker.sunday_short': 'Вс',
    'drPicker.thursday_short': 'Чт',
    'drPicker.to': 'До',
    'drPicker.today': 'За сегодня',
    'drPicker.tuesday_short': 'Вт',
    'drPicker.wednesday_short': 'Ср',
    'drPicker.week': 'За эту неделю',
    'drPicker.weeks': 'недель',
    'drPicker.years': 'лет',
    'drPicker.yesterday': 'За вчера',
    'easyInstallWizard.badgeSidebarInProgress': 'В процессе',
    'easyInstallWizard.easyInstallButtonInSidebar': 'Простая установка',
    'easyInstallWizard.headerCaption': 'Помощник создания Wi-Fi сети с порталом',
    'easyInstallWizard.step1Caption': 'Создание Wi-Fi сети',
    'easyInstallWizard.step1Header': 'Шаг 1: Создание Wi-Fi сети',
    'easyInstallWizard.step1NextButton': 'Создать',
    'easyInstallWizard.step1SuiteRequiredsError': 'Поле "Тип шифрования" обязательно для заполнения',
    'easyInstallWizard.step1WhereIsLookingForThisSettings': '"Сети Wi-Fi" ',
    'easyInstallWizard.step2Caption': 'Создание страницы',
    'easyInstallWizard.step2FieldPageName': 'Имя страницы',
    'easyInstallWizard.step2Header': 'Шаг 2: Создание страницы',
    'easyInstallWizard.step2NextButton': 'Создать',
    'easyInstallWizard.step2WhereIsLookingForThisSettings': '"Портал" -> "Конфигурация" -> "Страницы" ',
    'easyInstallWizard.step3Caption': 'Создание правила редиректа',
    'easyInstallWizard.step3Header': 'Шаг 3: Создание правила редиректа',
    'easyInstallWizard.step3NextButton': 'Создать',
    'easyInstallWizard.step3PrevStepPageURL': 'URL созданной на предыдущем шаге страницы портала',
    'easyInstallWizard.step3WhereIsLookingForThisSettings': '"Гостевой контроль" ',
    'easyInstallWizard.step4Caption': 'Добавление ранее созданной сети Wi-Fi на необходимые точки доступа',
    'easyInstallWizard.step4CPESPlaceholder': 'Выберите одну или несколько точек доступа',
    'easyInstallWizard.step4Header': 'Шаг 4: Добавление сети Wi-Fi на необходимые точки доступа',
    'easyInstallWizard.step4Hint': 'Выберите точки, для добавления на них ранее созданной сети Wi-Fi',
    'easyInstallWizard.step4NextButton': 'Добавить',
    'easyInstallWizard.step4WhereIsLookingForThisSettings': '"Точки доступа"',
    'easyInstallWizard.step5Caption': 'Создание профиля',
    'easyInstallWizard.step5Header': 'Шаг 5: Создание профиля',
    'easyInstallWizard.step5WhereIsLookingForThisSettings': 'Портал -> Конфигурация -> Профили',
    'easyInstallWizard.step5NextButton': 'Создать',
    'easyInstallWizard.whereIsLookingForThisSettings': 'Где в дальнейшем можно найти эти настройки',
    'events.chartEventsAlert': 'На графике показаны только загруженные в таблицу события',
    'events.clientRFAndEventsAuthorizationChartSeries': 'Авторизация',
    'events.clientRFAndEventsButtonCaption': 'События и RSSI',
    'events.clientRFAndEventsChartEventsCaption': 'События',
    'events.clientRFAndEventsClientCaption': 'Клиента',
    'events.clientRFAndEventsConnectedChartSeries': 'Подключение',
    'events.clientRFAndEventsDhcp_AckChartSeries': 'DHCP ACK',
    'events.clientRFAndEventsDisconnectedChartSeries': 'Отключение',
    'events.clientRFAndEventsEventHeader': 'Событие',
    'events.clientRFAndEventsModalCaption': 'События и RSSI',
    'events.clientRFAndEventsNumberOfEventsCaption': 'Количество событий',
    'events.clientRFAndEventsRFHeader': 'Состояние Wi-Fi',
    'events.copyToClipboard': 'Копировать в буфер обмена',
    'events.cpeName': 'Имя точки доступа',
    'events.description': 'описание',
    'events.errorEvents': 'Ошибка',
    'events.eventData': 'Информация по событию',
    'events.events': 'События',
    'events.eventsChart': 'График событий',
    'events.eventsTable': 'События',
    'events.filterACK': 'DHCP ACK',
    'events.filterAUTHORIZATION': 'Авторизация',
    'events.filterCLIENT': 'Клиент',
    'events.filterCLIENT AUTH': 'Аутентификация',
    'events.filterCONNECTED': 'Подключение',
    'events.filterCPE': 'Точка доступа',
    'events.filterDISCONNECTED': 'Отключение',
    'events.filterERROR': 'Ошибка',
    'events.filterFIRMWARE': 'Прошивка',
    'events.filterINFO': 'Информация',
    'events.filterLOG': 'Лог',
    'events.filterRULE': 'Правило',
    'events.filterSERVICE': 'Сервис',
    'events.filterWARNING': 'Предупреждение',
    'events.goBackToClients': 'Назад к клиентам',
    'events.goBackToCPE': 'Вернуться к ТД',
    'events.in24Hours': 'за 24 часа',
    'events.itemsOf': 'из',
    'events.level': 'Уровень',
    'events.noData': 'Нет данных. Попробуйте выбрать другой временной период и/или другие фильтры.',
    'events.noDataForSelectedDateRangeForSelectedCPE': 'Нет данных для выбранной ТД. Попробуйте выбрать другой временной период.',
    'events.paginationEventsOutOf': 'Событий из',
    'events.paginationLoad': 'Загрузить',
    'events.placeHolderSearch': 'Поиск',
    'events.sendConfigButton': 'Отправить скрипт настройки на проблемные ТД',
    'events.sendConfigDuccessMessage': 'Скрипт успешно отправлен',
    'events.sendConfigModalHintMessage': 'Скрипт будет отправлен на ТД с ошибками следующего вида',
    'events.showPayload': 'Посмотреть весь объект',
    'events.status': 'Статус',
    'events.subject': 'Объект',
    'events.subjectId': 'ID oбъекта',
    'events.summary': 'Сводка',
    'events.time': 'Время',
    'events.timestamp': 'Время',
    'events.type': 'Тип',
    'events.view': '',
    'externalAP.addExternalAP': 'Добавить стороннюю ТД',
    'externalAP.byMac': 'По MAC',
    'externalAP.byName': 'По имени',
    'externalAP.bySN': 'По серийному номеру',
    'externalAP.confirmDelete': 'Подтвердить удаление',
    'externalAP.deleteExternalAPHeader': 'Удаление сторонней ТД',
    'externalAP.description': 'Описание',
    'externalAP.externalAPHeader': 'Сторонняя ТД',
    'externalAP.externalAPHeaderAdd': 'Добавить стороннюю ТД',
    'externalAP.externalApsList': 'Сторонние точки доступа',
    'externalAP.externalAPsOutOf': 'сторон. ТД из',
    'externalAP.fw_version': 'Версия прошивки',
    'externalAP.ip_addr': 'IP',
    'externalAP.load': 'Загрузить',
    'externalAP.mac': 'MAC',
    'externalAP.name': 'Имя',
    'externalAP.notificationExternalApCreatedSuccessfully': 'Сторонняя ТД успешно создана',
    'externalAP.notificationExternalApDeletedSuccessfully': 'Сторонняя ТД успешно удалена',
    'externalAP.notificationExternalApOperationSuccessfull': 'Успешно',
    'externalAP.notificationExternalApUpdatedSuccessfully': 'Сторонняя ТД успешно обновлена',
    'externalAP.password': 'Пароль',
    'externalAP.port': 'Порт',
    'externalAP.serial': 'Серийный номер',
    'externalAP.setTrapServer': 'Автоматическая настройка trap-сервера',
    'externalAP.snmp': 'SNMP',
    'externalAP.snmpCommunity': 'Community',
    'externalAP.snmpPort': 'Порт',
    'externalAP.snmpVersion': 'Версия',
    'externalAP.ssh': 'SSH',
    'externalAP.sshKey': 'SSH ключ',
    'externalAP.sshKeyORPasswordMustNotEmptyError': 'Поле "Пароль" или "SSH ключ" обязательны для заполнения',
    'externalAP.sshKeyORPasswordOnlyOneError': 'Только одно поле должно быть заполнено: "Пароль" или "SSH ключ"',
    'externalAP.status': 'Статус',
    'externalAP.username': 'Имя пользователя',
    'externalAP.vendor': 'Производитель',
    'firewall.Action': 'Действие',
    'firewall.addFirewall': 'Добавить брандмауэр',
    'firewall.addNewRule': 'Добавление нового правила брандмауэра',
    'firewall.AddRule': 'Добавить правило',
    'firewall.ANY': 'Любой',
    'firewall.confirmButton': 'Подтвердить',
    'firewall.createFirewall': 'Помощник создания брандмауэра',
    'firewall.deleteRuleButton': 'Удалить правило',
    'firewall.Direction': 'Направление трафика',
    'firewall.direction': 'Направление трафика',
    'firewall.editRule': 'Редактирование правила',
    'firewall.enterIPPlaceholder': 'Введите IP адрес',
    'firewall.enterMACPlaceholder': 'Введите MAC адрес',
    'firewall.enterPortPlaceholder': 'Введите порт',
    'firewall.firewall': 'Брандмауэр',
    'firewall.firewallNamePlaceHolder': 'Введите имя брандмауэра',
    'firewall.Firewalls': 'Брандмауэры',
    'firewall.firewallSettings': 'Параметры брандмауэра',
    'firewall.fromIP': 'IP источника',
    'firewall.fromIPaddress': 'IP адрес источника',
    'firewall.fromMAC': 'MAC источника',
    'firewall.fromMACaddress': 'MAC адрес источника',
    'firewall.fromPort': 'Порт источника',
    'firewall.fromPortModal': 'Порт источника',
    'firewall.IN': 'Входящий',
    'firewall.infoMessage': 'Наш брандмауэр основан на IP-таблицах. Правила создаются в его нотации. Документацию по правилам можно найти тут.',
    'firewall.infoTooltip': 'Нажмите, чтобы просмотреть информацию о брандмауэре',
    'firewall.Internetlayer': 'Сетевой уровень',
    'firewall.Linklayer': 'Канальный уровень',
    'firewall.modalDeleteFirewallText': 'Подтвердить удаление',
    'firewall.modalDeleteFirewallTitle': 'Удалить брандмауэр',
    'firewall.notificationFirewallCreatedSuccessfully': 'Брандмауэр успешно создан',
    'firewall.notificationFirewallDeletedSuccessfully': 'Брандмауэр успешно удален',
    'firewall.notificationFirewallOperationSuccessfull': 'Успешно',
    'firewall.notificationFirewallUpdatedSuccessfully': 'Брандмауэр успешно обновлен',
    'firewall.OUT': 'Исходящий',
    'firewall.Policy': 'Политика по ум.',
    'firewall.policy': 'Политика по ум.',
    'firewall.ruleIPProtocol': 'IP протокол',
    'firewall.ruleProtocol': 'Протокол',
    'firewall.Rules': 'Правила',
    'firewall.rules': 'Правила',
    'firewall.RuleSettings': 'Настройка правила',
    'firewall.SaveRule': 'Сохранить правило',
    'firewall.toIP': 'IP целевой',
    'firewall.toIPaddress': 'IP адрес целевой',
    'firewall.toMAC': 'MAC целевой',
    'firewall.toMACaddress': 'MAC адрес целевой',
    'firewall.toPort': 'Порт целевой',
    'firewall.toPortModal': 'Порт целевой',
    'firewall.Transportlayer': 'Транспортный уровень',
    'firewall.wizard1Caption': 'Заполните обязательные параметры брандмауэра',
    'firewall.wizard2Caption': 'Заполните правила брандмауэра',
    'firewall.wizard3Caption': 'Подтвердите создание нового брандмауэра',
    'firewall.wizardNoRules': 'Нет правил',
    'general.multiEdit': 'Мультиредактирование',
    'general.multiEditMode': 'Режим мультиредактирования',
    'general.ipField': 'IP адрес',
    'general.ipFieldHint': 'IP адрес в формате X.X.X.X',
    'general.account': 'Профиль',
    'general.active': 'Актив',
    'general.add': 'Добавить',
    'general.here': 'здесь',
    'general.update': 'Обновить',
    'general.clone': 'Клонировать',
    'general.address': 'Адрес',
    'general.addTags': 'Добавить теги',
    'general.advancedParameters': 'Дополнительные параметры',
    'general.all': 'Все',
    'general.allCompanys': 'Все компании',
    'general.allLocations': 'Все локации',
    'general.allModels': 'Все модели',
    'general.allRoles': 'Все роли',
    'general.allStatuses': 'Все статусы',
    'general.allTags': 'Все теги',
    'general.allWlans': 'Все Wi-Fi сети',
    'general.Always': 'Всегда',
    'general.another': 'Другие',
    'general.apply': 'Применить',
    'general.applyFilters': 'Применить фильтры',
    'general.Auto': 'Авто',
    'general.auto': 'Авто',
    'general.back': 'Назад',
    'general.baseLocation': 'Базовая локация',
    'general.basicParameters': 'Базовые параметры',
    'general.byDefault': 'по умолчанию',
    'general.capslock': 'Включен Caps Lock',
    'general.cancel': 'Отмена',
    'general.change': 'Изменить',
    'general.changeLocations': 'Смена локаций',
    'general.chooseFile': 'Выберите файл',
    'general.clear': 'Очистить',
    'general.clickAndDrag': '#Н/Д',
    'general.clientRF': 'Client RF',
    'general.clients': 'Клиенты',
    'general.close': 'Закрыть',
    'general.closeModal': 'Закрыть окно',
    'general.closeModalConfirm': 'Пожалуйста, подтвердите закрытие окна.\nНесохраненные данные будут потеряны.',
    'general.company': 'Компания',
    'general.confirm': 'Создать',
    'general.confirmDeleting': 'Подтвердите удаление',
    'general.confirmDeletingText': 'Пожалуйста, подтвердите удаление',
    'general.confirmDeletingTitle': 'Подтверждение удаления',
    'general.connectedDisconnectedShort': 'Подкл./Откл.',
    'general.connectedShort': 'подкл.',
    'general.copy': 'Копировать',
    'general.copyToClipBoardCaption': 'Копировать в буфер обмена',
    'general.cpe': 'Точки доступа',
    'general.cpeOne': 'Точка доступа',
    'general.cpes': 'Точки доступа',
    'general.cpeSeveral': 'Точки доступа',
    'general.cpuLoad': 'Загрузка процессора',
    'general.create': 'Создать',
    'general.csvFileUUIds': 'Файл формата CSV со списком UUID',
    'general.date': 'Дата',
    'general.dateRangeLimitExceededError': 'Выбран промежуток более чем',
    'general.day': 'день',
    'general.dayShort': 'дн',
    'general.dBm': 'дБм',
    'general.delete': 'Удалить',
    'general.delete_sm': 'удалить',
    'general.description': 'Описание',
    'general.noDescription': 'Нет описания',
    'general.differenceBetweenPeriods': 'Разница',
    'general.differenceGrow': 'рост на',
    'general.differenceLoss': 'падение на',
    'general.disabled': 'выключено',
    'general.disconnectedShort': 'откл.',
    'general.download': 'Выгрузить',
    'general.downloadAllPng': 'Скачать общий отчёт',
    'general.downloadVouchers': 'Скачать ваучеры',
    'general.downloadCsv': 'Скачать csv',
    'general.downloadFiles': 'Выгрузить таблицу с данными',
    'general.downloadPdf': 'Скачать pdf',
    'general.downloadPng': 'Сохранить изображение',
    'general.downloadXls': 'Скачать xls',
    'general.downloadXlsx': 'Скачать xlsx',
    'general.duration12h': '12 часов',
    'general.durationDay': 'Сутки',
    'general.durationDays': '{days} дней',
    'general.durationMonth': 'Месяц',
    'general.durationWeek': 'Неделя',
    'general.durationYear': 'Год',
    'general.edit': 'изменить',
    'general.Edit': 'изменить',
    'general.editingMode': 'Режим редактирования',
    'general.email': 'Почта',
    'general.empty': 'Пусто',
    'general.enable': 'Включить',
    'general.disable': 'Выключить',
    'general.enabled': 'включено',
    'general.enableShort': 'Вкл.',
    'general.expand': 'Развернуть',
    'general.feltersDateRange': 'Диапазон дат',
    'general.filter': 'Фильтр',
    'general.filterPageLimitSmall': 'Кол-во записей',
    'general.filters': 'Фильтры',
    'general.filtersCity': 'Город проживания',
    'general.filtersGender': 'Пол',
    'general.filtersHomeTown': 'Место рождения',
    'general.filtersNasId': 'NAS ID',
    'general.filtersPageLimit': 'Количество записей',
    'general.filtersPageOffset': 'Показать начиная с',
    'general.filtersSocialNetwork': 'Соц.сеть',
    'general.filtersSort': 'Сортировка',
    'general.filtersSubscription': 'Статус подписки',
    'general.filtersSubscriptionAtTime': 'Дата подписки',
    'general.filtersSubscriptionStatus': 'Статус подписки',
    'general.filtersTownPlaceholder': 'Название города',
    'general.filtersVisitsAll': 'Посещения',
    'general.filtersYearOfBirth': 'Год рождения',
    'general.firstConnected': 'Первое подключение',
    'general.for': 'для',
    'general.forCurrentPeriod': 'За текущий период',
    'general.format': 'Формат',
    'general.forPreviousPeriod': 'За предыдущий период',
    'general.found': 'Найдено',
    'general.fr': 'Пт',
    'general.freeRAM': 'Свободная память',
    'general.frequency': 'Частота',
    'general.from': 'с',
    'general.fullscreen.expand': 'Полноэкранный режим',
    'general.fullscreen.collapse': 'Выйти из полноэкранного режима',
    'general.generatePassword': 'Сгенерировать пароль',
    'general.hideDescription': 'Скрыть описание',
    'general.hideid': 'скрыть id',
    'general.historyRequests': 'История запросов',
    'general.hour': 'час',
    'general.hours': 'Часы',
    'general.hourShort': 'час',
    'general.info': 'Информация',
    'general.infoShort': 'Информация',
    'general.insec': 'в сек.',
    'general.interface': 'Интерфейс',
    'general.itemsPerPage': 'записей на странице',
    'general.kbit': 'Кбит',
    'general.language.en': 'Английский',
    'general.language.ru': 'Русский',
    'general.lastDisconnected': 'Последнее отключение',
    'general.location': 'Локация',
    'general.locations': 'Локации',
    'general.locationsAll': 'Все',
    'general.locationSelect': 'Выбор локации',
    'general.mustBeLessThan': 'должен быть меньше чем',
    'generan.mustBeGreaterThan': 'должен быть больше чем',
    'general.mac': 'MAC',
    'general.maxOneElement': 'Максимум выбранных опций. Сначала удалите выбранный вариант, чтобы выбрать другой.',
    'general.mbit': 'Мбит',
    'general.mbitS': 'Мбит/с',
    'general.memoryLoad': 'Использование памяти',
    'general.mhz': 'МГц',
    'general.min': 'мин',
    'general.minimize': 'Свернуть',
    'general.minShort': 'мин',
    'general.mo': 'Пн',
    'general.modalChangeLocationsCaption': 'Изменить локацию для следующих',
    'general.Mode': 'Режим',
    'general.model': 'Модель',
    'general.month': 'Месяц',
    'general.multiSelectDeselectLabel': 'Нажмите "Enter" для отмены выбора',
    'general.multiSelectPressToSelect': 'Нажмите "Enter" для выбора',
    'general.multiSelectSelected': 'Выбрано',
    'general.name': 'Имя',
    'general.next': 'Далее',
    'general.no': 'Нет',
    'general.noData': 'нет данных',
    'general.noDataToDisplay': 'Нет данных для отображения',
    'general.noDataToDisplayWhenCPESelected': 'Нет данных для отображения для данной ТД',
    'general.noDataToDisplayWhenCPESelectedAndTimeRange': 'Нет данных для отображения для данной ТД за выбранный промежуток времени c указанными настройками фильтров',
    'general.noModel': 'Модель отсутствует',
    'general.noOptionsForSearch': 'Начните ввод для поиска',
    'general.noResultForSearch': 'Ничего не найдено',
    'general.maxElementsSelected': 'Выбрано максимальное количество вариантов: {max}. Чтобы выбрать другой вариант - удалите сначала один из ранее выбранных',
    'general.notActive': 'Не актив',
    'general.noTag': 'Без тега',
    'general.noTags': 'нет тегов',
    'general.notFound': 'не найдено',
    'general.object': 'Объект',
    'general.of': 'из',
    'general.Off': 'Выкл',
    'general.off': 'Выкл',
    'general.ofTotal': 'из всех',
    'general.offset': 'Смещение',
    'general.On': 'Вкл',
    'general.on': 'Вкл',
    'general.orderAsc': 'По возрастанию',
    'general.orderDesc': 'По убыванию',
    'general.password': 'Пароль',
    'general.pagination.firstPage': 'Первая',
    'general.pagination.lastPage': 'Последняя',
    'general.pagination.nextPage': 'След.',
    'general.pagination.prevPage': 'Пред.',
    'general.pagination.rowsPerPage': 'Строк на стр.',
    'general.pagination.of': 'из',
    'general.pagination.page': 'страниц',
    'general.pagination.all': 'Все',
    'general.pending': 'Ожидание',
    'general.port': 'порт',
    'general.previouslySelected': 'Ранее выбранные',
    'general.readOnlyMode': 'Режим чтения',
    'general.removeAll': 'убрать все',
    'general.resetAllFilters': 'Сбросить все фильтры',
    'general.role.admin': 'админ',
    'general.role.advertising': 'рекламный агент',
    'general.role.hotel_employee': 'отельный представитель',
    'general.role.marketer': 'маркетолог',
    'general.role.operator': 'оператор',
    'general.rub': 'руб.',
    'general.rules': 'Правила',
    'general.sa': 'Сб',
    'general.save': 'Сохранить',
    'general.search': 'Поиск',
    'general.searchPlaceHolder': 'Поиск',
    'general.sec': 'сек',
    'general.secShort': 'сек',
    'general.msShort': 'мс',
    'general.select': 'выбрать',
    'general.selectAll': 'выбрать все',
    'general.selected': 'Выбрано',
    'general.selectedForTables': 'выбрано',
    'general.searchBarPlaceholder': 'ТД\\Пользователь',
    'general.selectLocation': 'Выберите локацию',
    'general.select.initialOptionValue': 'Выберите значение',
    'general.serialNumber': 'С/Н',
    'general.server': 'сервер',
    'general.sessionAverageTime': 'Среднее время сессии',
    'general.sessionCount': 'Счетчик сессии',
    'general.sessionFirstClients': 'Первые клиенты для сессии',
    'general.sessionReturnClients': 'Повторные клиенты для сессии',
    'general.sessions': 'Сессия (подкл./откл.)',
    'general.Settings': 'Настройки',
    'general.showDescription': 'показать описание',
    'general.showid': 'показать id',
    'general.skip': 'Пропустить',
    'general.start': 'Cтарт',
    'general.startPage': 'Cтартовая страница',
    'general.state.loading': 'Загрузка...',
    'general.status': 'Статус',
    'general.step1': 'Шаг 1',
    'general.step10': 'Шаг 10',
    'general.step11': 'Шаг 11',
    'general.step2': 'Шаг 2',
    'general.step3': 'Шаг 3',
    'general.step4': 'Шаг 4',
    'general.step5': 'Шаг 5',
    'general.step6': 'Шаг 6',
    'general.step7': 'Шаг 7',
    'general.step8': 'Шаг 8',
    'general.step9': 'Шаг 9',
    'general.stop': 'Cтоп',
    'general.su': 'Вс',
    'general.submit': 'Отправить',
    'general.summary': 'Подтверждение',
    'general.summaryConfirmation': 'Проверьте введенные параметры',
    'general.system': 'Системные',
    'general.softwareVersion': 'Версия ПО',
    'general.tags': 'Теги',
    'general.th': 'Чт',
    'general.till': 'до',
    'general.time': 'время',
    'general.Timeout': 'Таймаут',
    'general.totalClients': 'Всего клиентов',
    'general.trafficDownloaded': 'Входящий трафик',
    'general.trafficTxRx': 'Трафик (вх./исх.)',
    'general.trafficTx': 'Трафик исх.',
    'general.trafficRx': 'Трафик вх.',
    'general.trafficUploaded': 'Исходящий трафик',
    'general.tu': 'Вт',
    'general.type': 'Тип',
    'general.tz': 'Часовой пояс (UTC)',
    'general.uniqueClients': 'Уник. клиенты',
    'general.unset': 'Не указано',
    'general.updating': 'Обновление',
    'general.view': 'Просмотр',
    'general.warning': 'Предупреждение',
    'general.we': 'Ср',
    'general.withChild': 'с дочерними',
    'general.wlan': 'Сеть Wi-Fi ',
    'general.wlanOne': 'сетей Wi-Fi',
    'general.wlans': 'Сети Wi-Fi',
    'general.wlanSeveral': 'сетей Wi-Fi',
    'general.width': 'Ширина',
    'general.yes': 'Да',
    'general.yesDelete': 'Да, удалить',
    'general.noSelected': 'Не выбрано',
    'GuestControl.addRule': 'Создать правило редиректа',
    'GuestControl.allCpes': 'Все точки доступа',
    'GuestControl.allWlans': 'Все Wi-Fi сети',
    'GuestControl.create': 'Помощник создания правила редиректа',
    'GuestControl.deleteRedirectRuleModalText': 'Подтвердить удаление',
    'GuestControl.deleteRedirectRuleModalTitle': 'Удалить правило редиректа',
    'GuestControl.domain': 'Домен',
    'GuestControl.domainCaption': 'Домен для перенаправления. Адрес должен указывать туда, где развёрнут "Wimark portal" относительно клиента. Если это общедоступный домен, в "Walled garden" достаточно указать только IP адрес. Если нет, в "Walled garden" также необходимо указать домен. Домен должен быть указан без протокола.',
    'GuestControl.domainName': 'Доменное имя',
    'GuestControl.enterName': 'Введите имя для правила редиректа',
    'GuestControl.expertHide': 'Выключить экспертный режим',
    'GuestControl.expertShow': 'Включить экспертный режим',
    'GuestControl.howTo': 'как заполнить?',
    'GuestControl.ipAddress': 'IP адрес',
    'GuestControl.macList': 'Белый список MAC',
    'GuestControl.noMasquerade': 'Без NAT/PAT',
    'GuestControl.PreAuthACLConfig': 'Настройка списка доступа для неаутентифицированных пользователей',
    'GuestControl.PreAuthACLConfigHint': 'Список доступа для неаутентифицированных пользователей называется “redirect”. Кроме ip адресов и доменов, которые указываются пользователем ниже, список доступа redirect разрешает работу протоколов bootp и arp. После успешной аутентификации к пользователю применяется список доступа, который называется “internet”. Список доступа “internet” не содержит никаких ограничений.',
    'GuestControl.notificationRedirectCreatedSuccessfully': 'Редирект успешно создан',
    'GuestControl.notificationRedirectDeletedSuccessfully': 'Редирект успешно удален',
    'GuestControl.notificationRedirectSuccessfull': 'Успешно',
    'GuestControl.notificationRedirectUpdatedSuccessfully': 'Редирект успешно обновлен',
    'GuestControl.redirectIP': 'IP редиректа',
    'GuestControl.redirectType': 'Тип редиректа',
    'GuestControl.redirectType.static': 'Статический URL',
    'GuestControl.redirectType.dynamic': 'Динамический URL',
    'GuestControl.redirectURL': 'URL портала авторизации, для перенаправления пользователей',
    'GuestControl.redirectUrl': 'URL портала авторизации, для перенаправления пользователей',
    'GuestControl.redirectURLCaption': 'Адрес, на который перенаправляется клиент после успешной авторизации',
    'GuestControl.redirectExample': 'Пример:',
    'GuestControl.redirectExampleText': 'Необходимо обязательно использовать {redirectExpl}',
    'GuestControl.redirectFormUsage': 'Заполните форму, используя key={paramName} шаблон. Соответствующий параметр будет использован вместо {paramName}.',
    'GuestControl.redirectSwitchParam': '{switchParam}: Адрес платформы, куда был перенаправлен клиент.',
    'GuestControl.redirectRedirectParam': '{redirectParam}: Адрес, куда был перенаправлен клиент после успешной авторизации.',
    'GuestControl.ruleSettings': 'Параметры правила редиректа',
    'GuestControl.switchURL': 'URL платформы',
    'GuestControl.switchURLCaption': 'Адрес платформы (IP или доменное имя), на который перенаправляется клиент. Должен содержать только имя хоста (например, "www.example.com"). Протокол, порт и URL-путь указывать не нужно.',
    'GuestControl.tableHeading': 'Правила редиректа для портала',
    'GuestControl.targetingLocation': 'Локация для таргетинга',
    'GuestControl.urlList': 'Белый список URL',
    'GuestControl.walledGarden': 'Walled garden',
    'GuestControl.walledGardenIPTooltip': 'НЕОБХОДИМО чтобы все IP адреса доменного имени с редиректом (URL платформы) находились в списке IP, доступных неавторизованным пользователям ',
    'GuestControl.wizard1Caption': 'Заполните базовые параметры для правила редиректа',
    'GuestControl.wizard2Caption': 'Подтвердите создание нового правила редиректа',
    'GuestControl.wlanCaption': 'Если правило будет работать на "Wimark Appliance" (на проводном интерфейсе), ID должен быть явно задан',
    'header.account': 'Аккаунт',
    'header.clearListOperations': 'Очистить список операций',
    'header.create': 'создание',
    'header.current': 'Текущие',
    'header.delete': 'удаление',
    'header.errors': 'ошибки',
    'header.hideLocations': 'Скрыть локации',
    'header.inputIntervalPlaceholder': 'Интервал в секундах',
    'header.last': 'Последние',
    'header.logout': 'Выйти',
    'header.max': 'макс.',
    'header.noCurrOperations': 'Нет операций',
    'header.off': 'выкл',
    'header.operations': 'операции',
    'header.pending': 'ожидание',
    'header.reboot': 'перезагрузка',
    'header.reset': 'сброс',
    'header.sec': 'сек',
    'header.setRefreshInterval': 'Интервал обновления',
    'header.settings': 'Настройки',
    'header.showLocations': 'Показать локации',
    'header.success': 'успешно',
    'header.tooltipDelete': 'удалить',
    'header.tooltipDeleteAll': 'удалить все',
    'header.tooltipShowAll': 'показать все',
    'header.tooltipShowErrorsOnly': 'показать только ошибки',
    'header.total': 'всего',
    'header.type': 'Тип',
    'header.typeCREATE': 'Создание',
    'header.typeDELETE': 'Удаление',
    'header.typeUPDATE': 'Изменение',
    'header.update': 'обновление',
    'header.updateInterval': 'Обновление страницы',
    'header.wifiWizard': 'Wi-Fi помощник',
    'hotspot.addHS20': 'Добавить HS 2.0 профиль',
    'hotspot.additionalStep': 'Требуется дополнительный шаг авторизации',
    'hotspot.addRealm': 'Добавить Realm',
    'hotspot.authSubType': 'Подтип аутентификации',
    'hotspot.authType': 'Тип аутентификации',
    'hotspot.cellular': 'Сотовая сеть',
    'hotspot.connectionCapabilityList': 'Возможности подключения',
    'hotspot.dgaf': 'Групповая переадресация (DGAF)',
    'hotspot.domain': 'Домен',
    'hotspot.domainList': 'Список доменов',
    'hotspot.domains': 'Домены',
    'hotspot.eapMethod': 'Метод EAP',
    'hotspot.emergencyServices': 'Доступность экстренных вызовов',
    'hotspot.enterRealmNamePlaceholder': 'Введите имя Realm',
    'hotspot.group': 'Группа',
    'hotspot.hessid': 'HESSID',
    'hotspot.hotspotWizard': 'Помощник создания Hotspot 2.0',
    'hotspot.hs20profiles': 'Hotspot 2.0 профили',
    'hotspot.internet': 'Интернет',
    'hotspot.ipAvailability': 'Доступность IP адресов',
    'hotspot.language': 'Язык',
    'hotspot.naiRealmList': 'Список NAI Realm',
    'hotspot.network': 'Сеть',
    'hotspot.networkType': 'Тип сети',
    'hotspot.noItems': 'нет элементов',
    'hotspot.notificationHotspotCreatedSuccessfully': 'Hotspot успешно создан',
    'hotspot.notificationHotspotDeletedSuccessfully': 'Hotspot успешно удален',
    'hotspot.notificationHotspotUpdatedSuccessfully': 'Hotspot успешно обновлен',
    'hotspot.notificationRADIUSOperationSuccessfull': 'Успешно',
    'hotspot.operatingClassIndication': 'Индикация рабочих каналов',
    'hotspot.operatorFriendlyNameList': 'Отображаемое имя оператора/провайдера',
    'hotspot.organizationID': 'ID организации',
    'hotspot.port': 'Порт',
    'hotspot.protocol': 'Протокол',
    'hotspot.realm': 'NAI Realm',
    'hotspot.realmNoAuth': 'нет аутентификаций',
    'hotspot.realmNoData': 'Нет данных для отображения',
    'hotspot.roaming': 'Роуминг',
    'hotspot.roamingConsortiumList': 'Список Roaming Consortium',
    'hotspot.selectClass': 'Выберите класс',
    'hotspot.status': 'Статус',
    'hotspot.threeGPP': 'Список сотовых сетей 3gpp',
    'hotspot.type': 'Тип',
    'hotspot.unauthenticatedEmergency': 'Доступность экстренных вызовов для неавторизованных пользователей',
    'hotspot.venue': 'Локация',
    'hotspot.WANMetrics': 'Метрики WAN',
    'hotspot.wizard1Title': 'Заполните основные и сетевые параметры "Hotspot"',
    'hotspot.wizard2Title': 'Заполните дополнительные параметры "Hotspot"',
    'hotspot.wizard3Title': 'Заполните дополнительные параметры "Hotspot"',
    'hotspot.wizard4Title': 'Подтвердите создание "Hotspot"',
    'hotspot.wizardGeneral': 'Основные настройки',
    'hotspot.wizardNamePlaceholder': 'Введите имя для Hotspot 2.0',
    'imgAndVideoRecommendations.adsAndPollsImgDesktop': 'Для планшетов и ноутбуков. Рекомендованный размер 1920*1080',
    'imgAndVideoRecommendations.adsAndPollsImgMobile': 'Для смартфонов. Рекомендованный размер 1080*1920',
    'imgAndVideoRecommendations.adsAndPollsVideo': 'Рекомендованный размер 1080*1920',
    'imgAndVideoRecommendations.pagesBackground': '',
    'imgAndVideoRecommendations.pagesLogoFooter': 'Рекомендованная ширина 400px',
    'imgAndVideoRecommendations.pagesLogoTop': 'Рекомендованная ширина 400px',
    'licenses.avgValueCaption': 'Среднее значение',
    'licenses.avgValuesCaption': 'Средние значения',
    'licenses.forTheSelectedPeriodCaption': 'за выбранный период',
    'licenses.licenses': 'Лицензии',
    'licenses.licensesAllConnected': 'Подключенные точки доступа, Wi-Fi Target и тепловые карты',
    'licenses.licensesStats': 'Статистика подключенных лицензий',
    'licenses.licensesStatsClose': 'К лицензиям',
    'licenses.notificationLicenseSuccessfullyUpdated': 'Лицензия успешно обновлена',
    'licenses.notificationNewLicenseFileSuccessfullyAdded': 'Новый файл лицензии успешно добавлен',
    'licenses.notificationNewLicenseSuccessfullyAdded': 'Новая лицензия успешно добавлена',
    'licenses.notificationWrongLicenseCode': 'Неверный код лицензии',
    'licenses.notificationWrongLicenseFile': 'Неверный файл лицензии',
    'licenses.types.cpes': 'Точки доступа',
    'licenses.types.heatmap': 'Тепловые карты',
    'licenses.types.radar': 'Wi-Fi таргет',
    'location.active_menu_item_color': 'Цвет активного пункта меню',
    'location.address': 'Адрес',
    'location.addressNotFound': 'Адрес не найден',
    'location.background_color': 'Цвет фона',
    'location.byUser': 'пользователем',
    'location.company': 'Компания',
    'location.companyCaptionForTable': 'Компания',
    'location.companyNotFound': 'Компания не найдена',
    'location.coords': 'Координаты',
    'location.coordsLat': 'шир.',
    'location.coordsLatFull': 'Широта',
    'location.coordsLng': 'долг.',
    'location.coordsLngFull': 'Долгота',
    'location.created': 'Создано',
    'location.custom_colors': 'Изменение цветов',
    'location.custom_logo': 'Специальный логотип для локации',
    'location.custom_styles_enable': 'Включить изменение цветов для локации',
    'location.delete': 'Удалить локацию',
    'location.deleteLocationDescriptionMsg': 'Все объекты удаляемой локации перейдут в локацию верхнего уровня. Например, при удалении локации "/wimark/office" все ТД локации "/office" перейдут в локацию "/wimark".',
    'location.deleteText': 'При удалении локации также будут удалены все дочерние локации и все пользователи таких локаций. Пожалуйста, подтвердите удаление.',
    'location.descendant': "Для создания вложенных локаций используйте символ '/' как разделитель",
    'location.description': 'Описание',
    'location.edit': 'Редактировать локацию',
    'location.enterAddressToSearch': 'Введите адрес для поиска координат на карте',
    'location.enterCompanyNameToSearch': 'Введите имя компании для поиска',
    'location.footer': 'Нижний колонтитул',
    'location.formal_name': 'Формальное название',
    'location.header': 'Верхний колонтитул',
    'location.hover_menu_item_color': 'Цвет пункта меню при наведении мыши',
    'location.img_upload_error': 'Файл должен быть изображением и его размер должен быть меньше 300kB',
    'location.inn': 'ИНН',
    'location.innCaptionForTable': 'ИНН',
    'location.innValidatorErrorMsg': 'Поле ИНН может содержать только цифры',
    'location.interfaceLogo': 'Логотип',
    'location.kpp': 'КПП',
    'location.kppValidatorErrorMsg': 'Поле КПП может содержать только цифры',
    'location.limits.cpe': 'Точки доступа',
    'location.limits.header': 'Ограничения',
    'location.limits.heatmap': 'Тепловые карты',
    'location.limits.portal': 'Портал',
    'location.limits.radar': 'Wi-Fi таргет',
    'location.list': 'Список локаций',
    'location.locationDescription': 'Описание локации',
    'location.locId': 'ID локации',
    'location.main_block': 'Основной блок',
    'location.manager': 'Ответственное лицо',
    'location.managerCaption': 'Если поле не заполнено, вы будете назначены ответственным лицом',
    'location.managerShort': 'Ответственный',
    'location.mapCaption': 'Карта',
    'location.menu_item_text_color': 'Цвет текста пункта меню',
    'location.name': 'Название',
    'location.new': 'Добавить локацию',
    'location.notificationLocationCreatedSuccessfully': 'Локация успешно создана',
    'location.notificationLocationDeletedSuccessfully': 'Локация успешно удалена',
    'location.notificationLocationUpdatedSuccessfully': 'Локация успешно обновлена',
    'location.ogrn': 'ОГРН',
    'location.ogrnValidatorErrorMsg': 'Поле ОГРН может содержать только цифры',
    'location.owner': 'Владелец',
    'location.path': 'Имя локации (путь)',
    'location.phone': 'Телефон',
    'location.phoneCaptionForTable': 'Тел.',
    'location.phoneRequiredErrorMsg': 'Необходимо заполнить поле "Телефон", т.к по нему происходит выставление счетов.',
    'location.sameAsSelecteted': 'Скопировать настройки из',
    'location.searchСompanCaption': 'Поиск компании',
    'location.searchAddressCaption': 'Поиск адреса',
    'location.sidebar': 'Боковое меню',
    'location.someFieldsHaveErrors': 'в некоторых полях есть ошибки',
    'location.text_color': 'Цвет текста',
    'location.withChildLocations': 'с дочерними локациями',
    'login.customPasswordValidation': 'Пароль может содержать только следующие спецсимволы: _ - # $ & ^ * @ !',
    'login.goToLoginButton': 'Перейти на страницу логина',
    'login.autologout.extendModal.extendSession': 'Продлить сессию',
    'login.autologout.extendModal.closeSession': 'Завершить сессию',
    'login.autologout.extendModal.heading': 'Пользовательская сессия истекает',
    'login.autologout.extendModal.text': 'Осталось {timeLeft} сек. Вы хотели бы продлить?',
    'login.autologout.afterStatusText': 'Пользовательская сессия завершена успешно',
    'login.login': 'Войти',
    'login.loginHeader': 'Вход',
    'login.newPasswordConfirmationField': 'Подтверждение нового пароля',
    'login.newPasswordField': 'Новый пароль',
    'login.password': 'Пароль',
    'login.passwordSuccessfullySet': 'Пароль успешно установлен',
    'login.resetPassword': 'Забыли пароль?',
    'login.resetPasswordHeader': 'Сбросить пароль',
    'login.resetPasswordHeaderCaption': 'Введите имя пользователя',
    'login.resetPasswordNewPassword': 'Введите новый пароль',
    'login.resetPasswordNewPasswordCaption': 'Введите новый пароль',
    'login.resetPasswordNewPasswordConfirm': 'Введите новый пароль еще раз',
    'login.resetPasswordNewPasswordConfirmationError': 'Пароли не совпадают.',
    'login.resetPasswordNewPasswordHeader': 'Новый пароль',
    'login.resetPasswordNewPasswordSubmitButton': 'Отправить',
    'login.resetPasswordNewPasswordSuccessMessage': 'Новый пароль был успешно установлен.',
    'login.resetPasswordNewPasswordTokenExpiredMessage': 'Токен просрочен. Запросите сброс пароля еще раз',
    'login.resetPasswordPageBackButton': 'Назад',
    'login.resetPasswordPageSendButton': 'Отправить ссылку для сброса пароля',
    'login.resetPasswordPageSuccessMessage': 'Ссылка на сброс пароля отправлена на ваш e-mail.',
    'login.signHeader': 'Войдите в аккаунт',
    'login.support': 'поддержка',
    'login.username': 'Имя пользователя',
    'login.errors.WRONG_LOGIN_OR_PASSWORD': 'Неверное имя пользователя или пароль',
    'login.errors.USER_NOT_FOUND': 'Пользователь с таким именем не найден',
    'logs.container': 'Контейнер',
    'logs.filter': 'Фильтр',
    'logs.filter.noSelected': 'Не выбран',
    'logs.datetime': 'Дата и время',
    'logs.filter.analytics': 'Аналитика',
    'logs.filter.controller': 'Контроллер',
    'logs.filter.web': 'Веб-клиент',
    'logs.filter.cpu-utilization': 'Превышение заданной утилизации CPU',
    'logs.filter.ram-utilization': 'Превышение заданной утилизации RAM',
    'logs.filter.disk-utilization': 'Превышение заданной утилизации диска',
    'logs.filter.net-utilization': 'Превышение заданной утилизации сетевого интерфейса',
    'logs.filter.user-login': 'Логин административного пользователя',
    'logs.filter.ap-status': 'Подключение/отключение ТД от контроллера',
    'logs.filter.client-status': 'Подключение/отключение клиента',
    'logs.filter.ap-channel': 'Изменение рабочего канала радиоинтерфейса ТД',
    'logs.filter.ap-power': 'Изменение уровня излучаемой мощности радиоинтерфейса ТД',
    'logs.filter.client-psk-mismatch': 'Блокировка клиентского устройства из-за подбора пароля',
    'logs.filter.portal': 'Портал',
    'logs.debugging': 'Отладка',
    'logs.informational': 'Информационный',
    'logs.ipConfig': 'Конфигурация IP-адреса',
    'logs.ipType': 'Тип IP-адреса',
    'logs.ipv4ipv6address': 'IPV4 / IPV6 адрес сервера',
    'logs.label': 'Метка',
    'logs.level': 'Уровень журнала',
    'logs.levelSettings': 'Настройки уровня журнала',
    'logs.manageServers': 'Управление серверами системного журнала',
    'logs.messageBuffer': 'Конфигурация буфера сообщений',
    'logs.numberOfLogs': 'Строк',
    'logs.server': 'Сервер',
    'logs.serverType': 'Тип сервера',
    'logs.syslog': 'Системный журнал (Syslog)',
    'logs.value': 'Значение',
    'logs.vrfName': 'Имя VRF',
    'maps.addMapButton': 'Добавить карту',
    'maps.allLocations': 'Все локации',
    'maps.availableCPEHint': 'Точки доступа, которые в одной локации с картой, и, которые еще не добавлены на карту.',
    'maps.availableCPEs': 'Доступные ТД',
    'maps.bubbleRX': 'вх.',
    'maps.bubbleTX': 'исх.',
    'maps.bubleConnectedClients': 'подкл. посетители',
    'maps.bublePasserbyClients': 'прохожие',
    'maps.byCPEs': 'по ТД',
    'maps.changedCPEsModalText': 'Есть несохраненные изменения точек доступа. Вы уверены, что хотите закрыть режим планирования?',
    'maps.changedCPEsModalTitle': 'Есть несохраненные изменения',
    'maps.clientCoordsButton': 'Посетители',
    'maps.clientCoordsHint': 'Последние координаты путей посетителей',
    'maps.Clients': 'Посетители',
    'maps.close': 'Закрыть',
    'maps.closeAndShowOtherMaps': 'закрыть и показать другие карты',
    'maps.closeCurrentMap': 'закрыть текущую карту',
    'maps.connected': 'подк.посетит.',
    'maps.connectedCounterTitle': 'Подключенные',
    'maps.connectedHint': 'Подключенные к Wi-Fi посетители',
    'maps.countersTitle': 'Счетчики',
    'maps.cpe': 'ТД',
    'maps.cpeControlTitle': 'Список ТД',
    'maps.cpeInfoAboutUsers': 'Информация о клиентах ТД',
    'maps.cpeNamePlaceHolder': 'Имя ТД',
    'maps.cpePopUpID': 'ID',
    'maps.cpePopUpIP': 'IP',
    'maps.cpePopUpMAC': 'MAC',
    'maps.cpePopUpModel': 'Модель',
    'maps.cpePopUpWifiRadar': 'Wi-Fi таргет',
    'maps.cpeShowMore': 'Больше информации',
    'maps.cpeStatisticCPULoad': 'ЦП',
    'maps.cpeStatisticMemory': 'Пам.',
    'maps.cpeStatisticUsers': 'Клиенты',
    'maps.cpesTooltipForTable': 'Точки доступа',
    'maps.cpeUsers': 'подключенные клиенты',
    'maps.Created': 'Дата создания',
    'maps.deleteMapModalText': 'Вы действительно хотите удалить карту',
    'maps.deleteMapModalTitle': 'Удалить карту',
    'maps.dynamicsTitle': 'Динамика',
    'maps.editMapModalOpenButton': 'Редактировать карту',
    'maps.editMapModalTitle': 'Редактирование карты',
    'maps.editScaleMode': 'режим изм.масштаба',
    'maps.enterMapNamePlaceholder': 'Введите имя карты',
    'maps.enterZoneNamePlaceholder': 'Введите имя зоны',
    'maps.error': 'Ошибка',
    'maps.filteringNightClientsCaption': 'Фильт. посетит.',
    'maps.filteringNightClientsModalCaption': 'Фильтрация посетителей',
    'maps.filteringNightClientsModalText': 'Режим фильтрации посетителей позволяет отсеивать посетителей, замеченных в нерабочее время объекта. Для возможности включения режима необходимо установить три настройки времени в окне редактирования карты (начало, окончание и часовой пояс)',
    'maps.found': 'Найдено посетителей',
    'maps.foundOne': 'Найден',
    'maps.heatmap': 'Тепловая карта',
    'maps.heatmapLegendHeader': 'Легенда (посетители)',
    'maps.highlightCPEsInfoCaption': 'Подсветка информации о ТД',
    'maps.highlightCPEsInfoHint': 'Подсвечивает информацию о ТД на карте для лучшей читаемости. Позволяет улучшить видимость на черно-белых картах.',
    'maps.lastConnected': 'Последнее подключение',
    'maps.lastSeen': 'Посл. появление',
    'maps.lastUpdated': 'Последнее обновление',
    'maps.layersControl': 'Управление слоями',
    'maps.locationPlaceholder': 'Локация',
    'maps.mapActions': 'Режимы',
    'maps.mapsList': 'Список карт',
    'maps.mapsWizardFillMapParameters': 'Заполните параметры карты',
    'maps.mapsWizardHeight': 'высота',
    'maps.mapsWizardPx': 'пикс',
    'maps.mapsWizardStep1Title': 'Выберите файл изображение для карты',
    'maps.mapsWizardStep2Title': 'Заполните обязательные поля',
    'maps.mapsWizardStep3Title': 'Настройки карты',
    'maps.mapsWizardTitle': 'Помощник создания карты',
    'maps.mapsWizardWidth': 'ширина',
    'maps.newZoneNameError': 'Имя зоны должно быть больше 0 и меньше чем 22 символа',
    'maps.noAvailableCPEs': 'Нет доступных ТД',
    'maps.noCpeConnectedUsersToDisplay': 'Нет подключенных клиентов',
    'maps.noCpeData': 'Нет данных о ТД',
    'maps.noCpeDataFull': 'Нет данных о ТД. Возможно ТД из локации, недоступной вам.',
    'maps.noData': 'нет данных',
    'maps.notFound': 'не найдено',
    'maps.notificationMapCPEpositionupdated': 'Позиция точки доступа успешно обновлена',
    'maps.notificationMapCreatedSuccessfully': 'Карта успешно создана',
    'maps.notificationMapDeletedSuccessfully': 'Карта успешно удалена',
    'maps.notificationMapScaleUpdated': 'Масштаб карты успешно обновлен',
    'maps.notificationMapZonesUpdated': 'Зоны успешно обновлены',
    'maps.onlyOnePointInPath': 'В пути только одна точка.',
    'maps.passerby': 'прохожие',
    'maps.passerbyCounterTitle': 'Прохожие',
    'maps.passerbyHint': 'Посетители проходящие около точки доступа',
    'maps.planingMode': 'режим планирования',
    'maps.playButton': 'Старт',
    'maps.refresh': 'обновить',
    'maps.saveAndCreateButton': 'Сохранить и создать',
    'maps.saveAndHandleClients': 'Сохранить изменения',
    'maps.scaleModeDangerText': 'Все предыдущие данные о посетителях будут недоступны',
    'maps.scaleModeDistance': 'Дистанция',
    'maps.scaleModeOf': 'из',
    'maps.scaleModePlaceholder': 'Введите дистанцию',
    'maps.scaleModeSave': 'Сохранить',
    'maps.scaleModeSetTwoPoints': 'Сначала установите две точки на карте',
    'maps.scaleModeTypeDistance': 'Теперь введите дистанцию между ними',
    'maps.serchByClientMAC': 'Поиск посетителей по MAC адресу',
    'maps.setMapUnitsPerPixel': 'Установите единицы карты',
    'maps.setMapUnitsPerPixelNew': 'Задайте масштаб',
    'maps.showClients': 'Посетители',
    'maps.showCPEs': 'Показать ТД',
    'maps.showMode': 'режим просмотра',
    'maps.showPath': 'показ. путь',
    'maps.showZones': 'Показать зоны',
    'maps.sortTypeCreated': 'По времени созд.',
    'maps.sortTypeName': 'По имени',
    'maps.sortTypeUpdated': 'По времени обновл.',
    'maps.statisticsTitle': 'Статистика',
    'maps.stopButton': 'Стоп',
    'maps.tableOutOf': 'карт из',
    'maps.timeline': 'динамика',
    'maps.timelineConnectedTitle': 'Временной график по подкл. посетит.',
    'maps.timelineConnectedTooltipCaption': 'Подкл. посетит.',
    'maps.timelinePasserbyTitle': 'Временной график по прохожим',
    'maps.timelinePasserbyTooltipCaption': 'Прохожие',
    'maps.timelineTitle': 'Временной график по посетителям',
    'maps.timelineTrafficTitle': 'Временной график по трафику',
    'maps.timelineTrafficTooltipCaption': 'Трафик',
    'maps.traffic': 'трафик',
    'maps.trafficCounterTitle': 'Трафик',
    'maps.trafficHint': 'Данные по трафику',
    'maps.user': 'посетитель',
    'maps.users': 'посетителей',
    'maps.vendor': 'Вендор',
    'maps.workTimeCaption': 'Настройки времени работы',
    'maps.workTimeHint': 'Позволяет установить время работы объекта для лучшей фильтрации данных посетителей. Для работы нужно заполнить все три поля.',
    'maps.workTimeStart': 'Начало',
    'maps.workTimeStartHint': 'Время начала работы в формате ЧЧ:ММ (например 10:00)',
    'maps.workTimeStartPlaceholder': 'в формате ЧЧ:ММ',
    'maps.workTimeStop': 'Окончание',
    'maps.workTimeStopHint': 'Время окончания работы в формате ЧЧ:ММ (например 21:00)',
    'maps.workTimeStopPlaceholder': 'в формате ЧЧ:ММ',
    'maps.workTimeTimezone': 'Часовой пояс',
    'maps.workTimeTimezoneMultiselectPlaceholder': 'Выберите часовой пояс',
    'maps.zoneClientsCounterCaptionInZoneTooltip': 'Посетители',
    'maps.zoneCreating': 'Новая зона',
    'maps.zoneEditMode': 'режим изм.зон',
    'maps.zoneIntersectionError': 'Зоны не должны пересекаться',
    'maps.zoneName': 'Имя зоны',
    'maps.zoneNameCaption': 'Имя зоны',
    'maps.zoneNameCaptionInZoneTooltip': 'Зона',
    'maps.zonesAddNewName': 'Имя для новой зоны',
    'maps.zonesForTable': 'Зоны',
    'maps.zonesTooltipForTable': 'Зоны',
    'marketing.all': 'Все точки доступа',
    'marketing.allCpes': 'Все точки доступа',
    'marketing.allCpesSelected': 'Выбраны все ТД',
    'marketing.allRadars': 'Все Wi-Fi таргет',
    'marketing.allVisitors': 'Все',
    'marketing.avgDwellTime': 'Ср. продолж. визита',
    'marketing.avgLoadingDataTimeMsg': 'Среднее время ожидания загрузки данных с выбранным интервалом составляет',
    'marketing.avgVisitFreq': 'Ср. частота визитов',
    'marketing.byDefault': 'по умолчанию',
    'marketing.columnNameForDownloads': 'Название',
    'marketing.columnPercentsForDownloads': 'Проценты',
    'marketing.columnValueForDownloads': 'Значение',
    'marketing.count': 'Количество',
    'marketing.counters': 'Кассы',
    'marketing.cpes': 'Точки доступа',
    'marketing.createExportButtonCaption': 'Создать сегмент',
    'marketing.createExportButtonInfo': 'Позволяет перейти в раздел "Сегменты Wi-Fi Таргет" и приступить к созданию нового сегмента используя выбранные точки доступа, локацию и диапазон дат',
    'marketing.cVisits': 'Визиты',
    'marketing.cVisitsVisitors': 'Посетители',
    'marketing.date': 'Дата',
    'marketing.dateForDownloads': 'Дата',
    'marketing.datePickerCaption': 'Диапазон дат',
    'marketing.deleteBenchmark': 'удалить сравнение',
    'marketing.downloadData': 'выгрузить данные',
    'marketing.exitWithoutPurchase': 'Выход без покупки',
    'marketing.fittingRoom': 'Примерочные',
    'marketing.footTraffic': 'Посещаемость',
    'marketing.h': 'ч.',
    'marketing.heatmap': 'Тепловые карты',
    'marketing.hideDescription': 'Скрыть описание',
    'marketing.housewares': 'Товары для дома',
    'marketing.infoAboutWifiTargetBegin': 'При выбранных условиях фильтрации имеется ',
    'marketing.infoAboutWifiTargetEnd': 'c включенным Wi-Fi таргет',
    'marketing.location': 'Локация',
    'marketing.mall': 'Магазин',
    'marketing.menDept': 'Мужской отдел',
    'marketing.min': 'мин',
    'marketing.new': 'Новые',
    'marketing.percent': 'Процент',
    'marketing.radar': 'Wi-Fi таргет',
    'marketing.repeat': 'Повторные',
    'marketing.repeatVisitors': 'Повторные посетители',
    'marketing.report': 'Отчет',
    'marketing.rVbyF': 'Анализ повторных визитов',
    'marketing.sales': 'Покупатели',
    'marketing.sec': 'сек',
    'marketing.selectCompanyPlaceholder': 'Выберите компанию',
    'marketing.selectLocationPlaceholder': 'Выберите локацию',
    'marketing.showDescription': 'показать описание',
    'marketing.submit': 'Обновить',
    'marketing.timeframe': 'Период',
    'marketing.trafficByZone': 'Детализация по отделам',
    'marketing.uniqueVisitors': 'Уникальные посетители',
    'marketing.vendor': 'Производитель',
    'marketing.vendors': 'Производители',
    'marketing.venue': 'Магазин',
    'marketing.visitors': 'Посетители',
    'marketing.visits': 'Визиты',
    'marketing.visits1': '1 визит',
    'marketing.visits10': '>= 10 визитов',
    'marketing.visits2': '2 визита',
    'marketing.visits34': '3-4 визита',
    'marketing.visits59': '5-9 визитов',
    'marketing.withoutS': 'Выход без покупки',
    'marketing.withS': 'Остановились около касс',
    'marketing.womenDept': 'Женский отдел',
    'marketing.workTimeBlockCaption': 'Фильтрация по времени работы',
    'marketing.workTimeFilterEnabled': 'вкл.',
    'marketing.workTimeHint': 'Позволяет установить время работы для фильтрации данных.',
    'marketing.workTimeStart': 'Начало работы',
    'marketing.workTimeStartHint': 'Время начала работы в формате ЧЧ:ММ (например 10:00)',
    'marketing.workTimeStartPlaceholder': 'в формате ЧЧ:ММ',
    'marketing.workTimeStop': 'Окончание работы',
    'marketing.workTimeStopHint': 'Время окончания работы в формате ЧЧ:ММ (например 21:00)',
    'marketing.workTimeStopPlaceholder': 'в формате ЧЧ:ММ',
    'marketing.workTimeTimezone': 'Часовой пояс',
    'marketing.workTimeTimezoneMultiselectPlaceholder': 'Выберите часовой пояс',
    'marketing.workTimeTimezoneNoSelectError': 'Поле "Часовой пояс" обязательно для заполнения',
    'marketing.zone': 'Отделы',
    'misc.$eq': 'Равно',
    'misc.$gt': 'Больше чем',
    'misc.$lt': 'Меньше чем',
    'misc.blacklist': 'Черный список',
    'misc.current': 'Выбранные',
    'misc.items': 'Элементы',
    'misc.no_data': 'Нет данных',
    'misc.no_errors': 'Нет ошибок',
    'misc.no_name': 'Нет имени',
    'misc.none': 'Нет',
    'misc.whitelist': 'Белый список',
    'monitor.addRule': 'Добавить правило',
    'monitor.noRules': 'Нет настроенных правил',
    'monitor.analyzerInfoMessage': 'Анализатор Wi-Fi работает только если в настройках интерфейса включено сканирование радиоэфира.',
    'monitor.clientsEvents': 'Клиентские события',
    'monitor.cpesEvents': 'События ТД',
    'monitor.cpesState': 'Статус ТД',
    'monitor.cpeState': 'Статус ТД',
    'monitor.cpuLoad': 'Загр. процессора',
    'monitor.createRule': 'Создание правила',
    'monitor.data': 'Данные',
    'monitor.enterRuleName': 'Введите имя правила',
    'monitor.error': 'Ошибок',
    'monitor.freeRAM': 'Своб. память',
    'monitor.info': 'Событий',
    'monitor.lastScanDate': 'Время последнего сканирования: ',
    'monitor.notificationRuleCopyingScriptWasSuccessful': 'Скрипт успешно скопирован',
    'monitor.notificationRuleCopyingScriptWasUnsuccessful': 'Ошибка при копировании скрипта',
    'monitor.notificationRuleCreatedSuccessfully': 'Правило успешно создано',
    'monitor.range': 'Диапазон',
    'monitor.ruleConfirmDeleting': 'Подтвердите удаление',
    'monitor.ruleDelete': 'Удаление правила',
    'monitor.ruleDescription': 'Укажите границу правила (красная линия). При заходе значений на данный диапазон будет приходить уведомление.',
    'monitor.ruleEvents': 'Правила',
    'monitor.ruleParameters': 'Заполните требуемые поля правила',
    'monitor.ruleRange': 'Диапазон правила',
    'monitor.rules': 'Правила',
    'monitor.modal.applyRules.header': 'Правила для',
    'monitor.modal.applyRules.label': 'Отредактируйте набор применяемых правил к точке',
    'monitor.ruleScript': 'Скрипт',
    'monitor.ruleType': 'Тип правила',
    'monitor.ruleWizard': 'Помощник cоздания правила',
    'monitor.script': 'Скрипт',
    'monitor.sessionConnected': 'По макс. времени работы',
    'monitor.sessionDisconnected': 'По мин. времени работы',
    'monitor.sessions': 'Время работы',
    'monitor.systemEvents': 'События системы',
    'monitor.warning': 'Предупреждений',
    'monitor.wifiAnalyzer': 'Анализ Wi-Fi',
    'NewVersionChecker.modalHeader': 'Версия ПО обновлена',
    'NewVersionChecker.modalReloadButton': 'Обновить',
    'NewVersionChecker.modalText': 'Доступно обновление. Пожалуйста, нажмите «Обновить» ниже для корректной дальнейшей работы. Вы также можете принять эти обновления, обновив страницу в браузере',
    'pageprofile.add': 'Добавить страницу',
    'pageprofile.authorizationButton': 'Окно авторизации',
    'pageprofile.back': 'Фон',
    'pageprofile.background': 'Фоновое изображение',
    'pageprofile.backgroundInPreview': 'Фон',
    'pageprofile.changeColorAlert': 'Тема в окне предпросмотра изменится после сохранения настроек!',
    'pageprofile.contrast': 'Контрастная',
    'pageprofile.dateRangeforShowing': 'Диапазон дат для показа',
    'pageprofile.dayOfWeek': 'День недели',
    'pageprofile.deleteSchedule': 'Удалить расписание',
    'pageprofile.edit': 'Редактирование страницы',
    'pageprofile.enterAgreement': 'Пожалуйста, вставьте текст соглашения в поле ниже (в виде обычного текста)',
    'pageprofile.facebook': 'Facebook',
    'pageprofile.free': 'Бесплатный',
    'pageprofile.hasAgreement': 'Есть пользовательское соглашение',
    'pageprofile.hasSupport': 'Есть контакты службы поддержки',
    'pageprofile.hotel_voucher': 'Отельный ваучер',
    'pageprofile.hoursforShowing': 'Часы для показа',
    'pageprofile.imagesBlock': 'Изображения',
    'pageprofile.imageUploadedNotification': 'Изменения изображений сохранятся только после нажатия на кнопку Сохранить внизу окна.',
    'pageprofile.instagram': 'Instagram',
    'pageprofile.interface': 'Интерфейс',
    'pageprofile.isExternalUrl': 'Внешний ресурс',
    'pageprofile.light': 'Светлая',
    'pageprofile.logo': 'Логотип',
    'pageprofile.logo_footer': 'Логотип внизу',
    'pageprofile.main_color': 'Основной цвет',
    'pageprofile.new': 'Новая страница',
    'pageprofile.noSchedule': 'Нет расписания',
    'pageprofile.openPage': 'Открыть страницу',
    'pageprofile.pageClonedSuccessfully': 'Страница успешно скопирована',
    'pageprofile.pageCreatedSuccessfully': 'Страница успешно создана',
    'pageprofile.pageDeletedSuccessfully': 'Страница успешно удалена',
    'pageprofile.pages': 'Страницы',
    'pageprofile.pageScheduleStatusTextForTooltipAciveAndDayMatch': 'Показ идет',
    'pageprofile.pageScheduleStatusTextForTooltipAciveAndDayNotMatch': 'В выбранный день показ не производится из-за настроек дня недели для показа',
    'pageprofile.pageUpdatedSuccessfully': 'Страница успешно обновлена',
    'pageprofile.portalurl': 'Ссылка на портал',
    'pageprofile.portalurlCaption': 'Используйте вместе со своим доменом на странице настройки гостевого контроля',
    'pageprofile.portalurlID': 'ID для ссылки на портал',
    'pageprofile.preview': 'Предпросмотр портала',
    'pageprofile.saveurl': 'Сохраните этот адрес ссылки. Он потребуется в редиректе',
    'pageprofile.schedule': 'Расписание',
    'pageprofile.scheduleAShow': 'Запланировать показ',
    'pageprofile.scheduleClonedSuccessfully': 'Расписание успешно скопировано',
    'pageprofile.scheduleCreatedSuccessfully': 'Расписание успешно создано',
    'pageprofile.scheduleDeletedSuccessfully': 'Расписание успешно удалено',
    'pageprofile.scheduleEmpty': 'Страницы с включенным расписанием на указанную дату отсутствуют',
    'pageprofile.scheduleInfoMessage': 'Для сохранений настроек расписания необходимо нажать кнопку "Сохранить"',
    'pageprofile.scheduleUpdatedSuccessfully': 'Расписание успешно обновлено',
    'pageprofile.showHours': 'Часы для показа',
    'pageprofile.showHoursAllDay': 'весь день',
    'pageprofile.showHoursNotAllDay': 'не весь день',
    'pageprofile.sponsor': 'Спонсорский',
    'pageprofile.staff': 'Служебный',
    'pageprofile.subscription': 'Доступ по подписке',
    'pageprofile.support': 'Контакты будут отображены пользователю Портала в случае ошибки',
    'pageprofile.theme': 'Цветовая тема',
    'pageprofile.themeType': 'Тип темы',
    'pageprofile.themeType-default': 'по умолчанию',
    'pageprofile.themeType-default-beeline': 'по умолчанию-beeline',
    'pageprofile.themeType-minimalistic': 'минималистичная',
    'pageprofile.themeType-minimalistic-beeline': 'минималистичная-beeline',
    'pageprofile.themeTypeMinimalisticOrNot': 'Вид темы',
    'pageprofile.timeOfDay': 'Время дня',
    'pageprofile.timeRange': 'Расписание',
    'pageprofile.title': 'Заголовок страницы',
    'pageprofile.ToS': 'Условия использования',
    'pageprofile.urlToExternalTOS': 'URL внешнего ресурса с условиями использования',
    'pageprofile.urlToFileTOS': 'URL с условиями использования',
    'pageprofile.vk': 'Вконтакте',
    'pageprofile.voucher': 'Платный доступ по ваучерам',
    'pageprofile.warnUsage': 'Пожалуйста, обратите внимание, что удалении Страницы, которая используется Порталом, приведёт к {0}.',
    'pageprofile.warnUsageAccent': 'некорректной работе Портала',
    'paymentSystems.add': 'Добавить метод пополнения баланса',
    'paymentSystems.directions': 'Инструкция',
    'paymentSystems.edit': 'Редактирование метода пополнения баланса',
    'paymentSystems.hashKey': 'Ключ хэша',
    'paymentSystems.head': 'Заголовок инструкции',
    'paymentSystems.identity': 'Внутренний ID',
    'paymentSystems.identityCaption': 'На текущий момент доступны следующие ID: Humo, MegafonLife, Terminal',
    'paymentSystems.image': 'Изображение',
    'paymentSystems.merchant': 'Мерчант',
    'paymentSystems.merchantCaption': 'ID должен быть уникальным',
    'paymentSystems.merchantInfo': 'Уникальный номер, передаваемый поставщику Платежной системы. Нужен при пользовании API пополнения виртуального баланса платного Wi-Fi',
    'paymentSystems.name': 'Название',
    'paymentSystems.new': 'Новый метод пополнения баланса',
    'paymentSystems.notificationPaymentSystemCreatedSuccessfully': 'Метод пополнения баланса успешно создан',
    'paymentSystems.notificationPaymentSystemDeletedSuccessfully': 'Метод пополнения баланса успешно удален',
    'paymentSystems.notificationPaymentSystemUpdatedSuccessfully': 'Метод пополнения баланса успешно обновлен',
    'paymentSystems.preview': 'Предпросмотр',
    'paymentSystems.profile': 'Профиль',
    'paymentSystems.templateHash': 'Хеш шаблона',
    'paymentSystems.templateURL': 'URL шаблона',
    'paymentSystems.title': 'Платежные системы',
    'paymentSystems.tranUnique': 'Проверять ID транзакции на уникальность',
    'paymentSystems.type.ext': 'Внешний шлюз',
    'paymentSystems.type.ext_url': 'Внешний шлюз с генерацией URL',
    'paymentSystems.type.guide': 'Инструкция',
    'paymentSystems.type.type': 'Тип',
    'paymentSystems.warnUsage': 'Пожалуйста, обратите внимание, что удаление метода пополнения баланса, который используется Порталом, приведёт к {0}.',
    'paymentSystems.warnUsageAccent': 'некорректной работе Портала',
    'paywallForAuthFreemium.contactToUsCaption': 'Если вы хотите включить данный раздел — пишите нам',
    'PeriodicTasks.actionType.change_password': 'Смена пароля Wi-Fi сети',
    'PeriodicTasks.actionType.reboot_point': 'Перезагрузка ТД',
    'PeriodicTasks.allZerosInSpecEveryDurationObjectError': 'Хотя бы одно поле (дни, часы, минуты, секунды) должно быть больше 0',
    'PeriodicTasks.cancelSelectAllCpesButtonCaption': 'Очистить все выбранные ТД',
    'PeriodicTasks.cancelSelectAllWlansButtonCaption': 'Очистить все выбранные сети',
    'PeriodicTasks.createPeriodicTaskButton': 'Создать периодическую задачу',
    'PeriodicTasks.days': 'Дни',
    'PeriodicTasks.daysShort': 'дн',
    'PeriodicTasks.deletePeriodicTaskModalHeader': 'Удаление периодической задачи',
    'PeriodicTasks.deletePeriodicTaskModalText': 'Удаление периодической задачи',
    'PeriodicTasks.filtersForCpeListFiltringForCpeListIsEnabled': 'вкл.',
    'PeriodicTasks.filtersForCpeListResetFiltersButton': 'сбросить фильтр',
    'PeriodicTasks.filtersForCpeListSwitcherCaption': 'Фильтрация списка ТД',
    'PeriodicTasks.filtersForCpeListSwitcherInfoTooltip': 'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации',
    'PeriodicTasks.filtersForCpeListSwitcherLocationCaption': 'Локация точки доступа',
    'PeriodicTasks.filtersForWlanListFiltringForWlanListIsEnabled': 'вкл.',
    'PeriodicTasks.filtersForWlanListSwitcherCaption': 'Фильтрация списка Wi-Fi сетей',
    'PeriodicTasks.filtersForWlanListSwitcherInfoTooltip': 'Фильтрация упрощает поиск нужной сети Wi-Fi в списке выбора сетей. Позволяет отфильтровать сети Wi-Fi по их локации',
    'PeriodicTasks.filtersForWlanListSwitcherLocationCaption': 'Локация сети Wi-Fi',
    'PeriodicTasks.hours': 'Часы',
    'PeriodicTasks.hoursShort': 'ч',
    'PeriodicTasks.minutes': 'Минуты',
    'PeriodicTasks.minutesShort': 'мин',
    'PeriodicTasks.modalActionTypeCaption': 'Тип действия',
    'PeriodicTasks.modalActionTypePlaceholder': 'Тип действия',
    'PeriodicTasks.modalCpeListNoOptionsMsg': 'Нет точек доступа',
    'PeriodicTasks.modalCpeListNoOptionsMsgWhenFilterEnabled': 'Нет ТД, соответствующих условиям фильтров',
    'PeriodicTasks.modalCpeListNoResultMsg': 'Не найдено ТД с таким именем',
    'PeriodicTasks.modalCpeListNoResultMsgWhenFilterEnabled': 'Не найдено ТД с именем, соответствующим условиям фильтров',
    'PeriodicTasks.modalCpeListPlaceholder': 'Выберите ТД или введите имя для поиска',
    'PeriodicTasks.modalCPEsCaption': 'Точки доступа',
    'PeriodicTasks.modalHeaderForEdit': 'Редактирование периодической задачи',
    'PeriodicTasks.modalHeaderForNew': 'Новая периодическая задача',
    'PeriodicTasks.modalNameCaption': 'Имя',
    'PeriodicTasks.modalNamePlaceholder': 'Имя',
    'PeriodicTasks.modalNotifyTypeCaption': 'Тип уведомления',
    'PeriodicTasks.modalNotifyTypePlaceholder': 'Тип уведомления',
    'PeriodicTasks.modalSelectedCPEsCaption': 'Выбрано',
    'PeriodicTasks.modalSelectedCpesLengthError': 'Выберите хотя бы одну точку доступа',
    'PeriodicTasks.modalSelectedWlansCaption': 'Выбрано',
    'PeriodicTasks.modalSelectedWlansLengthError': 'Выберите хотя бы одну сеть Wi-Fi',
    'PeriodicTasks.modalSpecCaption': 'Периодичность',
    'PeriodicTasks.modalSpecPlaceholder': 'Периодичность',
    'PeriodicTasks.modalSpecRawCaption': 'Периодичность в пользовательском формате (cron-выражение)',
    'PeriodicTasks.modalSpecRawInfoMsg': 'Подробнее о формате ввода можно прочитать тут',
    'PeriodicTasks.modalSpecRawPlaceholder': 'Периодичность в пользовательском формате (cron-выражение)',
    'PeriodicTasks.modalWlanListNoOptionsMsg': 'Нет сетей Wi-Fi',
    'PeriodicTasks.modalWlanListNoOptionsMsgWhenFilterEnabled': 'Нет сетей Wi-Fi, соответствующих условиям фильтров',
    'PeriodicTasks.modalWlanListNoResultMsg': 'Не найдено сетей Wi-Fi с таким именем',
    'PeriodicTasks.modalWlanListNoResultMsgWhenFilterEnabled': 'Не найдено сетей Wi-Fi с таким именем, соответствующим условиям фильтров',
    'PeriodicTasks.modalWlanListPlaceholder': 'Выберите сеть Wi-Fi или введите имя для поиска',
    'PeriodicTasks.modalWlansCaption': 'Сети Wi-Fi',
    'PeriodicTasks.notifySettingsEmailSubjectCaption': 'Тема',
    'PeriodicTasks.notifySettingsEmailSubjectPlaceholder': 'Тема письма',
    'PeriodicTasks.notifySettingsEmailTextCaption': 'Текст',
    'PeriodicTasks.notifySettingsEmailTextPlaceholder': 'Текст электронного письма',
    'PeriodicTasks.notifySettingsEmailToCaption': 'Кому',
    'PeriodicTasks.notifySettingsEmailToPlaceholder': 'Адрес e-mail',
    'PeriodicTasks.notifySettingsForTableCaption': 'Параметры действия',
    'PeriodicTasks.notifySettingsForTableForEmailToAddress': 'Письмо на адрес',
    'PeriodicTasks.notifySettingsForTableForMQTTToTopic': 'Сообщение в топик',
    'PeriodicTasks.notifySettingsForTableForTelegramToChatId': 'Сообщение в чат c id',
    'PeriodicTasks.notifySettingsForTableHide': 'Скрыть',
    'PeriodicTasks.notifySettingsForTableShowMore': 'подробнее',
    'PeriodicTasks.notifySettingsMQTTTextCaption': 'Текст',
    'PeriodicTasks.notifySettingsMQTTTextPlaceholder': 'Текст',
    'PeriodicTasks.notifySettingsMQTTTopicCaption': 'Топик',
    'PeriodicTasks.notifySettingsMQTTTopicInfo': 'MQTT топик',
    'PeriodicTasks.notifySettingsMQTTTopicPlaceholder': 'Топик',
    'PeriodicTasks.notifySettingsTelegramChatIdCaption': 'ID чата',
    'PeriodicTasks.notifySettingsTelegramChatIdInfo': 'Уникальный идентификатор telegram чата',
    'PeriodicTasks.notifySettingsTelegramChatIdPlaceholder': 'ID чата',
    'PeriodicTasks.notifySettingsTelegramTextCaption': 'Текст',
    'PeriodicTasks.notifySettingsTelegramTextPlaceholder': 'Текст для уведомления',
    'PeriodicTasks.notifySettingsTelegramTokenCaption': 'Токен',
    'PeriodicTasks.notifySettingsTelegramTokenInfo': 'Токен, получаемый у @BotFather',
    'PeriodicTasks.notifySettingsTelegramTokenPlaceholder': 'Токен',
    'PeriodicTasks.notifyType.email': 'Электронная почта',
    'PeriodicTasks.notifyType.mqtt': 'MQTT',
    'PeriodicTasks.notifyType.telegram': 'Telegram',
    'PeriodicTasks.onlyForWpaPersonalWLANSMsg': 'Смена пароля работает только для WPA Personal Wi-FI сетей (В списке для выбора сетей показаны только такие сети)',
    'PeriodicTasks.onlyForWpaWpa2PersonalWLANSMsg': 'Смена пароля работает только для WPA/WPA2 Personal Wi-FI сетей (В списке для выбора сетей показаны только такие сети)',
    'PeriodicTasks.period': 'Период',
    'PeriodicTasks.PeriodicTasksNotificationCreated': 'Периодическая задача успешно создана',
    'PeriodicTasks.PeriodicTasksNotificationDeleted': 'Периодическая задача успешно удалена',
    'PeriodicTasks.PeriodicTasksNotificationUpdated': 'Периодическая задача успешно обновлена',
    'PeriodicTasks.seconds': 'Секунды',
    'PeriodicTasks.secondsShort': 'сек',
    'PeriodicTasks.selectAllCpesButtonCaption': 'Добавить все отфильтрованные ТД',
    'PeriodicTasks.selectAllWlansButtonCaption': 'Добавить все отфильтр. сети',
    'PeriodicTasks.sortActionType': 'По типу действия',
    'PeriodicTasks.sortCpesCount': 'По количеству ТД',
    'PeriodicTasks.sortName': 'По имени',
    'PeriodicTasks.sortNotifyType': 'По типу уведомления',
    'PeriodicTasks.sortObjectsCount': 'По количеству объектов',
    'PeriodicTasks.specFieldCaption@daily': 'Ежедневно',
    'PeriodicTasks.specFieldCaption@every': 'Период',
    'PeriodicTasks.specFieldCaption@hourly': 'Ежечасно',
    'PeriodicTasks.specFieldCaption@monthly': 'Ежемесячно',
    'PeriodicTasks.specFieldCaption@weekly': 'Еженедельно',
    'PeriodicTasks.specFieldCaption@yearly': 'Ежегодно',
    'PeriodicTasks.specFieldCaptionraw': 'Пользовательский формат',
    'PeriodicTasks.specFieldInfo@daily': 'Запускается раз в день, в полночь',
    'PeriodicTasks.specFieldInfo@every': 'Запускается с заданной периодичностью',
    'PeriodicTasks.specFieldInfo@hourly': 'Запускается раз в час, в начале часа',
    'PeriodicTasks.specFieldInfo@monthly': 'Запускается раз в месяц в полночь первого числа месяца',
    'PeriodicTasks.specFieldInfo@weekly': 'Запускается раз в неделю, в полночь между сб / вс',
    'PeriodicTasks.specFieldInfo@yearly': 'Запускается раз в год, в полночь, 1 января',
    'PeriodicTasks.specFieldInforaw': 'строка в пользовательском формате (cron-выражение)',
    'PeriodicTasks.tableColumnActionType': 'Тип действия',
    'PeriodicTasks.tableColumnCPEs': 'Точки доступа',
    'PeriodicTasks.tableColumnName': 'Имя',
    'PeriodicTasks.tableColumnNotifyParameters': 'Параметры уведомления',
    'PeriodicTasks.tableColumnNotifyType': 'Тип уведомления',
    'PeriodicTasks.tableColumnObjects': 'ТД/Wi-Fi сети',
    'PeriodicTasks.tableColumnSpec': 'Периодичность',
    'PeriodicTasks.tableColumnWlans': 'Сети WI-FI',
    'PeriodicTasks.tableTitle': 'Периодические задачи',
    'PeriodicTasks.tableTooltipCPEs': 'Точки доступа',
    'PeriodicTasks.tableTooltipWlans': 'Сети WI-Fi',
    'portal.accessList': 'Список доступа',
    'portal.add': 'Добавить профиль',
    'portal.ads_to_watch': 'Количество реклам или опросов для просмотра',
    'portal.adsFollow': 'Дать доступ к интернету после клика на рекламу (опрос)',
    'portal.adsRotation': 'Показывать рекламу (опросы) в случайном порядке',
    'portal.allPrefix': 'Любой префикс',
    'portal.auth_timeout': 'Таймаут авторизации',
    'portal.auth.attachedURL': 'Прикрепленная ссылка',
    'portal.auth.groupURL': 'Ссылка на группу',
    'portal.auth.message': 'Сообщение',
    'portal.auth.redirectURL': 'Сайт для перехода',
    'portal.authentication': 'Аутентификация',
    'portal.authGroup': 'Аутентификация и подписка на группу',
    'portal.authOnly': 'Аутентификация',
    'portal.authorization': 'Авторизация',
    'portal.authPost': 'Аутентификация и публикация поста',
    'portal.blackList': 'Список заблокированных MAC',
    'portal.block_after': 'Время пользования интернетом до блокировки (в сек.)',
    'portal.block_afterCaption': 'Должно быть заполнено, если заполнено поле ниже',
    'portal.block_expire': 'Время сброса данных о блокировке (в сек.)',
    'portal.block_expireCaption': 'Должно быть заполнено, если заполнено поле выше',
    'portal.code': 'Код страны',
    'portal.download_limit': 'Лимит скачивания (в кб.)',
    'portal.duration3Days': '3 дня',
    'portal.edit': 'Редактирование профиля',
    'portal.errorMessage': 'Сообщение об ошибке при валидации',
    'portal.errorMessageCaption': 'Сообщение об ошибке, отображаемое при ошибке валидации',
    'portal.gatewaySettings.add': 'Добавить',
    'portal.gatewaySettings.blockHeader': 'Настройки шлюза отправки сообщений',
    'portal.gatewaySettings.cancel': 'Отмена',
    'portal.gatewaySettings.clear': 'Очистить',
    'portal.gatewaySettings.content_type': 'Content type',
    'portal.gatewaySettings.delete': 'Удалить',
    'portal.gatewaySettings.edit': 'Изменить',
    'portal.gatewaySettings.header': 'Имя заголовка',
    'portal.gatewaySettings.headers': 'Заголовки',
    'portal.gatewaySettings.hide': 'Скрыть',
    'portal.gatewaySettings.method': 'Метод',
    'portal.gatewaySettings.methodNoSelected': 'Не выбран',
    'portal.gatewaySettings.noHeaders': 'Нет заголовков',
    'portal.gatewaySettings.noParams': 'Нет параметров',
    'portal.gatewaySettings.param': 'Имя параметра',
    'portal.gatewaySettings.params': 'Параметры',
    'portal.gatewaySettings.payload_textarea': 'Нагрузка',
    'portal.gatewaySettings.save': 'Применить',
    'portal.gatewaySettings.url': 'URL адрес',
    'portal.gatewaySettings.value': 'Значение',
    'portal.max_sessions': 'Максимальное Количество сессий',
    'portal.mustContainCode': 'Обязательно должен содержать: {CODE}',
    'portal.nasId': 'NAS ID',
    'portal.new': 'Новый профиль',
    'portal.notification': 'Предупреждение об окончании времени сессии',
    'portal.notificationText': 'Текст сообщения',
    'portal.notificationTime': 'Время до окончания сессии (сек.)',
    'portal.noUsersInList': 'В списке нет пользователей',
    'portal.otp': 'Длина one-time password',
    'portal.otpCodeText': 'СМС текст для ОТР',
    'portal.paginationAdsOutOf': 'из оставшихся',
    'portal.paginationLoad': 'Загрузить',
    'portal.paginationOf': 'из',
    'portal.paginationPagesOutOf': 'из',
    'portal.paginationPaymentSystemsOutOf': 'из',
    'portal.paginationProfilesOutOf': 'из',
    'portal.paginationTariffsOutOf': 'из',
    'portal.paginationUserAccountsOutOf': 'из оставшихся',
    'portal.paginationVouchersOutOf': 'из оставшихся',
    'portal.paymentSystems': 'Платежные системы',
    'portal.pollquestionValidationError': 'Не могут использоваться символы: $ (доллар), . (точка)',
    'portal.portalprofiles': 'Профили',
    'portal.prefix': 'Префикс',
    'portal.redirect': 'Адрес редиректа на лендинг',
    'portal.regex': 'Регулярное выражение для валидации',
    'portal.regexCaption': 'Регулярное выражение для валидации пользовательского идентификатора на портале',
    'portal.remember': 'Время запоминания (в сек.)',
    'portal.rememberCaption': 'Время, на которое клиентская аутентификация запоминается и повторно не запрашивается',
    'portal.rememberQuick': 'Быстрый выбор:',
    'portal.skipFinalPage': 'Пропустить последнюю страницу',
    'portal.smsCodeValidationError': 'Текст должен содержать {CODE}',
    'portal.socialURLValidationError': 'URL должен содержать https://vk.com, https://facebook.com or https://instagram.com',
    'portal.thereAreUsersInList': 'В списке есть пользователи',
    'portal.timeout': 'Время сессии (в сек.)',
    'portal.transferable': 'Перенос сессии',
    'portal.transferableInfo': 'Возможность разбить сессию на несколько в рамках одного дня',
    'portal.tzCaption': 'Например: 3 (Москва), -4 (Нью-Йорк), 2 (Барселона)',
    'portal.voucherBuyHeader': 'Заголовок покупки ваучера',
    'portal.voucherBuyInfo': 'Информация о ваучерах',
    'portal.voucherCodeText': 'СМС текст для ваучеров',
    'portal.whiteList': 'Белый список',
    'portalAccessServers.bson': 'bson',
    'portalAccessServers.coaPort': 'CoA порт',
    'portalAccessServers.coaSecret': 'CoA Пароль',
    'portalAccessServers.coaVsa': 'Атрибуты CoA авторизации',
    'portalAccessServers.coaVsaForTable': 'Атрибуты CoA авториз.',
    'portalAccessServers.createAccessServerButtonCaption': 'Добавить Сервер Доступа',
    'portalAccessServers.description': 'Описание',
    'portalAccessServers.disableForTable': 'выкл.',
    'portalAccessServers.enable': 'Включено',
    'portalAccessServers.enableForTable': 'вкл.',
    'portalAccessServers.externalIP': 'Внешний IP',
    'portalAccessServers.location': 'Местоположение',
    'portalAccessServers.login': 'Логин',
    'portalAccessServers.macAuth': 'MAC Авторизация',
    'portalAccessServers.modalCoaPortFieldPlaceholder': 'CoA порт (3799 по-умолчанию)',
    'portalAccessServers.modalCoaSecretFieldPlaceholder': 'CoA Пароль',
    'portalAccessServers.modalCoaVsaFieldPlaceholder': 'Атрибуты CoA авторизации',
    'portalAccessServers.modalCreateAccessServerHeader': 'Добавление Сервера Доступа',
    'portalAccessServers.modalDescriptionFieldPlaceholder': 'Описание',
    'portalAccessServers.modalExternalIPFieldPlaceholder': 'Внешний IP',
    'portalAccessServers.modalLocationFieldPlaceholder': 'Местоположение',
    'portalAccessServers.modalLoginFieldPlaceholder': 'Логин',
    'portalAccessServers.modalNameFieldPlacheolder': 'Имя сервера доступа',
    'portalAccessServers.modalNasIdFieldPlaceholder': 'NAS ID',
    'portalAccessServers.modalNasIpFieldPlaceholder': 'NAS IP адрес',
    'portalAccessServers.modalPasswordFieldPlaceholder': 'Пароль',
    'portalAccessServers.modalPostAuthVsaFieldPlaceholder': 'Атрибуты, отправляемые после авторизации',
    'portalAccessServers.modalPreAuthVsaFieldPlaceholder': 'Атрибуты предварительной аутентификации',
    'portalAccessServers.modalUpdateAccessServerHeader': 'Редактирование сервера доступа',
    'portalAccessServers.name': 'Имя',
    'portalAccessServers.nasId': 'NAS ID',
    'portalAccessServers.nasIP': 'NAS IP',
    'portalAccessServers.noForTable': 'Нет',
    'portalAccessServers.notificationPortalAccessServerCreatedSuccessfully': 'Сервер доступа успешно создан',
    'portalAccessServers.notificationPortalAccessServerDeletedSuccessfully': 'Сервер доступа успешно удален',
    'portalAccessServers.notificationPortalAccessServerUpdatedSuccessfully': 'Сервер доступа успешно обновлен',
    'portalAccessServers.password': 'Пароль',
    'portalAccessServers.postAuthVsa': 'Атрибуты, отправляемые после авторизации',
    'portalAccessServers.postAuthVsaForTable': 'Атрибуты, отправляемые после авториз.',
    'portalAccessServers.preAuthVsa': 'Атрибуты предварительной авторизации',
    'portalAccessServers.preAuthVsaForTable': 'Атрибуты предварит. авториз.',
    'portalAccessServers.tableCaption': 'Серверы доступа',
    'portalAccessServers.type': 'Тип',
    'portalAccessServers.validatorErrors': 'Есть ошибки в заполнении полей. Проверьте поля на корректность',
    'portalBirthdays.birthdays': 'Дни рождения',
    'portalBirthdays.selectedDate': 'Выбранная дата',
    'portalBirthdays.users': 'Пользователи',
    'portalDashboard.adsPeriod': 'за все время',
    'portalDashboard.adsViewsPeriod': 'за этот месяц',
    'portalDashboard.Advert': 'Реклама',
    'portalDashboard.Advertdays': 'лучшие дни по просмотрам',
    'portalDashboard.AdvertPeriod': 'просмотры за все время',
    'portalDashboard.AdvertStatistic': 'Просмотры рекламы',
    'portalDashboard.AdvertStatisticByDates': 'по датам',
    'portalDashboard.AdvertStatisticPeriod': 'за этот месяц',
    'portalDashboard.AdvertStatisticTotal': 'Всего просмотров',
    'portalDashboard.AdvertTop3': 'лучшие 3',
    'portalDashboard.AdvertTop5': 'лучшие',
    'portalDashboard.AdvertTotal': 'Всего',
    'portalDashboard.AdvertViews': 'просмотров',
    'portalDashboard.authentications': 'Аутентификации',
    'portalDashboard.authenticationsEmail': 'Электронная почта',
    'portalDashboard.authenticationsPeriod': 'последние 7 дней',
    'portalDashboard.authenticationsSms': 'SMS',
    'portalDashboard.authenticationsTotal': 'Всего',
    'portalDashboard.authorizations': 'Авторизации',
    'portalDashboard.authorizationsPeriod': 'последние 7 дней',
    'portalDashboard.birthdays': 'Дни рождения',
    'portalDashboard.connections': 'Подключения',
    'portalDashboard.connectionsCaption': 'Количество подключений',
    'portalDashboard.connectionsNew': 'Новые пользователи',
    'portalDashboard.connectionsPeriod': 'последние 7 дней',
    'portalDashboard.connectionsRepeat': 'Повторные пользователи',
    'portalDashboard.connectionsTotal': 'Всего',
    'portalDashboard.connectionsUnique': 'Уникальные клиенты',
    'portalDashboard.locations': 'Локации',
    'portalDashboard.locationsAll': 'Все локации',
    'portalDashboard.locationsMap': 'Карта локаций',
    'portalDashboard.locationsTotal': 'Всего',
    'portalDashboard.locationsWhenManager': 'всего',
    'portalDashboard.locationsWhereCurrentUserisManager': 'Локации, где вы ответственный',
    'portalDashboard.noLocations': 'нет локаций',
    'portalDashboard.os': 'Операционные системы',
    'portalDashboard.osPeriod': 'последние 7 дней',
    'portalDashboard.recomendation': 'Рекомендация',
    'portalDashboard.recomendationVKFB': 'Рекомендация',
    'portalDashboard.showMore': 'и другие',
    'portalDashboard.smsCost': 'Стоимость СМС',
    'portalDashboard.support': 'Поддержка',
    'portalDashboard.supportText': 'Возникла проблема? Напишите нам, мы можем помочь.',
    'portalLocationsStat.adStat': 'Статистика реклам и опросов',
    'portalLocationsStat.allVisits': 'Все посещения',
    'portalLocationsStat.clicks': 'Переходы',
    'portalLocationsStat.clientsStat': 'Статистика клиентов',
    'portalLocationsStat.location': 'Локация',
    'portalLocationsStat.locationsStat': 'Статистика локаций',
    'portalLocationsStat.repeatVisit': 'Повторные посещения',
    'portalLocationsStat.total': 'Всего',
    'portalLocationsStat.uniqueVisit': 'Уникальные посещения',
    'portalLocationsStat.views': 'Просмотры',
    'portalPreview.default': 'По умолчанию',
    'portalPreview.fillData': 'Пожалуйста, заполните следующие поля',
    'portalPreview.minimalistic': 'Минималистичная',
    'portalPreview.params': 'Параметры предпросмотра',
    'portalPreview.portalLink': 'Ссылка на страницу портала',
    'portalPreview.preview': 'Предпросмотр',
    'portalProfiles.access': 'Доступ',
    'portalProfiles.accessInfo': 'Кому доступен или недоступен профиль',
    'portalProfiles.advertismentSettings': 'Настройки показа рекламы или опроса',
    'portalProfiles.authenInfo': 'Способ аутентификации (входа) в профиль на Портале авторизации. Доступно до 5 способов аутентификаций.',
    'portalProfiles.authenTypecallback': 'Бесплатный звонок',
    'portalProfiles.authenTypecallfront': 'Исходящий звонок',
    'portalProfiles.authenTypeemail': 'Электронная почта',
    'portalProfiles.authenTypeesia': 'Госуслуги',
    'portalProfiles.authenTypesms': 'СМС',
    'portalProfiles.authenTypeuserpass': 'Персональный код',
    'portalProfiles.authInfo': 'Способ авторизации на портале авторизации. Доступно до 5 способов авторизаций.',
    'portalProfiles.authnSkipCaption': 'Пропускать аутентификацию',
    'portalProfiles.authzDefaultCaption': 'Авторизация по умолчанию',
    'portalProfiles.callbackInfo': '',
    'portalProfiles.callfrontInfo': 'Аутентификация с использованием исходящего от пользователя звонка',
    'portalProfiles.checkAuthNsWhenAuthenSkipIsFalseErrorText': 'При выключенном режиме "Пропускать аутентификацию" должна быть включена хотя бы одна идентификация',
    'portalProfiles.chooseCustomInterval': 'Выберите произвольный интервал',
    'portalProfiles.condition': 'Условия для работы',
    'portalProfiles.conditionInfo': 'Профиль работает для указанных параметров. \nНельзя указывать одинаковые условия работы портального профиля (списка сетей Wi-Fi, ТД, NAS ID) на нескольких профилях - это приведет к непредсказуемому поведению!',
    'portalProfiles.countryCode': 'Код страны',
    'portalProfiles.customTimeInterval': 'Другой',
    'portalProfiles.cutDownButton': 'Свернуть ',
    'portalProfiles.emailInfo': 'Аутентификация с использованием e-mail. OTP код придет на указанную клиентом почту.',
    'portalProfiles.esiaInfo': 'Аутентификация с использованием сайта "Госуслуги"',
    'portalProfiles.facebookInfo': 'Бесплатный доступ в интернет после авторизации через Facebook',
    'portalProfiles.freeInfo': 'Бесплатный доступ в интернет',
    'portalProfiles.generalSettingsForAllAuthNApplyButton': 'Применить',
    'portalProfiles.generalSettingsForAllAuthNCaption': 'Общие настройки идентификаций',
    'portalProfiles.generalSettingsForAllAuthNInfoMsg': 'Позволяют применить некоторые настройки сразу ко всем включенным идентификациям. После применения изменений необходимо сохранить Профиль нажатием кнопки "Сохранить"',
    'portalProfiles.generalSettingsForAllAuthZApplyButton': 'Применить',
    'portalProfiles.generalSettingsForAllAuthZCaption': 'Общие настройки авторизаций (настройки сессии)',
    'portalProfiles.generalSettingsForAllAuthZInfoMsg': 'Позволяют применить некоторые настройки сразу ко всем включеным авторизациям. После применения изменений необходимо сохранить Профиль нажатием кнопки "Сохранить"',
    'portalProfiles.hotel_voucherInfo': 'Доступ к интернету после ввода кода ваучера',
    'portalProfiles.instagramInfo': 'Бесплатный доступ в интернет после авторизации через Instagram',
    'portalProfiles.lineCabinetAddressCaption': 'Адрес личного кабинета управления подпиской',
    'portalProfiles.lineCabinetCaption': 'Личный кабинет управления подпиской',
    'portalProfiles.lineCabinetEnableSwitcher': 'Включение личного кабинета управления подпиской',
    'portalProfiles.nasForTable': 'NAS',
    'portalProfiles.noAuthentication': 'нет идентификаций',
    'portalProfiles.noAuthorization': 'нет авторизаций',
    'portalProfiles.noCPEs': 'Нет точек доступа',
    'portalProfiles.noDataToDisplay': 'нет данных',
    'portalProfiles.noDescription': 'нет описания',
    'portalProfiles.noNAS': 'нет NAS',
    'portalProfiles.notificationProfileCreatedSuccessfully': 'Профиль успешно создан',
    'portalProfiles.notificationProfileDeletedSuccessfully': 'Профиль успешно удален',
    'portalProfiles.notificationProfileUpdatedSuccessfully': 'Профиль успешно обновлен',
    'portalProfiles.noWLANs': 'Нет сетей Wi-Fi',
    'portalProfiles.otpCodeText': 'Текст одноразового кода',
    'portalProfiles.otpInfo': 'Текст сообщения с одноразовым кодом (OTP). Обязательно должно содержать {CODE}',
    'portalProfiles.phonePrefixOptional': 'Префикс (опционально)',
    'portalProfiles.profileEdit': 'Редактирование профиля',
    'portalProfiles.sameAsForAuthN': 'Такие же настройки',
    'portalProfiles.sameAsForAuthZ': 'Такие же настройки',
    'portalProfiles.sessionSettings': 'Настройки сессии',
    'portalProfiles.settingsForAuthZandAuthN': 'Настройки',
    'portalProfiles.showMacSettingsView': 'Показывать инструкцию при подключении частных MAC адресов',
    'portalProfiles.skipInfo': 'Бесплатный доступ в интернет без авторизации',
    'portalProfiles.smsInfo': 'Аутентификация с использованием SMS. OTP код придет на указанный пользователем телефон.',
    'portalProfiles.socialAuthRequiredFieldsNotForgetToFillNotificationMsg': 'не забудьте заполнить обязательные поля',
    'portalProfiles.sponsorInfo': 'Бесплатный доступ в интернет от спонсора',
    'portalProfiles.staffInfo': 'Бесплатный доступ в интернет для сотрудников',
    'portalProfiles.subscriptionInfo': 'Доступ к интернету после оформления подписки',
    'portalProfiles.tosInfo': 'Показывать пользователю "Условия использования" и спрашивать его согласие с ними',
    'portalProfiles.userpassInfo': 'Аутентификация с использованием заранее полученного кода. Доступна только для пользователей из списка доступа.',
    'portalProfiles.validatorErrors': 'Есть ошибки в заполнении полей. Проверьте все поля, в т.ч и в свернутых категориях',
    'portalProfiles.vkInfo': 'Бесплатный доступ в интернет после авторизации через VK',
    'portalProfiles.voucherInfo': 'Доступ к интернету после ввода кода ваучера',
    'portalProfiles.vouchersSettings': 'Настройки ваучера',
    'portalSessions.allCpes': 'Все точки доступа',
    'portalSessions.allWlans': 'Все Wi-Fi сети',
    'portalSessions.authenStatechecked': 'Аутентификация пройдена',
    'portalSessions.authenStateneed': 'Идентификация не пройдена',
    'portalSessions.authenStatesent': 'Ожидается ввод кода или звонок',
    'portalSessions.authenStateTableCaption': 'Статус аутентификации',
    'portalSessions.authenStateunset': 'Все',
    'portalSessions.authenTypecallback': 'Бесплатный звонок',
    'portalSessions.authenTypecallfront': 'Исходящий звонок',
    'portalSessions.authenTypeemail': 'Электронная почта',
    'portalSessions.authenTypeesia': 'Госуслуги',
    'portalSessions.authenTypesms': 'СМС',
    'portalSessions.authenTypeTableCaption': 'Тип аутентификации',
    'portalSessions.authenTypeunset': 'Все',
    'portalSessions.authenTypeuserpass': '#Н/Д',
    'portalSessions.authZStatechecked': 'Авторизация пройдена',
    'portalSessions.authZStatenone': 'Авторизация не пройдена',
    'portalSessions.authZStateTableCaption': 'Статус авторизации',
    'portalSessions.authZStateunset': 'Все',
    'portalSessions.authZTimeBeginTableCaption': 'Начало авторизации пользователя',
    'portalSessions.authZTimeEndTableCaption': 'Окончание авторизации пользователя',
    'portalSessions.authZTypefacebook': 'Facebook',
    'portalSessions.authZTypefb': 'Facebook',
    'portalSessions.authZTypefree': 'Бесплатный',
    'portalSessions.authZTypehotel_voucher': 'Отельный ваучер',
    'portalSessions.authZTypeinstagram': 'Instagram',
    'portalSessions.authZTypenone': '',
    'portalSessions.authZTypenotSelect': 'Все',
    'portalSessions.authZTypeskip': 'Пропустить',
    'portalSessions.authZTypesponsor': 'Спонсорский',
    'portalSessions.authZTypestaff': 'Служебный',
    'portalSessions.authZTypesubscription': 'Подписка',
    'portalSessions.authZTypeTableCaption': 'Тип авторизации',
    'portalSessions.authZTypetype': 'Тип',
    'portalSessions.authZTypeunset': 'Не указано',
    'portalSessions.authZTypevk': 'Вконтакте',
    'portalSessions.authZTypevoucher': 'Ваучер',
    'portalSessions.browser': 'Браузер',
    'portalSessions.costForTable': 'Стоимость СМС',
    'portalSessions.costForTableInfo': 'Стоимость указана для сессий с типом идентификации СМС',
    'portalSessions.cpe': 'ТД',
    'portalSessions.cpeTableCaption': 'ТД',
    'portalSessions.create': 'Дата',
    'portalSessions.create_at': 'Дата',
    'portalSessions.creationSessionForTable': 'Время создания сессии',
    'portalSessions.dateTimeFiltersCaption': 'Фильтры по дате и времени',
    'portalSessions.deleteSessionButtonCaption': 'Удалить сессию',
    'portalSessions.deleteSessionModalConfirm': 'Подтверждаете удаление сессии',
    'portalSessions.deleteSessionModalHeader': 'Удаление сессии',
    'portalSessions.expirationSessionForTable': 'Время истечения сессии',
    'portalSessions.getSessions': 'Показать {num} сессий',
    'portalSessions.identityTableCaption': 'Идентификатор',
    'portalSessions.ip': 'IP',
    'portalSessions.isDeletedForDownloadsForTableCaption': 'Удалена',
    'portalSessions.isDeletedForDownloadsNo': 'Нет',
    'portalSessions.isDeletedForDownloadsYes': 'Да',
    'portalSessions.mac': 'MAC',
    'portalSessions.nas_id': 'NAS ID',
    'portalSessions.oneOrMoreDateTimeFiltersAreEnabled': 'один или несколько фильтров включены',
    'portalSessions.os': 'ОС',
    'portalSessions.osVersion': 'Версия ОС',
    'portalSessions.profile': 'Профиль',
    'portalSessions.sessionCreationDate': 'Время создания сессии',
    'portalSessions.sessionCreationExpirationDate': 'Время создания сессии / Время истечения сессии',
    'portalSessions.sessionIsDelitedfalse': 'Не удалена',
    'portalSessions.sessionIsDelitedTitleCaption': 'Сессия удалена',
    'portalSessions.sessionIsDelitedtrue': 'Удалена',
    'portalSessions.sessionIsDelitedunset': 'Все',
    'portalSessions.sessions': 'Пользовательские сессии',
    'portalSessions.socialNetworksFirstNameTableCaption': 'Имя',
    'portalSessions.socialNetworksIdTableBirthday': 'День рождения',
    'portalSessions.socialNetworksIdTableCaption': 'ID',
    'portalSessions.socialNetworksIdTableCity': 'Город',
    'portalSessions.socialNetworksIdTableEmail': 'Электронная почта',
    'portalSessions.socialNetworksNameTableCaption': 'Имя',
    'portalSessions.socialNetworksSecondNameTableCaption': 'Фамилия',
    'portalSessions.stateTableCaption': 'Статус сессии',
    'portalSessions.stateTypeadvertise': 'Реклама или опрос',
    'portalSessions.stateTypeauthenticate': 'Аутентификация',
    'portalSessions.stateTypeauthorize': 'Авторизация',
    'portalSessions.stateTypepass': 'Пропущен в интернет',
    'portalSessions.stateTypeunset': 'Все',
    'portalSessions.tabeleViewTypeCaption': 'Вид отображаемых данных',
    'portalSessions.tableViewTypeauthenticationView': 'Аутентификация',
    'portalSessions.tableViewTypeauthorizationView': 'Авторизация',
    'portalSessions.tableViewTypesocialNetworksView': 'Социальные сети',
    'portalSessions.tableViewTypestandardView': 'Стандартный',
    'portalSessions.tableViewTypesubscriptionsView': 'Подписки',
    'portalSessions.title': 'Пользовательские сессии',
    'portalSessions.ua.bot': 'Бот',
    'portalSessions.ua.desktop': 'Десктоп',
    'portalSessions.ua.mobile': 'Смартфон',
    'portalSessions.ua.tablet': 'Планшет',
    'portalSessions.undoDeleteSessionButtonCaption': 'Отменить удаление сессии',
    'portalSessions.undoDeleteSessionModalConfirm': 'Подтверждаете отмену удаления сессии',
    'portalSessions.undoDeleteSessionModalHeader': 'Отмена удаления сессии',
    'portalSessions.undoDeleteSessionModalUndoButton': 'Отменить удаление',
    'portalSessions.wlan': 'Сеть Wi-Fi',
    'portalSessions.wlanTableCaption': 'Сеть Wi-Fi',
    'portalStats.accounts': 'Пользователи',
    'portalStats.allAccounts': 'Все пользователи',
    'portalStats.allLocations': 'Все локации',
    'portalStats.allProfiles': 'Все профили',
    'portalStats.allTariffs': 'Все тарифы',
    'portalStats.auth.facebook': 'Facebook',
    'portalStats.auth.fb': 'Facebook',
    'portalStats.auth.free': 'Бесплатный',
    'portalStats.auth.hotel_voucher': 'Отельный ваучер',
    'portalStats.auth.instagram': 'Instagram',
    'portalStats.auth.skip': 'Пропустить',
    'portalStats.auth.sponsor': 'Спонсорский',
    'portalStats.auth.staff': 'Служебный',
    'portalStats.auth.type': 'Тип',
    'portalStats.auth.unset': 'Не указано',
    'portalStats.auth.vk': 'Вконтакте',
    'portalStats.auth.voucher': 'Ваучер',
    'portalStats.authen.callfront': 'Исходящий звонок',
    'portalStats.authen.email': 'Электронная почта',
    'portalStats.authen.sms': 'СМС',
    'portalStats.authen.type': 'Тип',
    'portalStats.authen.userpass': 'Персональный код',
    'portalStats.authenFull': 'Тип аутентификации',
    'portalStats.authenN': 'Аутентификация',
    'portalStats.authenZ': 'Авторизация',
    'portalStats.authFull': 'Типы авторизации',
    'portalStats.average': 'Среднее значение',
    'portalStats.browser': 'Браузер',
    'portalStats.browserFull': 'Браузеры',
    'portalStats.comparePeriodsPeriod': 'Выбраный период',
    'portalStats.comparePeriodsPeriodForCompare': 'Период для сравнения',
    'portalStats.compareWithPreviousPeriodButton': 'Сравнить',
    'portalStats.count': 'Количество',
    'portalStats.dataToYesterdayInclusive': 'данные не включают сегодняшний день (данные по вчерашний день включительно)',
    'portalStats.desktop': 'Компьютер',
    'portalStats.historyData': 'Исторические данные',
    'portalStats.locale.en': 'Английский',
    'portalStats.locale.locale': 'Языки',
    'portalStats.locale.ru': 'Русский',
    'portalStats.locale.tg': 'Таджикский',
    'portalStats.mobile': 'Смартфон',
    'portalStats.newAndRepeatUsers': 'Новые и повторные пользователи',
    'portalStats.newUsers': 'Новые пользователи',
    'portalStats.nonRealTimeData': 'Данные, обновляемые не в реальном времени',
    'portalStats.notNewUsers': 'Повторные пользователи',
    'portalStats.OS': 'ОС',
    'portalStats.os': 'ОС',
    'portalStats.other': 'Другие',
    'portalStats.percent': 'Процент',
    'portalStats.profiles': 'Профили',
    'portalStats.profileSelection': 'Профили',
    'portalStats.range': 'Интервал',
    'portalStats.realTimeData': 'Данные, обновляемые в реальном времени',
    'portalStats.selectAccount': 'Выберите пользователя',
    'portalStats.selectAccountPlaceholder': 'Выберите пользоват.',
    'portalStats.selectAds': 'Выберите рекламы',
    'portalStats.selectCpe': 'Выберите ТД',
    'portalStats.selectLocation': 'Выберите локацию',
    'portalStats.selectLocationWithChild': 'С дочерними',
    'portalStats.selectPage': 'Выберите страницу',
    'portalStats.selectProfile': 'Выберите профиль',
    'portalStats.selectRange': 'Выберите период',
    'portalStats.selectRules': 'Выберите правила',
    'portalStats.selectTariff': 'Выберите тариф',
    'portalStats.selectTheme': 'Выберите тему',
    'portalStats.selectWlan': 'Выберите WLAN',
    'portalStats.showSelectPreviousPeriodBlockButton': 'Сравнить с другим периодом',
    'portalStats.smsCostInfoMsg': 'Этот показатель не зависит от фильтров выбора Локации и Профиля. При выборе чего-либо в этих фильтрах они игнорируются для этого показателя',
    'portalStats.stats': 'Статистика',
    'portalStats.tariffs': 'Тарифы',
    'portalStats.totalConnections': 'Всего соединений',
    'portalStats.type': 'Тип',
    'portalStats.typeFull': 'Типы устройств',
    'portalStats.uniqClients': 'Уникальные клиенты',
    'portalStats.users.new': 'Новые пользователи',
    'portalStats.users.repeat': 'Повторные пользователи',
    'portalStats.users.type': 'Пользователи',
    'portalStats.usersForTable.new': 'Новые',
    'portalStats.usersForTable.repeat': 'Повторные',
    'portalStats.usersForTable.type': 'Тип',
    'portalStats.usersFull': 'Пользователи',
    'portalStats.vendors': 'Производители',
    'portalStats.vendorsForTable': 'Произв-ли',
    'portalStats.vendorsFull': 'Производители устройств',
    'portalStats.vouchers': 'Ваучеры',
    'portalStats.vouchersAmount': 'Всего ваучеров',
    'radar.addNew': 'Добавить сегмент',
    'radar.addresses': 'Адреса',
    'radar.addressesCountCaption': 'Всего',
    'radar.addressesOrBusinessId': 'Адреса / Business ID',
    'radar.addressesOrCTN': 'Адреса / CTN',
    'radar.allStatuses': 'Все статусы',
    'radar.allVisitors': 'Все',
    'radar.audience': 'Аудитория',
    'radar.audienceForBeelineForTableCaption': 'Аудитория',
    'radar.auto': 'Периодичность',
    'radar.autoPeriod': 'Периодичность обновления (в часах)',
    'radar.autoPlaceholder': 'Укажите периодичность',
    'radar.beepro': 'БиПродвижение',
    'radar.businessIdErrorShow': 'Необходимо чтобы был выбран пользователь с Business id (и Business id не должен быть равен 0)',
    'radar.businessIdsCaption': 'Business ID',
    'radar.cabinet': 'В кабинет БиПро',
    'radar.cancelSelectAllCpesButtonCaption': 'Очистить все выбранные ТД',
    'radar.continuously': 'Непрерывно',
    'radar.cpeListNoOptionsMsg': 'Нет точек доступа',
    'radar.cpeListNoOptionsMsgWhenFilterEnabled': 'Нет ТД, соответствующих условиям фильтров',
    'radar.cpeListNoResultMsg': 'Не найдено ТД с таким именем',
    'radar.cpeListNoResultMsgWhenFilterEnabled': 'Не найдено ТД с именем, соответствующим условиям фильтров',
    'radar.cpeListPlaceholder': 'Выберите ТД или введите имя для поиска',
    'radar.cpes': 'Точки доступа',
    'radar.cpeSelectedCounterCaption': 'Всего выбрано ТД',
    'radar.createCopy': 'создать копию',
    'radar.created': 'Создана',
    'radar.createdForBeelineForTableCaption': 'Дата создания',
    'radar.createdForCTNForBeelineForTableCaption': 'Создана для клиента с CTN',
    'radar.ctnCaption': 'CTN',
    'radar.ctnErrorShow': 'Необходимо чтобы был выбран пользователь с CTN',
    'radar.dataRangeFilterTypeButtonCreated': 'Создана',
    'radar.dataRangeFilterTypeButtonUpdated': 'Обновлена',
    'radar.deleteModalText': 'Подтвердите удаление',
    'radar.deleteModalTitle': 'Удаление сегмента',
    'radar.description': 'Описание',
    'radar.descriptionPlaceholder': 'Введите описание',
    'radar.email': 'Электронная почта',
    'radar.exportNameForBeelineForModalCaption': 'Имя сегмента',
    'radar.exportNameForBeelineForTableCaption': 'Имя сегмента',
    'radar.filter': 'Фильтр по посетителям',
    'radar.filtersForCpeListSwitcherCaption': 'Фильтрация списка ТД',
    'radar.filtersForCpeListSwitcherInfoTooltip': 'Фильтрация упрощает поиск нужной точки доступа в списке выбора точек доступа. Позволяет отфильтровать точки доступа по их локации и по состоянию включения или отключения Wi-Fi Таргет на них',
    'radar.filtersForCpeListSwitcherLocationCaption': 'Локация точки доступа',
    'radar.finished': 'Завершена',
    'radar.format': 'Формат',
    'radar.hash': 'Хэшировать данные',
    'radar.hashInfo': 'Маска данных, полученная путем шифрования. Служит для однозначного определения объекта или сущности',
    'radar.infinity': 'Без ограничения',
    'radar.items': 'Всего адресов',
    'radar.match': 'совпадение',
    'radar.matched': 'найдено',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedError': 'Превышено ограничение на количество точек с включенным сбором данных Wi-Fi Таргет для заданного временного периода',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMore': 'Подробнее об ограничениях',
    'radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader': 'Об ограничениях',
    'radar.mytarget': 'myTarget',
    'radar.name': 'Название',
    'radar.namePlaceholder': 'Укажите название сегмента',
    'radar.newVisitors': 'Только новые',
    'radar.notificationRadarExportCreatedSuccessfully': 'Сегмент успешно создан',
    'radar.notificationRadarExportDeletedSuccessfully': 'Сегмент успешно удален',
    'radar.notificationRadarExportUpdatedSuccessfully': 'Сегмент успешно обновлен',
    'radar.period': 'Период',
    'radar.processed': 'Обработана',
    'radar.radarEnableStatusForCpeFilterAny': 'Любой',
    'radar.radarEnableStatusForCpeFilterCaption': 'Статус Wi-Fi таргет на точке',
    'radar.radarEnableStatusForCpeFilterDisabled': 'Wi-Fi таргет выключен',
    'radar.radarEnableStatusForCpeFilterEnabled': 'Wi-Fi таргет включен',
    'radar.radarFiltringForCpeListIsEnabled': 'вкл.',
    'radar.radarFiltringForCpeListResetFiltersButton': 'сбросить фильтр',
    'radar.refreshButtonCaption': 'Обновить',
    'radar.returnVisitors': 'Только повторные',
    'radar.running': 'Запущена',
    'radar.selectAllCpesButtonCaption': 'Добавить все отфильтрованные ТД',
    'radar.selectedBusinessIdsCaption': 'Выбраный Business ID',
    'radar.selectedCPEsCaption': 'Выбрано',
    'radar.selectedCTNCaption': 'Выбраный CTN',
    'radar.selectPlaceholder': 'Выберите ТД',
    'radar.settingsBeepro': 'Настройки БиПродвижения',
    'radar.settingsEmail': 'Настройки электронной почты',
    'radar.settingsExport': 'Настройки экспорта',
    'radar.settingsForBeelineForModalCaption': 'Настройки',
    'radar.settingsGeneral': 'Общие настройки',
    'radar.share': 'Адреса',
    'radar.sortTypeCreated': 'По времени созд.',
    'radar.sortTypeName': 'По имени',
    'radar.sortTypeUpdated': 'По времени обновл.',
    'radar.status': 'Статус',
    'radar.statusForBeelineForTableCaption': 'Статус обработки сегмента',
    'radar.subject': 'Тема',
    'radar.subjectPlaceholder': 'Укажите тему',
    'radar.tableHeaderForBeelineForTableCaption': 'Список сегментов',
    'radar.title': 'Сегменты Wi-Fi таргет',
    'radar.type': 'Тип',
    'radar.updated': 'Последнее обновление',
    'radar.user': 'Пользователь',
    'radar.usersManualLinkCaption': 'Инструкция пользователя',
    'radar.usersWithBusinessIdsPlaceholder': 'Выберите пользователя или введите имя для поиска',
    'radar.valid': 'выгружено',
    'radar.wifiTargetDisabledOnAllSelectedCPES': 'Внимание! Все выбранные точки доступа с выключенным сбором данных Wi-Fi таргет',
    'radar.wifiTargetEnabled': 'Wi-Fi таргет включен',
    'radar.wifiTargetEnabledShort': 'Wi-Fi таргет вкл.',
    'radar.wrongEmailMsg': 'Неправильная электронная почта',
    'radar.yandex': 'Яндекс',
    'radar.yandexCaption': 'При типе экспорта «Яндекс» необходимо использовать адрес электронной почты формата «@yandex.ru»',
    'radius.acctPort': 'Порт аккаунтинга',
    'radius.acctPortPlaceholder': 'Введите порт учета данных',
    'radius.addRadius': 'Добавить RADIUS',
    'radius.addressField': 'IP адрес',
    'radius.addressInTableTitle': 'Адрес',
    'radius.advancedSettings': 'Настройка дополнительных параметров',
    'radius.authPort': 'Порт авторизации',
    'radius.authPortPlaceholder': 'Введите порт авторизации',
    'radius.basicParameters': 'Настройка базовых RADIUS параметров',
    'radius.clientOnAP': 'Клиент на ТД',
    'radius.clientOnWLC': 'Клиент на WLC',
    'radius.clientOnWLCHint': 'Только для сетей с web аутентификацией (Captive portal)',
    'radius.coaServerKey': 'Ключ CoA сервера',
    'radius.coaServerKeyConfirmation': 'Подтверждение ключа CoA сервера',
    'radius.coaSettings': 'CoA (RFC 5176)',
    'radius.confirmDeletingModal': 'Подтвердите удаление',
    'radius.confirmRadius': 'Подтвердите создание нового RADIUS',
    'radius.createRadiusServer': 'Добавить RADIUS сервер',
    'radius.daeBlockHintInfoMsg': 'Расширения динамической авторизации (DAE)(RFC 5176)',
    'radius.daeClient': 'DAE клиент',
    'radius.daeClientHintInfoMsg': 'Клиента для расширения динамической авторизации. Этот клиент может отправлять пакеты “Disconnect-Request” или “CoA-Request” чтобы принудительно отключить клиента или изменить параметры подключения. Групповой IP (например 0.0.0.0) не работает',
    'radius.daeClientPlaceholder': 'DAE клиент (IP адрес)',
    'radius.daePort': 'DAE порт',
    'radius.daePortHintInfoMsg': 'Порт Dynamic Authorization Extension сервера',
    'radius.daePortPlaceholder': 'DAE порт (3799 по умолчанию)',
    'radius.daeSecret': 'DAE cекрет',
    'radius.daeSecretHintInfoMsg': 'DAE Секрет',
    'radius.daeSecretPlaceholder': 'Shared DAE secret',
    'radius.daeSettings': 'Расширения динамической авторизации (DAE)(RFC 5176)',
    'radius.deleteRADIUSModalTitle': 'Удалить RADIUS',
    'radius.editRadiusServer': 'Редактировать RADIUS сервер',
    'radius.ipPlaceholder': 'X.X.X.X',
    'radius.key': 'Ключ',
    'radius.keyConfirmation': 'Подтверждение ключа',
    'radius.local': 'Локальный',
    'radius.namePlaceholder': 'Введите имя',
    'radius.notificationRADIUSCreatedSuccessfully': 'RADIUS успешно создан',
    'radius.notificationRADIUSDeletedSuccessfully': 'RADIUS успешно удален',
    'radius.notificationRADIUSOperationSuccessfull': 'Успешно',
    'radius.notificationRADIUSUpdatedSuccessfully': 'RADIUS успешно обновлен',
    'radius.password': 'Пароль',
    'radius.portal': 'Портал',
    'radius.radius': 'RADIUS',
    'radius.radiusForSidebar': 'Серверы RADIUS',
    'radius.radiusList': 'Список RADIUS',
    'radius.radiusWizard': 'Помощник создания RADIUS',
    'radius.radiusWizardButtonCaption': 'RADIUS визард',
    'radius.secret': 'Пароль',
    'radius.secretPlaceholder': 'Введите ключ',
    'radius.wizardAdvancedParameters': 'Дополнительные параметры',
    'reactiveLogs.addButton': 'Добавить',
    'reactiveLogs.confirmDeletingModal': 'Подтвердите удаление',
    'reactiveLogs.confirmDeletingModalHeader': 'Удаление',
    'reactiveLogs.customIntervalsUnits.hours': 'ч.',
    'reactiveLogs.customIntervalsUnits.minutes': 'мин',
    'reactiveLogs.customIntervalsUnits.seconds': 'сек',
    'reactiveLogs.datePickerStartGreaterThenEndError': 'Дата и время окончания не может быть раньше чем дата начала',
    'reactiveLogs.debugDHCP': 'Отладка DHCP',
    'reactiveLogs.debuggedCaption': 'Отладка ',
    'reactiveLogs.debugHostap': 'Отладка hostAP',
    'reactiveLogs.debugNTP': 'Отладка NTP',
    'reactiveLogs.debugStateCaption': 'Состояние отладки',
    'reactiveLogs.debugStateStopped': 'остановлено',
    'reactiveLogs.debugStateStrated': 'запущено',
    'reactiveLogs.deleteButton': 'Удалить',
    'reactiveLogs.endTime': 'Время окончания',
    'reactiveLogs.fileDownloadingError': 'Ошибка при скачивании файла',
    'reactiveLogs.generateButton': 'Сгенерировать',
    'reactiveLogs.generateLogsFor': 'Сгенерировать логи за последние',
    'reactiveLogs.generateLogsFor10Minutes': '10 минут',
    'reactiveLogs.generateLogsFor1Hour': '1 час',
    'reactiveLogs.generateLogsFor20Minutes': '30 минут',
    'reactiveLogs.generateLogsForCustomDatetime': 'Задать диапазон дат и времени',
    'reactiveLogs.interval': 'Интервал',
    'reactiveLogs.logsSettingsModal': 'Настройка логов',
    'reactiveLogs.logState': 'Состояние',
    'reactiveLogs.logStateError': 'Ошибка',
    'reactiveLogs.logStateInProgress': 'В процессе',
    'reactiveLogs.logStateSuccessful': 'Успешно',
    'reactiveLogs.modalMACIP': 'MAC/IP адрес',
    'reactiveLogs.modalMACIPInputHint': 'Введите MAC/IP адрес: каждый с новой строки (не более 3). Если идентификаторы относятся к одному объекту, они должны вводиться одной строкой через запятую (не более 3)',
    'reactiveLogs.modalMACIPEditInputHint': 'Введите MAC/IP адрес, они должны вводиться одной строкой через запятую (не более 3)',
    'reactiveLogs.notificationCreatedSuccessfully': 'Фильтр успешно добавлен',
    'reactiveLogs.notificationDeletedSuccessfully': 'Фильтр успешно удален',
    'reactiveLogs.notificationStartedSuccessfully': 'Сбор логов успешно запущен',
    'reactiveLogs.notificationStoppedSuccessfully': 'Сбор логов успешно остоновлен',
    'reactiveLogs.notificationUpdatedSuccessfully': 'Фильтр успешно обновлен',
    'reactiveLogs.nowClickGenerateToGetGatheredDataHint': 'Нажмите Сгенирировать чтобы получить собраные данные',
    'reactiveLogs.replicateTheIssueNowHint': 'Воспроизведите проблему',
    'reactiveLogs.seeDetails': 'Посмотреть детали',
    'reactiveLogs.startButton': 'Старт',
    'reactiveLogs.startdataCapturingFirstHint': 'Сначала начните сбор данных и воспроизведите проблему',
    'reactiveLogs.startDateTime': 'Дата и время начала сбора данных логов',
    'reactiveLogs.startTime': 'Время начала',
    'reactiveLogs.stopButton': 'Стоп',
    'reactiveLogs.stopDateTime': 'Дата и время окончания сбора данных логов',
    'reactiveLogs.tableAction': 'Действие',
    'reactiveLogs.tableMACIP': 'MAC/IP адрес',
    'reactiveLogs.tableTraceFile': 'Файл',
    'reactiveLogs.errors.maxLinesEnteredForReactiveLogs': 'Введено более 3 строк',
    'recommendations.addAdds.button': 'Настроить рекламу',
    'recommendations.addAdds.text': 'Добавьте рекламу на портал для увеличения продаж.',
    'recommendations.addAdds.title': 'Добавьте рекламу',
    'recommendations.addBanners.button': 'Включить баннеры',
    'recommendations.addBanners.text': 'Добавляйте рекламные баннеры, чтобы привлечь внимание посетителей портала.',
    'recommendations.addBanners.title': 'Добавьте баннеры',
    'recommendations.addPolls.button': 'Включить опросы',
    'recommendations.addPolls.text': 'Опросы позволят узнать мнение посетителей портала или развлечь их.',
    'recommendations.addPolls.title': 'Добавьте опросы',
    'recommendations.addPollsQuality.button': 'Включить опросы качества',
    'recommendations.addPollsQuality.text': 'Опросы качества позволят узнать мнение посетителей портала о чем угодно.',
    'recommendations.addPollsQuality.title': 'Добавьте опросы качества',
    'recommendations.addPollsUserDataBirthday.button': 'Настроить опросы',
    'recommendations.addPollsUserDataBirthday.text': 'Запрос данных пользователя позволяет узнать информацию о днях рождения посетителей портала.',
    'recommendations.addPollsUserDataBirthday.title': 'Добавить запрос данных пользователя',
    'recommendations.addRadarExport.button': 'Настроить выгрузку сегментов',
    'recommendations.addRadarExport.text': 'Настройте периодическую выгрузку сегментов Wi-Fi Таргет, чтобы своевременно получать информацию о собранных данных.',
    'recommendations.addRadarExport.title': 'Отчёты Wi-Fi таргет',
    'recommendations.addVideo.button': 'Включить видео',
    'recommendations.addVideo.text': 'Используйте видео для продвижения товаров и услуг.',
    'recommendations.addVideo.title': 'Показать видео',
    'recommendations.allGood.text': 'Все рекомендации выполнены.',
    'recommendations.allGood.title': 'Поздравляем!',
    'recommendations.distressedCPEs.button': 'Показать ТД',
    'recommendations.distressedCPEs.text': 'Обнаружены Точки доступа с ошибками в работе. Необходимо проверить конфигурацию, исправить и/или перезапустить агент AP. Пожалуйста, обратитесь к администратору.',
    'recommendations.distressedCPEs.title': 'Ошибки ТД',
    'recommendations.easyInstallWizard.button': 'Начать',
    'recommendations.easyInstallWizard.text': 'Пройдите шаги помощника для простой установки и настройки сети WLAN с порталом',
    'recommendations.easyInstallWizard.title': 'Простая установка',
    'recommendations.heading': 'Рекомендации',
    'recommendations.highCPULoad.text': 'Загрузка процессора превысила 90%. Пожалуйста, обратитесь к администратору сервера.',
    'recommendations.highCPULoad.title': 'Высокая загрузка процессора',
    'recommendations.knowledgeBase.button': 'Перейти',
    'recommendations.knowledgeBase.text': 'Раздел с описанием основных разделов платформы, поможет вам познакомиться с платформой поближе',
    'recommendations.knowledgeBase.title': 'База знаний',
    'recommendations.moveRecommendationsBottom': 'переместить вниз',
    'recommendations.moveRecommendationsTop': 'переместить вверх',
    'recommendations.showAdsStats.button': 'Просмотреть статистику',
    'recommendations.showAdsStats.text': 'Узнайте, насколько эффективно работает ваша реклама.',
    'recommendations.showAdsStats.title': 'Статистика рекламы',
    'registerMyCPEPlease.cpeAlreadyRegisteredInfoMessage': 'Устройство с данным идентификатором уже зарегистрировано',
    'registerMyCPEPlease.cpeId': 'Идентификатор устройства',
    'registerMyCPEPlease.cpeRegisterButton': 'Зарегистрировать',
    'registerMyCPEPlease.cpeRegistrationHeader': 'Регистрация устройства',
    'registerMyCPEPlease.deviceMoreInfo': 'Устройство — точка доступа или сервер',
    'registerMyCPEPlease.goToAllCpeListButton': 'Перейти к списку всех устройств',
    'reports.add': 'Добавить отчет',
    'reports.addCharts': 'Добавить графики',
    'reports.addChartsCaption': "Доступно только для объекта выгрузки 'Обобщенная статистика по ТД' и типа отчёта 'xlsx'",
    'reports.collect_period': 'Период',
    'reports.confirmDeleting': 'Подтвердить удаление',
    'reports.dayMonth': 'День',
    'reports.dayWeek': 'День недели',
    'reports.deleteReport': 'Удаление отчета',
    'reports.editReport': 'Редактирование отчета',
    'reports.format': 'Тип отчета',
    'reports.formats.csv': 'csv',
    'reports.formats.json': 'json',
    'reports.formats.xlsx': 'xlsx',
    'reports.hour': 'час',
    'reports.newReport': 'Новый отчет',
    'reports.notificationReportCreatedSuccessfully': 'Отчет успешно создан',
    'reports.notificationReportDeletedSuccessfully': 'Отчет успешно удален',
    'reports.notificationReportUpdatedSuccessfully': 'Отчет успешно обновлен',
    'reports.period.day': 'Каждый день',
    'reports.period.month': 'Каждый месяц',
    'reports.period.now': 'Сейчас',
    'reports.period.week': 'Каждую неделю',
    'reports.placedescr': 'Введите описание отчета',
    'reports.placeHolderAddEmail': 'Введите адрес электронной почты',
    'reports.placename': 'Введите имя отчета',
    'reports.subject': 'Объект выгрузки',
    'reports.subjects.clients': 'Клиенты',
    'reports.subjects.cpes': 'Точки доступа',
    'reports.subjects.cpes_common': 'Обобщенная статистика по ТД',
    'reports.subjects.events': 'События',
    'reports.tableTooltipStart': 'С',
    'reports.tableTooltipStop': 'По',
    'reports.type': 'Тип выгрузки',
    'reports.types.current': 'На текущий момент',
    'reports.types.summary': 'За период',
    'reports.typestypeForClientsSubject.clients': 'Клиенты',
    'reports.typestypeForClientsSubject.clients_auth': 'Авторизованные клиенты',
    'reports.typestypeForClientsSubject.current': 'На текущий момент',
    'reports.typestypeForClientsSubject.summary': 'За период',
    'reports.week.0': 'Воскресенье',
    'reports.week.1': 'Понедельник',
    'reports.week.2': 'Вторник',
    'reports.week.3': 'Среда',
    'reports.week.4': 'Четверг',
    'reports.week.5': 'Пятница',
    'reports.week.6': 'Суббота',
    'RRM.alertAction': 'Настроить радио интерфейсы',
    'RRM.alertText': 'Должно быть включено сканирование эфира на радио интерфейсах.',
    'RRM.algo': 'Алгоритм',
    'RRM.algorithmBlind': 'Базовый',
    'RRM.algorithmGreed': 'Интеллектуальный',
    'RRM.allGroups': 'Все группы',
    'RRM.deleteRRMHeader': 'Удалить RRM группу',
    'RRM.forGroup': 'Для группы',
    'RRM.group': 'Группа',
    'RRM.groups': 'Группы',
    'RRM.modalCPEs': 'Точки доступа',
    'RRM.modalGroupNamePlaceHolder': 'Введите имя группы',
    'RRM.modalGroupTimeoutPlaceHolder': 'Введите таймаут',
    'RRM.modalNOCPEs': 'Нет точек доступа',
    'RRM.modalTitle': 'Добавить новую группу',
    'RRM.Movetoselectedgroup': 'Переместить в выбранную RRM группу',
    'RRM.multipleEdit': 'Мультиредактирование',
    'RRM.multipleEditSelectGroup': 'Выберите группу',
    'RRM.noGroup': 'Без группы',
    'RRM.notificationRRMGroupCreated': 'RRM группа успешно создана',
    'RRM.notificationRRMGroupDeleted': 'RRM группа успешно удалена',
    'RRM.notificationRRMGroupForceCall': 'RRM успешно вызван для данной группы',
    'RRM.notificationRRMGroupUpdated': 'RRM группа успешно обновлена',
    'RRM.power': 'Управлять мощностью',
    'RRM.powerForTable': 'Управление мощностью',
    'RRM.RemovefromRRMgroups': 'Удалить из RRM групп',
    'RRM.rrmForceCallButtonInModal': 'Принудительный вызов RRM',
    'RRM.rrmForceCallButtonInModalDisabledHint': 'Перед принудительным вызовом RRM необходимо сохранить настройки группы',
    'RRM.showAll': 'показать все',
    'RRM.tableItemsOf': 'из',
    'RRM.tableLoad': 'Загрузить',
    'RRM.tableOutOf': 'ТД из',
    'RRM.tableRRMCPEs': 'Точки доступа',
    'RRM.tableRRMCPEsTooltip': 'Показать ТД',
    'RRM.tableRRMSeconds': 'сек',
    'RRM.tableSelected': 'выбрано',
    'RRM.tableView': '',
    'RRM.timeout': 'Таймаут (в сек.)',
    'rules.addRule': 'Добавить',
    'rules.minTrafficThresholdCaption': 'Минимальный порог трафика',
    'rules.minTrafficThresholdCaptionBytes': 'в байтах',
    'rules.minTrafficThresholdCaptionBytesForTableColumn': 'байт',
    'rules.minTrafficThresholdInfoCaption': 'Если пришло меньше трафика за расчетный период, то выполняется скрипт',
    'rules.minTrafficThresholdPlaceholder': 'Порог трафика (в байтах)',
    'rules.namePlaceHolderInWizard': 'Введите имя для правила',
    'rules.noRules': 'нет',
    'rules.notificationTypeCaption': 'Тип действия',
    'rules.notificationTypes.email': 'Электронная почта',
    'rules.notificationTypes.mqtt': 'MQTT',
    'rules.notificationTypes.telegram': 'Telegram',
    'rules.notifySettingsEmailSubjectCaption': 'Тема',
    'rules.notifySettingsEmailSubjectPlaceholder': 'Тема письма',
    'rules.notifySettingsEmailTextCaption': 'Текст',
    'rules.notifySettingsEmailTextPlaceholder': 'Текст электронного письма',
    'rules.notifySettingsEmailToCaption': 'Кому',
    'rules.notifySettingsEmailToPlaceholder': 'Адрес e-mail',
    'rules.notifySettingsForTableCaption': 'Параметры действия',
    'rules.notifySettingsForTableForEmailToAddress': 'Письмо на адрес',
    'rules.notifySettingsForTableForMQTTToTopic': 'Сообщение в топик',
    'rules.notifySettingsForTableForTelegramToChatId': 'Сообщение в чат c id',
    'rules.notifySettingsForTableHide': 'Скрыть',
    'rules.notifySettingsForTableShowMore': 'Подробнее',
    'rules.notifySettingsMQTTTextCaption': 'Текст',
    'rules.notifySettingsMQTTTextPlaceholder': 'Текст',
    'rules.notifySettingsMQTTTopicCaption': 'Топик',
    'rules.notifySettingsMQTTTopicInfo': 'MQTT топик',
    'rules.notifySettingsMQTTTopicPlaceholder': 'Топик',
    'rules.notifySettingsTelegramChatIdCaption': 'ID чата',
    'rules.notifySettingsTelegramChatIdInfo': 'Уникальный идентификатор telegram чата',
    'rules.notifySettingsTelegramChatIdPlaceholder': 'ID чата',
    'rules.notifySettingsTelegramTextCaption': 'Текст',
    'rules.notifySettingsTelegramTextPlaceholder': 'Текст для уведомления',
    'rules.notifySettingsTelegramTokenCaption': 'Токен',
    'rules.notifySettingsTelegramTokenInfo': 'Токен, получаемый у @BotFather',
    'rules.notifySettingsTelegramTokenPlaceholder': 'Токен',
    'rules.ruleTypes.client_con': 'Клиент: Подключение',
    'rules.ruleTypes.client_dis': 'Клиент: Отключение',
    'rules.ruleTypes.client_far': 'Клиент: Дистанция от ТД',
    'rules.ruleTypes.config_error': 'Точка доступа: Ошибка конфигурации',
    'rules.ruleTypes.connected': 'Точка доступа: Подключение',
    'rules.ruleTypes.cpu_load': 'Точка доступа: Загрузка процессора',
    'rules.ruleTypes.customer_activity': 'Точка доступа: Активность',
    'rules.ruleTypes.disconnected': 'Точка доступа: Отключение',
    'rules.ruleTypes.free_ram': 'Точка доступа: Свободная память',
    'rules.ruleTypes.iface_error': 'Точка доступа: Ошибка интерфейса',
    'rules.script': 'Выполнение скрипта',
    'rules.scriptInfoCaptionInWizard': 'Вставьте однострочный sh скрипт. Возможные переменные окружения: TEXT - event payload, CPE - AP uuid',
    'rules.scriptPlaceHolderInWizard': 'Введите скрипт',
    'rules.triggerScript': 'Скрипт',
    'sessions.noSessions': 'Нет сессий',
    'sidebar.accounts': 'Пользователи',
    'sidebar.administration': 'Администрирование',
    'sidebar.ads': 'Реклама и опросы',
    'sidebar.analiticsAndData': 'Аналитика и данные',
    'sidebar.analytics': 'Аналитика',
    'sidebar.badgeTooltip24h': 'Всего (последние 24 часа)',
    'sidebar.badgeTooltipConnected': 'Подключенные',
    'sidebar.badgeTooltipTotal': 'Всего',
    'sidebar.birthdays': 'Дни рождения',
    'sidebar.buyers': 'Покупатели',
    'sidebar.clientDistance': 'Удаленность пользователя',
    'sidebar.clientRF': 'Client RF',
    'sidebar.clients': 'Клиенты',
    'sidebar.ConfigExportImport': 'Экспорт/Импорт конфигурации',
    'sidebar.controllers': 'Контроллеры',
    'sidebar.cpes': 'Точки доступа',
    'sidebar.cpu': 'ЦП',
    'sidebar.cpuLoad': 'Загрузка процессора',
    'sidebar.dashboard': 'Состояние',
    'sidebar.DBState': 'Состояние БД',
    'sidebar.DITExport': 'Экспорт ДИТ',
    'sidebar.DITStat': 'Статистика ДИТ',
    'sidebar.events': 'События',
    'sidebar.export': 'Экспорт',
    'sidebar.externalAPs': 'Сторонние точки доступа',
    'sidebar.firewall': 'Брандмауэр',
    'sidebar.GuestControl': 'Гостевой контроль',
    'sidebar.heatmap': 'Тепловые карты',
    'sidebar.hotelVouchers': 'Отельные ваучеры',
    'sidebar.hotspots': 'Hotspot 2.0',
    'sidebar.licenses': 'Лицензии',
    'sidebar.locations': 'Локации',
    'sidebar.logs': 'Логи',
    'sidebar.maps': 'Карты',
    'sidebar.monitor': 'Монитор',
    'sidebar.network': 'Сеть',
    'sidebar.nms': 'NMS',
    'sidebar.pages': 'Страницы',
    'sidebar.paidInternet': 'Платный интернет',
    'sidebar.paymentSystems': 'Платежные системы',
    'sidebar.PeriodicTasks': 'Периодические задачи',
    'sidebar.portal': 'Портал',
    'sidebar.PortalAccessServers': 'Серверы доступа',
    'sidebar.portalConfiguration': 'Конфигурация',
    'sidebar.portalDashboard': 'Рабочий стол',
    'sidebar.preview': 'Предпросмотр',
    'sidebar.profiles': 'Профили',
    'sidebar.radar': 'Wi-Fi таргет',
    'sidebar.radius': 'RADIUS серверы',
    'sidebar.reactiveLogs': 'Reactive логи',
    'sidebar.reports': 'Отчёты',
    'sidebar.rrm': 'RRM',
    'sidebar.rules': 'Правила',
    'sidebar.scanner': 'Сканер',
    'sidebar.sessions': 'Найти сессию',
    'sidebar.settings': 'Настройки',
    'sidebar.SNMP': 'SNMP',
    'sidebar.SNMPMonitoringConfig': 'Конфигурация мониторинга по SNMP',
    'sidebar.SNMPStat': 'Статистика SNMP',
    'sidebar.statistics': 'Статистика',
    'sidebar.support': 'База знаний',
    'sidebar.system': 'Система',
    'sidebar.systemInfo': 'Системная информация',
    'sidebar.tariffs': 'Тарифы',
    'sidebar.template': 'Шаблоны',
    'sidebar.Time': 'Время',
    'sidebar.troubleshooter': 'Подключение ТД',
    'sidebar.visitors': 'Посетители',
    'sidebar.vouchers': 'Ваучеры',
    'sidebar.wifiAnalyzer': 'Анализ Wi-Fi',
    'sidebar.wlans': 'Сети Wi-Fi',
    'sidebarNew.aaa': 'AAA',
    'sidebarNew.accounts': 'Пользователи',
    'sidebarNew.acl': 'ACL',
    'sidebarNew.administration': 'Администрирование',
    'sidebarNew.ads': 'Реклама и опросы',
    'sidebarNew.adsStatistic': 'Реклама и опросы (статистика)',
    'sidebarNew.analiticsAndData': 'Аналитика и данные',
    'sidebarNew.analytics': 'Аналитика',
    'sidebarNew.aps': 'Точки доступа',
    'sidebarNew.apStatistics': 'Статистика ТД',
    'sidebarNew.badgeTooltip24h': 'Всего (последние 24 часа)',
    'sidebarNew.badgeTooltipConnected': 'Подключенные',
    'sidebarNew.badgeTooltipTotal': 'Всего',
    'sidebarNew.birthdays': 'Дни рождения',
    'sidebarNew.buyers': 'Покупатели',
    'sidebarNew.clientDistance': 'Удаленность пользователя',
    'sidebarNew.clientRF': 'Client RF',
    'sidebarNew.clients': 'Клиенты',
    'sidebarNew.clientsStatistic': 'Статистика клиентов',
    'sidebarNew.ConfigExportImport': 'Экспорт/Импорт конфигурации',
    'sidebarNew.configuration': 'Конфигурация',
    'sidebarNew.controllers': 'Контроллеры',
    'sidebarNew.collapseMenu': 'Свернуть меню',
    'sidebarNew.cpes': 'Точки доступа',
    'sidebarNew.cpu': 'ЦП',
    'sidebarNew.cpuLoad': 'Загрузка процессора',
    'sidebarNew.dashboard': 'Состояние',
    'sidebarNew.DBState': 'Состояние БД',
    'sidebarNew.DITExport': 'Экспорт ДИТ',
    'sidebarNew.DITStat': 'Статистика ДИТ',
    'sidebarNew.events': 'События',
    'sidebarNew.expandMenu': 'Раскрыть меню',
    'sidebarNew.export': 'Экспорт',
    'sidebarNew.externalAPs': 'Сторонние точки доступа',
    'sidebarNew.firewall': 'Брандмауэр',
    'sidebarNew.general': 'Основное',
    'sidebarNew.GuestControl': 'Гостевой контроль',
    'sidebarNew.heatmap': 'Тепловые карты',
    'sidebarNew.hotelVouchers': 'Отельные ваучеры',
    'sidebarNew.hotspotOpenRoaming': 'Hotspot/OpenRoaming',
    'sidebarNew.hotspots': 'Hotspot 2.0',
    'sidebarNew.licenses': 'Лицензии',
    'sidebarNew.licensing': 'Лицензии',
    'sidebarNew.locations': 'Локации',
    'sidebarNew.locationsStatistics': 'Локации (статистика)',
    'sidebarNew.logs': 'Логи',
    'sidebarNew.maps': 'Карты',
    'sidebarNew.monitor': 'Монитор',
    'sidebarNew.monitoring': 'Монитор',
    'sidebarNew.network': 'Сеть',
    'sidebarNew.newMenuStyle': 'переключить на старое меню',
    'sidebarNew.nms': 'NMS',
    'sidebarNew.oldMenuStyle': 'переключить на новое меню',
    'sidebarNew.pages': 'Страницы',
    'sidebarNew.paidInternet': 'Платный интернет',
    'sidebarNew.paymentSystems': 'Платежные системы',
    'sidebarNew.PeriodicTasks': 'Периодические задачи',
    'sidebarNew.portal': 'Портал',
    'sidebarNew.PortalAccessServers': 'Серверы доступа',
    'sidebarNew.portalConfiguration': 'Конфигурация',
    'sidebarNew.portalDashboard': 'Рабочий стол',
    'sidebarNew.portalSection': 'Портал',
    'sidebarNew.preview': 'Предпросмотр',
    'sidebarNew.profiles': 'Профили',
    'sidebarNew.radar': 'Wi-Fi таргет',
    'sidebarNew.radioConfiguration': 'Конфигурация радио',
    'sidebarNew.radioStatistics': 'Радио статистика',
    'sidebarNew.radius': 'RADIUS серверы',
    'sidebarNew.reactiveLogs': 'Reactive логи',
    'sidebarNew.reports': 'Отчёты',
    'sidebarNew.rrm': 'RRM',
    'sidebarNew.rules': 'Правила',
    'sidebarNew.scanner': 'Сканер',
    'sidebarNew.security': 'Безопасность',
    'sidebarNew.sessions': 'Найти сессию',
    'sidebarNew.settings': 'Настройки',
    'sidebarNew.SNMP': 'SNMP',
    'sidebarNew.SNMPMonitoringConfig': 'Конфигурация мониторинга по SNMP',
    'sidebarNew.SNMPStat': 'Статистика SNMP',
    'sidebarNew.statistics': 'Статистика',
    'sidebarNew.support': 'База знаний',
    'sidebarNew.system': 'Система',
    'sidebarNew.systemInfo': 'Системная информация',
    'sidebarNew.tagsAndProfiles': 'Тэги и профили',
    'sidebarNew.layer2': 'Layer 2',
    'sidebarNew.tariffs': 'Тарифы',
    'sidebarNew.template': 'Шаблоны',
    'sidebarNew.Time': 'Время',
    'sidebarNew.troubleshooter': 'Подключение ТД',
    'sidebarNew.troubleshooting': 'Поиск неисправностей',
    'sidebarNew.visitors': 'Посетители',
    'sidebarNew.vouchers': 'Ваучеры',
    'sidebarNew.webAuth': 'Web auth',
    'sidebarNew.wifiAnalyzer': 'Анализ Wi-Fi',
    'sidebarNew.wireless': 'Беспроводные',
    'sidebarNew.wirelessSetup': 'Беспроводные настройки',
    'sidebarNew.wlans': 'Сети Wi-Fi',
    'sidebarNew.wlanWizard': 'Помощник Wi-Fi',
    'SNMP.noOptions': 'Нет опций',
    'SNMP.applyButton': 'Применить изменения',
    'SNMP.rollbackButton': 'Отменить изменения',
    'SNMP.selectAll': 'Выбрать всё',
    'SNMP.deselectAll': 'Отменить выбор всего',
    'SNMP.notSelected': 'Не выбрано',
    'SNMP.add': 'Добавить',
    'SNMP.delete': 'Удалить',
    'SNMP.enableAll': 'Применить всё',
    'SNMP.disableAll': 'Выключить всё',
    'SNMP.available': 'Доступно',
    'SNMP.enabled': 'Включено',
    'SNMP.apply': 'Применить',
    'SNMP.onOff': 'вкл/выкл',
    'SNMP.enabledDisbled': 'вкл/выкл',
    'SNMP.SNMPModeSwitcher': 'SNMP',
    'SNMP.SNMPGeneralHeader': 'Основное',
    'SNMP.SNMPViewsHeader': 'SNMP Views',
    'SNMP.SNMPCommunityStringsHeader': 'Community Strings',
    'SNMP.SNMPUserGroupsHeader': 'Пользовательские группы',
    'SNMP.SNMPUsersHeader': 'Пользователи',
    'SNMP.SNMPHostsHeader': 'Хосты',
    'SNMP.SNMPGeneral.systemLocation': 'Системная локация',
    'SNMP.SNMPGeneral.systemContact': 'Системные контакты',
    'SNMP.SNMPGeneral.snmpTraps': 'SNMP Traps',
    'SNMP.SNMPGeneral.snmpTrapsNoEnabled': 'SNMP Traps не включены',
    'SNMP.SNMPGeneral.notificationUpdatedSuccessfully': 'Настройки успешно обновлены',
    'SNMP.SNMPViews.included': 'Включены',
    'SNMP.SNMPViews.excluded': 'Исключенны',
    'SNMP.SNMPCommunityStrings.communityName': 'Community Name',
    'SNMP.SNMPCommunityStrings.accessMode': 'Режим доступа',
    'SNMP.SNMPCommunityStrings.editCommunityStringModalHeader': 'Редактирование',
    'SNMP.SNMPCommunityStrings.addCommunityStringModalHeader': 'Создание',
    'SNMP.SNMPCommunityStrings.notificationUpdatedSuccessfully': 'Community String успешно обновлена',
    'SNMP.SNMPCommunityStrings.notificationCreatedSuccessfully': 'Community String успешно создана',
    'SNMP.SNMPCommunityStrings.notificationDeletedSuccessfully': 'Community String успешно удалена',
    'SNMP.SNMPCommunityStrings.communityStringAlreadyExists': 'Community string с таким именем уже существует',
    'SNMP.SNMPCommunityStrings.communityStringAlreadyMaybeExists': 'Community string с таким именем возможно уже существует',
    'SNMP.SNMPCommunityStrings.modes.read': 'чтение',
    'SNMP.SNMPCommunityStrings.modes.read/write': 'чтение и запись',
    'SNMP.SNMPUserGroups.groupName': 'Имя группы',
    'SNMP.SNMPUserGroups.securityLevel': 'Уровень безопасности',
    'SNMP.SNMPUserGroups.readView': 'Read View',
    'SNMP.SNMPUserGroups.writeView': 'Write View',
    'SNMP.SNMPUserGroups.editUserGroupsModalHeader': 'Редактирование',
    'SNMP.SNMPUserGroups.addUserGroupsModalHeader': 'Создание',
    'SNMP.SNMPUserGroups.notificationUpdatedSuccessfully': 'Пользовательская группа успешно обновлена',
    'SNMP.SNMPUserGroups.notificationCreatedSuccessfully': 'Пользовательская группа успешно создана',
    'SNMP.SNMPUserGroups.notificationDeletedSuccessfully': 'Пользовательская группа успешно удалена',
    'SNMP.SNMPUserGroups.securityLevels.noAuthNoPriv': 'noAuthNoPriv',
    'SNMP.SNMPUserGroups.securityLevels.authNoPriv': 'authNoPriv',
    'SNMP.SNMPUserGroups.securityLevels.authPriv': 'authPriv',
    'SNMP.SNMPUsers.userName': 'Имя пользователя',
    'SNMP.SNMPUsers.groupName': 'Имя группы',
    'SNMP.SNMPUsers.securityMode': 'Режим безопасности',
    'SNMP.SNMPUsers.authenticationProtocol': 'Протокол аутентификации',
    'SNMP.SNMPUsers.authenticationPassword': 'Пароль аутентификации',
    'SNMP.SNMPUsers.authenticationPasswordConfirmation': 'Подтверждение пароля аутентификации',
    'SNMP.SNMPUsers.privacyProtocol': 'Протокол конфиденциальности',
    'SNMP.SNMPUsers.privacyPassword': 'Пароль',
    'SNMP.SNMPUsers.privacyPasswordConfirmation': 'Подтверждение пароля',
    'SNMP.SNMPUsers.editUsersModalHeader': 'Редактирование',
    'SNMP.SNMPUsers.addUsersModalHeader': 'Создание',
    'SNMP.SNMPUsers.notificationUpdatedSuccessfully': 'Пользователь успешно обновлен',
    'SNMP.SNMPUsers.notificationCreatedSuccessfully': 'Пользователь успешно создан',
    'SNMP.SNMPUsers.notificationDeletedSuccessfully': 'Пользователь успешно удален',
    'SNMP.SNMPUsers.securityLevels.noAuthNoPriv': 'noAuthNoPriv',
    'SNMP.SNMPUsers.securityLevels.authNoPriv': 'authNoPriv',
    'SNMP.SNMPUsers.securityLevels.authPriv': 'authPriv',
    'SNMP.SNMPHosts.ipAddress': 'IP адрес',
    'SNMP.SNMPHosts.version': 'Версия',
    'SNMP.SNMPHosts.community': 'Community String',
    'SNMP.SNMPHosts.port': 'Порт',
    'SNMP.SNMPHosts.type': 'Тип',
    'SNMP.SNMPHosts.editHostsHeader': 'Редактирование',
    'SNMP.SNMPHosts.addHostsModalHeader': 'Создание',
    'SNMP.SNMPHosts.notificationUpdatedSuccessfully': 'Хост успешно обновлен',
    'SNMP.SNMPHosts.notificationCreatedSuccessfully': 'Хост успешно создан',
    'SNMP.SNMPHosts.notificationDeletedSuccessfully': 'Хост успешно удален',
    'SNMP.SNMPHosts.types.read': 'чтение',
    'SNMP.SNMPHosts.types.write': 'запись',
    'SNMP.SNMPHosts.types.traps': 'traps',
    'SNMP.SNMPHosts.versions.1': 'V1',
    'SNMP.SNMPHosts.versions.2c': 'V2',
    'SNMP.SNMPHosts.versions.3': 'V3',
    'SNMP.SNMPWirelessTraps.enableAll': 'Включить всё',
    'SNMP.SNMPWirelessTraps.disableAll': 'Выключить всё',
    'SNMP.SNMPWirelessTraps.notificationUpdatedSuccessfully': 'Wireless Traps успешно обновлены',
    'SNMP.SNMPWirelessTrapsBlocks.mesh_trap': 'Mesh',
    'SNMP.SNMPWirelessTrapsBlocks.wireless_client_trap': 'Беспроводной клиент',
    'SNMP.SNMPWirelessTrapsBlocks.rf_trap': 'RF',
    'SNMP.SNMPWirelessTrapsBlocks.roque_trap': 'Rogue',
    'SNMP.SNMPWirelessTrapsBlocks.wireless_mobility_trap': 'Wireless Mobility',
    'SNMP.SNMPWirelessTrapsBlocks.ap_trap': 'Точка доступа',
    'SNMP.SNMPWirelessTrapsBlocks.rrm_trap': 'RRM',
    'SNMP.SNMPWirelessTrapsBlocks.general_controller': 'General Controller',
    'SNMP.SNMPWirelessTrapsHeader': 'Wireless Traps',
    'SNMP.SNMPWirelessTrapsOptions.abate_snr': 'Abate SNR',
    'SNMP.SNMPWirelessTrapsOptions.auth_failure': 'Authentiication Failure',
    'SNMP.SNMPWirelessTrapsOptions.child_moved': 'Child Moved',
    'SNMP.SNMPWirelessTrapsOptions.child_moved:': 'Child Moved',
    'SNMP.SNMPWirelessTrapsOptions.excessive_children': 'Excessive Children',
    'SNMP.SNMPWirelessTrapsOptions.excessive_hop_count': 'Excessive Hopcount',
    'SNMP.SNMPWirelessTrapsOptions.onset_snr': 'Onset SNR',
    'SNMP.SNMPWirelessTrapsOptions.parent_change': 'Parent Change',
    'SNMP.SNMPWirelessTrapsOptions.tx_power': 'Tx Power',
    'SNMP.SNMPWirelessTrapsOptions.channels': 'Channels',
    'SNMP.SNMPWirelessTrapsOptions.coverage': 'Coverage',
    'SNMP.SNMPWirelessTrapsOptions.interference': 'Interference',
    'SNMP.SNMPWirelessTrapsOptions.load': 'Load',
    'SNMP.SNMPWirelessTrapsOptions.noise': 'Noise',
    'SNMP.SNMPWirelessTrapsOptions.anchor_client_enabled': 'Anchor Client Enabled',
    'SNMP.SNMPWirelessTrapsOptions.group': 'Group',
    'SNMP.SNMPWirelessTrapsOptions.general_controller': ' General Controller',
    'SNMP.SNMPWirelessTrapsOptions.association_fail': 'Association Fail',
    'SNMP.SNMPWirelessTrapsOptions.associate': 'Associate',
    'SNMP.SNMPWirelessTrapsOptions.authentication_fail': 'Authentication Fail',
    'SNMP.SNMPWirelessTrapsOptions.de_authenticate': 'Deauthenticate',
    'SNMP.SNMPWirelessTrapsOptions.disassociate': 'Disassociate',
    'SNMP.SNMPWirelessTrapsOptions.excluded': 'Excluded',
    'SNMP.SNMPWirelessTrapsOptions.authenticate': 'Authenticate',
    'SNMP.SNMPWirelessTrapsOptions.max_threshold_warning': 'Maximum Threshold Warning',
    'SNMP.SNMPWirelessTrapsOptions.rogue_client': 'Rogue Client',
    'SNMP.SNMPWirelessTrapsOptions.rogue_ap': 'Rogue AP',
    'SNMP.SNMPWirelessTrapsOptions.ap_stats': 'Статистика ТД',
    'SNMP.SNMPWirelessTrapsOptions.authorization': 'Авторизация',
    'SNMP.SNMPWirelessTrapsOptions.broken_antenna': 'Сломаная антенна',
    'SNMP.SNMPWirelessTrapsOptions.dual_band_radio_band_change': 'Dual-band Radio Band Change',
    'SNMP.SNMPWirelessTrapsOptions.dual_band_radio_role_change': 'Dual-band Radio Role Change',
    'SNMP.SNMPWirelessTrapsOptions.mfp': 'MFP',
    'SNMP.SNMPWirelessTrapsOptions.mode': 'Режим',
    'SNMP.SNMPWirelessTrapsOptions.no_radio_cards': 'No Radio Cards',
    'SNMP.SNMPWirelessTrapsOptions.register': 'Register',
    'SNMP.SNMPWirelessTrapsOptions.crash': 'Crash',
    'SNMP.SNMPWirelessTrapsOptions.interface_up': 'Interface Up',
    'SNMP.SNMPWirelessTrapsOptions.ip_address_fall_back': 'IP Address Fallback',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCheckAgent': 'Cледить за агентом и его состоянием',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCommunity': 'Community',
    'SNMPMonitoringConfig.SNMPMonitoringConfigCommunityPlaceHolder': 'Введите community',
    'SNMPMonitoringConfig.SNMPMonitoringConfigEnabled': 'Включение SNMP',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationCreated': 'Конфигурация SNMP мониторинга успешно создана',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationDeleted': 'Конфигурация SNMP мониторинга успешно удалена',
    'SNMPMonitoringConfig.SNMPMonitoringConfigNotificationUpdated': 'Конфигурация SNMP мониторинга успешно обновлена',
    'SNMPMonitoringConfig.SNMPMonitoringConfigSaveState': 'Сохранять состояние запроса в БД',
    'SNMPMonitoringConfig.SNMPMonitoringConfigTitle': 'Конфигурация SNMP мониторинга',
    'SNMPMonitoringConfig.SNMPMonitoringCPEMustBeAccessibleByIPFromServer': 'Точка должна быть доступна по IP с сервера',
    'SNMPMonitoringConfig.SNMPMonitoringNeedToEnableSNMPInCPESettings': 'Для корректной работы SNMP мониторинга необходимо включить работу SNMP агента в настройках ТД',
    'SNMPMonitoringConfig.tableColumnTitleIP': 'IP',
    'SNMPMonitoringConfig.tableColumnTitleServer': 'Сервер',
    'SNMPMonitoringConfig.tableColumnTitleSNMPStatus': 'SNMP Статус',
    'SNMPStat.address': 'Адрес',
    'SNMPStat.associated_users': 'Ассоциированные пользователи',
    'SNMPStat.associated_users_small': 'Ассоц. польз.',
    'SNMPStat.availability': 'Доступность',
    'SNMPStat.availabilityAvailably': 'Доступно',
    'SNMPStat.availabilityNOTAvailably': 'Недоступно',
    'SNMPStat.client': 'Клиент',
    'SNMPStat.filtersAvailablyAll': 'Все',
    'SNMPStat.filtersAvailablyAvailably': 'Доступно',
    'SNMPStat.filtersAvailablyNotAvailably': 'Недоступно',
    'SNMPStat.id': 'Идентификатор',
    'SNMPStat.name': 'Имя',
    'SNMPStat.nas_ip': 'NAS IP',
    'SNMPStat.SNMPStatTitle': 'Статистика SNMP',
    'SNMPStat.sortAssociatedUsers': 'По ассоц. польз.',
    'SNMPStat.sortAvailability': 'По доступности',
    'SNMPStat.sortClient': 'По имени клиента',
    'SNMPStat.sortLocation': 'По названию локации',
    'SNMPStat.sortName': 'По имени',
    'SNMPStat.sortNASIP': 'По NAS IP',
    'SNMPStat.sortNumberOfWLANs': 'По количеству Wi-Fi сетей',
    'SNMPStat.sortTimestamp': 'По времени',
    'SNMPStat.sortTrafficRX': 'По вх. трафик',
    'SNMPStat.sortTrafficRXSpeed': 'По скорости вх. трафика',
    'SNMPStat.sortTrafficTX': 'По исх. трафик',
    'SNMPStat.sortTrafficTXSpeed': 'По скорости исх. трафика',
    'SNMPStat.sortUptime': 'По времени работы',
    'SNMPStat.time_stamp': 'Метка времени',
    'SNMPStat.traffic_rx_speed': 'Скорость вх. трафика',
    'SNMPStat.traffic_rx_volume': 'Входящий трафик',
    'SNMPStat.traffic_tx_speed': 'Скорость исх. трафика',
    'SNMPStat.traffic_tx_volume': 'Исх. трафик',
    'SNMPStat.uptime': 'Время работы',
    'SNMPStat.wlans': 'Сети Wi-Fi',
    'statistics.allCpes': 'Все точки доступа',
    'statistics.allUsers': 'Все пользователи',
    'statistics.authorizationDataForDownload': 'Данные авторизации',
    'statistics.authorized': 'Только авторизованные',
    'statistics.byconnectedClients': 'По подключенным клиентам',
    'statistics.byCPULoad': 'По загрузке CPU',
    'statistics.bydisconnectedClients': 'По отключенным клиентам',
    'statistics.byDuration': 'По длительности',
    'statistics.byLocation': 'По локациям',
    'statistics.byMemoryLoad': 'По исп. памяти',
    'statistics.bypasserbyClients': 'По проходящим клиентам',
    'statistics.byRxTraffic': 'По вх. трафику',
    'statistics.bytotalClients': 'По всем клиентам',
    'statistics.byTraffic': 'По трафику',
    'statistics.byTxTraffic': 'По исх. трафику',
    'statistics.chooseCPEs': 'Выберите ТД',
    'statistics.chooseDate': 'Выберите дату',
    'statistics.chooseDateRange': 'Выберите период',
    'statistics.clients': 'Клиенты',
    'statistics.companyForDownload': 'Компания',
    'statistics.cpeForDownload': 'ТД',
    'statistics.cpuLoadChartAxis': 'Загрузка процессора',
    'statistics.cpuLoadChartSeries': 'Процессор',
    'statistics.cpuLoadChartTitle': 'Загрузка процессора',
    'statistics.createAndDownload': 'Создать и скачать',
    'statistics.export': 'Экспорт данных',
    'statistics.exportStat': 'Экспорт данных Wi-Fi таргет',
    'statistics.field': 'Поле',
    'statistics.filteringByCPE': 'Фильтрация по ТД',
    'statistics.filtersCPE': 'Имя точки доступа',
    'statistics.filtersRadio': 'Имя интерфейса (radio или VLAN)',
    'statistics.filtersUsers': 'Пользователи',
    'statistics.filtersWLAN': 'Имя сети Wi-Fi',
    'statistics.from': 'Начало',
    'statistics.goToClientsSettings': 'К настройкам клиента',
    'statistics.goToCPESettings': 'К настройкам ТД',
    'statistics.goToWLANSettings': 'К настройкам Wi-Fi сети',
    'statistics.ipsForDownload': 'IP',
    'statistics.limit': 'Количество',
    'statistics.locationForDownload': 'Локация',
    'statistics.macForDownload': 'MAC',
    'statistics.memoryLoadChartAxis': 'Использование памяти',
    'statistics.memoryLoadChartSeries': 'Память',
    'statistics.memoryLoadChartTitle': 'Использование памяти',
    'statistics.noCompanyNameTooltipForTable': 'Нет имени компании',
    'statistics.offset': 'Начиная с',
    'statistics.order': 'Порядок',
    'statistics.pagination': 'Пагинация',
    'statistics.paginationCPERaitngsOutOf': 'из',
    'statistics.paginationLoad': 'Загрузить',
    'statistics.pan': 'Кликните и потяните для зума. Удерживайте shift для перемещения.',
    'statistics.passerbyClients': 'Проход. клиенты',
    'statistics.period': 'Период',
    'statistics.rawButton': 'Отчёт по сессиям',
    'statistics.rawConfirmation': 'Пожалуйста, подтвердите формирование отчёта по клиентским сессиям со следующими параметрами: ',
    'statistics.rawModal': 'Формирование отчёта',
    'statistics.rawRequest': 'Сформировать отчёт',
    'statistics.received': 'Входящий',
    'statistics.receivedShort': 'вх.',
    'statistics.sessionClientsChartAxisOffline': 'оффлайн',
    'statistics.sessionClientsChartAxisOnline': 'онлайн',
    'statistics.sessionClientsChartSeriesConnected': 'Подключен',
    'statistics.sessionClientsChartSeriesDisconnected': 'Отключен',
    'statistics.sessionClientsChartTitle': 'Сессии клиента',
    'statistics.sessionClientsChartTooltipCPE': 'ТД',
    'statistics.sessionClientsChartTooltipFrequency': 'Частота',
    'statistics.sessionClientsChartTooltipMode': 'Режим',
    'statistics.sessionClientsChartTooltipWLAN': 'Сеть Wi-Fi',
    'statistics.sessionDurationForDownload': 'Продолжительность сессии (сек.)',
    'statistics.showClient': 'показать информацию по клиенту',
    'statistics.ssidForDownload': 'SSID',
    'statistics.startForDownload': 'Начало',
    'statistics.statCpesTooltipAvgTime': 'ср. время',
    'statistics.statCpesTooltipCount': 'количество',
    'statistics.statCpesTooltipFirst': 'первый ',
    'statistics.statCpesTooltipReturn': 'повторный',
    'statistics.statCpesTooltipRx': 'вх.',
    'statistics.statCpesTooltipSession': 'сессия',
    'statistics.statCpesTooltipTx': 'исх.',
    'statistics.stopForDownload': 'Завершение',
    'statistics.to': 'Конец',
    'statistics.totalClientsChartAxis': 'Клиенты',
    'statistics.totalClientsChartSeries': 'Клиенты',
    'statistics.totalClientsChartTitle': 'Всего клиентов',
    'statistics.totalDurationForDownload': 'Всего продолжительность (сек.)',
    'statistics.totalTxRxPerUserChartMbytes': 'Мбайт',
    'statistics.totalTxRxPerUserChartRX': 'вх.',
    'statistics.totalTxRxPerUserChartTitle': 'Всего вх./исх. на пользователя',
    'statistics.totalTxRxPerUserChartTX': 'исх.',
    'statistics.totalTxRxPerUserChartTXandRX': 'вх. + исх.',
    'statistics.trafficDownloadedForDownload': 'Трафик входящий',
    'statistics.trafficUploadedForDownload': 'Трафик исходящий',
    'statistics.transmitted': 'Исходящий',
    'statistics.transmittedShort': 'исх.',
    'statistics.txRxChartMbytes': 'Мбайт',
    'statistics.txRxChartRX': 'вх.',
    'statistics.txRxChartTitle': 'Всего вх./исх.',
    'statistics.txRxChartTX': 'исх.',
    'statistics.txRxChartTXandRX': 'вх. + исх.',
    'statistics.txRxRateChartMbytess': 'Мбайт/c',
    'statistics.txRxRateChartRXRate': 'вх. скорость',
    'statistics.txRxRateChartTitle': 'Вх./исх. скорость',
    'statistics.txRxRateChartTXandRXRate': 'вх. + исх. скорость',
    'statistics.txRxRateChartTXRate': 'исх. скорость',
    'statistics.txRxRatePerUserChartMbytess': 'Мбайт/c',
    'statistics.txRxRatePerUserChartRXRate': 'вх. скорость',
    'statistics.txRxRatePerUserChartTitle': 'Вх./исх. скорость на пользователя',
    'statistics.txRxRatePerUserChartTXandRXRate': 'вх. + исх. скорость',
    'statistics.txRxRatePerUserChartTXRate': 'исх. скорость',
    'statistics.userData': 'Трафик пользователей',
    'statistics.username': 'Данные последней авторизации',
    'statistics.vlanForDownload': 'VLAN',
    'statistics.wlan': 'Сеть Wi-Fi',
    'statistics.wlanForDownload': 'Сеть Wi-Fi',
    'statistics.wlanForReportModal': 'Сеть Wi-Fi',
    'support.analytics.analytics1': 'оценка проходимости некоторой точки, локации;',
    'support.analytics.analytics2': 'построение тепловых карт при сопоставлении данных о местоположении с реальными картами;',
    'support.analytics.analytics3': 'сбор списка MAC-адресов для загрузки в Яндекс / myTarget для таргетинга в онлайне.',
    'support.analytics.analyticsHeader': 'Аналитика',
    'support.analytics.analyticsMain1': 'В блоке Wi-Fi аналитика собраны результаты по анализу данных, получаемых на основе так называемого "Wi-Fi Таргет" - радио интерфейса Wi-Fi, работающего в режиме пассивного монитора эфира, который собирает все запросы на подключение от устройств к Wi-Fi сетям.',
    'support.analytics.analyticsMain2': 'На основе этих данных получаются следующие метрики:',
    'support.analytics.heatmapHeader': 'Тепловые карты',
    'support.analytics.heatmapMain': 'На странице тепловых карт сопоставляются данные о местоположении точек доступа и клиентов, улавливаемых с помощью Wi-Fi Таргет с реальными картами. Карты позволяют визуализировать наиболее горячие зоны с точки зрения проходимости за выбранный период, а также оценить динамику.',
    'support.analytics.radarExportHeader': 'Wi-Fi таргет',
    'support.analytics.radarExportMain': 'Wi-Fi таргет — выгрузка данных клиентов по электронной почте в MyTarget, Yandex audience для использования их при показе рекламы.',
    'support.analytics.visitorsHeader': 'Посетители',
    'support.analytics.visitorsMain1': 'Отчеты по всем собираемым данным о клиентах формируются здесь. С помощью фильтров можно составить отчет как по всем ТД, так и по конкретным. На основе этого отчета можно выявить повторные посещения, продолжительность визитов, устройства клиентов и т.д.',
    'support.analytics.visitorsMain2': 'На странице представлена оценка посещаемости локаций, в которых установлены Wi-Fi Таргет. Непрерывным визитом считается захват пользователя на протяжении 10 минут. Новые пользователи считаются относительно выбранного периода. У одного пользователя может быть несколько визитов. Важно учесть, что данные в аналитике уже лишены шума и рандомных MAC адресов, что позволяет более точно оценить посещаемость и не учитывать одних пользователей по несколько раз.',
    'support.glossary.AP': 'ТД — точка доступа; AP — access point',
    'support.glossary.BSSID': 'BSSID — Basic Service Set IDentifier (MAC адрес сетевого адаптера)',
    'support.glossary.CPU': 'CPU — Central Processing Unit (центральный процессор)',
    'support.glossary.DHCP': 'DHCP — Dynamic Host Configuration Protocol (протокол, отвечающий за динамическую выдачу IP-адресов устройствам сети)',
    'support.glossary.DNS': 'DNS — Domain Name System (система доменных имён)',
    'support.glossary.header': 'Глоссарий',
    'support.glossary.HTTP': 'HTTP - HyperText Transfer Protocol (протокол передачи гипертекста)',
    'support.glossary.HTTPS': 'HTTPS - HyperText Transfer Protocol Secure (расширение протокола HTTP, поддерживающее шифрование)',
    'support.glossary.IP': 'IP — Internet Protocol address (уникальный сетевой адрес узла в компьютерной сети)',
    'support.glossary.MAC': 'MAC — Media Access Control (уникальный идентификатор, присваиваемый каждой единице сетевого оборудования)',
    'support.glossary.NAS': 'NAS — Network Attached Storage (сервер для хранения данных на файловом уровне)',
    'support.glossary.NAT': 'NAT — Network Address Translation (преобразование сетевых адресов)',
    'support.glossary.NMS': 'NMS — Network Management System (система управления сетью)',
    'support.glossary.OS': 'OS — Operating System (операционная система)',
    'support.glossary.RADIUS': 'RADIUS — Remote Authentication Dial-In User Service (служба дистанционной аутентификации пользователей по коммутируемым линиям)',
    'support.glossary.RRM': 'RRM — Radio Resource Management (управление характеристиками радиопередачи в системе)',
    'support.glossary.RSSI': 'RSSI — Received Signal Strength Indicator (полная мощность принимаемого приёмником сигнала)',
    'support.glossary.SOFTWARE': 'ПО — Программное обеспечение',
    'support.glossary.SSID': 'SSID — Service Set Identifier (символьное название беспроводной точки доступа Wi-Fi)',
    'support.glossary.URL': 'URL — Uniform Resource Locator (система унифицированных адресов электронных ресурсов)',
    'support.glossary.UUID': 'UUID — Universally Unique Identifier (универсальный уникальный идентификатор)',
    'support.glossary.VLAN': 'VLAN — Virtual Local Area Network (локальная компьютерная сеть, представляющая собой группу хостов с общим набором требований)',
    'support.glossary.WLAN': 'WLAN — Wireless Local Area Network (локальная сеть, построенная на основе беспроводных технологий)',
    'support.glossary.WPA': 'WPA — Wi-Fi Protected Access (программа сертификации устройств беспроводной связи)',
    'support.header': 'Описание платформы',
    'support.nms.ClientRFHeader': 'Состояние Wi-Fi клиента',
    'support.nms.ClientRFMain': 'Позволяет просмотреть состояние подключения клиента к сети на графике. Можно просмотреть такие параметры как скорость приема, скорость передачи, сигнал, шум, SNR. Также доступен просмотр рейтинга клиентов по качеству сигнала (можно просмотреть SNR среднее и т.д.)',
    'support.nms.clientsHeader': 'Клиенты',
    'support.nms.ClientsHeader': 'Клиенты',
    'support.nms.clientsMain': 'После подключения клиента к Wi-Fi-сети в этом подразделе будет находиться вся информация о его устройстве — MAC-адрес, IP, удаленность от ТД, название сети, к которой он подключен (можно увидеть, нажав напротив устройства кнопку "показать информацию по клиенту"), время подключения к определенному роутеру.',
    'support.nms.ClientsMain': 'После подключения клиента к Wi-Fi-сети в этом подразделе будет находиться вся информация о его устройстве — MAC-адрес, IP, удаленность от ТД, название сети, к которой он подключен (можно увидеть, нажав напротив устройства кнопку "показать информацию по клиенту"), время подключения к определенному роутеру.',
    'support.nms.cpesConnHeader': 'Подключение ТД',
    'support.nms.cpesConnMain': 'В Подключениях ТД удобно отследить время работы каждой точки. Фильтры позволяют отсортировать по локациям, дате и статусу подключения, что делает мониторинг раздела весьма удобным.',
    'support.nms.cpesHeader': 'Точки доступа',
    'support.nms.cpesMain1': 'Более подробные данные каждой ТД можно найти здесь.',
    'support.nms.cpesMain2': 'По каждой характеристике (потребляемый трафик, количество клиентов, загруженность процессора и памяти) предоставлены данные за определенный устанавливаемый промежуток времени.',
    'support.nms.dashboard1': 'характеристиках сервера (процессор, загрузка процессора, память);',
    'support.nms.dashboard2': 'событиях за последние 24 ч (уровень, тип, объект, статус, время точек доступа);',
    'support.nms.dashboard3': 'о состоянии и характеристиках перегруженных и самых активных точек доступа;',
    'support.nms.dashboard4': 'количество новых и повторных подключений, визитов;',
    'support.nms.dashboard5': 'процентном соотношении марок устройств, с которых был выполнен вход.',
    'support.nms.dashboardHeader': 'Рабочий стол',
    'support.nms.dashboardMain': 'Рабочий стол предоставляет информацию о:',
    'support.nms.eventsHeader': 'События',
    'support.nms.eventsMain1': 'На графике представлены события за последние 24 ч, связанные со временем и статусом подключенных клиентов.',
    'support.nms.eventsMain2': 'Так же, нажав на клиента, можно посмотреть подробную информацию о сессии подключения, ТД, Wi-Fi-сети.',
    'support.nms.FirewallHeader': 'Брандмауэр',
    'support.nms.FirewallMain': 'Раздел позволяет просматривать и редактировать существующие правила брандмауэра и создавать новые. Наш брандмауэр основан на Iptables. Правила создаются в его нотации. Документацию по правилам можно найти тут: ',
    'support.nms.GuestControlHeader': 'Гостевой контроль',
    'support.nms.GuestControlMain': 'Управление NAS - Network Access Server - модулем, отвечающим за перенаправление не авторизованных пользователей на портал идентификации и авторизации (Captive Portal).',
    'support.nms.HotspotHeader': 'Hotspot 2.0',
    'support.nms.HotspotMain': 'Управление профилями Hotspot 2.0, с помощью которых можно обеспечить подключение к закрытым сетям типа WPA2-Enterprise с помощью предустановленного на устройство профиля, либо с помощью SIM карты устройства (EAP-SIM, EAP-AKA, EAP-AKA’)',
    'support.nms.LicensesHeader': 'Лицензии',
    'support.nms.LicensesMain': 'В данном разделе отображается информация о текущем количестве точек доступа, максимальном количестве точек доступа. Возможно просмотреть статистику по точкам доступа, Wi-Fi таргет и тепловым картам на графике за выбранный период',
    'support.nms.locationsHeader': 'Локации',
    'support.nms.monitoring': 'Монитор',
    'support.nms.nms1': 'статистика по локациям, точкам доступа и клиентам касаемо системной информации;',
    'support.nms.nms2': 'события за последние 24 ч (присутствовали ранее на рабочем столе), правила(?), подключениях точек доступа (модель, имя, время работы, локация), клиентах, удаленности пользователей;',
    'support.nms.nms3': 'управление характеристиками радиопередачи в системе (RRM);',
    'support.nms.nms4': 'информация о сетях Wi-Fi (название (SSID), безопасность, количество точек доступа, клиентов, виртуальных локальных сетей (VLAN));',
    'support.nms.nms5': 'служба дистанционной аутентификации пользователей (RADIUS);',
    'support.nms.nms6': 'осуществляющий контроль и фильтрация проходящего через сеть сетевого трафика в соответствии с заданными правилами (Firewall);',
    'support.nms.nms7': 'технология Hotspot 2.0, позволяющая автоматически аутентифицироваться и получить доступ в сеть Wi-Fi, предварительно отправив запрос к точке доступа;',
    'support.nms.nms8': 'гостевой контроль (позволяет установить правила редиректа для порталов).',
    'support.nms.nmsHeader': 'NMS',
    'support.nms.nmsMain': 'В данной платформе управления сетями содержится:',
    'support.nms.RadiusHeader': 'RADIUS',
    'support.nms.RadiusMain': 'RADIUS - добавление внешних серверов AAA (аутентификации, авторизации и аккаунтинга) для организации на WLAN типа сети WPA2-Enterprise или СОРМ',
    'support.nms.reportsHeader': 'Отчёты',
    'support.nms.reportsMain': 'Отчеты по данным о событиях, количестве клиентов, точках доступа и совокупные отчеты по точкам (Обобщенная статистика по ТД) можно настроить в данном разделе. А также сделать выгрузку отчета по периодам. Доступные форматы — JSON, CSV, XLS. Для XLS у Обобщенная статистика по ТД можно добавить графики зависимости определенной величины от времени (загрузка, выгрузка, клиенты, загруженность процессора).',
    'support.nms.RRMHeader': 'RRM',
    'support.nms.RRMMain': 'RRM — Radio Resource Management — настройка групп для работы запатентованного алгоритма управления радио ресурсами. ',
    'support.nms.rulesHeader': 'Правила',
    'support.nms.rulesMain1': 'Каждому AP можно задать конкретные "правила" подключения пользователей.',
    'support.nms.rulesMain2': 'Условия прописываются в cкрипт и подключаются во вкладке "Правила".',
    'support.nms.rulesMain3': 'Далее можно выбрать нужное правило к каждой ТД.',
    'support.nms.SNMPMonitoringConfigHeader': 'Конфигурация мониторинга по SNMP',
    'support.nms.SNMPMonitoringConfigMain': 'Позволяет включить SNMP мониторинг для устройств, включить слежение за агентом и его состоянием',
    'support.nms.SNMPStatHeader': 'Статистика SNMP',
    'support.nms.SNMPStatMain': 'Позволяет просмотреть статистику SNMP, которая получена с устройств, сконфигурированных в разделе Конфигурация мониторинга по SNMP',
    'support.nms.statistics': 'Статистика',
    'support.nms.systemCpesMain1': 'В данном блоке происходит управление точками доступа — перезагрузка, обновление ПО, сброс настроек и установка новых, создание шаблонов настроек, а также вся системная информация о ней.',
    'support.nms.systemCpesMain2': 'В дополнительных настройках Wi-Fi-сети можно добавить формирование отчета по статистике по промежуткам времени.',
    'support.nms.systemHeader': 'Система',
    'support.nms.systemMain1': 'В данном подразделе происходит сбор данных с каждой локации. В локации объединены все образованные ТД.',
    'support.nms.systemMain2': 'Здесь видно, сколько каждая локация потребляет трафика, количество клиентов, загруженность процессора и памяти.',
    'support.nms.TemplatesHeader': 'Шаблоны',
    'support.nms.TemplatesMain': 'Раздел позволяет создавать шаблоны с настройками, для их дальнейшего применения на точках доступа',
    'support.nms.wifiAnalyzerHeader': 'Анализ Wi-Fi',
    'support.nms.wifiAnalyzerMain1': 'Данный интерфейс сканирует точку доступа и выдает результаты о технических характеристиках ее Wi-Fi-сетей (сигнал, канал, частота, смещение, ширина).',
    'support.nms.wifiAnalyzerMain2': 'Каждая Wi-Fi-сеть имеет свой график. Для удобства можно выбрать как все доступные WLAN, так и несколько конкретных.',
    'support.nms.wlansHeader': 'Сети Wi-Fi',
    'support.nms.WlansHeader': 'Сети Wi-Fi',
    'support.nms.wlansMain': 'Информация о Wi-Fi-сети, которая раздается конкретными ТД отображена на этой вкладке — трафик, количество клиентов и локация, к которой она относится.',
    'support.nms.WlansMain': 'Информация о Wi-Fi-сети, которая раздается конкретными ТД отображена на этой вкладке — трафик, количество клиентов и локация, к которой она относится.',
    'support.portal.adsAndPolls1': 'период активности рекламы;',
    'support.portal.adsAndPolls2': 'приоритет (чем больше приоритет, тем чаще будет показываться пользователям данная реклама);',
    'support.portal.adsAndPolls3': 'лимит показов (бесконечное или ограниченное, по истечении лимита, данная реклама перестанет показываться пользователям);',
    'support.portal.adsAndPolls4': 'общее время показа и возможность пропуска рекламы;',
    'support.portal.adsAndPolls5': 'настройки показа по дням недели, времени суток;',
    'support.portal.adsAndPolls6': 'показ определенным (всем) моделям устройств или ОС.',
    'support.portal.adsAndPollsHeader': 'Реклама и опросы',
    'support.portal.adsAndPollsMain1': 'Все, что касается настройки рекламы находится здесь.',
    'support.portal.adsAndPollsMain2': 'Возможно выбрать один из типов рекламы для портала: изображение, видео, опрос по теме, опрос о данных пользователя (например, указать дату дня рождения или пол).',
    'support.portal.adsAndPollsMain3': 'При настройке каждого вида рекламы можно указать:',
    'support.portal.adsHeader': 'Реклама и опросы',
    'support.portal.adsMain': 'Статистика просмотров рекламы и опросов, а так же состоянию рекламы в данный момент (идет показ, планируется и т.д.).',
    'support.portal.authHeader': 'Подключения, аутентификации, авторизации',
    'support.portal.authMain': 'Статистика по подключениям, аутентификации и авторизации новых, уникальных пользователей, браузерам, операционным системам, типах и производителях устройств, которые используют клиенты находится в данных разделах. Все диаграммы можно скачать в виде изображений.',
    'support.portal.birthdaysHeader': 'Дни рождения',
    'support.portal.birthdaysMain': 'Аналитика по дням рождения пользователей, их социальным сетям и контактам.',
    'support.portal.configuration': 'Конфигурация',
    'support.portal.dashboardHeader': 'Рабочий стол',
    'support.portal.dashboardMain': 'Краткая сводка основных данных по разделу "Портал".',
    'support.portal.data.accountsHeader': 'Пользователи',
    'support.portal.data.accountsMain': 'Здесь находятся гости Ваших Порталов авторизации. Аккаунт гостя постоянно пополняется: у каждого гостя может быть несколько устройств, авторизация через разные социальные сети, пользовательские сессии. Это позволяет точно знать день рождения и местоположение. Все данные можно выгрузить и сохранить в форматах PDF, CSV и XLS.',
    'support.portal.data.adsAndPollsMain': 'Статистика просмотров по типам авторизации (бесплатный, спонсорский, служебный) в виде графиков за указанный период времени.',
    'support.portal.data.analyticsAndData': 'Аналитика и данные',
    'support.portal.data.birthdaysMain': 'Данные о днях рождения ваших гостей, собранные из социальных сетей при авторизации и с помощью опросов. Все данные можно скачать в PDF, CSV и XLS.',
    'support.portal.data.portalLocationsStatHeader': 'Локации',
    'support.portal.data.portalLocationsStatMain': 'Сводная статистика по каждой из локаций. Есть данные о кликах, просмотрах, визитах (первых, повторных, уникальных, всех) по каждой из локации. Есть возможность фильтрации по профилю, временному промежутку и локациям.',
    'support.portal.data.portalSessionsHeader': 'Найти сессию',
    'support.portal.data.portalSessionsMain': 'Здесь находятся данные о сессиях гостей вашего портала. Можно узнать данные об IP адресе, версии ОС, браузере и профиле сессии. Присутствует возможность фильтрации по различным параметрам',
    'support.portal.data.statisticsHeader': 'Статистика',
    'support.portal.data.statisticsMain': 'В одном месте собраны данные по новым, повторным пользователям и уникальным, повторяющимся подключениям пользователей с разбивкой по типу Операционной системы, типу устройства, по языкам, по производителям и, конечно, по способам идентификации и авторизации. Все данные можно выгрузить за период и сохранить в форматах PDF, CSV и XLS.',
    'support.portal.hotelVouchersHeader': 'Отельные ваучеры',
    'support.portal.hotelVouchersMain1': 'Ваучеры нужны для входа в интернет без аутентификации с помощью специального кода (допустим, через SMS).',
    'support.portal.hotelVouchersMain2': 'Вся информация о доступных ваучерах отображается на данной странице — профиль, время действия, код для доступа, статус (активирован или нет)',
    'support.portal.hotelVouchersMain3': 'Все данные можно редактировать в окне для каждого ваучера.',
    'support.portal.locationHeader': 'Локация',
    'support.portal.locationMain': 'На карте можно посмотреть все локации и описание к ним: владельцев, адреса, даты создания. Каждую локацию можно отредактировать в специальном окне.',
    'support.portal.pagesHeader': 'Страницы',
    'support.portal.pagesMain': 'В данном интерфейсе настраивается визуальная часть страницы портала  — положение логотипов, фон, тема, заголовок.',
    'support.portal.paidInternet.paidInternetHeader': 'Платный интернет',
    'support.portal.paidInternet.paidInternetMain': 'В данном блоке находятся настройки платного интернета.',
    'support.portal.paidInternet.paymentHeader': 'Платежные системы',
    'support.portal.paidInternet.paymentMain': 'Здесь происходит настройка методов пополнения баланса: установка типа (внешний шлюз — переход на другой сайт для оплаты, инструкция — выполнения ряда действий для оплаты), изображения, указания мерчанта — уникального номера для предоставления его поставщику Платежной системы. ',
    'support.portal.paidInternet.tariffsHeader': 'Тарифы',
    'support.portal.paidInternet.tariffsMain': 'Добавление тарифов и всех необходимых для него настроек: стоимость, скорость, длина сессии.',
    'support.portal.paidInternet.vouchersHeader': 'Ваучеры',
    'support.portal.paidInternet.vouchersMain1': 'Добавление ваучеров со всеми сопутствующими настройками — время действия и тариф.',
    'support.portal.paidInternet.vouchersMain2': 'В списке видно статус ваучера, код для его использования, время действия и тариф.',
    'support.portal.portalHeader': 'Портал',
    'support.portal.portalMain': 'В данном блоке сосредоточены настройка и статистика по порталам авторизации — специальным веб страницам, на которых пользователи могут проходить этапы идентификации (по SMS или электронной почте) с последующей авторизацией и просмотром рекламы.',
    'support.portal.previewHeader': 'Предпросмотр',
    'support.portal.previewMain': 'Для просмотра демо-версии портала необходимо выбрать профиль, страницу и аккаунт. Далее можно перейти по ссылке непосредственно на сам портал и проверить его работу.',
    'support.portal.profiles1': 'привязать профиль к локации;',
    'support.portal.profiles2': 'установить часовой пояс;',
    'support.portal.profiles3': 'выставить условия работы (Wi-Fi сети, ТД);',
    'support.portal.profiles4': 'добавить MAC-адреса пользователей в специальные листы доступа — черный список (доступа к профилю нет), белый список (доступ без аутентификации и авторизации, преимущественно для устройств, на которых нельзя принять SMS), список доступа (вместо аутентификации использовать заранее известный код или ваучер);',
    'support.portal.profiles5': 'указать способ аутентификации (с помощью SMS или e-mail) и авторизации (через соц.сети, ваучеры и т.д.)',
    'support.portal.profilesHeader': 'Профили',
    'support.portal.profilesMain1': 'Здесь видны все профили, нажав на каждый из которых можно выставить определенные настройки:',
    'support.portal.profilesMain2': 'У каждого способа авторизации можно настроить сессию — время, количество, действия, которые необходимо выполнить, чтобы получить доступ в интернет.',
    'support.portal.recommendationHeader': 'Рекомендации',
    'support.portal.recommendationMain': 'Небольшие подсказки по добавлению рекламы и опросов для более эффективного пользования платформой.',
    'system.addCode': 'Вставьте код лицензии',
    'system.addLicense': 'Добавить лицензию',
    'system.analyticsCurrent': 'Текущие аналитики',
    'system.analyticsHint': 'Аналитики - это число включенных Wi-Fi Таргет на точках доступа',
    'system.analyticsLimit': 'Максимум Аналитик',
    'system.byFile': 'С помощью файла',
    'system.byText': 'С помощью кода',
    'system.current': 'Текущие ТД',
    'system.import': 'Импортировать',
    'system.licenceActivationCaption': 'Активация лицензии',
    'system.limit': 'Максимум ТД',
    'system.portalCurrent': 'Текущие порталы',
    'system.portalLimit': 'Максимум Порталов',
    'system.selectedFile': 'Выбраный файл',
    'system.selectedFileName': 'Имя выбранного файла',
    'system.selectFile': 'Выбрать файл',
    'system.uploadFileButton': 'Отправить',
    'tags.addTags': 'Добавить теги',
    'tags.availlableTags': 'Доступные теги',
    'tags.currentTags': 'Добавленные теги',
    'tags.deleteAllTags': 'Удалить все теги',
    'tariffs.add': 'Добавить тариф',
    'tariffs.amount': 'Стоимость',
    'tariffs.edit': 'Редактирование тарифа',
    'tariffs.locale.en': 'Английский',
    'tariffs.locale.locale': 'Языки',
    'tariffs.locale.ru': 'Русский',
    'tariffs.locale.tg': 'Таджикский',
    'tariffs.name': 'Название',
    'tariffs.new': 'Новый тариф',
    'tariffs.noProfileSelectedError': 'Необходимо выбрать профиль',
    'tariffs.notificationTariffCreatedSuccessfully': 'Тариф успешно создан',
    'tariffs.notificationTariffDeletedSuccessfully': 'Тариф успешно удален',
    'tariffs.notificationTariffUpdatedSuccessfully': 'Тариф успешно обновлен',
    'tariffs.profileCaptionInTable': 'Профиль',
    'tariffs.profileField': 'Профиль',
    'tariffs.profileSelectorTip': 'Нажмите enter для выбора',
    'tariffs.recurringCaption': 'Периодичность подписки',
    'tariffs.recurringPeriodCaption': 'Период',
    'tariffs.recurringPeriodDay': 'День',
    'tariffs.recurringPeriodMonth': 'Месяц',
    'tariffs.recurringPeriodWeek': 'Неделя',
    'tariffs.recurringSwitcherCaption': 'Включить периодичность подписки',
    'tariffs.selectProfile': 'Выберите профиль',
    'tariffs.session': 'Длина сессии',
    'tariffs.speed': 'Скорость',
    'tariffs.subscriptionDescriptionAdvice': 'Вставьте текст описания в поле ниже',
    'tariffs.subscriptionDescriptionCaption': 'Описание',
    'tariffs.tariffTypeCaption': 'Тип тарифа',
    'tariffs.tariffTypeSubscription': 'Подписка',
    'tariffs.tariffTypeVoucher': 'Ваучер',
    'tariffs.title': 'Тарифы',
    'template.addTemplate': 'Добавить шаблон',
    'template.advancedParameters': '#Н/Д',
    'template.advancedSettings': 'Дополнительные настройки',
    'template.allUUIDs': 'Все UUIDs',
    'template.alwaysApply': 'Применять всегда',
    'template.autoConfig': 'Авто конфиг.',
    'template.autoConfiguration': 'Конфигурация при подключении ТД',
    'template.basicParameters': 'Настройка базовых параметров шаблонов',
    'template.configFor': 'Конфигурация',
    'template.configGeneral': 'Настройка конфигурации',
    'template.configRadio': 'Настройка интерфейса',
    'template.confirmButton': 'Подтвердить',
    'template.cpeModel': 'Модель ТД',
    'template.CPEsTemplates': 'Шаблоны ТД',
    'template.fieldMustBeCsvFile': 'Поле должно быть csv файлом',
    'template.locationChangeHint': 'При изменении локации сброситься выбор всех сетей Wi-Fi, выбранных для интерфейсов',
    'template.macPrefix': 'MAC префикс',
    'template.macPrefixHint': 'MAC префикс в формате XX:XX:XX:XX:XX:XX',
    'template.manualConfig': 'Ручная конфиг.',
    'template.networkMask': 'Маска подсети',
    'template.networkMaskHint': 'Маска подсети в формате X.X.X.X',
    'template.notificationTemplateDeletedSuccessfully': 'Шаблон успешно удален',
    'template.notificationTemplateUpdatedSuccessfully': 'Шаблон успешно обновлен',
    'template.requiredConfigParameters': 'Настройка конфигурации',
    'template.statLogLBSSettings': 'Настройка статистики, логирования и Wi-Fi Таргет',
    'template.tableCPEsUUID': 'Точки доступа',
    'template.tableOff': 'выкл.',
    'template.tableOn': 'вкл.',
    'template.template': 'Шаблон',
    'template.templateAddUUIDPlaceHolder': 'Добавить UUID',
    'template.templateDescriptionPlaceHolder': 'Введите описание',
    'template.templateNamePlaceHolder': 'Введите имя шаблона',
    'template.templateSettings': 'Настройки Шаблона',
    'template.templateWizard': 'Помощник создания шаблона',
    'template.wizardrConfigParametersTitle': 'Настройка конфигурации',
    'template.wizardSelectModelPlaceholder': 'Выберите модель',
    'template.wizardWLANs': 'Сети Wi-Fi',
    'template.wizardWLANsPlaceHolder': 'Выберите сеть Wi-Fi',
    'template.wlansSelectorHint': 'Доступны только сети Wi-Fi из локации, которая выбрана для шаблона',
    'template.wrongDataInFile': 'Неверные данные в файле',
    'userAccount.actions': 'Действия',
    'userAccount.add': 'Добавить пользователя',
    'userAccount.addition': 'Пополнение',
    'userAccount.addToBlacklist': 'Добавить в черный список',
    'userAccount.amount': 'Сумма',
    'userAccount.authCode': 'Код аутентификации',
    'userAccount.authCodeInfo': 'Код генерируется при нажатии "Создать код доступа" в колонке "Идентификация". Именно его нужно вводить клиенту для доступа в интернет.',
    'userAccount.balance': 'Баланс',
    'userAccount.balanceInfo': 'Баланс виртуального счета в случае платного доступа',
    'userAccount.birthday': 'День рождения',
    'userAccount.city': 'Город проживания',
    'userAccount.createAt': 'Дата создания',
    'userAccount.createdOn': 'Дата',
    'userAccount.currency': 'Валюта',
    'userAccount.dataFromSocials': 'Информация о пользователе из соц. сетей',
    'userAccount.deleteAccessListCode': 'Удалить код доступа',
    'userAccount.deleteFromBlacklist': 'Удалить из черного списка',
    'userAccount.edit': 'Редактирование пользователя',
    'userAccount.email': 'Электронная почта',
    'userAccount.fb': 'Facebook',
    'userAccount.firstName': 'Имя',
    'userAccount.gender.<nil>': '',
    'userAccount.gender.мужик': 'Мужской',
    'userAccount.gender.female': 'Женский',
    'userAccount.gender.male': 'Мужской',
    'userAccount.gender.type': 'Пол',
    'userAccount.gender.unset': 'Не указан',
    'userAccount.generateAccessListCode': 'Создать код доступа',
    'userAccount.getTransactions': 'Показать {num} транзакций',
    'userAccount.goToLink': 'Перейти на {link}',
    'userAccount.history30d': 'История транзакций за последние 30 дней',
    'userAccount.homeTown': 'Место рождения',
    'userAccount.identityInfo': 'Телефон, электронная почта, MAC адрес - в зависимости от выбранного типа аутентификации',
    'userAccount.instagram': 'Instagram',
    'userAccount.isUserAddedToBlacklist': 'Добавлен ли пользователь в ЧС?',
    'userAccount.isUserAddedToBlacklistNo': 'Нет',
    'userAccount.isUserAddedToBlacklistYes': 'Да',
    'userAccount.macs': 'MAC',
    'userAccount.name': 'Имя',
    'userAccount.nameFromSocial': 'Имя из соц. сетей',
    'userAccount.new': 'Новый пользователь',
    'userAccount.next': 'след.',
    'userAccount.notificationUserAccountCreatedSuccessfully': 'Пользователь успешно создан',
    'userAccount.notificationUserAccountDeletedSuccessfully': 'Пользователь успешно удален',
    'userAccount.notificationUserAccountSuccess': 'Успешно',
    'userAccount.notificationUserAccountUpdatedSuccessfully': 'Пользователь успешно обновлен',
    'userAccount.noTransactions': 'Нет пополнений или списаний за последние 30 дней',
    'userAccount.notSubscribed': 'Не подписан',
    'userAccount.of': 'из',
    'userAccount.prev': 'пред.',
    'userAccount.profile': 'Профиль',
    'userAccount.purchase': 'Списание',
    'userAccount.rowsPerPage': 'Строк на странице',
    'userAccount.socialLink': 'Ссылка на соц. сеть',
    'userAccount.socialLinks': 'Соц. сети',
    'userAccount.subscribed': 'Подписан',
    'userAccount.subscribedFromDate': 'с',
    'userAccount.subscriptionName': 'Название подписки',
    'userAccount.subscriptionTableTitle': 'Подписка',
    'userAccount.surname': 'Фамилия',
    'userAccount.title': 'Пользователи',
    'userAccount.transactions': 'История транзакций',
    'userAccount.transactionsInfo': 'Список всех платежных транзакций пользователя (списание и пополнение)',
    'userAccount.type': 'Тип',
    'userAccount.universities': 'Образование',
    'userAccount.unset': 'не указан',
    'userAccount.unsetForFilter': 'Не указано',
    'userAccount.username': 'Имя пользователя',
    'userAccount.visits': 'Подключений',
    'userAccount.vk': 'Вконтакте',
    'users.addUser': 'Добавить пользователя',
    'users.addUserWithDemoData': 'Добавить пользователя с демо-данными',
    'users.bee_pro_bis_id': 'Business ID',
    'users.bee_pro_ctn': 'CTN',
    'users.blockedUsers': 'Заблокированные',
    'users.blockUserCaption': 'Заблокировать пользователя',
    'users.company': 'Компания',
    'users.copyFromLocation': 'скопировать из локации',
    'users.createdBy': 'Кем создан',
    'users.createdByForUserEditPanel': 'Создан пользователем',
    'users.creationDate': 'Когда создан',
    'users.deleteUserHeader': 'Удалить пользователя',
    'users.deleteUserCaption': 'Удалить пользователя',
    'users.description': 'Должность',
    'users.editUserCaption': 'Редактировать пользователя',
    'users.enterEmailPlaceHolder': 'Введите адрес электронной почты',
    'users.enterForPasswordChangePlaceHolder': 'Введите для изменения',
    'users.enterPasswordPlaceHolder': 'Введите пароль',
    'users.enterUsernamePlaceHolder': 'Введите имя пользователя',
    'users.firstLoginForUserEditPanel': 'Первый вход',
    'users.isBlocked': 'Заблокирован',
    'users.lastLogin': 'Последний вход',
    'users.lastLoginForUserEditPanel': 'Последний вход',
    'users.loginAsSubordinateForButton': 'Войти',
    'users.logoutAsSubordinateForButton': 'Продолжить как',
    'users.mac_mask': 'Маскировать MAC адреса для этого пользователя',
    'users.newUsernameInfoMsg': 'Это имя будет использоваться как логин при входе в личный кабинет',
    'users.newUserPasswordValidationMessage': 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ ! %',
    'users.noBlockedUsers': 'Незаблокированные',
    'users.noLogin': 'Вход не выполнялся',
    'users.notificationUserBlockedSuccessfully': 'Пользователь успешно заблокирован',
    'users.notificationUserCreatedSuccessfully': 'Пользователь успешно создан',
    'users.notificationUserDeletedSuccessfully': 'Пользователь успешно удален',
    'users.notificationUserUnblockedSuccessfully': 'Пользователь успешно разблокирован',
    'users.notificationUserUpdatedSuccessfully': 'Пользователь успешно обновлен',
    'users.password': 'Пароль',
    'users.phone': 'Телефон',
    'users.Role': 'Роль',
    'users.setActiveUntillForUser': 'Поставить ограничение на срок действия пользователя',
    'users.unblockUserCaption': 'Разблокировать пользователя',
    'users.updUsernameInfoMsg': 'Это имя используется как логин при входе в личный кабинет',
    'users.userHasCTNButHasNOtBisIDTooltip': 'У пользователя есть CTN но нет BusinessID (или он равен 0)',
    'users.username': 'Имя пользователя',
    'users.users': 'Пользователи',
    'users.usersList': 'Список пользователей',
    'users.wrongEmailMsg': 'Неправильная электронная почта',
    'usersDemoWizard.email': 'Электронная почта',
    'usersDemoWizard.headerCaption': 'Помощник создания пользователя с демо-данными',
    'usersDemoWizard.step1Caption': 'Создание локации',
    'usersDemoWizard.step1ErrorLocationWithThisNameAlredyExists': 'Локация с этим именем уже существует',
    'usersDemoWizard.step1Header': 'Шаг 1: Создание локации',
    'usersDemoWizard.step2Caption': 'Создание администратора для созданной локации',
    'usersDemoWizard.step2ErrorUserWithThisNameAlredyExists': 'Пользователь с этим именем уже существует',
    'usersDemoWizard.step2Header': 'Шаг 2: Создание администратора для локации',
    'usersDemoWizard.step3Caption': 'Создание Wi-Fi сети',
    'usersDemoWizard.step3Header': 'Шаг 3: Создание Wi-Fi сети',
    'usersDemoWizard.step3SuiteRequiredsError': 'Поле "Тип шифрования" обязательно для заполнения',
    'usersDemoWizard.step4Caption': 'Создание профиля',
    'usersDemoWizard.step4FieldProfileName': 'Имя профиля',
    'usersDemoWizard.step4Header': 'Шаг 4: Создание профиля',
    'usersDemoWizard.step5Caption': 'Создание страницы',
    'usersDemoWizard.step5FieldPageName': 'Имя страницы',
    'usersDemoWizard.step5Header': 'Шаг 5: Создание страницы',
    'vouchers.account': 'Счёт',
    'vouchers.add': 'Добавить ваучер',
    'vouchers.allAccounts': 'Все пользователи',
    'vouchers.backToVouchers': 'Назад к ваучерам',
    'vouchers.code': 'Код',
    'vouchers.codeLength': 'Длина кода',
    'vouchers.createHotelVouchers': 'Создать отельные ваучеры',
    'vouchers.createVouchers': 'Создать ваучеры',
    'vouchers.download': 'Скачать для печати',
    'vouchers.duration': 'Время действия',
    'vouchers.edit': 'Редактирование ваучера',
    'vouchers.editHotel': 'Редактирование отельного ваучера',
    'vouchers.expire_at': 'Время окончания',
    'vouchers.new': 'Новый ваучер',
    'vouchers.newHotelVouchers': 'Новые отельные ваучеры',
    'vouchers.newVouchers': 'Новые ваучеры',
    'vouchers.noAccountSelectedInModalErr': 'Поле аккаунт обязательно для заполнения',
    'vouchers.notificationHotelVoucherCreatedSuccessfully': 'Отельный ваучер успешно создан',
    'vouchers.notificationHotelVoucherDeletedSuccessfully': 'Отельный ваучер успешно удален',
    'vouchers.notificationHotelVoucherUpdatedSuccessfully': 'Отельный ваучер успешно обновлен',
    'vouchers.notificationVoucherCreatedSuccessfully': 'Ваучер успешно создан',
    'vouchers.notificationVoucherDeletedSuccessfully': 'Ваучер успешно удален',
    'vouchers.notificationVoucherUpdatedSuccessfully': 'Ваучер успешно обновлен',
    'vouchers.number_of_vouchers': 'Количество ваучеров',
    'vouchers.profile': 'Профиль',
    'vouchers.selectAccount': 'Выберите счет',
    'vouchers.session': 'Время доступа в интернет после активации',
    'vouchers.tariff': 'Тариф',
    'vouchers.title': 'Ваучеры',
    'vouchers.titleHotel': 'Отельные ваучеры',
    'vouchers.used': 'Использован',
    'vouchers.validFor': 'Действителен {day} дней',
    'vouchers.validForShort': 'Действителен',
    'wifiAnalyzer.channel': 'Канал',
    'wifiAnalyzer.quality': 'Качество',
    'wifiAnalyzer.signal': 'Сигнал',
    'wifiAnalyzer.wlanChars': 'Характеристики WLAN',
    'wlans.nasIp': 'NAS IP адрес',
    'wlans.nasIpPlaceholder': 'NAS IP адрес в формате X.X.X.X',
    'wlans.CentralAuth': 'Центральная аутентификация',
    'wlans.AAATabCaption': 'AAA',
    'wlans.AAAOverride': 'AAA Override',
    'wlans.DynamicVLAN': 'Dynamic VLAN',
    'wlans.DynamicVLANPlaceholder': 'Введите значения VLAN',
    'wlans.accessControl': 'Контроль доступа',
    'wlans.addRADIUS': 'Добавить RADIUS',
    'wlans.addRedirectRule': 'Добавить Redirect',
    'wlans.addTag': 'Добавить тег',
    'wlans.addWLAN': 'Добавить Wi-Fi сеть',
    'wlans.addWLANWizard': 'Помощник создания Wi-Fi сети',
    'wlans.advancedSettings': 'Настройка дополнительных параметров',
    'wlans.bandSteering': 'Включить band steering',
    'wlans.bandSteeringForBadge': 'Band steering',
    'wlans.bandSteeringForLastStepWlanWizard': 'Band steering',
    'wlans.basicParameters': 'Настройка базовых Wi-Fi параметров',
    'wlans.blackList': 'Черный список',
    'wlans.bridge': 'Для интерфейсов типа Bridge VLAN игнорируется',
    'wlans.bss_transitioForBadge': 'Быстрый переход BSS',
    'wlans.bss_transition': 'Быстрый переход BSS',
    'wlans.bss_transitionForBadge': 'Быстрый переход BSS',
    'wlans.centralized': 'Централизованный',
    'wlans.centralSwitching': 'Централизованная коммутация',
    'wlans.confirmDeleting': 'Подтвердить удаление',
    'wlans.confirmWlans': 'Подтвердите создание новой сети Wi-Fi',
    'wlans.currentBlacklistItems': 'Текущие элементы черного списка',
    'wlans.currentWhitelistItems': 'Текущие элементы белого списка',
    'wlans.dataTrafficManagement': 'Управление трафиком данных',
    'wlans.deleteMulti': 'Удалить выбранные Wi-Fi сети',
    'wlans.deleteTagsModalText': 'Подтвердите удаление всех тегов у выбранных сетей Wi-Fi',
    'wlans.deleteTagsModalTitile': 'Удаление тегов',
    'wlans.deleteWLAN': 'Удаление Wi-Fi сети',
    'wlans.deleteWLANs': 'Удаление Wi-Fi сетей',
    'wlans.descriptionPlaceholder': 'Введите описание',
    'wlans.dhcpOption82': 'DHCP Option 82 (без Relay)',
    'wlans.dhcpOption82CircuitId': 'Option 82 Circuit ID',
    'wlans.dhcpOption82CircuitIdExample': 'WLAN:wlan0:3690:SSID_Name:BC:E6:7C:4D:EE:00:XV3-8-4DDA3C:BC:E6:7C:4D:DA:3C:',
    'wlans.dhcpOption82CircuitIdExampleCaption': 'Пример',
    'wlans.dhcpOption82CircuitIdFormat': 'WLAN:IFNAME:VLAN:SSID:BSSID:HOSTNAME:AP-MAC:SITE-ID',
    'wlans.dhcpOption82CircuitIdFormatCaption': 'Формат ввода',
    'wlans.dhcpOption82Enable': 'Включить DHCP Option 82 (без Relay)',
    'wlans.dhcpOption82ForBadge': 'опция 82',
    'wlans.dhcpOption82InfoHint': 'формат “AP_MAC:SSID“',
    'wlans.disabledForBadge': 'выкл.',
    'wlans.download': 'Выгрузка',
    'wlans.editRADIUS': 'Редактировать RADIUS',
    'wlans.enabledForBadge': 'вкл.',
    'wlans.enterKbitPlaceholder': 'Введите значение в кбит',
    'wlans.enterNASIdentifierPlaceholder': 'Введите NAS идентификатор',
    'wlans.enterNASPortIdPlaceholder': 'Введите NAS порт идентификатор',
    'wlans.enterRSNAPlaceholder': 'Введите RSNA',
    'wlans.enterVLANPlaceHolder': 'Введите VLAN',
    'wlans.enterWlanPasswordPlaceholder': 'Введите пароль для Wi-Fi',
    'wlans.fastTransition': 'Быстрый роуминг (IEEE 802.11r)',
    'wlans.localPMKGeneration': 'Локальная генерация PMK',
    'wlans.localPMKGenerationOnlyForWP2PSK': 'Только для WPA2 Personal сетей',
    'wlans.experimentalFTImprovements': 'Экспериментальные улучшения роуминга',
    'wlans.RRBTunneling': 'Туннелирование RRB пакетов',
    'wlans.RRBSeqcheck': 'Проверка последовательности RRB',
    'wlans.directRRBExchange': 'Прямой обмен RRB между точками доступа',
    'wlans.directRRBExchangeHint': 'Позволяет ускорить переход клиентов между указанными ТД (при условии, что исходная ТД роуминга находится в этом списке)',
    'wlans.selectApsForDirectRRBExchange': 'Выберите ТД',
    'wlans.selectApsForDirectRRBExchangeHint': 'Можно выбрать не более 20 ТД',
    'wlans.filter': 'Фильтр',
    'wlans.filterBlacklistMode': 'Режим черного списка',
    'wlans.filterBlacklistModeCaption': 'Все, кроме выбранных клиентов, могут подключиться к Wi-Fi сети',
    'wlans.filterMode': 'Режим ограничения',
    'wlans.filterNoneModeCaption': 'Все клиенты могут подключаться к Wi-Fi сети',
    'wlans.filterWhitelistMode': 'Режим белого списка',
    'wlans.filterWhitelistModeCaption': 'Только выбранные клиенты могут подключаться к Wi-Fi сети',
    'wlans.firewall': 'Брандмауэр',
    'wlans.goBackToClients': 'Назад к клиентам',
    'wlans.goBackToWlanStatistic': 'Назад в статистику Wi-Fi сетей',
    'wlans.hidden': 'Скрытая',
    'wlans.hotspotProfileNone': 'Нет',
    'wlans.hotspotProfileTitle': 'Hotspot 2.0 профиль',
    'wlans.ieee80211k': '802.11k',
    'wlans.ieee80211kForBadge': '802.11k',
    'wlans.ieee80211v': '802.11v',
    'wlans.ieee80211vForBadge': '802.11v',
    'wlans.interface': 'Интерфейс',
    'wlans.itemsOf': 'из',
    'wlans.L2isolation': 'L2 изоляция',
    'wlans.L2TabCaption': 'Слой 2',
    'wlans.L3TabCaption': 'Слой 3',
    'wlans.load': 'Загрузить',
    'wlans.loadBalancing': 'Балансировка клиентов',
    'wlans.loadBalancingInfoMsg': 'Работает корректно только с 802.11v',
    'wlans.localSwitching': 'Локальная коммутация',
    'wlans.MACRADIUSAuthentication': 'MAC RADIUS Авторизация',
    'wlans.mirroring': 'Зеркалирование',
    'wlans.mirroringHint': 'Дублирование отправки сразу на несколько RADIUS серверов',
    'wlans.mobility': 'Настройки роуминга',
    'wlans.nas_generateCaption': 'Генерировать NAS ID',
    'wlans.nas_generateInfo': 'Позволяет более качественно обеспечивать работоспособность роуминга 802.11r',
    'wlans.NASidentifier': 'NAS идентификатор',
    'wlans.NASportid': 'NAS порт идентификатор',
    'wlans.natDisabledForTable': 'нет',
    'wlans.natEnabledForTable': 'есть',
    'wlans.natNetworkInfoMsg': 'Не используйте 10.9.0.1/16 для ТД с IPsec',
    'wlans.natNetworkIpaddr': 'IP для NAT сети',
    'wlans.natNetworkIpaddrPlaceholder': 'Введите IP для NAT сети',
    'wlans.natNetworkNetmask': 'Маска для NAT сети',
    'wlans.natNetworkNetmaskPlaceholder': 'Введите маску для NAT сети',
    'wlans.networkEncapsulation': 'Сетевая инкапсуляция',
    'wlans.noCurrentCPEs': 'Нет ТД',
    'wlans.noInterfaces': 'Нет интерфейсов',
    'wlans.none': 'Нет',
    'wlans.notAvailableForCisco': 'Недоступно для Cisco',
    'wlans.notForOpen': 'Не для открытой сети Wi-Fi',
    'wlans.notificationWLANCreatedSuccessfully': 'Wi-Fi сеть успешно создана',
    'wlans.notificationWLANDeletedSuccessfully': 'Wi-Fi сеть успешно удалена',
    'wlans.notificationWLANOperationSuccessfull': 'Успешно',
    'wlans.notificationWLANUpdatedSuccessfully': 'Wi-Fi сеть успешно обновлена',
    'wlans.onlyForWPAEnterprise': 'Только для WPA Enterprise',
    'wlans.optionalParameters': 'Настройка дополнительных параметров. Вы можете их настроить позднее.',
    'wlans.optionalStep': 'Вы можете настроить их позднее',
    'wlans.overTheDS': 'Over the DS',
    'wlans.peerAddress': 'EoGRE сервер',
    'wlans.peerAddressPlaceholder': 'Введите IP EoGRE сервера',
    'wlans.PMKcaching': 'Кэширование PMK',
    'wlans.ppsk': 'iPSK',
    'wlans.proxyARP': 'Proxy ARP',
    'wlans.proxyARPHint': 'Опция ARP Proxy позволяет точке доступа или контроллеру отвечать на ARP запросы от имени клиентов. Это снижает количество широковещательных сообщений в сети, экономя ресурсы и улучшая производительность Wi-Fi. Включите эту функцию для уменьшения нагрузки на беспроводных клиентов и увеличения пропускной способности сети',
    'wlans.proto': 'Тип',
    'wlans.qrForWLANConnet': 'QR код для подключения к сети Wi-FI',
    'wlans.qrSaveQRCode': 'Сохранить QR код',
    'wlans.radiusAccounting': 'RADIUS сервер учета',
    'wlans.radiusAccountingForWLANModalAAA': 'RADIUS сервер учета',
    'wlans.radiusAccountingInterval': 'RADIUS сервер учета интервал',
    'wlans.radiusAdditionalSettings': 'Дополнительные настройки RADIUS',
    'wlans.redirect': 'Перенаправление',
    'wlans.rrm_beacon_report': 'Отчет о beacon',
    'wlans.rrm_beacon_reportForBadge': 'Отчет о beacon',
    'wlans.rrm_neighbor_report': 'Отчет о соседях',
    'wlans.rrm_neighbor_reportForBadge': 'Отчет о соседях',
    'wlans.rsnaCapture': 'Если оставить пустым, будет заполнено в зависимости от настроек безопасности (OPEN / WPA / RSNA)',
    'wlans.rsnaSettings': 'Настройки RSNA',
    'wlans.rsnaSettingsV2': 'Изменить поле Calling Station ID',
    'wlans.rssiThreshold': 'RSSI порог',
    'wlans.rssiThresholdHint': 'Допустимый уровень сигнала клиента при подключении (от -90 до -60 дбм)',
    'wlans.rssiThresholdHintForInfo': 'Допустимый уровень сигнала клиента при подключении',
    'wlans.rssiThresholdHintRange': 'от -90 до -60 дбм, 0 для отключения',
    'wlans.rssiThresholdPlaceholder': 'от -90 до -60 дбм, 0 для отключения',
    'wlans.security': 'Безопасность',
    'wlans.securityOpen': 'Открытая',
    'wlans.securityParameters': 'Настройка параметров безопасности',
    'wlans.securitySettingsCaption': 'Настройки безопасности',
    'wlans.selected': 'выбрано',
    'wlans.selectInterface': 'Выберите интерфейс',
    'wlans.selectRADIUSPlaceholder': 'Выберите RADIUS',
    'wlans.selectSingleInterface': 'Выберите интерфейс',
    'wlans.selectSingleTunnel': 'Выберите туннель',
    'wlans.selectSingleWlan': 'Выберите сеть Wi-Fi',
    'wlans.signalBlockCaption': 'Настройки порога RSSI, при уменьшении которого пользователя отключает от WI-Fi сети с указанным кодом',
    'wlans.signalDropReason': 'Код причины',
    'wlans.signalDropReasonForBadge': 'Код причины',
    'wlans.signalDropReasonForInfo': 'Код причины, отправляемый клиенту',
    'wlans.signalDropReasonHint': '3 по умолчанию',
    'wlans.signalDropReasonMoreInfo': 'Подробнее о кодах',
    'wlans.signalDropReasonMoreInfoForModal': 'Подробнее о кодах можно прочитать тут',
    'wlans.signalDropReasonPlaceholder': 'Код причины, 3 по умолчанию',
    'wlans.signalPollTime': 'Время обновления',
    'wlans.signalPollTimeForBadge': 'Время обновления',
    'wlans.signalPollTimeForBadgeSec': 'сек',
    'wlans.signalPollTimeForInfo': 'Время обновления, 5 сек. по умолчанию',
    'wlans.signalPollTimeHint': '5 сек. по умолчанию',
    'wlans.signalPollTimePlaceholder': 'Время обновления, 5 сек. по умолчанию',
    'wlans.signalStay': 'Минимальный порог RSSI',
    'wlans.signalStayForBadge': 'Минимальный порог RSSI',
    'wlans.signalStayForInfo': 'Для подключенных клиентов при обнаружении уменьшения мощности сигнала меньше указанного, в течении заданного количества раз, происходит их отключение',
    'wlans.signalStayHint': 'от -100 до -40 дбм, 0 для отключения',
    'wlans.signalStayPlaceholder': 'от -100 до -40 дбм, 0 для отключения',
    'wlans.signalStrikes': 'Количество проверок перед отключением',
    'wlans.signalStrikesForBadge': 'Количество проверок',
    'wlans.signalStrikesForInfo': 'Количество проверок уровня мощности перед отключением клиента',
    'wlans.signalStrikesForLastWizardStep': 'Количество проверок',
    'wlans.signalStrikesHint': '3 по умолчанию',
    'wlans.signalStrikesPlaceholder': 'Количество проверок, 3 по умолчанию',
    'wlans.speedControl': 'Ограничение скорости',
    'wlans.speedDownload': 'Скорость выгрузки',
    'wlans.speedDownloadKbs': 'Кбит\\с',
    'wlans.speedDownloadPlaceholder': 'Введите значение от 8 до 10000000',
    'wlans.speedUpload': 'Скорость загрузки',
    'wlans.speedUploadKbs': 'Кбит\\с',
    'wlans.speedUploadPlaceholder': 'Введите значение от 8 до 10000000',
    'wlans.SSHWebAccess': 'SSH / Web доступ',
    'wlans.ssidPlaceholder': 'Введите SSID',
    'wlans.status': 'Статус',
    'wlans.statusActive': 'активна',
    'wlans.statusEmpty': 'пусто',
    'wlans.statusNotActive': 'не активна',
    'wlans.statusPending': 'ожидание',
    'wlans.suites': 'Тип шифрования',
    'wlans.suitesPlaceholder': 'Тип шифрования',
    'wlans.tabAdvanced': 'Дополнительно',
    'wlans.tabGeneral': 'Основное',
    'wlans.tableNone': 'нет',
    'wlans.tableTab_clients': 'Клиенты',
    'wlans.tableTab_cpes': 'Точки доступа',
    'wlans.tableTab_dataTrafficManagement': 'Управление трафиком данных',
    'wlans.tableTab_encapsulation': 'Инкапсуляция',
    'wlans.tableTab_filter': 'Фильтр',
    'wlans.tableTab_hasPortal': 'Портал',
    'wlans.tableTab_location': 'Локация',
    'wlans.tableTab_name': 'Имя',
    'wlans.tableTab_security': 'Безопасность',
    'wlans.tableTab_ssid': 'SSID',
    'wlans.tableTab_status': 'Статус',
    'wlans.tableTab_tags': 'Теги',
    'wlans.tableTab_visibility': 'Видимость',
    'wlans.tableTab_vlan': 'VLAN',
    'wlans.tableTab_wlans': 'Сети Wi-Fi',
    'wlans.tabSecurity': 'Безопасность',
    'wlans.tunnel': 'Тунель',
    'wlans.tunneling': 'Туннелирование до сервера',
    'wlans.tunnelingShort': 'Туннелирование',
    'wlans.tunnelTypeForTable': 'Туннель',
    'wlans.UAPSD': 'UAPSD',
    'wlans.unlim': 'без огранич.',
    'wlans.upload': 'Загрузка',
    'wlans.view': '',
    'wlans.visibility': 'Видимость',
    'wlans.visibilityHidden': 'Скрытая',
    'wlans.visibilityVisible': 'Видимая',
    'wlans.visible': 'Видимая',
    'wlans.vlanNative': 'Без тега',
    'wlans.warnService': 'Пожалуйста, обратите внимание, что выбрана активна Wi-Fi сеть. Её удаление приведёт к {0}.',
    'wlans.warnServiceLoss': 'потере сервиса',
    'wlans.warnStatistics': 'Пожалуйста, обратите внимание, что удаление Wi-Fi сети приведёт к {0}.',
    'wlans.warnStatisticsLoss': 'потере статистики',
    'wlans.whiteList': 'Белый список',
    'wlans.wizardAdditionalSettings': 'Дополнительные параметры',
    'wlans.wizardBlackWhiteListItem': 'зап.',
    'wlans.wizardGuestControl': 'Контроль доступа',
    'wlans.wizardGuestControlStepCaption': 'Настройка параметров контроля доступа',
    'wlans.wizardMobility': 'Настройки роуминга',
    'wlans.wizardMobilityStepCaption': 'Настройки параметров роуминга',
    'wlans.wizardNetworkEncapsulationStepCaption': 'Настройка параметров сетевой инкапсуляции',
    'wlans.wizardRADIUSAccounting': 'RADIUS сервер учета',
    'wlans.wizardRADIUSAccountingStepCaption': 'Настройка параметров RADIUS сервера учета',
    'wlans.wizardRADIUSAdditionalSettings': 'Дополнительные параметры RADIUS',
    'wlans.wizardRADIUSAdditionalSettingsStepCaption': 'Настройка дополнительных параметров RADIUS',
    'wlans.wizardSecuritySettings': 'Параметры безопасности',
    'wlans.wizardSkipButton': 'Пропустить следующие шаги',
    'wlans.wizardSkipButtonInfo': 'Пропустить все последующие шаги и перейти к завершающему шагу. Настройки заданные на текущем шаге будут применены.',
    'wlans.wizardSpeedControlSettings': 'Ограничение скорости',
    'wlans.wizardSpeedControlSettingsStepCaption': 'Настройка параметров ограничения скорости',
    'wlans.wizardVisibility': 'Видимость',
    'wlans.wizardWMM': 'WMM',
    'wlans.wizardWMMStepCaption': 'Настройки параметров WMM',
    'wlans.wlanCreate': 'Создание Wi-Fi сети',
    'wlans.wlanEdit': 'Редактирование Wi-Fi сети',
    'wlans.wlans': 'Сети Wi-Fi',
    'wlans.wlansList': 'Список Wi-Fi сетей',
    'wlans.WLANsOufOf': 'Wi-Fi сетей из',
    'wlans.wlanWizard': 'Помощник создания Wi-Fi сети',
    'wlans.wlanWizardV2': 'Создание Wi-Fi сети',
    'wlans.WMM': 'WMM',
    'wlans.wnm_sleep_mode': 'Возможность перехода в спящий режим',
    'wlans.wnm_sleep_modeForBadge': 'Возможность спящего режима',
    'wlans.wnm_sleep_modeForWizardLastStep': 'Спящ. режим',
    'wlans.wnm_sleep_modeInfo': 'Включает возможность перехода в спящий режим для энергосбережения',
    'wlans.wpa3IsNotSupportedOnAllCPE': 'WPA3 поддерживается не на всех ТД',
    'wmm.Background': 'Background (BK)',
    'wmm.BestEffort': 'BestEffort  (BE) ',
    'wmm.configPlaceHolder': 'Введите, чтобы изменить значение по умолчанию',
    'wmm.custom': 'включены',
    'wmm.default': 'по умолчанию',
    'wmm.infoTooltip': 'нажмите, чтобы просмотреть подробности о настройках WMM',
    'wmm.modalInfomoreInfo': 'Больше информации',
    'wmm.modalInfoTitle': 'О настройках WMM',
    'wmm.Video': 'Видео (VI)',
    'wmm.Voice': 'Голос (VO)',
    'wmm.error.AcCwFiled': 'Должно быть введено одно из следующих значений: 1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767',
    'wmm.hint.cli_cw_min': 'От 0 до 15. Значение должно быть не больше чем в поле Client cwMax. Обязательно для заполнения если заполено Client cwMax',
    'wmm.hint.cli_cw_max': 'От 0 до 15. Значение должно быть не меньше чем в поле Client cwMin. Обязательно для заполнения если заполено Client cwMin',
    'wmm.hint.cli_aifs': 'Значение должно быть больше или равно 0',
    'wmm.hint.cli_txop': 'Значение должно быть больше или равно 0',
    'wmm.hint.ac_cw_min': '1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767. Значение должно быть не больше чем в поле AC cwMax. Обязательно для заполнения если заполнено AC cwMax',
    'wmm.hint.ac_cw_max': '1, 3, 7, 15, 31, 63, 127, 255, 511, 1023, 2047, 4095, 8191, 16383, 32767. Значение должно быть не меьше чем в поле AC cwMin. Обязательно для заполнения если заполнено AC cwMin',
    'wmm.hint.ac_aifs': 'Значение должно быть больше или равно 0',
    'wmm.hint.ac_burst': 'Значение должно быть больше или равно 0, с одним разрядом после запятой',
    'vlan.errors.dynamicVLANCommonValidationError': 'Неверный формат ввода. Допустимый формат - числа от {min} до {max}. Возможно ввести несколько значений, разделенных  запятыми без повторов (например 2,6,3,10). Или диапазон значений с помощью тире (например 2-8)',
    'vlan.errors.dynamicVLANNumberNotInRangeValidationError': 'Допустимый формат - числа от {min} до {max}',
    'vlan.errors.dynamicVLANNumberRepeatedValidationError': 'Не допускаются повторы чисел',
    'vlan.errors.dynamicVLANFirstNumberInRangeGreaterThenLastValidationError': 'Первое число в диапазоне должно быть меньше последнего',
    'validator.message.wrongIpv4Format': 'Неверный формат IPv4 адреса',
    'validation.wlanNasIp': 'Неверный формат IPv4 адреса',
    'validation.ipv4WithSpecialOctetsRanges': 'Неверный формат IPv4 адреса',
    'validation.subnetMask': 'Неверная маска подсети. Введите маску подсети в формате X.X.X.X',
    'validation.macPrefix': 'Неверный MAC префикс. Введите MAC префикс в формате XX:XX:XX:XX:XX:XX',
    'validation.ValidCharacters': 'Допустимы следующие символы: латинские буквы в верхнем и нижнем регистрах, цифры, специальные символы: _ - # $ & ^ * @ !',
    'validation.usernameValidCharacters': 'Допустимы следующие символы: латинские буквы в верхнем и нижнем регистрах, цифры, специальные символы: _ - .',
    'validation.uuid': 'UUID не соответствует требованиям',
    'validation.location': 'Имя локации должно начинаться с буквы или числа и может содержать только буквы латинского алфавита и следующие специальные символы: _ -',
    'validation.wrongmacaddr': 'Неверный MAC адрес',
    'validation.ipv4ordomain': 'Неверный IP адрес или доменное имя',
    'validation.hexcolor': 'Неправильный HEX цвета',
    'validation.ipv4mask': 'Неверный IP адрес',
    'validation.wrongurl': 'Неправильный URL-адрес',
    'validation.wrongl2port': 'Неправильный адрес порта',
    'validation.ssidsize': 'Имя SSID не может быть длиннее 32 байт.',
    'validation.hexhs': 'Неправильный OI. Каждый OI имеет длину от 3 до 15 октетов и сконфигурирован как HEX строка',
    'validation.customUserPassword': 'Пароль может содержать только следующие специальные символы: _ - # $ & ^ * @ !',
    'validation.customCheckLength': 'Длина пароля должна быть от 8 до 64 символов',
    'validation.customCheckLengthMin': 'Длина пароля должна быть не менее 8 символов',
    'validation.customCheckLengthMinWLC': 'Длина пароля должна быть не менее 6 символов',
    'validation.customCheckLengthMax': 'Длина пароля должна быть не более 64 символов',
    'validation.upperCaseLetter': 'Должна быть хотя бы одна латинская буква в верхнем регистре',
    'validation.lowerCaseLetter': 'Должна быть хотя бы одна латинская буква в нижнем регистре',
    'validation.digitContained': 'Должна быть хотя бы одна цифра',
    'validation.wrongMaskNAT': 'Неверная маска для NAT сети',
    'validation.ctn': 'CTN должно быть номером телефона (10 цифр в формате 9ХХХХХХХХХ)',
    'validation.signalRSSI': 'Значение Минимального порога RSSI должно быть в диапазоне от -100 до -40 dbm (или 0 для отключения)',
    'validation.signalStrikes': 'Значение Количества проверок должно быть больше 0',
    'validation.signalPoll': 'Значение Времени обновления должно быть больше 0',
    'validation.signalReason': 'Код причины должен быть больше или равен 0',
    'validation.signalThreshold': 'Значение RSSI threshold должно быть в диапазоне от -90 до -60 dbm (или 0 для отключения)',
    'validation.timehhmm': 'Неверный формат времени. Введите время в формате ЧЧ:ММ',
    'validation.wrongFTP': 'Неверный Сервер FTP. Укажите адрес FTP сервера в формате ip:port (например 127.0.0.1:21)',
    'validation.multiInterface': 'Поле Интерфейс обязательно для заполнения',
    'validation.wrongIPorDomain': 'Неверный IP адрес или домен',
    'validation.wrongIPorMAC': 'Неверный IP или MAC',
    'validation.ipOrMacList': 'Неверный IP или MAC. Или введено более 1 строки',
    'validation.ipOrMacFilter': 'Введено более 3 фильтров в строке'
  }
};

export default LOCALES_FILE;
