<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <VlanFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице vlan.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  VlanFormTabContent
} from './components';

import {
  getVlanValidators
} from './validators';

/* eslint-disable camelcase */

export default {
  name: 'VlanForm',
  components: {
    VlanFormTabContent
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setVlanValidators() {
      const validators = getVlanValidators(this.$i18n);

      const uniqueVlanId = validators.uniqueVlanId(this.forbiddenVlanIds);
      const vlanNameMask = validators.vlanNameMask();
      const uniqueVlanName = validators.uniqueVlanName(this.forbiddenVlanNames);
      const isGreaterThan = validators.isGreaterThan();
      const isLessThan = validators.isLessThan();

      const validator = this.$validator;

      validator.extend('uniqueVlanId', uniqueVlanId);
      validator.extend('vlanNameMask', vlanNameMask);
      validator.extend('uniqueVlanName', uniqueVlanName);
      validator.extend('isGreaterThan', isGreaterThan, {
        hasTarget: true
      });
      validator.extend('isLessThan', isLessThan, {
        hasTarget: true
      });
    }
  },
  created() {
    this.setVlanValidators();
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
