<template>
  <VlanFormModal
    :heading="$t('vlan.changeVlan')"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:form>
      <ChangeVlanForm
        :vlanForChanging="vlanForChanging"
        :forbiddenVlanIds="forbiddenVlanIds"
        :forbiddenVlanNames="forbiddenVlanNames"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.update') }}
            </WButton>
          </div>
        </template>
      </ChangeVlanForm>
    </template>
  </VlanFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для редактирования vlan.
 * ! Компонент локальный, используется только в VlanPage.vue
 * @component
 */

import {
  VlanFormModal
} from '../features';

import {
  ChangeVlanForm
} from './components';

export default {
  name: 'ChangeVlanModal',
  components: {
    ChangeVlanForm,
    VlanFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    vlanForChanging: {
      type: Object,
      required: true
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
