<template>
  <div class="svi-page">
    <SVITable
      :items="SVIs"
      :totalItems="totalSVIsCount"
      :perPage="limit"
      :selectedSVIIds="selectedSVIIds"
      :activeRowSVIId="''"
      :sortState="sortParams"
      :isDataLoading="false"
      :isDisabled="isLoading"
      :isOneColMode="false"
      :isSelectionRowsModeEnabled="true"
      @on-cell-click="handleCellClick"
      @on-selected-rows-change="handleSelectRow"
      @on-sort-change="handleSortChange"
      @on-page-change="handlePageChange"
      @on-per-page-change="handlePerPageChange"
    >
      <template v-slot:table-menu>
        <div class="control-panel">
          <div class="control-panel__container left">
            <WButton info :disabled="isLoading" @click="openCreateSVIModal">
              {{ $t('general.add') }}
            </WButton>
            <WButton info :disabled="isLoading || !selectedSVIIds.length" @click="openDeleteSVIModal">
              {{ $t('general.delete') }}
            </WButton>
          </div>
        </div>
      </template>
    </SVITable>
    <CreateSVIModal
      :forbiddenSVINames="forbiddenSVINames"
      :forbiddenVlanIds="forbiddenVlanIds"
      :aclValues="aclValues"
      :isOpen="isCreateSVIModalOpen"
      @submit="createSVI"
      @close="closeCreateSVIModal"
    />
    <ChangeSVIModal
      :SVIForChanging="selectedSVIForChanging"
      :forbiddenVlanIds="forbiddenVlanIds"
      :forbiddenSVINames="forbiddenSVINames"
      :aclValues="aclValues"
      :isOpen="isChangeSVIModalOpen"
      @submit="changeSVI"
      @close="closeChangeSVIModal"
    />
    <DeleteSVIModal
      :SVIIdsToDelete="selectedSVIIds"
      :isOpen="isDeleteSVIModalOpen"
      @close="closeDeleteSVIModal"
      @confirm="handleDeleteSVIs"
    />
  </div>
</template>

<script>
/**
 * компонент страницы svi
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%B2%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B5-SVI
 * @component
 */
/* eslint-disable camelcase */
import { SVITable, CreateSVIModal, ChangeSVIModal, DeleteSVIModal } from './components';
import SVIApi from './api';

export default {
  name: 'SVIPage',
  components: {
    SVITable,
    CreateSVIModal,
    ChangeSVIModal,
    DeleteSVIModal
  },
  props: {},
  data() {
    return {
      SVIs: [],
      aclValues: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedSVIIds: [],
      selectedSVIForChanging: {},
      totalSVIsCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateSVIModalOpen: false,
      isChangeSVIModalOpen: false,
      isDeleteSVIModalOpen: false
    };
  },
  computed: {
    forbiddenSVINames() {
      const SVINames = this.SVIs.map((SVI) => SVI.name);
      return SVINames;
    },
    forbiddenVlanIds() {
      const SVIIds = this.SVIs.map((SVI) => SVI.vlan_id);
      return SVIIds;
    }
  },
  methods: {
    handleCellClick(rowData) {
      const {
        nativeData: { id }
      } = rowData;

      const [selectedSVIForChanging] = this.SVIs.filter((SVI) => SVI.id === id);

      this.selectedSVIForChanging = selectedSVIForChanging;

      this.openChangeSVIModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedSVIIds = selectedRowData.map((rowData) => rowData.nativeData.vlan_id);

      this.selectedSVIIds = selectedSVIIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };
      this.getSVIs();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalSVIsCount) {
        this.offset = 0;
      }
      this.getSVIs();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalSVIsCount) {
        this.offset = 0;
      }
      this.getSVIs();
    },
    handleDeleteSVIs() {
      const idsToDelete = this.SVIs.filter((SVI) => this.selectedSVIIds.includes(SVI.vlan_id)).map((SVI) => SVI.id);

      SVIApi.deleteSVIs(
        {
          ids: idsToDelete
        },
        {
          onLoading: () => {
            this.isLoading = true;
          },
          onSuccess: (data) => {
            this.isLoading = false;
            this.closeDeleteSVIModal();
            this.getSVIs();
          },
          onError: (error) => {
            this.isLoading = false;
          },
          successMessage: this.$t('svi.responseMessage.deleteSVIs.success'),
          errorMessage: this.$t('svi.responseMessage.deleteSVIs.error')
        }
      );
    },
    changeSVI(formValues) {
      const { id } = this.selectedSVIForChanging;
      SVIApi.updateSVI(
        {
          id,
          name: 'Vlan' + formValues['general.vlan_id'],
          vlan_id: formValues['general.vlan_id'],
          admin_status: formValues['general.admin_status'],
          description: formValues['general.description'],
          ip: formValues['general.ip'],
          subnet_mask: formValues['general.subnet_mask'],
          acl_ingress: formValues['general.acl_ingress'],
          acl_egress: formValues['general.acl_egress'],
          mdns_enabled: formValues['general.mdns_enabled'],
          mtu: formValues['general.mtu']
        },
        {
          onLoading: () => {
            this.isLoading = true;
          },
          onSuccess: (data) => {
            this.isLoading = false;
            this.closeChangeSVIModal();
            this.getSVIs();
          },
          onError: () => {
            this.isLoading = false;
          },
          successMessage: this.$t('svi.responseMessage.updateSVI.success'),
          errorMessage: this.$t('svi.responseMessage.updateSVI.error')
        }
      );
    },
    createSVI(formValues) {
      SVIApi.createSVI(
        {
          name: 'Vlan' + formValues['general.vlan_id'],
          vlan_id: formValues['general.vlan_id'],
          admin_status: formValues['general.admin_status'],
          description: formValues['general.description'],
          ip: formValues['general.ip'],
          subnet_mask: formValues['general.subnet_mask'],
          acl_ingress: formValues['general.acl_ingress'],
          acl_egress: formValues['general.acl_egress'],
          mdns_enabled: formValues['general.mdns_enabled'],
          mtu: formValues['general.mtu']
        },
        {
          onLoading: () => {
            this.isLoading = true;
          },
          onSuccess: (data) => {
            this.isLoading = false;
            this.closeCreateSVIModal();
            this.getSVIs();
          },
          onError: () => {
            this.isLoading = false;
          },
          successMessage: this.$t('svi.responseMessage.createSVI.success'),
          errorMessage: this.$t('svi.responseMessage.createSVI.error')
        }
      );
    },
    getSVIs() {
      SVIApi.getSVIs(
        {
          limit: this.limit,
          offset: this.offset,
          // // если надо будет реализовать фильтрацию, то использовать filterByName, filterById
          // filterByName: undefined,
          // filterById: undefined,
          sortOrderBy: this.sortParams.type,
          sortValueBy: this.sortParams.field
        },
        {
          onLoading: () => {
            this.isLoading = true;
          },
          onSuccess: (data) => {
            this.isLoading = false;

            const { total, limit, offset, svis } = data;

            this.SVIs = svis;
            this.totalSVIsCount = total;
            this.offset = offset;
            this.limit = limit;

            this.isLoading = false;

            this.selectedSVIIds = [];
          },
          onError: (error) => {
            this.isLoading = false;
          }
        }
      );
    },
    getAclValues() {
      SVIApi.getAclValues({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (aclValues) => {
          this.isLoading = false;
          this.aclValues = aclValues.map((aclValue) => aclValue.name);
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateSVIModal() {
      this.isCreateSVIModalOpen = true;
    },
    closeCreateSVIModal() {
      this.isCreateSVIModalOpen = false;
    },
    openChangeSVIModal() {
      this.isChangeSVIModalOpen = true;
    },
    closeChangeSVIModal() {
      this.isChangeSVIModalOpen = false;
      this.selectedSVIForChanging = {};
    },
    openDeleteSVIModal() {
      this.isDeleteSVIModalOpen = true;
    },
    closeDeleteSVIModal() {
      this.isDeleteSVIModalOpen = false;
    }
  },
  mounted() {
    this.getSVIs();
    this.getAclValues();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
