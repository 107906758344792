<template>
  <WlanPolicyFormModal
    :heading="$t('wlanPolicy.cloneWlanPolicyProfile', { name: wlanPolicyForCloning.name })"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:form>
      <CloneWlanPolicyForm
        :wlanPolicyForCloning="wlanPolicyForCloning"
        :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
        :aclOptions="aclOptions"
        :vlanOptions="vlanOptions"
        :vlanGroupOptions="vlanGroupOptions"
        :policyMapOptions="policyMapOptions"
        :eogreProfileOptions="eogreProfileOptions"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.add') }}
            </WButton>
          </div>
        </template>
      </CloneWlanPolicyForm>
    </template>
    </WlanPolicyFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для клонирования существующего wlan policy.
 * ! Компонент локальный, используется только в WlanPolicyPage.vue
 * @component
 */

import {
  WlanPolicyFormModal
} from '../features';

import {
  CloneWlanPolicyForm
} from './components';

export default {
  name: 'CloneWlanPolicyModal',
  components: {
    CloneWlanPolicyForm,
    WlanPolicyFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    wlanPolicyForCloning: {
      type: Object,
      required: true
    },
    forbiddenWlanPolicyNames: {
      type: Array,
      default: () => []
    },
    aclOptions: {
      type: Array,
      default: () => []
    },
    vlanOptions: {
      type: Array,
      default: () => []
    },
    vlanGroupOptions: {
      type: Array,
      default: () => []
    },
    policyMapOptions: {
      type: Array,
      default: () => []
    },
    eogreProfileOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  },
  created() {
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
