/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import { AXIOS_INSTANCE, GET_VLAN_GROUP_BY_NAME_URL } from '../config';
import {
  vlanGroupDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').VlanGroup_FRONTEND} VlanGroup_FRONTEND
 * @typedef {import('../types.js').VlanGroup_BACKEND} VlanGroup_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/514687020/FT23.10.22+VLAN+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {VlanGroup_BACKEND} vlan_group - искомый объект vlan group.
 */

/**
 * Функция-фетчер для получения vlan group по его name.
 *
 * @param {string} name - name искомого vlan group.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getVlanGroupByNameFetcher = async (name) => {
  const url = `${GET_VLAN_GROUP_BY_NAME_URL}${name}`;

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {VlanGroup_FRONTEND} vlan_group - искомый объект vlan group.
 */

/**
 * получает vlan group по name.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {string} name - name искомого vlan group.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getVlanGroupByName = async (id, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await getVlanGroupByNameFetcher(id);
    const {
      vlan_group
    } = response;

    const data = vlanGroupDataTypesConverterForFrontend(vlan_group);

    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
};

export default getVlanGroupByName;
