/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').SVI_FRONTEND} SVI_FRONTEND
 * @typedef {import('./types.js').SVI_BACKEND} SVI_BACKEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter((param) => param.value !== undefined && param.value !== null)
    .map((param) => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов vlan с бэковых типов (Vlan_BACKEND) в фронтовые (Vlan_FRONTEND).
 * Использовать, когда получаем vlan с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {Vlan_BACKEND} vlan - Vlan_BACKEND данные для конвертации типов.
 * @returns {Vlan_FRONTEND} - сконвертированные данные с типизацией Vlan_FRONTEND.
 */

function vlanDataTypesConverterForFrontend(vlan) {
  const {
    id,
    vlan_id,
    name,
    description,
    admin_status,
    created_at,
    updated_at
  } = vlan;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    vlan_id: String(vlan_id),
    name: String(name),
    description: String(description),
    admin_status: String(admin_status) === 'True'
  };

  return convertedTypesToFrontendFormat;
}


/**
 * Конвертер типов SVI с бэковых типов (SVI_BACKEND) в фронтовые (SVI_FRONTEND).
 * Использовать, когда получаем SVI с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {SVI_BACKEND} SVI - SVI_BACKEND данные для конвертации типов.
 * @returns {SVI_FRONTEND} - сконвертированные данные с типизацией SVI_FRONTEND.
 */

function SVITypesConverterForFrontend(SVI) {
  const {
    id,
    vlan_id,
    id_vlan,
    name,
    description,
    admin_status,
    oper_status,
    ip,
    subnet_mask,
    acl_ingress,
    acl_egress,
    mdns_enabled,
    mtu,
    created_at,
    updated_at
  } = SVI;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    vlan_id: String(vlan_id),
    name: String(name),
    description: String(description),
    admin_status: String(admin_status) === 'True',
    oper_status: String(oper_status) === 'True',
    ip: String(ip),
    subnet_mask: String(subnet_mask),
    acl_ingress: String(acl_ingress),
    acl_egress: String(acl_egress),
    mdns_enabled: String(mdns_enabled) === 'True',
    mtu: String(mtu)
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов svi с фронтовых типов (SVI_FRONTEND) в бэковые (SVI_BACKEND).
 * Использовать, когда возвращаем svi с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {SVI_BACKEND} SVI - SVI_BACKEND данные для конвертации типов.
 * @returns {SVI_FRONTEND} - сконвертированные данные с типизацией SVI_FRONTEND.
 */

function SVITypesConverterForBackend(svi) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    vlan_id,
    id_vlan,
    name,
    description,
    admin_status,
    oper_status,
    ip,
    subnet_mask,
    acl_ingress,
    acl_egress,
    mdns_enabled,
    mtu
  } = svi;

  const convertedTypesToBackendFormat = {
    vlan_id: String(vlan_id),
    name: String(name),
    description: String(description),
    admin_status: admin_status ? 'True' : 'False',
    oper_status: oper_status ? 'True' : 'False',
    ip: String(ip),
    subnet_mask: String(subnet_mask),
    acl_ingress: String(acl_ingress),
    acl_egress: String(acl_egress),
    mdns_enabled: mdns_enabled ? 'True' : 'False',
    mtu: String(mtu)
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  return convertedTypesToBackendFormat;
}

export { 
  getFetcherUrl,
  vlanDataTypesConverterForFrontend,
  SVITypesConverterForFrontend,
  SVITypesConverterForBackend 
};
