<template>
  <div class="vlan-tab">
    <!-- контент вкладок и/или секций -->
    <div class="vlan-tab-content">
      <!-- general tab -->
      <CreationTypeSection
        :config="creationTypeSectionContent"
        :formState="formState"
        @update:inputValue="handleFormSectionInputValue"
      />
      <SingleCreationModeSection
        v-if="currentCreationMode === 'single'"
        :config="singleModeSectionContent"
        :formState="formState"
        @update:inputValue="handleFormSectionInputValue"
      />
      <MultipleCreationModeSection
        v-if="currentCreationMode === 'multiple'"
        :config="multipleModeSectionContent"
        :formState="formState"
        @update:inputValue="handleFormSectionInputValue"
      />
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент управления содержимым табов формы VlanForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

import {
  CreationTypeSection,
  SingleCreationModeSection,
  MultipleCreationModeSection
} from './tabs/general';

export default {
  name: 'VlanFormTabContent',
  inject: ['$validator'],
  components: {
    CreationTypeSection,
    SingleCreationModeSection,
    MultipleCreationModeSection
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    currentTabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    creationTypeSectionContent() {
      return this.tabContent.filter(content => content.name === 'general.creationType')[0];
    },
    singleModeSectionContent() {
      return this.tabContent.filter(content => content.name === 'general.singleMode')[0];
    },
    multipleModeSectionContent() {
      return this.tabContent.filter(content => content.name === 'general.multipleMode')[0];
    },
    currentCreationMode() {
      return this.formState['general.creationMode'];
    }
  },
  methods: {
    handleFormSectionInputValue({ inputName, value }) {
      this.$emit('update:inputValue', { inputName, value });
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.vlan-tab {
  height: 100%;
  max-height: 500px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.vlan-tab::-webkit-scrollbar {
  width: 5px;
}

.vlan-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.vlan-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.vlan-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.vlan-tab-description {
  flex: 0 0 40%;
  display: flex;
}

.vlan-tab-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}
</style>
<style lang="css">
.vlan-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

.vlan-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vlan-tab-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wlan-policy-form-close-icon {
  color: var(--brand-danger);
  cursor: pointer;
}
</style>
