// selected checkbox of a row or not
function getSelectedRowValue(currentVlanGroupId, selectedVlanGroupIdsArray) {
  let value = false;

  selectedVlanGroupIdsArray.forEach(vlanGroupId => {
    if (vlanGroupId === currentVlanGroupId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
