<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <WlanPolicyFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице wlan-policy page.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  WlanPolicyFormTabContent
} from './components';

import {
  getWlanPolicyFormValidators
} from './validators';

/* eslint-disable camelcase */

export default {
  name: 'WlanPolicyForm',
  components: {
    WlanPolicyFormTabContent
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenWlanPolicyNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setWlanPolicyFormValidators() {
      const validators = getWlanPolicyFormValidators(this.$i18n);

      // todo написать валидаторы и подключить

      const uniqueWlanPolicyName = validators.uniqueWlanPolicyName(this.forbiddenWlanPolicyNames);
      const wlanPolicyNameMask = validators.wlanPolicyNameMask();
      const wlanPolicyDescriptionMask = validators.wlanPolicyDescriptionMask();
      const vlanConfigurationRequired = validators.vlanConfigurationRequired();
      const nasMask = validators.nasMask();

      const validator = this.$validator;

      validator.extend('uniqueWlanPolicyName', uniqueWlanPolicyName);
      validator.extend('wlanPolicyNameMask', wlanPolicyNameMask);
      validator.extend('wlanPolicyDescriptionMask', wlanPolicyDescriptionMask);
      validator.extend('vlanConfigurationRequired', vlanConfigurationRequired);
      validator.extend('nasMask', nasMask);
    }
  },
  created() {
    this.setWlanPolicyFormValidators();
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
