<template>
  <div class="wlan-policy-tab-input-group access-policies">
    <h3 v-if="config.label"
      class="wlan-policy-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <template v-for="input in config.inputs"
      class="wlan-policy-tab-input-group"
    >
      <template v-if="input.name === 'accessPolicies.acl'">
        <WSelect
          withInitialOption
          :options="input.selectOptions"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          :placeholder="input.placeholder"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'accessPolicies.id_vlan'">
        <WSelect
          v-if="currentCentral_switch_enabled"
          data-vv-scope="id_vlan"
          :options="input.selectOptions"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          :placeholder="input.placeholder"
          @input="handleSelect(input.name, $event)"
          :disabled="isVlanDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        >
        <template #icon>
          <i class="fa fa-close wlan-policy-form-close-icon"
            v-show="currentVlan !== '0'"
            @click="handleResetVlan"
          />
        </template>
      </WSelect>
      </template>
      <template v-else-if="input.name === 'accessPolicies.id_vlan_group'">
        <WSelect
          v-if="currentCentral_switch_enabled"
          data-vv-scope="id_vlan_group"
          :options="input.selectOptions"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          :placeholder="input.placeholder"
          @input="handleSelect(input.name, $event)"
          :disabled="isVlanGroupDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        >
          <template #icon>
            <i class="fa fa-close wlan-policy-form-close-icon"
              v-show="currentVlan_group !== '0'"
              @click="handleResetVlanGroup"
            />
          </template>
      </WSelect>
      </template>
      <template v-else>
        <WInput
          data-vv-delay="500"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </template>
    <div v-if="!currentCentral_switch_enabled"
      class="access-policies-section-description"
    >
      <div class="access-policies-section-description__content">
        <p class="warning-caption alert alert-warning">
          {{ $t('wlanPolicy.inputLabel.vlan.warningCaption') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба accessPolicies (основной и единственный таб - accessPolicies).
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 *
 * ft:
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398188/FT23.10.1+front#%D0%92%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0-%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B8-%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B0
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'AccessPoliciesGeneralSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentCentral_switch_enabled() {
      return this.formState['general.central_switch_enabled'];
    },
    currentVlan() {
      return this.formState['accessPolicies.id_vlan'];
    },
    currentVlan_group() {
      return this.formState['accessPolicies.id_vlan_group'];
    },
    // Если пользователь выбрал VLAN, то выбор VLAN группы должен быть недоступен.
    // Если пользователь выбрал VLAN группы, то выбор VLAN должен быть недоступен.
    isVlanDisabled() {
      const condition = this.currentVlan_group !== '0';
      return condition;
    },
    isVlanGroupDisabled() {
      const condition = this.currentVlan !== '0';
      return condition;
    }
  },
  watch: {
    currentCentral_switch_enabled() {
      // при изменении сentral_switch_enabled, сброс зависимых полей vlan, vlan_group в дефолт
      this.handleResetVlan();
      this.handleResetVlanGroup();
    },
    currentVlan_group(newVal) {
      // при vlan_group !== '0' - сброс значений и ошибок валидации поля vlan в дефолтное значение
      // потому что выбрать можно что-то одно: либо vlan, либо vlan_group
      if (newVal !== '0') {
        this.handleResetVlan();
      }
    },
    currentVlan(newVal) {
      // при vlan !== '0' - сброс значений и ошибок валидации поля vlan_group в дефолтное значение
      // потому что выбрать можно что-то одно: либо vlan, либо vlan_group
      if (newVal !== '0') {
        this.handleResetVlanGroup();
      }
    }
  },
  methods: {
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetVlan() {
      this.$emit('update:inputValue', {
        inputName: 'accessPolicies.id_vlan',
        value: '0'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'id_vlan',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetVlanGroup() {
      this.$emit('update:inputValue', {
        inputName: 'accessPolicies.id_vlan_group',
        value: '0'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'id_vlan_group',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.access-policies-section-description {
  flex: 0 0 40%;
  display: flex;
}
.access-policies-section-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}
</style>
