<template>
  <SVIFormModal
    :heading="$t('svi.changeSVI')"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:form>
      <ChangeSVIForm
        :SVIForChanging="SVIForChanging"
        :forbiddenSVIIds="forbiddenSVIIds"
        :forbiddenSVINames="forbiddenSVINames"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.update') }}
            </WButton>
          </div>
        </template>
      </ChangeSVIForm>
    </template>
  </SVIFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для редактирования svi.
 * ! Компонент локальный, используется только в SVIPage.vue
 * @component
 */

import {SVIFormModal} from '../features';

import {
  ChangeSVIForm
} from './components';

export default {
  name: 'ChangeSVIModal',
  components: {
    ChangeSVIForm,
    SVIFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    SVIForChanging: {
      type: Object,
      required: true
    },
    forbiddenSVIIds: {
      type: Array,
      default: () => []
    },
    forbiddenSVINames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
