/**
 * API-модуль для страницы vlan.
 * @module vlanApi
*/

import getVlans from './methods/getVlans';
import createVlan from './methods/createVlan';
import bulkCreateVlan from './methods/bulkCreateVlan';
import updateVlan from './methods/updateVlan';
import deleteVlans from './methods/deleteVlans';
import getVlanByVlanId from './methods/getVlanByVlanId';
import getVlanByName from './methods/getVlanByName';
import getSVIs from './methods/getSVIs';
import getVlanGroups from './methods/getVlanGroups';

const vlanApi = {
  getVlans,
  createVlan,
  bulkCreateVlan,
  updateVlan,
  deleteVlans,
  getVlanByVlanId,
  getVlanByName,
  getSVIs,
  getVlanGroups
};

export default vlanApi;
