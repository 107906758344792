import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_VLANS_URL = `${GAPI_URL}/api/v1/vlans`;
const POST_CREATE_VLAN_URL = `${GAPI_URL}/api/v1/vlans`;
const POST_BULK_CREATE_VLANS_URL = `${GAPI_URL}/api/v1/vlans/bulk/adding`;
const POST_UPDATE_VLAN_URL = `${GAPI_URL}/api/v1/vlans`;
const POST_DELETE_VLANS_URL = `${GAPI_URL}/api/v1/vlans/bulk/deletion`;
const GET_VLAN_BY_VLAN_ID_URL = `${GAPI_URL}/api/v1/vlans/find-by-vlan-id/`;
const GET_VLAN_BY_NAME_URL = `${GAPI_URL}/api/v1/vlans/find-by-name/`;
const GET_SVIS_URL = `${GAPI_URL}/api/v1/svis`;
const GET_VLAN_GROUPS_URL = `${GAPI_URL}/api/v1/vlan-groups`;

export {
  AXIOS_INSTANCE,
  GET_VLANS_URL,
  POST_CREATE_VLAN_URL,
  POST_BULK_CREATE_VLANS_URL,
  POST_UPDATE_VLAN_URL,
  POST_DELETE_VLANS_URL,
  GET_VLAN_BY_VLAN_ID_URL,
  GET_VLAN_BY_NAME_URL,
  GET_SVIS_URL,
  GET_VLAN_GROUPS_URL
};
