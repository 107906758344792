import { render, staticRenderFns } from "./VlanGroupTable.vue?vue&type=template&id=6c9c29db&scoped=true&"
import script from "./VlanGroupTable.vue?vue&type=script&lang=js&"
export * from "./VlanGroupTable.vue?vue&type=script&lang=js&"
import style1 from "./VlanGroupTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9c29db",
  null
  
)

export default component.exports