<template>
  <WCard class="route-with-tabs">
    <Tabs
      :tabs="routeTabs"
      :currentTab="currentRouteIndex"
      @on-tab-change="navigateToTab"
    >
      <router-view />
    </Tabs>
  </WCard>
</template>

<script>
/**
 * High-order UI-компонент компонент для роутинга,
 * который объединяет разные компоненты страниц, которые объединены по смыслу в группу под одним родительским роутом.
 * В свой функционал включает возможность переключаться между подстраницами через табы.
 *
 * Пример структуры:
 * корневой роут - RouteWithTabs.vue с корневым роутом /root
 * подстраница 1 - компонент страницы с роутом /root/foo
 * подстраница 2 - компонент страницы с роутом /root/bar
 *
 * ! Используется только в Router.
 *
 * @component
 *
 * @example
 * Настройка роута через RouteWithTabs с чилдренами:
 *
 * {
      path: 'root',
      name: 'RouteWithTabs',
      redirect: 'root/foo',
      component: RouteWithTabs,
      props: {
        childrenRouteParams: [
          { routeName: 'FooPage', i18nLabel: 'foo.pageName' },
          { routeName: 'BarPage', i18nLabel: 'bar.pageName' },
          { routeName: 'BuzzPage', i18nLabel: 'buzz.pageName' }
        ]
      },
      children: [
        {
          path: 'foo',
          name: 'FooPage',
          component: FooPage
        },
        {
          path: 'bar',
          name: 'BarPage',
          component: BarPage
        },
        {
          path: 'buzz',
          name: 'BuzzPage',
          component: BuzzPage
        }
      ]
    },
 */

export default {
  name: 'RouteWithTabs',
  props: {
    childrenRouteParams: {
      type: Array,
      required: true,
      validator: options => {
        const errorMessage = 'prop childrenRouteParams must be typed as { routeName: string, i18nLabel: string }[]';

        if (!Array.isArray(options)) {
          console.error(errorMessage);
          return false;
        }

        if (!options.length) {
          console.error(errorMessage);
          return false;
        }

        const isValid = options.reduce((acc, curr) => {
          if (typeof curr !== 'object') {
            console.error(errorMessage);
            return false;
          }

          const { routeName, i18nLabel } = curr;

          if (typeof routeName !== 'string') {
            console.error(errorMessage);
            return false;
          }

          if (typeof i18nLabel !== 'string') {
            console.error(errorMessage);
            return false;
          }

          return true;
        }, true);

        return isValid;
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    routeNames() {
      return this.childrenRouteParams.map(routeParam => routeParam.routeName);
    },
    currentRouteIndex() {
      return this.routeNames.indexOf(this.currentRouteName);
    },
    routeTabs() {
      return this.childrenRouteParams.map(routeParam => ({
        label: this.$i18n.t(routeParam.i18nLabel),
        id: routeParam.routeName,
        name: routeParam.routeName
      }));
    }
  },
  methods: {
    navigateToTab(nextRouteIndex) {
      const nextRouteName = this.routeNames[nextRouteIndex];

      if (this.$route.name === nextRouteName) {
        return;
      }

      this.$router.push({ name: nextRouteName });
    }
  }
};
</script>

<style scoped>
.route-with-tabs {
  background-color: var(--background-color);
}
</style>
