<template>
  <VlanGroupForm
    :formConfig="formConfig"
    :forbiddenVlanGroupNames="forbiddenVlanGroupNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </VlanGroupForm>
</template>

<script>
/**
 * компонент настроенной формы VlanGroupForm через generateFormConfig для редактирования vlan group.
 * ! Компонент локальный, используется только в ChangeVlanGroupModal.vue
 * @component
 */

/* eslint-disable camelcase */

import {
  VlanGroupForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'ChangeVlanGroupForm',
  components: {
    VlanGroupForm
  },
  props: {
    vlanGroupForChanging: {
      type: Object,
      required: true
    },
    forbiddenVlanGroupNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const {
      id,
      list_vlan_id,
      list_id_vlan,
      name
    } = this.vlanGroupForChanging;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          name: {
            defaultValue: name,
            isDisabled: true
          },
          list_vlan_id: {
            defaultValue: list_vlan_id
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
./features
