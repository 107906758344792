<template>
  <SVIFormModal 
    :heading="$t('svi.createSVI')" 
    :isOpen="isOpen" 
    @close="handleClose" 
    v-bind="$attrs"
  >
    <template v-slot:form>
      <CreateSVIForm 
        :forbiddenSVINames="forbiddenSVINames" 
        :forbiddenVlanIds="forbiddenVlanIds" 
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline @click="handleClose">
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.add') }}
            </WButton>
          </div>
        </template>
      </CreateSVIForm>
    </template>
  </SVIFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для создания нового svi.
 * ! Компонент локальный, используется только в SVIPage.vue
 * @component
 */

import { CreateSVIForm } from './components';
import { SVIFormModal } from '../features';

export default {
  name: 'CreateSVIModal',
  components: {
    CreateSVIForm,
    SVIFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    forbiddenSVINames: {
      type: Array,
      default: () => []
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  },
  created() {}
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
