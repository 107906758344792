/**
 * API-модуль для страницы vlan group.
 * @module vlanGroupApi
*/

import getVlanGroups from './methods/getVlanGroups';
import createVlanGroup from './methods/createVlanGroup';
import updateVlanGroup from './methods/updateVlanGroup';
import deleteVlanGroups from './methods/deleteVlanGroups';
import getVlanGroupById from './methods/getVlanGroupById';
import getVlanGroupByName from './methods/getVlanGroupByName';

const vlanGroupApi = {
  getVlanGroups,
  createVlanGroup,
  updateVlanGroup,
  deleteVlanGroups,
  getVlanGroupById,
  getVlanGroupByName
};

export default vlanGroupApi;
