import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_VLAN_GROUPS_URL = `${GAPI_URL}/api/v1/vlan-groups`;
const POST_CREATE_VLAN_GROUP_URL = `${GAPI_URL}/api/v1/vlan-groups`;
const POST_UPDATE_VLAN_GROUP_URL = `${GAPI_URL}/api/v1/vlan-groups`;
const POST_DELETE_VLAN_GROUPS_URL = `${GAPI_URL}/api/v1/vlan-groups/bulk/deletion`;
const GET_VLAN_GROUP_BY_ID_URL = `${GAPI_URL}/api/v1/vlan-groups/find-by-id/`;
const GET_VLAN_GROUP_BY_NAME_URL = `${GAPI_URL}/api/v1/vlan-groups/find-by-name/`;

export {
  AXIOS_INSTANCE,
  GET_VLAN_GROUPS_URL,
  POST_CREATE_VLAN_GROUP_URL,
  POST_UPDATE_VLAN_GROUP_URL,
  POST_DELETE_VLAN_GROUPS_URL,
  GET_VLAN_GROUP_BY_ID_URL,
  GET_VLAN_GROUP_BY_NAME_URL
};
