/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').Vlan_FRONTEND} Vlan_FRONTEND
 * @typedef {import('./types.js').Vlan_BACKEND} Vlan_BACKEND
 * @typedef {import('./types.js').BaseVlan_FRONTEND} BaseVlan_FRONTEND
 * @typedef {import('./types.js').SVI_FRONTEND} SVI_FRONTEND
 * @typedef {import('./types.js').SVI_BACKEND} SVI_BACKEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов vlan с бэковых типов (Vlan_BACKEND) в фронтовые (Vlan_FRONTEND).
 * Использовать, когда получаем vlan с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {Vlan_BACKEND} vlan - Vlan_BACKEND данные для конвертации типов.
 * @returns {Vlan_FRONTEND} - сконвертированные данные с типизацией Vlan_FRONTEND.
 */
function vlanDataTypesConverterForFrontend(vlan) {
  const {
    id,
    vlan_id,
    name,
    description,
    admin_status,
    created_at,
    updated_at
  } = vlan;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    vlan_id: String(vlan_id),
    name: String(name),
    description: String(description),
    admin_status: String(admin_status) === 'True'
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов vlan с фронтовых типов (Vlan_FRONTEND) в бэковые (Vlan_BACKEND).
 * Использовать, когда возвращаем vlan с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {BaseVlan_FRONTEND & Partial<Id>} vlan - Vlan_FRONTEND данные для конвертации типов.
 * @returns {Vlan_BACKEND>} - сконвертированные данные с типизацией Vlan_BACKEND.
 */
function vlanDataTypesConverterForBackend(vlan) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    vlan_id,
    name,
    description,
    admin_status
  } = vlan;

  const convertedTypesToBackendFormat = {
    vlan_id: String(vlan_id),
    name: String(name),
    // description: String(description),
    admin_status: admin_status ? 'True' : 'False'
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  return convertedTypesToBackendFormat;
}

/**
 * Конвертер типов svi с бэковых типов (SVI_BACKEND) в фронтовые (SVI_FRONTEND).
 * Использовать, когда получаем svi с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {SVI_BACKEND} svi - SVI_BACKEND данные для конвертации типов.
 * @returns {SVI_FRONTEND} - сконвертированные данные с типизацией SVI_FRONTEND.
 */
function sviDataTypesConverterForFrontend(svi) {
  const {
    id,
    vlan_id,
    id_vlan,
    name,
    description,
    admin_status,
    oper_status,
    ip,
    subnet_mask,
    acl_ingress,
    acl_egress,
    mdns_enabled,
    mtu,
    created_at,
    updated_at
  } = svi;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    vlan_id: String(vlan_id),
    id_vlan: String(id_vlan),
    name: String(name),
    description: String(description),
    admin_status: String(admin_status) === 'True',
    oper_status: String(oper_status) === 'True',
    ip: String(ip),
    subnet_mask: String(subnet_mask),
    acl_ingress: String(acl_ingress),
    acl_egress: String(acl_egress),
    mdns_enabled: String(mdns_enabled) === 'True',
    mtu: String(mtu)
  };

  return convertedTypesToFrontendFormat;
}

export {
  getFetcherUrl,
  vlanDataTypesConverterForFrontend,
  vlanDataTypesConverterForBackend,
  sviDataTypesConverterForFrontend
};
