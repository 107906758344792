<template>
  <VlanFormModal
    :heading="$t('vlan.createVlan')"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:form>
      <CreateVlanForm
        :forbiddenVlanIds="forbiddenVlanIds"
        :forbiddenVlanNames="forbiddenVlanNames"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.add') }}
            </WButton>
          </div>
        </template>
      </CreateVlanForm>
    </template>
  </VlanFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для создания нового vlan.
 * ! Компонент локальный, используется только в VlanPage.vue
 * @component
 */

import {
  VlanFormModal
} from '../features';

import {
  CreateVlanForm
} from './components';

export default {
  name: 'CreateVlanModal',
  components: {
    CreateVlanForm,
    VlanFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
