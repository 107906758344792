<template>
  <SVIForm
    :formConfig="formConfig"
    :forbiddenSVIIds="forbiddenSVIIds"
    :forbiddenSVINames="forbiddenSVINames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </SVIForm>
</template>

<script>
/**
 * компонент настроенной формы SVIForm через generateFormConfig для редактирования svi.
 * ! Компонент локальный, используется только в ChangeSVIModal.vue
 * @component
 */

import { 
  SVIForm, 
  generateFormConfig 
} from '../../features';

/* eslint-disable camelcase */

export default {
  name: 'ChangeSVIForm',
  components: {
    SVIForm
  },
  props: {
    SVIForChanging: {
      type: Object,
      required: true
    },
    forbiddenSVIIds: {
      type: Array,
      default: () => []
    },
    forbiddenSVINames: {
      type: Array,
      default: () => []
    },
    aclValues: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const { 
      id,
      vlan_id,
      admin_status,
      ip,
      subnet_mask,
      description,
      acl_ingress,
      acl_egress,
      mdns_enabled,
      mtu
    } = this.SVIForChanging;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          vlan_id: {
            isDisabled: true,
            defaultValue: vlan_id
          },
          admin_status: {
            defaultValue: admin_status
          },
          ip: {
            defaultValue: ip
          },
          subnet_mask: {
            defaultValue: subnet_mask
          },
          description: {
            defaultValue: description
          },
          acl_ingress: {
            defaultValue: acl_ingress,
            selectOptions: this.aclValues.map(name => ({
              display: name, data: name
            }))
          },
          acl_egress: {
            defaultValue: acl_egress,
            selectOptions: this.aclValues.map(name => ({
              display: name, data: name
            }))
          },
          mdns_enabled: {
            defaultValue: mdns_enabled
          },
          mtu: {
            defaultValue: mtu
          },
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped></style>
