/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента VlanForm.
 */

import vlanApi from '../../../api';

// Функция для проверки уникальности vlan_id на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueVlanIdByApiRequest = async idToCheck => {
  let result;

  await vlanApi.getVlanByVlanId(idToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

// Функция для проверки уникальности vlan name на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueVlanNameByApiRequest = async nameToCheck => {
  let result;

  await vlanApi.getVlanByName(nameToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

const getVlanValidators = (i18nInstance) => ({
  uniqueVlanId: (forbiddenVlanIds) => ({
    getMessage: field => {
      const message = i18nInstance.t('vlan.inputError.uniqueVlanId');
      return message;
    },
    validate: async value => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenVlanIds
        .map(value => String(value).toLocaleLowerCase())
        .includes(String(value).toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueVlanIdByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  vlanNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('vlan.inputError.vlanNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[\w!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~А-я]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  uniqueVlanName: (forbiddenVlanNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('vlan.inputError.uniqueVlanName');
      return message;
    },
    validate: async (value, [otherValue]) => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenVlanNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // если значения совпадают, то валидно
      // нужно, чтобы существующее имя не помечалось
      // как неуникальное при редактировании, тк нет намерения его редактирвоать
      if (value === otherValue) {
        return true;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueVlanNameByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  isGreaterThan: () => ({
    getMessage: (field, params, data) => {
      const message = `${field} ${i18nInstance.t('generan.mustBeGreaterThan')} ${params[0]}`;
      return message;
    },
    validate: async (value, [otherValue]) => {
      if (!otherValue) {
        return true;
      }
      const result = Number(value) > Number(otherValue);

      return result;
    }
  }),
  isLessThan: () => ({
    getMessage: (field, params, data) => {
      const message = `${field} ${i18nInstance.t('general.mustBeLessThan')} ${params[0]}`;
      return message;
    },
    validate: async (value, [otherValue]) => {
      if (!otherValue) {
        return true;
      }
      const result = Number(value) < Number(otherValue);

      return result;
    }
  })
});

export {
  getVlanValidators
};
