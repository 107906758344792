/**
 * API-модуль для страницы svi.
 * @module sviApi
*/

import getSVIs from './methods/getSVIs';
import createSVI from './methods/createSVI';
import updateSVI from './methods/updateSVI';
import deleteSVIs from './methods/deleteSVIs';
import getVlanByVlanId from './methods/getVlanByVlanId';
import getSVIByName from './methods/getSVIByName';
import getAclValues from './methods/getAclValues';

const sviApi = {
  getSVIs,
  createSVI,
  updateSVI,
  deleteSVIs, 
  getVlanByVlanId,
  getSVIByName,
  getAclValues
};

export default sviApi;
