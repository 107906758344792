/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Основные настройки.
 * @property {FieldConfig} general.name
 * @property {FieldConfig} general.description
 * @property {FieldConfig} general.profile_enabled
 * @property {FieldConfig} general.central_switch_enabled
 * @property {FieldConfig} general.central_auth_enabled
 * @property {Object} accessPolicies - Политики доступа.
 * @property {FieldConfig} accessPolicies.acl
 * @property {FieldConfig} accessPolicies.vlan_group
 * @property {Object} qos - Качество обслуживания.
 * @property {FieldConfig} qos.id_policy_map_client_in
 * @property {FieldConfig} qos.id_policy_map_client_out
 * @property {FieldConfig} qos.id_policy_map_ssid_in
 * @property {FieldConfig} qos.id_policy_map_ssid_out
 * @property {Object} advanced - Дополнительно.
 * @property {FieldConfig} advanced.session_timeout
 * @property {FieldConfig} advanced.idle_timeout
 * @property {FieldConfig} advanced.idle_threshold
 * @property {FieldConfig} advanced.exclusion_timeout
 * @property {FieldConfig} advanced.aaa_override_enabled
 * @property {FieldConfig} advanced.proxy_arp_enabled
 * @property {FieldConfig} advanced.id_eogre_profile
 * @property {FieldConfig} advanced.nas_id_1
 * @property {FieldConfig} advanced.nas_id_1_custom
 * @property {FieldConfig} advanced.nas_id_2
 * @property {FieldConfig} advanced.nas_id_2_custom
 * @property {FieldConfig} advanced.nas_id_3
 * @property {FieldConfig} advanced.nas_id_3_custom
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    name: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    description: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    },
    profile_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    central_switch_enabled: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false
    },
    // По умолчанию должно быть всегда включено и у пользователя не должно быть возможности выключить.
    central_auth_enabled: {
      isDisabled: true,
      defaultValue: true,
      isRequired: false
    }
  },
  accessPolicies: {
    acl: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    },
    // Если доступно, то пользователь должен обязательно выбрать либо VLAN,
    // либо VLAN группу.Если не выбран VLAN или VLAN Группа, то добавить Профиль Политики БЛВС нельзя.
    // логика реализована в AccessPoliciesGeneralSection.vue
    id_vlan: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    },
    // Если доступно, то пользователь должен обязательно выбрать либо VLAN,
    // либо VLAN группу.Если не выбран VLAN или VLAN Группа, то добавить Профиль Политики БЛВС нельзя.
    // логика реализована в AccessPoliciesGeneralSection.vue
    id_vlan_group: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    }
  },
  qos: {
    id_policy_map_client_in: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    },
    id_policy_map_client_out: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    },
    id_policy_map_ssid_in: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    },
    id_policy_map_ssid_out: {
      isDisabled: false,
      defaultValue: '0',
      isRequired: true
    }
  },
  advanced: {
    session_timeout: {
      isDisabled: false,
      defaultValue: 28800,
      isRequired: true
    },
    idle_timeout: {
      isDisabled: false,
      defaultValue: 300,
      isRequired: true
    },
    idle_threshold: {
      isDisabled: false,
      defaultValue: 0,
      isRequired: true
    },
    exclusion_timeout: {
      isDisabled: false,
      defaultValue: 60,
      isRequired: true
    },
    aaa_override_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    proxy_arp_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    // todo пока что поле eogre_profile скрыто. В будущем появится и ручка, и поле будет доступно в ui
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398188/FT23.10.1+front?focusedCommentId=625279040
    id_eogre_profile: {
      isDisabled: true,
      defaultValue: '0',
      isRequired: true
    },
    nas_id_1: {
      isDisabled: false,
      defaultValue: 'sys_name',
      isRequired: false
    },
    nas_id_1_custom: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    nas_id_2: {
      isDisabled: false,
      defaultValue: 'not_configured',
      isRequired: false
    },
    nas_id_2_custom: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    nas_id_3: {
      isDisabled: false,
      defaultValue: 'not_configured',
      isRequired: false
    },
    nas_id_3_custom: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */

function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    // Проверяем, если это массив, заменяем массив целиком
    if (Array.isArray(value)) {
      result[key] = value;
    } else if (value instanceof Object && key in target) {
      // Рекурсивно обрабатываем объекты
      result[key] = mergeDeep(target[key], value);
    } else {
      // Заменяем значение для простых типов
      result[key] = value;
    }
  });

  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 * @property {string|number|boolean} [radioValue] - value для radio инпута.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} [id] - Уникальный идентификатор для группы содержимого.
 * @property {string} label - Метка для группы содержимого.
 * @property {string} [name] - Имя для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для general вкладки.
 * @param {Object} [params.initialValues.accessPolicies] - Начальные значения и настройки для вкладки accessPolicies.
 * @param {Object} [params.initialValues.qos] - Начальные значения и настройки для вкладки qos.
 * @param {Object} [params.initialValues.advanced] - Начальные значения и настройки для вкладки advanced.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      name,
      description,
      profile_enabled,
      central_switch_enabled,
      central_auth_enabled
    },
    accessPolicies: {
      acl,
      id_vlan,
      id_vlan_group
    },
    qos: {
      id_policy_map_client_in,
      id_policy_map_client_out,
      id_policy_map_ssid_in,
      id_policy_map_ssid_out
    },
    advanced: {
      session_timeout,
      idle_timeout,
      idle_threshold,
      exclusion_timeout,
      aaa_override_enabled,
      proxy_arp_enabled,
      id_eogre_profile,
      nas_id_1,
      nas_id_1_custom,
      nas_id_2,
      nas_id_2_custom,
      nas_id_3,
      nas_id_3_custom,
    }
  } = mergedValues;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('wlanPolicy.tabLabel.general'),
      name: 'general',
      content: [
        {
          id: uuidv4(),
          label: '',
          name: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.profileName'),
              name: 'general.name',
              inputType: 'text',
              validateRules: `${name.isRequired ? 'required|' : ''}uniqueWlanPolicyName|wlanPolicyNameMask|max:120`,
              isDisabled: name.isDisabled,
              placeholder: '',
              defaultValue: name.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.description'),
              name: 'general.description',
              inputType: 'text',
              validateRules: `${description.isRequired ? 'required|' : ''}wlanPolicyDescriptionMask|max:120`,
              isDisabled: description.isDisabled,
              placeholder: '',
              defaultValue: description.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: '',
          name: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.profile_enabled'),
              name: 'general.profile_enabled',
              inputType: 'checkbox',
              validateRules: `${profile_enabled.isRequired ? 'required' : ''}`,
              isDisabled: profile_enabled.isDisabled,
              placeholder: '',
              defaultValue: profile_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.central_switch_enabled'),
              name: 'general.central_switch_enabled',
              inputType: 'checkbox',
              validateRules: `${central_switch_enabled.isRequired ? 'required' : ''}`,
              isDisabled: central_switch_enabled.isDisabled,
              placeholder: '',
              defaultValue: central_switch_enabled.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.central_auth_enabled'),
              name: 'general.central_auth_enabled',
              inputType: 'checkbox',
              validateRules: `${central_auth_enabled.isRequired ? 'required' : ''}`,
              isDisabled: central_auth_enabled.isDisabled,
              placeholder: '',
              defaultValue: central_auth_enabled.defaultValue
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('wlanPolicy.tabLabel.accessPolicies'),
      name: 'accessPolicies',
      content: [
        {
          id: uuidv4(),
          label: '',
          name: 'accessPolicies.general',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.acl'),
              name: 'accessPolicies.acl',
              inputType: 'select',
              validateRules: `${acl.isRequired ? 'required' : ''}`,
              isDisabled: acl.isDisabled,
              placeholder: '',
              defaultValue: acl.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.acl.selectOption.notConfigured'), data: '0' },
                // список селектов с бэка
                ...acl.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.vlan'),
              name: 'accessPolicies.id_vlan',
              inputType: 'select',
              validateRules: `${id_vlan.isRequired ? 'vlanConfigurationRequired' : ''}`,
              isDisabled: id_vlan.isDisabled,
              placeholder: $t('wlanPolicy.inputLabel.vlan.selectOption.notConfigured'),
              defaultValue: id_vlan.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.vlan.selectOption.notConfigured'), data: '0', disabled: true },
                // список селектов с бэка
                ...id_vlan.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.vlan_group'),
              name: 'accessPolicies.id_vlan_group',
              inputType: 'select',
              validateRules: `${id_vlan_group.isRequired ? 'vlanConfigurationRequired' : ''}`,
              isDisabled: id_vlan_group.isDisabled,
              placeholder: $t('wlanPolicy.inputLabel.vlan_group.selectOption.notConfigured'),
              defaultValue: id_vlan_group.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.vlan_group.selectOption.notConfigured'), data: '0', disabled: true },
                // список селектов с бэка
                ...id_vlan_group.selectOptions
              ]
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('wlanPolicy.tabLabel.qos'),
      name: 'qos',
      content: [
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.qoSProfileClient'),
          name: 'qos.profileClient',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.policy_map_client_in'),
              name: 'qos.id_policy_map_client_in',
              inputType: 'select',
              validateRules: `${id_policy_map_client_in.isRequired ? 'required' : ''}`,
              isDisabled: id_policy_map_client_in.isDisabled,
              placeholder: '',
              defaultValue: id_policy_map_client_in.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.policy_map_client_in.selectOption.notConfigured'), data: '0' },
                // список селектов с бэка
                ...id_policy_map_client_in.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.policy_map_client_out'),
              name: 'qos.id_policy_map_client_out',
              inputType: 'select',
              validateRules: `${id_policy_map_client_out.isRequired ? 'required' : ''}`,
              isDisabled: id_policy_map_client_out.isDisabled,
              placeholder: '',
              defaultValue: id_policy_map_client_out.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.policy_map_client_out.selectOption.notConfigured'), data: '0' },
                // список селектов с бэка
                ...id_policy_map_client_out.selectOptions
              ]
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.qoSProfileSsid'),
          name: 'qos.profileSsid',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.policy_map_ssid_in'),
              name: 'qos.id_policy_map_ssid_in',
              inputType: 'select',
              validateRules: `${id_policy_map_ssid_in.isRequired ? 'required' : ''}`,
              isDisabled: id_policy_map_ssid_in.isDisabled,
              placeholder: '',
              defaultValue: id_policy_map_ssid_in.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.policy_map_ssid_in.selectOption.notConfigured'), data: '0' },
                // список селектов с бэка
                ...id_policy_map_ssid_in.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.policy_map_ssid_out'),
              name: 'qos.id_policy_map_ssid_out',
              inputType: 'select',
              validateRules: `${id_policy_map_ssid_out.isRequired ? 'required' : ''}`,
              isDisabled: id_policy_map_ssid_out.isDisabled,
              placeholder: '',
              defaultValue: id_policy_map_ssid_out.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.policy_map_ssid_out.selectOption.notConfigured'), data: '0' },
                // список селектов с бэка
                ...id_policy_map_ssid_out.selectOptions
              ]
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      label: $t('wlanPolicy.tabLabel.advanced'),
      name: 'advanced',
      content: [
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.wlanTimeoutSettings'),
          name: 'advanced.wlanTimeoutSettings',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.session_timeout'),
              name: 'advanced.session_timeout',
              inputType: 'number',
              validateRules: `${session_timeout.isRequired ? 'required|' : ''}integer|between:0,86400`,
              isDisabled: session_timeout.isDisabled,
              placeholder: '',
              defaultValue: session_timeout.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.idle_timeout'),
              name: 'advanced.idle_timeout',
              inputType: 'number',
              validateRules: `${idle_timeout.isRequired ? 'required|' : ''}integer|between:15,100000`,
              isDisabled: idle_timeout.isDisabled,
              placeholder: '',
              defaultValue: idle_timeout.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.idle_threshold'),
              name: 'advanced.idle_threshold',
              inputType: 'number',
              validateRules: `${idle_threshold.isRequired ? 'required|' : ''}integer|between:0,4294967295`,
              isDisabled: idle_threshold.isDisabled,
              placeholder: '',
              defaultValue: idle_threshold.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.exclusion_timeout'),
              name: 'advanced.exclusion_timeout',
              inputType: 'number',
              validateRules: `${exclusion_timeout.isRequired ? 'required|' : ''}integer|between:0,214748`,
              isDisabled: exclusion_timeout.isDisabled,
              placeholder: '',
              defaultValue: exclusion_timeout.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.aaaSettings'),
          name: 'advanced.aaaSettings',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.aaa_override_enabled'),
              name: 'advanced.aaa_override_enabled',
              inputType: 'checkbox',
              validateRules: `${aaa_override_enabled.isRequired ? 'required' : ''}`,
              isDisabled: aaa_override_enabled.isDisabled,
              placeholder: '',
              defaultValue: aaa_override_enabled.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.proxySettings'),
          name: 'advanced.proxySettings',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.proxy_arp_enabled'),
              name: 'advanced.proxy_arp_enabled',
              inputType: 'checkbox',
              validateRules: `${proxy_arp_enabled.isRequired ? 'required' : ''}`,
              isDisabled: proxy_arp_enabled.isDisabled,
              placeholder: '',
              defaultValue: proxy_arp_enabled.defaultValue
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.eogreSettings'),
          name: 'advanced.eogreSettings',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.eogre_profile'),
              name: 'advanced.id_eogre_profile',
              inputType: 'select',
              validateRules: `${id_eogre_profile.isRequired ? 'required' : ''}`,
              isDisabled: id_eogre_profile.isDisabled,
              placeholder: '',
              defaultValue: id_eogre_profile.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.eogre_profile.selectOption.notConfigured'), data: 'not_configured' },
                // список селектов с бэка
                ...id_eogre_profile.selectOptions
              ]
            }
          ]
        },
        {
          id: uuidv4(),
          label: $t('wlanPolicy.tabSectionLabel.nasIdSettings'),
          name: 'advanced.nasIdSettings',
          inputs: [
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_1'),
              name: 'advanced.nas_id_1',
              inputType: 'select',
              validateRules: `${nas_id_1.isRequired ? 'required' : ''}`,
              isDisabled: nas_id_1.isDisabled,
              placeholder: '',
              defaultValue: nas_id_1.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-name'), data: 'sys_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-ip'), data: 'sys_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-mac'), data: 'sys_mac' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ssid'), data: 'ssid_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-ip'), data: 'ap_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-name'), data: 'ap_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-mac-eth'), data: 'ap_mac_eth' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-policy-tag'), data: 'ap_policy_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-site-tag'), data: 'ap_site_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-location'), data: 'ap_location' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.customString'), data: 'custom_string' }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_1_custom'),
              name: 'advanced.nas_id_1_custom',
              inputType: 'text',
              validateRules: `${nas_id_1_custom.isRequired ? 'required|' : ''}nasMask`,
              isDisabled: nas_id_1_custom.isDisabled,
              placeholder: '',
              defaultValue: nas_id_1_custom.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_2'),
              name: 'advanced.nas_id_2',
              inputType: 'select',
              validateRules: `${nas_id_2.isRequired ? 'required' : ''}`,
              isDisabled: nas_id_2.isDisabled,
              placeholder: '',
              defaultValue: nas_id_2.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.notConfigured'), data: 'not_configured' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-name'), data: 'sys_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-ip'), data: 'sys_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-mac'), data: 'sys_mac' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ssid'), data: 'ssid_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-ip'), data: 'ap_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-name'), data: 'ap_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-mac-eth'), data: 'ap_mac_eth' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-policy-tag'), data: 'ap_policy_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-site-tag'), data: 'ap_site_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-location'), data: 'ap_location' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.customString'), data: 'custom_string' }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_2_custom'),
              name: 'advanced.nas_id_2_custom',
              inputType: 'text',
              validateRules: `${nas_id_2_custom.isRequired ? 'required|' : ''}nasMask`,
              isDisabled: nas_id_2_custom.isDisabled,
              placeholder: '',
              defaultValue: nas_id_2_custom.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_3'),
              name: 'advanced.nas_id_3',
              inputType: 'select',
              validateRules: `${nas_id_3.isRequired ? 'required' : ''}`,
              isDisabled: nas_id_3.isDisabled,
              placeholder: '',
              defaultValue: nas_id_3.defaultValue,
              selectOptions: [
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.notConfigured'), data: 'not_configured' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-name'), data: 'sys_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-ip'), data: 'sys_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.sys-mac'), data: 'sys_mac' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ssid'), data: 'ssid_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-ip'), data: 'ap_ip' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-name'), data: 'ap_name' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-mac-eth'), data: 'ap_mac_eth' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-policy-tag'), data: 'ap_policy_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-site-tag'), data: 'ap_site_tag' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.ap-location'), data: 'ap_location' },
                { display: $t('wlanPolicy.inputLabel.nas.selectOption.customString'), data: 'custom_string' }
              ]
            },
            {
              id: uuidv4(),
              label: $t('wlanPolicy.inputLabel.nas_id_3_custom'),
              name: 'advanced.nas_id_3_custom',
              inputType: 'text',
              validateRules: `${nas_id_3_custom.isRequired ? 'required|' : ''}nasMask`,
              isDisabled: nas_id_3_custom.isDisabled,
              placeholder: '',
              defaultValue: nas_id_3_custom.defaultValue
            }
          ]
        }
      ]
    }
  ];

  return formConfig;
}

export default generateFormConfig;
