<template>
  <div class="vlan-tab-input-group single-mode">
    <h3 v-if="config.label"
      class="vlan-tab-input-group-heading"
    >
      {{ config.label }}
    </h3>
    <template v-for="input in config.inputs">
      <template v-if="input.inputType === 'radio'">
        <WRadio
          v-if="input.isVisible"
          data-vv-scope="multipleMode"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.id"
          :value="input.radioValue"
          @input="handleRadio(input, $event)"
          :checked="formState[input.name] === input.radioValue"
          :disabled="input.isDisabled"
        />
      </template>
      <template v-else-if="input.inputType === 'checkbox'">
        <WCheckbox
          v-if="input.isVisible"
          data-vv-scope="multipleMode"
          :key="input.id"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.inputType === 'select'">
        <WSelect
          v-if="input.isVisible"
          data-vv-scope="multipleMode"
          :key="input.id"
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else>
        <WInput
          v-if="input.isVisible"
          data-vv-scope="multipleMode"
          data-vv-delay="500"
          :ref="input.ref && input.ref"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </template>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба general секции general.multipleMode.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 *
 * ft:
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%94%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-VLAN
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'MultipleCreationModeSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      this.$emit('update:inputValue', { inputName: name, value });
    },
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetSectionValidation() {
      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'multipleMode',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetInitialVlanId() {
      this.$emit('update:inputValue', {
        inputName: 'general.initial_vlan_id',
        value: ''
      });
    },
    handleResetLastVlanId() {
      this.$emit('update:inputValue', {
        inputName: 'general.last_vlan_id',
        value: ''
      });
    }
  },
  mounted() {
  },
  beforeDestroy() {
    // сброс всех полей этой секции в дефолт
    this.handleResetInitialVlanId();
    this.handleResetLastVlanId();
    this.handleResetSectionValidation();
  }
};
</script>

<style lang="css" scoped>
</style>
