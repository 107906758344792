<template>
  <VlanForm
    :formConfig="formConfig"
    :forbiddenVlanIds="forbiddenVlanIds"
    :forbiddenVlanNames="forbiddenVlanNamesWithoutCurrentName"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </VlanForm>
</template>

<script>
/**
 * компонент настроенной формы VlanForm через generateFormConfig для редактирования vlan.
 * ! Компонент локальный, используется только в ChangeVlanModal.vue
 * @component
 */

import {
  VlanForm,
  generateFormConfig
} from '../../features';

/* eslint-disable camelcase */

export default {
  name: 'ChangeVlanForm',
  components: {
    VlanForm
  },
  props: {
    vlanForChanging: {
      type: Object,
      required: true
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    forbiddenVlanNamesWithoutCurrentName() {
      return this.forbiddenVlanNames
        .filter(vlanName => vlanName !== this.vlanForChanging.name);
    }
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const {
      id,
      name,
      vlan_id,
      description,
      admin_status
    } = this.vlanForChanging;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          creationMode: {
            isDisabled: true,
            isVisible: false
          },
          vlan_id: {
            isDisabled: true,
            defaultValue: vlan_id
          },
          name: {
            defaultValue: name
          },
          admin_status: {
            defaultValue: admin_status
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
