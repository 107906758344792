<template>
  <SVIForm
    :formConfig="formConfig"
    :forbiddenSVINames="forbiddenSVINames"
    :forbiddenVlanIds="forbiddenVlanIds"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </SVIForm>
</template>

<script>
/**
 * компонент настроенной формы SVIForm через generateFormConfig для
 * ! Компонент локальный, используется только в CreateSVIModal.vue
 * @component
 */

import { 
  SVIForm, 
  generateFormConfig 
} from '../../features';

export default {
  name: 'CreateSVIForm',
  components: {
    SVIForm
  },
  props: {
    forbiddenSVINames: {
      type: Array,
      default: () => []
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    aclValues: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          acl_ingress: {
            selectOptions: this.aclValues.map(name => ({
              display: name, data: name
            }))
          },
          acl_egress: {
            selectOptions: this.aclValues.map(name => ({
              display: name, data: name
            }))
          }
        },
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>