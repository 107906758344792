import { render, staticRenderFns } from "./BreadcrumbsNavigation.vue?vue&type=template&id=c1e3b2f2&scoped=true&"
import script from "./BreadcrumbsNavigation.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbsNavigation.vue?vue&type=script&lang=js&"
import style0 from "./BreadcrumbsNavigation.vue?vue&type=style&index=0&id=c1e3b2f2&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e3b2f2",
  null
  
)

export default component.exports