// selected checkbox of a row or not
function getSelectedRowValue(currentWlanPolicyId, selectedWlanPolicyIdsArray) {
  let value = false;

  selectedWlanPolicyIdsArray.forEach(wlanPolicyId => {
    if (wlanPolicyId === currentWlanPolicyId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
