/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента BranchProfileForm.
 */

import wlanPolicyApi from '../../../api';

// Функция для проверки уникальности имени профиля на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueWlanPolicyNameByApiRequest = async nameToCheck => {
  let result;

  await wlanPolicyApi.checkIsUniqueWlanPolicyName(nameToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

const getWlanPolicyFormValidators = (i18nInstance) => ({
  uniqueWlanPolicyName: (forbiddenProfileNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('wlanPolicy.inputError.uniqueWlanPolicyName');
      return message;
    },
    validate: async value => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenProfileNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueWlanPolicyNameByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  wlanPolicyNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlanPolicy.inputError.wlanPolicyNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[\w!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~А-я]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  wlanPolicyDescriptionMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlanPolicy.inputError.descriptionMask');
      return message;
    },
    validate(value) {
      const customValidationRegex = /^[\w !"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~А-я]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  vlanConfigurationRequired: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlanPolicy.inputError.vlanConfiguration');
      return message;
    },
    validate(value) {
      return value !== '0';
    }
  }),
  nasMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlanPolicy.inputError.nasMask');
      return message;
    },
    validate(value) {
      const customValidationRegex = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/;

      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }

      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
});

export {
  getWlanPolicyFormValidators
};
