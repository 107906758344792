// selected checkbox of a row or not
function getSelectedRowValue(currentApId, selectedAPIdsArray) {
  let value = false;

  selectedAPIdsArray.forEach(apId => {
    if (apId === currentApId) {
      value = true;
    }
  });

  return value;
}

function formatStringWithVisibleSpaces(str) {
  const hasLeadingSpace = str[0] === ' ';
  const hasTrailingSpace = str[str.length - 1] === ' ';
  const formattedStr = str.replace(/ /g, '\u00A0');

  return hasLeadingSpace || hasTrailingSpace ? `"${formattedStr}"` : formattedStr;
}

export {
  getSelectedRowValue,
  formatStringWithVisibleSpaces
};
