import { createNamespacedHelpers } from 'vuex';
/**
 * Миксин возвращает максимальное количество SSID (wi-fi сетей), которое можно выбрать для одного радиоинтерфейса
 * в зависимости от продукта - WLC или ONE.
 *
 * Исторически сложилось что было захардкожено максимукм 8 интерфейсов
 *
 * Пока что требуется чтобы для ONE так и осталось максимум 8, а для WLC стало максимум 16
 * Подробнее в этой задаче https://wimark.kaiten.ru/space/461929/boards/card/29936369
 *
 * Миксин используется в разделах где нужно выбирать SSID для радиоинтерфейсов - раздел с ТД, с шаблонами ТД, визард шаблонов ТД.
 *
 * @mixin
 *
 */

/**
 * Максимальное количество SSID (wi-fi сетей), которое можно выбрать для одного радиоинтерфейса по умаолчанию
 * @type {number}
 */
const MAX_NUMBER_OF_SSIDS_FOR_RADIO_INTERFACE_DEFAULT = 8;

/**
 * Максимальное количество SSID (wi-fi сетей), которое можно выбрать для одного радиоинтерфейса для WLC
 * @type {number}
 */
const MAX_NUMBER_OF_SSIDS_FOR_RADIO_INTERFACE_WLC = 16;


const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const maxNumberOfSSIDsForRadioInterfaceMixin = {
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),

    /**
     * Максимальное количество SSID, которое можно выбрать для радиоинтерфейса
     * Зависит от продукта - WLC или ONE
     * @return {number}
     */
    maxNumberOfSSIDsForRadioInterface() {
      if (this.isWLCProduct) {
        return MAX_NUMBER_OF_SSIDS_FOR_RADIO_INTERFACE_WLC;
      }
      return MAX_NUMBER_OF_SSIDS_FOR_RADIO_INTERFACE_DEFAULT;
    }
  }
};

export default maxNumberOfSSIDsForRadioInterfaceMixin;
