// https://wimark.kaiten.ru/space/461929/boards/card/43834115 - было принято решение полностью убрать EasyInstallWizard
// Если не пригодится в будущем, то следует полностью убрать (23.01.2025)
export default {
  checkStepForUserReditrect(ctx) {
    //  проверяем что записано в сторе и соотвественно с какого шага юзер покинул визард,
    //  возращаем номер шага куда его прокинуть
    if (
      ctx.$store.state.easyInstallWizardStepsData.step1SavedWlanObject &&
      ctx.$store.state.easyInstallWizardStepsData.step1locationId &&
      ctx.$store.state.easyInstallWizardStepsData.step1WlanId &&
      ctx.$store.state.easyInstallWizardStepsData.step2SavedPageObject &&
      ctx.$store.state.easyInstallWizardStepsData.step3RedirectObject &&
      ctx.$store.state.easyInstallWizardStepsData.step3RedirectId &&
      ctx.$store.state.easyInstallWizardStepsData.step4CpesWithWLANIDs
    ) {
      // если все это есть то юзер прошел 4 шага, кидаем его на 5
      return 'EasyInstallWizardStep5';
    }
    if (
      ctx.$store.state.easyInstallWizardStepsData.step1SavedWlanObject &&
      ctx.$store.state.easyInstallWizardStepsData.step1locationId &&
      ctx.$store.state.easyInstallWizardStepsData.step1WlanId &&
      ctx.$store.state.easyInstallWizardStepsData.step2SavedPageObject &&
      ctx.$store.state.easyInstallWizardStepsData.step3RedirectObject &&
      ctx.$store.state.easyInstallWizardStepsData.step3RedirectId
    ) {
      // если все это есть то юзер прошел 3 шага, кидаем его на 4
      return 'EasyInstallWizardStep4';
    }
    if (
      ctx.$store.state.easyInstallWizardStepsData.step1SavedWlanObject &&
      ctx.$store.state.easyInstallWizardStepsData.step1locationId &&
      ctx.$store.state.easyInstallWizardStepsData.step1WlanId &&
      ctx.$store.state.easyInstallWizardStepsData.step2SavedPageObject
    ) {
      // если все это есть то юзер прошел 2 шага, кидаем его на 3
      return 'EasyInstallWizardStep3';
    }
    if (
      ctx.$store.state.easyInstallWizardStepsData.step1SavedWlanObject &&
      ctx.$store.state.easyInstallWizardStepsData.step1locationId &&
      ctx.$store.state.easyInstallWizardStepsData.step1WlanId
    ) {
      // если все это есть то юзер прошел 1 шаг, кидаем его на 2
      return 'EasyInstallWizardStep2';
    }
    return 'EasyInstallWizardStep1';
  }
};
