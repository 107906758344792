<template>
  <div class="vlan-page">
    <VlanTable
      :items="vlans"
      :totalItems="totalVlansCount"
      :perPage="limit"
      :selectedVlanIds="selectedVlanIds"
      :activeRowVlanId="''"
      :sortState="sortParams"
      :isDataLoading="false"
      :isDisabled="isLoading"
      :isOneColMode="false"
      :isSelectionRowsModeEnabled="true"
      @on-cell-click="handleCellClick"
      @on-selected-rows-change="handleSelectRow"
      @on-sort-change="handleSortChange"
      @on-page-change="handlePageChange"
      @on-per-page-change="handlePerPageChange"
    >
      <template v-slot:table-menu>
        <div class="control-panel">
          <div class="control-panel__container left">
            <WButton info :disabled="isLoading" @click="openCreateVlanModal">
              {{ $t('general.add') }}
            </WButton>
            <WButton info :disabled="isDeleteButtonDisabled" @click="openDeleteVlanModal">
              {{ $t('general.delete') }}
            </WButton>
          </div>
        </div>
      </template>
    </VlanTable>

    <CreateVlanModal
      :forbiddenVlanIds="forbiddenVlanIds"
      :forbiddenVlanNames="forbiddenVlanNames"
      :isOpen="isCreateVlanModalOpen"
      @submit="createVlan"
      @close="closeCreateVlanModal"
    />
    <ChangeVlanModal
      :vlanForChanging="selectedVlanForChanging"
      :forbiddenVlanIds="forbiddenVlanIds"
      :forbiddenVlanNames="forbiddenVlanNames"
      :isOpen="isChangeVlanModalOpen"
      @submit="changeVlan"
      @close="closeChangeVlanModal"
    />
    <DeleteVlanModal
      :vlanIdsToDelete="selectedVlanIds"
      :isOpen="isDeleteVlanModalOpen"
      @close="closeDeleteVlanModal"
      @confirm="handleDeleteVlans"
    />
  </div>
</template>

<script>
/**
 * компонент страницы Vlan
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%B2%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B5-VLAN
 * @component
 */

/* eslint-disable camelcase */
import {
  VlanTable,
  CreateVlanModal,
  ChangeVlanModal,
  DeleteVlanModal
} from './components';

import vlanApi from './api';

export default {
  name: 'VlanPage',
  components: {
    VlanTable,
    CreateVlanModal,
    ChangeVlanModal,
    DeleteVlanModal
  },
  props: {},
  data() {
    return {
      vlans: [],
      SVIs: [],
      vlanGroups: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedVlanIds: [],
      selectedVlanForChanging: {},
      totalVlansCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateVlanModalOpen: false,
      isChangeVlanModalOpen: false,
      isDeleteVlanModalOpen: false
    };
  },
  computed: {
    forbiddenVlanNames() {
      const vlanNames = this.vlans.map((vlan) => vlan.name);
      return vlanNames;
    },
    forbiddenVlanIds() {
      const vlanIds = this.vlans.map((vlan) => vlan.vlan_id);
      return vlanIds;
    },
    isDeleteButtonDisabled() {
      // VLAN, для которого создан SVI или который входит в VLAN группы, нельзя удалить.
      // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%A3%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-VLAN
      // 1. если vlan.vlan_id === svi.id_vlan, то удалить данную vlan нельзя.

      const canAllSelectedVlanIdsBeDeletedBySVIMatch = !this.selectedVlanIds
        .some(selectedId => this.SVIs
          .some(svi => svi.id_vlan === selectedId));

      // 2. eсли vlan.vlan_id === vlanGroup.list_id_vlan, то удалить данную vlan нельзя.

      const canAllSelectedVlanIdsBeDeletedByVlanGroupMatch = !this.selectedVlanIds
        .some(selectedId => this.vlanGroups
          .some(vlanGroup => vlanGroup.list_id_vlan === selectedId));

      return this.isLoading
        || !this.selectedVlanIds.length
        || !canAllSelectedVlanIdsBeDeletedBySVIMatch
        || !canAllSelectedVlanIdsBeDeletedByVlanGroupMatch;
    }
  },
  methods: {
    handleCellClick(rowData) {
      const {
        nativeData: { id }
      } = rowData;

      const [selectedVlanForChanging] = this.vlans.filter((vlan) => vlan.id === id);

      this.selectedVlanForChanging = selectedVlanForChanging;

      this.openChangeVlanModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedVlanIds = selectedRowData.map((rowData) => rowData.nativeData.vlan_id);

      this.selectedVlanIds = selectedVlanIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };
      this.getVlans();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalVlansCount) {
        this.offset = 0;
      }
      this.getVlans();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalVlansCount) {
        this.offset = 0;
      }
      this.getVlans();
    },
    handleDeleteVlans() {
      const idsToDelete = this.vlans
        .filter(vlan => this.selectedVlanIds.includes(vlan.vlan_id))
        .map(vlan => vlan.id);

      vlanApi.deleteVlans({
        ids: idsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteVlanModal();
          this.getVlans();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('vlan.responseMessage.deleteVlans.success'),
        errorMessage: this.$t('vlan.responseMessage.deleteVlans.error')
      });
    },
    changeVlan(formValues) {
      const { id } = this.selectedVlanForChanging;

      vlanApi.updateVlan({
        id,
        name: formValues['general.name'],
        vlan_id: formValues['general.vlan_id'],
        admin_status: formValues['general.admin_status']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeVlanModal();
          this.getVlans();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('vlan.responseMessage.updateVlan.success'),
        errorMessage: this.$t('vlan.responseMessage.updateVlan.error')
      });
    },
    createVlan(formValues) {
      // if general.creationMode === "single" then createSingleVlan
      // if general.creationMode === "multiple" then createMultipleVlans

      const onLoading = () => {
        this.isLoading = true;
      };

      const onSuccess = () => {
        this.isLoading = false;
        this.closeCreateVlanModal();
        this.getVlans();
      };

      const onError = () => {
        this.isLoading = false;
      };

      const successMessage = this.$t('vlan.responseMessage.createVlan.success');
      const errorMessage = this.$t('vlan.responseMessage.createVlan.error');

      const createSingleVlan = (requestParams) => {
        vlanApi.createVlan(requestParams, {
          onLoading,
          onSuccess,
          onError,
          successMessage,
          errorMessage
        });
      };

      const createMultipleVlans = (requestParams) => {
        vlanApi.bulkCreateVlan(requestParams, {
          onLoading,
          onSuccess,
          onError,
          successMessage,
          errorMessage
        });
      };

      switch (formValues['general.creationMode']) {
        case 'multiple':
          createMultipleVlans({
            initial_vlan_id: formValues['general.initial_vlan_id'],
            last_vlan_id: formValues['general.last_vlan_id']
          });
          break;
        case 'single':
        default:
          createSingleVlan({
            name: formValues['general.name'],
            vlan_id: formValues['general.vlan_id'],
            admin_status: formValues['general.admin_status']
          });
      }
    },
    getVlans() {
      vlanApi.getVlans({
        limit: this.limit,
        offset: this.offset,
        // // если надо будет реализовать фильтрацию, то использовать filterByName, filterByVlanId
        // filterByName: undefined,
        // filterByVlanId: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            vlans
          } = data;

          this.vlans = vlans;
          this.totalVlansCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedVlanIds = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getSVIs() {
      vlanApi.getSVIs({
        limit: this.limit,
        offset: this.offset
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            svis
          } = data;

          this.SVIs = svis;

          this.isLoading = false;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getVlanGroups() {
      vlanApi.getVlanGroups({
        limit: this.limit,
        offset: this.offset
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            vlan_groups
          } = data;

          this.vlanGroups = vlan_groups;

          this.isLoading = false;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateVlanModal() {
      this.isCreateVlanModalOpen = true;
    },
    closeCreateVlanModal() {
      this.isCreateVlanModalOpen = false;
    },
    openChangeVlanModal() {
      this.isChangeVlanModalOpen = true;
    },
    closeChangeVlanModal() {
      this.isChangeVlanModalOpen = false;
      this.selectedVlanForChanging = {};
    },
    openDeleteVlanModal() {
      this.isDeleteVlanModalOpen = true;
    },
    closeDeleteVlanModal() {
      this.isDeleteVlanModalOpen = false;
    }
  },
  mounted() {
    this.getVlans();
    this.getSVIs();
    this.getVlanGroups();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
