/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_BULK_CREATE_VLANS_URL } from '../config';

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/512163876/FT23.10.13+VLAN+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {import('../types.js').BulkCreateVlansRequestData_FRONTEND} BulkCreateVlansRequestData_FRONTEND
 * @typedef {import('../types.js').BulkCreateVlansRequestData_BACKEND} BulkCreateVlansRequestData_BACKEND
 */

/**
 * Функция-фетчер для создания новых vlan-ов балком.
 *
 * @param {BulkCreateVlansRequestData_FRONTEND} requestParams - Настройки для создания новых vlan-ов балком.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const postVlansFetcher = async (requestParams) => {
  const {
    initial_vlan_id,
    last_vlan_id
  } = requestParams;

  /**
   * @type {BulkCreateVlansRequestData_BACKEND}
   */
  const payload = {
    initial_vlan_id: String(initial_vlan_id),
    last_vlan_id: String(last_vlan_id)
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_BULK_CREATE_VLANS_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postVlansFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {BulkCreateVlansRequestData_FRONTEND} requestParams - Настройки vlan.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @param {string} [options.errorMessage] - Сообщение об ошибке выполнении запроса.
 * @returns {Promise<void>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const bulkCreateVlan = async (requestParams, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage,
    errorMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await postVlansFetcher(requestParams);

    store.commit('setSuccess', successMessage);
    onSuccess();
    return Promise.resolve();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', errorMessage);
  }
};

export default bulkCreateVlan;
