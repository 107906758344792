<template>
  <div>
    <WCard class="table-card">
      <WlanPolicyTable
        :items="wlanPolicyProfiles"
        :totalItems="totalWlanPoliciesCount"
        :perPage="limit"
        :selectedWlanPolicyIds="selectedWlanPolicyIds"
        :activeRowWlanPolicyId="''"
        :sortState="sortParams"
        :isDataLoading="false"
        :isDisabled="isLoading"
        :isOneColMode="false"
        :isSelectionRowsModeEnabled="true"
        @on-cell-click="handleCellClick"
        @on-selected-rows-change="handleSelectRow"
        @on-sort-change="handleSortChange"
        @on-page-change="handlePageChange"
        @on-per-page-change="handlePerPageChange"
      >
        <template v-slot:table-menu>
          <div class="control-panel">
            <div class="control-panel__container left">
              <WButton info
                :disabled="isLoading"
                @click="openCreateWlanPolicyModal"
              >
                {{ $t('general.add') }}
              </WButton>
              <WButton info
                :disabled="isLoading || selectedWlanPolicyIds.length !== 1"
                @click="openCloneWlanPolicyModal"
              >
                {{ $t('general.clone') }}
              </WButton>
              <WButton info
                :disabled="isLoading || !selectedWlanPolicyIds.length"
                @click="openDeleteWlanPolicyModal"
              >
                {{ $t('general.delete') }}
              </WButton>
            </div>
            <div class="control-panel__container right">
            </div>
          </div>
        </template>
      </WlanPolicyTable>
    </WCard>

    <CreateWlanPolicyModal
      :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
      :aclOptions="aclOptions"
      :vlanOptions="vlanOptions"
      :vlanGroupOptions="vlanGroupOptions"
      :policyMapOptions="policyMapOptions"
      :eogreProfileOptions="eogreProfileOptions"
      :isOpen="isCreateWlanPolicyModalOpen"
      @submit="createWlanPolicyProfile"
      @close="closeCreateWlanPolicyModal"
    />

    <ChangeWlanPolicyModal
      :wlanPolicyForChanging="selectedWlanPolicyForChanging"
      :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
      :aclOptions="aclOptions"
      :vlanOptions="vlanOptions"
      :vlanGroupOptions="vlanGroupOptions"
      :policyMapOptions="policyMapOptions"
      :eogreProfileOptions="eogreProfileOptions"
      :isOpen="isChangeWlanPolicyModalOpen"
      @submit="updateWlanPolicy"
      @close="closeChangeWlanPolicyModal"
    />

    <CloneWlanPolicyModal
      :wlanPolicyForCloning="selectedWlanPolicyForCloning"
      :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
      :aclOptions="aclOptions"
      :vlanOptions="vlanOptions"
      :vlanGroupOptions="vlanGroupOptions"
      :policyMapOptions="policyMapOptions"
      :eogreProfileOptions="eogreProfileOptions"
      :isOpen="isCloneWlanPolicyModalOpen"
      @submit="createWlanPolicyProfile"
      @close="closeCloneWlanPolicyModal"
    />

    <DeleteWlanPolicyModal
      :wlanPolicyIdsToDelete="selectedWlanPolicyIds"
      :isOpen="isDeleteWlanPolicyModalOpen"
      @close="closeDeleteWlanPolicyModal"
      @confirm="handleDeleteWlanPolicies"
    />
  </div>
</template>

<script>
/**
 * компонент страницы настройки Wlan Policy (WLC)
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398188/FT23.10.1+front
 * @component
 */

import {
  WlanPolicyTable,
  CreateWlanPolicyModal,
  ChangeWlanPolicyModal,
  CloneWlanPolicyModal,
  DeleteWlanPolicyModal
} from './components';

import wlanPolicyApi from './api';

/* eslint-disable camelcase */

export default {
  name: 'WlanPolicyPage',
  components: {
    WlanPolicyTable,
    CreateWlanPolicyModal,
    ChangeWlanPolicyModal,
    CloneWlanPolicyModal,
    DeleteWlanPolicyModal
  },
  props: {
  },
  data() {
    return {
      wlanPolicyProfiles: [],
      l2Chains: [],
      vlans: [],
      vlanGroups: [],
      policyMaps: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedWlanPolicyIds: [],
      selectedWlanPolicyForChanging: {},
      selectedWlanPolicyForCloning: {},
      totalWlanPoliciesCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateWlanPolicyModalOpen: false,
      isChangeWlanPolicyModalOpen: false,
      isCloneWlanPolicyModalOpen: false,
      isDeleteWlanPolicyModalOpen: false
    };
  },
  computed: {
    forbiddenWlanPolicyNames() {
      return this.wlanPolicyProfiles.map(wlanPolicy => wlanPolicy.name);
    },
    aclOptions() {
      return this.l2Chains.map(l2Chain => ({
        name: l2Chain.name,
        value: l2Chain.id
      }));
    },
    vlanOptions() {
      return this.vlans.map(vlan => ({
        name: vlan.name,
        value: vlan.vlan_id
      }));
    },
    vlanGroupOptions() {
      return this.vlanGroups.map(vlanGroup => ({
        name: vlanGroup.name,
        value: vlanGroup.id
      }));
    },
    policyMapOptions() {
      return this.policyMaps.map(policyMaps => ({
        name: policyMaps.name,
        value: policyMaps.id
      }));
    },
    eogreProfileOptions() {
      // todo пока не понятно какие селекты и можно ли их будет выбирать
      // пока что остается пустым, а поле eogre_profile скрыто. В будущем появится и ручка, и поле будет доступно в ui
      // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398188/FT23.10.1+front?focusedCommentId=625279040
      return [];
    }
  },
  methods: {
    handleCellClick(rowData) {
      const { nativeData: { id } } = rowData;

      const [selectedWlanPolicyForChanging] = this.wlanPolicyProfiles
        .filter(branchProfile => branchProfile.id === id);

      this.selectedWlanPolicyForChanging = selectedWlanPolicyForChanging;

      this.openChangeWlanPolicyModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedWlanPolicyIds = selectedRowData.map(rowData => rowData.nativeData.id);

      if (selectedWlanPolicyIds.length === 1) {
        const [selectedWlanPolicyForCloning] = this.wlanPolicyProfiles
          .filter(wlanPolicy => wlanPolicy.id === selectedWlanPolicyIds[0]);

        this.selectedWlanPolicyForCloning = selectedWlanPolicyForCloning;
      }

      if (selectedWlanPolicyIds.length !== 1) {
        this.selectedWlanPolicyForCloning = {};
      }

      this.selectedWlanPolicyIds = selectedWlanPolicyIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };

      this.getWlanPolicyProfiles();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalWlanPoliciesCount) {
        this.offset = 0;
      }

      this.getWlanPolicyProfiles();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalWlanPoliciesCount) {
        this.offset = 0;
      }

      this.getWlanPolicyProfiles();
    },
    getL2Chains() {
      wlanPolicyApi.getL2Chains({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (l2Chains) => {
          this.isLoading = false;
          this.l2Chains = l2Chains;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getVlans() {
      wlanPolicyApi.getVlans({}, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: ({ vlans }) => {
          this.isLoading = false;
          this.vlans = vlans;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getVlanGroups() {
      wlanPolicyApi.getVlanGroups({}, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: ({ vlan_groups }) => {
          this.isLoading = false;
          this.vlanGroups = vlan_groups;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getPolicyMaps() {
      wlanPolicyApi.getPolicyMaps({}, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: ({ policy_maps }) => {
          this.isLoading = false;
          this.policyMaps = policy_maps;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    handleDeleteWlanPolicies() {
      const wlanPolicyIdsToDelete = this.selectedWlanPolicyIds;

      wlanPolicyApi.deleteWlanPolicies({
        ids: wlanPolicyIdsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteWlanPolicyModal();
          this.getWlanPolicyProfiles();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlanPolicy.responseMessage.deleteWlanPolicies.success'),
        errorMessage: this.$t('wlanPolicy.responseMessage.deleteWlanPolicies.error')
      });
    },
    updateWlanPolicy(formValues) {
      const { id } = this.selectedWlanPolicyForChanging;

      wlanPolicyApi.updateWlanPolicy({
        id,
        name: formValues['general.name'],
        description: formValues['general.description'],
        profile_enabled: formValues['general.profile_enabled'],
        central_switch_enabled: formValues['general.central_switch_enabled'],
        central_auth_enabled: formValues['general.central_auth_enabled'],
        acl: formValues['accessPolicies.acl'],
        id_vlan: formValues['accessPolicies.id_vlan'],
        id_vlan_group: formValues['accessPolicies.id_vlan_group'],
        id_policy_map_client_in: formValues['qos.id_policy_map_client_in'],
        id_policy_map_client_out: formValues['qos.id_policy_map_client_out'],
        id_policy_map_ssid_in: formValues['qos.id_policy_map_ssid_in'],
        id_policy_map_ssid_out: formValues['qos.id_policy_map_ssid_out'],
        session_timeout: formValues['advanced.session_timeout'],
        idle_timeout: formValues['advanced.idle_timeout'],
        idle_threshold: formValues['advanced.idle_threshold'],
        exclusion_timeout: formValues['advanced.exclusion_timeout'],
        aaa_override_enabled: formValues['advanced.aaa_override_enabled'],
        proxy_arp_enabled: formValues['advanced.proxy_arp_enabled'],
        id_eogre_profile: formValues['advanced.id_eogre_profile'],
        nas_id_1: formValues['advanced.nas_id_1'],
        nas_id_1_custom: formValues['advanced.nas_id_1_custom'],
        nas_id_2: formValues['advanced.nas_id_2'],
        nas_id_2_custom: formValues['advanced.nas_id_2_custom'],
        nas_id_3: formValues['advanced.nas_id_3'],
        nas_id_3_custom: formValues['advanced.nas_id_3_custom'],
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeWlanPolicyModal();
          this.getWlanPolicyProfiles();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlanPolicy.responseMessage.updateWlanPolicy.success'),
        errorMessage: this.$t('wlanPolicy.responseMessage.updateWlanPolicy.error')
      });
    },
    createWlanPolicyProfile(formValues) {
      wlanPolicyApi.createWlanPolicy({
        name: formValues['general.name'],
        description: formValues['general.description'],
        profile_enabled: formValues['general.profile_enabled'],
        central_switch_enabled: formValues['general.central_switch_enabled'],
        central_auth_enabled: formValues['general.central_auth_enabled'],
        acl: formValues['accessPolicies.acl'],
        id_vlan: formValues['accessPolicies.id_vlan'],
        id_vlan_group: formValues['accessPolicies.id_vlan_group'],
        id_policy_map_client_in: formValues['qos.id_policy_map_client_in'],
        id_policy_map_client_out: formValues['qos.id_policy_map_client_out'],
        id_policy_map_ssid_in: formValues['qos.id_policy_map_ssid_in'],
        id_policy_map_ssid_out: formValues['qos.id_policy_map_ssid_out'],
        session_timeout: formValues['advanced.session_timeout'],
        idle_timeout: formValues['advanced.idle_timeout'],
        idle_threshold: formValues['advanced.idle_threshold'],
        exclusion_timeout: formValues['advanced.exclusion_timeout'],
        aaa_override_enabled: formValues['advanced.aaa_override_enabled'],
        proxy_arp_enabled: formValues['advanced.proxy_arp_enabled'],
        id_eogre_profile: formValues['advanced.id_eogre_profile'],
        nas_id_1: formValues['advanced.nas_id_1'],
        nas_id_1_custom: formValues['advanced.nas_id_1_custom'],
        nas_id_2: formValues['advanced.nas_id_2'],
        nas_id_2_custom: formValues['advanced.nas_id_2_custom'],
        nas_id_3: formValues['advanced.nas_id_3'],
        nas_id_3_custom: formValues['advanced.nas_id_3_custom'],
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeCreateWlanPolicyModal();
          this.closeCloneWlanPolicyModal();
          this.getWlanPolicyProfiles();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('wlanPolicy.responseMessage.createWlanPolicyProfile.success'),
        errorMessage: this.$t('wlanPolicy.responseMessage.createWlanPolicyProfile.error')
      });
    },
    getWlanPolicyProfiles() {
      wlanPolicyApi.getWlanPolicies({
        limit: this.limit,
        offset: this.offset,
        // // если надо будет реализовать фильтрацию, то использовать filterByName, filterByDescription
        // filterByName: undefined,
        // filterByDescription: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            wlan_policy_profiles
          } = data;

          this.wlanPolicyProfiles = wlan_policy_profiles;
          this.totalWlanPoliciesCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedWlanPolicyIds = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateWlanPolicyModal() {
      this.isCreateWlanPolicyModalOpen = true;
    },
    closeCreateWlanPolicyModal() {
      this.isCreateWlanPolicyModalOpen = false;
    },
    openChangeWlanPolicyModal() {
      this.isChangeWlanPolicyModalOpen = true;
    },
    closeChangeWlanPolicyModal() {
      this.isChangeWlanPolicyModalOpen = false;
      this.selectedWlanPolicyForChanging = {};
    },
    openCloneWlanPolicyModal() {
      this.isCloneWlanPolicyModalOpen = true;
    },
    closeCloneWlanPolicyModal() {
      this.isCloneWlanPolicyModalOpen = false;
    },
    openDeleteWlanPolicyModal() {
      this.isDeleteWlanPolicyModalOpen = true;
    },
    closeDeleteWlanPolicyModal() {
      this.isDeleteWlanPolicyModalOpen = false;
    }
  },
  mounted() {
    this.getWlanPolicyProfiles();
    this.getL2Chains();
    this.getVlans();
    this.getVlanGroups();
    this.getPolicyMaps();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
