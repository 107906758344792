/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {boolean} isVisible - Указывает, отображается или скрыто поле.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Основные настройки.
 * @property {FieldConfig} general.name
 * @property {FieldConfig} general.list_vlan_id
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    name: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true,
      isVisible: true
    },
    list_vlan_id: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true,
      isVisible: true
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */

function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    // Проверяем, если это массив, заменяем массив целиком
    if (Array.isArray(value)) {
      result[key] = value;
    } else if (value instanceof Object && key in target) {
      // Рекурсивно обрабатываем объекты
      result[key] = mergeDeep(target[key], value);
    } else {
      // Заменяем значение для простых типов
      result[key] = value;
    }
  });

  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 * @property {string|number|boolean} [radioValue] - value для radio инпута.
 * @property {boolean} isVisible - Указывает, отображается или скрыто поле.
 * @property {string} [ref] - текстовое значение для ref-ссылки. Обычно может использоваться при валидации зависимых инпутов.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} [id] - Уникальный идентификатор для группы содержимого.
 * @property {string} label - Метка для группы содержимого.
 * @property {string} [name] - Имя для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для general вкладки.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      name,
      list_vlan_id
    }
  } = mergedValues;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('vlanGroup.tabLabel.general'),
      name: 'general',
      content: [
        {
          id: uuidv4(),
          label: '',
          name: 'general',
          inputs: [
            {
              id: uuidv4(),
              label: $t('vlanGroup.inputLabel.name'),
              name: 'general.name',
              inputType: 'text',
              validateRules: `${name.isRequired ? 'required|' : ''}vlanGroupNameMask|uniqueVlanGroupName|max:120`,
              isDisabled: name.isDisabled,
              placeholder: '',
              defaultValue: name.defaultValue,
              isVisible: name.isVisible
            },
            {
              id: uuidv4(),
              label: $t('vlan.inputLabel.list_vlan_id'),
              name: 'general.list_vlan_id',
              inputType: 'text',
              validateRules: `${list_vlan_id.isRequired ? 'required|' : ''}vlanGroupListVlanIdMask`,
              isDisabled: list_vlan_id.isDisabled,
              placeholder: $t('vlan.inputPlaceholder.list_vlan_id'),
              defaultValue: list_vlan_id.defaultValue,
              isVisible: list_vlan_id.isVisible
            }
          ]
        }
      ],
      childrenTabs: []
    }
  ];

  return formConfig;
}

export default generateFormConfig;
