<template>
  <WlanPolicyForm
    :formConfig="formConfig"
    :forbiddenWlanPolicyNames="forbiddenWlanPolicyNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </WlanPolicyForm>
</template>

<script>
/**
 * компонент настроенной формы WlanPolicyForm через generateFormConfig для создания нового wlan policy.
 * ! Компонент локальный, используется только в CreateWlanPolicyModal.vue
 * @component
 */

import {
  WlanPolicyForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateWlanPolicyForm',
  components: {
    WlanPolicyForm
  },
  props: {
    forbiddenWlanPolicyNames: {
      type: Array,
      default: () => []
    },
    aclOptions: {
      type: Array,
      default: () => []
    },
    vlanOptions: {
      type: Array,
      default: () => []
    },
    vlanGroupOptions: {
      type: Array,
      default: () => []
    },
    policyMapOptions: {
      type: Array,
      default: () => []
    },
    eogreProfileOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const aclSelectOptions = this.aclOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const vlanSelectOptions = this.vlanOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const vlanGroupSelectOptions = this.vlanGroupOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const policyMapSelectOptions = this.policyMapOptions.map(({ name, value }) => ({
      display: name, data: value
    }));
    const eogreProfileSelectOptions = this.eogreProfileOptions.map(({ name, value }) => ({
      display: name, data: value
    }));

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        accessPolicies: {
          acl: {
            selectOptions: aclSelectOptions
          },
          id_vlan: {
            selectOptions: vlanSelectOptions
          },
          id_vlan_group: {
            selectOptions: vlanGroupSelectOptions
          }
        },
        qos: {
          id_policy_map_client_in: {
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_client_out: {
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_ssid_in: {
            selectOptions: policyMapSelectOptions
          },
          id_policy_map_ssid_out: {
            selectOptions: policyMapSelectOptions
          }
        },
        advanced: {
          id_eogre_profile: {
            selectOptions: eogreProfileSelectOptions
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
