// selected checkbox of a row or not
function getSelectedRowValue(currentSVIId, selectedSVIIdsArray) {
  let value = false;

  selectedSVIIdsArray.forEach(SVIId => {
    if (SVIId === currentSVIId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
