<template>
  <SidebarWrapper :class="[isMenuCollapsed ? 'collapsed' : 'opened']">
    <template v-slot:heading>
      <div class="header-content">
        <CPULoadBadge
          v-if="isShowCPULoadBadge"
          :isCollapsed="isMenuCollapsed"
        />
      </div>
    </template>
    <template v-slot:content>
      <nav>
        <Menu
          :menuItems="menuConfigList"
          :isCollapsed="isMenuCollapsed"
        />
      </nav>
    </template>
    <template v-slot:footer>
      <ProductBuildInfo v-if="isShowProductBuildInfo"/>
    </template>
  </SidebarWrapper>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { getMenuNavigationConfig, BUILD_VERSION, BUILD_DATE } from '@/config';

import {
  SidebarWrapper,
  CPULoadBadge,
  ProductBuildInfo,
  Menu
} from './components';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: menuMapGetters } = createNamespacedHelpers('menu');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'SidebarNewStructure',
  components: {
    SidebarWrapper,
    CPULoadBadge,
    ProductBuildInfo,
    Menu
  },
  data() {
    return {};
  },
  computed: {
    // ! Обратить внимание, чтобы набор всех этих флагов (brandingMapGetters, featureFlagsMapGetters, WLCProductMapGetters
    // и возможно других, если они используются внутри getMenuNavigationConfig) был одинаков и в
    // src/components/BreadcrumbsNavigation/BreadcrumbsNavigation.vue и в
    // src/components/SidebarNewStructure/SidebarNewStructure.vue
    // getMenuNavigationConfig вызывается и там и там, передается как один инстанс, так и другой (в thisInstance: this)
    // И, например, если в одном из компонентов есть флаг isWLCProduct, а в другом нет, то
    // в меню какой-либо из пунктов может не отображаться, а в breadcrumbs отображаться. Или наоборот
    ...brandingMapGetters(['isBeelineBranding']),
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters([
      'isContentPortalEnabled',
      'isContentPortalPaidInternetEnabled',
      'isPortalOnlyModeEnabled',
      'isContentAnalyticsEnabled',
      'isOneExperimentalModeEnabled',
      'isWlcExperimentalModeEnabled',
      'isCiscoModeEnabled'
    ]),
    ...WLCProductMapGetters(['isWLCProduct']),
    ...menuMapGetters(['isMenuCollapsed']),
    menuConfigList() {
      // настрофка и формирование конфига меню
      return getMenuNavigationConfig({
        i18nInstance: this.$i18n,
        thisInstance: this,
        currentPath: this.$route.path
      });
    },

    // roles
    isMarketerRole() {
      return this.$store.state.userData.role === 'marketer';
    },

    isOperatorRole() {
      return this.$store.state.userData.role === 'operator';
    },
    isAdminRole() {
      return this.$store.state.userData.role === 'admin';
    },
    isAdvertisingRole() {
      return this.$store.state.userData.role === 'advertising';
    },

    currentUserLocationData() {
      const locations = this.$store.state.locationsItems;
      const currentUserBaseLocation = this.$store.state.userData.base_location;

      const isCurrentUserBaseLocationInLocations = Object.prototype.hasOwnProperty.call(locations, currentUserBaseLocation);

      if (isCurrentUserBaseLocationInLocations) {
        return locations[currentUserBaseLocation];
      }
      return {};
    },
    isSuperAdmin() {
      // суперадмины - юзеры с ролью admin и локацией '/'

      const userLocation = this.currentUserLocationData.name;

      return this.isAdminRole && userLocation === '/';
    },
    isShowCPULoadBadge() {
      return this.$store.state.systemTotalObjList
        && this.$store.state.systemTotalObjList.system.load
        && !this.isMarketerRole;
    },
    isShowProductBuildInfo() {
      // показываем служебную инфу в футере, если:
      // 1. если не свернуто меню
      // 2. если это не билайн-суперадмин
      const isBeelineSuperAdmin = this.isBeelineBranding && this.isSuperAdmin;

      const condition = !this.isMenuCollapsed && !isBeelineSuperAdmin;

      return condition;
    }
  },
  methods: {
  },
  created() {
    this.BUILD_VERSION = BUILD_VERSION;
    this.BUILD_DATE = BUILD_DATE;
  }
};
</script>

<style lang="css" scoped>
.opened {
  max-width: 230px;
  padding: 24px 12px;
}

.collapsed {
  align-items: center;
  width: fit-content;
  padding: 16px 8px;
}

.header-content {
  width: 100%;
  display: flex;
}
</style>
