/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента SVIForm.
 */

import sviApi from '../../../api';

// Функция для проверки уникальности vlan_id на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isVlanIdByApiRequest = async idToCheck => {
  let result;

  await sviApi.getVlanByVlanId(idToCheck, {
    onSuccess: (data) => {
      result = true;
    },
    onError: (error) => {
      result = false;
    }
  });

  return result;
};

// Функция для проверки уникальности svi name на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueSVINameByApiRequest = async (nameToCheck) => {
  let result;

  await sviApi.getSVIByName(nameToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });
  return result;
};

const getSVIFormValidators = (i18nInstance) => ({
  isVlanId: (forbiddenVlanIds) => ({
    getMessage: (field) => {
      const message = i18nInstance.t('svi.inputError.isVlanId');
      return message;
    },
    validate: async (value) => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isForbiddenFromStaticList = forbiddenVlanIds
        .map((value) => String(value).toLocaleLowerCase())
        .includes(String(value).toLocaleLowerCase());

      if (isForbiddenFromStaticList) {
        return false;
      }
      
      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isVlanIdByApiRequest(value);
      return isUniqueByApiRequest;
    }
  }),

  //svi name проверка на уникальность
  uniqueSVIName: (forbiddenSVINames) => ({
    getMessage: (field) => {
      const message = i18nInstance.t('svi.inputError.uniqueSVIName');
      return message;
    },
    validate: async (value) => {
      // генерируем SVIname: “Vlan” + введённый идентификатор VLAN, нужно для проверки уникальности SVI
      // ft: https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%94%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-SVI
      const SVIName = 'Vlan' + value;
      //1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenSVINames
        .map((value) => String(value).toLocaleLowerCase())
        .includes(String(SVIName).toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueSVINameByApiRequest(SVIName);
      return isUniqueByApiRequest;
    }
  }),

  descriptionMask: () => ({
    getMessage: (field) => {
      const message = i18nInstance.t('svi.inputError.descriptionMask');
      return message;
    },
    validate: (value) => {
      const customValidationRegex = /^[a-zA-Zа-яА-Я0-9!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}]*$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  })
});

export { getSVIFormValidators };
