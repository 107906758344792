/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_CREATE_VLAN_GROUP_URL } from '../config';
import {
  vlanGroupDataTypesConverterForBackend
} from '../utils';

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/512294969/FT23.10.21+VLAN+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {import('../types.js').CreateVlanGroupRequestData} CreateVlanGroupRequestData
 */

/**
 * Функция-фетчер для создания нового vlan group.
 *
 * @param {CreateVlanGroupRequestData} requestParams - Настройки vlan group.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const postVlanGroupFetcher = async (requestParams) => {
  const payload = {
    vlan_group: vlanGroupDataTypesConverterForBackend(requestParams)
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_CREATE_VLAN_GROUP_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postVlanGroupFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {CreateWlanProfileRequestData} requestParams - Настройки vlan group.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @param {string} [options.errorMessageDefault] - Сообщение об ошибке выполнении запроса.
 * @param {string} [options.errorMessageInvalidRange] - Сообщение об ошибке при некорректном vlan range.
 * @returns {Promise<void>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const createVlanGroup = async (requestParams, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage,
    errorMessageDefault,
    errorMessageInvalidRange
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await postVlanGroupFetcher(requestParams);

    store.commit('setSuccess', successMessage);
    onSuccess();
    return Promise.resolve();
  } catch (error) {
    const { code, description, details } = error.response.data;

    if (onError) {
      onError(error);
    }

    const isInvalidRangeError = code === 400 && (description === 'Invalid VLAN range' || description === 'Non existing VLANs');

    if (isInvalidRangeError) {
      store.commit('setError', `${errorMessageInvalidRange} ${details.range}`);
    } else {
      store.commit('setError', errorMessageDefault);
    }
  }
};

export default createVlanGroup;
