/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы VlanGroupTable.vue
 */

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
 */

import { getSelectedRowValue } from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('vlanGroup.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('vlanGroup.tableHeading.list_vlan_id'),
      field: 'list_vlan_id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.list_vlan_id
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
 */

function generateTableRows({ vlanGroupsData, selectedVlanGroupIds }) {
  return vlanGroupsData.map((vlanGroup) => {
    const {
      id,
      list_vlan_id,
      list_id_vlan,
      name
    } = vlanGroup;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: vlanGroup,
      name,
      list_vlan_id,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedVlanGroupIds)
    };
  });
}

export { generateTableColumns, generateTableRows };
