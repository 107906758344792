<template>
  <div class="p-0 dashboard">
    <DashboardBlocksVisibilitySettings></DashboardBlocksVisibilitySettings>
    <div v-if="isCurrentUserMarketer">
      <!--      <div v-if="dashboardLoadingCount || isMarketerDashboardLoading"><Loader-spinner centered></Loader-spinner></div>-->
      <!--      <div v-if="!dashboardLoadingCount && !isMarketerDashboardLoading" class="animated fadeIn">-->
      <div class="animated fadeIn">
        <transition name="fade"
          v-if="!isWLCProduct"
        >
          <dashboard-recommendations
            @moveRecommendationsBottom="moveRecommendationsBottom"
            @moveRecommendationsTop="moveRecommendationsTop"
            :dashboard-recommendations-on-the-dashboard-top="dashboardRecommendationsOnTheDashboardTop"
            v-if="!dashboardLoadingCount && !isMarketerDashboardLoading && dashboardRecommendationsOnTheDashboardTop"
            class="row dashboard-row-padding mt-1 cards-fix"
          />
        </transition>
        <div class="row row-flex">
          <!-- All locations -->
          <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div  class="w-100" v-if="areLocationsVisible && ckeckBlockVisibilityStatus('marketer','marketerViewLocations' )">
          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'locations')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'locations')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewLocations"
            :header-title="$t('dashboard.locations')"
            card-accent="info"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewLocations'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <router-link
                v-if="$can('read', { route: 'Location' })"
                class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                to="/settings/location"
              >
                {{ $t('dashboard.toLocations') }}
              </router-link>
            </template>
            <template #body>
              <div class="no-data" v-if="allLocations.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="allLocations.length > 0">
                <!--                <img src="/static/img/user_map.svg" class="dashboar-icon" alt />-->
                <div class="dashboard-total">
                  <div class="dashboard-total-header">{{ $t('dashboard.locationsTotal') }}</div>
                  <div class="dashboard-total-text">{{ allLocations.length }}</div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3" v-for="location in allLocations.slice(0, 3)" :key="location.id">
                <h4 class="dashboard-title mb-0">
                  <router-link
                    v-if="$can('read', { route: 'Location' })"
                    class="ml-auto one-line-text"
                    :to="{ name: 'Location', params: { locationId: location.id } }"
                  >
                    {{ location.name }}
                  </router-link>
                </h4>
                <small>
                  <span class="text-muted">{{ location.id }}</span>
                </small>
                <div class="callout callout-info">
                  <p class="mb-0 mt-1">{{ $t('dashboard.locationOwner') }}: {{ getUserNameByID(location.owner) }}</p>
                  <p class="mb-0">{{ $t('dashboard.locationManager') }}: {{ getUserNameByID(location.manager) }}</p>
                  <p class="mb-0">{{ $t('dashboard.locationAddress') }}: {{ location.address }}</p>
                </div>
                <small class="text-muted">
                  <i class="fa fa-clock-o"></i>
                  {{ $t('dashboard.locationCreated') }}: {{ location.create_at | momentForLocation }}
                </small>
                <p></p>
                <span></span>
              </div>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="info">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
            </div>
          </transition>

                    <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="w-100" v-if="areLocationsVisible && ckeckBlockVisibilityStatus('marketer','marketerViewlocationsWhereManager' )">
          <!-- My locations -->
          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'locationsWhereManager')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'locationsWhereManager')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewlocationsWhereManager"
            :header-title="$t('dashboard.locationsWhereManager')"
            :header-caption="$t('dashboard.locationsWhereManagerCaption')"
            card-accent="info"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewlocationsWhereManager'">
              </DashboardBlockCloseButton>
            </template>
            <template #body>
              <div class="no-data" v-if="allLocationsForCurrentMarketer.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="allLocationsForCurrentMarketer.length > 0">
                <!--                <img src="/static/img/user_map.svg" class="dashboar-icon" alt />-->
                <div class="dashboard-total">
                  <div class="dashboard-total-header">{{ $t('dashboard.locationsTotal') }}</div>
                  <div class="dashboard-total-text">{{ allLocationsForCurrentMarketer.length }}</div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3" v-for="location in allLocationsForCurrentMarketer" :key="location.id">
                <h4 class="dashboard-title mb-0">
                  <router-link
                    v-if="$can('read', { route: 'Location' })"
                    class="ml-auto one-line-text"
                    :to="{ name: 'Location', params: { locationId: location.id } }"
                  >
                    {{ location.name }}
                  </router-link>
                </h4>
                <small>
                  <span class="text-muted">{{ location.id }}</span>
                </small>
                <div class="callout callout-info">
                  <p class="mb-0 mt-1">{{ $t('dashboard.locationOwner') }}: {{ getUserNameByID(location.owner) }}</p>
                  <p class="mb-0">{{ $t('dashboard.locationManager') }}: {{ getUserNameByID(location.manager) }}</p>
                  <p class="mb-0">{{ $t('dashboard.locationAddress') }}: {{ location.address }}</p>
                </div>
                <small class="text-muted">
                  <i class="fa fa-clock-o"></i>
                  {{ $t('dashboard.locationCreated') }}: {{ location.create_at | momentForLocation }}
                </small>
                <p></p>
                <span></span>
              </div>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="info">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
          </div>
                    </transition>

                    <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="w-100" v-if="areLocationsVisible && ckeckBlockVisibilityStatus('marketer','marketerViewLocationsMap' ) &&  isShowMapsAndLatitudeLongitudeSettings">
          <!-- Map -->

          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'mapOfLocations')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'mapOfLocations')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewLocationsMap"
            :header-title="$t('dashboard.locationsMap')"
            :header-caption="$t('dashboard.locationsMapCaption')"
            card-accent="info"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewLocationsMap'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <router-link
                v-if="$can('read', { route: 'Location' })"
                class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                to="/settings/location"
              >
                {{ $t('dashboard.toLocations') }}
              </router-link>
            </template>
            <template #body>
              <map-for-locations
                @markerClick="onLocationsMapMarkerClick"
                :locationsWithCoords="locationsWithCoords"
              ></map-for-locations>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="info">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
          </div>
                    </transition>

                    <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="w-100" v-if="ckeckBlockVisibilityStatus('marketer','marketerViewAds' )" >
          <!-- Ads -->
          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'advertisement')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'advertisement')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewAds"
            :header-title="$t('dashboard.ads')"
            card-accent="warning"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewAds'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <router-link
                v-if="$can('read', { route: 'PortalAdsNew' })"
                class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                to="/portal/ads"
              >
                {{ $t('dashboard.toAds') }}
              </router-link>
            </template>
            <template #body>
              <div
                class="no-data"
                v-if="adsForMarketerDashboard.length === 0 || adsDataForMarketerDashboard.length === 0"
              >
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="adsDataForMarketerDashboard.length > 0">
                <!--                <img src="/static/img/user_map.svg" class="dashboar-icon" alt />-->
                <div class="dashboard-total">
                  <div class="dashboard-total-header">{{ $t('dashboard.adsTotal') }}</div>
                  <div class="dashboard-total-text">{{ adsDataForMarketerDashboard.length }}</div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3" v-for="ad in adsForMarketerDashboard" :key="ad.id">
                <h4 class="dashboard-title mb-0">
                  <router-link
                    v-if="$can('read', { route: 'PortalAdsNew' })"
                    class="ml-auto one-line-text"
                    :to="{ name: 'PortalAdsNew', params: { adId: ad.id, adName: ad.name } }"
                  >
                    {{ ad.name }}
                  </router-link>
                </h4>
                <small>
                  <span class="text-muted">{{ ad.id }}</span>
                </small>
                <div class="callout callout-info">
                  <p class="mb-0 mt-1">{{ $t('dashboard.adsCounter') }}: {{ ad.counter }}</p>
                  <p class="mb-0">{{ $t('dashboard.adsType') }}: {{ $t(`adsprofile.${ad.type}`) }}</p>
                </div>
                <small class="text-muted"></small>
                <p></p>
                <span></span>
              </div>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="warning">
            <template #loader>
              <div>
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
          </div>
                    </transition>

                    <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="w-100" v-if="ckeckBlockVisibilityStatus('marketer','marketerViewAdsadsViewStatistic' )">
          <!-- Ads stats -->
          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'advertisementStatistic')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'advertisementStatistic')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewAdsadsViewStatistic"
            :header-title="$t('dashboard.adsStatistic')"
            :header-caption="$t('dashboard.adsStatisticCaption')"
            card-accent="warning"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewAdsadsViewStatistic'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <router-link
                v-if="$can('read', { route: 'PortalAdsNew' })"
                class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                :to="{ name: 'PortalAdsNew', params: { adStatsShowing: true } }"
              >
                {{ $t('dashboard.toAdsStatistic') }}
              </router-link>
            </template>
            <template #body>
              <div class="no-data" v-if="adsViewsMarketerDashboard.daysData.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="adsViewsMarketerDashboard.daysData.length > 0">
                <!--                <img src="/static/img/user_map.svg" class="dashboar-icon" alt />-->
                <div class="dashboard-total">
                  <div class="dashboard-total-header">{{ $t('dashboard.adsStatisticTotal') }}</div>
                  <div class="dashboard-total-text">{{ adsViewsMarketerDashboard.allcounter }}</div>
                </div>
              </div>
              <div
                class="col-lg-3 col-sm-3"
                v-for="(adViews, index) in adsViewsMarketerDashboard.daysData"
                :key="index"
              >
                <br />
                <h4 class="dashboard-title mb-0">
                  <router-link
                    v-if="$can('read', { route: 'PortalAdsNew' })"
                    class="ml-auto one-line-text"
                    :to="{ name: 'PortalAdsNew', params: { start: adViews[0] } }"
                  >
                    {{ moment(adViews[0]).format(' D MMM YYYY') }}
                  </router-link>
                </h4>
                <div class="callout callout-info">
                  <p class="mb-0 mt-1">{{ $t('dashboard.adsCounter') }}: {{ adViews[1] }}</p>
                </div>
                <small class="text-muted"></small>
                <p></p>
                <span></span>
              </div>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="warning">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
          </div>
                    </transition>

                    <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="w-100" v-if="ckeckBlockVisibilityStatus('marketer','marketerViewConnectionsStatistic' )">
          <!-- Connections stats -->
          <folding-card
            :is-opened="checkBlockCollapseStatus('marketer', 'connectionsStatistic')"
            @blockDisplayingChange = "toggleCollapse('marketer', 'connectionsStatistic')"
            class="animated fadeIn"
            v-if="!showSpinner.marketerViewConnectionsStatistic"
            :header-title="$t('dashboard.connectionsStat')"
            :header-caption="$t('dashboard.connectionsStatCaption')"
            card-accent="success"
          >
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'marketer'"
                                         :block-name="'marketerViewConnectionsStatistic'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <router-link
                v-if="$can('read', { route: 'PortalAdsNew' })"
                class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                :to="{ name: 'PortalStats' }"
              >
                {{ $t('dashboard.toConnectionsStat') }}
              </router-link>
            </template>
            <template #body>
              <div
                class="no-data"
                v-if="!isConnectionsDataForMarketerDashBoard "
              >
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <section v-else class="graph-block width_full">
                <div class="row pl-1 d-flex pr-1" >
                  <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
                    <stats-card
                      :data="newUsersForMarketerDashBoard"
                      :data-caption="$t('portalStats.newUsers')"
                      class="card-new"
                    >
                      <template #icon><i class="icon-user-follow"></i></template>
                    </stats-card>
                  </div>

                  <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
                    <stats-card
                      :data="notNewUsersForMarketerDashBoard"
                      :data-caption="$t('portalStats.notNewUsers')"
                      class="card-returned"
                    >
                      <template #icon><i class="icon-user-following"></i></template>
                    </stats-card>
                  </div>

                  <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
                    <stats-card
                      :data="uniqClientsForMarketerDashBoard"
                      :data-caption="$t('portalStats.uniqClients')"
                      class="card-unique"
                    >
                      <template #icon><i class="icon-people"></i></template>
                    </stats-card>
                  </div>

                  <div class="col-lg-3 col-md-3 d-flex pl-q pr-q">
                    <stats-card
                      :data="totalConnectionsForMarketerDashBoard"
                      :data-caption="$t('portalStats.totalConnections')"
                      class="card-default"
                    >
                      <template #icon><i class="icon-login"></i></template>
                    </stats-card>
                  </div>
                </div>
              </section>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="success">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>
          </div>
                    </transition>
        </div>
      </div>
      <transition name="DashboardBlockSHowHideTransition" mode="out-in">
        <dashboard-visitors
          :is-opened="checkBlockCollapseStatus('marketer', 'visitors')"
          @blockDisplayingChange = "toggleCollapse('marketer', 'visitors')"
          v-if="!dashboardLoadingCount && !isMarketerDashboardLoading && isContentAnalyticsEnabled && ckeckBlockVisibilityStatus('marketer','visitors' )"
          class="row dashboard-row-padding"
        />
      </transition>
      <transition name="fade"
        v-if="!isWLCProduct"
      >
        <dashboard-recommendations
          @moveRecommendationsBottom="moveRecommendationsBottom"
          @moveRecommendationsTop="moveRecommendationsTop"
          :dashboard-recommendations-on-the-dashboard-top="dashboardRecommendationsOnTheDashboardTop"
          v-if="!dashboardLoadingCount && !isMarketerDashboardLoading && !dashboardRecommendationsOnTheDashboardTop"
          class="row dashboard-row-padding mt-1 cards-fix"
        />
      </transition>
    </div>
    <div v-else>
      <!--      <div v-if="dashboardLoadingCount"><Loader-spinner centered></Loader-spinner></div>-->
      <div class="animated fadeIn">
        <!--        <div class="row row-flex" v-if="isEmpty">-->
        <transition name="fade"
          v-if="!isWLCProduct"
        >
          <dashboard-recommendations
            @moveRecommendationsBottom="moveRecommendationsBottom"
            @moveRecommendationsTop="moveRecommendationsTop"
            :dashboard-recommendations-on-the-dashboard-top="dashboardRecommendationsOnTheDashboardTop"
            v-if="!dashboardLoadingCount && dashboardRecommendationsOnTheDashboardTop"
            class="row dashboard-row-padding mt-1 cards-fix"
          />
        </transition>
        <div class="d-flex flex-row justify-content-start flex-wrap" style="position: relative;">
          <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3 col-xs-12 " v-if="ckeckBlockVisibilityStatus('general','serverInfo' )">

            <div

              :class="{ 'card-icon---system card-success': !showSpinner.serverInfo }"
              class="card card-inverse card-icon custom-min-height-for-blocks"
            >
              <DashboardBlockCloseButton :button-padding-right="'0.7em'"
                                         :button-padding-top="'0.7em'"
                                         :block-section="'general'"
                                         :block-name="'serverInfo'">
              </DashboardBlockCloseButton>
              <div v-if="!showSpinner.serverInfo" class="card-block pb-0">
                <h4 class="mb-1">{{ $t('dashboard.serverInfo') }}</h4>
                <div v-if="currentSystemInfo.host">
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.uptime') }}:</strong>
                    {{ secondsInDays(currentSystemInfo.host.uptime) }}
                  </p>
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.os') }}:</strong>
                    {{ currentSystemInfo.host.os }}
                  </p>
                  <p class="mb-1 card-systemtext">
                    <strong>{{ $t('dashboard.modelCPU') }}:</strong>
                    {{ currentSystemInfo.cpu.modelName }}
                  </p>
                </div>
                <div v-else>{{ $t('general.noDataToDisplay') }}</div>
              </div>
              <div class="card-block pb-0" v-else>
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>

          </div>
            </transition>

<transition name="DashboardBlockSHowHideTransition" mode="out-in" >
          <div class="col-lg-3 col-sm-3 col-xs-12 " v-if="ckeckBlockVisibilityStatus('general','cpu' )" >

            <div :class="{ 'card-icon---cpu card-primary': !showSpinner.cpu }" class="card card-inverse card-icon custom-min-height-for-blocks">
              <DashboardBlockCloseButton :button-padding-right="'0.7em'"
                                         :button-padding-top="'0.7em'"
                                         :block-section="'general'"
                                         :block-name="'cpu'">
              </DashboardBlockCloseButton>
              <div v-if="!showSpinner.cpu" class="card-block pb-0">
                <h4 class="mb-1">{{ $t('dashboard.cpu') }}</h4>
                <div v-if="currentSystemInfo.cpu">
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.cpuCount') }}:</strong>
                    {{ currentSystemInfo.cpu.cpu }}
                  </p>
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.cpuCores') }}:</strong>
                    {{ currentSystemInfo.cpu.cores }}
                  </p>
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.cache') }}:</strong>
                    {{ cacheInKb(currentSystemInfo.cpu.cacheSize) }} {{ $t('dashboard.kb') }}
                  </p>
                  <p class="mb-1 card-systemtext">
                    <strong>{{ $t('dashboard.load') }}:</strong>
                    {{ cpuPercent }}%
                  </p>
                </div>
                <div v-else>{{ $t('general.noDataToDisplay') }}</div>
              </div>
              <div v-else class="card-block pb-0">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>

          </div>
  </transition>
<transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3  col-xs-12 " v-if="ckeckBlockVisibilityStatus('general','cpuLoad' )" >

            <div :class="{ 'card-icon---load card-danger': !showSpinner.cpuLoad }" class="card card-inverse card-icon custom-min-height-for-blocks">
              <DashboardBlockCloseButton :button-padding-right="'0.7em'"
                                         :button-padding-top="'0.7em'"
                                         :block-section="'general'"
                                         :block-name="'cpuLoad'">
              </DashboardBlockCloseButton>
              <div v-if="!showSpinner.cpuLoad" class="card-block pb-0">
                <h4 class="mb-1">{{ $t('general.cpuLoad') }}</h4>
                <div v-if="currentSystemInfo.load">
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.load1') }}:</strong>
                    {{ currentSystemInfo.load.load1 }}
                  </p>

                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.load5') }}:</strong>
                    {{ currentSystemInfo.load.load5 }}
                  </p>

                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.load15') }}:</strong>
                    {{ currentSystemInfo.load.load15 }}
                  </p>

                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.load_core') }}:</strong>
                    {{ currentSystemInfo.load.load_core }}
                  </p>
                </div>
                <div v-else>{{ $t('general.noDataToDisplay') }}</div>
              </div>
              <div v-else class="card-block pb-0">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>

          </div>
   </transition>
<transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3 col-xs-12 " v-if="ckeckBlockVisibilityStatus('general','mem' )" >

            <div  :class="{ 'card-icon---ram card-info': !showSpinner.memory }" class="card card-inverse card-icon custom-min-height-for-blocks">
              <DashboardBlockCloseButton :button-padding-right="'0.7em'"
                                         :button-padding-top="'0.7em'"
                                         :block-section="'general'"
                                         :block-name="'mem'">
              </DashboardBlockCloseButton>
              <div v-if="!showSpinner.memory" class="card-block pb-0">
                <h4 class="mb-1">{{ $t('dashboard.memory') }}</h4>
                <div v-if="currentSystemInfo.mem">
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.total') }}:</strong>
                    {{ memoryInGb(currentSystemInfo.mem.total) }} {{ $t('dashboard.gb') }}
                  </p>
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.available') }}:</strong>
                    {{ memoryInGb(currentSystemInfo.mem.available) }} {{ $t('dashboard.gb') }}
                  </p>
                  <p class="mb-0 card-systemtext">
                    <strong>{{ $t('dashboard.free') }}:</strong>
                    {{ memoryInGb(currentSystemInfo.mem.free) }} {{ $t('dashboard.gb') }}
                  </p>
                  <p class="mb-1 card-systemtext">
                    <strong>{{ $t('dashboard.used') }}:</strong>
                    {{ currentSystemInfo.mem.usedPercent | toFixed(2) }}%
                  </p>
                </div>
                <div v-else>{{ $t('general.noDataToDisplay') }}</div>
              </div>
              <div v-else class="card-block pb-0">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>

          </div>
  </transition>

          <!--          <div class="loader loader-backdrop" v-if="isLoadingDashboardsCharts"></div>-->
<!--          <div v-if="isLoadingDashboardsCharts"><Loader-spinner-small centered></Loader-spinner-small></div>-->
       <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3 col-xs-12" v-if="ckeckBlockVisibilityStatus('general','cpuLoadChart' )"
          :class="{ 'no-data-block': !(isEmpty && cpuCharts) }">
            <div  v-if="!showSpinner.cpuLoadChart" class="card card-accent-primary">
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'general'"
                                         :block-name="'cpuLoadChart'">
              </DashboardBlockCloseButton>
              <div class="card-header">
                <span>{{ $t('dashboard.cpuLoad') }}</span>
              </div>
              <div class="card-block">
                <div class="row">
<!--                  <div class="col-lg-12 col-sm-12">-->
<!--                    <highcharts :options="cpuCharts" ref="cpuHighcharts" class="cpu-hc"></highcharts>-->
<!--                  </div>-->
                  <div class="no-data" v-if="!(isEmpty && cpuCharts)">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </div>
                  <div class="col-lg-12 col-sm-12" v-else>
                    <highcharts :options="cpuCharts" ref="cpuHighcharts" class="cpu-hc"></highcharts>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card card-accent-primary">
              <div class="small-spinner-placeholder-height-for-mini-pie-charts">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>
          </div>
     </transition>
          <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3 col-xs-12" v-if="ckeckBlockVisibilityStatus('general','memoryUsedChart' )"
          :class="{ 'no-data-block': !(isEmpty && memoryCharts) }">
            <div v-if="!showSpinner.memoryUsedChart" class="card card-accent-primary">
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'general'"
                                         :block-name="'memoryUsedChart'">
              </DashboardBlockCloseButton>
              <div class="card-header">
                <span>{{ $t('dashboard.memoryUsed') }}</span>
              </div>
              <div class="card-block">
                <div class="row">
<!--                  <div class="col-lg-12 col-sm-12">-->
<!--                    <highcharts :options="memoryCharts" ref="memoryHighcharts" class="cpu-hc"></highcharts>-->
<!--                  </div>-->
                  <div class="no-data" v-if="!(isEmpty && memoryCharts)">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </div>
                  <div class="col-lg-12 col-sm-12" v-else>
                    <highcharts :options="memoryCharts" ref="memoryHighcharts" class="cpu-hc"></highcharts>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card card-accent-primary">
              <div class="small-spinner-placeholder-height-for-mini-pie-charts">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>
          </div>
            </transition>
          <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3" v-if="ckeckBlockVisibilityStatus('general','connectedCPEsChart' )">
            <div
              v-if="!showSpinner.connectedCPEsChart"
              class="card card-accent-primary"
              :class="{ 'no-data-block': cpesInfo.by_connected && cpesInfo.by_connected.length === 0 }"
            >
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'general'"
                                         :block-name="'connectedCPEsChart'">
              </DashboardBlockCloseButton>
              <div class="card-header">
                <div class="d-flex justify-content-between flex-wrap">
                <div>
                  <span>{{ $t('dashboard.connectedCPEs') }}</span>
                </div>
                  <div>
                    <div>
                    <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
                       class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                       href="#"
                       @click.prevent.stop="openModalForSelectLocationForBlock('connectedCPEsChart')">{{getSelectedForBlockLocationNameForShowInBlock('connectedCPEsChart')}}
                    </a>
                  </div>
                  </div>
                </div>

              </div>
              <div class="card-block">
                <div class="row">
                  <div class="no-data" v-if="cpesInfo.by_connected && cpesInfo.by_connected.length === 0">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </div>
                  <div class="col-lg-12 col-sm-12" v-else>
                    <highcharts :options="cpeConnectedPieCharts" ref="highcharts" class="cpe-hc"></highcharts>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-accent-primary"
              :class="{ 'no-data-block': cpesInfo.by_connected && cpesInfo.by_connected.length === 0 }"
            >
              <div class="small-spinner-placeholder-height-for-mini-pie-charts">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>
          </div>
          </transition>
           <transition name="DashboardBlockSHowHideTransition" mode="out-in">
          <div class="col-lg-3 col-sm-3" v-if="ckeckBlockVisibilityStatus('general','configCPEsChart' )">
            <div
              v-if="!showSpinner.configCPEsChart"
              class="card card-accent-primary"
              :class="{ 'no-data-block': !cpesInfo.by_configstatus || cpesInfo.by_configstatus.length === 0 }"
            >
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'general'"
                                         :block-name="'configCPEsChart'">
              </DashboardBlockCloseButton>
              <div class="card-header">
                <div class="d-flex justify-content-between flex-wrap">
                  <div>
                    <span>{{ $t('dashboard.configCPEs') }}</span>
                  </div>
                  <div>
                    <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
                       class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                       href="#"
                       @click.prevent.stop="openModalForSelectLocationForBlock('configCPEsChart')">{{getSelectedForBlockLocationNameForShowInBlock('configCPEsChart')}}
                    </a>
                  </div>
                </div>


              </div>
              <div class="card-block">
                <div class="row">
                  <div class="no-data" v-if="!cpesInfo.by_configstatus || cpesInfo.by_configstatus.length === 0">
                    <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                  </div>
                  <div class="col-lg-12 col-sm-12" v-else>
                    <highcharts :options="cpeConfigPieCharts" ref="highcharts" class="cpe-hc"></highcharts>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="card card-accent-primary"
              :class="{ 'no-data-block': !cpesInfo.by_configstatus || cpesInfo.by_configstatus.length === 0 }"
            >
              <div class="small-spinner-placeholder-height-for-mini-pie-charts">
                <Loader-spinner-small centered></Loader-spinner-small>
              </div>
            </div>
          </div>
           </transition>
        </div>
         <transition name="DashboardBlockSHowHideTransition" mode="out-in">
        <div class="row pr-1 pl-1" v-if="ckeckBlockVisibilityStatus('general','eventsChart' )">
          <folding-card
            :is-opened="checkBlockCollapseStatus('general', 'eventsChart')"
            @blockDisplayingChange = "toggleCollapse('general', 'eventsChart')"
            v-if="!showSpinner.eventsChart" :header-title="$t('dashboard.last10events')" card-accent="info">
            <!-- <template #header-icon><i class="fa fa-warning" /></template> -->
            <template #header-close-button>
              <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                         :button-padding-top="'0.8em'"
                                         :block-section="'general'"
                                         :block-name="'eventsChart'">
              </DashboardBlockCloseButton>
            </template>
            <template #header-link>
              <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
                 class="ml-auto one-line-text custom-padding-for-folding-card-header-links"
                 href="#"
                 @click.prevent.stop="openModalForSelectLocationForBlock('eventsChart')">{{getSelectedForBlockLocationNameForShowInBlock('eventsChart')}}
              </a>
              <router-link v-if="$can('read', { route: 'Events' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" to="/monitor/events">
                {{ $t('dashboard.toEvents') }}
              </router-link>
            </template>
            <template #body>
              <div class="col-12">
                <EventsSummaryChart viewType="dashboard"/>
              </div>
            </template>
          </folding-card>
          <folding-card class="animated fadeIn" v-else card-accent="info">
            <template #loader>
              <div><Loader-spinner-small centered></Loader-spinner-small></div>
            </template>
            <template #body>
              <div class="col-12">
                <div class="small-spinner-placeholder-height"></div>
              </div>
            </template>
          </folding-card>

        </div>
                     </transition>
      </div>
      <transition name="DashboardBlockSHowHideTransition" mode="out-in">
      <div v-if="!dashboardLoadingCount &&  ckeckBlockVisibilityStatus('general','mostLoadCPES' )" class="row pr-1 pl-1" style="position: relative;">
        <!--        <div class="loader loader-backdrop" v-if="!Object.keys(cpesInfo).length"></div>-->
        <!--        <div v-if="!Object.keys(cpesInfo).length"><Loader-spinner centered></Loader-spinner></div>-->

        <folding-card
          :is-opened="checkBlockCollapseStatus('general', 'mostLoadCPES')"
          @blockDisplayingChange = "toggleCollapse('general', 'mostLoadCPES')"
          v-if="!showSpinner.mostLoadCPES" :header-title="$t('dashboard.mostloadCPEs')" card-accent="info">
          <!-- <template #header-icon><i class="fa fa-warning" /></template> -->
          <template #header-close-button>
            <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                       :button-padding-top="'0.8em'"
                                       :block-section="'general'"
                                       :block-name="'mostLoadCPES'">
            </DashboardBlockCloseButton>
          </template>
          <template #header-link>
            <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
               class="ml-auto one-line-text custom-padding-for-folding-card-header-links" href="#" @click.prevent.stop="openModalForSelectLocationForBlock('mostLoadCPES')">{{getSelectedForBlockLocationNameForShowInBlock('mostLoadCPES')}}</a>
<!--            <router-link v-if="$can('read', { route: 'Aps' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" to="/aps">-->
<!--              {{ $t('dashboard.toCPEs') }}-->
<!--            </router-link>-->
            <a href="#" @click.prevent.stop="goToCpePageWithSelectedForBlocksLocationPass" v-if="$can('read', { route: 'Aps' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" >
              {{ $t('dashboard.toCPEs') }}
            </a>
          </template>
          <template #body>
            <template v-if="Object.keys(cpesInfo).length">
              <div class="no-data" v-if="cpesInfo.by_last_cpestat.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="cpesInfo.by_last_cpestat.length > 0">
                <img src="/static/img/wifi.svg" class="dashboar-icon" alt />
              </div>
              <div class="col-lg-3 col-sm-3" v-for="cpe in cpesInfo.by_last_cpestat.slice(0, 3)" :key="cpe.id">
                <h4 class="dashboard-title mb-0">{{ cutName(cpesName(cpe.id)) || $t('misc.no_name') }}</h4>
                <small >
                  <span class="text-info">{{ $t('dashboard.model') }}:&nbsp;</span>
                  <span class="text-muted">{{ cpesModel(cpe.id) || $t('misc.no_data') }}</span>
                </small>
                <div class="mt-0 custom-margin-for-links-to-other-pages-for-cpes-block">
                  <small class>
                  <span class="text-info mr-1"><a href="#" @click.prevent.stop="goToCpeStatistic(cpe.id)">{{$t('dashboard.goToCpeStatistic')}}</a> </span>
                    <span></span>
                  <span class="text-info"><a href="#" @click.prevent.stop="goToCpeSettings(cpe.id)">{{$t('dashboard.goToCpeSettings')}}</a> </span>
                  </small>
                </div>
                <div class="callout callout-info">
                  <p class="mb-0 mt-1">{{ $t('dashboard.cpuLoad') }}: {{ dataInPercentages(cpe.cpu) }}%</p>
                  <p class="mb-0">
                    {{ $t('dashboard.memoryTotal') }}: {{ memoryInMb(cpe.mem_total) }} {{ $t('dashboard.mb') }}
                  </p>
                  <p class="mb-0">
                    {{ $t('dashboard.memoryFree') }}: {{ memoryInMb(cpe.mem_free) }} {{ $t('dashboard.mb') }}
                  </p>
                </div>
                <small class="text-muted">
                  <i class="fa fa-clock-o"></i>
                  {{ cpe.timestamp | moment }}
                </small>
                <p></p>
                <span></span>
              </div>
            </template>
          </template>
        </folding-card>
        <folding-card class="animated fadeIn" v-else card-accent="info">
          <template #loader>
            <div><Loader-spinner-small centered></Loader-spinner-small></div>
          </template>
          <template #body>
            <div class="col-12">
              <div class="small-spinner-placeholder-height"></div>
            </div>
          </template>
        </folding-card>
      </div>
      </transition>
      <transition name="DashboardBlockSHowHideTransition" mode="out-in">
      <div v-if="!dashboardLoadingCount && ckeckBlockVisibilityStatus('general','mostActiveCPES' )" class="row pr-1 pl-1" style="position: relative;">
        <!--        <div class="loader loader-backdrop" v-if="!Object.keys(cpesInfo).length"></div>-->
        <!--        <div class="loader loader-backdrop" v-if="!Object.keys(cpesInfo).length">-->
        <!--          <Loader-spinner centered></Loader-spinner>-->
        <!--        </div>-->

        <folding-card
           :is-opened="checkBlockCollapseStatus('general', 'mostActiveCPES')"
          @blockDisplayingChange = "toggleCollapse('general', 'mostActiveCPES')"
          v-if="!showSpinner.mostActiveCPES"
          :header-title="$t('dashboard.mostactiveCPEs')"
          card-accent="danger"
        >
          <!-- <template #header-icon><i class="fa fa-warning" /></template> -->
          <template #header-close-button>
            <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                       :button-padding-top="'0.8em'"
                                       :block-section="'general'"
                                       :block-name="'mostActiveCPES'">
            </DashboardBlockCloseButton>
          </template>

          <template #header-link>
                        <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
               class="ml-auto one-line-text custom-padding-for-folding-card-header-links" href="#" @click.prevent.stop="openModalForSelectLocationForBlock('mostActiveCPES')">{{getSelectedForBlockLocationNameForShowInBlock('mostActiveCPES')}}</a>
<!--            <router-link v-if="$can('read', { route: 'Aps' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" to="/aps">-->
<!--              {{ $t('dashboard.toCPEs') }}-->
<!--            </router-link>-->
            <a href="#" @click.prevent.stop="goToCpePageWithSelectedForBlocksLocationPass" v-if="$can('read', { route: 'Aps' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" >
              {{ $t('dashboard.toCPEs') }}
            </a>
          </template>
          <template #body>
            <template v-if="Object.keys(cpesInfo).length">
              <div class="no-data" v-if="cpesInfo.by_last_stat.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="cpesInfo.by_last_stat.length > 0">
                <img src="/static/img/traffic.svg" class="dashboar-icon" alt />
              </div>
              <div class="col-lg-3 col-sm-3" v-for="cpe in cpesInfo.by_last_stat.slice(0, 3)" :key="cpe.id">
                <h4 class="dashboard-title mb-0">{{ cutName(cpesName(cpe.id)) || $t('misc.no_name') }}</h4>
                <small class>
                  <span class="text-info">{{ $t('dashboard.model') }}:&nbsp;</span>
                  <span class="text-muted">{{ cpesModel(cpe.id) || $t('misc.no_data')  }}</span>
                </small>
                <div class="mt-0 custom-margin-for-links-to-other-pages-for-cpes-block">
                  <small class>
                  <span class="text-info mr-1"><a href="#" @click.prevent.stop="goToCpeStatistic(cpe.id)">{{$t('dashboard.goToCpeStatistic')}}</a> </span>
                    <span></span>
                  <span class="text-info"><a href="#" @click.prevent.stop="goToCpeSettings(cpe.id)">{{$t('dashboard.goToCpeSettings')}}</a> </span>
                  </small>
                </div>
                <div class="callout callout-danger">
                  <p class="mb-0 mt-1">
                    {{ $t('dashboard.trafficIn') }}: {{ memoryInMb(cpe.traffic_in_bytes) }} {{ $t('dashboard.mb') }}
                  </p>
                  <p class="mb-0">
                    {{ $t('dashboard.trafficOut') }}: {{ memoryInMb(cpe.traffic_out_bytes) }} {{ $t('dashboard.mb') }}
                  </p>
                </div>
                <small class="text-muted">
                  <i class="fa fa-clock-o"></i>
                  <span>{{ cpe.timestamp | moment }}</span>
                </small>
                <p></p>
                <span></span>
              </div>
            </template>
          </template>
        </folding-card>
        <folding-card class="animated fadeIn" v-else card-accent="danger">
          <template #loader>
            <div><Loader-spinner-small centered></Loader-spinner-small></div>
          </template>
          <template #body>
            <div class="col-12">
              <div class="small-spinner-placeholder-height"></div>
            </div>
          </template>
        </folding-card>
      </div>
      </transition>
      <transition name="DashboardBlockSHowHideTransition" mode="out-in">
      <div v-if="!dashboardLoadingCount && ckeckBlockVisibilityStatus('general','mostActiveClients' )" class="row pr-1 pl-1" style="position: relative;">
        <!--        <div class="loader loader-backdrop" v-if="!Object.keys(clientsInfo).length"></div>-->
        <!--        <div v-if="!Object.keys(clientsInfo).length"><Loader-spinner centered></Loader-spinner></div>-->

        <folding-card
          :is-opened="checkBlockCollapseStatus('general', 'mostActiveClients')"
            @blockDisplayingChange = "toggleCollapse('general', 'mostActiveClients')"
          v-if="!showSpinner.mostActiveClients"
          :header-title="$t('dashboard.mostactiveclients')"
          card-accent="warning"
        >
          <!-- <template #header-icon><i class="fa fa-warning" /></template> -->
          <template #header-close-button>
            <DashboardBlockCloseButton :button-padding-right="'0.8em'"
                                       :button-padding-top="'0.8em'"
                                       :block-section="'general'"
                                       :block-name="'mostActiveClients'">
            </DashboardBlockCloseButton>
          </template>
          <template #header-link>
                                    <a v-if="areLocationsVisible" v-tooltip.top-center="{ content: $t('general.locationSelect'),
                              popperOptions: {
                                  modifiers: {
                                    preventOverflow: {
                                      boundariesElement: 'window'
                                    }
                                  }} }"
               class="ml-auto one-line-text custom-padding-for-folding-card-header-links" href="#" @click.prevent.stop="openModalForSelectLocationForBlock('mostActiveClients')">{{getSelectedForBlockLocationNameForShowInBlock('mostActiveClients')}}</a>
<!--            <router-link v-if="$can('read', { route: 'Clients' })" class="ml-auto one-line-text custom-padding-for-folding-card-header-links" to="/clients">-->
<!--              {{ $t('dashboard.toClients') }}-->
<!--            </router-link>-->
            <a href="#" @click.prevent.stop="goToClientsPageWithSelectedForBlocksLocationPass" v-if="$can('read', { route: 'Clients' })"  class="ml-auto one-line-text custom-padding-for-folding-card-header-links" >
             {{ $t('dashboard.toClients') }}
            </a>
          </template>
          <template #body>
            <template v-if="Object.keys(clientsInfo).length">
              <div class="no-data" v-if="clientsInfo.by_acct_stats.length === 0">
                <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
              </div>
              <div class="col-lg-3 col-sm-3" v-if="clientsInfo.by_acct_stats.length > 0">
                <img src="/static/img/client.svg" class="dashboar-icon" alt />
              </div>
              <div class="col-lg-3 col-sm-3" v-for="client in clientsInfo.by_acct_stats.slice(0, 3)" :key="client.id">
                <h4 class="mb-1 text-nowrap client-mac dashboard-title">
                  {{
                    client.id
                      .replace(/(.{2})/g, '$1:')
                      .slice(0, -1)
                      .toUpperCase()
                  }}
                </h4>
                <small class>
                  <span class="text-info">{{ $t('general.description') }}:</span>
                  <span class="text-muted">{{ client.data.description }}</span>
                </small>
                <br />
                <small class>
                  <span class="text-info">{{ $t('dashboard.CPE') }}:</span>
                  <span class="text-muted">{{ cpesModel(client.cpe_id) }}</span>
                </small>
                <div class="callout callout-warning">
                  <p class="mb-0">
                    {{ $t('dashboard.received') }}: {{ kbInMb(client.out_kbytes) }} {{ $t('dashboard.mb') }}
                  </p>
                  <p class="mb-0">
                    {{ $t('dashboard.transmitted') }}: {{ kbInMb(client.in_kbytes) }} {{ $t('dashboard.mb') }}
                  </p>
                </div>
                <small class="text-muted">
                  <i class="fa fa-clock-o"></i>
                  {{ client.timestamp | moment }}
                </small>
                <p></p>
                <span></span>
              </div>
            </template>
          </template>
        </folding-card>
        <folding-card class="animated fadeIn" v-else card-accent="warning">
          <template #loader>
            <div><Loader-spinner-small centered></Loader-spinner-small></div>
          </template>
          <template #body>
            <div class="col-12">
              <div class="small-spinner-placeholder-height"></div>
            </div>
          </template>
        </folding-card>
      </div>
      </transition>
      <transition name="DashboardBlockSHowHideTransition" mode="out-in">
      <dashboard-visitors
        :is-opened="checkBlockCollapseStatus('general', 'visitors')"
         @blockDisplayingChange = "toggleCollapse('general', 'visitors')"
        v-if="!dashboardLoadingCount && isContentAnalyticsEnabled && ckeckBlockVisibilityStatus('general','visitors' )" class="row dashboard-row-padding dashboard-visitors-padding" />
      </transition>
      <transition name="fade"
        v-if="!isWLCProduct"
      >
        <dashboard-recommendations
          @moveRecommendationsBottom="moveRecommendationsBottom"
          @moveRecommendationsTop="moveRecommendationsTop"
          :dashboard-recommendations-on-the-dashboard-top="dashboardRecommendationsOnTheDashboardTop"
          v-if="!dashboardLoadingCount && !dashboardRecommendationsOnTheDashboardTop"
          class="row dashboard-row-padding mt-1 cards-fix dashboard-recomendations-padding"
        />
      </transition>
    </div>
    <Modal
      :scrollBarWidthEqualPaddingRightForBody="false"
      centeredPosition
      close-btn
      v-model="showModalForSelectLocationsForBlocksStatistics"
      :title="$t('general.locationSelect')"
      class="modal-info"
      effect="fade/zoom"
      :backdrop="false"
      @cancel="closeModalForSelectLocationForBlock(statisticBlockNameSelectLocationInOpenedModal)"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('general.locationSelect') }}</h4>
      <div class="d-flex flex-row justify-content-start align-items-center w-100">
        <div class="w-75 mr-1">
          <SelectComponentV2
            small-size
            v-if="showModalForSelectLocationsForBlocksStatistics"
            modal-body-class-name="modal-content"
            in-modal
            show-delete-button
            v-model="selectedInModalLocationForBlocksStatistics"
            track-by="name"
            option-id-name="id"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="selectLocationInLocationForBlocksStatisticsSelector"
          >
            <template #dropdown-start>
            </template>
          </SelectComponentV2>
        </div>
        <div >
          <div class="d-flex  align-items-end">
            <div
              class="d-flex  align-items-end justify-content-start switcher-with-childs"
            >
              <Switch-component
                :disabled="!selectedInModalLocationForBlocksStatistics"
                v-model="selectedInModalWithChildsForBlocksStatistics"
                class="switcher-with-childs-in-dashboard-location-modal"
              />
            </div>
            <div class="">
              <small class="with-child-text">{{ $t('general.withChild') }}</small>
            </div>
          </div>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary outline
          @click="closeModalForSelectLocationForBlock(statisticBlockNameSelectLocationInOpenedModal)"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton secondary
          @click="selectLocationForBlocksStatistics(statisticBlockNameSelectLocationInOpenedModal, selectedInModalLocationForBlocksStatistics)"
        >
          {{ $t('general.save') }}
        </WButton>
      </div>
    </Modal>


  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge';
import drilldown from 'highcharts/modules/drilldown';
import noData from 'highcharts/modules/no-data-to-display';
import commonService from '../services/commonService';
import cpeService from '../services/cpeService';
import DashboardRecommendations from '../components/Dashboard/dashboard-recommendations.vue';
import dashboardService from '../services/dashboardService';
import DashboardVisitors from '../components/Dashboard/dashboard-visitors.vue';
import eventsService from '../services/eventsService';
import FoldingCard from '../components/Universal/folding-card.vue';
import helpers from '../helpers';
import locationService from '../services/locationService';
import MapForLocations from '../components/Maps/MapForLocations.vue';
import portalAdsService from '../services/portalAdsService';
import portalAdsStatsService from '../services/portalAdsStatsService';
import portalStatsService from '../services/portalStatsService';
import ruleService from '../services/ruleService';
import statsCard from '../components/Universal/stats-card.vue';
import usersService from '../services/usersService';
import DashboardBlockCloseButton from '../components/Dashboard/DashboardBlockCloseButton.vue';
import DashboardBlocksVisibilitySettings from '../components/Dashboard/DashboardBlocksVisibilitySettings.vue'
import Modal from '../components/Modal.vue';
import SelectComponentV2 from '../components/Universal/select/select-componentV2.vue';
import SwitchComponent from '../components/Universal/Switch-component.vue';
import EventsSummaryChart from '@/components/EventsSummaryChart/EventsSummaryChart.vue';
import { intervalUpdateModeMixin } from '@/mixins';

drilldown(Highcharts);
highchartsMore(Highcharts);
solidgauge(Highcharts);
noData(Highcharts);

Vue.use(VueHighcharts, { Highcharts });
Vue.use(require('vue-moment'));

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'Dashboard',
  components: {
    EventsSummaryChart,
    DashboardRecommendations,
    DashboardVisitors,
    MapForLocations,
    FoldingCard,
    statsCard,
    DashboardBlockCloseButton,
    DashboardBlocksVisibilitySettings,
    Modal,
    SelectComponentV2,
    SwitchComponent
  },
  mixins: [intervalUpdateModeMixin],
  data() {
    return {
      // locationsForBlocksStatistics: {
      //   mostLoadCPES: ''
      // },

      // locationForBlocksStatistics: '',
      // locationWithChildsForBlocksStatistics: false,
      selectedInModalLocationForBlocksStatistics: '',
      selectedInModalWithChildsForBlocksStatistics: false,
      showModalForSelectLocationsForBlocksStatistics: false,
      statisticBlockNameSelectLocationInOpenedModal: '',

      // showModalForSelectLocationsForBlocksStatistics: {
      //   mostLoadCPES: false
      // },
      // selectedInModalLocationForBlocksStatistics: {
      //   mostLoadCPES: ''
      // },
      dashboardRecommendationsOnTheDashboardTop: true,
      // marketerDashboardBlockDisplaying: {
      //   locations: true,
      //   mapOfLocations: true,
      //   advertisement: true,
      //   advertisementStatistic: true,
      //   connectionsStatistic: true,
      //   locationsWhereManager: true,
      //   visitors: true
      // },
      // generalDashboardBlockDisplaying: {
      //   eventsChart: true,
      //   mostLoadCPES: true,
      //   mostActiveCPES: true,
      //   mostActiveClients: true,
      //   visitors: true,
      // },

      totalConnectionsForMarketerDashBoard: 0,
      uniqClientsForMarketerDashBoard: 0,
      newUsersForMarketerDashBoard: 0,
      notNewUsersForMarketerDashBoard: 0,
      adsViewsDataForMarketerDasboard: {},
      adsDataForMarketerDashboard: [],
      adsStatisticForMarketerDashboard: [],
      window,
      systemInfo: {},
      cpuPercent: '',
      memPercent: '',
      cpuCharts: {
        chart: {
          type: 'solidgauge'
        },
        lang: {
          noData: ''
        },
        pane: {
          center: ['50%', '85%'],
          size: '130%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '75%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
          }
        },

        title: null,

        tooltip: {
          enabled: false,
          valueSuffix: '%'
        },

        // the value axis

        plotOptions: {
          solidgauge: {
            innerRadius: '75%',
            dataLabels: {
              y: -45,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#4dbd74'], // green
            [0.5, '#f8cb00'], // yellow
            [0.9, '#f86c6b'] // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            text: '',
            y: -55
          },
          labels: {
            y: 16,
            x: 1,
            distance: -10
          }
        },

        credits: {
          enabled: false
        },

        series: [
          {
            name: 'CPU load',
            data: [],
            dataLabels: {
              format:
                `<div style="text-align:center"><span style="font-size:25px;color:${
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }">{y}</span><br/>` + '<span style="font-size:12px;color:silver">%</span></div>'
            }
          }
        ]
      },

      memoryCharts: {
        chart: {
          type: 'solidgauge'
        },
        lang: {
          noData: ''
        },
        pane: {
          center: ['50%', '85%'],
          size: '130%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '75%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
          }
        },

        title: null,

        tooltip: {
          enabled: false,
          valueSuffix: '%'
        },
        plotOptions: {
          solidgauge: {
            innerRadius: '75%',
            dataLabels: {
              y: -45,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, '#4dbd74'], // green
            [0.5, '#f8cb00'], // yellow
            [0.9, '#f86c6b'] // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            text: '',
            y: -55
          },
          labels: {
            y: 16,
            x: 1,
            distance: -10
          }
        },

        credits: {
          enabled: false
        },

        series: [
          {
            name: 'Memory used',
            data: [],
            dataLabels: {
              format:
                `<div style="text-align:center"><span style="font-size:25px;color:${
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }">{y}</span><br/>` + '<span style="font-size:12px;color:silver">%</span></div>'
            }
          }
        ]
      },
      /**
      Данные для pie графика подключенных/отключенных ТД
      */
      cpeConnectedPieChartsSeriesData: {
        connected: 0,
        disconnected: 0
      },
      /**
       Данные для pie графика статусов ТД
     */
      cpeConfigPieChartsSeriesData: {
        online: 0,
        inProgress: 0,
        offline: 0,
        empty: 0
      },
      clientsTypePieCharts: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'top',
          itemStyle: { color: '#BCC5D0', fontSize: '9px' },
          margin: 10,
          lineHeight: 1
        },
        plotOptions: {
          pie: {
            size: '170%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: 'CPEs by connected status',
            colorByPoint: true,
            data: [
              {
                name: 'Mobile',
                y: false,
                color: '#f86c6b'
              },
              {
                name: 'Camera',
                y: false,
                color: '#63c2de'
              },
              {
                name: 'Other',
                y: false,
                color: '#f8cb00'
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    ...featureFlagsMapGetters(['isContentAnalyticsEnabled']),
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
    Настройки и данные для pie графика подключенных/отключенных ТД
     */
    cpeConnectedPieCharts() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'bottom',
          itemStyle: {color: '#BCC5D0', fontSize: '9px'},
          margin: 5,
          lineHeight: 1,
          padding: 5,
          y: 17
        },
        plotOptions: {
          pie: {
            size: '135%',
            allowPointSelect: true,
            cursor: 'pointer',
            color: '#ffffff',
            dataLabels: {
              softConnector: true,
              enabled: true,
              formatter() {
                if (this.y != 0) {
                  return this.y;
                }
              },
              distance: 1
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: this.$t('dashboard.cpeChartSeriesName'),
            colorByPoint: true,
            data: [
              {
                name: this.$t('dashboard.connected'),
                y: this.cpeConnectedPieChartsSeriesData.connected,
                color: '#4dbd74'
              },
              {
                name: this.$t('dashboard.notConnected'),
                y: this.cpeConnectedPieChartsSeriesData.disconnected,
                color: '#BCC5D0'
              }
            ]
          }
        ]
      }
    },
    /**
    Настройки и данные для pie графика статусов ТД
     */
    cpeConfigPieCharts() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          verticalAlign: 'bottom',
          itemStyle: {color: '#BCC5D0', fontSize: '9px'},
          margin: 5,
          lineHeight: 1,
          padding: 5,
          y: 17
        },
        plotOptions: {
          pie: {
            size: '135%',
            allowPointSelect: true,
            cursor: 'pointer',
            color: '#ffffff',
            className: 'pie-class',
            dataLabels: {
              enabled: true,
              distance: 1,
              formatter() {
                if (this.y) {
                  return this.y;
                }
              }
            },
            showInLegend: true
          }
        },

        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
        },

        series: [
          {
            name: this.$t('dashboard.cpeStatusChartSeriesName'),
            colorByPoint: true,
            data: [
              {
                name: this.$t('dashboard.online'),
                y: this.cpeConfigPieChartsSeriesData.online,
                color: '#4dbd74'
              },
              {
                name: this.$t('dashboard.updating'),
                y: this.cpeConfigPieChartsSeriesData.inProgress,
                color: '#63c2de'
              },
              {
                name: this.$t('dashboard.offline'),
                y: this.cpeConfigPieChartsSeriesData.offline,
                color: '#BCC5D0'
              },
              {
                name: this.$t('dashboard.empty'),
                y: this.cpeConfigPieChartsSeriesData.empty,
                color: '#eee'
              }
            ]
          }
        ]
      }
    },
    isShowMapsAndLatitudeLongitudeSettings() {
      return this.$store.getters.isShowMapsAndLatitudeLongitudeSettings;
    },
    locationForBlocksStatistics(){
      return this.dashboardBlocksLocationSettingsForGetStatistic.locationId
    },
    locationWithChildsForBlocksStatistics(){
      return this.dashboardBlocksLocationSettingsForGetStatistic.with_child
    },
    dashboardBlocksLocationSettingsForGetStatistic(){
      return this.$store.getters.dashboardBlocksLocationSettingsForGetStatistic
    },
    dashboardBlocksVisibilityGeneral() {
      return this.$store.getters.getDashboardBlocksVisibility.general;
    },
    dashboardBlocksVisibilityMarketer() {
      return this.$store.getters.getDashboardBlocksVisibility.marketer;
    },
    showSpinner() {
      return {
        serverInfo: this.isDataLoading.systemInfo,
        cpu: this.isDataLoading.systemInfo,
        cpuLoad: this.isDataLoading.systemInfo,
        memory: this.isDataLoading.systemInfo,
        cpuLoadChart: this.isDataLoading.systemInfo,
        memoryUsedChart: this.isDataLoading.systemInfo,
        connectedCPEsChart: this.isDataLoading.cpesInfo,
        configCPEsChart: this.isDataLoading.cpesInfo,
        eventsChart: this.isDataLoading.eventsChart,
        mostLoadCPES: this.$store.state.loadingCpesInfoForDashboard,
        mostActiveCPES: this.$store.state.loadingCpesInfoForDashboard,
        mostActiveClients: this.$store.state.loadingClientsInfoForDashboard,
        marketerViewLocations: this.isLoadingLocationsForMarketerDashboard,
        marketerViewlocationsWhereManager: this.isLoadingLocationsForMarketerDashboard,
        marketerViewLocationsMap: this.isLoadingLocationsForMarketerDashboard,
        marketerViewAds: this.isAdsInfoForMarketerDashboardLoading || this.isAdsStatInfoForMarketerDashboardLoading,
        marketerViewAdsadsViewStatistic: this.isAdsViewsForDashboardLoading,
        marketerViewConnectionsStatistic: this.isDataLoading.connectionsStatistic
      };
    },
    isDataLoading() {
      return {
        systemInfo: this.$store.state.loadingSystemInfoForDashboard,
        wlansInfo: this.$store.state.loadingWlansInfoForDashboard,
        cpesInfo: this.$store.state.loadingCpesInfoForDashboard,
        clientsInfo: this.$store.state.loadingClientsInfoForDashboard,
        eventsChart: this.$store.state.loadingEventsCharts === true,
        connectionsStatistic: this.$store.state.activeRequestsForConnectionsStatisticInDashboardMarketerView > 1
      };
    },
    isConnectionsDataForMarketerDashBoard() {
      if (
        this.totalConnectionsForMarketerDashBoard === 0 &&
        this.uniqClientsForMarketerDashBoard === 0 &&
        this.newUsersForMarketerDashBoard === 0 &&
        this.notNewUsersForMarketerDashBoard === 0
      ) {
        return false;
      }
      return true;
    },
    isMarketerDashboardLoading() {
      return (
        this.isLoadingLocationsForMarketerDashboard ||
        this.isAdsInfoForMarketerDashboardLoading ||
        this.isAdsStatInfoForMarketerDashboardLoading ||
        this.isAdsViewsForDashboardLoading
      );
    },
    isLoadingLocationsForMarketerDashboard() {
      return this.$store.state.loadingLocations;
    },
    locationsWithCoords() {
      return this.locationsItems.filter((location) => {
        if (location.coords.lat || location.coords.lng) {
          return true;
        }
        return false;
      });
    },
    locationsWithCoordsForCurrentMarketer() {
      return this.locationsWithCoords.filter((location) => location.manager === this.$store.state.userData.id);
    },
    isPortalAdsLoading() {
      return this.$store.state.loadingPortalAds || this.$store.state.loadingPortalAdsStats;
    },
    isAdsInfoForMarketerDashboardLoading() {
      return this.$store.state.loadingAdsInfoForMarketerDashboard;
    },
    isAdsStatInfoForMarketerDashboardLoading() {
      return this.$store.state.loadingAdsStatsInfoForMarketerDashboard;
    },
    isAdsViewsForDashboardLoading() {
      return this.$store.state.loadingAdsViewsDataForMarketerDashboard;
    },
    adsForMarketerDashboard() {
      if (
        this.isAdsInfoForMarketerDashboardLoading === false &&
        this.isAdsStatInfoForMarketerDashboardLoading === false
      ) {
        const adsDataToDashboard = [];
        if (this.adsDataForMarketerDashboard && this.adsStatisticForMarketerDashboard) {
          const copy = JSON.parse(JSON.stringify(this.adsDataForMarketerDashboard));
          this.adsStatisticForMarketerDashboard.forEach((item) => {
            const ad = copy.find((ad) => ad.id === item.id);
            if (ad) {
              adsDataToDashboard.push({
                name: ad.name,
                counter: item.counter,
                counter_skipped: item.counter_skipped,
                duration: item.duration,
                type: ad.data.type,
                id: ad.id
              });
            }
          });
          const sortedByViews = adsDataToDashboard.sort((a, b) => {
            if (a.counter > b.counter) {
              return -1;
            }
            if (a.counter < b.counter) {
              return 1;
            }
            return 0;
          });
          return sortedByViews.splice(0, 3);
        }
        return [];
      }
      return [];
    },
    adsViewsMarketerDashboard() {
      if (!this.adsViewsDataForMarketerDasboard.allforDashBoardStat) {
        return { allcounter: 0, daysData: [] };
      }
      if (this.isAdsViewsForDashboardLoading === false) {
        let counter = 0;
        for (const day of this.adsViewsDataForMarketerDasboard.allforDashBoardStat) {
          counter = day[1] + counter;
        }
        const unsortedViewsData = [...this.adsViewsDataForMarketerDasboard.allforDashBoardStat];
        const sortedByViewsData = unsortedViewsData.sort((a, b) => {
          if (a[1] > b[1]) {
            return -1;
          }
          if (a[1] < b[1]) {
            return 1;
          }
          return 0;
        });
        return { allcounter: counter, daysData: sortedByViewsData.splice(0, 3) };
      }
      return { allcounter: 0, daysData: [] };
    },
    usersList() {
      return this.$store.state.usersList || [];
    },
    locationsItems() {
      if (this.isCurrentUserMarketer) {
        return this.$store.state.locationsList;
      }
      return [];
    },
    rootLocation() {
      return this.locationsItems.find((item) => this.isRootLocations(item.id));
    },
    nonRootLocations() {
      return this.locationsItems.filter((item) => !this.isRootLocations(item.id));
    },
    allLocations() {
      // old return this.locationsItems;
      // now with shuffle
      let all = [...this.locationsItems];
      all = this.shuffleArr(all);
      return all;
    },
    allLocationsForCurrentMarketer() {
      return this.allLocations.filter((location) => location.manager === this.$store.state.userData.id);
    },
    nonRootLocationsForCurrentMarketer() {
      return this.nonRootLocations.filter((location) => location.manager === this.$store.state.userData.id);
    },
    locationsCount() {
      return this.nonRootLocationsForCurrentMarketer.length;
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    },
    currentSystemInfo() {
      return this.$store.state.systemInfo;
    },
    cpesInfo() {
      return this.$store.state.cpesInfo;
    },
    clientsInfo() {
      return this.$store.state.clientsInfo;
    },
    isLoadingDashboardsCharts() {
      return this.$store.state.loadingDashboardsCharts;
    },
    isEmpty() {
      const { systemInfo } = this.$store.state;
      return Object.keys(systemInfo).length > 1;
    },
    dashboardLoadingCount() {
      return this.$store.state.dashboardLoadingCount;
    }
  },
  methods: {
    selectLocationInLocationForBlocksStatisticsSelector(selected) {
      if (!selected) {
        this.selectedInModalWithChildsForBlocksStatistics = false;
      }
    },
    selectLocationForBlocksStatistics(blockName, location) {
      const locationIdForSave = location ? location.id : ''
      // this.locationsForBlocksStatistics[blockName] = locationIdForSave;
      // this.locationForBlocksStatistics = locationIdForSave
      // this.locationWithChildsForBlocksStatistics = this.selectedInModalWithChildsForBlocksStatistics

      this.$store.commit('saveDashboardBlocksLocationSettingsForGetStatisticToStoreAndLocalStorage', {
        loctionId: locationIdForSave,
        with_child: this.selectedInModalWithChildsForBlocksStatistics
      })

      this.closeModalForSelectLocationForBlock()
      // if (blockName === 'mostLoadCPES') {
      //   dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfo);
      // }
      // dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfo);
      dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfoWithClearCounts);
      dashboardService.getOnlyClientsInfo(this);
    },
    openModalForSelectLocationForBlock(blockName) {
      // const selectedForBlockLocation = this.getLocationById(this.locationsForBlocksStatistics[blockName]);

      const selectedForBlockLocation = this.getLocationById(this.locationForBlocksStatistics);
      // console.log(selectedForBlockLocation)
      this.selectedInModalLocationForBlocksStatistics = selectedForBlockLocation
      this.statisticBlockNameSelectLocationInOpenedModal = blockName
      this.selectedInModalWithChildsForBlocksStatistics = this.locationWithChildsForBlocksStatistics;
      this.showModalForSelectLocationsForBlocksStatistics = true;
    },
    closeModalForSelectLocationForBlock(blockName) {
      this.showModalForSelectLocationsForBlocksStatistics = false;
      this.statisticBlockNameSelectLocationInOpenedModal = ''
      this.showModalForSelectLocationsForBlocksStatistics = false;
      this.selectedInModalLocationForBlocksStatistics = '';
    },
    getSelectedForBlockLocationNameForShowInBlock(blockName) {
      // if (this.locationsForBlocksStatistics[blockName] !== '') {
      if (this.locationForBlocksStatistics !== '') {
        // return this.getLocationNameById(this.locationsForBlocksStatistics[blockName]) ?? this.locationsForBlocksStatistics[blockName];
        return this.getLocationNameById(this.locationForBlocksStatistics) ?? this.locationForBlocksStatistics;
      } else {
        return this.$t('general.locationsAll');
      }
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      return locationService.requestLocationsWithSort(searchQuery);
    },
    getLocationNameById(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      // console.log(showLocationFromId)
      return showLocationFromId;
    },
    getLocationById(id){
      const locationObject = commonService.locationObjbyId(id);
      // console.log(showLocationFromId)
      return locationObject;
    },
    goToCpeStatistic(cpeId) {
      // console.log('goToCpeStatistic', cpeId);
      this.$router.push({ name: 'Statcpes', params: { cpeIdFormDashboard: cpeId } });
    },
    goToCpeSettings(cpeId) {
      // console.log('goToCpeSettings', cpeId)
      this.$router.push({ name: 'Aps', params: { cpeIdFormDashboard: cpeId } });
    },
    goToCpePageWithSelectedForBlocksLocationPass() {
      this.$router.push({
        name: 'Aps', params: {
          locationSelectedFromDashboard: {
            locationId: this.locationForBlocksStatistics ?? '',
            locationObject: this.locationForBlocksStatistics ? JSON.parse(JSON.stringify(this.getLocationById(this.locationForBlocksStatistics))) : {},
            with_child: this.locationWithChildsForBlocksStatistics ?? true
          }
        }
      });
    },
    goToClientsPageWithSelectedForBlocksLocationPass() {
      this.$router.push({
        name: 'Clients', params: {
          locationSelectedFromDashboard: {
            locationId: this.locationForBlocksStatistics ?? '',
            locationObject: this.locationForBlocksStatistics ? JSON.parse(JSON.stringify(this.getLocationById(this.locationForBlocksStatistics))) : {},
            with_child: this.locationWithChildsForBlocksStatistics ?? true
          }
        }
      });
    },
    ckeckBlockVisibilityStatus(blockSection, blockname) {
      try {
        return this.$store.state.dashboardBlocksVisibility[blockSection][blockname]
      } catch (e) {
        console.log(e)
        return true
      }
    },
    checkBlockCollapseStatus(blockSection, blockname) {
      try {
        return this.$store.state.dashboardBlocksCollapse[blockSection][blockname]
      } catch (e) {
        console.log(e)
        return true
      }
    },
    moveRecommendationsBottom() {
      this.dashboardRecommendationsOnTheDashboardTop = false;
      localStorage.setItem('dashboardRecommendationsOnTheDashboardTop', false);
    },
    moveRecommendationsTop() {
      this.dashboardRecommendationsOnTheDashboardTop = true;
      localStorage.setItem('dashboardRecommendationsOnTheDashboardTop', true);
    },
    shuffleArr(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    toggleCollapse(blockSection, blockName) {
      this.$store.commit('setDashboardBlocksCollapseForBlock', {
        blockSection: blockSection,
        blockName: blockName,
        visible: !this.checkBlockCollapseStatus(blockSection, blockName),
      });
    },
    // toggleBlockDisplayingForMarketerDashboard(blockName) {
    //   if (this.marketerDashboardBlockDisplaying[blockName] !== undefined) {
    //     this.marketerDashboardBlockDisplaying[blockName] = !this.marketerDashboardBlockDisplaying[blockName];
    //     const { marketerDashboardBlockDisplaying } = this;
    //     const localStorageMarketerDashboardBlockDisplayingSettingsAsObject = JSON.parse(
    //       localStorage.getItem('marketerDashboardBlockDisplayingSettings')
    //     );
    //     localStorageMarketerDashboardBlockDisplayingSettingsAsObject[
    //       this.$store.state.userData.id
    //     ] = marketerDashboardBlockDisplaying;
    //     localStorage.setItem(
    //       'marketerDashboardBlockDisplayingSettings',
    //       JSON.stringify(localStorageMarketerDashboardBlockDisplayingSettingsAsObject)
    //     );
    //   }
    // },
    // toggleBlockDisplayingForGeneralDashboard(blockName) {
    //   if (this.generalDashboardBlockDisplaying[blockName] !== undefined) {
    //     this.generalDashboardBlockDisplaying[blockName] = !this.generalDashboardBlockDisplaying[blockName];
    //     const { generalDashboardBlockDisplaying } = this;
    //     const localStoragegeneralDashboardBlockDisplayingSettingsAsObject = JSON.parse(
    //       localStorage.getItem('generalDashboardBlockDisplayingSettings')
    //     );
    //     localStoragegeneralDashboardBlockDisplayingSettingsAsObject[
    //       this.$store.state.userData.id
    //     ] = generalDashboardBlockDisplaying;
    //     localStorage.setItem(
    //       'generalDashboardBlockDisplayingSettings',
    //       JSON.stringify(localStoragegeneralDashboardBlockDisplayingSettingsAsObject)
    //     );
    //   }
    // },
    getConnectionsDataForMarketerDashboard(subtype) {
      subtype.forEach((el) => {
        portalStatsService.getConnectionsDataForMarketerDashboard(this, 'pie', el);
        const piesOnly = ['all_visit', 'unique_visit', 'browser'];
        if (!piesOnly.includes(el)) {
          portalStatsService.getConnectionsDataForMarketerDashboard(this, 'timeseries', el);
        }
      });
    },
    setTotalConnectionsForMarketerDashBoard(amount) {
      this.totalConnectionsForMarketerDashBoard = amount;
    },
    setUniqClientsForMarketerDashBoard(amount) {
      this.uniqClientsForMarketerDashBoard = amount;
    },
    setNewUsersForMarketerDashBoard(amount) {
      this.newUsersForMarketerDashBoard = amount;
    },
    setNotNewUsersForMarketerDashBoard(amount) {
      this.notNewUsersForMarketerDashBoard = amount;
    },
    onLocationsMapMarkerClick(locationId) {
      this.$router.push({ name: 'Location', params: { locationId } });
    },
    getUserNameByID(id) {
      if (this.usersList && id) {
        const result = this.usersList.find((item) => item.id === id);
        if (result) {
          return result.username;
        }
        return '';
      }
      return '';
    },
    isRootLocations(id) {
      const rootLocation = this.$store.state.userData.base_location;
      return rootLocation === id;
    },
    dataInPercentages(data) {
      return (data * 100).toFixed(0);
    },
    memoryInGb(memory) {
      return (memory / 1073741824).toFixed(2);
    },
    memoryInMb(memory) {
      return (memory / 1048576).toFixed(0);
    },

    kbInMb(memory) {
      return (memory / 1024).toFixed(0);
    },

    cacheInKb(cache) {
      return (cache / 1024).toFixed(0);
    },
    secondsInDays(seconds) {
      const days = Math.floor(seconds / (3600 * 24));
      return `${days}`;
    },
    cpesName(id) {
      const cpesList = this.$store.state.cpesList || [];
      let cpeName;
      cpesList.forEach((cpe) => {
        if (cpe.id == id) {
          if (cpe.name == '') {
            cpeName = cpe.model.name;
          } else {
            cpeName = cpe.name;
          }
        }
      });
      return cpeName;
    },
    cpesModel(id) {
      const cpesList = this.$store.state.cpesList || [];
      let cpeModel;
      cpesList.forEach((cpe) => {
        if (cpe.id == id) {
          cpeModel = cpe.model.name;
        }
      });
      if (cpeModel) {
        if (cpeModel.length > 20) {
          cpeModel = cpeModel.substring(0, 20);
          cpeModel += '...';
        }
      }
      return cpeModel;
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    }
  },
  filters: {
    momentForLocation(value) {
      if (value) {
        return moment(value).format('D MMM YYYY, H:mm');
      }
    },
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  },
  created() {
    this.$store.commit('loadDashboardBlocksVisibilityFromLocalStorage');
    this.$store.commit('loadDashboardBlocksCollapseFromLocalStorage');
    // this.$store.commit('clearDashboardBlocksLocationSettingsForGetStatistic')
    this.$store.commit('loadDashboardBlocksLocationSettingsForGetStatisticFromLocalStorage');
    if (localStorage.getItem('dashboardRecommendationsOnTheDashboardTop') === null) {
      localStorage.setItem('dashboardRecommendationsOnTheDashboardTop', this.dashboardRecommendationsOnTheDashboardTop);
    }
    // console.log(localStorage.getItem('dashboardRecommendationsOnTheDashboardTop'))
    this.dashboardRecommendationsOnTheDashboardTop = JSON.parse(
      localStorage.getItem('dashboardRecommendationsOnTheDashboardTop')
    );
    ruleService.getRules(this);
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
    dashboardService.getOnlySystemsInfo(this, dashboardService.setSystemInfoV2);
    dashboardService.getOnlyWlansInfo(this);
    // dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfo);
    dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfoWithClearCounts);
    dashboardService.getOnlyClientsInfo(this);
    // if (!this.isCurrentUserMarketer) {
    //   if (localStorage.getItem('generalDashboardBlockDisplayingSettings') === null) {
    //     localStorage.setItem('generalDashboardBlockDisplayingSettings', JSON.stringify({}));
    //   }
    //   let localStorageGeneralDashboardBlockDisplayingSettingsAsObject = JSON.parse(
    //     localStorage.getItem('generalDashboardBlockDisplayingSettings')
    //   );
    //   // console.log(localStorageGeneralDashboardBlockDisplayingSettingsAsObject);
    //   if (!localStorageGeneralDashboardBlockDisplayingSettingsAsObject[this.$store.state.userData.id]) {
    //     localStorageGeneralDashboardBlockDisplayingSettingsAsObject[
    //       this.$store.state.userData.id
    //       ] = this.generalDashboardBlockDisplaying;
    //     localStorage.setItem(
    //       'generalDashboardBlockDisplayingSettings',
    //       JSON.stringify(localStorageGeneralDashboardBlockDisplayingSettingsAsObject)
    //     );
    //   }
    //   localStorageGeneralDashboardBlockDisplayingSettingsAsObject = JSON.parse(
    //     localStorage.getItem('generalDashboardBlockDisplayingSettings')
    //   );
    //   const generalDashboardBlocksDisplayingForCurrentUser =
    //     localStorageGeneralDashboardBlockDisplayingSettingsAsObject[this.$store.state.userData.id];
    //   // тут перебираем настройки для показа блоков из компонента, если добавились новые блоки,
    //   // которых нет у пользователя, добавляем их пользователю в local storage
    //   for (const key in this.generalDashboardBlockDisplaying) {
    //     if (!generalDashboardBlocksDisplayingForCurrentUser.hasOwnProperty(key)) {
    //       generalDashboardBlocksDisplayingForCurrentUser[key] = this.generalDashboardBlockDisplaying[key];
    //     }
    //   }
    //   localStorageGeneralDashboardBlockDisplayingSettingsAsObject[
    //     this.$store.state.userData.id
    //     ] = generalDashboardBlocksDisplayingForCurrentUser;
    //   localStorage.setItem(
    //     'generalDashboardBlockDisplayingSettings',
    //     JSON.stringify(localStorageGeneralDashboardBlockDisplayingSettingsAsObject)
    //   );
    //   this.generalDashboardBlockDisplaying = generalDashboardBlocksDisplayingForCurrentUser;
    // }

    if (this.isCurrentUserMarketer) {
      // if (localStorage.getItem('marketerDashboardBlockDisplayingSettings') === null) {
      //   localStorage.setItem('marketerDashboardBlockDisplayingSettings', JSON.stringify({}));
      // }
      // let localStorageMarketerDashboardBlockDisplayingSettingsAsObject = JSON.parse(
      //   localStorage.getItem('marketerDashboardBlockDisplayingSettings')
      // );
      // // console.log(localStorageMarketerDashboardBlockDisplayingSettingsAsObject);
      // if (!localStorageMarketerDashboardBlockDisplayingSettingsAsObject[this.$store.state.userData.id]) {
      //   localStorageMarketerDashboardBlockDisplayingSettingsAsObject[
      //     this.$store.state.userData.id
      //   ] = this.marketerDashboardBlockDisplaying;
      //   localStorage.setItem(
      //     'marketerDashboardBlockDisplayingSettings',
      //     JSON.stringify(localStorageMarketerDashboardBlockDisplayingSettingsAsObject)
      //   );
      // }
      // localStorageMarketerDashboardBlockDisplayingSettingsAsObject = JSON.parse(
      //   localStorage.getItem('marketerDashboardBlockDisplayingSettings')
      // );
      // const marketerDashboardBlocksDisplayingForCurrentUser =
      //   localStorageMarketerDashboardBlockDisplayingSettingsAsObject[this.$store.state.userData.id];
      // // тут перебираем настройки для показа блоков из компонента, если добавились новые блоки,
      // // которых нет у пользователя, добавляем их пользователю в local storage
      // for (const key in this.marketerDashboardBlockDisplaying) {
      //   if (!marketerDashboardBlocksDisplayingForCurrentUser.hasOwnProperty(key)) {
      //     marketerDashboardBlocksDisplayingForCurrentUser[key] = this.marketerDashboardBlockDisplaying[key];
      //   }
      // }
      // localStorageMarketerDashboardBlockDisplayingSettingsAsObject[
      //   this.$store.state.userData.id
      // ] = marketerDashboardBlocksDisplayingForCurrentUser;
      // localStorage.setItem(
      //   'marketerDashboardBlockDisplayingSettings',
      //   JSON.stringify(localStorageMarketerDashboardBlockDisplayingSettingsAsObject)
      // );
      // this.marketerDashboardBlockDisplaying = marketerDashboardBlocksDisplayingForCurrentUser;

      locationService.getLocations(this);
      usersService.getUsers(this, { onlyLoad: true });
      portalAdsService.getPortalAdsForMarketerDashboard(this);
      portalAdsService.getAdStatisticsForMarketerDashboard(this);
      portalAdsStatsService.getRangeForMarketerDashBoard(this);
      this.getConnectionsDataForMarketerDashboard(['all_visit', 'unique_visit', 'first_visit']);
    }
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      if (this.isCurrentUserMarketer) {
        locationService.getLocations(this);
        usersService.getUsers(this, { onlyLoad: true });
        portalAdsService.getPortalAdsForMarketerDashboard(this);
        portalAdsService.getAdStatisticsForMarketerDashboard(this);
        portalAdsStatsService.getRangeForMarketerDashBoard(this);
        this.getConnectionsDataForMarketerDashboard(['all_visit', 'unique_visit', 'first_visit']);
      } else {
        dashboardService.updateInfoFormSeparatedApiEndpoints(this);
      }
    });
    // // dashboardService.getSystemsInfo(this, dashboardService.setSystemInfo);
    // dashboardService.getOnlySystemsInfo(this, dashboardService.setSystemInfoV2);
    // dashboardService.getOnlyWlansInfo(this);
    // dashboardService.getOnlyCpesInfo(this, dashboardService.setCpesInfo);
    // dashboardService.getOnlyClientsInfo(this);
  }
};
</script>

<style lang="scss">
  .DashboardBlockSHowHideTransition-enter-active,
.DashboardBlockSHowHideTransition-leave-active {
  transition: all 0.5s;
    transition-timing-function: ease
}

.DashboardBlockSHowHideTransition-enter,
.DashboardBlockSHowHideTransition-leave-to {
  opacity: 0;
  transform: perspective(500px) translateY(-200px)
}
.row-flex {
  display: flex;
  justify-content: space-around;
}
.card-systemtext {
  font-size: 90%;
  line-height: 1.6;
}

.card-icon {
  min-height: 180px;
  padding: 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 75px 75px;
  background-position: 95% 80%;
}
.card-icon---system {
  background-image: url('/static/img/control-system-unit.svg');
}
.card-icon---cpu {
  background-image: url('/static/img/cpu-chip.svg');
}
.card-icon---ram {
  background-image: url('/static/img/ram.svg');
}
.card-icon---list {
  background-image: url('/static/img/list.svg');
}

.card-icon---load {
  background-image: url('/static/img/scales.svg');
}

.card-system {
  display: flex;
}
.card-systeminfo {
  padding-right: 1rem;
}
.dashboard {
  // min-width: 730px;
}

.card.card-new {
  border-top: 4px solid #81c784;
}

.card.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.card.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.dashboard-title {
  font-size: 1.1rem;
}

.container-dashboard {
  display: flex;
  height: 100vh;
}
.container-dashboard .loader {
  background: rgba(0, 0, 0, 0);
}

.dashboar-icon {
  width: 140px;
}

.no-data-block {
  opacity: 0.6;
}

.no-data {
  width: 100%;
  text-align: center;
}

.cpu-hc {
  height: 140px;
}
.cpe-hc {
  height: 140px;
}

.client-mac:after {
  content: 'MAC';
  color: #bcc5d0 !important;
  font-size: 45%;
  top: 20px;
  left: 16px;
  position: absolute;
}

.cpe-icon {
  vertical-align: baseline;
  height: 15px;
}
</style>
<style>
    .switcher-with-childs-in-dashboard-location-modal label.switch {
  margin: 0 !important;
}
.switcher-with-childs-in-dashboard-location-modal label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-in-dashboard-location-modal span.switch-handle,
.switcher-with-childs-in-dashboard-location-modal span.switch-label {
  height: 27px !important;
}
.switcher-with-childs-in-dashboard-location-modal-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}
.switcher-with-childs-in-dashboard-location-modal-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  user-select: none;
}

  .with-child-text {
  position: relative;
  font-size: 0.7em;
  padding: 0;
  margin: 0em;
  border: 0;
  /*top: -1px;*/
    bottom: -1px;
}
  .switcher-with-child{
      padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
  }
  .switcher-with-childs-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
}
  .custom-margin-for-links-to-other-pages-for-cpes-block{
    margin-top: -5px !important;
  }
  .dashboard-recomendations-padding{
        padding-right: 2.2em !important;
    padding-left: 2.2em !important;
  }
  .dashboard-visitors-padding {
    padding-right: 2.2em !important;
    padding-left: 2.2em !important;
  }
  .custom-min-height-for-blocks{
    min-height: 230px;
  }
.small-spinner-placeholder-height {
  height: 160px;
}

.small-spinner-placeholder-height-for-mini-pie-charts {
  height: 226px;
}
.dashboard-row-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.cards-fix {
  flex-direction: column;
}

.loader-dashboard:after {
  position: absolute;
  top: calc(50vh - 10%);
}
.loader-backdrop-dashboard-for-marketer {
  width: 100%;
  position: absolute;
}

.add-padding {
  padding-left: 1em;
}
.dashboard-total {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-total-header {
  font-size: 2em;
  text-align: center;
}
.dashboard-total-text {
  font-size: 4em;
}
.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}
.width_full {
  width: 100%;
}
.one-line-text {
  white-space: nowrap;
}

.custom-padding-for-folding-card-header-links {
  padding-right: 0.9em;
  padding-top: 0.3em;
}

</style>
