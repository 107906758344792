/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_CREATE_SVI_URL } from '../config';
import {
  SVITypesConverterForBackend
} from '../utils';

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/512491544/FT23.10.16+SVI+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads
/**
 * @typedef {import('../types.js').CreateSVIRequestData} CreateSVIRequestData
 */

/**
 * Функция-фетчер для создания нового SVI.
 *
 * @param {CreateSVIRequestData} requestParams - Настройки SVI.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const postSVIFetcher = async (requestParams) => {
  const payload = {
    svi: SVITypesConverterForBackend(requestParams)
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_CREATE_SVI_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postSVIFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {CreateSVIRequestData} requestParams - Настройки SVI.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @param {string} [options.errorMessage] - Сообщение об ошибке выполнении запроса.
 * @returns {Promise<void>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const createSVI = async (requestParams, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage,
    errorMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await postSVIFetcher(requestParams);

    store.commit('setSuccess', successMessage);
    onSuccess();
    return Promise.resolve();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', errorMessage);
  }
};

export default createSVI;
