/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы WlanPolicyTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('wlanPolicy.tableHeading.id'),
      field: 'id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.id
    },
    {
      label: $t('wlanPolicy.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('wlanPolicy.tableHeading.description'),
      field: 'description',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.description
    },
    {
      label: $t('wlanPolicy.tableHeading.profile_enabled'),
      field: 'profile_enabled',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.profile_enabled
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ wlanPoliciesData, selectedWlanPolicyIds }) {
  return wlanPoliciesData.map(wlanPolicy => {
    const {
      id,
      created_at,
      updated_at,
      name,
      description,
      profile_enabled,
      central_switch_enabled,
      acl,
      id_vlan,
      id_vlan_group,
      id_policy_map_client_out,
      id_policy_map_ssid_in,
      id_policy_map_ssid_out,
      session_timeout,
      idle_timeout,
      idle_threshold,
      exclusion_timeout,
      aaa_override_enabled,
      proxy_arp_enabled,
      id_eogre_profile,
      central_auth_enabled
    } = wlanPolicy;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: wlanPolicy,
      id,
      name,
      description,
      profile_enabled,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedWlanPolicyIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
