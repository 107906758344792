/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы SVITable.vue
 */

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
 */

import { getSelectedRowValue } from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('svi.tableHeading.adminStatus'),
      field: 'admin_status',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.admin_status
    },
    {
      label: $t('svi.tableHeading.operStatus'),
      field: 'oper_status',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.oper_status
    },
    {
      label: $t('svi.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('svi.tableHeading.ip'),
      field: 'ip',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.ip
    },
    {
      label: $t('svi.tableHeading.subnetMask'),
      field: 'subnet_mask',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.subnet_mask
    },
    {
      label: $t('svi.tableHeading.description'),
      field: 'description',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.description
    },
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
 */

/**
 * todo: подтянуть типизацию и типизировать, когда добавим api
 */

function generateTableRows({ SVIsData, selectedSVIIds }) {
  return SVIsData.map((SVI) => {
    const {
      id,
      created_at,
      updated_at,
      vlan_id,
      id_vlan,
      name,
      description,
      admin_status,
      oper_status,
      ip,
      subnet_mask,
      acl_ingress,
      acl_egress,
      mdns_enabled,
      mtu
    } = SVI;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: SVI,
      id,
      vlan_id,
      name,
      description,
      admin_status,
      oper_status,
      ip,
      subnet_mask,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(vlan_id, selectedSVIIds)
    };
  });
}

export { generateTableColumns, generateTableRows };
