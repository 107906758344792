import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_WLAN_POLICIES_URL = `${GAPI_URL}/api/v1/profiles/wlan-policy`;
const POST_CREATE_WLAN_POLICY_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/wlan-policy`;
const POST_UPDATE_WLAN_POLICY_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/wlan-policy`;
const POST_DELETE_WLAN_POLICY_PROFILES_URL = `${GAPI_URL}/api/v1/profiles/wlan-policy/bulk/deletion`;
const GET_WLAN_POLICY_PROFILE_BY_NAME_URL = `${GAPI_URL}/api/v1/profiles/wlan-policy/find-by-name/`;
const GET_VLANS_URL = `${GAPI_URL}/api/v1/vlans`;
const GET_VLAN_GROUPS_URL = `${GAPI_URL}/api/v1/vlan-groups`;
const GET_POLICY_MAPS_URL = `${GAPI_URL}/api/v1/policy-maps`;
const GET_L2CHAINS_URL = `${API_URL}/api/network/l2chains`;

export {
  AXIOS_INSTANCE,
  GET_WLAN_POLICIES_URL,
  POST_CREATE_WLAN_POLICY_PROFILE_URL,
  POST_UPDATE_WLAN_POLICY_PROFILE_URL,
  POST_DELETE_WLAN_POLICY_PROFILES_URL,
  GET_WLAN_POLICY_PROFILE_BY_NAME_URL,
  GET_VLANS_URL,
  GET_VLAN_GROUPS_URL,
  GET_POLICY_MAPS_URL,
  GET_L2CHAINS_URL
};
