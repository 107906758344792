/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы VlanTable.vue
 */

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
 */

import { getSelectedRowValue } from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('vlan.tableHeading.adminStatus'),
      field: 'admin_status',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.admin_status
    },
    {
      label: $t('vlan.tableHeading.vlanId'),
      field: 'vlan_id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.vlan_id
    },
    {
      label: $t('vlan.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
 */

/**
 * todo: подтянуть типизацию и типизировать, когда добавим api
 */

function generateTableRows({ vlansData, selectedVlanIds }) {
  return vlansData.map((vlan) => {
    const { id, name, vlan_id, admin_status, created_at, updated_at, description } = vlan;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: vlan,
      id,
      admin_status,
      vlan_id,
      name,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(vlan_id, selectedVlanIds)
    };
  });
}

export { generateTableColumns, generateTableRows };
