/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, GET_WLAN_POLICIES_URL } from '../config';
import {
  getFetcherUrl,
  wlanPolicyProfileDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').WlanPolicyProfile_FRONTEND} WlanPolicyProfile_FRONTEND
 * @typedef {import('../types.js').WlanPolicyProfile_BACKEND} WlanPolicyProfile_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/286621781
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {string} total - Общее количество записей.
 * @property {string} limit - Количество записей.
 * @property {string} offset - Номер записи, с которой начинается вывод.
 * @property {WlanPolicyProfile_BACKEND[]} wlan_policy_profiles - Массив объектов, содержащий wlan profiles.
 */

/**
 * Функция-фетчер для получения wlan profiles.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {number} settings.limit - Количество записей wlan policies, которое необходимо запросить.
 * @param {number} settings.offset - номер записи(идентификатор), с которой необходимо запросить кол-во записей.
 * @param {string} settings.filterByName - фильтрация по столбцу name.
 * @param {string} settings.filterByDescription- фильтрация по description.
 * @param {'asc' | 'desc'} settings.sortOrderBy - как сортировать.
 * @param {'id' | 'name' | 'description' | 'profile_enabled' } settings.sortValueBy - сотритровка по полю.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getWlanPoliciesFetcher = async (settings = {}) => {
  const {
    limit,
    offset,
    filterByName,
    filterByDescription,
    sortOrderBy,
    sortValueBy
  } = settings;

  const url = getFetcherUrl(GET_WLAN_POLICIES_URL, [
    {
      name: 'limit',
      value: limit
    },
    {
      name: 'offset',
      value: offset
    },
    {
      name: 'name',
      value: filterByName
    },
    {
      name: 'description',
      value: filterByDescription
    },
    {
      name: 'order',
      value: sortOrderBy
    },
    {
      name: 'sort',
      value: sortValueBy
    }
  ]);

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {number} total - Общее количество записей.
 * @property {number} limit - Количество записей.
 * @property {number} offset - Номер записи, с которой начинается вывод.
 * @property {WlanPolicyProfile_FRONTEND[]} wlan_policy_profiles - Массив объектов, содержащих информацию о wlan policies.
 */

/**
 * фукнция-обработчик фетчера getWlanPoliciesFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {number} settings.limit - Количество записей wlan policies, которое необходимо запросить.
 * @param {number} settings.offset - номер записи(идентификатор), с которой необходимо запросить кол-во записей.
 * @param {string} settings.filterByName - фильтрация по столбцу name.
 * @param {string} settings.filterByDescription- фильтрация по description.
 * @param {'asc' | 'desc'} settings.sortOrderBy - как сортировать.
 * @param {'id' | 'name' | 'description' | 'profile_enabled' } settings.sortValueBy - сотритровка по полю.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getWlanPolicies = async (settings, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await getWlanPoliciesFetcher(settings);
    const {
      total,
      limit,
      offset,
      wlan_policy_profiles
    } = response;

    // конвертация типов в соответствии с ResponseData_FRONTEND
    const data = {
      total: Number(total),
      limit: Number(limit),
      offset: Number(offset),
      wlan_policy_profiles: wlan_policy_profiles.map(wlan_policy_profile => wlanPolicyProfileDataTypesConverterForFrontend(wlan_policy_profile))
    };

    store.commit('setSuccess');
    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default getWlanPolicies;
