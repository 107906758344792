/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента VlanGroupForm.
 */

import vlanGroupApi from '../../../api';

// Функция для проверки уникальности vlan name на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueVlanGroupNameByApiRequest = async nameToCheck => {
  let result;

  await vlanGroupApi.getVlanGroupByName(nameToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

const getVlanGroupValidators = (i18nInstance) => ({
  vlanGroupNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('vlanGroup.inputError.vlanGroupNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[\w!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~А-я]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  uniqueVlanGroupName: (forbiddenVlanNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('vlanGroup.inputError.uniqueVlanGroupName');
      return message;
    },
    validate: async value => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenVlanNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueVlanGroupNameByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  vlanGroupListVlanIdMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('vlanGroup.inputError.vlanGroupListVlanIdMask');
      return message;
    },
    validate: value => {
      // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/497713224/FT23.10.9+VLAN+frontend#%D0%94%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-VLAN-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D1%8B
      // Поле для ввода может содержать целые числа от 1 до 4094
      // Поле для ввода может содержать символы, -
      // можно вводить следующими способами:
      // Указать только идентификатор VLAN через запятую(без проблелов) Пример: 4,5,6
      // Указать только перечень идентификаторов VLAN. Пример: 4-6
      // Указать идентификатор VLAN через запятую и указать перечень идентификаторов VLAN. Пример: 4-6,7

      const [VALID_RANGE_START, VALID_RANGE_END] = [1, 4094];

      // Проверка на допустимые символы (числа, запятые, дефисы)
      const customValidationRegex = /^[\d,-]+$/;
      if (!customValidationRegex.test(value)) {
        return false;
      }

      // Разделяем ввод по запятой
      const segments = value.split(',');

      // Проверка на повторяющиеся идентификаторы
      const uniqueSegments = new Set();

      for (let segment of segments) {
        // Убираем лишние пробелы
        segment = segment.trim();

        // Проверка диапазона, например, 4-6
        if (segment.includes('-')) {
          const [start, end] = segment.split('-').map(Number);
          const isValidRange = Number.isNaN(start)
            || Number.isNaN(end)
            || start < VALID_RANGE_START
            || end > VALID_RANGE_END
            || start >= end
            || /^0\d+/.test(segment.split('-')[0]) // Проверка на ведущие нули в начале диапазона
            || /^0\d+/.test(segment.split('-')[1]); // Проверка на ведущие нули в конце диапазона

          if (isValidRange) {
            return false;
          }

          // Добавление всех значений диапазона в Set
          for (let i = start; i <= end; i++) {
            if (uniqueSegments.has(i)) {
              return false; // Найден повтор
            }
            uniqueSegments.add(i);
          }
        } else {
        // Проверка одиночного идентификатора
          if (/^0\d+/.test(segment)) { // Проверка на ведущие нули
            return false;
          }

          const vlanId = Number(segment);
          if (Number.isNaN(vlanId) || vlanId < VALID_RANGE_START || vlanId > VALID_RANGE_END) {
            return false;
          }

          if (uniqueSegments.has(vlanId)) {
            return false; // Найден повтор
          }
          uniqueSegments.add(vlanId);
        }
      }

      return true;
    }
  })
});

export {
  getVlanGroupValidators
};
