import { v4 as uuidv4 } from 'uuid';

/**
 @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {boolean} isVisible - Указывает, отображается или скрыто поле.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Основные настройки.
 * @property {FieldConfig} general.vlan_id
 * @property {FieldConfig} general.name
 * @property {FieldConfig} general.description
 * @property {FieldConfig} general.admin_status
 * @property {FieldConfig} general.ip
 * @property {FieldConfig} general.subnet_mask
 * @property {FieldConfig} general.acl_ingress
 * @property {FieldConfig} general.acl_egress
 * @property {FieldConfig} general.mdns_enabled
 * @property {FieldConfig} general.mtu
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    vlan_id: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true,
      isVisible: true
    },
    description: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false,
      isVisible: true
    },
    admin_status: {
      isDisabled: false,
      defaultValue: true,
      isRequired: false,
      isVisible: true
    },
    ip: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true,
      isVisible: true
    },
    subnet_mask: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true,
      isVisible: true
    },
    acl_ingress: {
      isDisabled: false,
      defaultValue: 'not_configured',
      isRequired: false,
      isVisible: true
    },
    acl_egress: {
      isDisabled: false,
      defaultValue: 'not_configured',
      isRequired: false,
      isVisible: true
    },
    mdns_enabled: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false,
      isVisible: true
    },
    mtu: {
      isDisabled: false,
      defaultValue: 1500,
      isRequired: true,
      isVisible: true
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */
function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    if (value instanceof Object && key in target) {
      result[key] = mergeDeep(target[key], value);
    } else {
      result[key] = value;
    }
  });
  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} label - Метка для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const { i18nInstance, initialValues = {} } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: { vlan_id, description, admin_status, ip, subnet_mask, acl_ingress, acl_egress, mdns_enabled, mtu }
  } = mergedValues;

  /**
   * @type {TabConfig[]}
   */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('svi.tabLabel.general'),
      name: 'general',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.idVlan'),
              name: 'general.vlan_id',
              inputType: 'number',
              validateRules: `${
                vlan_id.isRequired ? 'required|' : ''
              }integer|isVlanId|uniqueSVIName|between:1,4094`,
              isDisabled: vlan_id.isDisabled,
              placeholder: '',
              defaultValue: vlan_id.defaultValue,
              isVisible: vlan_id.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.description'),
              name: 'general.description',
              inputType: 'text',
              validateRules: `${description.isRequired ? 'required|' : ''}descriptionMask|max:200`,
              isDisabled: description.isDisabled,
              placeholder: '',
              defaultValue: description.defaultValue,
              isVisible: description.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.adminStatus'),
              name: 'general.admin_status',
              inputType: `${admin_status.isRequired ? 'required|' : ''}checkbox`,
              validateRules: ``,
              isDisabled: admin_status.isDisabled,
              placeholder: '',
              defaultValue: admin_status.defaultValue,
              isVisible: admin_status.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.ip'),
              name: 'general.ip',
              inputType: 'text',
              validateRules: `${ip.isRequired ? 'required|' : ''}ipv4mask`,
              isDisabled: ip.isDisabled,
              placeholder: 'X.X.X.X',
              defaultValue: ip.defaultValue,
              isVisible: ip.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.subnetMask'),
              name: 'general.subnet_mask',
              inputType: 'text',
              validateRules: `${subnet_mask.isRequired ? 'required|' : ''}subnetMask`,
              isDisabled: subnet_mask.isDisabled,
              placeholder: 'X.X.X.X',
              defaultValue: subnet_mask.defaultValue,
              isVisible: subnet_mask.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.aclIngress'),
              name: 'general.acl_ingress',
              inputType: 'select',
              validateRules: `${acl_ingress.isRequired ? 'required|' : ''}`,
              isDisabled: acl_ingress.isDisabled,
              placeholder: '',
              defaultValue: acl_ingress.defaultValue,
              isVisible: acl_ingress.isVisible,
              selectOptions: [
                { display: $t('svi.inputLabel.acl.selectOption.notConfigured'), data: 'not_configured' },
                ...acl_ingress.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.aclEgress'),
              name: 'general.acl_egress',
              inputType: 'select',
              validateRules: `${acl_egress.isRequired ? 'required|' : ''}`,
              isDisabled: acl_egress.isDisabled,
              placeholder: '',
              defaultValue: acl_egress.defaultValue,
              isVisible: acl_egress.isVisible,
              selectOptions: [
                { display: $t('svi.inputLabel.acl.selectOption.notConfigured'), data: 'not_configured' },
                ...acl_egress.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.mDNSGateway'),
              name: 'general.mdns_enabled',
              inputType: 'checkbox',
              validateRules: `${mdns_enabled.isRequired ? 'required|' : ''}`,
              isDisabled: mdns_enabled.isDisabled,
              placeholder: '',
              defaultValue: mdns_enabled.defaultValue,
              isVisible: mdns_enabled.isVisible
            },
            {
              id: uuidv4(),
              label: $t('svi.inputLabel.mtu'),
              name: 'general.mtu',
              inputType: 'number',
              validateRules: `${mtu.isRequired ? 'required|' : ''}integer|between:68,1500`,
              isDisabled: mtu.isDisabled,
              placeholder: '',
              defaultValue: mtu.defaultValue,
              isVisible: mtu.isVisible
            }
          ]
        }
      ]
    }
  ];

  return formConfig;
}

export default generateFormConfig;
