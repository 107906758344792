<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 130px)"
    mode="remote"
    :columns="columns"
    :rows="rows"
    :totalRows="totalItems"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      enabled: !isOneColMode,
      sortState: [sortState]
    }"
    :select-options="{
      enabled: isSelectionRowsModeEnabled,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: false // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: !isOneColMode,
      remoteMode: false,
      mode: 'records', // pages, records
      position: 'bottom',
      perPage: perPage,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: false,
      jumpFirstOrLast: true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >
    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom>
      <slot name="table-actions-bottom"></slot>
    </template>
  </WTable>
</template>

<script>
/**
 * компонент - таблица vlan gropu.
 * ! компонент локальный: используется только в VlanGroupPage.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
 */

import { generateTableColumns, generateTableRows } from './config';

export default {
  name: 'VlanGroupTable',
  components: {
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    activeRowVlanGroupId: {
      type: String,
      default: ''
    },
    selectedVlanGroupIds: {
      type: Array,
      required: true
    },
    sortState: {
      type: Object,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    },
    isSelectionRowsModeEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        name: false,
        list_vlan_id: false
      }
    };
  },
  watch: {},
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            name: this.isTableColumnHidden.name,
            list_vlan_id: this.isTableColumnHidden.list_vlan_id
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach((el) => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        vlanGroupsData: this.items,
        selectedVlanGroupIds: this.selectedVlanGroupIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const vlanGroupId = row.nativeData.id;

      const classesArr = [this.getActiveRowClass(vlanGroupId)];

      return classesArr.join(' ');
    },
    getActiveRowClass(vlanGroupId) {
      return this.activeRowVlanGroupId === vlanGroupId && 'vlan-group-table__active-row';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    }
  }
};
</script>

<style lang="css" scoped></style>

<style lang="css">
.vlan-group-table-td-wrapper {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vlan-group-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.vlan-group-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
