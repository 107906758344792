/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').WlanPolicyProfile_FRONTEND} WlanPolicyProfile_FRONTEND
 * @typedef {import('./types.js').BaseProfile_FRONTEND} BaseProfile_FRONTEND
 * @typedef {import('./types.js').WlanPolicyProfile_BACKEND} WlanPolicyProfile_BACKEND
 * @typedef {import('./types.js').Vlan_BACKEND} Vlan_BACKEND
 * @typedef {import('./types.js').Vlan_FRONTEND} Vlan_FRONTEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов wlan_policy_profile с бэковых типов (WlanPolicyProfile_BACKEND) в фронтовые (WlanPolicyProfile_FRONTEND).
 * Использовать, когда получаем wlan_policy_profile с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {WlanPolicyProfile_BACKEND} wlan_policy_profile - WlanPolicyProfile_BACKEND данные для конвертации типов.
 * @returns {WlanPolicyProfile_FRONTEND} - сконвертированные данные с типизацией WlanPolicyProfile_FRONTEND.
 */
function wlanPolicyProfileDataTypesConverterForFrontend(wlan_policy_profile) {
  const {
    id,
    name,
    description,
    profile_enabled,
    central_switch_enabled,
    central_auth_enabled,
    acl,
    id_vlan,
    id_vlan_group,
    id_policy_map_client_in,
    id_policy_map_client_out,
    id_policy_map_ssid_in,
    id_policy_map_ssid_out,
    session_timeout,
    idle_timeout,
    idle_threshold,
    exclusion_timeout,
    aaa_override_enabled,
    proxy_arp_enabled,
    id_eogre_profile,
    nas_id_1,
    nas_id_1_custom,
    nas_id_2,
    nas_id_2_custom,
    nas_id_3,
    nas_id_3_custom,
    created_at,
    updated_at
  } = wlan_policy_profile;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    name: String(name),
    description: String(description),
    profile_enabled: String(profile_enabled) === 'True',
    central_switch_enabled: String(central_switch_enabled) === 'True',
    central_auth_enabled: String(central_auth_enabled) === 'True',
    acl: String(acl),
    id_vlan: String(id_vlan),
    id_vlan_group: String(id_vlan_group),
    id_policy_map_client_in: String(id_policy_map_client_in),
    id_policy_map_client_out: String(id_policy_map_client_out),
    id_policy_map_ssid_in: String(id_policy_map_ssid_in),
    id_policy_map_ssid_out: String(id_policy_map_ssid_out),
    session_timeout: Number(session_timeout),
    idle_timeout: Number(idle_timeout),
    idle_threshold: Number(idle_threshold),
    exclusion_timeout: Number(exclusion_timeout),
    aaa_override_enabled: String(aaa_override_enabled) === 'True',
    proxy_arp_enabled: String(proxy_arp_enabled) === 'True',
    id_eogre_profile: String(id_eogre_profile),
    nas_id_1: String(nas_id_1),
    nas_id_1_custom: String(nas_id_1_custom),
    nas_id_2: String(nas_id_2),
    nas_id_2_custom: String(nas_id_2_custom),
    nas_id_3: String(nas_id_3),
    nas_id_3_custom: String(nas_id_3_custom),
    created_at: String(created_at),
    updated_at: String(updated_at)
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов wlan_policy_profile с фронтовых типов (WlanPolicyProfile_FRONTEND) в бэковые (WlanPolicyProfile_BACKEND).
 * Использовать, когда возвращаем wlan_profile с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {WlanPolicyProfile_FRONTEND} wlan_policy_profile - WlanPolicyProfile_FRONTEND данные для конвертации типов.
 * @returns {WlanPolicyProfile_BACKEND>} - сконвертированные данные с типизацией WlanPolicyProfile_BACKEND.
 */
function wlanPolicyProfileDataTypesConverterForBackend(wlan_policy_profile) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    name,
    description,
    profile_enabled,
    central_switch_enabled,
    central_auth_enabled,
    acl,
    id_vlan,
    id_vlan_group,
    id_policy_map_client_in,
    id_policy_map_client_out,
    id_policy_map_ssid_in,
    id_policy_map_ssid_out,
    session_timeout,
    idle_timeout,
    idle_threshold,
    exclusion_timeout,
    aaa_override_enabled,
    proxy_arp_enabled,
    id_eogre_profile,
    nas_id_1,
    nas_id_1_custom,
    nas_id_2,
    nas_id_2_custom,
    nas_id_3,
    nas_id_3_custom
  } = wlan_policy_profile;

  const convertedTypesToBackendFormat = {
    name: String(name),
    description: String(description),
    profile_enabled: profile_enabled ? 'True' : 'False',
    central_switch_enabled: central_switch_enabled ? 'True' : 'False',
    central_auth_enabled: central_auth_enabled ? 'True' : 'False',
    acl: String(acl),
    id_vlan: String(id_vlan),
    id_vlan_group: String(id_vlan_group),
    id_policy_map_client_in: String(id_policy_map_client_in),
    id_policy_map_client_out: String(id_policy_map_client_out),
    id_policy_map_ssid_in: String(id_policy_map_ssid_in),
    id_policy_map_ssid_out: String(id_policy_map_ssid_out),
    session_timeout: String(session_timeout),
    idle_timeout: String(idle_timeout),
    idle_threshold: String(idle_threshold),
    exclusion_timeout: String(exclusion_timeout),
    aaa_override_enabled: aaa_override_enabled ? 'True' : 'False',
    proxy_arp_enabled: proxy_arp_enabled ? 'True' : 'False',
    id_eogre_profile: String(id_eogre_profile),
    nas_id_1: String(nas_id_1),
    nas_id_1_custom: String(nas_id_1_custom),
    nas_id_2: String(nas_id_2),
    nas_id_2_custom: String(nas_id_2_custom),
    nas_id_3: String(nas_id_3),
    nas_id_3_custom: String(nas_id_3_custom),
    // created_at: String(created_at),
    // updated_at: String(updated_at)
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  return convertedTypesToBackendFormat;
}

/**
 * Конвертер типов vlan с бэковых типов (Vlan_BACKEND) в фронтовые (Vlan_FRONTEND).
 * Использовать, когда получаем vlan с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {Vlan_BACKEND} vlan - Vlan_BACKEND данные для конвертации типов.
 * @returns {Vlan_FRONTEND} - сконвертированные данные с типизацией Vlan_FRONTEND.
 */
function vlanDataTypesConverterForFrontend(vlan) {
  const {
    id,
    vlan_id,
    name,
    description,
    admin_status,
    created_at,
    updated_at
  } = vlan;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    vlan_id: String(vlan_id),
    name: String(name),
    description: String(description),
    admin_status: String(admin_status) === 'True',
    created_at: String(created_at),
    updated_at: String(updated_at)
  };

  return convertedTypesToFrontendFormat;
}

export {
  getFetcherUrl,
  wlanPolicyProfileDataTypesConverterForFrontend,
  wlanPolicyProfileDataTypesConverterForBackend,
  vlanDataTypesConverterForFrontend
};
