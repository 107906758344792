import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_SVIS_URL = `${GAPI_URL}/api/v1/svis`;
const POST_CREATE_SVI_URL = `${GAPI_URL}/api/v1/svis`;
const POST_UPDATE_SVI_URL = `${GAPI_URL}/api/v1/svis`;
const POST_DELETE_SVIS_URL = `${GAPI_URL}/api/v1/svis/bulk/deletion`;
const GET_VLAN_BY_VLAN_ID_URL = `${GAPI_URL}/api/v1/vlans/find-by-vlan-id/`;
const GET_SVI_BY_NAME_URL = `${GAPI_URL}/api/v1/svis/find-by-name/`;
const GET_ACL_VALUES_URL = `${API_URL}/api/network/l2chains`;

export {
  AXIOS_INSTANCE,
  GET_SVIS_URL,
  POST_CREATE_SVI_URL,
  POST_UPDATE_SVI_URL,
  POST_DELETE_SVIS_URL,
  GET_VLAN_BY_VLAN_ID_URL,
  GET_SVI_BY_NAME_URL,
  GET_ACL_VALUES_URL
};
