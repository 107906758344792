<template>
  <WModalContent danger
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ $t('general.confirmDeletingTitle') }}
      </h4>
    </template>
    <template #body>
      <h6 class="subheading">
        {{ $t('general.confirmDeletingText') }}:
      </h6>
      <WList class="ids-list"
        :fixedItems="{ maxItems: 5 }"
      >
        <li v-for="id in vlanIdsToDelete" :key="id">
          {{ id }}
        </li>
      </WList>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary outline @click="handleClose">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline @click="handleConfirm">
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для подтверждения удаления vlan / vlans.
 * ! компонент локальный: используется только в VlanPage.vue
 * @component
 */

export default {
  name: 'DeleteVlanModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    vlanIdsToDelete: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
  h4, h6 {
    margin: 0;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .ids-list {
    margin-top: 16px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
</style>
