/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').VlanGroup_FRONTEND} VlanGroup_FRONTEND
 * @typedef {import('./types.js').BaseVlanGroup_FRONTEND} BaseVlanGroup_FRONTEND
 * @typedef {import('./types.js').VlanGroup_BACKEND} VlanGroup_BACKEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов vlan_group с бэковых типов (VlanGroup_BACKEND) в фронтовые (VlanGroup_FRONTEND).
 * Использовать, когда получаем vlan_group с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {VlanGroup_BACKEND} vlan_group - WlanProfile_BACKEND данные для конвертации типов.
 * @returns {VlanGroup_FRONTEND} - сконвертированные данные с типизацией VlanGroup_FRONTEND.
 */
function vlanGroupDataTypesConverterForFrontend(vlan_group) {
  const {
    id,
    list_vlan_id,
    list_id_vlan,
    name,
    created_at,
    updated_at
  } = vlan_group;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    list_vlan_id: String(list_vlan_id),
    list_id_vlan: String(list_id_vlan),
    name: String(name)
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов vlan_group с фронтовых типов (VlanGroup_FRONTEND) в бэковые (VlanGroup_BACKEND).
 * Использовать, когда возвращаем vlan_group с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {BaseVlanGroup_FRONTEND & Partial<Id>} vlan_group - VlanGroup_FRONTEND данные для конвертации типов.
 * @returns {VlanGroup_BACKEND>} - сконвертированные данные с типизацией VlanGroup_BACKEND.
 */
function vlanGroupDataTypesConverterForBackend(vlan_group) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    list_vlan_id,
    list_id_vlan,
    name
  } = vlan_group;

  const convertedTypesToBackendFormat = {
    list_vlan_id: String(list_vlan_id),
    name: String(name)
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  if (list_id_vlan) {
    convertedTypesToBackendFormat.list_id_vlan = String(list_id_vlan);
  }

  return convertedTypesToBackendFormat;
}

export {
  getFetcherUrl,
  vlanGroupDataTypesConverterForFrontend,
  vlanGroupDataTypesConverterForBackend
};
