/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import { AXIOS_INSTANCE, GET_WLAN_POLICY_PROFILE_BY_NAME_URL } from '../config';
import {
  wlanPolicyProfileDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').WlanPolicyProfile_FRONTEND} WlanPolicyProfile_FRONTEND
 * @typedef {import('../types.js').WlanPolicyProfile_BACKEND} WlanPolicyProfile_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/485326850/FT23.10.7+backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {WlanPolicyProfile_BACKEND} wlan_policy_profile - искомый объект wlan po;icy profile.
 */

/**
 * Функция-фетчер для получения wlan policy profile по его name.
 *
 * @param {string} wlanPolicyName - Name искомого wlan policy profile.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getWlanPolicyByNameFetcher = async (wlanPolicyName) => {
  const url = `${GET_WLAN_POLICY_PROFILE_BY_NAME_URL}${wlanPolicyName}`;

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {WlanPolicyProfile_FRONTEND} wlan_policy_profile - искомый объект wlan policy profile.
 */

/**
 * проверяет, уникален ли name в рамках wlan policy profiles.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {string} wlanPolicyName - Name искомого wlan policy profile.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const checkIsUniqueWlanPolicyName = async (wlanPolicyName, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await getWlanPolicyByNameFetcher(wlanPolicyName);
    const {
      wlan_policy_profile
    } = response;

    const data = wlanPolicyProfileDataTypesConverterForFrontend(wlan_policy_profile);

    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
};

export default checkIsUniqueWlanPolicyName;
