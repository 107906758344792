<template>
  <div class="svi-tab">
    <div class="svi-tab-content">
      <div v-for="(content, index) in tabContent" class="svi-tab-input-group" :key="index">
        <h3 v-if="content.label" class="svi-tab-input-group-heading">
          {{ content.label }}
        </h3>
        <div v-for="input in content.inputs" class="svi-tab-input" :key="input.id">
          <template v-if="input.inputType === 'checkbox'">
            <WCheckbox
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :checked="formState[input.name]"
              @change="handleCheckbox(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="change"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <template v-else-if="input.inputType === 'select'">
            <WSelect
              :options="input.selectOptions"
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :value="formState[input.name]"
              @input="handleSelect(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="input"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <!-- обычные инпуты -->
          <template v-else>
            <WInput
              data-vv-delay="500"
              :key="input.id"
              :type="input.inputType"
              :label="input.label"
              :value="formState[input.name]"
              @input="handleInput(input, $event)"
              :id="input.name"
              :name="input.name"
              :placeholder="input.placeholder"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="input"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
              autocomplete="one-time-code"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент таба SVIForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

export default {
  name: 'SVIFormTabContent',
  inject: ['$validator'],
  components: {},
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleCheckbox(inputName, event) {
     this.handleDefaultCheckbox(inputName, event);
    },
    handleDefaultCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
  }
};
</script>

<style lang="css" scoped>
.svi-tab {
  height: 100%;
  max-height: 500px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.svi-tab::-webkit-scrollbar {
  width: 5px;
}

.svi-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.svi-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.svi-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.svi-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.svi-tab-description {
  flex: 0 0 40%;
  display: flex;
}

.svi-tab-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.svi-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}

.rules-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: decimal;
  padding-left: 16px;
  margin: 0;
}
</style>
