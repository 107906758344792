<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <SVIFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице svi page.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  SVIFormTabContent
} from './components';

import {
  getSVIFormValidators
} from './validators';

export default {
  name: 'SVIForm',
  components: {
    SVIFormTabContent
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    forbiddenSVINames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setSVIFormValidators() {
      const validators = getSVIFormValidators(this.$i18n);
    
      const isVlanId = validators.isVlanId(this.forbiddenVlanIds);
      const uniqueSVIName = validators.uniqueSVIName(this.forbiddenSVINames);
      const descriptionMask = validators.descriptionMask();
    
      const validator = this.$validator;
      validator.extend('isVlanId', isVlanId);
      validator.extend('uniqueSVIName', uniqueSVIName);
      validator.extend('descriptionMask', descriptionMask);
    }
  },
  created() {
    this.setSVIFormValidators();
  }
};
</script>

<style lang="css" scoped>
</style>

