/**
 * API-модуль для страницы wlan policy.
 * @module wlanPolicyApi
*/

import getWlanPolicies from './methods/getWlanPolicies';
import createWlanPolicy from './methods/createWlanPolicy';
import updateWlanPolicy from './methods/updateWlanPolicy';
import deleteWlanPolicies from './methods/deleteWlanPolicies';
import checkIsUniqueWlanPolicyName from './methods/checkIsUniqueWlanPolicyName';
import getVlans from './methods/getVlans';
import getVlanGroups from './methods/getVlanGroups';
import getPolicyMaps from './methods/getPolicyMaps';
import getL2Chains from './methods/getL2Chains';

const wlanPolicyApi = {
  getWlanPolicies,
  createWlanPolicy,
  updateWlanPolicy,
  deleteWlanPolicies,
  checkIsUniqueWlanPolicyName,
  getVlans,
  getVlanGroups,
  getPolicyMaps,
  getL2Chains
};

export default wlanPolicyApi;
