<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step2') }}</div>
          <small class="text-muted">{{ $t('template.wizardrConfigParametersTitle') }}</small>
          <span class="h6 setting-title">{{ $t('template.configGeneral') }}</span>

          <div
            class="form-group mt-1"
            v-if="
              isBeelineBranding &&
              newTemplateData.template.cpe_config_template &&
              newTemplateData.template.cpe_config_template.beeline_config
            "
          >
            <label for="beeline-nas-ip">{{ $t('aps.nasIp') }}</label>
            <input
              type="text"
              id="beeline-nas-ip"
              placeholder="0.0.0.0"
              name="beeline-nas-ip"
              v-validate="'ipv4'"
              class="form-control"
              :class="{ input: true, 'is-danger': errors.has('beeline-nas-ip') }"
              v-model="newTemplateData.template.cpe_config_template.beeline_config.nas_ip"
            />
            <span v-show="errors.has('beeline-nas-ip')" class="help is-danger">
              {{ errors.first('beeline-nas-ip') }}
            </span>
          </div>

          <div class="form-group mb-2" v-if="areLocationsVisible">
            <label for="location">{{ $t('general.location') }}</label>
            <select @change="changeLocationHandler" v-model="newTemplateData.template.location" name="location" class="form-control" id="location">
              <option v-for="location in locationsList" :value="location.id" :key="location.id">
                {{ location.name }}
              </option>
            </select>
            <span class="small text-muted">{{$t('aps.locationChangeHint')}}</span>
          </div>

          <section v-if="currentTemplateData && cpeModelData">
            <span class="h6 setting-title">{{ $t('template.configRadio') }}</span>
            <span v-if="modelObj(currentTemplateData.model.id)">
              ( {{ modelObj(currentTemplateData.model.id).description }} )
            </span>

            <!---->
            <div
              class="form-group interface-block mb-1 mt-2"
              v-for="cpeInterface in newTemplateData.template.cpe_config_template.wifi"
              v-if="cpeModelData.caps.wifi[cpeInterface.id]"
              :data-id="cpeInterface.id"
              :key="cpeInterface.id"
            >
              <p class="mb-0 h6" @click.prevent="showId">
                <img class="interface-block-icon" src="/static/img/radio.svg" />
                <strong>{{ cpeInterface.id }}</strong>
                <span class="badge badge-success ml-1 frequency-title">
                  <span v-if="cpeInterface && (cpeInterface.frequency === '2.4' || cpeInterface.frequency === '5')">
                    {{ cpeInterface.frequency }} {{ $t('aps.ghz') }}
                  </span>
                </span>
                <WButton link
                  v-if="!interfaceSettings[cpeInterface.id]"
                         custom-class="float-right"
                >
                  <i class="icon-arrow-left mr-1"></i>
                  <i class="icon-settings"></i>
                </WButton>
                <WButton link
                  v-if="interfaceSettings[cpeInterface.id]"
                          custom-class="float-right"
                >
                  <i class="icon-arrow-down mr-1"></i>
                  <i class="icon-settings"></i>
                </WButton>
              </p>
              <div>
                <small class="text-xs" v-if="interfaceCapabilities[cpeInterface.id]">
                  {{ $t('aps.mode') }}:
                  <span class="radio-data">{{ interfaceCapabilities[cpeInterface.id].selected.bandmode }},</span>
                  {{ $t('aps.width') }}:
                  <span class="radio-data">{{ interfaceCapabilities[cpeInterface.id].selected.bandwidth }},</span>
                  {{ $t('aps.channel') }}:
                  <span class="radio-data">
                    <span
                      v-if="interfaceCapabilities[cpeInterface.id].selected.channels.length"
                      v-for="channel in interfaceCapabilities[cpeInterface.id].selected.channels"
                    >
                      {{ channel }},
                    </span>
                    <span v-if="!interfaceCapabilities[cpeInterface.id].selected.channels.length">Auto,</span>
                  </span>
                  {{ $t('aps.txPower') }}:
                  <span class="radio-data" v-if="interfaceCapabilities[cpeInterface.id].selected.txpower == '0'">
                    {{ $t('aps.auto') }}
                  </span>
                  <span class="radio-data" v-else>
                    {{ interfaceCapabilities[cpeInterface.id].selected.txpower }} {{ $t('aps.dbm') }}
                  </span>
                  {{ $t('aps.country') }}:
                  <span class="radio-data">{{ interfaceCapabilities[cpeInterface.id].selected.country }},</span>
                  {{ $t('aps.rateControl') }}:
                  <span class="radio-data">{{ interfaceCapabilities[cpeInterface.id].selected.require_mode }},</span>
                  {{ $t('aps.backgroundScaning') }}:
                  <span
                    class="radio-data"
                    v-if="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                  >
                    {{ $t('general.on') }},
                  </span>
                  <span class="radio-data" v-else>{{ $t('general.off') }},</span>
                  {{ $t('aps.maxClients') }}:
                  <span class="radio-data" v-if="interfaceCapabilities[cpeInterface.id].selected.maxclients">
                    {{ interfaceCapabilities[cpeInterface.id].selected.maxclients }}
                  </span>
                  <span class="radio-data" v-else>{{ $t('aps.unlim') }}</span>
                </small>
              </div>
              <transition name="interface">
                <section
                  v-if="
                    interfaceSettings[cpeInterface.id] &&
                    cpeModelData.caps.wifi &&
                    newTemplateData.template.cpe_config_template.wifi &&
                    cpeModelData.caps.wifi[cpeInterface.id] &&
                    cpeModelData.caps.wifi[cpeInterface.id].frequency !== 'None'
                  "
                >
                  <span class="h5">{{ $t('aps.cpeEditRadioSettings') }}</span>
                  <div class="text-danger select-channels-less-or-more-in-interface-settings"
                       v-if="isCpeModelYuncoreXd6800">
                    <div v-if="cpeInterface.id === 'radio0'">
                      {{$t('aps.selectChannelsMoreThanForCorrectWork')}}&nbsp;100
                    </div>
                    <div v-if="cpeInterface.id === 'radio2'">
                      {{$t('aps.selectChannelsLessThanForCorrectWork')}}&nbsp;100
                    </div>
                  </div>
                  <div
                    class="form-group mt-1"
                    v-if="cpeModelData.caps.wifi && cpeModelData.caps.wifi[cpeInterface.id].frequency === `Both`"
                  >
                    <label for="frequency">{{ $t('general.frequency') }}</label>
                    <select
                      id="frequency"
                      class="form-control"
                      :disabled="isDisable"
                      :class="{ 'select-disabled': isDisable }"
                      @change="
                        handleFrequencyChange(
                          interfaceCapabilities[cpeInterface.id].selected,
                          interfaceCapabilities[cpeInterface.id].capabilities,
                          cpeInterface.id
                        )
                      "
                      v-model="interfaceCapabilities[cpeInterface.id].selected.frequency"
                    >
                      <option>2.4</option>
                      <option>5</option>
                    </select>
                  </div>

                  <div class="form-group mt-1">
                    <label for="bandmode">{{ $t('aps.bandMode') }}</label>
                    <select
                      id="bandmode"
                      class="form-control"
                      @change="
                        handleModeChange(
                          interfaceCapabilities[cpeInterface.id].selected,
                          interfaceCapabilities[cpeInterface.id].capabilities,
                          cpeInterface.id
                        )
                      "
                      v-model="interfaceCapabilities[cpeInterface.id].selected.bandmode"
                    >
                      <option
                        v-for="(key, value) in interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist"
                        :selected="interfaceCapabilities[cpeInterface.id].selected.bandmode === '11' + value"
                        :value="'11' + value"
                        v-if="
                          isModeAllowed(
                            interfaceCapabilities[cpeInterface.id].selected.frequency,
                            value,
                            interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist
                          )
                        "
                      >
                        {{ '11' + value }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group" v-if="calculatedCapabilities[cpeInterface.id]">
                    <label for="bandwidth">{{ $t('aps.bandwidth') }}</label>
                    <select
                      id="bandwidth"
                      class="form-control"
                      v-model="interfaceCapabilities[cpeInterface.id].selected.bandwidth"
                      @change="clearChanList(cpeInterface.id)"
                    >
                      <option
                        v-for="bandwidth in calculatedCapabilities[cpeInterface.id].bandwidth"
                        :key="bandwidth"
                        :selected="interfaceCapabilities[cpeInterface.id].selected.bandwidth == bandwidth"
                        :value="bandwidth"
                      >
                        <span
                          v-if="
                            bandwidth === 'HT40' && interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                          "
                        >
                          HT40+
                        </span>
                        <span v-else>{{ bandwidth }}</span>
                      </option>
                    </select>
                  </div>

<!--                  &lt;!&ndash;Channels&ndash;&gt;-->

<!--                  <label for="channel">-->
<!--                    {{ cpeModelData.cisco ? $t('aps.channel') : $t('aps.channels') }}-->
<!--                    <span class="text-muted" v-if="!interfaceCapabilities[cpeInterface.id].selected.channels.length">-->
<!--                      : auto-->
<!--                    </span>-->
<!--                  </label>-->
<!--                  <div-->
<!--                    class="form-group"-->
<!--                    v-if="-->
<!--                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                      interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'-->
<!--                    "-->
<!--                  >-->
<!--                    <div class="channel-list">-->
<!--                      <div-->
<!--                        class="channel-list-item interface-channel-trapezoid"-->
<!--                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"-->
<!--                        v-if="channel != 'auto'"-->
<!--                      >-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                          class="interface-channel-checkbox"-->
<!--                          :value="channel"-->
<!--                          @change="-->
<!--                            handlerChannels(-->
<!--                              cpeModelData.cisco,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                              channel,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                              cpeInterface.id-->
<!--                            )-->
<!--                          "-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                        />-->
<!--                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }}</label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    class="form-group"-->
<!--                    v-if="-->
<!--                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||-->
<!--                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||-->
<!--                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')-->
<!--                    "-->
<!--                  >-->
<!--                    <div class="channel-list">-->
<!--                      <div-->
<!--                        class="channel-list-item interface-channel-trapezoid"-->
<!--                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"-->
<!--                        v-if="channel != 'auto'"-->
<!--                      >-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                          class="interface-channel-checkbox"-->
<!--                          :value="channel"-->
<!--                          @change="-->
<!--                            handlerChannels(-->
<!--                              cpeModelData.cisco,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                              channel,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                              cpeInterface.id-->
<!--                            )-->
<!--                          "-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                        />-->
<!--                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }}</label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    class="form-group"-->
<!--                    v-if="-->
<!--                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||-->
<!--                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')-->
<!--                    "-->
<!--                  >-->
<!--                    <div class="channel-list">-->
<!--                      <div-->
<!--                        class="channel-list-item interface-channel-trapezoid__ht40"-->
<!--                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"-->
<!--                        v-if="channel != 'auto'"-->
<!--                      >-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          class="interface-channel-checkbox"-->
<!--                          :value="channel"-->
<!--                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                          @change="-->
<!--                            handlerChannels(-->
<!--                              cpeModelData.cisco,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                              channel,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                              cpeInterface.id-->
<!--                            )-->
<!--                          "-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                        />-->
<!--                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }} {{ channel + 4 }}</label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    class="form-group"-->
<!--                    v-if="-->
<!--                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80'-->
<!--                    "-->
<!--                  >-->
<!--                    <div class="channel-list">-->
<!--                      <div-->
<!--                        class="channel-list-item interface-channel-trapezoid__ht80"-->
<!--                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"-->
<!--                        v-if="channel != 'auto'"-->
<!--                      >-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                          class="interface-channel-checkbox"-->
<!--                          :value="channel"-->
<!--                          @change="-->
<!--                            handlerChannels(-->
<!--                              cpeModelData.cisco,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                              channel,-->
<!--                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),-->
<!--                              cpeInterface.id-->
<!--                            )-->
<!--                          "-->
<!--                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                        />-->
<!--                        <label class="" :for="`${cpeInterface.id}-${channel}`">-->
<!--                          {{ channel }} {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;End channels&ndash;&gt;-->

                                    <!--Channels-->

                  <label for="channel">
                    {{ cpeModelData.cisco ? $t('aps.channel') : $t('aps.channels') }}
                    <span class="text-muted" v-if="!interfaceCapabilities[cpeInterface.id].selected.channels.length">
                      : auto
                    </span>
                  </label>
                  <div
                    class="form-group"
                    v-if="
                      calculatedCapabilities[cpeInterface.id] &&
                      interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                    "
                  >
                    <div class="channel-list">
                      <div
                        class="channel-list-item interface-channel-trapezoid"
                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"
                        v-if="channel != 'auto'"
                      >
                        <input
                          type="checkbox"
                          :id="`${cpeInterface.id}-${channel}`"
                          class="interface-channel-checkbox"
                          :value="channel"
                          @change="
                            handlerChannels(
                              cpeModelData.cisco,
                              interfaceCapabilities[cpeInterface.id].selected.channels,
                              channel,
                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                        />
                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }}</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      calculatedCapabilities[cpeInterface.id] &&
                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||
                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||
                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE20' ||
                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')
                    "
                  >
                    <div class="channel-list">
                      <div
                        class="channel-list-item interface-channel-trapezoid"
                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"
                        v-if="channel != 'auto'"
                      >
                        <input
                          type="checkbox"
                          :id="`${cpeInterface.id}-${channel}`"
                          class="interface-channel-checkbox"
                          :value="channel"
                          @change="
                            handlerChannels(
                              cpeModelData.cisco,
                              interfaceCapabilities[cpeInterface.id].selected.channels,
                              channel,
                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                        />
                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }}</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      calculatedCapabilities[cpeInterface.id] &&
                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||
                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE40' ||
                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')
                    "
                  >
                    <div class="channel-list">
                      <div
                        class="channel-list-item interface-channel-trapezoid__ht40"
                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"
                        v-if="channel != 'auto'"
                      >
                        <input
                          type="checkbox"
                          class="interface-channel-checkbox"
                          :value="channel"
                          :id="`${cpeInterface.id}-${channel}`"
                          @change="
                            handlerChannels(
                              cpeModelData.cisco,
                              interfaceCapabilities[cpeInterface.id].selected.channels,
                              channel,
                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                        />
                        <label class="" :for="`${cpeInterface.id}-${channel}`">{{ channel }} {{ channel + 4 }}</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      calculatedCapabilities[cpeInterface.id] &&
                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80' ||
                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80')
                    "
                  >
                    <div class="channel-list">
                      <div
                        class="channel-list-item interface-channel-trapezoid__ht80"
                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"
                        v-if="channel != 'auto'"
                      >
                        <input
                          type="checkbox"
                          :id="`${cpeInterface.id}-${channel}`"
                          class="interface-channel-checkbox"
                          :value="channel"
                          @change="
                            handlerChannels(
                              cpeModelData.cisco,
                              interfaceCapabilities[cpeInterface.id].selected.channels,
                              channel,
                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                        />
                        <label class="" :for="`${cpeInterface.id}-${channel}`">
                          {{ channel }} {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      calculatedCapabilities[cpeInterface.id] &&
                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT160' ||
                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE160' ||
                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80+80' ||
                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80+80')
                    "
                  >
                    <div class="channel-list">
                      <div
                        class="channel-list-item interface-channel-trapezoid__ht80"
                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                        :class="{ active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel) }"
                        v-if="channel != 'auto'"
                      >
                        <input
                          type="checkbox"
                          :id="`${cpeInterface.id}-${channel}`"
                          class="interface-channel-checkbox"
                          :value="channel"
                          @change="
                            handlerChannels(
                              cpeModelData.cisco,
                              interfaceCapabilities[cpeInterface.id].selected.channels,
                              channel,
                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(channel),
                              cpeInterface.id
                            )
                          "
                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                        />
                        <label class="" :for="`${cpeInterface.id}-${channel}`">
                                                        {{ channel }}
                              {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }} {{ channel + 16
                              }} {{ channel + 20 }} {{ channel + 24 }} {{ channel + 28 }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <!--End channels-->

                  <div
                    class="form-group mb-2"
                    v-if="
                      interfaceCapabilities[cpeInterface.id].selected.power &&
                      interfaceCapabilities[cpeInterface.id].selected.power.range &&
                      Array.isArray(interfaceCapabilities[cpeInterface.id].selected.power.range)
                    "
                  >
                    <span class="m-0 mt-2">{{ $t('aps.txPowerAdjust') }}</span>
                    <Switch-component
                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                      :disabled="isDisable"
                      :label="$t('general.Auto')"
                      class="float-right"
                      id="step2-is-auto"
                      key="step2-is-auto"
                    />

<!--                    <vue-slider-->
<!--                      ref="slider"-->
<!--                      v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"-->
<!--                      :disabled="cpeModelData.cisco"-->
<!--                      v-bind="TXPower"-->
<!--                      :max="calculatedCapabilities[cpeInterface.id].txPower"-->
<!--                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                      tooltip-dir="bottom"-->
<!--                      tooltip="always"-->
<!--                      formatter="{value} dbm"-->
<!--                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                    ></vue-slider>-->
<!--                    <vue-slider-->
<!--                      ref="slider"-->
<!--                      v-else-->
<!--                      :disabled="true"-->
<!--                      v-bind="TXPower"-->
<!--                      :max="calculatedCapabilities[cpeInterface.id].txPower"-->
<!--                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                      tooltip-dir="bottom"-->
<!--                      tooltip="always"-->
<!--                      formatter="{value} dbm"-->
<!--                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                    ></vue-slider>-->
                    <!--                                       // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)-->
                    <vue-slider
                      ref="slider"
                      v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"
                      :disabled="cpeModelData.cisco"
                      v-bind="TXPower"
                      :max="calculatedCapabilities[cpeInterface.id].txPower"
                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 1"
                      tooltip-dir="bottom"
                      tooltip="always"
                      formatter="{value} dbm"
                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                    ></vue-slider>
                    <vue-slider
                      ref="slider"
                      v-else
                      :disabled="true"
                      v-bind="TXPower"
                      :max="calculatedCapabilities[cpeInterface.id].txPower"
                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 1"
                      tooltip-dir="bottom"
                      tooltip="always"
                      formatter="{value} dbm"
                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                    ></vue-slider>
                    <!--                                  конец изменений по WNE-3314-->
                  </div>

<!--&lt;!&ndash;                  // todo убрать этот старый input (который теперь заменен select'ом) если не потребуется его восстановить (11.12.2023)&ndash;&gt;-->

<!--                  <div class="form-group" v-if="!cpeModelData.cisco">-->
<!--                    <label for="country">{{ $t('aps.country') }}</label>-->
<!--                    <input-->
<!--                      @input="changeCountryHandler(cpeInterface.id)"-->
<!--                      id="country"-->
<!--                      class="form-control"-->
<!--                      v-model="interfaceCapabilities[cpeInterface.id].selected.country"-->
<!--                    />-->
<!--                  </div>-->

                  <div class="form-group" v-if="!cpeModelData.cisco">
                    <WSelect
                      @input="changeCountryHandler(cpeInterface.id)"
                      v-model="interfaceCapabilities[cpeInterface.id].selected.country"
                      :options="countriesCodesOptionsForSelectorInInterfaceRadioSettings"
                      :label="$t('aps.country')"
                      :disabled="isDisable"
                      id="country"
                      customClass="form-control"
                    />
                  </div>




                  <div v-if="!cpeModelData.cisco">
                    <hr class="hr-settings mb-2" />
                    <span class="h5">{{ $t('aps.advancedSettings') }}</span>

                    <div
                      class="form-group mt-2 mb-2"
                      v-if="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                    >
                      <label for="tx-power">
                        <b>{{ $t('aps.rateControl') }}</b>
                      </label>
                      <vue-slider
                        ref="slider"
                        :disabled="
                          interfaceCapabilities[cpeInterface.id].selected.bandmode != '11n' &&
                          interfaceCapabilities[cpeInterface.id].selected.bandmode != '11ac'
                        "
                        v-bind="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                        tooltip-dir="bottom"
                        tooltip="hover"
                        formatter="{value}"
                        v-model="interfaceCapabilities[cpeInterface.id].selected.require_mode"
                      ></vue-slider>
                    </div>

                    <div class="form-group mb-0 mt-3">
                      <Switch-component
                        v-model="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                        :label="$t('aps.backgroundScaning')"
                        class=""
                        id="step2-background-scanning"
                        key="step2-background-scanning"
                      />
                    </div>

                    <div class="form-group mt-1 mb-0">
                      <Switch-component
                        v-model="advancedSettings[cpeInterface.id].maxClientsEnabled"
                        :label="$t('aps.maxClients')"
                        class=""
                        id="step2-max-client"
                        key="step2-max-client"
                      />
                    </div>
                    <div class="form-group mb-2" v-if="advancedSettings[cpeInterface.id].maxClientsEnabled">
                      <label for="maxclients">{{ $t('aps.limitperWlan') }}</label>
                      <input
                        id="maxclients"
                        class="form-control"
                        :disabled="!advancedSettings[cpeInterface.id].maxClientsEnabled"
                        name="maxclients"
                        v-validate="'required|numeric'"
                        :class="{ input: true, 'is-danger': errors.has('description') }"
                        v-model="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                      />
                      <span v-show="errors.has('maxclients')" class="help is-danger">
                        {{ errors.first('maxclients') }}
                      </span>
                    </div>
                  </div>
                </section>
              </transition>

              <hr class="hr-settings mb-2" />
              <div class="mt-1">
                <label>
                  <strong>{{ $t('template.wizardWLANs') }}</strong>
                </label>
                <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">
                  <span v-if="!isWlanDescription">{{ $t('general.showDescription') }}</span>
                  <span v-else>{{ $t('general.hideDescription') }}</span>
                </a>
              </div>
<!--              <multiselect-->
<!--                v-model="interfaceSelectedWlans[cpeInterface.id]"-->
<!--                :multiple="true"-->
<!--                :options="wlansList || []"-->
<!--                :max="8"-->
<!--                label="ssid"-->
<!--                track-by="ssid"-->
<!--                :custom-label="ssidWithDescription"-->
<!--                :close-on-select="false"-->
<!--                :hide-selected="true"-->
<!--                :placeholder="$t('template.wizardWLANsPlaceHolder')"-->
<!--                :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                :selectedLabel="$t('general.multiSelectSelected')"-->
<!--              ></multiselect>-->


              <Multiselect
                v-model="interfaceSelectedWlans[cpeInterface.id]"
                :multiple="true"
                :options="wlansListForWlansSearch[cpeInterface.id] || []"
                :max="maxNumberOfSSIDsForRadioInterface"
                label="ssid"
                :disabled="isDisable"
                :id="[cpeInterface.id]"
                trackBy="ssid"
                :closeOnSelect="false"
                :customLabel="ssidWithDescription"
                :hideSelected="true"
                :internalSearch="false"
                :placeholder="$t('aps.wlansSelectorPlaceHolder')"
                :selectLabel="$t('aps.wlansSelectorTip')"

                :class="{ 'multiselect-disabled': isDisable }"
                :loading="loadingDataForWlanList[cpeInterface.id]"
                @search-change="asyncSearchWlanWhenSearchChange"
                @open="asyncSearchWlanWhenOpenSelectorList"
              >
                <template v-slot:noOptions>
                  {{ $t('general.noOptionsForSearch') }}
                </template>
                <template v-slot:noResult>
                  {{ $t('general.noResultForSearch') }}
                </template>
                <template v-slot:maxElements>
                  <span class="wlans-selector__max-elements-msg">
                    {{ $t('general.maxElementsSelected', { max: maxNumberOfSSIDsForRadioInterface })}}
                  </span>
                </template>
              </Multiselect>
              <div v-if="!isWLCProduct">
                <span class="small text-muted">{{$t('aps.wlansSelectorHint')}}</span>
              </div>

            </div>
          </section>
          <!---->
          <div v-if="!newTemplateData.model || !Object.keys(cpeModelData.caps).length" class="mt-2">
            <div>
              <label class="">
                <strong>{{ $t('template.wizardWLANs') }}</strong>
              </label>
              <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">
                <span v-if="!isWlanDescription">{{ $t('general.showDescription') }}</span>
                <span v-else>{{ $t('general.hideDescription') }}n</span>
              </a>
            </div>
<!--            <multiselect-->
<!--              v-model="interfaceSelectedWlans.common"-->
<!--              :multiple="true"-->
<!--              :options="wlansList || []"-->
<!--              :max="8"-->
<!--              label="ssid"-->
<!--              :custom-label="ssidWithDescription"-->
<!--              class="mb-2"-->
<!--              track-by="ssid"-->
<!--              :close-on-select="false"-->
<!--              :hide-selected="true"-->
<!--              :placeholder="$t('template.wizardWLANsPlaceHolder')"-->
<!--              :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--              :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--              :selectedLabel="$t('general.multiSelectSelected')"-->
<!--            ></multiselect>-->


            <Multiselect
              class="mb-1"
              v-model="interfaceSelectedWlans.common"
              :multiple="true"
              :options="wlansListForWlansSearchForCommonEdit || []"
              :max="maxNumberOfSSIDsForRadioInterface"
              label="ssid"
              :customLabel="ssidWithDescription"
              :internalSearch="false"
              trackBy="ssid"
              :closeOnSelect="false"
              :hideSelected="true"
              :placeholder="$t('aps.wlansSelectorPlaceHolder')"
              :loading="loadingDataForWlanListForCommonEdit"
              @search-change="asyncSearchWlanWhenSearchChangeForCommonEdit"
              @open="asyncSearchWlanWhenOpenSelectorListForCommonEdit"
            >
              <template v-slot:noOptions>
                {{ $t('general.noOptionsForSearch') }}
              </template>
              <template v-slot:noResult>
                {{ $t('general.noResultForSearch') }}
              </template>
              <template v-slot:maxElements>
                <span class="wlans-selector__max-elements-msg">
                  {{ $t('general.maxElementsSelected', { max: maxNumberOfSSIDsForRadioInterface })}}
                </span>
              </template>
            </Multiselect>
            <div v-if="!isWLCProduct">
              <span class="small text-muted">{{$t('aps.wlansSelectorHint')}}</span>
            </div>


          </div>

          <!-- Wired component -->
          <wiredConfig
            v-if="cpeModelData"
            :redirectsList="redirectsList"
            :cpeModelData="cpeModelData"
            :objWiredConfig="newTemplateData.template.cpe_config_template.wired"
            :isRootUser="isRootUser"
            :isDisable="isDisable"
            :isOperator="false"
            :hostsList="hostsList"
            :wlansList="wlansList"
          ></wiredConfig>

          <div v-if="!cpeModelData || (cpeModelData && !cpeModelData.cisco)">
            <statLogLbsConfig
              v-if="newTemplateData"
              :updatedCpeConfig="newTemplateData.template.cpe_config_template"
              :currentCpeConfig="newTemplateData.template.cpe_config_template"
              :isEditShow="statLogLbsmodal"
              :lbsFilterList="configFilterList"
              :isDisable="false"
              :isOperator="false"
            ></statLogLbsConfig>

            <accessControl
              v-if="newTemplateData"
              :updatedCpeConfig="newTemplateData.template.cpe_config_template"
              :currentCpeConfig="newTemplateData.template.cpe_config_template"
              :firewallL3List="newTemplateData.template.cpe_config_template.firewall.l3_filter"
              :isEditShow="isFirewallShow"
              :firewallsList="firewallsList"
              :isDisable="false"
              :isOperator="false"
            ></accessControl>
          </div>

          <div class="mt-2">
            <WButton outline
              customClass="cpe-register-next-button float-right"
              @click="setNewTemplateData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton secondary outline
              customClass="cpe-register-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import vueSlider from 'vue-slider-component';
import { countriesCodesOptionsMixin, maxNumberOfSSIDsForRadioInterfaceMixin } from '@/mixins';
import commonService from '../../services/commonService';
import firewallService from '../../services/firewallService';
import helpers from '../../helpers';
import capabilities from '../../services/capabilities';
import wlanService from '../../services/wlanService';
import wiredConfig from '../../components/Aps/wiredConfig.vue';
import statLogLbsConfig from '../../components/Aps/statLogLbsConfig.vue';
import accessControl from '../../components/Aps/accessControl.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import { disabledChannelsForCountries, countriesList } from '../../helpers/disabledChannelsForCountries';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const DEFAULT_VALUE_FOR_WIRELESS_INTERFACE = {
  id: '',
  bandmode: '',
  bandwidth: '',
  power: {
    auto: false,
    range: null
  },
  wlans: [],
  channels: [],
  country: 'RU',
  require_mode: 'off',
  maxclients: 0,
  scanningconfig: {
    enabled: false
  }
};

export default {
  components: {
    Multiselect,
    vueSlider,
    wiredConfig,
    statLogLbsConfig,
    accessControl,
    SwitchComponent
  },
  name: 'TemplatesStep2',
  mixins: [countriesCodesOptionsMixin, maxNumberOfSSIDsForRadioInterfaceMixin],
  data() {
    return {
      isWlanDescription: false,
      isFirewallShow: false,
      interfaceCapabilities: {},
      calculatedCapabilities: {},
      interfaceSelectedWlans: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
        common: []
      },
      configFilterList: {
        blacklist: [],
        whitelist: []
      },
      firewallL3List: {
        ip_list: [],
        mac_list: []
      },
      statLogLbsmodal: false,
      interfaceSettings: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
      },
      loadingDataForWlanList: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
      },
      wlansListForWlansSearch: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
      },
      timeoutsForListForWlansSearch: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
      },
      timeoutsForListForWlansSearchForCommonEdit: undefined,
      wlansListForWlansSearchForCommonEdit: [],
      loadingDataForWlanListForCommonEdit: false,
      TXPower: {
        bgStyle: {},
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #3498db, #f05b72)'
        }
      },
      rateControl: {
        '11n': {
          data: ['off', 'ht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        },
        '11ac': {
          data: ['off', 'ht', 'vht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        }
      },
      advancedSettings: {
        // изменяется (добавляются ключи) функцией initFieldsForComponentWork в зависимости от выбраной модели ТД
      },
      // наполняется значениями по умолчаню для интерфейсов в функции initFieldsForComponentWork
      // в зависимости от выбраной модели ТД
      interfacesWithDefaultValuesFromCpeModel: [],
      newTemplateData: {
        template: {
          cpe_config_template: {
            wmsnmpd: {
              default: {
                community: '',
                enabled: false,
                interfaces: [],
                location: ''
              }
            },
            beeline_config: {
              nas_ip: ''
            },
            firewall: {
              l2_chain: '',
              nat_access: false,
              wan_access_block: false,
              l3_filter: {
                ip_list: [],
                mac_list: []
              }
            },
            stats_config: {
              enabled: false,
              reportperiod: 30
            },
            log_config: {
              enabled: false,
              log_ip: '',
              log_port: ''
            },
            dhcpcap_config: {
              enabled: false
            },
            lbs_config: {
              emptywatcher: false,
              maxquiet: 5,
              maxcachequeue: 50,
              enabled: false,
              reportperiod: 30,
              clienttimeout: 30,
              blacklist: [],
              whitelist: [],
              filtermode: 'None'
            },
            wifi: [
            ],
            wired: []
          }
        }
      },
      cpeModelData: false,
      newMacAddress: ''
    };
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...WLCProductMapGetters(['isWLCProduct']),
    disabledChannelsForCountriesComputed() {
      return disabledChannelsForCountries;
    },
    countriesListComputed() {
      return countriesList;
    },
    isCpeModelYuncoreXd6800() {
      // проверяем yuncore,xd6800 ли точка для шаблона
      return this.checkCPEModel('yuncore,xd6800');
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },

    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },

    firewallsList() {
      return this.$store.state.firewallsList;
    },
    cpeModelsList() {
      const cpeModelsListIds = helpers.combineIdsArrayFromObjectsArray(this.$store.state.cpeModelsList);
      return cpeModelsListIds;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    currentTemplateData() {
      const assignTemplateData = Object.assign(this.$store.state.newTemplateData, this.newTemplateData);
      this.newTemplateData = JSON.parse(JSON.stringify(assignTemplateData));
      this.newTemplateData.template.cpe_config_template.wifi = this.interfacesWithDefaultValuesFromCpeModel;

      if (this.newTemplateData.model) {
        const modelId = this.newTemplateData.model.id;
        if (!this.$store.state.cpeModelsList) {
          return;
        }
        const { cpeModelsList } = this.$store.state;
        cpeModelsList.forEach((model) => {
          if (model.id === modelId) {
            this.cpeModelData = model;
            if (this.cpeModelData.name.toLowerCase().includes('cisco')) {
              this.cpeModelData.cisco = true;
            }
          } else {
          }
        });
        //
        if (this.cpeModelData) {
          this.newTemplateData.template.cpe_config_template.wifi.forEach((radioInterface) => {
            if (this.cpeModelData.caps.wifi[radioInterface.id]) {
              if (!capabilities.isValidCaps(this.cpeModelData.caps.wifi[radioInterface.id])) {
                return;
              }
              const sortedTxpwrlist = this.cpeModelData.caps.wifi[radioInterface.id].txpwrlist.sort(helpers.compareDbm);
              const maxTxPower = sortedTxpwrlist[sortedTxpwrlist.length - 1].dbm;
              const minTxPower = sortedTxpwrlist[0].dbm;

              // if (!radioInterface.power.range) {
              //   if (this.cpeModelData && maxTxPower && minTxPower) {
              //     radioInterface.power.range = [minTxPower, maxTxPower];
              //   } else {
              //     radioInterface.power.range = [0, 10];
              //   }
              // }
              // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)
              if (!radioInterface.power.range) {
                if (this.cpeModelData && maxTxPower && minTxPower) {
                  let minTxPowerForUse = minTxPower
                  if (minTxPowerForUse === 0) {
                    minTxPowerForUse = 1
                  }
                  radioInterface.power.range = [minTxPowerForUse, maxTxPower];
                } else {
                  // radioInterface.power.range = [0, 10];
                  radioInterface.power.range = [1, 10];
                }
              }
              // конец изменений  для WNE-3314

            }

            for (const wiredInterface in this.cpeModelData.caps.wired) {
              if (
                this.newTemplateData &&
                !helpers.transformArrayToObject(this.newTemplateData.template.cpe_config_template.wired)[wiredInterface]
              ) {
                this.newTemplateData.template.cpe_config_template.wired.push({
                  id: wiredInterface,
                  vlans: [],
                  primary_vlan: 0
                });
              }
              const wiredPorts = this.cpeModelData.caps.wired[wiredInterface].ports;

              const filteredPorts = wiredPorts.sort((curr, prev) => {
                if (curr.index > prev.index) {
                  return 1;
                }
                if (curr.index < prev.index) {
                  return -1;
                }
                return 0;
              });
              this.cpeModelData.caps.wired[wiredInterface].ports = filteredPorts;
            }
            if (!this.cpeModelData.caps.wifi[radioInterface.id]) {
              return;
            }
            // устанавливаем дефолтный bandmode
            if (!radioInterface.bandmode) {
              if (this.cpeModelData.caps.wifi[radioInterface.id].hwmodelist.ac && radioInterface.frequency === '5') {
                radioInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[radioInterface.id].hwmodelist.n) {
                radioInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[radioInterface.id].hwmodelist.a) {
                radioInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[radioInterface.id].hwmodelist.b) {
                radioInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[radioInterface.id].hwmodelist.g) {
                radioInterface.bandmode = '11g';
              }
            }

            this.interfaceCapabilities[radioInterface.id] = {
              selected: radioInterface,
              capabilities: this.cpeModelData.caps.wifi[radioInterface.id]
            };

            // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
            // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)
            if (typeof this.interfaceCapabilities[radioInterface.id].selected === 'object' &&
              this.interfaceCapabilities[radioInterface.id].selected !== null &&
              Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[radioInterface.id].selected, 'power') &&
              typeof this.interfaceCapabilities[radioInterface.id].selected.power === 'object' &&
              this.interfaceCapabilities[radioInterface.id].selected.power !== null &&
              Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[radioInterface.id].selected.power, 'range') &&
              Array.isArray(this.interfaceCapabilities[radioInterface.id].selected.power.range) &&
              this.interfaceCapabilities[radioInterface.id].selected.power.range.length &&
              this.interfaceCapabilities[radioInterface.id].selected.power.range[0] === 0) {
              // если в настроках интерфеса было установлено txPower min 0, то ставим 1,
              // так как минимальное значение в слайдере теперь 1
              this.interfaceCapabilities[radioInterface.id].selected.power.range[0] = 1;
            }
            // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
            // конец изменений  для WNE-3314

            if (this.cpeModelData.caps.wifi[radioInterface.id].frequency !== 'Both') {
              this.interfaceCapabilities[radioInterface.id].selected.frequency = this.cpeModelData.caps.wifi[
                radioInterface.id
              ].frequency;
            }

            if (
              !this.interfaceCapabilities[radioInterface.id].selected.frequency &&
              this.cpeModelData.caps.wifi[radioInterface.id].frequency &&
              this.cpeModelData.caps.wifi[radioInterface.id].frequency === 'Both'
            ) {
              this.interfaceCapabilities[radioInterface.id].selected.frequency = '2.4';
            }

            this.calculateCapabilities(
              radioInterface,
              this.interfaceCapabilities[radioInterface.id].capabilities,
              radioInterface.id
            );
          });
        }
        return this.newTemplateData;
      }
    }

  },
  methods: {
    /**
     * Возвращает объект с дефолтными значнеиями для беспроводного интерфейса
     *
     * @param interfaceId id интерфейса
     * @return {{bandmode: string, country: string, channels: [], bandwidth: string, scanningconfig: {enabled: boolean}, id: string, power: {auto: boolean, range: null}, require_mode: string, wlans: [], maxclients: number}}
     */
    getDefaultValuesForWirelessInterface(interfaceId) {
      const defaultValuesForWirelessInterface = JSON.parse(JSON.stringify(DEFAULT_VALUE_FOR_WIRELESS_INTERFACE));
      if (interfaceId) defaultValuesForWirelessInterface.id = interfaceId;
      return defaultValuesForWirelessInterface;
    },
    /**
     * Инициализирует необходимые поля, которые нужны для работы компонента в зависимости от данных о
     * cpeModel, если эта модель выбрана
     */
    initFieldsForComponentWork() {
      this.interfacesWithDefaultValuesFromCpeModel = [];
      // находим нужную модель ТД
      const newTemplateDataFromStore = this.$store.state.newTemplateData;
      let cpeModelData;

      if (newTemplateDataFromStore.model) {
        const modelId = newTemplateDataFromStore.model.id;
        const {cpeModelsList} = this.$store.state;
        cpeModelsList.forEach((model) => {
          if (model.id === modelId) {
            cpeModelData = model;
          }
        });
        //
        if (cpeModelData) {
          // инициализруем нужные для работы с беспроводными интерфейсами поля в зависимости от модели ТД
          Object.keys(cpeModelData.caps.wifi).forEach((configInterfaceKey) => {
            if (!Object.prototype.hasOwnProperty.call(this.advancedSettings, configInterfaceKey)) {
              this.$set(this.advancedSettings, configInterfaceKey, {
                maxClientsEnabled: false,
                rateControlEnabled: false
              })
            }
            if (!Object.prototype.hasOwnProperty.call(this.interfaceSelectedWlans, configInterfaceKey)) {
              this.$set(this.interfaceSelectedWlans, configInterfaceKey, []);
            }
            if (!Object.prototype.hasOwnProperty.call(this.wlansListForWlansSearch, configInterfaceKey)) {
              this.$set(this.wlansListForWlansSearch, configInterfaceKey, []);
            }
            if (!Object.prototype.hasOwnProperty.call(this.interfaceSettings, configInterfaceKey)) {
              this.$set(this.interfaceSettings, configInterfaceKey, false);
            }
            if (!Object.prototype.hasOwnProperty.call(this.loadingDataForWlanList, configInterfaceKey)) {
              this.$set(this.loadingDataForWlanList, configInterfaceKey, false);
            }
            if (!Object.prototype.hasOwnProperty.call(this.timeoutsForListForWlansSearch, configInterfaceKey)) {
              this.$set(this.timeoutsForListForWlansSearch, configInterfaceKey, undefined);
            }
            this.interfacesWithDefaultValuesFromCpeModel.push(this.getDefaultValuesForWirelessInterface(configInterfaceKey));
          });
        }
      }
    },
    changeLocationHandler(){
      for (const interfaceName of Object.keys(this.interfaceSelectedWlans)) {
        this.interfaceSelectedWlans[interfaceName] = [];
      }
    },
    changeCountryHandler(interfaceId) {
      // для задачи WNE-3589
      // если для выбраной страны недоступны какие-то каналы, то чистим от них список выбранных
      this.$nextTick(() => {
        const selectedCountry = this.interfaceCapabilities[interfaceId].selected.country;
        // console.log(selectedCountry)

        const isDisabledChannelsForSelectedCountry = Object.keys(this.countriesListComputed).includes(selectedCountry);
        let channelsDisabledForSelectedCountry = [];
        // console.log('isDisableChannelsForSelectedCountry', isDisableChannelsForSelectedCountry);
        if (isDisabledChannelsForSelectedCountry) {
          channelsDisabledForSelectedCountry = JSON.parse(JSON.stringify(this.disabledChannelsForCountriesComputed[this.countriesListComputed[selectedCountry]]));
        }
        this.$nextTick(() => {
          if (isDisabledChannelsForSelectedCountry && Array.isArray(this.interfaceCapabilities[interfaceId].selected.channels)) {
            this.interfaceCapabilities[interfaceId].selected.channels =
              this.interfaceCapabilities[interfaceId].selected.channels.filter((channel) => !channelsDisabledForSelectedCountry.includes(channel));
          }
        });
      });
    },
    checkCPEModel(modelName) {
      // ждем на вход назавние модели - напирмер "yuncore,xd6800"
      // если точка выбраная для шаблона этой модели - возвращаем true, иначе false
      try {
        if (this.newTemplateData?.model?.name === modelName) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e)
        return false;
      }
    },
    setNewTemplateData() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.newTemplateData.template.cpe_config_template.lbs_config.blacklist = this.configFilterList.blacklist;
            this.newTemplateData.template.cpe_config_template.lbs_config.whitelist = this.configFilterList.whitelist;
            if (this.cpeModelData && Object.keys(this.cpeModelData.caps.wifi).length) {
              this.newTemplateData.template.cpe_config_template.wifi.forEach((currIterface) => {
                currIterface.wlans = this.interfaceSelectedWlans[currIterface.id];
              });
            } else if (this.cpeModelData && !Object.keys(this.cpeModelData.caps.wifi).length) {
              this.newTemplateData.template.wlans = this.interfaceSelectedWlans.common;
            } else {
              this.newTemplateData.template.cpe_config_template.wifi = null;
              this.newTemplateData.template.wlans = this.interfaceSelectedWlans.common;
            }

            this.$store.commit('setNewTemplateData', this.newTemplateData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    handleFrequencyChange(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.frequency === '2.4') {
        if (interfaceCapabilities.hwmodelist.b) {
          interfaceSelectedData.bandmode = '11b';
        } else if (interfaceCapabilities.hwmodelist.g) {
          interfaceSelectedData.bandmode = '11g';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        }
      } else if (interfaceSelectedData.frequency === '5') {
        if (interfaceCapabilities.hwmodelist.a) {
          interfaceSelectedData.bandmode = '11a';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        } else if (interfaceCapabilities.hwmodelist.ac) {
          interfaceSelectedData.bandmode = '11ac';
        }
      }
      this.calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName);
      this.clearChanList(interfaceName);
    },
    showLogStatLbs() {
      this.statLogLbsmodal = !this.statLogLbsmodal;
    },
    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    addVLAN(id) {
      if (this.isDisable) {
        return;
      }
      const newVLAN = {};
      Vue.set(newVLAN, 'vlan', '');
      Vue.set(newVLAN, 'ports', []);
      Vue.set(newVLAN, 'tunnel', '');
      Vue.set(newVLAN, 'fake_wlan', '');
      Vue.set(newVLAN, 'acct', false);
      this.newTemplateData.template.cpe_config_template.wired.forEach((wired) => {
        if (wired.id === id) {
          wired.vlans.push(newVLAN);
        }
      });
    },
    removeVLAN(vlans, index) {
      if (this.isDisable) {
        return;
      }
      if (vlans[index]) {
        vlans.splice(index, 1);
      }
    },
    changePorts(ports, index) {
      if (this.isDisable) {
        return;
      }
      const vlanPorts = ports;
      const portInedx = index;

      if (vlanPorts.includes(portInedx)) {
        vlanPorts.splice(vlanPorts.indexOf(portInedx), 1);
      } else {
        vlanPorts.push(portInedx);
      }
    },
    listOfVLAN(vlans) {
      const vlansObj = vlans;
      const vlansArray = [];
      vlansObj.forEach((item) => {
        if (typeof item.vlan === 'number') {
          vlansArray.push(item.vlan);
        }
      });
      return vlansArray || [];
    },
    checkNativeVlan(wiredInterface, array, vlan) {
      if (!array.includes(vlan)) {
        wiredInterface.primary_vlan = 0;
      }
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.newTemplateData.template.cpe_config_template.lbs_config.filtermode === 'WhiteList') {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          if (this.newTemplateData.template.cpe_config_template.lbs_config.filtermode === 'BlackList') {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    removeMac(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.configFilterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.configFilterList.blacklist.splice(index, 1);
      }
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    showId(e) {
      const selectedNode = e.target.closest('[data-id]');
      this.interfaceSettings[selectedNode.dataset.id] = !this.interfaceSettings[selectedNode.dataset.id];
    },
    // VALIDATION
    // isModeAllowed(frequency, mode, hwmodelist) {
    //   if (frequency === '2.4') {
    //     const modes24 = {
    //       b: true,
    //       g: true,
    //       n: hwmodelist.n
    //     };
    //     return modes24[mode];
    //   }
    //   const modes5 = {
    //     a: true,
    //     n: hwmodelist.n,
    //     ac: hwmodelist.ac
    //   };
    //   return modes5[mode];
    // },

    isModeAllowed(frequency, mode, hwmodelist) {
      // console.log('frequency', frequency)
      // console.log('mode', mode)
      // console.log('hwmodelist', hwmodelist)
      if (frequency === '2.4') {
        const modes24 = {
          b: hwmodelist.b,
          g: hwmodelist.g,
          n: hwmodelist.n,
          // 'ac': hwmodelist.ac
          ax: hwmodelist.ax
        };
        return modes24[mode];
      }
      const modes5 = {
        a: hwmodelist.a,
        n: hwmodelist.n,
        ac: hwmodelist.ac,
        ax: hwmodelist.ax
      };
      return modes5[mode];
    },

    activeChannel(channelList, currChannel) {
      let result;
      channelList.forEach((channel) => {
        if (channel === currChannel) {
          result = true;
        }
      });
      return result;
    },
    handleModeChange(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      this.calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName);
    },
    // calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName) {
    //   let config = null;
    //   config = interfaceSelectedData;
    //
    //   if (!interfaceName) return;
    //   this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);
    //
    //   if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
    //     config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
    //   }
    //
    //   // validate channels for HT40 & 2.4
    //   if (
    //     this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'HT40'
    //     );
    //   }
    //   // end validate
    //
    //   // validate channels for HT40 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT40/HT40'
    //     );
    //   }
    //   // end
    //   // validate channels for HT80 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT80/HT80'
    //     );
    //   }
    //   //             end
    // },


    calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      let config = null;
      config = interfaceSelectedData;

      if (!interfaceName) return;
      this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);

      if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
        config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
      }

      // validate channels for HT40 & 2.4
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'HT40/HE40'
        );
      }
      // end validate

      // validate channels for HT40 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT40/HT40/HE40'
        );
      }
      // end
      // validate channels for HT80 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80/HT80/HE80'
        );
      }

      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT160' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE160') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80+80/VHT160/HE80+80/HE160'
        );
      }
      //             end
    },


    clearChanList(id) {
      this.interfaceCapabilities[id].selected.channels = [];
    },
    modelObj(id) {
      return commonService.modelObjbyId(id);
    },
    handlerChannels(isCisco, channels, channel, isActive, id) {
      if (!isCisco || !isActive) {
        return;
      }
      this.interfaceCapabilities[id].selected.channels = [channel];
    },
        async asyncSearchWlanWhenOpenSelectorList(id) {
      return this.asyncSearchWlan('', id, 0);
    },
    async asyncSearchWlanWhenSearchChange(query, id) {
      return this.asyncSearchWlan(query, id);
    },
    async asyncSearchWlan(query, id, timeout = 1000) {
      if (this.timeoutsForListForWlansSearch[id] !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearch[id])
      }
      this.loadingDataForWlanList[id] = true;

      let locationForSearch = undefined;
      if (this.newTemplateData.template.location) {
        locationForSearch = this.newTemplateData.template.location
      }

      this.timeoutsForListForWlansSearch[id] = setTimeout(async () => {
        // if (query !== '') {
        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanList[id] = true;
        try {
          // const response = await wlanService.requestWlansWithSearch(query);
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query, locationForSearch, locationForSearch ? false : true);
          this.wlansListForWlansSearch[id] = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearch[id] = [];
        } finally {
          this.loadingDataForWlanList[id] = false;
        }
        // console.log(this.wlansListForWlansSearch[id]);
        // }
      }, timeout);
    },
    async asyncSearchWlanWhenOpenSelectorListForCommonEdit(id) {
      return this.asyncSearchWlanForCommonEdit('', 0);
    },
    async asyncSearchWlanWhenSearchChangeForCommonEdit(query) {
      return this.asyncSearchWlanForCommonEdit(query);
    },
    async asyncSearchWlanForCommonEdit(query, timeout = 1000) {
      if (this.timeoutsForListForWlansSearchForCommonEdit !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearchForCommonEdit)
      }
      this.loadingDataForWlanListForCommonEdit = true;

      let locationForSearch = undefined;
      if (this.newTemplateData.template.location) {
        locationForSearch = this.newTemplateData.template.location
      }

      this.timeoutsForListForWlansSearchForCommonEdit = setTimeout(async () => {

        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanListForCommonEdit = true;
        try {
          // const response = await wlanService.requestWlansWithSearch(query);
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query, locationForSearch, locationForSearch ? false : true);
          this.wlansListForWlansSearchForCommonEdit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearchForCommonEdit = [];
        } finally {
          this.loadingDataForWlanListForCommonEdit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },
  },
  created() {
    if (!String.prototype.format) {
      String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
      };
    }
    if (!this.firewallsList) {
      firewallService.getFirewalls(this);
    }
    wlanService.getHosts(this);
    // this.newTemplateData.template.location = this.$store.state.userData.base_location;
    this.initFieldsForComponentWork();

    if (this.$store.state.selectedOnTemplateWizStep1Location) {
      this.newTemplateData.template.location = this.$store.state.selectedOnTemplateWizStep1Location;
    } else {
      this.newTemplateData.template.location = this.$store.state.userData.base_location;
    }

  }
};
</script>
<style lang="scss" scoped>
  .wlans-selector__max-elements-msg {
    white-space: normal;
}

  .select-channels-less-or-more-in-interface-settings {
    text-align: center;
    font-size: 1.2em !important;
  }

  .interface-channel-trapezoid__ht160 {
    font-size: 1em;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 25%;
    box-sizing: border-box;
    min-width: 210px;
    max-width: 300px;
    margin: 6px -1px;
    height: 0;
    border: 10px solid rgba(0, 0, 0, 0);
    border-top: 0 solid;
    border-bottom: 30px solid #bcc5d0;
    text-align: center;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
    transition: border-bottom-color 0.5s;
  }

  .interface-channel-trapezoid__ht160:hover {
    border-bottom-color: #8cd4a5;
  }

  .active.interface-channel-trapezoid__ht160 {
    border-bottom-color: #4ebd75;
  }

  .interface-channel-trapezoid__ht160.disabled {
    cursor: not-allowed;
  }
</style>
